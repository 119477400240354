import React from "react";
import './btngenerico.css'


const BtnGenerico = ({ title = "", children, onClick=() =>{},className="" }) => {
    return (
        <button className={`btn_container activebtnMenu2 btnEstiloPersonalizado ${className}`} style={{fontSize:'16px'}} onClick={onClick}>
            {children}

            {title}

        </button>
    )
}

export default BtnGenerico;