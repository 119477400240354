import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBNav, MDBNavItem, MDBNavLink, MDBTabPane, MDBTabContent, MDBIcon, MDBCol, MDBCardBody, MDBCard, MDBCardText, MDBRow, MDBTable, MDBTableHead, MDBTableBody, MDBContainer } from 'mdbreact';
import { addDefaultSrc } from "../librerias/herramientas.js"
import { getStockDesglose } from "../action/actionCreatorsRequests"
import 'antd/dist/antd.css';

import { Tabs, Skeleton, Space, Divider, Drawer } from 'antd';
import TablaGenerica from './tablaGenerica'
import { DrawerStockAlmacen, createRipple } from "../action/actionCreators";
import { traducirPagina, obtenerDatosUsuarioYLicenciaActual, convertirMoneda, etiquetaReferenciaSinStock } from "../librerias/funciones"
import store from '../store'
import {
    LikeOutlined,
    DislikeOutlined,
} from '@ant-design/icons';

const { TabPane } = Tabs;

const FichaStockAlmacen = ({ props, masInformacion, configApp, openDrawerStockAlmacen, mostrarComponente, propiedadesVisor, fichaBusquedaVehiculo }) => {
    const [hayStockAlmacen, setHayStockAlmacen] = React.useState(false);
    const [hayPrecios, setHayPrecios] = React.useState(false);
    const [obtenerStockAlmacen, setObtenerStockAlmacen] = React.useState([]);

    const buscarFechaEntrega = (registros) => {

        const resultado = registros.filter((registro) => (registro.FechaEntrega !== undefined && registro.FechaEntrega !== null && registro.FechaEntrega !== ""));

        return resultado;
    }


    const buscarPrecios = (registros) => {
        let hayPrecios = registros.find((item) => item.Precio !== null)
        return hayPrecios === undefined ? false : true
    }

    if (!hayStockAlmacen && openDrawerStockAlmacen) {
        setHayStockAlmacen(true)

        getStockDesglose(props.referencia, props.marca, props.referenciaProveedor).then(stock => {
            if (stock !== undefined && stock !== null && stock.length > 0) {
                setHayStockAlmacen(true)
                setObtenerStockAlmacen(stock);

                setHayPrecios(buscarPrecios(stock))
            } else {
                setHayStockAlmacen(true)
                setObtenerStockAlmacen(null);
                setHayPrecios(false)
            }
        })
    } else {
        if (!openDrawerStockAlmacen && hayStockAlmacen) {
            setObtenerStockAlmacen([])
            setHayStockAlmacen(false)
            setHayPrecios(false)

        }
    }

    return (
        <Drawer
            width={650}
            onClose={() => { store.dispatch(DrawerStockAlmacen(false)); store.dispatch({ type: 'STATE_ES_NEUMATICO', NEUMATICO: { ES_NEUMATICO: false, REFERENCIA_FABRICANTE: "" } }) }}
            visible={openDrawerStockAlmacen}
            closable={false}

            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '999999999', color: '#fff' }}>

            <div className="flex-container-Grow-Tercero" >
                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { store.dispatch(DrawerStockAlmacen(false)); createRipple(e); store.dispatch({ type: 'STATE_ES_NEUMATICO', NEUMATICO: { ES_NEUMATICO: false, REFERENCIA_FABRICANTE: "" } }) }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>

            <div className="p-3" style={{ height: (configApp.height - 70).toString() + "px", overflow: 'auto' }}>
                {
                    obtenerStockAlmacen !== null ?
                        obtenerStockAlmacen.length > 0 ?
                            <MDBTable responsive hover>
                                <MDBTableHead>
                                    <tr>
                                        <th style={{ textAlign: 'left', fontWeight: '500' }}>{traducirPagina("Almacen").toUpperCase()}</th>
                                        {obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE || obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD ? null : <th style={{ textAlign: 'right', width: '100px', fontWeight: '500' }}>{traducirPagina("Cantidad").toUpperCase()}</th>}
                                        <th style={{ textAlign: 'center', width: '100px', fontWeight: '500' }}>{traducirPagina("Disponibilidad").toUpperCase()}</th>

                                        {hayPrecios && (<th style={{ textAlign: 'center', width: '80px', fontWeight: '500' }}>{traducirPagina("Precio").toUpperCase()}</th>)}

                                        {buscarFechaEntrega(obtenerStockAlmacen).length > 0 ? <th style={{ textAlign: 'center', width: '190px', fontWeight: '500' }}>{traducirPagina("Fecha_entrega").toUpperCase()}</th> : null}

                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {obtenerStockAlmacen.map((item, i) =>
                                        <tr key={i} style={{ color: item.DefectoCliente ? "blue" : "", fontWeight: item.DefectoCliente ? 'bold' : "" }}>
                                            <td style={{ textAlign: 'left' }}>{item.DescripcionAlmacen}</td>
                                            {obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE || obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD ? null : <td style={{ textAlign: 'right', width: '100px' }}>{item.Stock}</td>}
                                            <td style={{ textAlign: 'center', width: '100px' }}>
                                                <button className="activebtnMenu2" style={{ border: '0', borderRadius: '3px', fontSize: '13px', color: item.Stock === 0 ? "red" : '#25c281', backgroundColor: 'transparent', verticalAlign: 'text-bottom' }}>  {item.Stock === 0 ? <DislikeOutlined style={{ fontSize: '20px' }}></DislikeOutlined> : <LikeOutlined style={{ fontSize: '20px' }} />}  </button>
                                            </td>

                                            {hayPrecios && (<td style={{ textAlign: 'right' }}>{item.Precio === null ? "" : convertirMoneda(item.Precio, "€")}</td>)}


                                            {buscarFechaEntrega(obtenerStockAlmacen).length > 0 ? <td style={{ textAlign: 'center', width: '190px' }}>  {item.FechaEntrega !== null ? `${item.FechaEntrega} ${item.HoraEntrega}` : ""} </td> : null}

                                        </tr>
                                    )
                                    }
                                </MDBTableBody>
                            </MDBTable>
                            :
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#000' }}> <div color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                        :
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{etiquetaReferenciaSinStock()}</h3> </div>
                }
            </div>
        </Drawer>

    )
}

FichaStockAlmacen.propTypes = {
    onClick: PropTypes.func,
    config: PropTypes.object,
    detallesReferencia: PropTypes.object,
    masInformacion: PropTypes.object,
    fichaAbierta: PropTypes.bool,
    marca: PropTypes.number,
    referenciaProveedor: PropTypes.string,
    activeItemOuterTabs: PropTypes.string
};

const mapStateToProps = (state, props) => {
    return {
        fichaBusquedaVehiculo: state.fichaBusquedaVehiculo,
        datosGraficos: state.datosGraficos,
        hayReferencias: state.hayReferencias,
        masInformacion: state.masInformacion,
        configApp: state.configApp,
        props: props,
        allCourse: state.allCourse,
        openDrawerStockAlmacen: state.estadoDrawer.openDrawerStockAlmacen,
        mostrarComponente: state.mostrarComponente,
        propiedadesVisor: state.propiedadesVisor,

    };
}

export default connect(mapStateToProps)(FichaStockAlmacen);