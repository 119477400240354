import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from 'mdbreact';
import 'antd/dist/antd.css';
import { Select, Card, Drawer, Modal, Button, Form, Input, Checkbox, Row, Col, Tabs, Menu, Switch, Divider, Collapse } from 'antd';
import { DrawerFichaTiemposReparacion, createRipple, DrawerFichaGMVehiculoHaynes, DrawerFichaLubricantes } from "../action/actionCreators";
import { GetUsuariosEnAPI, SetUsuarioEnAPI, GetIdiomasEnAPI, GetNodosRaizTiemposReparacionEnAPI, GetSubNodosTiempoReparacioEnAPI, ObtenerTareasTiempoReparacionEnnAPI, GetVehiculosHaynesByIdTecdocEnAPI, GetSmartPackEnAPI, ObtenerLubricantesByIdVehiculoTecdocEnAPI } from "../action/actionCreatorsRequests";
import { convertirMoneda, MSGERROR, MSGOK, traducirPagina, dynamicSortAsc, obtenerDatosUsuarioYLicenciaActual, imprimirDivGenerico } from "../librerias/funciones"
import FichaLubricantesERP from '../components/FichaLubricantesERP'

import {
    SearchOutlined,
    PrinterOutlined
} from '@ant-design/icons';
import store from '../store'

const { Option } = Select;
const { TabPane } = Tabs;
const { Meta } = Card;
const { Panel } = Collapse;
const FichaLubricantes = ({ props, configApp, openDrawerFichaLubricantes, codmotorizacion }) => {
    const [mode, setMode] = React.useState('inline');
    const [theme, setTheme] = React.useState('light');


    const [hayRegistros, setHayRegistros] = React.useState(false);
    const [abrirFichaLubricante, setAbrirFichaLubricante] = React.useState(false);
    const [viscosiidad, setViscosiidad] = React.useState("");
    const [calidad, setCalidad] = React.useState("");




    const [lubricantes, setLubricantes] = React.useState([]);



    if (!hayRegistros && openDrawerFichaLubricantes) {
        setHayRegistros(true);


        ObtenerLubricantesByIdVehiculoTecdocEnAPI(codmotorizacion).then(registro => {
            if (registro !== undefined) {
                if (registro.AJUSTES !== null && registro.AJUSTES.length > 0) {
                    setLubricantes(registro.AJUSTES)
                }
            }
        })
    } else {
        if (!openDrawerFichaLubricantes && hayRegistros) {
            setLubricantes([])
            setHayRegistros(false)

        }
    }


    const listaIndice = () => {
        let indices = []
        lubricantes.map((regis, index) => {
            indices.push(index);
        })

        return indices
    }



    //5W-40

    const filtroReferenciaLocal = (listaDeReferenciasDeLaPaginaActual) => {

        listaDeReferenciasDeLaPaginaActual = listaDeReferenciasDeLaPaginaActual.sort(dynamicSortAsc("Orden"))


        return listaDeReferenciasDeLaPaginaActual

    }


    return (
        <div>
            <Drawer
                width={800}
                onClose={() => {
                    store.dispatch(DrawerFichaLubricantes(false));
                }}
                visible={openDrawerFichaLubricantes}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>
                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { store.dispatch(DrawerFichaLubricantes(false)); }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ textAlign: 'left', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>


                    <div style={{ flexGrow: '1', textAlign: 'end', color: '#fff', verticalAlign: 'middle', paddingRight: '10px', fontSize: '16px' }}>
                        <button className={`col-md-12 ${'btnColor'}`} style={{
                            height: '40px', width: '50px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top',
                            color: '#fff',
                            marginBottom: '5px', marginRight: '5px'
                        }} onClick={(e) => {

                            imprimirDivGenerico("conatinerTiempos");
                        }}>
                            <PrinterOutlined style={{ fontSize: '20px' }} />
                            <span style={{ verticalAlign: 'middle', fontSize: '16px', marginLeft: '10px' }}></span>
                        </button>
                    </div>

                </div>



                <div style={{ height: (configApp.height - 150).toString() + "px", }} className="ml-2 mr-2 mt-2" >
                    <Card
                        type="inner"
                        bordered={false}
                        style={{ height: (configApp.height - 85).toString() + "px", overflow: 'auto' }}
                    >
                        <div>

                            <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000', marginTop: '10px' }}>

                                <Col md={24} className="conatinerTiempos">
                                    {lubricantes === null ?
                                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                            <h4>{traducirPagina("No_resultados")}</h4>
                                        </div>
                                        :
                                        lubricantes.length > 0 ?
                                            <Collapse
                                                className="site-collapse-custom-collapse"
                                                bordered={false}
                                                defaultActiveKey={listaIndice()}
                                            >

                                                {lubricantes.map((nodo) =>

                                                    nodo.LUBRICANTES !== null ?
                                                        nodo.LUBRICANTES.map((subnodo, index) =>
                                                            <Panel
                                                                className="site-collapse-custom-panel"
                                                                header={subnodo.Nombre}
                                                                key={index}
                                                            >
                                                                {subnodo === null ?
                                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '20vh' }}> <h3>{traducirPagina("No_lubricantes")}</h3> </div>
                                                                    :
                                                                    subnodo.LUBRICANTES !== undefined ?
                                                                        subnodo.LUBRICANTES.length === 0 ?
                                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '20vh' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                                                            :
                                                                            <ul style={{ paddingLeft: '8px' }}>

                                                                                {filtroReferenciaLocal(subnodo.LUBRICANTES).map((tarea, indice) =>
                                                                                    <li key={indice} style={{ listStyle: 'none' }}>
                                                                                        <div className={"site-collapse-custom-tareas"}>
                                                                                            <Row gutter={[8, 8]} style={subnodo.LUBRICANTES.length > 1 ? { borderBottom: (subnodo.LUBRICANTES.length - 1) === indice ? "" : "1px #dcdcdc solid" } : {}}>
                                                                                                <Col md={24}>
                                                                                                    <Row gutter={[8, 8]} >
                                                                                                        <Col md={24}>
                                                                                                            <div>
                                                                                                                <span style={{ color: '#4285f4' }}>{tarea.Nombre} </span>
                                                                                                                <br></br>
                                                                                                                <table style={{ width: '100%' }}>

                                                                                                                    <thead>
                                                                                                                        <tr>
                                                                                                                            <th style={{ textAlign: 'left' }}>{traducirPagina("Calidad")}</th>
                                                                                                                            <th style={{ width: '200px', textAlign: 'left' }}>{traducirPagina("Temperatura")}</th>
                                                                                                                            <th style={{ width: '200px', textAlign: 'left' }}>{traducirPagina("Viscosidad")}</th>
                                                                                                                        </tr>
                                                                                                                    </thead>

                                                                                                                    <tr>
                                                                                                                        <td style={{}}> {tarea.Calidad}</td>
                                                                                                                        <td style={{}}>{tarea.Temperatura}</td>
                                                                                                                        <td style={{}}> {tarea.Viscosidad}       <button id="btnArtVeh" onClick={() => { setViscosiidad(tarea.Viscosidad); setCalidad(tarea.Calidad); setAbrirFichaLubricante(true) }} className="activebtnMenu2" style={{ marginLeft: '10px', width: '80px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#1890ff', backgroundColor: '#ccc', display: obtenerDatosUsuarioYLicenciaActual().PERMISO_NEUMATICOS ? tarea.Viscosidad === null || tarea.Viscosidad === "" ? 'none' : '' : 'none' }}><SearchOutlined style={{ fontSize: '14px' }} />  <span style={{ verticalAlign: 'middle', fontSize: '16px' }}> {traducirPagina("Buscar")}</span> </button> </td>
                                                                                                                    </tr>


                                                                                                                </table>
                                                                                                                <hr></hr>

                                                                                                                {index === 0 ?
                                                                                                                    nodo.CAPACIDADES.map((capacidad, i) =>
                                                                                                                        <div key={i}>
                                                                                                                            <span> {capacidad.Nombre + " " + (capacidad.Valor !== null ? capacidad.Valor : "") + " " + (capacidad.Unidad !== null ? capacidad.Unidad : "")} <hr></hr></span>

                                                                                                                            {capacidad.SubAjustes.map((ajuste, i) =>

                                                                                                                                <div key={i}>
                                                                                                                                    <span> {ajuste.Nombre + " " + (ajuste.Valor !== null ? ajuste.Valor : "") + " " + (ajuste.Observacion !== null ? "(" + ajuste.Observacion + ")" : "")} <hr></hr></span>


                                                                                                                                    <img src={ajuste.NombreImagen}></img>
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </div>

                                                                                                                    ) :
                                                                                                                    null}


                                                                                                            </div>


                                                                                                        </Col>
                                                                                                    </Row>

                                                                                                </Col>



                                                                                            </Row>
                                                                                        </div>
                                                                                    </li>



                                                                                )}
                                                                            </ul>

                                                                        : null
                                                                }
                                                            </Panel>
                                                        )
                                                        : null
                                                )
                                                }
                                            </Collapse>
                                            :
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>

                                    }

                                </Col>
                            </Row>
                        </div>

                    </Card>
                </div>
            </Drawer>



            {
                abrirFichaLubricante ?
                    <FichaLubricantesERP
                        title={traducirPagina("Catalogo_de_lubricantes").toUpperCase()}
                        ABRIR_FICHA={abrirFichaLubricante}
                        CERRAR_FICHA={setAbrirFichaLubricante}
                        TIPO={"GENERICO"}
                        VISCOSIDAD={viscosiidad}
                        CALIDAD={calidad}
                    >
                    </FichaLubricantesERP>
                    :
                    null
            }

        </div>
    )
}

FichaLubricantes.propTypes = {
    config: PropTypes.object,
    detallesReferencia: PropTypes.object,
    masInformacion: PropTypes.object,
    fichaAbierta: PropTypes.bool,
    activeItemOuterTabs: PropTypes.string,
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        openDrawerFichaLubricantes: state.estadoDrawer.openDrawerFichaLubricantes,
        codmotorizacion: state.codmotorizacion

    };
}

export default connect(mapStateToProps)(FichaLubricantes);