import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRipple } from "../action/actionCreators"
import { MDBRow, MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from 'mdbreact';
import {
    SearchOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import store from "../store";
import { getMotorizacionesByReferenciaAndModelo, getIdTecdocMotorByIdHaynes, setHistorialBusqueda } from "../action/actionCreatorsRequests"
import { gestionarContenedorCoche, estadoAgrupacionDeMarcas, showChildrenDrawerLevel2, showItemReferencia, setHistoricApp, updateVisualizacionFiltro } from "../action/actionCreators"
import { traducirPagina, limpiarCocheStore, limpiarGrupoDrawer,efectoRemoveBuscadorReferencia } from '../librerias/funciones';



class tablaGenerica extends Component {
    state = {
        propiedadesMasInfo: {},
        listaFiltro: {}
    }

    componentDidMount(prevProps, prevState) {
        store.subscribe(() => {
            this.setState({
                propiedadesMasInfo: store.getState().masInformacion,
                listaFiltro: this.props.tipoTabla === "MODELOS" ? store.getState().masInformacion.masInfoModels : store.getState().masInformacion.masInfoMotorizacion,
            })
        })
    }

    aplicarFiltro = (e) => {
        let texto = e.target.value.toUpperCase();
        let lista = [];
        if (this.props.tipoTabla === "MODELOS") {
            this.state.propiedadesMasInfo.masInfoModels.map((item) => {
                if (item.MODELO.toUpperCase().includes(texto)) {
                    lista.push(item)
                }
            })

            this.setState({ listaFiltro: lista.length > 0 ? lista : this.state.propiedadesMasInfo.masInfoModels })
        } else {
            this.state.propiedadesMasInfo.masInfoMotorizacion.map((item) => {
                if (item.DESCRIPCION.toUpperCase().includes(texto)) {
                    lista.push(item)
                }
            })

            this.setState({ listaFiltro: lista.length > 0 ? lista : this.state.propiedadesMasInfo.masInfoMotorizacion })
        }
    }
    render() {
        return (
            <div>
                {this.props.tipoTabla === "MODELOS" ?
                    Object.keys(this.state.propiedadesMasInfo).length > 0 ?
                        Object.keys(this.state.listaFiltro).length > 0 ?
                            <div className="mdb-skin">
                                <div className="mt-3 pb-2">
                                    <div className="grid-container" style={{ backgroundColor: '#fff', marginTop: '2px' }}>
                                        <div className="grid-item">
                                            <input id="buscadorMasInfoModelo" className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_por_modelo")} style={{ backgroundColor: "#fff", width: '580px', border: '0px', height: '100%', color: '#000' }} autoComplete="off" onChange={(e) => { this.aplicarFiltro(e) }} />
                                        </div>

                                        <div className="grid-item" style={{ backgroundColor: '#fff' }}>
                                            <MDBBtn id="closeMasInfoModelo" onClick={e => { createRipple(e); }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: 'hidden' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                        </div>

                                        <div className="grid-item barraLateral" ></div>

                                        <div className="grid-item" style={{ backgroundColor: '#fff' }}>
                                            <MDBBtn onClick={e => { createRipple(e); this.buscarReferencia() }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matriculav2">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                        </div>
                                    </div>
                                    <button id='btnVolverFabricante ' className="activebtnMenu3 btnColor" style={{ height: '40px', width: '150px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#fff' }} onClick={() => {
                                        if (document.getElementById("maInfoModelos") != null) {
                                            document.getElementById("maInfoModelos").style.display = "none";
                                        }
                                        if (document.getElementById("maInfoMarcas") != null) {
                                            document.getElementById("maInfoMarcas").style.display = ""
                                        }
                                        if (document.getElementById("maInfo2Modelos") != null) {
                                            document.getElementById("maInfo2Modelos").style.display = "none";
                                        }
                                        if (document.getElementById("maInfoMarcas2") != null) {
                                            document.getElementById("maInfoMarcas2").style.display = ""
                                        }

                                    }}> <span>{traducirPagina("Volver_al_fabricante")} </span>  </button>
                                </div>

                                <MDBTable hover>
                                    <MDBTableHead>
                                        <tr>
                                            <th style={{ textAlign: 'left' }}>{traducirPagina("Modelo")}</th>
                                            {/*
                                        <th>Desde</th>
                                        <th>Hasta</th>
                                        */}
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {this.state.listaFiltro.map((row, i) =>
                                            <tr key={row.ID} id={row.ID} style={{ padding: '.40rem 1.20rem', cursor: 'pointer' }} onClick={() => {
                                                if (document.getElementById("maInfoMotorizacion") != null) {
                                                    document.getElementById("maInfoMotorizacion").style.display = "";
                                                }
                                                if (document.getElementById("maInfoMotorizacion2") != null) {
                                                    document.getElementById("maInfoMotorizacion2").style.display = "";
                                                }
                                                if (document.getElementById("maInfoModelos") != null) {
                                                    document.getElementById("maInfoModelos").style.display = "none";
                                                }
                                                if (document.getElementById("maInfo2Modelos") != null) {
                                                    document.getElementById("maInfo2Modelos").style.display = "none";
                                                }

                                                store.dispatch(getMotorizacionesByReferenciaAndModelo(this.props.detalles.REFERENCIA, this.props.detalles.CODIGO_MARCA, row.ID));
                                                store.dispatch({ type: "REPLACE_VEHICULO_IDMODELO", idmodelo: row })
                                            }} >
                                                <td style={{ textAlign: 'left' }}>{row.MODELO}</td>
                                                {/*
                                            <td>{row.DESDE === "" ? "..." : row.DESDE}</td>
                                            <td>{row.HASTA === "" ? "..." : row.HASTA}</td>
                                             */}
                                            </tr>
                                        )}
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                            : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}>  <div className="spinner-border  fast colorSubAzul" role="status" disabled /></div>
                        : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border  fast colorSubAzul" role="status" disabled /> </div>
                    :
                    Object.keys(this.state.propiedadesMasInfo).length > 0 ?
                        Object.keys(this.state.listaFiltro).length > 0 ?
                            <div className="mdb-skin">
                                <div className="mt-3 pb-2">
                                    <div className="grid-container" style={{ backgroundColor: '#fff', marginTop: '2px' }}>
                                        <div className="grid-item">
                                            <input id="buscadorMasInfoMotorizacion" className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_por_motorizacion")} style={{ backgroundColor: "#fff", width: '585px', border: '0px', height: '100%', color: '#000' }} autoComplete="off" onChange={(e) => { this.aplicarFiltro(e) }} />
                                        </div>

                                        <div className="grid-item" style={{ backgroundColor: '#fff' }}>
                                            <MDBBtn id="closeMasInfoMotorizacion" onClick={e => { createRipple(e); }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: 'hidden' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                        </div>

                                        <div className="grid-item barraLateral" ></div>

                                        <div className="grid-item" style={{ backgroundColor: '#fff' }}>
                                            <MDBBtn onClick={e => { createRipple(e); this.buscarReferencia() }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matriculav2">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                        </div>
                                    </div>
                                    <button id='btnVolverModelo' className="activebtnMenu3 btnColor" style={{ height: '40px', width: '130px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#fff', backgroundColor: '#4285f4' }} onClick={() => {
                                        if (document.getElementById("maInfoModelos") != null) {
                                            document.getElementById("maInfoModelos").style.display = "";
                                        }
                                        if (document.getElementById("maInfoMotorizacion") != null) {
                                            document.getElementById("maInfoMotorizacion").style.display = "none";

                                        }
                                        if (document.getElementById("maInfo2Modelos") != null) {
                                            document.getElementById("maInfo2Modelos").style.display = "";
                                        }
                                        if (document.getElementById("maInfoMotorizacion2") != null) {
                                            document.getElementById("maInfoMotorizacion2").style.display = "none";
                                        }

                                    }}> <span> {traducirPagina("Volver_al_modelo")}</span>  </button>
                                </div>

                                <MDBTable hover>
                                    <MDBTableHead>
                                        <tr>
                                            <th style={{ textAlign: 'left' }}>{traducirPagina("Motor")}</th>
                                            <th style={{ textAlign: 'left' }}>{traducirPagina("Codigo_cliente")}</th>
                                            <th>{traducirPagina("Año")}</th>
                                            <th>KW</th>
                                            <th>CV</th>
                                            <th>CC</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {this.state.listaFiltro.map((row, i) =>
                                            <tr key={row.ID} id={row.ID} style={{ padding: '.40rem 1.20rem', cursor: 'pointer' }} onClick={(e) => {
                                                //limpiarCocheStore();
                                                efectoRemoveBuscadorReferencia("buscadorReferencia", true);
                                                store.dispatch({ type: "STATE_SEARCH_COCHE", buscadorCoche: {} })
                                                store.dispatch(estadoAgrupacionDeMarcas({ marcas: [] }));
                                                store.dispatch(showChildrenDrawerLevel2(false));
                                                store.dispatch(getIdTecdocMotorByIdHaynes(row.ID,row));
                                                
                                                store.dispatch({ type: "SET_TIPO_COMBUSTIBLE", TIPO_COMBUSTIBLE: row.TIPO_COMBUSTIBLE,})


                                                store.dispatch(showItemReferencia("tree"))
                                                let marca = {
                                                    ID: this.props.fichaBusquedaVehiculo.idmarca.ID,
                                                    DESCRIPCION: this.props.fichaBusquedaVehiculo.idmarca.FABRICANTE.substring(0, this.props.fichaBusquedaVehiculo.idmarca.FABRICANTE.length > 7 ? 7 : this.props.fichaBusquedaVehiculo.idmarca.FABRICANTE.length) + " ..."
                                                }

                                                let modelo = {
                                                    ID: this.props.fichaBusquedaVehiculo.idmodelo.ID,
                                                    DESCRIPCION: this.props.fichaBusquedaVehiculo.idmodelo.MODELO.substring(0, this.props.fichaBusquedaVehiculo.idmodelo.MODELO.length > 7 ? 7 : this.props.fichaBusquedaVehiculo.idmodelo.MODELO.length) + " ..."
                                                }

                                                let objetoVehiculo = {
                                                    ID: row.ID,
                                                    DESCRIPCION: row.DESCRIPCION.substring(0, row.DESCRIPCION.length > 12 ? 12 : row.DESCRIPCION.length) + " ..."
                                                };

                                                store.dispatch(setHistoricApp(marca, "/allbrands", "brand"));
                                                store.dispatch(setHistoricApp(modelo, "/modelos", "modelo"));
                                                store.dispatch(setHistoricApp(objetoVehiculo, "/motorizacion", "tipomotor"));
                                                gestionarContenedorCoche(true);
                                                store.dispatch(updateVisualizacionFiltro(true));

                                                setHistorialBusqueda({
                                                    MARCA: this.props.fichaBusquedaVehiculo.idmarca.ID,
                                                    MODELO: this.props.fichaBusquedaVehiculo.idmodelo.ID,
                                                    MOTORIZACION: row.ID,
                                                    NOMBRE_MARCA: this.props.fichaBusquedaVehiculo.idmarca.FABRICANTE,
                                                    NOMBRE_MODELO: this.props.fichaBusquedaVehiculo.idmodelo.MODELO,
                                                    NOMBRE_MOTOR: row.DESCRIPCION
                                                })
                                                
                                                limpiarGrupoDrawer()

                                            }}>
                                                <td style={{ textAlign: 'left' }}>{row.DESCRIPCION}</td>
                                                <td style={{ textAlign: 'left' }}>{row.MOTORES === "" ? "..." : row.MOTORES}</td>
                                                <td>{row.DESDE === "" ? "..." : row.DESDE}</td>

                                                <td>{row.KW === "" ? "..." : row.KW}</td>
                                                <td>{row.CV === "" ? "..." : row.CV}</td>
                                                <td>{row.CC === "" ? "..." : row.CC}</td>
                                            </tr>
                                        )}
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                            : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}>  <div className="spinner-border  fast colorSubAzul" role="status" disabled /></div>
                        : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border  fast colorSubAzul" role="status" disabled /> </div>

                }
            </div>
        )
    }
}

tablaGenerica.propTypes = {
    onClick: PropTypes.func,
    fichaBusquedaVehiculo: PropTypes.object,
    tipoTabla: PropTypes.string
};




export default tablaGenerica;