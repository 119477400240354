import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
    MDBBtn, MDBTableBody, MDBTable, MDBTableHead
} from 'mdbreact';
import Lightbox from "react-image-lightbox";

import { onChildrenDrawerCloseIntegraciones } from "../action/actionCreators";
import store from "../store";
import { createRipple } from "../action/actionCreators";

import { MSGERROR,traducirPagina } from "../librerias/funciones"
import {
    LikeOutlined,
    DislikeOutlined,

} from '@ant-design/icons';

const mapDispatchToProps = (dispatch, props) => {
    return {
        onClick: () => {
            /*
                        if (dispatch(validateBeforeSubmit(props)) !== true) return;
                        if (typeof props.antesDe === 'function') {
                            if (dispatch(props.antesDe(props)) === false) return;
                        }
                        dispatch(desbloquearRegistro(props, ACCIONES_GRID.UPDATE)).then(
                            json => {
                                if (json.ok) {
                                    if (typeof props.despuesDe === 'function') {
                                        dispatch(props.despuesDe(props));
                                    }
                                    if (typeof props.after === 'function') {
                                        props.after(json);
                                    }
                                }
                            }
                        );
            */
        }
    };
};


class IframeIntegraciones extends Component {
    state = {
        activeItemOuterTabs: "1"
    }

    componentDidUpdate(prevProps, prevState) {

        let comparar = '1'
        let verificarEstadoFicha = this.props.fichaAbierta === undefined ? false : this.props.fichaAbierta;
        if (!verificarEstadoFicha) {


            if (prevState && prevState.activeItemOuterTabs !== comparar) {
                this.setState({
                    activeItemOuterTabs: comparar
                });
            }


            var iframes = document.querySelectorAll("iframe");
            //var frame = document.getElementById(this.props.id);
            if (iframes != undefined) {
                //frame.parentNode.removeChild(frame);
                /*
                for (var i = 0; i < iframes.length; i++) {
                    iframes[i].parentNode.removeChild(iframes[i]);
                }
*/
            }

        }
    }

    render() {
        if (this.props.id === "haynespro") {
            let fichaCompletaCoche = this.props.fichaBusquedaVehiculo
            if (fichaCompletaCoche.historicapp.length === 3) {
                setTimeout(() => {
                    if (fichaCompletaCoche.codigoHaynesPro !== "") {
                        if (document.getElementById("haynespro") !== null) {
                            document.getElementById("haynespro").src = "https://www.workshopdata.com/touch/site/layout/modelDetail?typeId=t_" + fichaCompletaCoche.codigoHaynesPro;
                        }
                    } else {
                        // MSGERROR("No se pudo hacer la converssión de Tecdoc a HayensPro")
                    }
                }, 100)
            }
        }

        return (
            <div className={this.props.tipoComponente === "masInformacion" ? "" : "estiloDrawerHeader"}>
                <div>
                    <div className="flex-container-Grow-Tercero" style={{ width: this.props.tipoComponente === "masInformacion" ? "900px" : this.props.tipoComponente === "tableStock" ? "600px" : (this.props.config.width - 360).toString() + "px" }}>
                        <div style={{ textAlign: 'inherit' }}>
                            <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { store.dispatch(onChildrenDrawerCloseIntegraciones(false)); createRipple(e) }} >
                                <i aria-label="icon: close" className="anticon anticon-close">
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                    </svg>
                                </i>
                            </MDBBtn>
                        </div>

                        <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                            {this.props.title}
                        </div>
                    </div>
                </div>

                {(() => {
                    switch (this.props.tipoComponente) {
                        case 'iframe':
                            return this.props.url === "" ? 
                              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div style={{ color: 'black' }} color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                            :
                            <iframe
                                id={this.props.id}
                                width="100%"
                                height={(this.props.config.height - 60).toString() + "px"}
                                title={this.props.title}
                                frameBorder="0"
                                allowtransparency="true"
                                src={this.props.url}
                            />
                        
                    }
                })()}
            </div>
        );
    }
}

IframeIntegraciones.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    onClick: PropTypes.func,
    config: PropTypes.object,
    tipoComponente: PropTypes.string,
    detallesReferencia: PropTypes.object,
    fichaAbierta: PropTypes.bool,
    masInformacion: PropTypes.object,
    fichaBusquedaVehiculo: PropTypes.object
};


export default IframeIntegraciones;