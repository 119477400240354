import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBNav, MDBNavItem, MDBCol } from 'mdbreact';
import 'antd/dist/antd.css';
import { Drawer, Tabs, Col, Layout, Card, Select, Row, Tree, Image } from 'antd';
import { getStockDesglose, setCestaLineasEnAPI, ObtenerBateriasERPEnAPI, ObtenerMarcasIQEnAPI, BusquedaUniversalEnAPI, ObtenerCategoriasEnAPI, ObtenerGraficosEnAPI, ObtenerListaDetalleRapidoEnAPI } from "../action/actionCreatorsRequests";
import MantenimientoVehiculoYQ from './MantenimientoVehiculoYQ';
import {
    createRipple
} from "../action/actionCreators";
import { addDefaultSrc } from "../librerias/herramientas.js"
import {
    traducirPagina, filtroGenericoTree, MSGOK, crearListaKeysCategoriaYQ, recortarCadena, crearListaKeysGenerico, dynamicSortAsc,
    filtroGenericoCategorias, obtenerPathNodoGrupos
} from "../librerias/funciones"
import FichaMasInfoYQ from './FichaMasInfoYQ';
import VisorDocumentos from '../components/VisorDocumentos'
import FichaDatosCocheYQ from './FichaDatosCocheYQ';

import store from '../store'
import {
    CloseOutlined,
    SearchOutlined,
    FieldTimeOutlined
} from '@ant-design/icons';

const { Content } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;
const { DirectoryTree } = Tree;
const { Meta } = Card;


const FichaYQ = ({ props, configApp }) => {
    const [hayReferencias, setHayReferencias] = React.useState(false);
    const [abrirFichaVeh, setAbrirFichaVeh] = React.useState(false);

    const [abrirFichaCocheIQ, setAbrirFichaCocheIQ] = React.useState(false);
    const [registroCocheIQ, setRegistroCocheIQ] = React.useState([]);


    const [loaderGrafico, setLoaderGrafico] = React.useState(false);
    const [cocheSeleccionado, setCocheSeleccionado] = React.useState({});
    const [arbolUniversal, setArbolUniversal] = React.useState([]);
    const [arbolCategoria, setArbolCategoria] = React.useState([]);
    const [tabPorDefecto, setTabPorDefecto] = React.useState("2");
    const [abrirFichaMasInfo, setAbrirFichaMasInfo] = React.useState(false);
    const [graficoActual, setGraficoActual] = React.useState({});
    const [listaGraficosSeleccionado, setListaGraficosSeleccionado] = React.useState({});
    const [graficos, setGraficos] = React.useState([]);


    const [abrirVisor, setAbrirVisor] = React.useState(false);
    const [documentoSeleccionado, setDocumentoSeleccionado] = React.useState({});

    const [marcaSeleccionada, setMarcaSeleccionada] = React.useState({});
    const [abrirFichaInfoCoche, setAbrirFichaInfoCoche] = React.useState(false);

    const [txtBuscadorArbol, setTxtBuscadorArbol] = React.useState("");

    const [listaUniversal, setListaUniversal] = React.useState([]);
    const [listaCategoria, setListaCategoria] = React.useState([]);

    const [registroFiltroUniversal, setregistroFiltroUniversal] = React.useState({});
    const [registroFiltroCategoria, setregistroFiltroCategoria] = React.useState({});




    const limpiarCoche = () => {
        //LIMPIAR_COCHE
        setCocheSeleccionado({});
        setAbrirFichaVeh(true);
        setArbolUniversal([])
        setArbolCategoria([])
        setGraficos([])
    }

    const resetOEM = () => {
        setLoaderGrafico(false);
        setCocheSeleccionado({});
        setArbolUniversal([]);
        setArbolCategoria([]);
        setTabPorDefecto("2");
        setAbrirFichaMasInfo(false);
        setGraficoActual({});
        setListaGraficosSeleccionado({});
        setGraficos([]);


        setAbrirVisor(false);
        setDocumentoSeleccionado({});
        setMarcaSeleccionada({});
        setAbrirFichaInfoCoche(false);
        setTxtBuscadorArbol("");

        setListaUniversal([]);
        setListaCategoria([]);

        setregistroFiltroUniversal({});
        setregistroFiltroCategoria({});
    }

    const BusquedaUniversal = (ID_VEHICULO, SSD, MARCA) => {
        setArbolUniversal([])

        BusquedaUniversalEnAPI(ID_VEHICULO, SSD, MARCA).then(registros => {
            if (registros !== undefined) {
                if (registros.RESULTADOS[0] !== null && registros.RESULTADOS.length > 0) {
                    setArbolUniversal(registros.RESULTADOS)

                    setListaUniversal(registros.LISTADO)
                } else {
                    setArbolUniversal(null)

                    setListaUniversal(null)

                }
            }
        })
    }


    const ObtenerCategorias = (ID_VEHICULO, SSD, MARCA) => {
        setArbolCategoria([])
        ObtenerCategoriasEnAPI(ID_VEHICULO, SSD, MARCA).then(registros => {
            if (registros !== undefined) {
                if (registros.CATEGORIAS !== null && registros.CATEGORIAS.length > 0) {
                    setArbolCategoria(registros.CATEGORIAS)

                    setListaCategoria(registros.LISTA_CATEGORIAS)


                } else {
                    setArbolCategoria(null)
                    setListaCategoria(null)

                }
            }
        })
    }


    const ObtenerGraficosPorCategoria = (ID_VEHICULO, ID_CATEGORIA, SSD, MARCA) => {
        setGraficos([])
        ObtenerGraficosEnAPI(ID_VEHICULO, ID_CATEGORIA, SSD, MARCA).then(registros => {
            if (registros !== undefined) {
                if (registros.UNIDADES !== null && registros.UNIDADES.length > 0) {
                    setListaGraficosSeleccionado(registros.UNIDADES)

                    setGraficos(registros.UNIDADES)
                } else {
                    setGraficos(null)
                }
            }
        })
    }

    const ObtenerGraficosUniversal = (ID_VEHICULO, ID_GRUPO, SSD, MARCA) => {
        setGraficos([])
        ObtenerListaDetalleRapidoEnAPI(ID_VEHICULO, ID_GRUPO, SSD, MARCA).then(registros => {
            if (registros !== undefined) {
                if (registros.UNIDADES !== null && registros.UNIDADES.length > 0) {
                    setListaGraficosSeleccionado(registros.UNIDADES)

                    setGraficos(registros.UNIDADES)
                } else {
                    setGraficos(null)
                }
                /*
                if (registros.RESULTADOS !== null && registros.RESULTADOS.length > 0) {
                    setListaGraficosSeleccionado(registros.RESULTADOS[0])

                    setGraficos(registros.RESULTADOS[0].UNIDADES)
                } else {
                    setGraficos(null)
                }
                */
            }
        })
    }


    if (!hayReferencias && props.ABRIR_FICHA) {
        setHayReferencias(true);
        if (Object.keys(cocheSeleccionado).length === 0 || props.LIMPIAR_COCHE_ANTERIOR_OEM) {
            setTimeout(() => {
                setAbrirFichaVeh(true)
                resetOEM();
            }, 500)
        }


    } else {
        if (!props.ABRIR_FICHA && hayReferencias) {
            setHayReferencias(false);
            setLoaderGrafico(false);
            /*
                ABRIR_MODAL_OEM: true,
                    LIMPIAR_COCHE_ANTERIOR_OEM: false,
            */
            props.CERRAR.setState(
                {
                    COCHE_SELECCIONADO_OEM: cocheSeleccionado,
                    LIMPIAR_COCHE_ANTERIOR_OEM: false,
                }
            )

        }
    }


    /*

    if (props.LIMPIAR_COCHE_ANTERIOR_OEM && !abrirFichaVeh) {
        setAbrirFichaVeh(true);
        //setHayReferencias(false);
        //setAbrirFichaVeh(false);
        setLoaderGrafico(false);
        setCocheSeleccionado({});
        setArbolUniversal([]);
        setArbolCategoria([]);
        setTabPorDefecto("2");
        setAbrirFichaMasInfo(false);
        setGraficoActual({});
        setListaGraficosSeleccionado({});
        setGraficos([]);


        setAbrirVisor(false);
        setDocumentoSeleccionado({});
        setMarcaSeleccionada({});
        setAbrirFichaInfoCoche(false);
        setTxtBuscadorArbol("");

        setListaUniversal([]);
        setListaCategoria([]);

        setregistroFiltroUniversal({});
        setregistroFiltroCategoria({});

    }
    */




    const gestionarFichas = (estado) => {
        if (Object.keys(cocheSeleccionado).length === 0) {
            setAbrirFichaVeh(estado)

            if (props.TIPO === "MASTER") {
                props.CERRAR.setState({ ABRIR_FICHA_YQ: false })
            } else {
                props.CERRAR_FICHA(false)
            }

        }
    }

    const actualizarCocheSeleccionado = (registro) => {
        setCocheSeleccionado(registro)
        setAbrirFichaVeh(false)

        BusquedaUniversal(registro.ID_VEHICULO, registro.SSD, registro.MARCA)
        ObtenerCategorias(registro.ID_VEHICULO, registro.SSD, registro.MARCA)
    }



    const loop = (data) => data.map(item => {
        const title = (
            item.NODOS.length > 0 ?
                <span style={{ fontSize: '16px' }} onClick={() => {
                    setLoaderGrafico(true)
                    ObtenerGraficosUniversal(cocheSeleccionado.ID_VEHICULO, crearListaKeysGenerico(item, data), cocheSeleccionado.SSD, cocheSeleccionado.MARCA)
                    // console.log("nodos",  crearListaKeysGenerico(item,data))

                }}> {item.NOMBRE}</span>
                :
                <span style={{ fontSize: '16px', display: 'flex' }} onClick={() => {
                    setLoaderGrafico(true)
                    ObtenerGraficosUniversal(cocheSeleccionado.ID_VEHICULO, item.ID_GRUPO_RAPIDO, cocheSeleccionado.SSD, cocheSeleccionado.MARCA)
                }}>
                    {item.NOMBRE}
                </span>
        );

        if (item.NODOS) {
            return { title, key: item.ID_GRUPO_RAPIDO, children: loop(item.NODOS) };
        }

        return { title, key: item.ID_GRUPO_RAPIDO, isLeaf: true };
    });

    const loopCategoria = (data) => data.map(item => {
        const title = (
            item.TIENE_NODOS ?
                <span style={{ fontSize: '16px', display: 'flex' }} onClick={() => {
                    setLoaderGrafico(true)
                    ObtenerGraficosPorCategoria(cocheSeleccionado.ID_VEHICULO, crearListaKeysCategoriaYQ(item, data), item.SSD, cocheSeleccionado.MARCA)

                }}>
                    {item.NOMBRE}
                </span>
                :
                <span style={{ fontSize: '16px' }} onClick={() => {
                    setLoaderGrafico(true)
                    ObtenerGraficosPorCategoria(cocheSeleccionado.ID_VEHICULO, item.ID_CATEGORIA, item.SSD, cocheSeleccionado.MARCA)

                }}> {item.NOMBRE}</span>
        );

        if (item.TIENE_NODOS) {
            //let padre = item.ID_CATEGORIA_PADRE;
            //let enviarLista = data.filter((registro) => registro.ID_CATEGORIA_PADRE ===  padre);
            return { title, key: item.ID_CATEGORIA, children: loopCategoria(item.HIJOS) };
        }

        return { title, key: item.ID_CATEGORIA, isLeaf: true };
    });

    const masInformacionFicha = (registro, listaRegistros) => {
        //console.log("info", registro);
        setGraficoActual(registro)
        //setListaGraficosSeleccionado(listaRegistros)
        setAbrirFichaMasInfo(true)

        let VEHICULO = store.getState().VEHICULO_OEM;
        const {MARCA,NOMBRE} = cocheSeleccionado
        VEHICULO.MARCA = Object.keys(marcaSeleccionada).length > 0 ? marcaSeleccionada.NOMBRE : MARCA
        VEHICULO.MODELO = NOMBRE

        store.dispatch({ type: 'STATE_VEHICLE_OEM', VEHICULO_OEM: VEHICULO })
    }




    return (
        <div>
            <Drawer
                width={"90%"}
                onClose={() => {
                    if (props.TIPO === "MASTER") {
                        props.CERRAR.setState({ ABRIR_FICHA_YQ: false })
                    } else {
                        props.CERRAR_FICHA(false)
                    }


                    store.dispatch({ type: 'STATE_VEHICLE_OEM', VEHICULO_OEM: {} })

                }}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                closable={false}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>

                <div className="flex-container-Grow-Tercero">
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                            if (props.TIPO === "MASTER") {
                                props.CERRAR.setState({ ABRIR_FICHA_YQ: false })
                            } else {
                                props.CERRAR_FICHA(false)
                            }

                            store.dispatch({ type: 'STATE_VEHICLE_OEM', VEHICULO_OEM: {} })
                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>

                <div>
                    <Content>

                        <div className="flex-container p-2" style={{ backgroundColor: '#cdcdcd' }} >

                            <div className="flex-item-left" style={{ textAlign: 'left', padding: '0px', flex: '40%', display: 'flex', alignItems: 'center' }}>
                                <div style={{ padding: '4px', color: '#000', fontSize: '16px', fontWeight: '500' }}>
                                    {Object.keys(cocheSeleccionado).length ?
                                        <div className="grid-container mdb-skin" >

                                            <div className="grid-item" style={{ cursor: 'pointer' }}>
                                                <div className="text">{Object.keys(cocheSeleccionado).length > 0 ? marcaSeleccionada.NOMBRE : ""}</div>
                                            </div>

                                            <div className="grid-item" style={{ cursor: 'pointer' }}>
                                                <div className="text">{cocheSeleccionado.NOMBRE}</div>
                                            </div>

                                            <div className="grid-item">
                                                <MDBBtn onClick={e => {
                                                    setCocheSeleccionado({});
                                                    setAbrirFichaVeh(true);
                                                    setArbolUniversal([])
                                                    setArbolCategoria([])
                                                    setGraficos([])

                                                }}
                                                    className="btn-matricula  estiloBotonRemove"> <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} /> </MDBBtn>
                                            </div>
                                            <div className="grid-item barraLateral"></div>

                                            <div className="grid-item">
                                                <MDBBtn onClick={e => {
                                                    createRipple(e);
                                                    setAbrirFichaInfoCoche(true)
                                                }} style={{ border: '0', padding: '6px 6px 5px 7px', margin: '0', boxShadown: 'none', backgroundColor: 'transparent !important' }} className="btn-matricula">  <img src="./imagenes/cocheGris.svg" style={{ height: '26px', color: 'rgb(102, 102, 102)', cursor: 'pointer', fontSize: '20px' }} className="" alt={"coche"} ></img> </MDBBtn>
                                            </div>
                                        </div>
                                        :
                                        null}
                                </div>

                            </div>


                            <div className="flex-item-right" style={{ textAlign: 'right', padding: '0px', flex: '90%' }}>

                            </div>
                        </div>

                        <div>
                            <Col span={6} style={{ backgroundColor: '#fff', padding: '10px' }}>
                                <div className="ml-1 mr-1">
                                    <Tabs activeKey={tabPorDefecto} onChange={(e) => {
                                        setTabPorDefecto(e)
                                        setGraficos([])
                                        setLoaderGrafico(false)
                                        setTxtBuscadorArbol("")
                                        setregistroFiltroUniversal(undefined)

                                    }}>

                                        <TabPane tab={traducirPagina("Categorías")} key="1">

                                            <div className="grid-container mdb-skin" >
                                                <div className="grid-item">
                                                    <input className="form-control boxCoche" type="text" aria-label="Search" placeholder={traducirPagina("Ingrese_categoria")} value={txtBuscadorArbol} style={{ backgroundColor: "#eee", width: '260px', border: '0' }} onChange={(e) => {
                                                        setTxtBuscadorArbol(e.target.value.trim().length > 0 ? e.target.value : "");
                                                        let registros = filtroGenericoCategorias(e.target.value, listaCategoria);
                                                        setregistroFiltroCategoria(registros)
                                                        /*efectoBotonRemoveLocal("busquedaArbol", "closeBusquedaArbol", "ARBOL"); filtroTree(e.target.value); setTextoArbol(e.target.value)*/
                                                    }} autoComplete="off" />
                                                </div>

                                                <div className="grid-item">
                                                    <MDBBtn id="closeBusquedaArbol" onClick={e => {
                                                        setTxtBuscadorArbol("");
                                                        setregistroFiltroCategoria(undefined)

                                                        /*createRipple(e); efectoBotonRemoveLocal("", "closeBusquedaArbol", "ARBOL")*/
                                                    }} className="btn-matricula  estiloBotonRemove"
                                                        style={{ visibility: txtBuscadorArbol !== "" ? "visible" : 'hidden' }}> <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} /> </MDBBtn>
                                                </div>

                                                <div className="grid-item barraLateral" ></div>

                                                <div className="grid-item">
                                                    <MDBBtn onClick={e => { /*createRipple(e); filtroTree(textoArbol);*/ }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none', backgroundColor: 'transparent !important' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                                </div>
                                            </div>


                                            {registroFiltroCategoria !== undefined ?
                                                <div className="containerFilroTree" style={{ height: "200px", overflow: 'auto', backgroundColor: '#f7f7f7', display: registroFiltroCategoria.openFiltroTree ? '' : 'none', position: 'fixed', width: '30%' }}>
                                                    <ul className="ulTree">
                                                        {registroFiltroCategoria.openFiltroTree ?
                                                            registroFiltroCategoria.registrosFiltro.map((registro, i) =>
                                                                <li className="li_tree" style={{ paddingBottom: '5px', color: '#666', cursor: 'pointer' }} key={registro[registro.length - 1].ID_CATEGORIA} onClick={() => {
                                                                    setLoaderGrafico(true)
                                                                    ObtenerGraficosPorCategoria(cocheSeleccionado.ID_VEHICULO, registro[registro.length - 1].ID_CATEGORIA, registro[registro.length - 1].SSD, cocheSeleccionado.MARCA)
                                                                    registroFiltroCategoria.openFiltroTree = false;
                                                                }}>{
                                                                        obtenerPathNodoGrupos(registro)
                                                                    }
                                                                </li>
                                                            )
                                                            :
                                                            <li className="li_tree">{traducirPagina("No_resultados")}</li>
                                                        }
                                                    </ul>
                                                </div>
                                                : null
                                            }


                                            <div style={{ height: (configApp.height - 265) + "px", overflow: 'auto', overflowX: 'hidden', marginTop: '16px' }}>

                                                {arbolCategoria === null ?
                                                    null
                                                    :
                                                    arbolCategoria.length > 0 ?
                                                        <DirectoryTree showIcon={false} multiple treeData={loopCategoria(arbolCategoria.sort(dynamicSortAsc("NOMBRE")))} />
                                                        :


                                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                                }
                                            </div>
                                        </TabPane>


                                        <TabPane tab={traducirPagina("Grupos")} key="2">

                                            <div className="grid-container mdb-skin" >
                                                <div className="grid-item">
                                                    <input className="form-control boxCoche" type="text" aria-label="Search" placeholder={traducirPagina("Ingrese_un_grupo")} value={txtBuscadorArbol} style={{ backgroundColor: "#eee", width: '260px', border: '0' }} onChange={(e) => {
                                                        setTxtBuscadorArbol(e.target.value.trim().length > 0 ? e.target.value : "");
                                                        let registros = filtroGenericoTree(e.target.value, listaUniversal);
                                                        setregistroFiltroUniversal(registros)

                                                        /*efectoBotonRemoveLocal("busquedaArbol", "closeBusquedaArbol", "ARBOL"); filtroTree(e.target.value); setTextoArbol(e.target.value)*/
                                                    }} autoComplete="off" />
                                                </div>

                                                <div className="grid-item">
                                                    <MDBBtn id="closeBusquedaArbol" onClick={e => {
                                                        setTxtBuscadorArbol("");
                                                        setregistroFiltroUniversal(undefined)

                                                        /*createRipple(e); efectoBotonRemoveLocal("", "closeBusquedaArbol", "ARBOL")*/
                                                    }} className="btn-matricula  estiloBotonRemove"
                                                        style={{ visibility: txtBuscadorArbol !== "" ? "visible" : 'hidden' }}> <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} /> </MDBBtn>
                                                </div>

                                                <div className="grid-item barraLateral" ></div>

                                                <div className="grid-item">
                                                    <MDBBtn onClick={e => { /*createRipple(e); filtroTree(textoArbol);*/ }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none', backgroundColor: 'transparent !important' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                                </div>
                                            </div>



                                            {registroFiltroUniversal !== undefined ?
                                                <div className="containerFilroTree" style={{ height: "200px", overflow: 'auto', backgroundColor: '#f7f7f7', display: registroFiltroUniversal.openFiltroTree ? '' : 'none', position: 'fixed', width: '30%' }}>
                                                    <ul className="ulTree">
                                                        {registroFiltroUniversal.openFiltroTree ?
                                                            registroFiltroUniversal.registrosFiltro.map((registro, i) =>
                                                                <li className="li_tree" style={{ paddingBottom: '5px', color: '#666', cursor: 'pointer' }} key={registro[registro.length - 1].ID_GRUPO_RAPIDO} onClick={() => {
                                                                    setLoaderGrafico(true)
                                                                    ObtenerGraficosUniversal(cocheSeleccionado.ID_VEHICULO, registro[registro.length - 1].ID_GRUPO_RAPIDO, cocheSeleccionado.SSD, cocheSeleccionado.MARCA)
                                                                    registroFiltroUniversal.openFiltroTree = false;

                                                                }}>{
                                                                        obtenerPathNodoGrupos(registro)
                                                                    }
                                                                </li>
                                                            )
                                                            :
                                                            <li className="li_tree">{traducirPagina("No_resultados")}</li>
                                                        }
                                                    </ul>
                                                </div>
                                                : null
                                            }
                                            <div style={{ height: (configApp.height - 265) + "px", overflow: 'auto', overflowX: 'hidden', marginTop: '16px' }}>
                                                {arbolUniversal === null ?
                                                    null
                                                    :
                                                    arbolUniversal.length > 0 ?
                                                        <DirectoryTree showIcon={false} multiple treeData={loop(arbolUniversal[0].NODOS.sort(dynamicSortAsc("NOMBRE")))} />
                                                        :

                                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                                }
                                            </div>

                                        </TabPane>
                                    </Tabs>
                                </div>
                            </Col>

                            <Col span={18} style={{ padding: '10px', height: (configApp.height - 135) + "px", overflow: 'auto', overflowX: 'hidden' }}>
                                <div>
                                    {graficos === null ?
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#fff' }}> <h3>{traducirPagina("No_marcas")}</h3> </div>
                                        :
                                        graficos.length > 0 ?
                                            <Row gutter={[8, 8]}>
                                                {graficos.map((registro, i) =>
                                                    <Col span={6}>

                                                        <Card
                                                            onClick={() => { }}
                                                            hoverable
                                                            style={{ margin: '10px' }}
                                                            bodyStyle={{
                                                                background: '#f7f7f7',
                                                                borderTop: '1px solid #dcdcdc'
                                                            }}
                                                            cover={
                                                                <div style={{ padding: '20px', height: '200px', textAlign: 'center', overflow: 'hidden' }} onClick={() => {
                                                                    setAbrirVisor(true);
                                                                    setDocumentoSeleccionado(
                                                                        {
                                                                            TITULO: registro.NOMBRE,
                                                                            URL: tabPorDefecto === "1" ? registro.IMAGEN_URL : registro.IMAGEN_LARGE
                                                                        }
                                                                    )
                                                                }}
                                                                >
                                                                    <img alt={registro.CODIGO} src={tabPorDefecto === "1" ? registro.IMAGEN_URL : registro.IMAGEN_LARGE} style={{ maxHeight: '100%' }} />
                                                                    <div className="image-mask">
                                                                        <div className="image-mask-info">
                                                                            <span >{traducirPagina("Ampliar")} </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            }
                                                        >
                                                            <div onClick={() => {
                                                                masInformacionFicha(registro, graficos)

                                                            }} style={{ textAlign: 'center' }} title={registro.NOMBRE}> {recortarCadena(registro.NOMBRE, 25, "...")} </div>

                                                        </Card>
                                                    </Col>
                                                )}
                                            </Row>
                                            :
                                            loaderGrafico ?
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#000' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                                :
                                                null
                                    }
                                </div>
                            </Col>
                        </div>
                    </Content>
                </div>

                {
                    abrirVisor ?
                        <VisorDocumentos
                            title={traducirPagina("Visor_de_imagen").toUpperCase()}
                            ABRIR_VISOR={abrirVisor}
                            CERRAR_VISOR={setAbrirVisor}
                            TIPO_DOCUMENTO={"IMG"}
                            PROPIEDADES_DOC={documentoSeleccionado}
                            ZOOM_DOCUMENTO={false}
                        ></VisorDocumentos>
                        :
                        null
                }
            </Drawer>

            {abrirFichaMasInfo ?
                <FichaMasInfoYQ
                    title={traducirPagina("Información_del_vehiculo").toUpperCase()}
                    ABRIR_FICHA={abrirFichaMasInfo}
                    CERRAR_FICHA={setAbrirFichaMasInfo}
                    GRAFICO_SELECCIONADO={graficoActual}
                    LIMPIAR_COCHE={limpiarCoche}
                    PROPIEDADES_COCHE={cocheSeleccionado}
                    LISTA_GRAFICOS={listaGraficosSeleccionado}
                    TAB_SELECCIONADO={tabPorDefecto}
                    MARCA_SELECCIONADA={marcaSeleccionada}
                >
                </FichaMasInfoYQ>
                :
                null

            }

            {abrirFichaVeh ?
                <MantenimientoVehiculoYQ
                    title={traducirPagina("Seleccione_el_vehiculo").toUpperCase()}
                    ABRIR_FICHA={abrirFichaVeh}
                    CERRAR_FICHA={gestionarFichas}
                    COCHE_SELECCIONADO={actualizarCocheSeleccionado}
                    COCHE_ACTUAL={cocheSeleccionado}
                    MODIFICAR_MARCA_SELECCIONADA={setMarcaSeleccionada}
                    MARCA_SELECCIONADA={marcaSeleccionada}
                >
                </MantenimientoVehiculoYQ>
                :
                null
            }
            {
                abrirFichaInfoCoche ?
                    <FichaDatosCocheYQ
                        title={traducirPagina("FICHA_DEL_VEHÍCULO").toUpperCase()}
                        ABRIR_FICHA={abrirFichaInfoCoche}
                        CERRAR_FICHA={setAbrirFichaInfoCoche}
                        COCHE_ACTUAL={cocheSeleccionado}
                        MARCA_SELECCIONADA={marcaSeleccionada}

                    >
                    </FichaDatosCocheYQ>
                    :
                    null
            }

          
        </div>

    )
}

FichaYQ.propTypes = {
    TIPO: PropTypes.string,
    CERRAR: PropTypes.object,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    VISCOSIDAD: PropTypes.string,
    LIMPIAR_COCHE_ANTERIOR_OEM: PropTypes.bool
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        filtrosReferencia: state.filtrosReferencia,
        usuario: state.usuario,
        codmotorizacion: state.codmotorizacion,
        clienteSeleccionado: state.clienteSeleccionado,
        listaTree: state.listaTree,
        mostrarComponente: state.mostrarComponente,
        propiedadesVisor: state.propiedadesVisor
    };
}

export default connect(mapStateToProps)(FichaYQ);