import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createRipple, onChildrenDrawerCloseIntegraciones, DrawerCarroCompra, DrawerMasInformacion, DrawerFichaAlbaranTaller, DrawerFichaOrden, DrawerFichaPresupuestoTaller, DrawerFichaFactura, DrawerFichaMantenimientoPresupuesto } from "../action/actionCreators"
import { getClienteTallerEnAPI, setClienteTallerEnAPI, ObtenerAlbaranesVentaEnAPI, ObtenerAlbaranHTMLEnAPI, ObtenerAlbaranPDFEnAPI } from "../action/actionCreatorsRequests"
import { traducirPagina, MSGOK, MSGERROR, efectoRemoveBuscadorReferencia, obtenerFechaLocal, convertirMoneda, dynamicSortDesc, descargarPdfPasandoBase64, ordenarTablaLocal, obtenerDatosUsuarioYLicenciaActual, getLastWeeksDate } from '../librerias/funciones'
import { MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBInput } from 'mdbreact';
import FichaDetalleAlbaran from '../components/FichaDetalleAbaranCompra'
import FichaTracking from './FichaTracking';
import FichaImpresionGenerico from './FichaImpresionGenerico';
import {
    PlusOutlined,
    CloseOutlined,
    SearchOutlined,
    EditOutlined,
    DeleteOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined,
    PrinterOutlined,
    EyeOutlined,
    EnvironmentOutlined,
    SendOutlined,
    CheckSquareOutlined
} from '@ant-design/icons';


import { Select, Card, Modal, Button, Input, Row, Col, Drawer, Checkbox } from 'antd';

import store from "../store";
const { Option } = Select;
const FichaAlbaranesTaller = ({ props, configApp, openDrawerFichaAlbaranesTaller, clienteTallerSeleccionado }) => {

    const [hayAlbaranes, setHayAlbaranes] = React.useState(false);
    const [albarenesTaller, setAlbaranesTaller] = React.useState([]);
    const [albarenesTallerAUX, setAlbaranesTallerAUX] = React.useState([]);
    const [fechaLocalDesde, setFechaLocalDesde] = React.useState("");
    const [fechaLocalHasta, setFechaLocalHasta] = React.useState("");
    const [textoFiltroPresupuesto, setTextoFiltroPresupuesto] = React.useState("");
    const [textoFiltroReferencia, setTextoFiltroReferencia] = React.useState("");

    const [abrirFichaDetalle, setAbrirFichaDetalle] = React.useState(false);
    const [albaranSeleccionado, setalbaranSeleccionado] = React.useState({});
    const [ABRIR_FICHA, setABRIR_FICHA] = React.useState(false);
    const [DOC_HTML, setDOC_HTML] = React.useState("");
    const [ABRIR_FICHA_TRACKING, setABRIR_FICHA_TRACKING] = React.useState(false);
    const [ABRIR_MODAL_TRASPASO, setABRIR_MODAL_TRASPASO] = React.useState(false);
    const [filtroSeleccionado, setFiltroSeleccionado] = React.useState({
        ES_ABONO: false

    });





    const [ordenarTabla, setOrdenarPorTabla] = React.useState({
        CODIGO: false,
        FECHA: false,
        BASE_IMPONIBLE: false,
        TOTAL_IVA: false,
        TOTAL: false,
        MATRICULA: false,
        ES_ABONO: false
    });


    const ordenarTablaDesc = (registros) => {

        let datos = registros.sort(function (a, b) {
            a = a.CABECERA.fechaAlbaran.split('/').reverse().join('');
            b = b.CABECERA.fechaAlbaran.split('/').reverse().join('');
            return a < b ? 1 : a > b ? -1 : 0;
        });
        return datos;
    }

    const ordenarTablaAsc = (registros) => {

        let datos = registros.sort(function (a, b) {
            a = a.CABECERA.fechaAlbaran.split('/').reverse().join('');
            b = b.CABECERA.fechaAlbaran.split('/').reverse().join('');
            return a < b ? 1 : a > b ? -1 : 0;
        });
        return datos;
    }

    const ObtenerAlbaranesVenta = (obtenerFechaLocalDesde, obtenerFechaLocalHasta, callback) => {
        ObtenerAlbaranesVentaEnAPI(obtenerFechaLocalDesde, obtenerFechaLocalHasta).then((albranes) => {
            if (albranes !== null && albranes.length > 0) {

                let registros = callback(albranes)
                registros.map((item) => {
                    item.LINEAS.map((linea, i) => {
                        linea.SELECCIONADO = false;
                        linea.ID = i;

                    });

                    item.TOTAL_ALBARAN = parseFloat(item.CABECERA.totalAlbaran.replace(",", "."));
                    item.TOTAL_IVA = parseFloat(item.CABECERA.totalIva.replace(",", "."));
                    item.BASE_IMPONIBLE = parseFloat(item.CABECERA.baseImponible.replace(",", "."));
                    item.CODIGO_ALBARAN = item.CABECERA.codigoalbaran;
                    item.FECHA_ALBARAN = item.CABECERA.fechaAlbaran;


                })

                setAlbaranesTaller(registros)
                setAlbaranesTallerAUX(registros)

            } else {
                setAlbaranesTaller(null)
                setAlbaranesTallerAUX(null)
            }
        })
    }


    if (!hayAlbaranes && openDrawerFichaAlbaranesTaller) {
        let obtenerFechaLocalDesde = obtenerFechaLocal(true)

        let obtenerFechaLocalHasta = obtenerFechaLocal(false)

        let ultimaSemana = getLastWeeksDate();
        obtenerFechaLocalDesde = ultimaSemana === undefined ? obtenerFechaLocalDesde : ultimaSemana;
        setHayAlbaranes(true);
        setFechaLocalDesde(obtenerFechaLocalDesde)
        setFechaLocalHasta(obtenerFechaLocalHasta)


        ObtenerAlbaranesVenta(obtenerFechaLocalDesde, obtenerFechaLocalHasta, ordenarTablaDesc)
    } else {
        if (!openDrawerFichaAlbaranesTaller && hayAlbaranes) {
            setTextoFiltroPresupuesto("")
            setHayAlbaranes(false);
            setAlbaranesTaller([])
            setAlbaranesTallerAUX([])
            setABRIR_FICHA(false)
            setDOC_HTML("")
        }
    }




    const actualizarCabeceraTabla = (e, valor) => {
        const CODIGO = e === 'CODIGO' ? valor : false;
        const FECHA = e === 'FECHA' ? valor : false
        const BASE_IMPONIBLE = e === 'BASE_IMPONIBLE' ? valor : false;
        const TOTAL_IVA = e === 'TOTAL_IVA' ? valor : false;
        const TOTAL = e === 'TOTAL' ? valor : false;
        const MATRICULA = e === 'MATRICULA' ? valor : false;
        const ES_ABONO = e === 'ES_ABONO' ? valor : false;




        setOrdenarPorTabla(
            {
                CODIGO: CODIGO,
                FECHA: FECHA,
                BASE_IMPONIBLE: BASE_IMPONIBLE,
                TOTAL_IVA: TOTAL_IVA,
                TOTAL: TOTAL,
                MATRICULA: MATRICULA,
                ES_ABONO: ES_ABONO

            }
        )
    }


    const ordenarTablaCliente = (CAMPO, ORDERDIR) => {
        switch (CAMPO) {
            case "CODIGO":
                setAlbaranesTaller(ordenarTablaLocal(ORDERDIR === "A" ? "ASC" : "DESC", albarenesTaller, "CODIGO_ALBARAN"))
                break;
            case "FECHA":
                setAlbaranesTaller(ordenarTablaLocal(ORDERDIR === "A" ? "ASC" : "DESC", albarenesTaller, "FECHA_ALBARAN"))
                break;
            case "BASE_IMPONIBLE":
                setAlbaranesTaller(ordenarTablaLocal(ORDERDIR === "A" ? "ASC" : "DESC", albarenesTaller, "BASE_IMPONIBLE"))
                break;
            case "TOTAL_IVA":
                setAlbaranesTaller(ordenarTablaLocal(ORDERDIR === "A" ? "ASC" : "DESC", albarenesTaller, "TOTAL_IVA"))
                break;
            case "TOTAL":
                setAlbaranesTaller(ordenarTablaLocal(ORDERDIR === "A" ? "ASC" : "DESC", albarenesTaller, "TOTAL_ALBARAN"))
                break;
            case "MATRICULA":
                setAlbaranesTaller(ordenarTablaLocal(ORDERDIR === "A" ? "ASC" : "DESC", albarenesTaller, "MATRICULA"))
                break;
            case "ES_ABONO":
                setAlbaranesTaller(ordenarTablaLocal(ORDERDIR === "A" ? "ASC" : "DESC", albarenesTaller, "ES_ABONO"))
                break;


            default:

        }
    }


    const verificarFechas = (desde, hasta) => {
        setAlbaranesTaller([]);
        let dateDesde = new Date(desde);
        let dateHasta = new Date(hasta);

        setFechaLocalDesde(desde)
        setFechaLocalHasta(hasta)

        ObtenerAlbaranesVenta(desde, hasta, ordenarTablaAsc)

    }

    const filtrarRegistrosPorReferencia = (palabra, registros) => {
        let resultado = []
        if (palabra !== "") {
            if (registros !== null) {
                let resultadoFiltro = [];
                registros.map((registro) => {
                    let existe = registro.LINEAS.filter((item) => item.referencia.toUpperCase().includes(palabra.toUpperCase()) || item.referencia.toUpperCase() === palabra.toUpperCase())
                    if (existe.length > 0) {
                        resultadoFiltro.push(registro)
                    }
                })

                if (resultadoFiltro.length > 0) {
                    resultado = resultadoFiltro;
                } else {
                    resultado = null;
                }
            }
        } else {
            resultado = albarenesTallerAUX;
        }

        setAlbaranesTaller(resultado);

        return resultado;
    }


    const buscarEnTabla = (palabra, listaCliente) => {
        let registrosEncontrados = [];
        if (palabra !== "") {
            if (listaCliente !== null) {
                listaCliente.find(function (registro, i) {
                    if (registro.CABECERA.codigoalbaran.toLowerCase().includes(palabra.toLowerCase())) {
                        let existeRegistro = registrosEncontrados.find(regi => regi.CABECERA.codigoalbaran === registro.CABECERA.codigoalbaran)
                        if (existeRegistro === undefined) {
                            registrosEncontrados.push(registro)
                        }
                    }

                    if (registro.CABECERA.fechaAlbaran.toLowerCase().includes(palabra.toLowerCase())) {
                        let existeRegistro = registrosEncontrados.find(regi => regi.CABECERA.codigoalbaran === registro.CABECERA.codigoalbaran)
                        if (existeRegistro === undefined) {
                            registrosEncontrados.push(registro)
                        }
                    }


                    if (registro.CABECERA.matricula !== undefined && registro.CABECERA.matricula !== null) {
                        if (registro.CABECERA.matricula.toLowerCase().includes(palabra.toLowerCase()) || registro.CABECERA.matricula.toLowerCase() === palabra.toLowerCase()) {
                            let existeRegistro = registrosEncontrados.find(regi => regi.CABECERA.codigoalbaran === registro.CABECERA.codigoalbaran)
                            if (existeRegistro === undefined) {
                                registrosEncontrados.push(registro)
                            }
                        }
                    }



                    if (registro.CABECERA.baseImponible.toLowerCase().includes(palabra.toLowerCase())) {
                        let existeRegistro = registrosEncontrados.find(regi => regi.CABECERA.codigoalbaran === registro.CABECERA.codigoalbaran)
                        if (existeRegistro === undefined) {
                            registrosEncontrados.push(registro)
                        }
                    }

                    if (registro.CABECERA.totalIva.toLowerCase().includes(palabra.toLowerCase())) {
                        let existeRegistro = registrosEncontrados.find(regi => regi.CABECERA.codigoalbaran === registro.CABECERA.codigoalbaran)
                        if (existeRegistro === undefined) {
                            registrosEncontrados.push(registro)
                        }
                    }

                    if (registro.CABECERA.totalAlbaran.toLowerCase().includes(palabra.toLowerCase())) {
                        let existeRegistro = registrosEncontrados.find(regi => regi.CABECERA.codigoalbaran === registro.CABECERA.codigoalbaran)
                        if (existeRegistro === undefined) {
                            registrosEncontrados.push(registro)
                        }
                    }
                })
            }
            if (registrosEncontrados.length > 0) {
                setAlbaranesTaller(registrosEncontrados);
            } else {
                setAlbaranesTaller(null);
            }
        } else {
            setAlbaranesTaller(albarenesTallerAUX);
        }
    }

    const buscarDocumento = (DOCUMENTO) => {
        ObtenerAlbaranHTMLEnAPI(DOCUMENTO).then((albaran) => {
            if (albaran !== undefined) {
                if (albaran.HTML !== null && albaran.HTML !== "") {
                    setDOC_HTML(albaran.HTML)
                } else {
                    setDOC_HTML("")
                }
            }
        })
    }


    const buscarDocumentoPDF = () => {
        ObtenerAlbaranPDFEnAPI(albaranSeleccionado).then((albaran) => {
            if (albaran !== undefined) {
                if (albaran.PDF !== "" && albaran.PDF !== null) {
                    descargarPdfPasandoBase64(albaran.PDF, "Albaran")
                }
            }
        })
    }



    const enviarAlbaran_A_Documento = (documento, TIPO_DOCUMENTO) => {
        if (documento.TOTAL_ALBARAN > 0) {
            if (TIPO_DOCUMENTO === 1) {
                store.dispatch(DrawerFichaPresupuestoTaller(true));


                setTimeout(() => {
                    store.dispatch({ type: "UPDATE_MODO_FICHA_PRESUPUESTO_TALLER", esNuevoPresupuesto: true });
                    store.dispatch(DrawerFichaMantenimientoPresupuesto(true));
                }, 500)



            } else if (TIPO_DOCUMENTO === 2) {
                store.dispatch(DrawerFichaOrden(true));


            } else if (TIPO_DOCUMENTO === 3) {
                store.dispatch(DrawerFichaFactura(true));

            }

            store.dispatch({ type: 'STATE_ALBARAN_SELECCIONADO', ALBARAN_SELECCIONADO: documento })


        } else {
            MSGERROR(traducirPagina("msg_pasar_albaran"))
        }
    }



    const filtroConsultasCerradas = (
        <div className='container_checked_abonos'>
            <Checkbox name="ABONOS" checked={filtroSeleccionado.ES_ABONO} onChange={(e) => {
                filtroSeleccionado.ES_ABONO = e.target.checked;
                setFiltroSeleccionado({ ...filtroSeleccionado })

            }} ><span style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>{traducirPagina("Abonos")}</span> </Checkbox>
        </div>

    );


    return (
        <div>
            <Drawer
                width={1180}
                onClose={() => { store.dispatch(DrawerFichaAlbaranTaller(false)); }}
                visible={openDrawerFichaAlbaranesTaller}
                closable={false}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>

                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                            onClick={(e) => {
                                store.dispatch(DrawerFichaAlbaranTaller(false));
                                createRipple(e)
                            }}>
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>


                <div className="p-3" style={{ height: (configApp.height - 70).toString() + "px", overflow: 'auto' }}>

                    <div className="flex-container mdb-skin">

                        <div className="grid-container mb-3" style={{ backgroundColor: '#eee', marginRight: '10px' }}>
                            <div className="grid-item">
                                <input className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_referencia")} style={{ backgroundColor: "transparent", width: '200px', border: '0px', height: '100%', color: '#000' }} name="txtInputPresupuesto" autoComplete="off" value={textoFiltroReferencia} onChange={(e) => { setTextoFiltroReferencia(e.target.value); filtrarRegistrosPorReferencia(e.target.value, albarenesTallerAUX) }} />
                            </div>

                            <div className="grid-item" >
                                <MDBBtn onClick={e => { createRipple(e); setTextoFiltroReferencia(""); filtrarRegistrosPorReferencia("", albarenesTallerAUX) }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: textoFiltroReferencia !== "" ? 'visible' : 'hidden' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                            </div>

                            <div className="grid-item barraLateral" ></div>

                            <div className="grid-item">
                                <MDBBtn onClick={e => { createRipple(e); }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                            </div>
                        </div>


                        <div className="grid-container mb-3" style={{ backgroundColor: '#eee' }}>
                            <div className="grid-item">
                                <input className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_en_la_tabla")} style={{ backgroundColor: "transparent", width: '200px', border: '0px', height: '100%', color: '#000' }} name="txtInputPresupuesto" autoComplete="off" value={textoFiltroPresupuesto} onChange={(e) => { setTextoFiltroPresupuesto(e.target.value); buscarEnTabla(e.target.value, albarenesTallerAUX) }} />
                            </div>

                            <div className="grid-item" >
                                <MDBBtn onClick={e => { createRipple(e); setTextoFiltroPresupuesto(""); buscarEnTabla("", albarenesTallerAUX) }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: textoFiltroPresupuesto !== "" ? 'visible' : 'hidden' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                            </div>

                            <div className="grid-item barraLateral" ></div>

                            <div className="grid-item">
                                <MDBBtn onClick={e => { createRipple(e); }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                            </div>
                        </div>



                        <div className="grid-item" style={{ backgroundColor: 'transparent', color: '#000', marginTop: '5px', marginLeft: '10px' }}>
                            <input type="date" value={fechaLocalDesde} onChange={(e) => setFechaLocalDesde(e.target.value)}></input>
                        </div>


                        <div className="grid-item" style={{ backgroundColor: 'transparent', color: '#000', marginTop: '5px', marginLeft: '10px' }}>
                            <input type="date" value={fechaLocalHasta} onChange={(e) => setFechaLocalHasta(e.target.value)}></input>
                        </div>

                        <div className="grid-item" style={{ backgroundColor: 'transparent', color: '#000', marginLeft: '5px' }}>

                            <button className="col-md-12" style={{ height: '33px', width: '130px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top', color: '#fff', backgroundColor: '#4285f4' }} onClick={(e) => {
                                verificarFechas(fechaLocalDesde, fechaLocalHasta)
                            }}>
                                <SearchOutlined style={{ fontSize: '20px' }} />
                                <span style={{ verticalAlign: 'middle', fontSize: '16px', marginLeft: '10px' }}>{traducirPagina("Filtrar").toUpperCase()}</span>
                            </button>
                        </div>


                        <div className="grid-item" style={{ backgroundColor: 'transparent', color: '#000', marginLeft: '5px' }}>

                            {filtroConsultasCerradas}

                        </div>
                    </div>

                    <Card style={{ height: (configApp.height - 170).toString() + "px" }} className="mdb-skin" >
                        {albarenesTaller !== undefined ?
                            albarenesTaller === null ?
                                <div style={{ textAlign: 'center' }}>
                                    <h4>{traducirPagina("No_albaranes")}</h4>
                                </div>
                                :
                                albarenesTaller.length > 0 ?

                                    <div style={{ fontSize: '16px', fontWeight: '500', color: "#000", height: (configApp.height - 220).toString() + "px", overflow: 'auto' }} className="row">
                                        <MDBTable responsive hover >
                                            <MDBTableHead>
                                                <tr>
                                                    <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '50px', verticalAlign: 'baseline', textAlign: 'center' }} onClick={() => {
                                                        //actualizarCabeceraTabla("ES_ABONO", !ordenarTabla.ES_ABONO);
                                                        //ordenarTablaCliente("ES_ABONO", ordenarTabla.ES_ABONO ? "A" : "D");
                                                    }}>{"A"} </th>


                                                    <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '150px', verticalAlign: 'baseline', cursor: 'pointer' }} onClick={() => {
                                                        actualizarCabeceraTabla("CODIGO", !ordenarTabla.CODIGO);
                                                        ordenarTablaCliente("CODIGO", ordenarTabla.CODIGO ? "A" : "D");
                                                    }}>
                                                        {traducirPagina("Codigo")} {ordenarTabla.CODIGO ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}
                                                    </th>

                                                    <th style={{ width: '125px', paddingLeft: 0, fontSize: "14px", fontWeight: "bold", verticalAlign: 'baseline', cursor: 'pointer' }} onClick={() => {
                                                        actualizarCabeceraTabla("FECHA", !ordenarTabla.FECHA);
                                                        ordenarTablaCliente("FECHA", ordenarTabla.FECHA ? "A" : "D");
                                                    }}>{traducirPagina("Fecha")}{ordenarTabla.FECHA ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />} </th>

                                                    <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", verticalAlign: 'baseline', cursor: 'pointer' }} onClick={() => {
                                                        actualizarCabeceraTabla("MATRICULA", !ordenarTabla.MATRICULA);
                                                        ordenarTablaCliente("MATRICULA", ordenarTabla.MATRICULA ? "A" : "D");
                                                    }}>{traducirPagina("Matricula")}{ordenarTabla.MATRICULA ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />} </th>

                                                    <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '180px', verticalAlign: 'baseline', textAlign: 'right' }} onClick={() => {
                                                        actualizarCabeceraTabla("BASE_IMPONIBLE", !ordenarTabla.BASE_IMPONIBLE);
                                                        ordenarTablaCliente("BASE_IMPONIBLE", ordenarTabla.BASE_IMPONIBLE ? "A" : "D");
                                                    }}>{traducirPagina("Base_imponible")}{ordenarTabla.BASE_IMPONIBLE ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}</th>

                                                    <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '180px', verticalAlign: 'baseline', textAlign: 'right' }} onClick={() => {
                                                        actualizarCabeceraTabla("TOTAL_IVA", !ordenarTabla.TOTAL_IVA);
                                                        ordenarTablaCliente("TOTAL_IVA", ordenarTabla.TOTAL_IVA ? "A" : "D");
                                                    }}>{traducirPagina("Total_IVA")} {ordenarTabla.TOTAL_IVA ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}</th>

                                                    <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '180px', verticalAlign: 'baseline', textAlign: 'right' }} onClick={() => {
                                                        actualizarCabeceraTabla("TOTAL", !ordenarTabla.TOTAL);
                                                        ordenarTablaCliente("TOTAL", ordenarTabla.TOTAL ? "A" : "D");
                                                    }}>{traducirPagina("Total")} {ordenarTabla.TOTAL ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}</th>





                                                    <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '100px', verticalAlign: 'baseline', textAlign: 'center' }}> </th>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                {
                                                    albarenesTaller.filter((registro) => filtroSeleccionado.ES_ABONO ? registro.CABECERA.abono === filtroSeleccionado.ES_ABONO : registro).map((albaran, i) =>

                                                        <tr key={i}>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle', textAlign: 'center' }}>
                                                                {
                                                                    albaran.CABECERA.abono ?
                                                                        <div style={{ display: albaran.CABECERA.abono ? '' : 'none', width: '25px', height: '25px', borderRadius: '50%', backgroundColor: 'red', color: '#fff', fontSize: '15px', fontWeight: 'bold', marginLeft: '10px' }}>
                                                                            A
                                                                        </div> : ""

                                                                }</td>

                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{albaran.CABECERA.codigoalbaran}</td>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{albaran.CABECERA.fechaAlbaran}</td>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{albaran.CABECERA.matricula}</td>

                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle', textAlign: 'right' }}>{convertirMoneda(albaran.CABECERA.baseImponible, "€")}</td>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle', textAlign: 'right' }}>{convertirMoneda(albaran.CABECERA.totalIva, "€")}</td>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle', textAlign: 'right' }}>{convertirMoneda(albaran.CABECERA.totalAlbaran, "€")}</td>



                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>
                                                                <div className="flex-container-Grow-Simple">

                                                                    <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                        <MDBBtn onClick={() => {
                                                                            setalbaranSeleccionado(albaran);
                                                                            setAbrirFichaDetalle(true)
                                                                        }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                            <EyeOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                        </MDBBtn>
                                                                    </div>

                                                                    <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                        <MDBBtn onClick={() => {
                                                                            setalbaranSeleccionado(albaran);
                                                                            setABRIR_FICHA(true)
                                                                            buscarDocumento(albaran)
                                                                        }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                            <PrinterOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                        </MDBBtn>
                                                                    </div>

                                                              

                                                                    <div title='Pasar el documento' style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                        <MDBBtn onClick={() => {
                                                                            setalbaranSeleccionado(albaran);
                                                                            obtenerDatosUsuarioYLicenciaActual().FACTURACION_LITE ?
                                                                                setABRIR_MODAL_TRASPASO(true)
                                                                                :
                                                                                enviarAlbaran_A_Documento(albaran, 1)
                                                                        }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                            <SendOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                        </MDBBtn>
                                                                    </div>


                                                                </div>
                                                            </td>
                                                        </tr>

                                                    )}
                                            </MDBTableBody>
                                        </MDBTable>
                                    </div>
                                    :
                                    <div style={{ fontSize: '16px', fontWeight: '500', color: "#000" }} className="row">
                                        <MDBCol md="9">
                                            <div>
                                                <span className="skeleton-box" style={{ width: '800px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '800px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '700px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '700px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '600px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '600px' }}></span>
                                            </div>
                                        </MDBCol>
                                    </div>
                            : null}
                    </Card>
                </div>
            </Drawer>

            {abrirFichaDetalle ?
                <FichaDetalleAlbaran title={traducirPagina("Ficha_de_albaranes_compra").toUpperCase()} VER_FICHA={abrirFichaDetalle} CERRAR_FICHA={setAbrirFichaDetalle} REGISTRO={albaranSeleccionado} MODIFICAR_LINEA={setalbaranSeleccionado}></FichaDetalleAlbaran>
                :
                null
            }
            {ABRIR_FICHA ?
                <FichaImpresionGenerico title={traducirPagina("Ficha_albaran_compra").toUpperCase()} ABRIR_FICHA={ABRIR_FICHA} CERRAR_FICHA={setABRIR_FICHA} DOCUMENTO={DOC_HTML} DESCARGAR_DOCUMENTO={buscarDocumentoPDF} MODIFICAR_DOCUMENTO={setDOC_HTML}
                > </FichaImpresionGenerico>
                :
                null
            }
            {
                ABRIR_FICHA_TRACKING ?
                    <FichaTracking
                        title={"TRACKING REPARTO"}
                        ABRIR_FICHA={ABRIR_FICHA_TRACKING}
                        CERRAR_FICHA={setABRIR_FICHA_TRACKING}
                    >
                    </FichaTracking>
                    :
                    null
            }

            {ABRIR_MODAL_TRASPASO ?
                <Modal
                    visible={ABRIR_MODAL_TRASPASO}
                    width={700}
                    centered
                    title={"SELECCIONE EL DOCUMENTO PARA EL TRASPASO DE ARTÍCULOS"}
                    afterClose={() => { }}
                    onCancel={() => setABRIR_MODAL_TRASPASO(false)}
                    zIndex={10000}

                    footer={[

                    ]}

                >

                    <Row gutter={[8, 8]} style={{ color: '#000', fontSize: '16px', fontWeight: '500' }}>
                        <Col md={8}>
                            <button id="btnArtVeh"
                                className="activebtnMenu3 btnColor"
                                style={{ padding: '10px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#fff', backgroundColor: '#ccc', width: '100%' }}
                                onClick={() => {
                                    enviarAlbaran_A_Documento(albaranSeleccionado, 1);
                                    setABRIR_MODAL_TRASPASO(false)
                                }} > <span style={{ verticalAlign: 'middle', fontSize: '16px' }}> {traducirPagina("Presupuestos")}</span>
                            </button>
                        </Col>

                        <Col md={8}>
                            <button id="btnArtVeh"
                                className="activebtnMenu3 btnColor"
                                style={{ padding: '10px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#fff', backgroundColor: '#ccc', width: '100%' }}
                                onClick={() => {
                                    enviarAlbaran_A_Documento(albaranSeleccionado, 2);
                                    setABRIR_MODAL_TRASPASO(false)
                                }} > <span style={{ verticalAlign: 'middle', fontSize: '16px' }}> {traducirPagina("Ordenes_de_reparacion")}</span>
                            </button>
                        </Col>

                        <Col md={8}>
                            <button id="btnArtVeh"
                                className="activebtnMenu3 btnColor"
                                style={{ padding: '10px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#fff', backgroundColor: '#ccc', width: '100%' }}
                                onClick={() => {
                                    enviarAlbaran_A_Documento(albaranSeleccionado, 3);
                                    setABRIR_MODAL_TRASPASO(false)
                                }}
                            > <span style={{ verticalAlign: 'middle', fontSize: '16px' }}> {traducirPagina("Facturas_de_venta")}</span>
                            </button>
                        </Col>
                    </Row>


                </Modal>
                :
                null
            }

        </div>

    )
}


FichaAlbaranesTaller.propTypes = {
    title: PropTypes.string,
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        openDrawerFichaAlbaranesTaller: state.estadoDrawer.openDrawerFichaAlbaranesTaller,
        clienteTallerSeleccionado: state.clienteTallerSeleccionado
    };
}

export default connect(mapStateToProps)(FichaAlbaranesTaller);