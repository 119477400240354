import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBNav, MDBNavItem, MDBCol } from 'mdbreact';
import 'antd/dist/antd.css';
import { Drawer, Checkbox, Col, Layout, Pagination, Input, Select, Row } from 'antd';
import {
    getStockDesglose,
    ObtenerNeumaticoImagenEnAPI,
    buscarPrecioReferencia,
    ObtenerNeumaticosVehiculoEnAPI,
    ObtenerNeumaticosCatalogoEnAPI,
    ObtenerNeumaticosAnchoEnAPI,
    ObtenerNeumaticosLargoEnAPI,
    ObtenerNeumaticosLlantaEnAPI,
    ObtenerNeumaticoVelocidadEnAPI,
    ObtenerNeumaticoCargaEnAPI,
    ObtenerNeumaticosMarcaEnAPI,
    ObtenerDesgloseStockNeumaticoEnAPI
} from "../action/actionCreatorsRequests";
import {
    DrawerStockAlmacen, efectoBotonRemove
} from "../action/actionCreators";

import {
    traducirPagina, referenciaActualSelect, MSGOK, verificarSiEsMostrador, dynamicSortMultipleDesc,
    verficarSiEsCatalogo, crearLineasParaElPresupuesto, MSGERROR, obtenerDatosUsuarioYLicenciaActual,
    mostrarNeumaticosEnPantalla, colorConsumo, colorAgarre, convertirMoneda, soloNumeros, condicionesAbrirCesta, cestaAutomatica, dynamicSortAsc,
    credencialesHaynes, verficarPreciosAsincronos, dynamicSortDesc, datosLincenciaUsuario, verificarCantidadCesta,condicionesAbrirCestav2
} from "../librerias/funciones"
import FichaAñadirCesta from "./FichaAñadirCesta.jsx";
import FichaStockSocio from "./FichaStockSocio.jsx";
import FichaDetalleNeumatico from './FichaDetalleNeumatico';

import store from '../store'
import {
    ShoppingOutlined,
    EyeOutlined,
    LikeOutlined,
    DislikeOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    EyeInvisibleOutlined,
    CopyOutlined,
    CheckSquareOutlined,
    CloseSquareOutlined,
    FileAddOutlined,
    PlusOutlined,
    MinusOutlined,
    BorderOutlined,
    DeploymentUnitOutlined,
    FileImageOutlined,
    RightOutlined,
    DownOutlined,
    CloseOutlined,
    UpOutlined,
    ClearOutlined,
    SearchOutlined
} from '@ant-design/icons';
import { FichaStockNeumatico } from './Compra/FichaStockNeumatico';
import { FichaAgregarArticuloGenerico } from './Compra/FichaAgregarArticuloGenerico';

const { Content } = Layout;
const { Option } = Select;


const FichaNeumaticosErp = ({ props, usuario, clienteSeleccionado }) => {
    const OBTENER_DATOS_USUARIO = datosLincenciaUsuario();
    const { COMPRAR_SIN_DISPONIBILIDAD } = OBTENER_DATOS_USUARIO === undefined ? { COMPRAR_SIN_DISPONIBILIDAD: false } : OBTENER_DATOS_USUARIO;



    const [alturaSubmenu, setAlturaSubmenu] = React.useState(0);
    const ref = React.useRef(null)
    const refTabla = React.useRef(null)

    const inputRef = React.useRef(null);
    const sharedProps = {
        ref: inputRef,
    };


    const [abrirStockNeumatico, setAbrirStockNeumatico] = React.useState(false);
    const [abrirCestaNeumatico, setAAbrirCestaNeumatico] = React.useState(false);


    const [abrirFichaDetalle, setAbrirFichaDetalle] = React.useState(false);
    const [neumaticoSeleccionado, setNeumaticoSeleccionado] = React.useState({});
    const [abrirFichaStockSocio, setAbrirFichaStockSocio] = React.useState(false);
    const [datosPaginacion, setDatosPaginacion] = React.useState({ iniPage: 1, inicio: 1, final: 0, numPagina: 8 });
    const [filtroCarga, setFiltroCarga] = React.useState("");
    const [listadoDeReferencias, setListadoDeReferencias] = React.useState({});
    const [esRunFlat, setEsRunFlat] = React.useState(false);
    const [marcasNeumaticos, setMarcasNeumaticos] = React.useState([]);


    //C1 TURISMO C2 FURGONETA C3 CAMION
    const [listaCamino, setListaCamino] = React.useState({
        NIEVE: false,
        ALPINO: false,

        TURISMO: false,
        FURGON: false,
        CAMION: false,

        INVIERNO: false,
        VERANO: false,
        ALLSEASON: false

    });


    const [initCatalogoNeumatico, setInitCatalogoNeumatico] = React.useState(true);
    const [visualizacionDescuento, setVisualizacionDescuento] = React.useState(true);

    const [mostrarFilrosComponente, setMostrarFilrosComponente] = React.useState(true);
    const [hayReferenicaAMostrar, setHayReferenicaAMostrar] = React.useState(false);
    const [fichaCesta, setFichaCesta] = React.useState(false);
    const [referenciaSeleccionada, setReferenciaSeleccionada] = React.useState({});


    const [REGISTROS_NEUMATICOS, setREGISTROS_NEUMATICOS] = React.useState({
        NEUMATICOS: [],
        MARCAS: [],
        MEDIDAS: [],
        ANCHO_NEUMATICO: [],
        PERFIL_NEUMATICO: [],
        LLANTA_NEUMATICO: [],
        VELOCIDAD_NEUMATICO: [],
        CARGA_NEUMATICO: [],
        ABRIR_MEDIDAS: false
    });

    const [FILTRO_LOCAL, setFILTRO_LOCAL] = React.useState({
        MEDIDAS_SELECCIONADAS: [],
        MEDIDA_SELECCIONADA: "",
        MARCA_SELECCIONADA: "",
        ANCHO_SELECCIONADO: "",
        PERFIL_SELECCIONADO: "",
        LLANTA_SELECCIONADO: "",
        VELOCIDAD_SELECCIONADA: "",
        CARGA_SELECCIONADO: "",
        REFERENCIA_FABRICANTE_SELECCIONADA: "",
    });

    const textosDinamicos = [
        {
            X1: 480,
            X2: 0,
            Y1: 120,
            Y2: 0,
            TEXTO: traducirPagina("Msg_ancho_imagen")
        },
        {
            X1: 420,
            X2: 0,
            Y1: 160,
            Y2: 0,
            TEXTO: traducirPagina("Msg_alto_imagen")
        },
        {
            X1: 390,
            X2: 0,
            Y1: 200,
            Y2: 0,
            TEXTO: traducirPagina("Msg_radial_imagen")
        },
        {
            X1: 50,
            X2: 0,
            Y1: 200,
            Y2: 0,
            TEXTO: traducirPagina("Msg_diametro_imagen")
        },
        {
            X1: -15,
            X2: 0,
            Y1: 160,
            Y2: 0,
            TEXTO: traducirPagina("Msg_carga_imagen")
        },
        {
            X1: -70,
            X2: 0,
            Y1: 120,
            Y2: 0,
            TEXTO: traducirPagina("Msg_velocidad_imagen")
        }

    ];


    const buscarMedidasSeleccionadas = (MEDIDAS) => {
        let MEDIDAS_SELECCIONADS = MEDIDAS.filter((registro) => registro.SELECCIONADO && registro.ID !== -1)

        FILTRO_LOCAL.MEDIDAS_SELECCIONADAS = MEDIDAS_SELECCIONADS;

        setFILTRO_LOCAL({ ...FILTRO_LOCAL })
    }

    const iniciarMedidas = (REGISTROS) => {
        const TODAS = { DESCRIPCION: "", MEDIDA: traducirPagina("Todas_medidas").toUpperCase(), MEDIDA_UNIFICADA: "", PROPIEDADES: [], ID: -1 }
        const NUEVA_LISTA = [TODAS].concat(REGISTROS);
        NUEVA_LISTA.map((registro, i) => {
            registro.VER_INFORMACION = false;
            registro.SELECCIONADO = true;
            registro.ID = i === 0 ? -1 : (i - 1)
        })

        buscarMedidasSeleccionadas(NUEVA_LISTA)

        return NUEVA_LISTA
    }



    const implementarFiltros = (REGISTROS_NEUMATICOS, FILTROS) => {
        let MODIFICAR_NEUMATICOS = REGISTROS_NEUMATICOS.NEUMATICOS
        const {
            MEDIDAS_SELECCIONADAS: MEDIDAS,
            MARCA_SELECCIONADA: ID_MARCA,
            ANCHO_SELECCIONADO: ANCHURA,
            PERFIL_SELECCIONADO: ALTURA,
            LLANTA_SELECCIONADO: DIAMETRO,
            VELOCIDAD_SELECCIONADA: VELOCIDAD,
            CARGA_SELECCIONADO: CARGA,
            REFERENCIA_FABRICANTE_SELECCIONADA: REFERENCIA_FABRICANTE
        } = FILTROS


        let LISTA_MEDIDAS = MEDIDAS.filter((registro) => registro.SELECCIONADO && registro.ID !== -1).map((registro) => { return registro.MEDIDA_UNIFICADA });

        if (LISTA_MEDIDAS.length > 0) {
            MODIFICAR_NEUMATICOS = MODIFICAR_NEUMATICOS.filter(neumatico => LISTA_MEDIDAS.includes(neumatico.ANCHO_LARGO_LLANTA));
        }

        if (ID_MARCA !== "") {
            MODIFICAR_NEUMATICOS = MODIFICAR_NEUMATICOS.filter(neumatico => neumatico.ID_MARCA === ID_MARCA);
        }

        if (ANCHURA !== "") {
            MODIFICAR_NEUMATICOS = MODIFICAR_NEUMATICOS.filter(neumatico => neumatico.ANCHURA === ANCHURA);
        }

        if (ALTURA !== "") {
            MODIFICAR_NEUMATICOS = MODIFICAR_NEUMATICOS.filter(neumatico => neumatico.ALTURA === ALTURA);
        }

        if (DIAMETRO !== "") {
            MODIFICAR_NEUMATICOS = MODIFICAR_NEUMATICOS.filter(neumatico => neumatico.DIAMETRO === DIAMETRO);
        }

        if (VELOCIDAD !== "") {
            MODIFICAR_NEUMATICOS = MODIFICAR_NEUMATICOS.filter(neumatico => neumatico.VELOCIDAD === VELOCIDAD);
        }

        if (CARGA !== "") {
            MODIFICAR_NEUMATICOS = MODIFICAR_NEUMATICOS.filter(neumatico => neumatico.CARGA === CARGA);
        }

      



        let RESULTADO_FILTRO = initSelectNeumatico({ NEUMATICOS: MODIFICAR_NEUMATICOS })

        REGISTROS_NEUMATICOS.MARCAS = RESULTADO_FILTRO.MARCAS
        REGISTROS_NEUMATICOS.ANCHO_NEUMATICO = RESULTADO_FILTRO.ANCHO_NEUMATICO
        REGISTROS_NEUMATICOS.PERFIL_NEUMATICO = RESULTADO_FILTRO.PERFIL_NEUMATICO
        REGISTROS_NEUMATICOS.LLANTA_NEUMATICO = RESULTADO_FILTRO.LLANTA_NEUMATICO
        REGISTROS_NEUMATICOS.VELOCIDAD_NEUMATICO = RESULTADO_FILTRO.VELOCIDAD_NEUMATICO
        REGISTROS_NEUMATICOS.CARGA_NEUMATICO = RESULTADO_FILTRO.CARGA_NEUMATICO

        setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS })
    }



    const initSelectNeumatico = (REGISTROS_NEUMATICOS) => {
        let VERIFICAR_NEUMATICOS = REGISTROS_NEUMATICOS.NEUMATICOS !== undefined && REGISTROS_NEUMATICOS.NEUMATICOS !== null && REGISTROS_NEUMATICOS.NEUMATICOS.length > 0 ? REGISTROS_NEUMATICOS.NEUMATICOS : null

        REGISTROS_NEUMATICOS.MARCAS = VERIFICAR_NEUMATICOS !== null ?
            VERIFICAR_NEUMATICOS.filter((value, index, self) =>
                self.findIndex((m) => m.ID_MARCA === value.ID_MARCA) === index)
                .map(obj => {
                    return { ID: obj.ID_MARCA, DESCRIPCION: obj.MARCA_DESCRIPCION }
                })

            : null;

        REGISTROS_NEUMATICOS.ANCHO_NEUMATICO = VERIFICAR_NEUMATICOS !== null ?
            VERIFICAR_NEUMATICOS.filter((value, index, self) =>
                self.findIndex((m) => m.ANCHURA === value.ANCHURA) === index)
                .map((obj, i) => {
                    return { ID: i, DESCRIPCION: obj.ANCHURA }
                })
            : null;

        REGISTROS_NEUMATICOS.PERFIL_NEUMATICO = VERIFICAR_NEUMATICOS !== null ?
            VERIFICAR_NEUMATICOS.filter((value, index, self) =>
                self.findIndex((m) => m.ALTURA === value.ALTURA) === index)
                .map((obj, i) => {
                    return { ID: i, DESCRIPCION: obj.ALTURA }
                })
            : null;

        REGISTROS_NEUMATICOS.LLANTA_NEUMATICO = VERIFICAR_NEUMATICOS !== null ?
            VERIFICAR_NEUMATICOS.filter((value, index, self) =>
                self.findIndex((m) => m.DIAMETRO === value.DIAMETRO) === index)
                .map((obj, i) => {
                    return { ID: i, DESCRIPCION: obj.DIAMETRO }
                })
            : null;

        REGISTROS_NEUMATICOS.VELOCIDAD_NEUMATICO = VERIFICAR_NEUMATICOS !== null ?
            VERIFICAR_NEUMATICOS.filter((value, index, self) =>
                self.findIndex((m) => m.VELOCIDAD === value.VELOCIDAD) === index)
                .map((obj, i) => {
                    return { ID: i, DESCRIPCION: obj.VELOCIDAD }
                })
            : null;

        REGISTROS_NEUMATICOS.CARGA_NEUMATICO = VERIFICAR_NEUMATICOS !== null ?
            VERIFICAR_NEUMATICOS.filter((value, index, self) =>
                self.findIndex((m) => m.CARGA === value.CARGA) === index)
                .map((obj, i) => {
                    return { ID: i, DESCRIPCION: obj.CARGA }
                })
            : null;


        return REGISTROS_NEUMATICOS
    }




    const ObtenerNeumaticosVehiculo = async (ID_VEHICULO, HEYNES_TOKEN_DIST) => {
        setListadoDeReferencias({})

        datosPaginacion.iniPage = 1
        datosPaginacion.inicio = 1
        datosPaginacion.final = datosPaginacion.numPagina
        setDatosPaginacion({ ...datosPaginacion })

        let registros = await ObtenerNeumaticosVehiculoEnAPI(ID_VEHICULO, HEYNES_TOKEN_DIST)
        if (registros !== undefined && registros !== null) {
            REGISTROS_NEUMATICOS.MEDIDAS = registros.MEDIDAS !== undefined && registros.MEDIDAS !== null && registros.MEDIDAS.length > 0 ? iniciarMedidas(registros.MEDIDAS) : null
            REGISTROS_NEUMATICOS.NEUMATICOS = registros.NEUMATICOS !== undefined && registros.NEUMATICOS !== null && registros.NEUMATICOS.length > 0 ? registros.NEUMATICOS : null

            let FILTROS_NEUMATICOS = initSelectNeumatico(REGISTROS_NEUMATICOS)
            setREGISTROS_NEUMATICOS({ ...FILTROS_NEUMATICOS })
        }

        modelarRegistros(registros);
    }



    const actualizarRegistros = (neumaticos, marcasSeleccionadas) => {

        let objetoRegistro = mostrarNeumaticosEnPantalla(neumaticos, marcasSeleccionadas)
        setHayReferenicaAMostrar(false)

        //setListadoDeReferenciasAux(objetoRegistro.Referencias)
        setListadoDeReferencias(objetoRegistro.Referencias)

        return objetoRegistro;
    }




    const neumaticoMarcas = async (MEDIDA_UNIFICADA) => {
        let registros = await ObtenerNeumaticosMarcaEnAPI(MEDIDA_UNIFICADA)
        let resultado = null;

        if (registros !== undefined && registros !== null && registros.MARCAS !== null && registros.MARCAS.length > 0) {
            resultado = registros.MARCAS.map((obj, i) => {
                return { ID: obj.ID, DESCRIPCION: obj.NOMBRE }
            })
        }

        return resultado;
    }


    const neumaticosAnchura = async (ID_MARCA) => {
        let registros = await ObtenerNeumaticosAnchoEnAPI(ID_MARCA)
        let resultado = null;

        if (registros !== undefined && registros !== null && registros.REGISTROS !== null && registros.REGISTROS.length > 0) {
            resultado = registros.REGISTROS.map((obj, i) => {
                return { ID: i, DESCRIPCION: obj.ANCHO }
            })
        }

        return resultado;
    }

    const neumaticosAltura = async (ANCHURA, ID_MARCA) => {
        let registros = await ObtenerNeumaticosLargoEnAPI(ANCHURA, ID_MARCA)
        let resultado = null;


        if (registros !== undefined && registros !== null && registros.REGISTROS !== null && registros.REGISTROS.length > 0) {
            resultado = registros.REGISTROS.map((obj, i) => {
                return { ID: i, DESCRIPCION: obj.ALTURA }
            })
        }
        return resultado;

    }


    const neumaticosDiametro = async (ANCHURA, ALTURA, ID_MARCA) => {
        let registros = await ObtenerNeumaticosLlantaEnAPI(ANCHURA, ALTURA, ID_MARCA)
        let resultado = null;

        if (registros !== undefined && registros !== null && registros.REGISTROS !== null && registros.REGISTROS.length > 0) {
            resultado = registros.REGISTROS.map((obj, i) => {
                return { ID: i, DESCRIPCION: obj.DIAMETRO }
            })
        }
        return resultado;
    }

    const neumaticosVelocidad = async (ANCHURA, ALTURA, DIAMETRO, CARGA, ID_MARCA) => {
        let registros = await ObtenerNeumaticoVelocidadEnAPI(ANCHURA, ALTURA, DIAMETRO, CARGA, ID_MARCA)
        let resultado = null;

        if (registros !== undefined && registros !== null && registros.REGISTROS !== null && registros.REGISTROS.length > 0) {
            resultado = registros.REGISTROS.map((obj, i) => {
                return { ID: i, DESCRIPCION: obj.VELOCIDAD }
            })
        }
        return resultado
    }


    const neumaticosCarga = async (ANCHURA, ALTURA, DIAMETRO, ID_MARCA) => {
        let registros = await ObtenerNeumaticoCargaEnAPI(ANCHURA, ALTURA, DIAMETRO, ID_MARCA)
        let resultado = null;

        if (registros !== undefined && registros !== null && registros.REGISTROS !== null && registros.REGISTROS.length > 0) {
            resultado = registros.REGISTROS.map((obj, i) => {
                return { ID: i, DESCRIPCION: obj.CARGA }
            })
        }

        return resultado
    }




    const paginarNeumatico = async (page, pageSize) => {
        if (Object.keys(listadoDeReferencias).length > 0) {
            let pInicio = (pageSize * page) - datosPaginacion.numPagina;
            let pFinal = pageSize * page;

            datosPaginacion.iniPage = page
            datosPaginacion.inicio = pInicio > 1 ? (pInicio + 1) : pInicio < 1 ? 1 : pInicio
            datosPaginacion.final = pFinal
            datosPaginacion.numPagina = datosPaginacion.numPagina

            setDatosPaginacion({ ...datosPaginacion })

        }
    }



    const modelarRegistros = async (referencia) => {
        if (referencia !== undefined && referencia !== null && referencia.NEUMATICOS !== undefined && referencia.NEUMATICOS !== null && referencia.NEUMATICOS.length > 0) {

            if (verficarPreciosAsincronos()) {
                await Promise.all(
                    referencia.NEUMATICOS.map(async (item, i) => {
                        item.IMAGEN_NEUMATICO = "";
                        item.CANTIDAD_CLIENTE = 1
                        if (item.DETALLE_ERP_SOCIO !== undefined && item.DETALLE_ERP_SOCIO !== null && item.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO !== undefined && item.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO !== null) {
                            const data = await buscarPrecioReferencia({ REFERENCIA_PROVEEDOR: item.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO });
                            if (data !== undefined) {
                                item.DETALLE_ERP_SOCIO.PRECIO = data.PRECIO
                                item.DETALLE_ERP_SOCIO.NETO = data.NETO
                                item.DETALLE_ERP_SOCIO.DESCUENTO = data.DESCUENTO
                                item.DETALLE_ERP_SOCIO.PVP = data.PVP === undefined ? 0 : data.PVP
                            }
                            return data;
                        }
                    })
                )
            } else {
                referencia.NEUMATICOS.map(async (item, i) => {
                    item.IMAGEN_NEUMATICO = "";
                    item.CANTIDAD_CLIENTE = 1
                })

            }


            let objetoRegistro = actualizarRegistros(referencia.NEUMATICOS, undefined)
            buscarMarcasNeumaticos(referencia.NEUMATICOS)
            setListadoDeReferencias({ ...objetoRegistro.Referencias })

        } else {
            setListadoDeReferencias(null)
        }
    }



    const ObtenerNeumaticosCatalogo = async (REGSPAGE, PAGE, ORDERCOLUM, ORDERDIR, PAYLOAD) => {
        setListadoDeReferencias({})


    
        datosPaginacion.iniPage = 1
        datosPaginacion.inicio = 1
        datosPaginacion.final = datosPaginacion.numPagina
        setDatosPaginacion({ ...datosPaginacion })


        const REGISTROS = await ObtenerNeumaticosCatalogoEnAPI(REGSPAGE, PAGE, ORDERCOLUM, ORDERDIR, PAYLOAD)
      



        modelarRegistros(REGISTROS)
    }



    const filtrarNeumaticos = (listaTodosNeumaticos, PAGINADOR) => {
        let listaDeNeumaticosFiltrados = listaTodosNeumaticos
        if (listaTodosNeumaticos !== null) {

            if (listaTodosNeumaticos.length > 0) {

                
                if (filtroCarga !== "") {
                    listaDeNeumaticosFiltrados = listaTodosNeumaticos.filter(neumatico => neumatico.CARGA === filtroCarga);

                } else {
                    listaDeNeumaticosFiltrados = listaTodosNeumaticos
                }


                if (listaCamino.INVIERNO || listaCamino.VERANO || listaCamino.ALLSEASON) {

                    listaDeNeumaticosFiltrados = listaDeNeumaticosFiltrados.filter(neumatico => neumatico.TEMPORADA === (listaCamino.INVIERNO ? "I" : "") || neumatico.TEMPORADA === (listaCamino.VERANO ? "V" : "") || neumatico.TEMPORADA === (listaCamino.ALLSEASON ? "T" : ""));
                }


                if (esRunFlat) {
                    listaDeNeumaticosFiltrados = listaDeNeumaticosFiltrados.filter(neumatico => neumatico.RUN_FLAT === esRunFlat);
                }

                if (listaCamino.NIEVE && listaCamino.ALPINO) {
                    listaDeNeumaticosFiltrados = listaDeNeumaticosFiltrados.filter(neumatico => neumatico.NIEVE === listaCamino.NIEVE && neumatico.ALPINO === listaCamino.ALPINO);
                } else {
                    if (listaCamino.NIEVE) {
                        listaDeNeumaticosFiltrados = listaDeNeumaticosFiltrados.filter(neumatico => neumatico.NIEVE === listaCamino.NIEVE && neumatico.ALPINO === false);
                    }

                    if (listaCamino.ALPINO) {
                        listaDeNeumaticosFiltrados = listaDeNeumaticosFiltrados.filter(neumatico => neumatico.ALPINO === listaCamino.ALPINO && neumatico.NIEVE === false);
                    }
                }




                let LISTA_MEDIDAS = FILTRO_LOCAL.MEDIDAS_SELECCIONADAS.filter((registro) => registro.SELECCIONADO && registro.ID !== -1).map((registro) => { return registro.MEDIDA_UNIFICADA });
                if (LISTA_MEDIDAS.length > 0) {
                    listaDeNeumaticosFiltrados = listaDeNeumaticosFiltrados.filter(neumatico => LISTA_MEDIDAS.includes(neumatico.ANCHO_LARGO_LLANTA));
                }

                if (FILTRO_LOCAL.MARCA_SELECCIONADA !== "") {
                    listaDeNeumaticosFiltrados = listaDeNeumaticosFiltrados.filter(neumatico => neumatico.ID_MARCA === FILTRO_LOCAL.MARCA_SELECCIONADA);
                }

                if (FILTRO_LOCAL.ANCHO_SELECCIONADO !== "") {
                    listaDeNeumaticosFiltrados = listaDeNeumaticosFiltrados.filter(neumatico => neumatico.ANCHURA === FILTRO_LOCAL.ANCHO_SELECCIONADO);
                }

                if (FILTRO_LOCAL.PERFIL_SELECCIONADO !== "") {
                    listaDeNeumaticosFiltrados = listaDeNeumaticosFiltrados.filter(neumatico => neumatico.ALTURA === FILTRO_LOCAL.PERFIL_SELECCIONADO);
                }

                if (FILTRO_LOCAL.LLANTA_SELECCIONADO !== "") {
                    listaDeNeumaticosFiltrados = listaDeNeumaticosFiltrados.filter(neumatico => neumatico.DIAMETRO === FILTRO_LOCAL.LLANTA_SELECCIONADO);
                }

                if (FILTRO_LOCAL.VELOCIDAD_SELECCIONADA !== "") {
                    listaDeNeumaticosFiltrados = listaDeNeumaticosFiltrados.filter(neumatico => neumatico.VELOCIDAD === FILTRO_LOCAL.VELOCIDAD_SELECCIONADA);
                }

                if (FILTRO_LOCAL.CARGA_SELECCIONADO !== "") {
                    listaDeNeumaticosFiltrados = listaDeNeumaticosFiltrados.filter(neumatico => neumatico.CARGA === FILTRO_LOCAL.CARGA_SELECCIONADO);
                }

                if (FILTRO_LOCAL.REFERENCIA_FABRICANTE_SELECCIONADA !== "") {
                    listaDeNeumaticosFiltrados = listaDeNeumaticosFiltrados.filter(neumatico => neumatico.REFERENCIA_FABRICANTE.includes(FILTRO_LOCAL.REFERENCIA_FABRICANTE_SELECCIONADA) || neumatico.REFERENCIA_FABRICANTE === FILTRO_LOCAL.REFERENCIA_FABRICANTE_SELECCIONADA);
                }


                if (listaCamino.TURISMO || listaCamino.FURGON || listaCamino.CAMION) {

                    listaDeNeumaticosFiltrados = listaDeNeumaticosFiltrados.filter(neumatico => neumatico.CLASE === (listaCamino.TURISMO ? "C1" : "") || neumatico.CLASE === (listaCamino.FURGON ? "C2" : "") || neumatico.CLASE === (listaCamino.CAMION ? "C3" : ""));
                }


            }
        }

        if (listadoDeReferencias.neumaticos.length !== listaDeNeumaticosFiltrados.length) {
            listadoDeReferencias.neumaticos = listaDeNeumaticosFiltrados;
            setListadoDeReferencias(listadoDeReferencias)
        }

        return listaDeNeumaticosFiltrados
    }




    const updateNumberPage = (current, size) => {
        datosPaginacion.iniPage = 1;

        datosPaginacion.inicio = 1;
        datosPaginacion.final = size;
        datosPaginacion.numPagina = size;

        setDatosPaginacion({ ...datosPaginacion })
    }



    const añadirALaCesta = (registro, CANTIDAD) => {

        store.dispatch({ type: 'STATE_ES_NEUMATICO', NEUMATICO: { ES_NEUMATICO: true, REFERENCIA_FABRICANTE: registro.REFERENCIA_FABRICANTE } })

        if (CANTIDAD === "") {
            MSGERROR(traducirPagina("ingrese_cantidad"))
        } else if (registro.DETALLE_ERP_SOCIO.STOCK_TOTAL === 0 ? false : parseInt(CANTIDAD) > registro.DETALLE_ERP_SOCIO.STOCK_TOTAL) {
            MSGERROR(`${traducirPagina("cantidad_maxima")} ${registro.DETALLE_ERP_SOCIO.STOCK_TOTAL}`)
        } else {
            let esMostrador = verificarSiEsMostrador()
            if (esMostrador && clienteSeleccionado.nombre === "") {
                MSGERROR(traducirPagina("Msg_vender"))
            } else {
                const PERMITIR_COMPRA = verificarCantidadCesta(registro, COMPRAR_SIN_DISPONIBILIDAD);

                const { TIPO_STOCK, ABRIR_FICHA } = condicionesAbrirCestav2(registro, CANTIDAD);

                if (ABRIR_FICHA) {
                    registro.CANTIDAD_SELECCIONADA = CANTIDAD;
                    setReferenciaSeleccionada({
                        CANTIDAD_SELECCIONADA: CANTIDAD, REFERENCIA: "", CODIGO_MARCA: "", REFERENCIA_PROVEEDOR: registro.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO, REGISTROS: registro.DETALLE_ERP_SOCIO
                    })
                    setNeumaticoSeleccionado(registro)
                    setAAbrirCestaNeumatico(true)

                   
                } else {
                  
                    ObtenerDesgloseStockNeumaticoEnAPI(registro.DETALLE_ERP_SOCIO.REFERENCIA_FABRICANTE,registro.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO , registro.EAN,registro.ID_MARCA).then(item => {

                        const REGISTRO = {
                            REFERENCIA_PROVEEDOR: registro.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO,
                            REFERENCIA: registro.DETALLE_ERP_SOCIO.REFERENCIA_FABRICANTE,
                            CODIGO_MARCA: registro.ID_MARCA,
                            NOMRE_MARCA: registro.DESCRIPCION_1,
                            CANTIDAD: CANTIDAD,
                            PRECIO: registro.DETALLE_ERP_SOCIO.PRECIO,
                            DESCUENTO: registro.DETALLE_ERP_SOCIO.DESCUENTO,
                            NETO: registro.DETALLE_ERP_SOCIO.NETO,
                            DESCRIPCION_CESTA: "[ " + registro.MARCA_DESCRIPCION.toUpperCase() + " ] - " + registro.DESCRIPCION_1.toUpperCase(),
                        }

                        cestaAutomatica(item.Stock, TIPO_STOCK, CANTIDAD, REGISTRO);
                    })
                }


/*
                if (!ABRIR_FICHA || (COMPRAR_SIN_DISPONIBILIDAD ? !PERMITIR_COMPRA : false)) {

                    ObtenerDesgloseStockNeumaticoEnAPI(registro.DETALLE_ERP_SOCIO.REFERENCIA_FABRICANTE,registro.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO , registro.EAN,registro.ID_MARCA).then(item => {

                        const REGISTRO = {
                            REFERENCIA_PROVEEDOR: registro.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO,
                            REFERENCIA: registro.DETALLE_ERP_SOCIO.REFERENCIA_FABRICANTE,
                            CODIGO_MARCA: registro.ID_MARCA,
                            NOMRE_MARCA: registro.DESCRIPCION_1,
                            CANTIDAD: CANTIDAD,
                            PRECIO: registro.DETALLE_ERP_SOCIO.PRECIO,
                            DESCUENTO: registro.DETALLE_ERP_SOCIO.DESCUENTO,
                            NETO: registro.DETALLE_ERP_SOCIO.NETO,
                            DESCRIPCION_CESTA: "[ " + registro.MARCA_DESCRIPCION.toUpperCase() + " ] - " + registro.DESCRIPCION_1.toUpperCase(),
                        }

                        cestaAutomatica(item.Stock, TIPO_STOCK, CANTIDAD, REGISTRO);
                    })
                } else {
                    registro.CANTIDAD_SELECCIONADA = CANTIDAD;
                    setReferenciaSeleccionada({
                        CANTIDAD_SELECCIONADA: CANTIDAD, REFERENCIA: "", CODIGO_MARCA: "", REFERENCIA_PROVEEDOR: registro.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO, REGISTROS: registro.DETALLE_ERP_SOCIO
                    })
                    setNeumaticoSeleccionado(registro)
                    setAAbrirCestaNeumatico(true)

                }
*/

            }

        }
    }


    const abrirDesglose = (item) => {


        store.dispatch({ type: 'STATE_ES_NEUMATICO', NEUMATICO: { ES_NEUMATICO: true, REFERENCIA_FABRICANTE: item.REFERENCIA_FABRICANTE, EAN: item.EAN } });

        if (!obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE) {
            setNeumaticoSeleccionado(item)
            setAbrirStockNeumatico(true)

            referenciaActualSelect({ REFERENCIA: "", CODIGO_MARCA: item.ID_MARCA, REFERENCIA_PROVEEDOR: item.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO, REGISTROS: item.DETALLE_ERP_SOCIO });

        }
    }



    const buscarMarcasNeumaticos = (neumaticos) => {
        let agrupacionMarcas = neumaticos.map(item => item.MARCA_DESCRIPCION)
            .filter((value, index, self) => self.indexOf(value) === index)
        agrupacionMarcas = agrupacionMarcas.sort((a, b) => a.localeCompare(b));

        setMarcasNeumaticos(agrupacionMarcas)
    }


    const vistaPrevia = () => {
        const {
            REFERENCIA_FABRICANTE_SELECCIONADA,
            MEDIDAS_SELECCIONADAS,
            MEDIDA_SELECCIONADA,
            MARCA_SELECCIONADA,
            ANCHO_SELECCIONADO,
            PERFIL_SELECCIONADO,
            LLANTA_SELECCIONADO,
            CARGA_SELECCIONADO,
            VELOCIDAD_SELECCIONADA
        } = FILTRO_LOCAL

        //if (ANCHO_LARGO_LLANTA !== "" || payLoadLocal.ID_DIAMETRO !== "" || payLoadLocal.REFERENCIA_FABRICANTE !== "") {



        setListadoDeReferencias({})


        // if (MEDIDA_SELECCIONADA !== "") {
        ObtenerNeumaticosCatalogo(10000, 1, 1, "A", {
            ID_NEUMATICO: 0,
            ANCHO: ANCHO_SELECCIONADO,
            LARGO: PERFIL_SELECCIONADO,
            LLANTA: LLANTA_SELECCIONADO,
            CARGA: CARGA_SELECCIONADO,
            VELOCIDAD: VELOCIDAD_SELECCIONADA,
            MARCA: MARCA_SELECCIONADA,
            TEMPORADA: "",
            RUNFLAT: "",
            ANCHO_LARGO_LLANTA: MEDIDA_SELECCIONADA,
            REFERENCIA_FABRICANTE: REFERENCIA_FABRICANTE_SELECCIONADA
        });
        //}

        setInitCatalogoNeumatico(false);

    }





    const asignarOrdenANeumaticos = (neumaticos) => {
        let filtro = neumaticos

        if (neumaticos !== undefined) {
            let verdes = []
            let naranjas = []
            let rojos = []
            let rojos_sin_precio = []
            let rojos_sin_precio_sin_proveedor = []
            neumaticos.map((neumatico) => {
                if (neumatico.DETALLE_ERP_SOCIO !== null) {
                    neumatico.STOCK_ALMACEN = neumatico.DETALLE_ERP_SOCIO.STOCK_ALMACEN;
                    neumatico.STOCK_TOTAL = neumatico.DETALLE_ERP_SOCIO.STOCK_TOTAL;
                    neumatico.PRECIO = neumatico.DETALLE_ERP_SOCIO.PRECIO;
                    neumatico.REFERENCIA_FABRICANTE = neumatico.DETALLE_ERP_SOCIO.REFERENCIA_FABRICANTE;
                    neumatico.NETO = neumatico.DETALLE_ERP_SOCIO.NETO;
                } else {
                    neumatico.STOCK_ALMACEN = 0;
                    neumatico.STOCK_TOTAL = 0;
                    neumatico.PRECIO = 0;
                    neumatico.NETO = 0;
                    neumatico.REFERENCIA_FABRICANTE = neumatico.REFERENCIA_FABRICANTE;
                }
            })


            neumaticos.map((item) => {
                if (item.STOCK_ALMACEN > 0) {
                    verdes.push(item)
                }

                if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL > 0) {
                    naranjas.push(item)
                }

                if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 && item.PRECIO > 0) {
                    rojos.push(item)
                }
                if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 && item.PRECIO === 0 && item.REFERENCIA_FABRICANTE !== "") {
                    rojos_sin_precio.push(item)
                }

                if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 && item.PRECIO === 0 && item.REFERENCIA_FABRICANTE === "") {
                    rojos_sin_precio_sin_proveedor.push(item)
                }
            })

            //ORDEN 
            //PRECIO

            verdes.sort(dynamicSortAsc("NETO"))
            verdes.sort(dynamicSortDesc("ORDEN_MARCA"))

            naranjas.sort(dynamicSortAsc("NETO"))
            naranjas.sort(dynamicSortDesc("ORDEN_MARCA"))

            rojos.sort(dynamicSortAsc("NETO"))
            rojos.sort(dynamicSortDesc("ORDEN_MARCA"))

            let preciosFinal = [...verdes, ...naranjas, ...rojos, ...rojos_sin_precio, ...rojos_sin_precio_sin_proveedor];

            filtro = preciosFinal;
        }
        return filtro
    }


    const resetNeumaticos = () => {
        setListadoDeReferencias(-1)


        REGISTROS_NEUMATICOS.NEUMATICOS = [];
        REGISTROS_NEUMATICOS.MARCAS = [];
        REGISTROS_NEUMATICOS.MEDIDAS = [];
        REGISTROS_NEUMATICOS.ANCHO_NEUMATICO = [];
        REGISTROS_NEUMATICOS.PERFIL_NEUMATICO = [];
        REGISTROS_NEUMATICOS.LLANTA_NEUMATICO = [];
        REGISTROS_NEUMATICOS.VELOCIDAD_NEUMATICO = [];
        REGISTROS_NEUMATICOS.CARGA_NEUMATICO = [];
        REGISTROS_NEUMATICOS.ABRIR_MEDIDAS = false;


        FILTRO_LOCAL.MEDIDAS_SELECCIONADAS = [];
        FILTRO_LOCAL.MEDIDA_SELECCIONADA = "";
        FILTRO_LOCAL.MARCA_SELECCIONADA = "";
        FILTRO_LOCAL.ANCHO_SELECCIONADO = "";
        FILTRO_LOCAL.PERFIL_SELECCIONADO = "";
        FILTRO_LOCAL.LLANTA_SELECCIONADO = "";
        FILTRO_LOCAL.VELOCIDAD_SELECCIONADA = "";
        FILTRO_LOCAL.CARGA_SELECCIONADO = "";
        FILTRO_LOCAL.REFERENCIA_FABRICANTE_SELECCIONADA = "";

        neumaticoMarcas("").then((resultado) => {
            REGISTROS_NEUMATICOS.MARCAS = resultado === null ? [] : resultado
            setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS })

        })


        neumaticosAnchura("").then((resultado) => {
            REGISTROS_NEUMATICOS.ANCHO_NEUMATICO = resultado === null ? [] : resultado
            setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS })
        })



        setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS })
        setFILTRO_LOCAL({ ...FILTRO_LOCAL })

    }

    const recalcularPaginacion = () => {
        const { final, numPagina } = datosPaginacion;
        let ALTURA_PANTALLA = window.innerHeight - alturaSubmenu;
        let CALCULO_LINEAS_TABLA = ((ALTURA_PANTALLA) - 250) / 83
        CALCULO_LINEAS_TABLA = Math.round(CALCULO_LINEAS_TABLA)
        if (numPagina !== CALCULO_LINEAS_TABLA) {
            datosPaginacion.iniPage = 1;
            datosPaginacion.inicio = 1;
            datosPaginacion.final = CALCULO_LINEAS_TABLA;

            datosPaginacion.numPagina = CALCULO_LINEAS_TABLA;
            setDatosPaginacion({ ...datosPaginacion })
        }
    }


    const buscarRegistroEnFiltro = (FILTRO_LOCAL, EVENTO) => {

        if (EVENTO === undefined && props.ID_VEHICULO === -1) {
            const {
                MEDIDAS_SELECCIONADAS,
                MEDIDA_SELECCIONADA,
                MARCA_SELECCIONADA,
                ANCHO_SELECCIONADO,
                PERFIL_SELECCIONADO,
                LLANTA_SELECCIONADO,
                VELOCIDAD_SELECCIONADA,
                CARGA_SELECCIONADO,
                REFERENCIA_FABRICANTE_SELECCIONADA,
            } = FILTRO_LOCAL


            if (MEDIDA_SELECCIONADA !== "" || MARCA_SELECCIONADA !== "" || ANCHO_SELECCIONADO !== "" || PERFIL_SELECCIONADO !== "" || LLANTA_SELECCIONADO !== "" || VELOCIDAD_SELECCIONADA !== "" || CARGA_SELECCIONADO !== "" || REFERENCIA_FABRICANTE_SELECCIONADA !== "") {
                vistaPrevia();
            } else {
                resetNeumaticos();
            }
        }


        datosPaginacion.iniPage = 1
        datosPaginacion.inicio = 1
        datosPaginacion.final = datosPaginacion.numPagina
        setDatosPaginacion({ ...datosPaginacion })


    }



    const pintarRecuadroImagen = (coordenadas, alturaActual) => {
        let heightDinamico = (window.innerHeight) / 2;
        let widthDinamico = (window.innerWidth) / 2;




        let img = document.getElementById("img_neumatico_medida");
        let scala = 0;
        let resultado = {
            marginTop: '',
            marginLeft: '',
            height: '',
            width: '',
            color: '#000',
        }

        if (img !== null) {
            scala = img.width / img.naturalWidth

            resultado.marginTop = (heightDinamico + coordenadas.Y1) + "px";

            if (coordenadas.X1 < 0) {
                resultado.marginLeft = (widthDinamico + Math.abs(coordenadas.X1)) + "px";

            } else {
                resultado.marginLeft = (widthDinamico - coordenadas.X1) + "px";
            }

        
        }
        return resultado;
    }


    React.useEffect(() => {
        if (ref.current !== null) {
            setAlturaSubmenu(ref.current.clientHeight)
        }

        recalcularPaginacion()
    })

    React.useEffect(() => {
        setListadoDeReferencias({})
        const { TOKEN_TECNICO_DIST } = credencialesHaynes();



        if (props.ID_VEHICULO !== -1) {
            setInitCatalogoNeumatico(false);
            ObtenerNeumaticosVehiculo(props.ID_VEHICULO, TOKEN_TECNICO_DIST)
        } else {
            resetNeumaticos()
        }



        if (props.MEDIDAS !== "") {
            FILTRO_LOCAL.MEDIDA_SELECCIONADA = props.MEDIDAS
            setFILTRO_LOCAL({ ...FILTRO_LOCAL })
            vistaPrevia()
        }


        setTimeout(() => {
            if (inputRef.current !== null) {
                inputRef.current.focus({
                    cursor: 'start',
                });
            }

        }, 1000)

    }, [])




    return (
        <div>
            <Drawer
                width={"90%"}
                onClose={() => {

                    if (props.TIPO === "MASTER") {
                        props.CERRAR.setState({ ABRIR_FICHA_NEUMATICOS_ERP: false })
                    } else {
                        props.CERRAR_FICHA(false)
                    }
                }}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                closable={false}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>

                <div className="flex-container-Grow-Tercero">
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {

                            if (props.TIPO === "MASTER") {
                                props.CERRAR.setState({ ABRIR_FICHA_NEUMATICOS_ERP: false })
                            } else {
                                props.CERRAR_FICHA(false)
                            }


                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>

                {

                    <>
                        <div className='menu_filtros_neumaticos' ref={ref}>
                            <div className='menu_filtros_neumaticos_1'>

                                {
                                    visualizacionDescuento ?
                                        <div style={{ visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }} onClick={() => setVisualizacionDescuento(false)} >
                                            <EyeOutlined />
                                        </div>
                                        :
                                        <div style={{ visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }} onClick={() => { setVisualizacionDescuento(true) }} >
                                            <EyeInvisibleOutlined />
                                        </div>

                                }

                                <div>
                                    <img id={"TURISMO"} title={traducirPagina("Turismo").toUpperCase()} src="./imagenes/cocheNegro.png" style={{
                                        height: '30px', cursor: 'pointer',
                                        opacity: listaCamino.TURISMO ? '' : '0.3'
                                    }}
                                        onClick={() => {
                                            listaCamino.TURISMO = !listaCamino.TURISMO
                                            setListaCamino({ ...listaCamino })

                                        }}></img>

                                </div>

                                <div>
                                    <img id={"FURGON"} title={traducirPagina("Furgon").toUpperCase()} src="./imagenes/neumaticos/furgoneta.png" style={{
                                        height: '30px', cursor: 'pointer',
                                        opacity: listaCamino.FURGON ? '' : '0.3'
                                    }}
                                        onClick={() => {
                                            listaCamino.FURGON = !listaCamino.FURGON
                                            setListaCamino({ ...listaCamino })

                                        }}></img>

                                </div>

                                <div>
                                    <img id={"CAMION"} title={traducirPagina("Camion").toUpperCase()} src="./imagenes/camion.png" style={{
                                        height: '30px', cursor: 'pointer',
                                        opacity: listaCamino.CAMION ? '' : '0.3'
                                    }}
                                        onClick={() => {
                                            listaCamino.CAMION = !listaCamino.CAMION
                                            setListaCamino({ ...listaCamino })
                                        }}></img>
                                </div>


                                <div>
                                    <img title={traducirPagina("Invierno").toUpperCase()} src="./imagenes/nieve.png" style={{
                                        height: '24px', cursor: 'pointer',
                                        opacity: listaCamino.INVIERNO ? '' : '0.3'
                                    }}
                                        onClick={() => {
                                            listaCamino.INVIERNO = !listaCamino.INVIERNO
                                            setListaCamino({ ...listaCamino })
                                        }}></img>
                                </div>

                                <div>
                                    <img title={traducirPagina("Verano").toUpperCase()} src="./imagenes/verano.png" style={{
                                        height: '24px', cursor: 'pointer',
                                        opacity: listaCamino.VERANO ? '' : '0.3'
                                    }}
                                        onClick={() => {

                                            listaCamino.VERANO = !listaCamino.VERANO
                                            setListaCamino({ ...listaCamino })
                                        }}></img>
                                </div>

                                <div>
                                    <span title={traducirPagina("Todas_las_temporadas").toUpperCase()} style={{
                                        color: '#000', fontSize: '14px', cursor: 'pointer',
                                        opacity: listaCamino.ALLSEASON ? '' : '0.3'
                                    }}
                                        onClick={() => {

                                            listaCamino.ALLSEASON = !listaCamino.ALLSEASON
                                            setListaCamino({ ...listaCamino })
                                        }} >{traducirPagina("Todas_las_temporadas").toUpperCase()}</span>
                                </div>



                                <div>
                                    <img src="./imagenes/neumaticos/nube_nieve.png" style={{ height: '24px', cursor: 'pointer', opacity: listaCamino.NIEVE ? '' : '0.3' }} onClick={() => {

                                        listaCamino.NIEVE = !listaCamino.NIEVE
                                        setListaCamino({ ...listaCamino })
                                    }}></img>
                                </div>

                                <div>
                                    <img src="./imagenes/neumaticos/montanas.png" style={{ height: '24px', cursor: 'pointer', opacity: listaCamino.ALPINO ? '' : '0.3' }} onClick={() => {

                                        listaCamino.ALPINO = !listaCamino.ALPINO
                                        setListaCamino({ ...listaCamino })
                                    }}></img>
                                </div>



                            </div>

                            <div className='menu_filtros_neumaticos_2'>

                                {
                                    props.ID_VEHICULO !== -1 ?

                                        REGISTROS_NEUMATICOS.MEDIDAS === null ?
                                            null
                                            :
                                            REGISTROS_NEUMATICOS.MEDIDAS.length > 0 ?
                                                <div className='medidas_neumaticos'>
                                                    <div className='container_medidas_seleccionada'>
                                                        <div className='item_medidas_seleccionada'>
                                                            {
                                                                REGISTROS_NEUMATICOS.MEDIDAS.length - 1 === REGISTROS_NEUMATICOS.MEDIDAS.filter((item) => item.SELECCIONADO).length - 1 ?
                                                                    REGISTROS_NEUMATICOS.MEDIDAS.filter((item) => item.ID === -1).map((registro) =>

                                                                        <div>
                                                                            {registro.MEDIDA}
                                                                            <CloseOutlined onClick={(e) => {
                                                                                REGISTROS_NEUMATICOS.MEDIDAS.map((registro) =>
                                                                                    registro.SELECCIONADO = false
                                                                                )

                                                                                REGISTROS_NEUMATICOS.MEDIDAS = REGISTROS_NEUMATICOS.MEDIDAS;
                                                                                REGISTROS_NEUMATICOS.ABRIR_MEDIDAS = true;
                                                                                setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS })

                                                                                buscarMedidasSeleccionadas(REGISTROS_NEUMATICOS.MEDIDAS)

                                                                                implementarFiltros(REGISTROS_NEUMATICOS, FILTRO_LOCAL)


                                                                            }}></CloseOutlined>
                                                                        </div>
                                                                    )

                                                                    :

                                                                    REGISTROS_NEUMATICOS.MEDIDAS.filter((item) => item.SELECCIONADO).map((registro) =>
                                                                        <div>
                                                                            {registro.MEDIDA}
                                                                            <CloseOutlined onClick={(e) => {
                                                                                registro.SELECCIONADO = false
                                                                                REGISTROS_NEUMATICOS.MEDIDAS = REGISTROS_NEUMATICOS.MEDIDAS;
                                                                                setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS })
                                                                                buscarMedidasSeleccionadas(REGISTROS_NEUMATICOS.MEDIDAS)

                                                                                implementarFiltros(REGISTROS_NEUMATICOS, FILTRO_LOCAL)

                                                                            }}></CloseOutlined>
                                                                        </div>
                                                                    )

                                                            }

                                                        </div>

                                                        <div className='barraLateral'></div>
                                                        <div className='btn_medidas'>
                                                            {
                                                                REGISTROS_NEUMATICOS.ABRIR_MEDIDAS ?
                                                                    <UpOutlined onClick={() => { REGISTROS_NEUMATICOS.ABRIR_MEDIDAS = false; setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS }) }}></UpOutlined>
                                                                    :
                                                                    <DownOutlined onClick={() => { REGISTROS_NEUMATICOS.ABRIR_MEDIDAS = true; setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS }) }}></DownOutlined>

                                                            }
                                                        </div>
                                                    </div>

                                                    {REGISTROS_NEUMATICOS.ABRIR_MEDIDAS ?
                                                        <div className='container_lista_medidas'>
                                                            <ul className='ulTree_mediddas'>
                                                                {REGISTROS_NEUMATICOS.MEDIDAS.map((registro) =>
                                                                    <>
                                                                        <li className='li_tree_medidas'>
                                                                            <div>
                                                                                {
                                                                                    registro.ID === -1 ?
                                                                                        <div style={{ marginLeft: '15px' }}></div>
                                                                                        :
                                                                                        registro.VER_INFORMACION ?
                                                                                            <DownOutlined onClick={() => {
                                                                                                registro.VER_INFORMACION = false
                                                                                                REGISTROS_NEUMATICOS.MEDIDAS = REGISTROS_NEUMATICOS.MEDIDAS;
                                                                                                setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS })


                                                                                            }} ></DownOutlined>
                                                                                            :
                                                                                            <RightOutlined onClick={() => {
                                                                                                registro.VER_INFORMACION = true
                                                                                                REGISTROS_NEUMATICOS.MEDIDAS = REGISTROS_NEUMATICOS.MEDIDAS;
                                                                                                setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS })


                                                                                            }}></RightOutlined>
                                                                                }

                                                                                <Checkbox checked={registro.SELECCIONADO} onChange={(e) => {
                                                                                    if (registro.ID === -1) {
                                                                                        REGISTROS_NEUMATICOS.MEDIDAS.map((registro) =>
                                                                                            registro.SELECCIONADO = e.target.checked
                                                                                        )
                                                                                    } else {
                                                                                        REGISTROS_NEUMATICOS.MEDIDAS[0].SELECCIONADO = false
                                                                                        registro.SELECCIONADO = e.target.checked
                                                                                        REGISTROS_NEUMATICOS.MEDIDAS = REGISTROS_NEUMATICOS.MEDIDAS;
                                                                                    }

                                                                                    setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS })
                                                                                    buscarMedidasSeleccionadas(REGISTROS_NEUMATICOS.MEDIDAS)
                                                                                    implementarFiltros(REGISTROS_NEUMATICOS, FILTRO_LOCAL)

                                                                                    datosPaginacion.iniPage = 1
                                                                                    datosPaginacion.inicio = 1
                                                                                    datosPaginacion.final = datosPaginacion.numPagina
                                                                                    setDatosPaginacion({ ...datosPaginacion })


                                                                                }}>
                                                                                    {`${registro.DESCRIPCION} ${registro.MEDIDA}`}
                                                                                </Checkbox>
                                                                            </div>
                                                                        </li>
                                                                        {
                                                                            registro.VER_INFORMACION ?
                                                                                <div className='info_medidas'>
                                                                                    {registro.PROPIEDADES.map((item) =>
                                                                                        <p>{item}</p>
                                                                                    )}
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </>
                                                                )}
                                                            </ul>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                :
                                                null
                                        :

                                        <Input {...sharedProps} placeholder={traducirPagina("Medidas_unificadas")} allowClear onChange={(e) => {
                                            //setANCHO_LARGO_LLANTA(e.target.value === null ? "" : e.target.value)
                                            FILTRO_LOCAL.MEDIDA_SELECCIONADA = e.target.value === null ? "" : e.target.value
                                            setFILTRO_LOCAL({ ...FILTRO_LOCAL })
                                            let VERIFICAR_MEDIDA = (e.target.value === "" && undefined);
                                            if (VERIFICAR_MEDIDA === undefined) {
                                                resetNeumaticos();
                                            }
                                        }} value={FILTRO_LOCAL.MEDIDA_SELECCIONADA} size={"large"} style={{ width: "180px" }} onKeyPress={(e) => e.key === 'Enter' ? vistaPrevia() : {}} />

                                }

                                <div>
                                    {REGISTROS_NEUMATICOS.MARCAS === null ?
                                        null
                                        :
                                        <Select
                                            showSearch
                                            size={"large"}
                                            disabled={REGISTROS_NEUMATICOS.MARCAS.length === 0}
                                            allowClear={true}
                                            placeholder={traducirPagina("Marca")}
                                            value={FILTRO_LOCAL.MARCA_SELECCIONADA !== "" ? FILTRO_LOCAL.MARCA_SELECCIONADA : traducirPagina("Marca")}
                                            style={{ width: "120px" }}
                                            optionFilterProp="children"
                                            onChange={(e) => {

                                                let SELECCIONADO = e === undefined || e === null ? "" : e
                                                FILTRO_LOCAL.MARCA_SELECCIONADA = SELECCIONADO

                                                if (props.ID_VEHICULO === -1) {
                                                    neumaticosAnchura(FILTRO_LOCAL.MARCA_SELECCIONADA).then((resultado) => {
                                                        REGISTROS_NEUMATICOS.ANCHO_NEUMATICO = resultado === null ? [] : resultado
                                                        setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS })
                                                    })

                                                    FILTRO_LOCAL.ANCHO_SELECCIONADO = ""
                                                    FILTRO_LOCAL.PERFIL_SELECCIONADO = ""
                                                    FILTRO_LOCAL.LLANTA_SELECCIONADO = ""
                                                    FILTRO_LOCAL.CARGA_SELECCIONADO = ""
                                                    FILTRO_LOCAL.VELOCIDAD_SELECCIONADA = ""

                                                    // REGISTROS_NEUMATICOS.ANCHO_NEUMATICO = []
                                                    REGISTROS_NEUMATICOS.LLANTA_NEUMATICO = []
                                                    REGISTROS_NEUMATICOS.CARGA_NEUMATICO = []
                                                    REGISTROS_NEUMATICOS.VELOCIDAD_NEUMATICO = []
                                                    setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS })

                                                } else {
                                                    implementarFiltros(REGISTROS_NEUMATICOS, FILTRO_LOCAL)
                                                }


                                                setFILTRO_LOCAL({ ...FILTRO_LOCAL })

                                                buscarRegistroEnFiltro(FILTRO_LOCAL, e)


                                            }}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {REGISTROS_NEUMATICOS.MARCAS.length > 0 ?
                                                REGISTROS_NEUMATICOS.MARCAS.map((registro, i) =>
                                                    <Option value={registro.ID} key={registro.ID}>{registro.DESCRIPCION}</Option>
                                                )
                                                :
                                                null
                                            }
                                        </Select>
                                    }
                                </div>




                                {REGISTROS_NEUMATICOS.ANCHO_NEUMATICO === null ?
                                    null
                                    :
                                    <Select
                                        showSearch
                                        size={"large"}
                                        disabled={REGISTROS_NEUMATICOS.ANCHO_NEUMATICO.length === 0}
                                        value={FILTRO_LOCAL.ANCHO_SELECCIONADO !== "" ? FILTRO_LOCAL.ANCHO_SELECCIONADO : traducirPagina("Ancho")}

                                        allowClear={true}
                                        placeholder={traducirPagina("Ancho")}
                                        style={{ width: "120px" }}
                                        optionFilterProp="children"
                                        onChange={(e) => {
                                            let SELECCIONADO = e === undefined || e === null ? "" : e
                                            FILTRO_LOCAL.ANCHO_SELECCIONADO = SELECCIONADO




                                            if (props.ID_VEHICULO === -1) {
                                                neumaticosAltura(FILTRO_LOCAL.ANCHO_SELECCIONADO, FILTRO_LOCAL.MARCA_SELECCIONADA).then((resultado) => {
                                                    REGISTROS_NEUMATICOS.PERFIL_NEUMATICO = resultado === null ? [] : resultado
                                                    setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS })
                                                })


                                                FILTRO_LOCAL.PERFIL_SELECCIONADO = ""
                                                FILTRO_LOCAL.LLANTA_SELECCIONADO = ""
                                                FILTRO_LOCAL.CARGA_SELECCIONADO = ""
                                                FILTRO_LOCAL.VELOCIDAD_SELECCIONADA = ""

                                                REGISTROS_NEUMATICOS.LLANTA_NEUMATICO = []
                                                REGISTROS_NEUMATICOS.CARGA_NEUMATICO = []
                                                REGISTROS_NEUMATICOS.VELOCIDAD_NEUMATICO = []
                                                setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS })

                                            } else {
                                                implementarFiltros(REGISTROS_NEUMATICOS, FILTRO_LOCAL)
                                            }

                                            setFILTRO_LOCAL({ ...FILTRO_LOCAL })
                                            buscarRegistroEnFiltro(FILTRO_LOCAL, e)


                                        }}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {REGISTROS_NEUMATICOS.ANCHO_NEUMATICO.length > 0 ?
                                            REGISTROS_NEUMATICOS.ANCHO_NEUMATICO.map((registro, i) =>
                                                <Option value={registro.DESCRIPCION} key={i}>{registro.DESCRIPCION}</Option>
                                            )
                                            :
                                            null
                                        }
                                    </Select>
                                }



                                {REGISTROS_NEUMATICOS.PERFIL_NEUMATICO === null ?
                                    null
                                    :
                                    <Select
                                        showSearch
                                        size={"large"}
                                        disabled={REGISTROS_NEUMATICOS.PERFIL_NEUMATICO.length === 0}
                                        value={FILTRO_LOCAL.PERFIL_SELECCIONADO !== "" ? FILTRO_LOCAL.PERFIL_SELECCIONADO : traducirPagina("Perfil")}

                                        allowClear={true}
                                        placeholder={traducirPagina("Perfil")}
                                        style={{ width: "120px" }}
                                        optionFilterProp="children"
                                        onChange={(e) => {
                                            let SELECCIONADO = e === undefined || e === null ? "" : e
                                            FILTRO_LOCAL.PERFIL_SELECCIONADO = SELECCIONADO





                                            if (props.ID_VEHICULO === -1) {
                                                neumaticosDiametro(FILTRO_LOCAL.ANCHO_SELECCIONADO, FILTRO_LOCAL.PERFIL_SELECCIONADO, FILTRO_LOCAL.MARCA_SELECCIONADA).then((resultado) => {
                                                    REGISTROS_NEUMATICOS.LLANTA_NEUMATICO = resultado === null ? [] : resultado
                                                    setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS })
                                                })


                                                FILTRO_LOCAL.LLANTA_SELECCIONADO = ""
                                                FILTRO_LOCAL.CARGA_SELECCIONADO = ""
                                                FILTRO_LOCAL.VELOCIDAD_SELECCIONADA = ""

                                                REGISTROS_NEUMATICOS.CARGA_NEUMATICO = []
                                                REGISTROS_NEUMATICOS.VELOCIDAD_NEUMATICO = []
                                                setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS })

                                            } else {
                                                implementarFiltros(REGISTROS_NEUMATICOS, FILTRO_LOCAL)
                                            }
                                            setFILTRO_LOCAL({ ...FILTRO_LOCAL })


                                            buscarRegistroEnFiltro(FILTRO_LOCAL, e)

                                        }}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {REGISTROS_NEUMATICOS.PERFIL_NEUMATICO.length > 0 ?
                                            REGISTROS_NEUMATICOS.PERFIL_NEUMATICO.map((registro, i) =>
                                                <Option value={registro.DESCRIPCION} key={i}>{registro.DESCRIPCION}</Option>
                                            )
                                            :
                                            null
                                        }
                                    </Select>
                                }



                                {REGISTROS_NEUMATICOS.LLANTA_NEUMATICO === null ?
                                    null
                                    :
                                    <Select
                                        showSearch
                                        size={"large"}
                                        disabled={REGISTROS_NEUMATICOS.LLANTA_NEUMATICO.length === 0}
                                        value={FILTRO_LOCAL.LLANTA_SELECCIONADO !== "" ? FILTRO_LOCAL.LLANTA_SELECCIONADO : traducirPagina("Llanta")}

                                        allowClear={true}
                                        placeholder={traducirPagina("Llanta")}
                                        style={{ width: "120px" }}
                                        optionFilterProp="children"
                                        onChange={(e) => {
                                            let SELECCIONADO = e === undefined || e === null ? "" : e
                                            FILTRO_LOCAL.LLANTA_SELECCIONADO = SELECCIONADO



                                            if (props.ID_VEHICULO === -1) {

                                                neumaticosCarga(FILTRO_LOCAL.ANCHO_SELECCIONADO, FILTRO_LOCAL.PERFIL_SELECCIONADO, FILTRO_LOCAL.LLANTA_SELECCIONADO, FILTRO_LOCAL.MARCA_SELECCIONADA).then((resultado) => {
                                                    REGISTROS_NEUMATICOS.CARGA_NEUMATICO = resultado === null ? [] : resultado
                                                    setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS })
                                                })


                                                FILTRO_LOCAL.CARGA_SELECCIONADO = ""
                                                FILTRO_LOCAL.VELOCIDAD_SELECCIONADA = ""

                                                REGISTROS_NEUMATICOS.VELOCIDAD_NEUMATICO = []
                                                setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS })

                                            } else {
                                                implementarFiltros(REGISTROS_NEUMATICOS, FILTRO_LOCAL)
                                            }

                                            setFILTRO_LOCAL({ ...FILTRO_LOCAL })
                                            buscarRegistroEnFiltro(FILTRO_LOCAL, e)



                                        }}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {REGISTROS_NEUMATICOS.LLANTA_NEUMATICO.length > 0 ?
                                            REGISTROS_NEUMATICOS.LLANTA_NEUMATICO.map((registro, i) =>
                                                <Option value={registro.DESCRIPCION} key={i}>{registro.DESCRIPCION}</Option>
                                            )
                                            :
                                            null
                                        }
                                    </Select>
                                }


                                {REGISTROS_NEUMATICOS.CARGA_NEUMATICO === null ?
                                    <div></div>
                                    :
                                    <Select
                                        showSearch
                                        size={"large"}
                                        disabled={REGISTROS_NEUMATICOS.CARGA_NEUMATICO.length === 0}
                                        value={FILTRO_LOCAL.CARGA_SELECCIONADO !== "" ? FILTRO_LOCAL.CARGA_SELECCIONADO : traducirPagina("Carga")}

                                        allowClear={true}
                                        placeholder={traducirPagina("Carga")}
                                        style={{ width: "120px" }}
                                        optionFilterProp="children"
                                        onChange={(e) => {
                                            let SELECCIONADO = e === undefined || e === null ? "" : e
                                            FILTRO_LOCAL.CARGA_SELECCIONADO = SELECCIONADO


                                            if (props.ID_VEHICULO === -1) {
                                                neumaticosVelocidad(FILTRO_LOCAL.ANCHO_SELECCIONADO, FILTRO_LOCAL.PERFIL_SELECCIONADO, FILTRO_LOCAL.LLANTA_SELECCIONADO, FILTRO_LOCAL.CARGA_SELECCIONADO, FILTRO_LOCAL.MARCA_SELECCIONADA).then((resultado) => {
                                                    REGISTROS_NEUMATICOS.VELOCIDAD_NEUMATICO = resultado === null ? [] : resultado
                                                    setREGISTROS_NEUMATICOS({ ...REGISTROS_NEUMATICOS })
                                                })
                                                FILTRO_LOCAL.VELOCIDAD_SELECCIONADA = ""


                                            } else {
                                                implementarFiltros(REGISTROS_NEUMATICOS, FILTRO_LOCAL)
                                            }

                                            setFILTRO_LOCAL({ ...FILTRO_LOCAL })
                                            buscarRegistroEnFiltro(FILTRO_LOCAL, e)



                                        }}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {REGISTROS_NEUMATICOS.CARGA_NEUMATICO.length > 0 ?
                                            REGISTROS_NEUMATICOS.CARGA_NEUMATICO.map((registro, i) =>
                                                <Option value={registro.DESCRIPCION} key={i}>{registro.DESCRIPCION}</Option>
                                            )
                                            :
                                            null
                                        }
                                    </Select>
                                }



                                {REGISTROS_NEUMATICOS.VELOCIDAD_NEUMATICO === null ?
                                    null
                                    :
                                    <Select
                                        showSearch
                                        size={"large"}
                                        disabled={REGISTROS_NEUMATICOS.VELOCIDAD_NEUMATICO.length === 0}
                                        value={FILTRO_LOCAL.VELOCIDAD_SELECCIONADA !== "" ? FILTRO_LOCAL.VELOCIDAD_SELECCIONADA : traducirPagina("Velocidad")}

                                        allowClear={true}
                                        placeholder={traducirPagina("Velocidad")}
                                        style={{ width: "120px" }}
                                        optionFilterProp="children"
                                        onChange={(e) => {
                                            let SELECCIONADO = e === undefined || e === null ? "" : e
                                            FILTRO_LOCAL.VELOCIDAD_SELECCIONADA = SELECCIONADO

                                            if (props.ID_VEHICULO !== -1) {
                                                implementarFiltros(REGISTROS_NEUMATICOS, FILTRO_LOCAL)
                                            }

                                            setFILTRO_LOCAL({ ...FILTRO_LOCAL })
                                            buscarRegistroEnFiltro(FILTRO_LOCAL, e)


                                        }}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {REGISTROS_NEUMATICOS.VELOCIDAD_NEUMATICO.length > 0 ?
                                            REGISTROS_NEUMATICOS.VELOCIDAD_NEUMATICO.map((registro, i) =>
                                                <Option value={registro.DESCRIPCION} key={i}>{registro.DESCRIPCION}</Option>
                                            )
                                            :
                                            null
                                        }
                                    </Select>
                                }

                                {
                                    <Input placeholder={traducirPagina("Referencia")} allowClear onChange={(e) => {

                                        let SELECCIONADO = e.target.value === null ? "" : e.target.value
                                        FILTRO_LOCAL.REFERENCIA_FABRICANTE_SELECCIONADA = SELECCIONADO

                                        setFILTRO_LOCAL({ ...FILTRO_LOCAL })
                                        buscarRegistroEnFiltro(FILTRO_LOCAL, (e.target.value === "" && undefined))



                                    }} value={FILTRO_LOCAL.REFERENCIA_FABRICANTE_SELECCIONADA} size={"large"} style={{ width: "150px" }} onKeyPress={(e) => e.key === 'Enter' ? vistaPrevia() : {}} />
                                }





                                {
                                    props.ID_VEHICULO === -1 ?
                                        <div className='container_btn_filtro_neumatico'>
                                            <div className='btn_limpiar_filtros' onClick={() => {
                                                resetNeumaticos();
                                            }}>
                                                <ClearOutlined></ClearOutlined>
                                            </div>
                                            <div className='btn_buscar_filtros' onClick={() => {
                                                vistaPrevia()
                                            }}>
                                                <SearchOutlined></SearchOutlined>
                                            </div>
                                        </div>

                                        :
                                        null
                                }

                            </div>
                        </div>


                        <div className='layout_neumaticos'>

                            {listadoDeReferencias === -1 ?
                                <div style={{ height: `calc(100vh - ${(110 + alturaSubmenu)}px )`, display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto' }}>
                                    <div className='container_neumatico_sin_coche' >
                                        <div className='imagenes_medidas_coche'>
                                            <img id="img_neumatico_medida" loading="lazy" src={`./imagenes/neumaticos/neumatico_medidas.png`} alt='meumatico_medida'></img>

                                            {
                                                textosDinamicos.map((registro, i) =>
                                                    <div className='piezaImagenDocOE descripcion_neumatico' key={i}
                                                        style={pintarRecuadroImagen(registro, (110 + alturaSubmenu))}
                                                    >
                                                        {registro.TEXTO}
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div>
                                            <h1 className='titulo_neumatco_sin_vehiculo'>{traducirPagina("Msg_criterio_neumatico")} </h1>
                                        </div>

                                      
                                    </div>
                                </div>
                                :

                                <>
                                    <div className='container_tabla_neumatico' style={{ height: `calc(100vh - ${(155 + alturaSubmenu)}px )` }}>
                                        {

                                            listadoDeReferencias === null ?
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#fff' }}> <h3>{traducirPagina("No_neumaticos")}</h3> </div>
                                                :
                                                Object.keys(listadoDeReferencias).length > 0 ?
                                                    <table style={{ width: '100%' }} className={"tabla_catalogo_neumatico"} ref={refTabla}>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '40px' }}></th>
                                                                <th style={{ width: '100px' }}>{traducirPagina("Marca").toUpperCase()}</th>
                                                                <th style={{ width: '250px' }}>{traducirPagina("Referencia").toUpperCase()}</th>

                                                                <th>{traducirPagina("Descripción").toUpperCase()}</th>
                                                                <th style={{ width: '100px', textAlign: 'right' }}>{traducirPagina("PVP").toUpperCase()}</th>
                                                                {visualizacionDescuento && <th style={{ width: '100px', textAlign: 'right' }}>{traducirPagina("Precio").toUpperCase()}</th>}
                                                                {visualizacionDescuento && <th style={{ width: '80px', textAlign: 'right' }}>{traducirPagina("Dto").toUpperCase()}</th>}
                                                                {visualizacionDescuento && <th style={{ width: '100px', textAlign: 'right' }}>{traducirPagina("Neto").toUpperCase()}</th>}
                                                                <th style={{ width: '80px' }}></th>

                                                                <th style={{ width: '135px' }}></th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                filtrarNeumaticos(asignarOrdenANeumaticos(listadoDeReferencias.auxNeumaticos), datosPaginacion).map((item, i) =>

                                                                    (i + 1) >= datosPaginacion.inicio && (i + 1) <= datosPaginacion.final ?
                                                                        <tr id="Altura_row_tabla" style={{ height: '83px' }}>
                                                                            <td style={{ verticalAlign: 'middle' }}>
                                                                                <div className='imagen_tabla_neumatico'>
                                                                                    <img
                                                                                        onError={(e) => { e.target.src = "./imagenes/neumatico_ng.png"; }}
                                                                                        src={`${item.IMAGEN}`}
                                                                                    >
                                                                                    </img>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {`${item.MARCA_DESCRIPCION}`}
                                                                            </td>
                                                                            <td>
                                                                                <div>{`${item.REFERENCIA_FABRICANTE}`} </div>
                                                                                <div>{`${item.TIPO_VEHICULO}`} </div>


                                                                            </td>
                                                                            <td>
                                                                                <div> {`${item.DESCRIPCION_1 !== "" ? item.DESCRIPCION_1 : ""} ${item.DESCRIPCION_2 !== "" ? item.DESCRIPCION_2 : ""} ${item.DESCRIPCION_3 !== "" ? item.DESCRIPCION_3 : ""}`}</div>

                                                                                <div className='container_info_neumatico'>
                                                                                    <img alt="resistance" src="/imagenes/neumaticos/gas_bomba.png" style={{ height: '60px' }}></img>

                                                                                    <span className="etiquetaNeumatico_info" style={{ backgroundColor: colorConsumo(item.CONSUMO) }}>{item.CONSUMO}</span>
                                                                                    <img alt="grip" src="./imagenes/neumaticos/lluvias_nubes.png" style={{ height: '60px' }}></img>
                                                                                    <span className="etiquetaNeumatico_info" style={{ backgroundColor: colorAgarre(item.AGARRE) }}>{item.AGARRE} </span>
                                                                                    <img alt="noise" src="./imagenes/neumaticos/volumen.png" style={{ height: '60px' }}></img>
                                                                                    <span className="etiquetaNeumatico_info" style={{ border: 'none', minWidth: '80px' }}>{`${item.ONDAS} ${item.DECIBELIOS}`} </span>

                                                                                    <span className='txt_runflat' style={{ color: '#000', backgroundColor: 'transparent' }}>
                                                                                        {item.CLASE === "C1" ?
                                                                                            <img title={traducirPagina("Turismo")} src="./imagenes/cocheNegro.png" style={{ height: '28px', marginRight: '3px' }} ></img>
                                                                                            : item.CLASE === "C2" ? <img title={traducirPagina("Furgon")} src="./imagenes/coche4x4.png" style={{ height: '28px', marginRight: '3px' }} ></img>
                                                                                                : <img title={"CAMION"} src="./imagenes/camion.png" style={{ height: '28px', marginRight: '3px' }} ></img>
                                                                                        }

                                                                                        {item.CLASE === "C1" ? traducirPagina("Turismo").toUpperCase() :
                                                                                            item.CLASE === "C2" ? traducirPagina("Furgon").toUpperCase() :
                                                                                                traducirPagina("Camion").toUpperCase()
                                                                                        }
                                                                                    </span>

                                                                                    {item.RUN_FLAT ? <span className='txt_runflat' > {"RUNFLAT"} </span> : null}

                                                                                    <span className='txt_runflat' style={{
                                                                                        color: '#000',
                                                                                        backgroundColor: item.TEMPORADA === "V" ? '#F2CD79' : item.TEMPORADA === "I" ? '#92cde2' : '#ccc'
                                                                                    }}>
                                                                                        {item.TEMPORADA === "V" ?
                                                                                            <img title={traducirPagina("Verano").toUpperCase()} src="./imagenes/neumaticos/sol.png" style={{ height: '24px', marginRight: '3px' }} ></img>
                                                                                            : item.TEMPORADA === "I" ?
                                                                                                <img title={traducirPagina("Invierno").toUpperCase()} src="./imagenes/nieve.png" style={{ height: '24px', marginRight: '3px' }} ></img>
                                                                                                :
                                                                                                <img title={traducirPagina("Todas_las_temporadas").toUpperCase()} src="./imagenes/neumaticos/nube_sol_lluvia.png" style={{ height: '24px', marginRight: '3px' }} ></img>

                                                                                        }

                                                                                        {item.TEMPORADA === "V" ?
                                                                                            traducirPagina("Verano").toUpperCase() :
                                                                                            item.TEMPORADA === "I" ?
                                                                                                traducirPagina("Invierno").toUpperCase() :
                                                                                                traducirPagina("Todas_las_temporadas").toUpperCase()}
                                                                                    </span>

                                                                                    <span>
                                                                                        {item.NIEVE ?
                                                                                            <img alt="noise" src="./imagenes/neumaticos/nube_nieve.png" style={{ height: '24px', marginRight: '10px' }}></img>
                                                                                            :
                                                                                            null
                                                                                        }

                                                                                        {item.ALPINO ?
                                                                                            <img alt="noise" src="./imagenes/neumaticos/montanas.png" style={{ height: '24px' }}></img>
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                    </span>
                                                                                </div>


                                                                            </td>
                                                                            <td style={{ textAlign: 'right' }}>
                                                                                {item.REFERENCIA_DISTRIBUIDOR !== "" ?
                                                                                    item.DETALLE_ERP_SOCIO !== null ?
                                                                                        `${convertirMoneda(item.DETALLE_ERP_SOCIO.PVP, " €")}`

                                                                                        : null
                                                                                    :
                                                                                    null
                                                                                }
                                                                            </td>

                                                                            {visualizacionDescuento ?

                                                                                <td style={{ textAlign: 'right' }}>
                                                                                    {item.REFERENCIA_DISTRIBUIDOR !== "" ?
                                                                                        item.DETALLE_ERP_SOCIO !== null ?
                                                                                            `${convertirMoneda(item.DETALLE_ERP_SOCIO.PRECIO, " €")}`

                                                                                            : null
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                </td>
                                                                                :
                                                                                null
                                                                            }

                                                                            {visualizacionDescuento ?
                                                                                <td style={{ textAlign: 'right' }}>
                                                                                    {item.REFERENCIA_DISTRIBUIDOR !== "" ?
                                                                                        item.DETALLE_ERP_SOCIO !== null ?
                                                                                            <div>
                                                                                                {item.DETALLE_ERP_SOCIO.DESCUENTO + " %"}
                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                </td>
                                                                                : null
                                                                            }

                                                                            {visualizacionDescuento ?
                                                                                <td style={{ textAlign: 'right' }}>
                                                                                    {item.REFERENCIA_DISTRIBUIDOR !== "" ?
                                                                                        item.DETALLE_ERP_SOCIO !== null ?
                                                                                            <div >
                                                                                                {convertirMoneda(item.DETALLE_ERP_SOCIO.NETO, " €")}
                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                </td>
                                                                                :
                                                                                null
                                                                            }

                                                                            <td>
                                                                                <div className='container_acciones_tabla'>

                                                                                    <div onClick={() => {
                                                                                        setAbrirFichaDetalle(true)
                                                                                        setNeumaticoSeleccionado(item)
                                                                                    }}>
                                                                                        <EyeOutlined>

                                                                                        </EyeOutlined>
                                                                                    </div>

                                                                                    {item.DETALLE_ERP_SOCIO !== null ?
                                                                                        <div onClick={(e) => {
                                                                                            abrirDesglose(item)
                                                                                      
                                                                                        }} style={{ color: item.DETALLE_ERP_SOCIO.STOCK_ALMACEN > 0 ? '#25c281' : item.DETALLE_ERP_SOCIO.STOCK_ALMACEN === 0 && item.DETALLE_ERP_SOCIO.STOCK_TOTAL > 0 ? '#ff8000' : 'red', backgroundColor: 'transparent', display: verficarSiEsCatalogo(usuario) ? 'none' : '' }}>
                                                                                            {item.DETALLE_ERP_SOCIO.STOCK_ALMACEN === 0 && item.DETALLE_ERP_SOCIO.STOCK_TOTAL === 0 ?
                                                                                                <DislikeOutlined />
                                                                                                : <LikeOutlined />}

                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                    }

                                                                                    {item.DETALLE_ERP_SOCIO !== null ?

                                                                                        <div
                                                                                            onClick={() => {
                                                                                                añadirALaCesta(item, item.CANTIDAD_CLIENTE === null ? "1" : item.CANTIDAD_CLIENTE)

                                                                                            }}>

                                                                                            <ShoppingOutlined ></ShoppingOutlined>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                    }

                                                                                </div>
                                                                            </td>

                                                                            <td>
                                                                                {

                                                                                    item.DETALLE_ERP_SOCIO !== null ?
                                                                                        verificarCantidadCesta({ STOCK_ALMACEN: item.DETALLE_ERP_SOCIO.STOCK_ALMACEN, STOCK_TOTAL: item.DETALLE_ERP_SOCIO.STOCK_TOTAL, PRECIO: item.DETALLE_ERP_SOCIO.PRECIO }, COMPRAR_SIN_DISPONIBILIDAD) ?
                                                                                            null
                                                                                            :
                                                                                            <span className="next-number-picker next-number-picker-inline next-medium zoro-ui-number-picker number-picker product-number-picker" >
                                                                                                <span className="next-input-group">
                                                                                                    <span className="next-input-group-addon next-before">
                                                                                                        <button disabled="" type="button" className="next-btn next-medium next-btn-normal" role="button" onClick={() => {
                                                                                                            if (item.CANTIDAD_CLIENTE !== null) {

                                                                                                                let valor = item.CANTIDAD_CLIENTE
                                                                                                                if (valor) {
                                                                                                                    if (parseInt(valor) <= 1) {
                                                                                                                        item.CANTIDAD_CLIENTE = "1";
                                                                                                                    } else {
                                                                                                                        item.CANTIDAD_CLIENTE = parseInt(valor) - 1;
                                                                                                                    }
                                                                                                                } else {
                                                                                                                    item.CANTIDAD_CLIENTE = "1";
                                                                                                                }

                                                                                                                setListadoDeReferencias({ ...listadoDeReferencias })

                                                                                                            }


                                                                                                        }} >
                                                                                                            <MinusOutlined style={{ fontSize: '16px' }} />
                                                                                                        </button>
                                                                                                    </span>
                                                                                                    <span className="next-input next-medium next-input-group-auto-width">


                                                                                                        <input id={"CANTIDAD_NEUMATICO" + item.MARCA_DESCRIPCION + item.REFERENCIA_DISTRIBUIDOR} className="txtCantidad" aria-valuemax="1798" aria-valuemin="1" height="100%" autoComplete="off" style={{ width: '50px', margin: '5px', height: '24px' }}
                                                                                                            value={item.CANTIDAD_CLIENTE}

                                                                                                            onChange={(e) => {
                                                                                                                if (item.CANTIDAD_CLIENTE !== null) {

                                                                                                                    let valor = e.target.value;
                                                                                                                    if (valor === "") {
                                                                                                                        item.CANTIDAD_CLIENTE = e.target.value;
                                                                                                                    } else {
                                                                                                                        if (soloNumeros(valor)) {
                                                                                                                            item.CANTIDAD_CLIENTE = valor === "0" ? "1" : e.target.value
                                                                                                                        } else {
                                                                                                                            item.CANTIDAD_CLIENTE = "1";
                                                                                                                        }
                                                                                                                    }
                                                                                                                    setListadoDeReferencias({ ...listadoDeReferencias })
                                                                                                                }

                                                                                                            }} />


                                                                                                    </span>
                                                                                                    <span className="next-input-group-addon next-after">
                                                                                                        <button type="button" className="next-btn next-medium next-btn-normal" role="button" data-spm-anchor-id="a2g0o.detail.1000016.i3.759e707cqToxAp"
                                                                                                            onClick={() => {

                                                                                                                if (item.CANTIDAD_CLIENTE !== null) {
                                                                                                                    let valor = item.CANTIDAD_CLIENTE
                                                                                                                    if (valor) {
                                                                                                                        item.CANTIDAD_CLIENTE = parseInt(valor) + 1;
                                                                                                                    } else {
                                                                                                                        item.CANTIDAD_CLIENTE = "1";
                                                                                                                    }
                                                                                                                    setListadoDeReferencias({ ...listadoDeReferencias })

                                                                                                                }

                                                                                                            }}
                                                                                                        >
                                                                                                            <PlusOutlined style={{ fontSize: '16px' }} />
                                                                                                        </button>
                                                                                                    </span>
                                                                                                </span>


                                                                                            </span>
                                                                                        :
                                                                                        null

                                                                                }

                                                                            </td>
                                                                        </tr>
                                                                        :
                                                                        null

                                                                )}
                                                        </tbody>
                                                    </table>
                                                    :
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#000' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                        }
                                    </div>


                                    <div className='paginador_tabla'>
                                        {listadoDeReferencias !== null ?
                                            Object.keys(listadoDeReferencias).length > 0 ?
                                                <Pagination style={{ marginTop: '5px' }}
                                                    showSizeChanger
                                                    current={datosPaginacion.iniPage}
                                                    onShowSizeChange={(current, size) => { updateNumberPage(current, size) }}
                                                    total={listadoDeReferencias.neumaticos.length}
                                                    showTotal={total => ` ${total} ${traducirPagina("Neumáticos")}`}
                                                    defaultPageSize={datosPaginacion.numPagina}
                                                    onChange={(page, pageSize) => { paginarNeumatico(page, pageSize) }}
                                                > </Pagination>
                                                :
                                                null
                                            : null
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </>
                }
            </Drawer>


            {fichaCesta ?
                <FichaAñadirCesta
                    title={traducirPagina("Editar_referencia_a_la_cesta").toUpperCase()}
                    ABRIR_FICHA={fichaCesta}
                    CERRAR_FICHA={setFichaCesta}
                    DATOS_REFERENCIA={referenciaSeleccionada}
                    ES_CESTA_COMPRA={false}
                >
                </FichaAñadirCesta>
                :
                null

            }
        

            {
                abrirFichaDetalle ?
                    <FichaDetalleNeumatico
                        title={`${neumaticoSeleccionado.MARCA_DESCRIPCION} - ${neumaticoSeleccionado.DESCRIPCION_1}`}
                        ABRIR_FICHA={abrirFichaDetalle}
                        CERRAR_FICHA={setAbrirFichaDetalle}
                        DATOS_SELECCIONADO={neumaticoSeleccionado}
                    >
                    </FichaDetalleNeumatico>
                    :
                    null
            }

            {
                
                abrirStockNeumatico &&
                <FichaStockNeumatico
                    title={traducirPagina("Desglose_disponibilidad").toUpperCase()}
                    ABRIR_FICHA={abrirStockNeumatico}
                    CERRAR_FICHA={setAbrirStockNeumatico}
                    REFERENCIA_FABRICANTE={neumaticoSeleccionado.DETALLE_ERP_SOCIO.REFERENCIA_FABRICANTE}
                    REFERENCIA_ERP={neumaticoSeleccionado.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO}
                    EAN={neumaticoSeleccionado.EAN}
                    ID_MARCA={neumaticoSeleccionado.ID_MARCA}



                ></FichaStockNeumatico>
            }

            {
                
                abrirCestaNeumatico &&
                <FichaAgregarArticuloGenerico
                    title={traducirPagina("Desglose_disponibilidad").toUpperCase()}
                    ABRIR_FICHA={abrirCestaNeumatico}
                    CERRAR_FICHA={setAAbrirCestaNeumatico}
                    REFERENCIA_FABRICANTE={neumaticoSeleccionado.DETALLE_ERP_SOCIO.REFERENCIA_FABRICANTE}
                    REFERENCIA_ERP={neumaticoSeleccionado.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO}
                    EAN={neumaticoSeleccionado.EAN}
                    DATOS_REFERENCIA={neumaticoSeleccionado}
                    ES_CESTA_COMPRA={false}
                    ID_MARCA={neumaticoSeleccionado.ID_MARCA}


                >
                </FichaAgregarArticuloGenerico>
                
            }


        </div>

    )
}

FichaNeumaticosErp.propTypes = {
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    CERRAR: PropTypes.object,
    TIPO: PropTypes.string,

    MEDIDAS: PropTypes.string,
    ID_VEHICULO: PropTypes.number

};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        openDrawerFichaNeumaticos: state.estadoDrawer.openDrawerFichaNeumaticos,
        filtrosReferencia: state.filtrosReferencia,
        usuario: state.usuario,
        clienteSeleccionado: state.clienteSeleccionado,



    };
}

export default connect(mapStateToProps)(FichaNeumaticosErp);