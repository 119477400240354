import React from "react";

const BtnEnvio = ({ onClick = () => { }, children = {} }) => {
    return (
        <button style={{ height: '40px', border: '0', borderRadius: '3px', fontSize: '13px', color: '#000', cursor: 'pointer',display:'flex',alignItems:'center',gap:'10px' }}
            onClick={onClick}>
            {children}
        </button>

    )
}

export default BtnEnvio;