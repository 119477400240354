import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getClienteVehiculosEnAPI } from "../action/actionCreatorsRequests"
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBCol } from 'mdbreact';
import { Drawer } from 'antd';
import { traducirPagina } from "../librerias/funciones"

const FichaVehClienteGenerico = ({ props, configApp }) => {
    const [hayRegistros, setHayRegistros] = React.useState(false);
    const [vehiculos, setVehiculos] = React.useState([]);

    if (!hayRegistros && props.ABRIR_FICHA) {
        setHayRegistros(true);
        
        getClienteVehiculosEnAPI("", 1, "A", props.ID_CLIENTE).then((coches) => {
            if (coches.CLIENTES_VEHICULOS !== null && coches.CLIENTES_VEHICULOS.length > 0) {
                setVehiculos(coches.CLIENTES_VEHICULOS);
            } else {
                setVehiculos(null);
            }
        })
    } else {
        if (!props.ABRIR_FICHA && hayRegistros) {
            setHayRegistros(false);
            setVehiculos([])

        }
    }

    return (
        <Drawer
            width={600}
            onClose={() => {
                props.CERRAR_FICHA(false);
            }}
            visible={props.ABRIR_FICHA}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '99999999999', color: '#fff' }}>

            <div className="flex-container-Grow-Tercero" >
                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                        onClick={(e) => { props.CERRAR_FICHA(false); }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>

            <div className="p-2" style={{ height: (configApp.height - 70).toString() + "px", overflow: 'auto' }}>

                <div style={{ height: (configApp.height - 190).toString() + "px", }} className="ml-4 mr-4" >
                    {vehiculos === null ?
                        <div style={{ textAlign: 'center' }}>
                            <h4>{traducirPagina("No_vehiculos")}</h4>
                        </div>
                        :
                        vehiculos.length > 0 ?
                            <MDBTable responsive hover className="mdb-skin">
                                <MDBTableHead>
                                    <tr>
                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '110px', verticalAlign: 'baseline' }}>{traducirPagina("Matricula")}</th>
                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '200px', verticalAlign: 'baseline' }}>{traducirPagina("Bastidor")}</th>
                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", verticalAlign: 'baseline' }}>{traducirPagina("Descripción")}</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {vehiculos.map((vehiculo, i) =>
                                        <tr key={i} onClick={() => {
                                            props.VEHICULO_SELECCIONADO(vehiculo);

                                        }}>
                                            <td style={{ paddingLeft: 0, fontSize: "16px", verticalAlign: 'middle' }}>{vehiculo.MATRICULA}</td>
                                            <td style={{ paddingLeft: 0, fontSize: "16px", verticalAlign: 'middle' }}>{vehiculo.BASTIDOR}</td>
                                            <td style={{ paddingLeft: 0, fontSize: "16px", verticalAlign: 'middle' }}>{vehiculo.DESCRIPCION.substring(0, vehiculo.DESCRIPCION.length > 7 ? 7 : vehiculo.DESCRIPCION.length) + " ..."}</td>
                                        </tr>
                                    )}
                                </MDBTableBody>
                            </MDBTable>
                            :
                            <div style={{ fontSize: '16px', fontWeight: '500', color: "#000" }} className="row">
                                <MDBCol md="9">
                                    <div>
                                        <span className="skeleton-box" style={{ width: '500px' }}></span>
                                        <br />
                                        <span className="skeleton-box" style={{ width: '500px' }}></span>
                                        <br />
                                        <span className="skeleton-box" style={{ width: '500px' }}></span>
                                        <br />
                                        <span className="skeleton-box" style={{ width: '400px' }}></span>
                                        <br />
                                        <span className="skeleton-box" style={{ width: '400px' }}></span>
                                        <br />
                                        <span className="skeleton-box" style={{ width: '400px' }}></span>
                                    </div>
                                </MDBCol>
                            </div>
                    }
                </div>
            </div>
        </Drawer>
    )
}

FichaVehClienteGenerico.propTypes = {
    title: PropTypes.string,
    ID_CLIENTE: PropTypes.number,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    VEHICULO_SELECCIONADO: PropTypes.func

};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        openDrawerFichaVehiculoCliente: state.estadoDrawer.openDrawerFichaVehiculoCliente,
        clienteTallerSeleccionado: state.clienteTallerSeleccionado,
        VehiculoTallerSeleccionado: state.VehiculoTallerSeleccionado,
        PresupuestoTallerSeleccionado: state.PresupuestoTallerSeleccionado
    };
}

export default connect(mapStateToProps)(FichaVehClienteGenerico);