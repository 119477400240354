
import React from "react";
import store from '../store'
import {
  hideSpinnerAcademy, hideSpinnerLoginHaynes, deleteItemHistoricApp, changeDimensionsApp, selectCoche,
  changeStateImageViewer, updateStateMasterPage, activeTabApp, stateVisibleDrawer, showItemCar, createRipple,
  showChildrenDrawerLevel2, gestionarContenedorCoche, changeCocheModal, showItemReferencia,
  estadoAgrupacionDeMarcas, efectoBotonRemove, showChildrenDrawerIntegraciones,
  activeItemTipoIntegracion, updateVisualizacionFiltro, DrawerHistoricoCoche, mostrarVistaGrafica, detenerBusquedaDetalles,
  DrawerPerfilUsuario, actualizarNodoTree, DrawerHaynesPro, DrawerNextAssist, DrawerAcademyCloud, DrawerFichaCliente, DrawerFichaCocheDGT,
  DrawerFichaClienteTaller, DrawerFichaDatosCliente, DrawerFichaPresupuestoTaller, DrawerFichaMarcasVehiculo, DrawerFichaAlbaranTaller,
  DrawerFichaEmpresa, DrawerFichaTiemposReparacion, DrawerFichaMantenimientoHaynes, DrawerFichaPublicidad, DrawerFichaLubricantes,
  onChildrenDrawerCloseLevel2, DrawerFichaHistorialPedido, DrawerFichaProveedores, DrawerFichaOrden, DrawerFichaFactura, DrawerFichaNeumaticos,
  DrawerFichaGTEstimate, actualizarCocheIdentificador, actualizarCodigoGT, actualizarCocheID, DrawerFichaBateriasVehiculo, DrawerIntranet,
  onExpand, generateList
} from '../action/actionCreators';
import { loginByHaynesPro, getSesionHaynesPro, logOutSesion, abortFetch, getIdTecdocMotorByIdHaynes, ObtenerNotificacionesEnAPI, ActualizarNotificacionesClienteEnAPI, PermisoConsultaOEMEnAPI, ObtenerGMPorIdModeloEnAPI, InfotechniLogincEnAPI } from '../action/actionCreatorsRequests';
import { MDBNavbar, MDBNavbarNav, MDBNavItem, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBBtn, Link } from 'mdbreact';
import FichaCarroCompra from '../components/CarroCompra'
import FichaMasInfo from '../components/MasInformacion'
import FichaHaynesPro from '../components/FichaHaynesPro'
import FichaReferenciaGrafico from '../components/FichaReferenciaGrafico'
import FichaPerfil from '../components/FichaPerfil'
import FichaCliente from '../components/FichaCliente'
import FichaStockAlmacen from '../components/FichaStockAlmacen'
import FichaCocheDGT from '../components/FichaCocheDGT'
import ContainerDrawerFicha from '../components/ContainerDrawerCoche'
import ContainerDrawerIntegraciones from '../components/ContainerDrawerIntegraciones'
import FichaHistoricoCoche from '../components/FichaHistoricoCoche'
import FichaNexAssist from '../components/FichaNextAssist'
import FichaAcademy from '../components/FichaAcademyCloud'
import FichaClienteTaller from '../components/FichaClienteTaller'


import FichaMasInfo2 from "../components/FichaMasInfo2"
import FichaDatosCliente from "../components/FichaDatosCliente"
import FichaPresupuestoTaller from "../components/FichaPresupuestoTaller"
import FichaMarcasVehiculo from "../components/FichaMarcasVehiculo"
import FichaReferenciaMarcaVehiculo from "../components/FichaRefenciaMarcaVehiculo"
import FichaEquivalenciaArticulo from "../components/FichaEquivalenciaArticulo"
import FichaAlbaranesTaller from "../components/FichaAlbaranesTaller"
import FichaEmpresa from "../components/FichaEmpresa"
import FichaVehiculoCliente from "../components/FichaVehiculoCliente"
import FichaTiemposReparacion from "../components/FichaTiemposReparacion"
import FichaMantenimientoHaynesPro from "../components/FichaMantenimientoHaynesPro"
import FichaPublicidad from "../components/FichaPublicidad";
import FichaLubricante from "../components/FichaLubricante";
import FichaNeumaticosErp from "../components/FichaNeumaticosErp";

import FichaHistorialPedido from "../components/FichaHistorialPedido";
import FichaProveedores from "../components/FichaProveedores";
import FichaOrdenReparacionTaller from "../components/FichaOrdenReparacionTaller";
import FichaFacturaTaller from "../components/FichaFacturaTaller";
import FichaGTEstimate from "../components/FichaGTEstimate";
import FichaLubricantesERP from "../components/FichaLubricantesERP";
import FichaBaterias from "../components/FichaBaterias";
import FichaYQ from "../components/FichaYQ";
import FichaTelegram from "../components/FichaTelegram";
import FichaIntranet from "../components/FichaIntranet";
import FichaBoletines from '../components/FichaBoletines'
import Traking from "../components/Traking/Traking";
import Manuales from "../components/Haynes/Manuales"



import {
  efectoRemoveBuscadorReferencia, volverABuscarVehiculo, traducirPagina,
  buscarReferenciaYMostrarEnPantalla, limpiarCocheStore, limpiarFichaClienteSeleccionado, efectoRemoveBuscador, volverAMostrarReferenciaPorCliente,
  limpiarGrupoDrawer, volverAlHome, MSGERROR, verificarSiEsMostrador, obtenerTipoDeBusquedaActual, recortarCadena, mostrarIcono,
  filtarClientes, obtenerServicio, obtenerPuntos, modelarTreeGenerico, buscarArticuloMotoYMostrarEnPantalla, MODIFICAR_TIPO_BUSQUEDA,
  permisoMotos, datosLincenciaSertec, datosServiciosSertec, comprobarNeumatico, idModeloLatam, esUsuarioFlotas
} from '../librerias/funciones'
import history from '../history';
import {
  ShoppingOutlined,
  UserOutlined,
  CloseOutlined,
  AppstoreOutlined,
  GlobalOutlined,
  AimOutlined,
  SearchOutlined,
  FieldTimeOutlined,
  ToolOutlined,
  EuroCircleOutlined,
  CommentOutlined,
  FileTextOutlined,
  FileDoneOutlined,
  EnvironmentOutlined,
  SettingOutlined,
  GifOutlined,
  GiftOutlined,
  ReadOutlined
} from '@ant-design/icons';

import { Modal, Button, Row, Col, Select, Badge } from 'antd';
import ComponentInputCoche from '../components/cpInputCoche';
import FichaBateriasVehiculo from "../components/FichaBateriasVehiculo";
import FichaFast from "../components/FichaFast";
import FichaTutorial from "../components/Visor/FichaTutorial";
import FichaAjustes from "../components/FichaAjustes";
import { CPBuscarMoto } from "../components/CPBuscarMoto";
import { RMIFluidos } from "../components/TecRMI/RMIFluidos";
import { RMIDATA } from "../components/TecRMI/RMIData";
import { RMIManuales } from "../components/TecRMI/RMIManuales";
import { RMIAjustes } from "../components/TecRMI/RMIAjustes";
import { RMIMantenimientos } from "../components/TecRMI/RMIMantenimientos";
import { RMITiempos } from "../components/TecRMI/RMITiempos";
import { CatalogoBaterias } from "../components/Bateria/CatalogoBaterias";
import VisorDocumentos from "../components/VisorDocumentos";
import VisorDoc from "../components/Visor/VisorDoc";
import CPBtndoc from "../components/utils/CPBtndoc";
import PresupuestoFlota from "../components/Flotas/PresupuestoFlota";
import PresupuestoFlotaTaller from "../components/Flotas/PresupuestoFlotaTaller";
import CestaSertec from "../components/Cesta/CestaSertec";
import CestaCompra from "../components/CestaCompra";


class MasterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleStateA: false,
      breakWidth: 1300,
      windowWidth: 0,
      isOpenMenuTop: true,
      contenido: 1,
      activeItemClassicTabs1: "1",
      listItems: this.props.listItems,
      txtoem: "", txtcoche: "",
      collapsed: false,
      stateNavbar: '8rem',
      historicapp: {},
      showmasterpage: false,
      usuarioLocal: {},
      nameDropDOWN: "",
      activeItemReferencia: "",
      buscadorTree: {},
      estadoDrawer: false,
      configApp: {},
      grupoDeMarcas: {},
      idiomaActual: {},
      referenciaSeleccionada: {},
      referenciaMasInfo2: {},
      referenciaActual: {},
      esMostrador: false,
      plantillaCss: "plantillaDefault.css",
      esCatalogo: false,
      clienteSeleccionado: { nombre: "", codigo: "", cif: "" },
      codMotorizacionFichaHistorial: "",
      abrirModalVehiculo: false,
      vehiculoPorMatricula: {},
      tituloAcademy: "ACADEMY CLOUD",
      urlAcademy: "https://academycloud.servicenext.eu/educa/campus/menu.html?account=next-acadey&username_campus=PROGRAMADOR.WEB@SERCA.ES&password_campus=pOU6jr1FEpLh5Hq",
      subtituloFichaPublicidad: "",
      tipoLicencia: "",
      BusquedaPorModelo: {},
      TextoBuscadorPorModelo: "",
      PreLoaderPage: false,
      VerPreloader: false,
      PermisoMatricula: false,
      PERMISO_NEUMATICOS: false,
      PERMISO_LUBRICANTES: false,
      PERMISO_BATERIAS: false,


      TEXTO_CLIENTE_MOSTRADOR: "",
      MATRICULA_BASTIDOR: "",
      ABRIR_FICHA_GT: false,
      PERMISO_GTESTIMATE: false,
      PERMISO_CATALOGO_ORIGINAL: false,
      FACTURACION_LITE: false,
      GT_ESTIMATE: { CODIGO: "", IDENTIFICADOR_COCHE: "" },
      ABRIR_MODAL_GT: false,
      ABRIR_FICHA_LUBRICANTES: false,
      ABRIR_FICHA_BATERIAS: false,
      ABRIR_FICHA_BATERIAS_VEHICULO: false,
      ABRIR_CATALOGO_BATERIAS: false,


      ABRIR_FICHA_YQ: false,
      ABRIR_FICHA_TELEGRAM: false,
      ABRIR_FICHA_BOLETINES: false,
      ABRIR_FICHA_TRAKING: false,
      ABRIR_FICHA_FAST: false,
      ABRIR_FICHA_MANUALES: false,
      ABRIR_FICHA_TUTORIAL: false,
      ABRIR_FICHA_AJUSTES: false,
      ABRIR_FICHA_NEUMATICOS: false,
      ABRIR_FICHA_NEUMATICOS_ERP: false,

      ID_VEHICULO_NEUMATICO: -1,
      NOTIFICACIONES: { CHAT: 0, CESTA: 0 },
      openDrawerIntegraciones: false,

      COCHE_SELECCIONADO_OEM: {},
      LIMPIAR_COCHE_ANTERIOR_OEM: false,
      ABRIR_MODAL_OEM: false,
      PERMISO_DOCUMENTACION_TECNICA: false,

      ABRIR_FICHA_RMI_FLUIDOS: false,
      ABRIR_FICHA_RMI_DATA: false,
      ABRIR_FICHA_RMI_MANUALES: false,
      ABRIR_FICHA_RMI_AJUSTES: false,
      ABRIR_FICHA_RMI_MANTENIMIENTOS: false,
      ABRIR_FICHA_RMI_TIEMPOS: false,
      ABRIR_FICHA_PRESUPUESTO_FLOTA: false,
      ABRIR_FICHA_PRESUPUESTO_FLOTA_TALLER: false,
      ABRIR_FICHA_CESTA_SERTEC: false,


      ABRIR_FICHA_DOC: false,
      URL_DOCUMENTO: ""

    };




    //store.dispatch(getSesionHaynesPro());

    if (history.location.pathname !== "/") {
      store.dispatch(updateStateMasterPage(true))

      //verifico el estado del usuario en redux
      if (Object.keys(this.state.usuarioLocal).length === 0) {
        history.push("/");
        store.dispatch(updateStateMasterPage(false))
      }

    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }


  componentDidMount() {


    store.subscribe(() => {
      let mostrador = store.getState().usuario.LoginSertec === undefined ? false : store.getState().usuario.LoginSertec.List_LicenciaSERTE !== null ? store.getState().usuario.LoginSertec.List_LicenciaSERTE[0].MOSTRADOR : false;
      let verificarPlantilla = "Default";
      let modificarTxtReferencia = store.getState().txtBuscadorReferencia;
      let cambiosLicencia = true;
      let PERMISO_NEUMATICOS = false;
      let PERMISO_LUBRICANTES = false;
      let PERMISO_BATERIAS = false;

      let PermisoMatricula = false;
      let PERMISO_GTESTIMATE = false;
      let PERMISO_CATALOGO_ORIGINAL = false;
      let FACTURACION_LITE = false
      let PERMISO_DOCUMENTACION_TECNICA = false



      if (store.getState().usuario.LoginSertec !== undefined) {

        if (store.getState().usuario.LoginSertec.List_LicenciaSERTE !== null) {
          verificarPlantilla = store.getState().usuario.LoginSertec.List_LicenciaSERTE[0].PLANTILLA !== undefined && store.getState().usuario.LoginSertec.List_LicenciaSERTE[0].PLANTILLA !== null && store.getState().usuario.LoginSertec.List_LicenciaSERTE[0].PLANTILLA !== "" ? store.getState().usuario.LoginSertec.List_LicenciaSERTE[0].PLANTILLA : "Default"
          cambiosLicencia = store.getState().usuario.LoginSertec.List_LicenciaSERTE[0].SOLO_CATALOGO
          PERMISO_NEUMATICOS = store.getState().usuario.LoginSertec.List_LicenciaSERTE[0].PERMISO_NEUMATICOS


          PERMISO_LUBRICANTES = store.getState().usuario.LoginSertec.List_LicenciaSERTE[0].PERMISO_LUBRICANTES
          PERMISO_BATERIAS = store.getState().usuario.LoginSertec.List_LicenciaSERTE[0].PERMISO_BATERIAS

          PermisoMatricula = store.getState().usuario.LoginSertec.List_LicenciaSERTE[0].PERMISO_MATRICULAS
          PERMISO_GTESTIMATE = store.getState().usuario.LoginSertec.List_LicenciaSERTE[0].PERMISO_PRESUPUESTOS_GTESTIMATE
          PERMISO_CATALOGO_ORIGINAL = store.getState().usuario.LoginSertec.List_LicenciaSERTE[0].PERMISO_CATALOGO_ORIGINAL
          FACTURACION_LITE = store.getState().usuario.LoginSertec.List_LicenciaSERTE[0].FACTURACION_LITE
          PERMISO_DOCUMENTACION_TECNICA = store.getState().usuario.LoginSertec.List_LicenciaSERTE[0].PERMISO_DOCUMENTACION_TECNICA
        }
      }

      if (store.getState().PreLoaderPage) {
        if (!this.state.VerPreloader) {
          setTimeout(() => {
            this.setState({ VerPreloader: true })
          }, 500)
        }
      }


      this.setState({
        historicapp: store.getState().fichaBusquedaVehiculo.historicapp,
        windowWidth: store.getState().width,
        showmasterpage: store.getState().showmasterpage,
        activeItemClassicTabs1: store.getState().activetab,
        usuarioLocal: store.getState().usuario,
        nameDropDOWN: store.getState().nameDropDownCar,
        activeItemReferencia: store.getState().activeItemReferencia,
        stateNavbar: '3rem',
        buscadorTree: store.getState().buscadorTree,
        estadoDrawer: store.getState().estadoDrawer,
        configApp: store.getState().configApp,
        grupoDeMarcas: store.getState().grupoDeMarcas,
        idiomaActual: store.getState().idiomaActual,
        referenciaSeleccionada: store.getState().referenciaSeleccionada,
        referenciaMasInfo2: store.getState().referenciaMasInfo2,
        referenciaActual: store.getState().referenciaActual,
        txtoem: modificarTxtReferencia,
        esMostrador: mostrador,
        plantillaCss: "template" + verificarPlantilla + ".css",
        esCatalogo: cambiosLicencia,
        clienteSeleccionado: store.getState().clienteSeleccionado,
        codMotorizacionFichaHistorial: store.getState().codMotorizacionFichaHistorial,
        abrirModalVehiculo: store.getState().abrirModalVehiculo,
        vehiculoPorMatricula: store.getState().fichaBusquedaVehiculo.vehiculoPorMatricula,
        TextoBuscadorPorModelo: store.getState().TextoBuscadorPorModelo,
        BusquedaPorModelo: store.getState().BusquedaPorModelo,
        PreLoaderPage: store.getState().PreLoaderPage,
        PermisoMatricula: PermisoMatricula,
        PERMISO_NEUMATICOS: PERMISO_NEUMATICOS,
        PERMISO_LUBRICANTES: PERMISO_LUBRICANTES,
        PERMISO_BATERIAS: PERMISO_BATERIAS,
        MATRICULA_BASTIDOR: store.getState().fichaBusquedaVehiculo.cocheABuscar,
        PERMISO_GTESTIMATE: PERMISO_GTESTIMATE,
        PERMISO_CATALOGO_ORIGINAL: PERMISO_CATALOGO_ORIGINAL,
        FACTURACION_LITE: FACTURACION_LITE,
        GT_ESTIMATE: store.getState().GT_ESTIMATE,
        openDrawerIntegraciones: store.getState().openDrawerIntegraciones,
        PERMISO_DOCUMENTACION_TECNICA: PERMISO_DOCUMENTACION_TECNICA,
        NOTIFICACIONES: store.getState().NOTIFICACIONES_APP

      })

    })


    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
/*
    this.intervalChat = setInterval(() => {
      if (window.location.pathname === "/referencia") {

        ObtenerNotificacionesEnAPI().then(registro => {
          if (registro !== undefined) {
            if (registro.NOTIFICACIONES !== undefined && registro.NOTIFICACIONES !== null) {

              store.dispatch({ type: 'STATE_NOTIFICACIONES_APP', CHAT: registro.NOTIFICACIONES.MENSAJES_TELEGRAM_NUEVOS, CESTA: registro.NOTIFICACIONES.ARTICULOS_CESTA });

            } else {
              store.dispatch({ type: 'STATE_NOTIFICACIONES_APP', CHAT: 0, CESTA: 0 });

            }
          }
        })


      }
    }, 60000);

*/

  }


  valoresPorDefectoMater() {
    this.setState(
      {
        toggleStateA: false,
        breakWidth: 1300,
        windowWidth: 0,
        isOpenMenuTop: true,
        contenido: 1,
        activeItemClassicTabs1: "1",
        listItems: this.props.listItems,
        txtoem: "", txtcoche: "",
        collapsed: false,
        stateNavbar: '8rem',
        historicapp: {},
        showmasterpage: false,
        usuarioLocal: {},
        nameDropDOWN: "",
        activeItemReferencia: "",
        buscadorTree: {},
        estadoDrawer: false,
        configApp: {},
        grupoDeMarcas: {},
        idiomaActual: {},
        referenciaSeleccionada: {},
        referenciaMasInfo2: {},
        referenciaActual: {},
        esMostrador: false,
        plantillaCss: "plantillaDefault.css",
        esCatalogo: false,
        clienteSeleccionado: { nombre: "", codigo: "", cif: "" },
        codMotorizacionFichaHistorial: "",
        abrirModalVehiculo: false,
        vehiculoPorMatricula: {},
        tituloAcademy: "ACADEMY CLOUD",
        urlAcademy: "https://academycloud.servicenext.eu/educa/campus/menu.html?account=next-acadey&username_campus=PROGRAMADOR.WEB@SERCA.ES&password_campus=pOU6jr1FEpLh5Hq",
        subtituloFichaPublicidad: "",
        tipoLicencia: "",
        BusquedaPorModelo: {},
        TextoBuscadorPorModelo: "",
        PreLoaderPage: false,
        VerPreloader: false,

        ABRIR_FICHA_RMI_FLUIDOS: false,
        ABRIR_FICHA_RMI_DATA: false,
        ABRIR_FICHA_RMI_MANUALES: false,
        ABRIR_FICHA_RMI_AJUSTES: false,
        ABRIR_FICHA_RMI_MANTENIMIENTOS: false,
        ABRIR_FICHA_RMI_TIEMPOS: false

      }
    )
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }


  updateWindowDimensions() {
    store.dispatch(changeDimensionsApp(window.innerWidth, window.innerHeight));
  }

  actualizarEstadoNavbar = () => {
    history.location.pathname === "/haynespro" ? this.setState({ stateNavbar: '-8rem' }) : this.setState({ stateNavbar: '8rem' });
  }


  toggleClassicTabs = tab => () => {
    if (this.state.activeItemClassicTabs1 !== tab[0]) {
      store.dispatch(activeTabApp(tab[0]));
    }

    store.dispatch(changeStateImageViewer(false, ""));
    store.dispatch(loginByHaynesPro());
    store.dispatch(hideSpinnerAcademy(true));
    store.dispatch(hideSpinnerLoginHaynes(true));
    history.push(tab[1])
    store.dispatch(deleteItemHistoricApp(tab[1].replace("/", ""), this.state.historicapp));

    if (tab[0] === "1") {
      store.dispatch(stateVisibleDrawer(true))
      store.dispatch(showItemCar("1"))
    }
  };


  buscarReferenciaLocal = () => {

    buscarReferenciaYMostrarEnPantalla(this.state.txtoem)
    store.dispatch({ type: 'UPDATE_TIPO_BUSQUEDA_MODELO', tipoBusqueda: "C", descripcion: "CARROCERIA" });
  }

  verServicios = (plataforma) => {
    let credencial = this.state.usuarioLocal.LoginSertec.SERVICIOS.filter((registro) => registro.PLATAFORMA === plataforma)

    switch (plataforma) {
      case "INTRANET":

        store.dispatch(DrawerIntranet(true));

        break;

      case "HAYNESS":
        if (credencial[0].USUARIO === "") {
          store.dispatch(DrawerFichaPublicidad(true));
          this.setState({ subtituloFichaPublicidad: traducirPagina("No_licencia_HaynesPro"), tipoLicencia: "NEXT DATA" })
        } else {
          const { CONTRATADO, ORIGEN } = datosServiciosSertec("NEXTDATA_COMPLETO");
          if (CONTRATADO && ORIGEN === "RMI") {
            this.setState({ ABRIR_FICHA_RMI_DATA: true })

          } else {
            store.dispatch(DrawerHaynesPro(true));

          }
        }
        break;
      case "ASSIST":
        if (credencial[0].USUARIO === "") {
          store.dispatch(DrawerFichaPublicidad(true));
          this.setState({ subtituloFichaPublicidad: traducirPagina("No_licencia_Next_Assist"), tipoLicencia: "NEXT ASSIST" })
        } else {
          store.dispatch(DrawerNextAssist(true));
        }
        break;
      case "ACADEMY":
        if (credencial[0].USUARIO === "") {
          store.dispatch(DrawerFichaPublicidad(true));
          this.setState({ subtituloFichaPublicidad: traducirPagina("No_licencia_Academy_Cloud"), tipoLicencia: "NEXT ACADEMY" })
        } else {
          store.dispatch(DrawerAcademyCloud(true));
        }
        break;
    }
  }

  VolverEstadoInicialMoto = () => {

    volverAlHome();

    this.verificarEstadoBuscarReferencia();
    store.dispatch(updateVisualizacionFiltro(true));

  }

  buscarReferenciaMoto = (filtros) => {
    const { TXT_FILTRO } = filtros

    MODIFICAR_TIPO_BUSQUEDA("MT")

    buscarArticuloMotoYMostrarEnPantalla(TXT_FILTRO)
  }


  buscarGenericoMoto = (filtros) => {
    this.VolverEstadoInicialMoto()

    limpiarCocheStore();
    store.dispatch({ type: "UPDATE_BRANDS_GROUPING", marcas: [] });
    store.dispatch({ type: "UPDATE_PAGINATION", pagination: {} });


    //store.dispatch(getIdTecdocMotorByIdHaynes(0));
    store.dispatch({ type: "LIST_TREE", listaTree: [] });
    ObtenerGMPorIdModeloEnAPI(0).then((registro) => {
      if (registro !== undefined && registro !== null) {
        const { TREE_ESTRUCTURA, LISTA_ESTRUCTURA } = registro

        let crearTree = modelarTreeGenerico(TREE_ESTRUCTURA);
        store.dispatch(onExpand(generateList(crearTree)))
        store.dispatch({ type: "LIST_TREE", listaTree: crearTree })
        store.dispatch({ type: "LISTA_ESTRUCTURA", listaEstructura: LISTA_ESTRUCTURA })
      }
    })



    store.dispatch({ type: "SET_ID_MOTORIZACION", codmotorizacion: 0 })
    store.dispatch(showItemReferencia("tree"));
    store.dispatch(mostrarVistaGrafica(false))
    store.dispatch(updateVisualizacionFiltro(true));

    efectoRemoveBuscadorReferencia("buscadorReferencia", true);

  }


  verificarEstadoBuscarReferencia = () => {
    abortFetch();
    store.dispatch({ type: "STATE_TXT_BUSCAR_REFERENCIA", txtBuscadorReferencia: "" });
    store.dispatch(mostrarVistaGrafica(true));

    if (this.state.historicapp.length === 3) {
      store.dispatch(showItemReferencia('tree'));
      //store.dispatch(estadoAgrupacionDeMarcas({"marcas": [], "referencias": []}));
      store.dispatch(detenerBusquedaDetalles(true));
      store.dispatch(actualizarNodoTree(undefined))
      store.dispatch(estadoAgrupacionDeMarcas({ "marcas": [] }));

    } else if (this.state.historicapp.length <= 2) {
      store.dispatch(deleteItemHistoricApp('referencia', this.state.historicapp));
      gestionarContenedorCoche(false);
      store.dispatch(showItemReferencia(""));


      store.dispatch(detenerBusquedaDetalles(true));
      store.dispatch(actualizarNodoTree(undefined))
      store.dispatch(estadoAgrupacionDeMarcas({ "marcas": [] }));
    }
  }

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  handleToggleClickA = () => {
    this.setState({
      toggleStateA: !this.state.toggleStateA
    });
  };

  onChange(e) {
    if (e.target.name === "txtOEM") {
      efectoBotonRemove(e.target.value, "closeBuscadorReferencia")
      store.dispatch({ type: "STATE_TXT_BUSCAR_REFERENCIA", txtBuscadorReferencia: e.target.value });
    }

    this.setState({
      // txtoem: e.target.name === "txtOEM" ? e.target.value : this.state.txtoem,
      txtcoche: e.target.name === "txtCoche" ? e.target.value : this.state.txtcoche,
    })
  }

  cestaDeLaCompra(e) {
    let esMostrador = verificarSiEsMostrador();
    let abrirFicha = true

    if (esMostrador) {
      let cliente = store.getState().clienteSeleccionado;
      if (cliente.codigo === "") { MSGERROR(traducirPagina("msgTiene_seleccionar_cliente")); abrirFicha = false }
    }

    if (abrirFicha) {
      this.setState({ ABRIR_FICHA_CESTA_SERTEC: true })
      /*
      createRipple(e);
      store.dispatch(showChildrenDrawerIntegraciones(true));
      store.dispatch(activeItemTipoIntegracion("cesta"))
      */

    }
  }


  btnHistorialCoche() {
    if (Object.keys(this.state.BusquedaPorModelo).length > 0) {
      if (this.state.BusquedaPorModelo.tipoBusqueda !== 'C') {
        store.dispatch(DrawerHistoricoCoche(true));
        store.dispatch({ type: 'UPDATE_TIPO_BUSQUEDA_MODELO', tipoBusqueda: "C", descripcion: "CARROCERIA" });
        store.dispatch(showItemReferencia(""));
      } else {
        store.dispatch(DrawerHistoricoCoche(true));
      }
    } else {
      store.dispatch(DrawerHistoricoCoche(true));
    }
  }

  verificarPermisoOEM = async () => {
    const resultado = await PermisoConsultaOEMEnAPI();
    const { OK } = await resultado;
    if (OK) {
      if (Object.keys(this.state.COCHE_SELECCIONADO_OEM).length > 0) {
        if (this.state.historicapp.length === 3) {
          this.setState({ ABRIR_MODAL_OEM: true })
        } else {
          this.setState({ ABRIR_FICHA_YQ: true })
        }
      } else {
        this.setState({ ABRIR_FICHA_YQ: true })
      }
    }
  }


  cerrarFichaLubricantesERP() {
    this.CERRAR_FICHA(false)
  }

  gestionarFichaDocumento = (documento) => {
    this.setState({ ABRIR_FICHA_DOC: true, URL_DOCUMENTO: documento });
  }



  render() {
    const navStyle = {
      paddingLeft: this.state.windowWidth > this.state.breakWidth ? "10px" : "16px",
      background: '#000'
    };

    const specialCaseNavbarStyles = {
      WebkitBoxOrient: "horizontal",
      flexDirection: "row"
    };

    const ID_VEHICULO_MOTORIZACION = store.getState().codmotorizacion;
    const OBTENER_DATOS_USUARIO = datosLincenciaSertec();
    const { ID_IDIOMA, ID_PAIS } = OBTENER_DATOS_USUARIO === undefined || OBTENER_DATOS_USUARIO === null ? { ID_IDIOMA: 0, ID_PAIS: 1 } : OBTENER_DATOS_USUARIO;


    /*
    const { TITULO_ACADEMY, URL_ACADEMY } = ID_PAIS === 3 ?
      { TITULO_ACADEMY: "Academy cloud", URL_ACADEMY: "https://academycloud.servicenext.eu/educa/campus/menu.html?account=next-acadey&username_campus=PROGRAMADOR.WEB@SERCA.ES&password_campus=pOU6jr1FEpLh5Hq" }
      :
      { TITULO_ACADEMY: "Infotechnic", URL_ACADEMY: "https://www.infotechnic.es/" }
*/

    const { TITULO_ACADEMY, URL_ACADEMY } = { TITULO_ACADEMY: "Infotechnic", URL_ACADEMY: "https://www.infotechnic.es/" }


    return (
      this.state.showmasterpage ?
        <div>
          {this.state.PreLoaderPage ?
            <div>
              <div className="mdb-skin" >
                <MDBNavbar style={this.state.isOpenMenuTop ? navStyle : null} double expand="xs" className={"barraPrincipal"}>
                  <MDBNavbarNav left>
                    <MDBNavItem className="d-none d-md-inline" >
                      <img className="logoApp" style={{ height: '38px', cursor: 'pointer', marginRight: '20px' }} onClick={() => {
                        volverAlHome();

                        this.verificarEstadoBuscarReferencia();
                        store.dispatch(updateVisualizacionFiltro(true));
                      }}></img>
                    </MDBNavItem>

                    <MDBNavItem className="d-none d-md-inline mt-2" >
                      {this.state.usuarioLocal.LoginSertec !== undefined && this.state.configApp.width > 1300 ?
                        <div className="estiloNombreDeSocio" >
                          <AimOutlined style={{ fontSize: '24px', verticalAlign: 'inherit' }} />
                          <strong style={{ textTransform: 'uppercase' }}> {this.state.usuarioLocal.LoginSertec.RAZON_SOCIO} </strong>
                        </div>
                        : null}
                    </MDBNavItem>

                  </MDBNavbarNav>

                  <MDBNavbarNav right style={specialCaseNavbarStyles}>
                    <MDBNavItem style={{ marginRight: '20px' }}>
                      <div className="grid-container" style={{ backgroundColor: '#eee', marginTop: '2px' }}>
                        <div className="grid-item" >
                          <input id="buscadorReferencia" className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_referencia")} style={{ backgroundColor: "transparent", width: '200px', border: '0px', height: '100%', color: '#000' }} onChange={this.onChange.bind(this)} name="txtOEM" onKeyPress={(e) => e.key === 'Enter' ? this.buscarReferenciaLocal() : {}} autoComplete="off" />
                        </div>

                        <div className="grid-item" >
                          <MDBBtn id="closeBuscadorReferencia" onClick={e => { this.verificarEstadoBuscarReferencia(); efectoRemoveBuscadorReferencia("buscadorReferencia", true); store.dispatch(updateVisualizacionFiltro(true)); }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: 'hidden' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                        </div>

                        <div className="grid-item barraLateral" ></div>

                        <div className="grid-item">
                          <MDBBtn onClick={e => { /*createRipple(e); limpiarCocheStore();*/ buscarReferenciaYMostrarEnPantalla(this.state.txtoem) }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                        </div>
                      </div>
                    </MDBNavItem>


                    {permisoMotos() && <CPBuscarMoto EVENTO_TXT_FILTRO={this.buscarReferenciaMoto} EVENTO_BTN_GENERICO={this.buscarGenericoMoto} LIMPIAR_PANTALLA_MOTO={this.VolverEstadoInicialMoto}  ></CPBuscarMoto>}


                    {obtenerPuntos().ID_SOCIO === 87 &&
                      <MDBNavItem className="d-none d-md-inline">
                        <button title={`${traducirPagina("Msg_puntos_regalos")} ${obtenerPuntos().PUNTOS}`} onClick={(e) => { obtenerPuntos().URL !== "" && window.open(obtenerPuntos().URL, "_blank") }} style={{ border: '0', padding: '0.5rem 0.5rem', margin: '0', display: 'flex', gap: '5px' }} className="btnTema">
                          <GiftOutlined style={{ fontSize: '24px', color: '#fff' }} className="colorIconoBarra" />
                          {obtenerPuntos().PUNTOS}
                        </button>
                      </MDBNavItem>
                    }



                    <MDBNavItem className="d-none d-md-inline">
                      <div style={{ marginLeft: '10px', display: this.state.PERMISO_BATERIAS ? '' : 'none' }}>

                        <button title={traducirPagina("Baterias")} onClick={(e) => { this.setState({ ABRIR_CATALOGO_BATERIAS: true }) }} style={{ border: '0', padding: '0.5rem 0.5rem', margin: '0' }} className="btnTema">  <img src="./imagenes/bateria_coche_white.png" style={{ width: '29px' }} /></button>
                      </div>
                    </MDBNavItem>

                    {/*
                    <MDBNavItem className="d-none d-md-inline">
                      <div style={{ marginLeft: '10px', display: this.state.PERMISO_BATERIAS ? '' : 'none' }}>
                        <button title={traducirPagina("Baterias")} onClick={(e) => { this.setState({ ABRIR_FICHA_BATERIAS: true }) }} style={{ border: '0', padding: '0.5rem 0.5rem', margin: '0' }} className="btnTema">  <img src="./imagenes/bateria_coche_white.png" style={{ width: '29px' }} /></button>
                      </div>
                    </MDBNavItem>
                    */}

                    <MDBNavItem className="d-none d-md-inline">
                      <div style={{ marginLeft: '10px', display: this.state.PERMISO_LUBRICANTES ? '' : 'none' }}>
                        <button title={traducirPagina("Lubricantes")} onClick={(e) => { this.setState({ ABRIR_FICHA_LUBRICANTES: true }) }} style={{ border: '0', padding: '0.5rem 0.5rem', margin: '0' }} className="btnTema">  <img src="./imagenes/car_oil_white.png" style={{ width: '29px' }} /></button>
                      </div>
                    </MDBNavItem>

                    <MDBNavItem className="d-none d-md-inline">
                      <div style={{ marginLeft: '10px', display: this.state.PERMISO_NEUMATICOS ? '' : 'none' }}>
                        <button title={traducirPagina("Neumáticos")} onClick={(e) => {


                          this.setState({ ABRIR_FICHA_NEUMATICOS_ERP: true, ID_VEHICULO_NEUMATICO: -1 });

                        }} style={{ border: '0', padding: '0.5rem 0.5rem', margin: '0' }} className="btnTema"> <img src="./imagenes/neumatico_mod.png" style={{ height: '29px' }} className=""></img> </button>
                      </div>
                    </MDBNavItem>

                    <MDBNavItem style={{ marginLeft: '10px' }} className="d-none d-md-inline">
                      {this.state.esMostrador ?
                        <button title={traducirPagina("Telegram")} onClick={(e) => { this.setState({ ABRIR_FICHA_TELEGRAM: true }) }} style={{ border: '0', padding: '0.5rem 0.5rem', margin: '0' }} className="btnTema">  <Badge count={this.state.NOTIFICACIONES.CHAT} style={{ backgroundColor: '#33b5e5', color: '#fff' }}> <CommentOutlined style={{ fontSize: '24px', color: '#fff' }} className="colorIconoBarra" /> </Badge> </button>
                        :
                        <button title={traducirPagina("Telegram")} onClick={(e) => {

                          ActualizarNotificacionesClienteEnAPI().then(registro => {
                          })

                          window.open("https://web.telegram.org/", "_blank")
                        }} style={{ border: '0', padding: '0.5rem 0.5rem', margin: '0' }} className="btnTema"> <Badge count={this.state.NOTIFICACIONES.CHAT} style={{ backgroundColor: '#33b5e5', color: '#fff' }}>  <CommentOutlined style={{ fontSize: '24px', color: '#fff' }} className="colorIconoBarra" /></Badge> </button>
                      }

                    </MDBNavItem>


                    <MDBNavItem style={{ marginLeft: '10px', visibility: this.state.esCatalogo ? 'hidden' : 'visible' }} className="d-none d-md-inline">
                      <button title={traducirPagina("Cesta_de_la_compra")}
                        onClick={(e) => { this.cestaDeLaCompra(e) }}
                        style={{ border: '0', padding: '0.5rem 0.5rem', margin: '0' }} className="btnTema">  <Badge count={this.state.NOTIFICACIONES.CESTA} style={{ backgroundColor: '#33b5e5', color: '#fff' }}><ShoppingOutlined style={{ fontSize: '24px', color: '#fff' }} className="colorIconoBarra" /></Badge> </button>
                    </MDBNavItem>




                    <MDBNavItem style={{ marginLeft: '10px', marginRight: '10px' }}  >
                      <button title={traducirPagina("Historico_del_coche")} onClick={e => { this.btnHistorialCoche() }} style={{ border: '0', padding: '0.5rem 0.5rem', margin: '0' }} className="btnTema">  <FieldTimeOutlined style={{ fontSize: '24px', color: '#fff' }} className="colorIconoBarra" />   </button>
                    </MDBNavItem>

                    <div style={{ margin: '0px 10px 0px 0px' }}>
                      <button title={traducirPagina("Pedidos_pendientes").toUpperCase()} onClick={(e) => { this.setState({ ABRIR_FICHA_TRAKING: true }) }} style={{ border: '0', padding: '0.5rem 0.5rem', margin: '0' }} className="btnTema"> <EnvironmentOutlined style={{ fontSize: '24px', color: '#fff' }} className="colorIconoBarra" />  </button>
                    </div>


                    <div style={{ display: this.state.FACTURACION_LITE ? '' : 'none', margin: '0px 10px 0px 0px' }}>
                      <MDBDropdown>
                        <MDBDropdownToggle nav>
                          <button title={traducirPagina("Facturación")} style={{ border: '0', padding: '0.5rem 0.5rem', margin: '0' }} className="btnTema"> <EuroCircleOutlined style={{ fontSize: '24px', color: '#fff' }} /> </button>
                        </MDBDropdownToggle>

                        <MDBDropdownMenu right>
                          <MDBDropdownItem style={{ display: this.state.esMostrador ? 'none' : '' }} onClick={() => store.dispatch(DrawerFichaPresupuestoTaller(true))}>{traducirPagina("Presupuestos")}</MDBDropdownItem>

                          {/*<MDBDropdownItem style={{ display: this.state.esMostrador ? 'none' : '' }} onClick={() => { this.setState({ ABRIR_FICHA_PRESUPUESTO_FLOTA: true }) }}>{traducirPagina("txt_presupuesto_flota")}</MDBDropdownItem>*/}

                          <MDBDropdownItem onClick={() => store.dispatch(DrawerFichaOrden(true))}>{traducirPagina("Ordenes_de_reparacion")}</MDBDropdownItem>
                          <MDBDropdownItem onClick={() => store.dispatch(DrawerFichaFactura(true))}>{traducirPagina("Facturas_de_venta")}</MDBDropdownItem>
                          <MDBDropdownItem onClick={() => store.dispatch(DrawerFichaProveedores(true))}>{traducirPagina("Proveedores")}</MDBDropdownItem>

                          <MDBDropdownItem style={{ display: this.state.esMostrador ? 'none' : '' }} onClick={() => { store.dispatch(DrawerFichaClienteTaller(true)); store.dispatch({ type: "UPDATE_FRONT_PARA_FICHA_PRESUPUESTO", esDesdeFichaPresupuesto: false }) }}>{traducirPagina("Mis_Clientes")}</MDBDropdownItem>
                          <MDBDropdownItem style={{ display: this.state.esMostrador ? 'none' : '' }} onClick={() => store.dispatch(DrawerFichaHistorialPedido(true))} >{traducirPagina("Historico_de_pedidos")}</MDBDropdownItem>
                          <MDBDropdownItem style={{ display: this.state.esMostrador ? 'none' : '' }} onClick={() => store.dispatch(DrawerFichaAlbaranTaller(true))}>{traducirPagina("Albaranes_de_compra")}</MDBDropdownItem>
                        </MDBDropdownMenu>
                      </MDBDropdown>
                    </div>



                    <MDBDropdown>
                      <MDBDropdownToggle nav caret>
                        <button title={traducirPagina("Perfil_del_usuario")} onClick={e => { createRipple(e) }} style={{ border: '0', padding: '0.5rem  0.5rem', margin: '0' }} className="btnTema"> <UserOutlined style={{ fontSize: '24px', color: '#fff' }} /> </button>
                      </MDBDropdownToggle>

                      <MDBDropdownMenu right>
                        <MDBDropdownItem onClick={() => store.dispatch(DrawerPerfilUsuario(true))}>{traducirPagina("Mi_configuracion")}</MDBDropdownItem>
                        <MDBDropdownItem onClick={() => store.dispatch(DrawerFichaEmpresa(true))}>{traducirPagina("Mi_taller")}</MDBDropdownItem>

                        {
                          !this.state.esMostrador && (
                            <MDBDropdownItem style={{ display: this.state.esMostrador ? 'none' : '' }} onClick={() => { store.dispatch(DrawerFichaClienteTaller(true)); store.dispatch({ type: "UPDATE_FRONT_PARA_FICHA_PRESUPUESTO", esDesdeFichaPresupuesto: false }) }}>{traducirPagina("Mis_Clientes")}</MDBDropdownItem>
                          )}

                        {
                          esUsuarioFlotas() ?
                            <>
                              <MDBDropdownItem style={{ display: this.state.esMostrador ? 'none' : '' }} onClick={() => { this.setState({ ABRIR_FICHA_PRESUPUESTO_FLOTA: true }) }}>{traducirPagina("txt_presupuesto_flota")}</MDBDropdownItem>

                              <MDBDropdownItem style={{ display: this.state.esMostrador ? 'none' : '' }} onClick={() => { this.setState({ ABRIR_FICHA_PRESUPUESTO_FLOTA_TALLER: true }) }}>{traducirPagina("txt_presupuesto_flota_taller")}</MDBDropdownItem>
                            </>

                            :
                            null
                        }


                        <div style={{ display: this.state.FACTURACION_LITE ? 'none' : '' }}>
                          <MDBDropdownItem style={{ display: this.state.esMostrador ? 'none' : '' }} onClick={() => store.dispatch(DrawerFichaPresupuestoTaller(true))}>{traducirPagina("Presupuestos")}</MDBDropdownItem>

                          <MDBDropdownItem style={{ display: this.state.esMostrador ? 'none' : '' }} onClick={() => store.dispatch(DrawerFichaAlbaranTaller(true))}>{traducirPagina("Albaranes_de_compra")}</MDBDropdownItem>
                          <MDBDropdownItem style={{ display: this.state.esMostrador ? 'none' : '' }} onClick={() => store.dispatch(DrawerFichaHistorialPedido(true))} >{traducirPagina("Historico_de_pedidos")}</MDBDropdownItem>
                        </div>

                        <MDBDropdownItem onClick={() => { window.open("https://download.anydesk.com/AnyDesk.exe") }}>{traducirPagina("Soporte_remoto")}</MDBDropdownItem>

                        {
                          ID_IDIOMA === 1 &&
                          <MDBDropdownItem onClick={() => { this.setState({ ABRIR_FICHA_TUTORIAL: true }) }}>{traducirPagina("Video_tutorial")}</MDBDropdownItem>

                        }



                        <MDBDropdownItem onClick={() => {
                          store.dispatch({ type: "STATE_ID_MODELO_MOTO", ID_MODELO_MOTO: null });

                          store.dispatch({ type: "STATE_TIPO_VEHICULO_POR_MODELO", TIPO_VEHICULO_POR_MODELO: "", })

                          store.dispatch({ type: "SET_TIPO_COMBUSTIBLE", TIPO_COMBUSTIBLE: 1 })

                          store.dispatch({ type: "STATE_PRELOADAER", PreLoaderPage: false })
                          store.dispatch({ type: 'UPDATE_TIPO_BUSQUEDA_MODELO', tipoBusqueda: "C", descripcion: "CARROCERIA" });
                          localStorage.removeItem('usersertec');
                          localStorage.removeItem('tokensertec');
                          store.dispatch({ type: 'STATE_ES_NEUMATICO', NEUMATICO: { ES_NEUMATICO: false, REFERENCIA_FABRICANTE: "" } })
                          limpiarGrupoDrawer();
                          history.push("/");
                          store.dispatch(updateStateMasterPage(false));
                          store.dispatch({ type: "DELETE_ITEM_HISTORIC_APP", historicapp: [] });
                          store.dispatch(showItemReferencia(''));
                          logOutSesion();
                          limpiarFichaClienteSeleccionado();
                          //window.location.reload();
                          store.dispatch({ type: "UPDATE_LISTA_PRESUPUESTO_SECUNDARIO", presupuestoSecundario: [] })
                          store.dispatch({ type: "STATE_DICCIONARIOS", DICCIONARIO: [] })

                          this.valoresPorDefectoMater();
                          this.setState({
                            TEXTO_CLIENTE_MOSTRADOR: "", COCHE_SELECCIONADO_OEM: {},
                            LIMPIAR_COCHE_ANTERIOR_OEM: false,
                            ABRIR_MODAL_OEM: false
                          })


                          store.dispatch(actualizarCodigoGT(""))
                          store.dispatch(actualizarCocheIdentificador(""))
                          store.dispatch(actualizarCocheID(""))

                          store.dispatch({ type: "STATE_FILTRO_GRUPO_POR_DEFECTO", FiltroGrupoPorDefecto: true })
                        }} >{traducirPagina("Cerrar_sesion")}</MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </MDBNavbarNav>
                </MDBNavbar>


                <div className="centerMenu2 row">
                  {Object.keys(this.state.BusquedaPorModelo).length > 0 ?
                    <MDBDropdown style={{ cursor: 'pointer' }}>
                      <MDBDropdownToggle style={{ border: 'none', verticalAlign: '-webkit-baseline-middle', padding: '0px', margin: '0px 8px' }}  >
                        <div className="estilosbotonesModalV2 colorGris2">
                          <img src={this.state.BusquedaPorModelo.tipoBusqueda === 'M' ? "./imagenes/motorv2.svg" : this.state.BusquedaPorModelo.tipoBusqueda === 'E' ? "./imagenes/eje.svg" : "./imagenes/coche.svg"} style={{ height: '26px', marginTop: '7px' }} alt={"coche"} ></img>
                        </div>
                      </MDBDropdownToggle>

                      <MDBDropdownMenu onClick={e => {
                        gestionarContenedorCoche(false);
                        store.dispatch(showItemReferencia(""));
                        limpiarCocheStore();
                        store.dispatch({
                          type: "LIST_TREE",
                          listaTree: []
                        })
                        store.dispatch({
                          type: 'UPDATE_TIPO_BUSQUEDA_MODELO',
                          tipoBusqueda: e.target.className === "dropdown-item" ? e.target.attributes[2].value : e.target.attributes[0].value,
                          descripcion: e.target.textContent.trim(),
                        })

                      }}>
                        <MDBDropdownItem name="C"><button className={"estilosbotonesModalV2 colorGris2"} style={{ height: '30px', width: '30px' }}> <img name="C" src="./imagenes/coche.svg" style={{ height: '18px', verticalAlign: 'text-top' }} /> </button> <span name="C" className="estiloTexto" style={{ marginLeft: '10px' }}>{traducirPagina("Busqueda_por_vehiculo")}</span> </MDBDropdownItem>
                        <MDBDropdownItem name="M"><button className={"estilosbotonesModalV2 colorGris2"} style={{ height: '30px', width: '30px' }}> <img name="M" src="./imagenes/motorv2.svg" style={{ height: '18px', verticalAlign: 'text-top' }} /> </button> <span name="M" className="estiloTexto" style={{ marginLeft: '10px' }}>{traducirPagina("Busqueda_por_motor")}</span>   </MDBDropdownItem>
                        <MDBDropdownItem name="E"><button className={"estilosbotonesModalV2 colorGris2"} style={{ height: '30px', width: '30px' }}> <img name="E" src="./imagenes/eje.svg" style={{ height: '18px', verticalAlign: 'text-top' }} /> </button> <span name="E" className="estiloTexto" style={{ marginLeft: '10px' }}>{traducirPagina("Busqueda_por_eje")}</span>   </MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                    :
                    null
                  }

                  <div style={{ display: obtenerTipoDeBusquedaActual() === 'C' || obtenerTipoDeBusquedaActual() === 'MT' || obtenerTipoDeBusquedaActual() === 'I' || obtenerTipoDeBusquedaActual() === 'T' ? 'inherit' : 'none' }}>
                    <button onClick={() => {
                      limpiarCocheStore();
                      MODIFICAR_TIPO_BUSQUEDA("C")

                      store.dispatch({ type: "UPDATE_BRANDS_GROUPING", marcas: [] });
                      store.dispatch({ type: "UPDATE_PAGINATION", pagination: {} });
                      store.dispatch(getIdTecdocMotorByIdHaynes(0));

                      store.dispatch(showItemReferencia("tree"));
                      store.dispatch(mostrarVistaGrafica(false))
                      store.dispatch(updateVisualizacionFiltro(true));

                      efectoRemoveBuscadorReferencia("buscadorReferencia", true);

                    }}
                      className="activebtnMenu2 btnEstiloPersonalizado" style={{ height: '40px', width: this.state.configApp.width < 800 ? '50px' : '120px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#1890ff', backgroundColor: 'transparent', marginRight: '10px' }}><GlobalOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{this.state.configApp.width < 800 ? "" : traducirPagina("Genericos")}</span>
                    </button>

                    {

                      this.state.activeItemReferencia === 'tree' || this.state.activeItemReferencia === 'listreference' ?
                        this.state.historicapp.length === 3 || (this.state.historicapp.length === 2 && obtenerTipoDeBusquedaActual() === 'MT') ?

                          <div className="grid-container" id="contenedorHistorialCoche">
                            {this.state.historicapp.map((row, i) =>
                              <div key={i} className="grid-item" style={{ cursor: 'pointer' }} onClick={() => { store.dispatch(selectCoche(this.state.historicapp)); store.dispatch(changeCocheModal(row.link)); store.dispatch(showChildrenDrawerLevel2(true)); volverABuscarVehiculo(row) }}>
                                <div className="text">{recortarCadena(row.ruta, 8, "...")}</div>
                              </div>
                            )}

                            <div className="grid-item">
                              <MDBBtn onClick={e => {
                                createRipple(e);
                                store.dispatch({ type: "STATE_SEARCH_COCHE", buscadorCoche: {} })
                                store.dispatch({ type: "SET_TIPO_COMBUSTIBLE", TIPO_COMBUSTIBLE: 1 })

                                gestionarContenedorCoche(false);
                                store.dispatch(updateVisualizacionFiltro(true))
                                store.dispatch(showItemReferencia(""));
                                limpiarCocheStore();
                                store.dispatch({
                                  type: "LIST_TREE",
                                  listaTree: []
                                })


                              }} className="btn-matricula  estiloBotonRemove"> <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} /> </MDBBtn>
                            </div>

                            <div className="grid-item barraLateral"></div>

                            <div className="grid-item">
                              <MDBBtn onClick={e => { createRipple(e); store.dispatch(DrawerFichaCocheDGT(true)) }} style={{ border: '0', padding: '6px 6px 5px 7px', margin: '0', boxShadown: 'none', backgroundColor: 'transparent !important' }} className="btn-matricula">  <img src="./imagenes/cocheGris.svg" style={{ height: '26px', color: 'rgb(102, 102, 102)', cursor: 'pointer', fontSize: '20px' }} className="" alt={"coche"} ></img> </MDBBtn>
                            </div>
                          </div>
                          :
                          <div>
                            <ComponentInputCoche listaValoresDrop={'{"nombreDrop":"Matricula","placeholder":"Buscar_coche","listaitem":[],"id":"dropcoche"}'}></ComponentInputCoche>
                            <button id='btnBuscarPorModelo' className="activebtnMenu2 btnEstiloPersonalizado" onClick={() => { store.dispatch(showItemCar("1")); store.dispatch(showChildrenDrawerLevel2(true)) }} style={{ height: '40px', width: this.state.configApp.width < 800 ? '50px' : '200px' }} ><AppstoreOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{this.state.configApp.width < 800 ? "" : traducirPagina("Buscar_por_modelo")} </span>  </button>
                          </div>
                        :
                        null
                    }

                    {/*==============================BOTON ARTICULOS VEHICULLO */}
                    {this.state.activeItemReferencia === 'tree' || this.state.activeItemReferencia === 'listreference' ?
                      this.state.historicapp.length === 3 ?
                        <div>
                          <button id="btnArtVeh" onClick={() => { store.dispatch(DrawerFichaMarcasVehiculo(true)); }}
                            className="activebtnMenu2" style={{ height: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: '#ccc', marginLeft: '10px' }}><SearchOutlined style={{ fontSize: '20px' }} />  <span style={{ verticalAlign: 'middle', fontSize: '16px' }}> {traducirPagina("Articulos_vehiculo")}</span>
                          </button>

                          {datosServiciosSertec("NEXTDATA_BASIC").CONTRATADO && ID_VEHICULO_MOTORIZACION != 0 ?
                            <button id="btnTiempos" title={traducirPagina("Tiempos_de_reparacion")} onClick={() => {

                              const SERVICIO_BASIC = datosServiciosSertec("NEXTDATA_BASIC");
                              if (SERVICIO_BASIC.CONTRATADO) {
                                switch (SERVICIO_BASIC.ORIGEN) {
                                  case "HAY":
                                    store.dispatch(DrawerFichaTiemposReparacion(true));
                                    break;
                                  case "RMI":
                                    //TEMPORAL PORQUE NO ESTA DESARROLLADO LOS TIEMPOS EN TECRMI
                                    //store.dispatch(DrawerFichaTiemposReparacion(true));
                                    this.setState({ ABRIR_FICHA_RMI_TIEMPOS: true })
                                    break;
                                }
                              }

                            }}
                              className="activebtnMenu2" style={{ height: '40px', width: '50px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: '#ccc', marginLeft: '10px' }}><FieldTimeOutlined style={{ fontSize: '28px' }} />
                            </button>
                            : null}

                          {datosServiciosSertec("NEXTDATA_BASIC").CONTRATADO && ID_VEHICULO_MOTORIZACION != 0 ?
                            <button id="btnMantenimiento" title={traducirPagina("Mantenimientos")} onClick={() => {
                              //store.dispatch(DrawerFichaMantenimientoHaynes(true));

                              const SERVICIO_BASIC = datosServiciosSertec("NEXTDATA_BASIC");
                              if (SERVICIO_BASIC.CONTRATADO) {
                                switch (SERVICIO_BASIC.ORIGEN) {
                                  case "HAY":
                                    store.dispatch(DrawerFichaMantenimientoHaynes(true));
                                    break;
                                  case "RMI":
                                    //TEMPORAL PORQUE ESTA EN DESARROLLADO LOS MANTENIMIENTOS EN TECRMI
                                    //store.dispatch(DrawerFichaMantenimientoHaynes(true));

                                    this.setState({ ABRIR_FICHA_RMI_MANTENIMIENTOS: true })
                                    break;
                                }
                              }


                            }}
                              className="activebtnMenu2" style={{ height: '40px', width: '50px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: '#ccc', marginLeft: '10px' }}><ToolOutlined style={{ fontSize: '28px' }} />
                            </button>
                            : null}

                          {
                            this.state.usuarioLocal.LoginSertec !== undefined && datosServiciosSertec("NEXTDATA_MANUALES").CONTRATADO && ID_VEHICULO_MOTORIZACION != 0 ?

                              <button onClick={() => {

                                const SERVICIO_MANUALES = datosServiciosSertec("NEXTDATA_MANUALES");
                                if (SERVICIO_MANUALES.CONTRATADO) {
                                  switch (SERVICIO_MANUALES.ORIGEN) {
                                    case "HAY":
                                      this.setState({ ABRIR_FICHA_MANUALES: true })
                                      break;
                                    case "RMI":
                                      this.setState({ ABRIR_FICHA_RMI_MANUALES: true })
                                      break;
                                  }

                                }

                              }}
                                className="activebtnMenu2" style={{ height: '40px', width: '50px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: '#ccc', marginLeft: '10px' }}> <FileTextOutlined style={{ fontSize: '25px' }} />
                              </button>

                              :
                              null
                          }

                          {this.state.PERMISO_DOCUMENTACION_TECNICA && ID_VEHICULO_MOTORIZACION != 0 ?
                            <button title="RAPID" onClick={() => { this.setState({ ABRIR_FICHA_FAST: true }) }}
                              className="activebtnMenu2" style={{ height: '40px', width: '50px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: '#ccc', marginLeft: '10px' }}> <img src="./imagenes/fast.png" style={{ width: '35px' }} />
                            </button>
                            : null}

                          {datosServiciosSertec("NEXTDATA_BASIC").CONTRATADO && ID_VEHICULO_MOTORIZACION != 0 ?
                            <button title={traducirPagina("Liquidos_y_lubricantes")} onClick={() => {

                              const SERVICIO_BASIC = datosServiciosSertec("NEXTDATA_BASIC");
                              if (SERVICIO_BASIC.CONTRATADO) {
                                switch (SERVICIO_BASIC.ORIGEN) {
                                  case "HAY":
                                    store.dispatch(DrawerFichaLubricantes(true));
                                    break;
                                  case "RMI":
                                    this.setState({ ABRIR_FICHA_RMI_FLUIDOS: true })
                                    break;
                                }
                              }

                            }}
                              className="activebtnMenu2" style={{ height: '40px', width: '50px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#1890ff', backgroundColor: '#ccc', marginLeft: '10px' }}> <img src="./imagenes/car_oil_black.png" style={{ width: '30px' }} />
                            </button>
                            : null}


                          {ID_VEHICULO_MOTORIZACION != 0 ?
                            <button title={traducirPagina("Baterias")} onClick={() => { store.dispatch(DrawerFichaBateriasVehiculo(true)) }}
                              className="activebtnMenu2 " style={{ height: '40px', width: '50px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#1890ff', backgroundColor: '#ccc', marginLeft: '10px' }}>  <img src="./imagenes/bateria_coche_black.png" style={{ width: '30px' }} />
                            </button>
                            :
                            null
                          }

                          {datosServiciosSertec("NEXTDATA_BASIC").CONTRATADO && ID_VEHICULO_MOTORIZACION != 0 ?
                            <button title={traducirPagina("Ajustes")} onClick={() => {
                              //this.setState({ ABRIR_FICHA_RMI_AJUSTES: true })

                              const SERVICIO_BASIC = datosServiciosSertec("NEXTDATA_BASIC");
                              if (SERVICIO_BASIC.CONTRATADO) {
                                switch (SERVICIO_BASIC.ORIGEN) {
                                  case "HAY":
                                    this.setState({ ABRIR_FICHA_AJUSTES: true })
                                    break;
                                  case "RMI":
                                    this.setState({ ABRIR_FICHA_RMI_AJUSTES: true })
                                    break;
                                }
                              }

                            }}
                              className="activebtnMenu2" style={{ height: '40px', width: '50px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', backgroundColor: '#ccc', marginLeft: '10px' }}>  <img src="./imagenes/ajustes.png" style={{ width: '30px' }} />
                            </button>
                            :
                            null
                          }


                          {ID_VEHICULO_MOTORIZACION != 0 ?
                            <button title={traducirPagina("Neumáticos")} onClick={() => {
                              let ID_VEHICULO = store.getState().codmotorizacion;
                              this.setState({ ABRIR_FICHA_NEUMATICOS_ERP: true, ID_VEHICULO_NEUMATICO: ID_VEHICULO });

                            }}
                              className="activebtnMenu2 " style={{ height: '40px', width: '50px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: '#ccc', marginLeft: '10px' }}> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}><img src="./imagenes/neumatico_ng.png" style={{ width: '30px' }} /></span>
                            </button>
                            :
                            null
                          }

                        </div>
                        :
                        null
                      : null
                    }

                    {
                      this.state.activeItemReferencia === '' ?
                        <div>
                          <ComponentInputCoche listaValoresDrop={'{"nombreDrop":"Matricula","placeholder":"Buscar_coche","listaitem":[],"id":"dropcoche"}'}></ComponentInputCoche>

                          <button id='btnBuscarPorModelo' className="activebtnMenu2 btnEstiloPersonalizado" onClick={() => { store.dispatch(showItemCar("1")); store.dispatch(showChildrenDrawerLevel2(true)) }} style={{ height: '40px', width: this.state.configApp.width < 800 ? '50px' : '200px' }} ><AppstoreOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{this.state.configApp.width < 800 ? "" : traducirPagina("Buscar_por_modelo")} </span>  </button>
                        </div>
                        :
                        null
                    }


                    <div style={{ display: this.state.PERMISO_CATALOGO_ORIGINAL ? '' : 'none' }}>
                      <button title="OEM" onClick={() => {
                        this.verificarPermisoOEM();
                      }}
                        className="activebtnMenu2 " style={{ height: '40px', width: '50px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: '#ccc', marginLeft: '10px' }}> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>OEM</span>
                      </button>
                    </div>



                    {
                      idModeloLatam() !== null && (
                        <CPBtndoc
                          EVENTO_ABRIR_VISOR={this.gestionarFichaDocumento}
                        />
                      )
                    }


                  </div>


                  <div style={{ display: obtenerTipoDeBusquedaActual() === 'M' ? 'inherit' : 'none' }}>
                    {
                      this.state.historicapp.length > 0 && obtenerTipoDeBusquedaActual() === 'M' ?

                        <div className="grid-container">
                          {this.state.historicapp.map((row, i) =>
                            <div key={i} className="grid-item" style={{ cursor: 'pointer' }} onClick={() => {
                              limpiarCocheStore();
                              store.dispatch(showItemCar("1"));
                              store.dispatch(showItemReferencia(""));
                              store.dispatch({
                                type: "LIST_TREE",
                                listaTree: []
                              })
                              store.dispatch(showChildrenDrawerLevel2(true));


                            }}>
                              <div className="text">{row.ruta.CODIGO_MOTOR}</div>
                            </div>
                          )}

                          <div className="grid-item">
                            <MDBBtn onClick={e => {
                              createRipple(e);
                              gestionarContenedorCoche(false);
                              store.dispatch(showItemReferencia(""));
                              limpiarCocheStore();
                              store.dispatch({
                                type: "LIST_TREE",
                                listaTree: []
                              })
                            }} className="btn-matricula  estiloBotonRemove"> <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} /> </MDBBtn>
                          </div>

                          <div className="grid-item barraLateral"></div>

                          <div className="grid-item">
                            <MDBBtn onClick={e => { createRipple(e); store.dispatch(DrawerFichaCocheDGT(true)) }} style={{ border: '0', padding: '6px 6px 5px 7px', margin: '0', boxShadown: 'none', backgroundColor: 'transparent !important' }} className="btn-matricula">  <img src="./imagenes/cocheGris.svg" style={{ height: '26px', color: 'rgb(102, 102, 102)', cursor: 'pointer', fontSize: '20px' }} className="" alt={"coche"} ></img> </MDBBtn>
                          </div>

                        </div>
                        :
                        <div>
                          <div className="grid-container">
                            <div className="grid-item">
                              <input className="form-control boxCoche" type="text" aria-label="Search" placeholder={traducirPagina("Busqueda_por_motor")} style={{ backgroundColor: "#eee", width: '300px', border: '0' }} autoComplete="off" onKeyPress={(e) => e.key === 'Enter' ? store.dispatch(onChildrenDrawerCloseLevel2(true)) : {}} value={this.state.TextoBuscadorPorModelo} onChange={(e) => { store.dispatch({ type: "UPDATE_FILTRO_BUSQUEDA", TextoBuscadorPorModelo: e.target.value }) }} />
                            </div>

                            <div className="grid-item">
                              <button onClick={e => { }} className="btn-matricula  estiloBotonRemove" style={{ visibility: 'hidden' }}> <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} /> </button>
                            </div>

                            <div className="grid-item barraLateral" ></div>

                            <div className="grid-item">
                              <MDBBtn onClick={e => { createRipple(e); store.dispatch(onChildrenDrawerCloseLevel2(true)) }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none', backgroundColor: 'transparent !important' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                            </div>
                          </div>
                          <button className="activebtnMenu2 btnEstiloPersonalizado" onClick={() => { store.dispatch(showItemCar("1")); store.dispatch(showChildrenDrawerLevel2(true)) }} style={{ height: '40px', width: this.state.configApp.width < 800 ? '50px' : '200px' }} ><AppstoreOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{this.state.configApp.width < 800 ? "" : traducirPagina("Buscar_por_motor")} </span>  </button>
                        </div>
                    }
                  </div>


                  <div style={{ display: obtenerTipoDeBusquedaActual() === 'E' ? 'inherit' : 'none' }}>
                    {
                      this.state.historicapp.length > 0 && obtenerTipoDeBusquedaActual() === 'E' ?
                        <div className="grid-container" >
                          {this.state.historicapp.map((row, i) =>
                            <div key={i} className="grid-item" style={{ cursor: 'pointer' }} onClick={() => {
                              limpiarCocheStore();
                              store.dispatch(showItemCar("1"));
                              store.dispatch(showItemReferencia(""));
                              store.dispatch({
                                type: "LIST_TREE",
                                listaTree: []
                              })
                              store.dispatch(showChildrenDrawerLevel2(true));

                            }}>
                              <div className="text">{row.ruta}</div>
                            </div>
                          )}

                          <div className="grid-item">
                            <MDBBtn onClick={e => {
                              createRipple(e);
                              gestionarContenedorCoche(false);
                              store.dispatch(showItemReferencia(""));
                              limpiarCocheStore();
                              store.dispatch({
                                type: "LIST_TREE",
                                listaTree: []
                              })
                            }} className="btn-matricula  estiloBotonRemove"> <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} /> </MDBBtn>
                          </div>

                          <div className="grid-item barraLateral"></div>

                          <div className="grid-item">
                            <MDBBtn onClick={e => { createRipple(e); store.dispatch(DrawerFichaCocheDGT(true)) }} style={{ border: '0', padding: '6px 6px 5px 7px', margin: '0', boxShadown: 'none', backgroundColor: 'transparent !important' }} className="btn-matricula">  <img src="./imagenes/cocheGris.svg" style={{ height: '26px', color: 'rgb(102, 102, 102)', cursor: 'pointer', fontSize: '20px' }} className="" alt={"coche"} ></img> </MDBBtn>
                          </div>
                        </div>
                        :
                        <div>
                          <button className="activebtnMenu2 btnEstiloPersonalizado" onClick={() => { store.dispatch(showItemCar("1")); store.dispatch(showChildrenDrawerLevel2(true)) }} style={{ height: '40px', width: this.state.configApp.width < 800 ? '50px' : '200px' }} ><AppstoreOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{this.state.configApp.width < 800 ? "" : traducirPagina("Buscar_por_eje")} </span>  </button>

                        </div>
                    }
                  </div>


                  {!this.state.esMostrador ?
                    <div style={{ flexGrow: '8', textAlign: 'end', display: 'flex', justifyContent: 'flex-end' }} className="d-none d-md-inline">


                      {this.state.usuarioLocal.LoginSertec !== undefined ?
                        this.state.usuarioLocal.LoginSertec.SERVICIOS.filter((registro) => registro.PLATAFORMA === "ASSIST")[0].USUARIO !== "" || this.state.usuarioLocal.LoginSertec.SERVICIOS.filter((registro) => registro.PLATAFORMA === "ACADEMY")[0].USUARIO !== "" ?
                          <button className="activebtnMenu3 btnColor" title={traducirPagina("Boletines")} style={{ padding: '0px 10px', height: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#fff', backgroundColor: '#4285f4' }} onClick={() => {
                            this.setState({ ABRIR_FICHA_BOLETINES: true })

                          }}> <FileDoneOutlined style={{ fontSize: '24px' }}> </FileDoneOutlined> <span style={{ verticalAlign: 'middle', fontSize: '16px', display: mostrarIcono(1500) }}>{traducirPagina("Boletines")} </span>
                          </button>
                          :
                          null
                        : null}


                      {/*this.state.plantillaCss.toUpperCase() === "TEMPLATENEXUS.CSS" || this.state.plantillaCss.toUpperCase() === "TEMPLATESPG.CSS"*/
                        /*
                          this.state.plantillaCss.toUpperCase() !== "TEMPLATENEXUS.CSS" ?
                          <button id='btnIntranet' title={traducirPagina("Intranet")} className="activebtnMenu3 btnColor" style={{ padding: '0px 10px', height: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#fff', backgroundColor: '#4285f4' }} onClick={() => {
                            this.verServicios("INTRANET")
                          }}><GlobalOutlined style={{ fontSize: '24px' }}></GlobalOutlined> <span style={{ verticalAlign: 'middle', fontSize: '16px', display: mostrarIcono(1500) }}>{traducirPagina("Intranet")} </span>
                          </button>
                          :
                          null
                          */
                      }

                      <button className="activebtnMenu3 btnColor" style={{ padding: '0px 10px', height: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#fff', backgroundColor: '#4285f4', display: this.state.PERMISO_GTESTIMATE ? this.state.MATRICULA_BASTIDOR === undefined || this.state.MATRICULA_BASTIDOR === "" ? 'none' : '' : 'none' }} onClick={() => {
                        if (this.state.GT_ESTIMATE.CODIGO === "") {
                          //store.dispatch(DrawerFichaGTEstimate(true))
                          this.setState({ ABRIR_MODAL_GT: true });

                        } else {
                          if (this.state.MATRICULA_BASTIDOR === this.state.GT_ESTIMATE.IDENTIFICADOR_COCHE) {
                            store.dispatch(DrawerFichaGTEstimate(true))
                          } else {
                            this.setState({ ABRIR_MODAL_GT: true })
                            // aviso de perdida de presupuesto
                          }
                        }
                      }}><img src="./imagenes/gtEstimate.png" title="GT Estimate" style={{ height: '24px' }}></img>  <span style={{ verticalAlign: 'middle', fontSize: '16px', display: mostrarIcono(1500) }}> GT Estimate</span>
                      </button>


                      <button id='btnHaynesPro' title="Data" className="activebtnMenu3 btnColor" style={{ padding: '0px 10px', height: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#fff', backgroundColor: '#4285f4' }}
                        onClick={() => {
                          //this.verServicios("HAYNESS")
                          const SERVICIO_COMPLETO = datosServiciosSertec("NEXTDATA_COMPLETO");
                          if (SERVICIO_COMPLETO.CONTRATADO) {
                            switch (SERVICIO_COMPLETO.ORIGEN) {
                              case "HAY":
                                store.dispatch(DrawerHaynesPro(true));
                                break;
                              case "RMI":
                                this.setState({ ABRIR_FICHA_RMI_DATA: true })
                                break;
                            }
                          } else {
                            store.dispatch(DrawerFichaPublicidad(true));
                            this.setState({ subtituloFichaPublicidad: traducirPagina("No_licencia_HaynesPro"), tipoLicencia: "NEXT DATA" })
                          }

                        }}><img src="./imagenes/next_data.png" style={{ height: '24px' }}></img>  <span style={{ verticalAlign: 'middle', fontSize: '16px', display: mostrarIcono(1500) }}> Data</span>
                      </button>



                      <button id='btnNextAssist' title="Assist" className="activebtnMenu3 btnColor" style={{ padding: '0px 10px', height: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#fff', backgroundColor: '#4285f4' }} onClick={() => {

                        this.verServicios("ASSIST")
                      }}> <img src="./imagenes/next_assist.png" style={{ height: '24px' }}></img>  <span style={{ verticalAlign: 'middle', fontSize: '16px', display: mostrarIcono(1500) }}> Assist</span>  </button>

                      <MDBDropdown>


                        <MDBDropdownToggle nav >
                          <button id='btnAcademyCloud' className="activebtnMenu3  btnColor" title="Academy" style={{ padding: '0px 10px', height: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#fff', backgroundColor: '#4285f4' }} onClick={() => { }}><img src="./imagenes/next_academy.png" style={{ height: '24px' }}></img>  <span style={{ verticalAlign: 'middle', fontSize: '16px', display: mostrarIcono(1500) }}>Academy </span>  </button>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu right>
                          {
                            this.state.plantillaCss.toUpperCase() === "TEMPLATENEXUS.CSS" ?
                              <MDBDropdownItem style={{ display: this.state.plantillaCss.toUpperCase() === "TEMPLATENEXUS.CSS" ? '' : 'none' }} onClick={() => {
                                store.dispatch(DrawerAcademyCloud(true))
                                this.setState({
                                  tituloAcademy: "N! ACADEMY",
                                  urlAcademy: "https://nacademyinternational.com/"
                                })
                              }
                              }>{"N! Academy"}</MDBDropdownItem>
                              :
                              <>
                                <MDBDropdownItem onClick={async () => {

                                  /*
                                    if (ID_PAIS === 3) {
                                      this.verServicios("ACADEMY")
                                      this.setState({ tituloAcademy: TITULO_ACADEMY.toUpperCase(), urlAcademy: URL_ACADEMY })
                                    } else {
                                    }
                                  */

                                  const SERVICIO_INFO_TECHNIC = datosServiciosSertec("NEXTDATA_INFOTECNIC");
                                  if (SERVICIO_INFO_TECHNIC.CONTRATADO) {

                                    const PKTYPE_VEHICULO = ID_VEHICULO_MOTORIZACION === undefined || ID_VEHICULO_MOTORIZACION === null || ID_VEHICULO_MOTORIZACION === "" ? null : Number(ID_VEHICULO_MOTORIZACION)
                                    
                                    let data = await InfotechniLogincEnAPI(SERVICIO_INFO_TECHNIC.USUARIO, SERVICIO_INFO_TECHNIC.PWD, PKTYPE_VEHICULO)
                                    this.setState({ tituloAcademy: TITULO_ACADEMY.toUpperCase(), urlAcademy: data.url })
                                    store.dispatch(DrawerAcademyCloud(true))

                                  } else {
                                    store.dispatch(DrawerFichaPublicidad(true));
                                    this.setState({ subtituloFichaPublicidad: traducirPagina("No_licencia_Academy_Cloud"), tipoLicencia: "NEXT ACADEMY" })
                                  }
                                }

                                }>{TITULO_ACADEMY}</MDBDropdownItem>


                                <MDBDropdownItem onClick={() => {
                                  store.dispatch(DrawerAcademyCloud(true))
                                  this.setState({
                                    tituloAcademy: "ACADEMY CLOUD",
                                    urlAcademy: "https://academy.servicenext.eu/login/index.php"
                                  })
                                }
                                }>{"Reevolución"}</MDBDropdownItem>
                              </>
                          }

                        </MDBDropdownMenu>
                      </MDBDropdown>
                    </div>
                    :
                    <div style={{ flexGrow: '8', textAlign: 'end', display: 'flex', justifyContent: 'flex-end' }} className="d-none d-md-inline">


                      {this.state.usuarioLocal.LoginSertec !== undefined ?
                        this.state.usuarioLocal.LoginSertec.SERVICIOS.filter((registro) => registro.PLATAFORMA === "ACADEMY")[0].USUARIO !== "" ?
                          <button id='btnIntranet' title={traducirPagina("Boletines")} className="activebtnMenu3 btnColor" style={{ padding: '0px 10px', height: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#fff', backgroundColor: '#4285f4' }} onClick={() => {
                            this.setState({ ABRIR_FICHA_BOLETINES: true })
                          }}><FileDoneOutlined style={{ fontSize: '24px' }}></FileDoneOutlined> <span style={{ verticalAlign: 'middle', fontSize: '16px', display: mostrarIcono(1500) }}>{traducirPagina("Boletines")} </span>
                          </button>
                          :
                          null
                        :
                        null}

                      {/*this.state.plantillaCss.toUpperCase() === "TEMPLATENEXUS.CSS"*/
                        /*
                         this.state.plantillaCss.toUpperCase() !== "TEMPLATENEXUS.CSS" ?
                          <button id='btnIntranet' title={traducirPagina("Intranet")} className="activebtnMenu3 btnColor" style={{ padding: '0px 10px', height: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#fff', backgroundColor: '#4285f4' }} onClick={() => {
                            this.verServicios("INTRANET")
                          }}><GlobalOutlined style={{ fontSize: '24px' }}></GlobalOutlined> <span style={{ verticalAlign: 'middle', fontSize: '16px', display: mostrarIcono(1500) }}>{traducirPagina("Intranet")} </span>
                          </button>
                          :
                          null
                          */
                      }


                      <button className="activebtnMenu3 btnColor" title="Comm" style={{ padding: '0px 10px', height: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#fff', backgroundColor: '#4285f4', display: this.state.PERMISO_GTESTIMATE ? this.state.MATRICULA_BASTIDOR === undefined || this.state.MATRICULA_BASTIDOR === "" ? 'none' : '' : 'none' }} onClick={() => {
                        if (this.state.GT_ESTIMATE.CODIGO === "") {
                          store.dispatch(DrawerFichaGTEstimate(true))
                        } else {
                          if (this.state.MATRICULA_BASTIDOR === this.state.GT_ESTIMATE.IDENTIFICADOR_COCHE) {
                            store.dispatch(DrawerFichaGTEstimate(true))
                          } else {
                            this.setState({ ABRIR_MODAL_GT: true })
                            // aviso de perdida de presupuesto
                          }
                        }
                      }}><img src="./imagenes/gtEstimate.png" style={{ height: '24px' }}></img>  <span style={{ verticalAlign: 'middle', fontSize: '16px', display: mostrarIcono(1500) }}> GT Estimate</span>
                      </button>

                      <button id='btnHaynesPro' title="Data" className="activebtnMenu3 btnColor" style={{ padding: '0px 10px', height: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#fff', backgroundColor: '#4285f4' }} onClick={() => {
                        const SERVICIO_COMPLETO = datosServiciosSertec("NEXTDATA_COMPLETO");
                        if (SERVICIO_COMPLETO.CONTRATADO) {
                          switch (SERVICIO_COMPLETO.ORIGEN) {
                            case "HAY":
                              store.dispatch(DrawerHaynesPro(true));
                              break;
                            case "RMI":
                              this.setState({ ABRIR_FICHA_RMI_DATA: true })
                              break;
                          }
                        } else {
                          store.dispatch(DrawerFichaPublicidad(true));
                          this.setState({ subtituloFichaPublicidad: traducirPagina("No_licencia_HaynesPro"), tipoLicencia: "NEXT DATA" })
                        }
                      }}><img src="./imagenes/next_data.png" style={{ height: '24px' }}></img>  <span style={{ verticalAlign: 'middle', fontSize: '16px', display: mostrarIcono(1500) }}> Data</span>
                      </button>



                      <div className="grid-container" style={{ backgroundColor: '#eee', marginTop: '2px', marginLeft: '10px' }}>

                        <div className="grid-item" style={{ cursor: 'no-drop' }}>
                          <input id="buscadorCliente" className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_cliente")} style={{ backgroundColor: "transparent", width: '220px', border: '0px', height: '100%', color: '#000' }} name="txtInputCliente" autoComplete="off"
                            value={this.state.clienteSeleccionado.nombre !== "" || this.state.clienteSeleccionado.codigo !== "" ? `${this.state.clienteSeleccionado.codigo} - ${this.state.clienteSeleccionado.nombre}` : this.state.TEXTO_CLIENTE_MOSTRADOR}
                            onChange={(e) => { this.setState({ TEXTO_CLIENTE_MOSTRADOR: e.target.value }) }}
                            onKeyPress={(e) => e.key === 'Enter' ? filtarClientes(this.state.TEXTO_CLIENTE_MOSTRADOR) : {}}
                          />
                        </div>

                        <div className="grid-item" >
                          <MDBBtn id="closeBuscadorCliente" onClick={e => { createRipple(e); efectoRemoveBuscador("buscadorCliente", true, "closeBuscadorCliente"); limpiarFichaClienteSeleccionado(); volverAMostrarReferenciaPorCliente(); this.setState({ TEXTO_CLIENTE_MOSTRADOR: "" }) }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: this.state.TEXTO_CLIENTE_MOSTRADOR === "" ? 'hidden' : 'visible' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                        </div>

                        <div className="grid-item barraLateral" ></div>

                        <div className="grid-item">
                          <MDBBtn onClick={e => { createRipple(e); store.dispatch(DrawerFichaCliente(true)); }} style={{ border: '0', padding: '3px', boxShadown: 'none' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                        </div>
                        <div className="grid-item barraLateral" ></div>

                        <div className="grid-item">
                          <MDBBtn onClick={e => { createRipple(e); store.dispatch(DrawerFichaDatosCliente(true)); }} style={{ border: '0', padding: '2px 0px 3px 4px', boxShadown: 'none' }} className="btn-matricula">  <UserOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                        </div>

                      </div>
                    </div>
                  }

                  <div style={{ display: 'none' }}>
                    <ComponentInputCoche listaValoresDrop={'{"nombreDrop":"","placeholder":"Buscar por cliente","listaitem":[],"id":"dropcliente"}'}>  </ComponentInputCoche>
                  </div>


                </div>
              </div>

              <FichaCarroCompra title={this.state.referenciaActual.TITULO === undefined ? traducirPagina("Añadir_referencia_a_la_cesta").toUpperCase() : this.state.referenciaActual.TITULO} referencia={this.state.referenciaActual.REFERENCIA} marca={this.state.referenciaActual.CODIGO_MARCA} referenciaProveedor={this.state.referenciaActual.REFERENCIA_PROVEEDOR} cantidad={this.state.referenciaActual.CANTIDAD} almacen={this.state.referenciaActual.ALMACEN} IDCESTA={this.state.referenciaActual.IDCESTA} REGISTROS={this.state.referenciaActual.REGISTROS}></FichaCarroCompra>

              <FichaCocheDGT title={traducirPagina("FICHA_DEL_VEHÍCULO")} codMotorizacionSeleccionada={this.state.codMotorizacionFichaHistorial.toString()} ></FichaCocheDGT>

              <ContainerDrawerFicha ></ContainerDrawerFicha>

              {
                this.state.openDrawerIntegraciones ?
                  <ContainerDrawerIntegraciones></ContainerDrawerIntegraciones>
                  :
                  null
              }


              <FichaMasInfo title={traducirPagina("DETALLE_REFERENCIA")} referencia={this.state.referenciaSeleccionada.REFERENCIA} marca={this.state.referenciaSeleccionada.CODIGO_MARCA}></FichaMasInfo>

              <FichaHistoricoCoche verFichaHistorico={this.state.estadoDrawer.openDrawerHistoricoCoche} title={traducirPagina("Historial_de_busquedas").toUpperCase()} widthFicha={900} config={this.state.configApp}></FichaHistoricoCoche>

              <FichaStockAlmacen title={traducirPagina("Desglose_disponibilidad").toUpperCase()} referencia={this.state.referenciaActual.REFERENCIA} marca={this.state.referenciaActual.CODIGO_MARCA} referenciaProveedor={this.state.referenciaActual.REFERENCIA_PROVEEDOR} ></FichaStockAlmacen>

              <FichaReferenciaGrafico title={traducirPagina("BÚSQUEDA_REFERENCIAS")}></FichaReferenciaGrafico>

              <FichaHaynesPro title={"NEXT DATA"}></FichaHaynesPro>


              <FichaPerfil title={traducirPagina("Mi_configuracion").toUpperCase()}></FichaPerfil>

              <FichaNexAssist title={"NEXT ASSIST"}> </FichaNexAssist>

              <FichaAcademy title={this.state.tituloAcademy} url={this.state.urlAcademy}></FichaAcademy>

              <FichaCliente title={traducirPagina("Buscar_cliente").toUpperCase()}></FichaCliente>

              <FichaClienteTaller title={traducirPagina("Clientes_del_taller").toUpperCase()} ></FichaClienteTaller>

              <FichaMasInfo2 title={traducirPagina("DETALLE_REFERENCIA")} referencia={this.state.referenciaMasInfo2.REFERENCIA} marca={this.state.referenciaMasInfo2.CODIGO_MARCA}></FichaMasInfo2>

              <FichaDatosCliente title={traducirPagina("Datos_del_cliente").toUpperCase()}></FichaDatosCliente>

              <FichaPresupuestoTaller title={traducirPagina("Presupuestos_del_taller").toUpperCase()}> </FichaPresupuestoTaller>

              <FichaMarcasVehiculo title={traducirPagina("Seleccione_una_marca").toUpperCase()}></FichaMarcasVehiculo>

              <FichaReferenciaMarcaVehiculo title={traducirPagina("Referencias_de_una_marca").toUpperCase()}></FichaReferenciaMarcaVehiculo>

              {this.state.estadoDrawer.openDrawerFichaEquivalenciaArticulo ?
                <FichaEquivalenciaArticulo title={traducirPagina("Equivalencia_de_un_articulo").toUpperCase()}></FichaEquivalenciaArticulo>
                :
                null
              }

              <FichaAlbaranesTaller title={traducirPagina("Albaranes_de_compra").toUpperCase()}></FichaAlbaranesTaller>

              <FichaEmpresa title={traducirPagina("Ficha_empresa").toUpperCase()} ></FichaEmpresa>

              <FichaVehiculoCliente></FichaVehiculoCliente>

              <FichaTiemposReparacion title={traducirPagina("Tiempos_de_reparacion").toUpperCase()}></FichaTiemposReparacion>

              <FichaMantenimientoHaynesPro title={traducirPagina("Mantenimientos")}></FichaMantenimientoHaynesPro>

              <FichaPublicidad title={traducirPagina("Servicios").toUpperCase()} SUBTITULO_FICHA={this.state.subtituloFichaPublicidad} TIPO_LICENCIA={this.state.tipoLicencia}></FichaPublicidad>

              <FichaHistorialPedido title={traducirPagina("Historial_de_pedidos").toUpperCase()} ></FichaHistorialPedido>

              <FichaProveedores title={traducirPagina("Ficha_de_proveedores").toUpperCase()}> </FichaProveedores>

              <FichaOrdenReparacionTaller title={traducirPagina("Orden_reparacion_taller").toUpperCase()}> </FichaOrdenReparacionTaller>

              <FichaFacturaTaller title={traducirPagina("Facturas_venta_taller").toUpperCase()}></FichaFacturaTaller>

              <FichaIntranet></FichaIntranet>


              {/*this.state.ABRIR_FICHA_NEUMATICOS ?
                <FichaNeumaticos
                  title={traducirPagina("Catalogo_de_neumaticos").toUpperCase()}
                  ABRIR_FICHA={this.state.ABRIR_FICHA_NEUMATICOS}
                  CERRAR={this}
                  TIPO={"MASTER"}
                  MEDIDAS={""}
                  ID_VEHICULO={parseInt(this.state.ID_VEHICULO_NEUMATICO)}
                ></FichaNeumaticos>
                : null
            */}


              {
                this.state.ABRIR_FICHA_NEUMATICOS_ERP ?
                  <FichaNeumaticosErp
                    title={traducirPagina("Catalogo_de_neumaticos").toUpperCase()}
                    ABRIR_FICHA={this.state.ABRIR_FICHA_NEUMATICOS_ERP}
                    CERRAR={this}
                    TIPO={"MASTER"}
                    MEDIDAS={""}
                    ID_VEHICULO={comprobarNeumatico(this.state.ID_VEHICULO_NEUMATICO)}
                  >

                  </FichaNeumaticosErp>
                  :
                  null
              }


              {
                this.state.ABRIR_FICHA_LUBRICANTES ?
                  <FichaLubricantesERP
                    title={traducirPagina("Catalogo_de_lubricantes").toUpperCase()}
                    ABRIR_FICHA={this.state.ABRIR_FICHA_LUBRICANTES}
                    CERRAR={this}
                    TIPO={"MASTER"}
                  >

                  </FichaLubricantesERP>
                  :
                  null
              }

              {this.state.ABRIR_FICHA_BATERIAS ?
                <FichaBaterias
                  title={traducirPagina("Catalogo_de_baterias").toUpperCase()}
                  ABRIR_FICHA={this.state.ABRIR_FICHA_BATERIAS}
                  CERRAR={this}
                  TIPO={"MASTER"}
                >
                </FichaBaterias>

                :
                null
              }

              {
                this.state.ABRIR_CATALOGO_BATERIAS &&
                (
                  <CatalogoBaterias
                    title={traducirPagina("Catalogo_de_baterias").toUpperCase()}
                    ABRIR_FICHA={this.state.ABRIR_CATALOGO_BATERIAS}
                    CERRAR={this}
                    TIPO={"MASTER"}
                  >

                  </CatalogoBaterias>
                )
              }

              <FichaBateriasVehiculo
                title={traducirPagina("Catalogo_de_baterias").toUpperCase()}
                TIPO={"MASTER"}
              >
              </FichaBateriasVehiculo>


              <FichaYQ
                title={traducirPagina("Catalogo_original").toUpperCase()}
                ABRIR_FICHA={this.state.ABRIR_FICHA_YQ}
                CERRAR={this}
                TIPO={"MASTER"}
                LIMPIAR_COCHE_ANTERIOR_OEM={this.state.LIMPIAR_COCHE_ANTERIOR_OEM}
              >
              </FichaYQ>



              {this.state.ABRIR_FICHA_TELEGRAM ?
                <FichaTelegram
                  title={"MENSAJES CLIENTES"}
                  ABRIR_FICHA={this.state.ABRIR_FICHA_TELEGRAM}
                  CERRAR={this}
                  TIPO={"MASTER"}
                >

                </FichaTelegram>
                :
                null}


              {this.state.ABRIR_FICHA_BOLETINES ?
                <FichaBoletines
                  title={traducirPagina("Boletines").toUpperCase()}
                  ABRIR_FICHA={this.state.ABRIR_FICHA_BOLETINES}
                  CERRAR={this}
                  TIPO={"MASTER"}
                >
                </FichaBoletines>

                : null}

              {this.state.ABRIR_FICHA_TRAKING ?
                <Traking
                  title={traducirPagina("Pedidos_pendientes").toUpperCase()}
                  ABRIR_FICHA={this.state.ABRIR_FICHA_TRAKING}
                  CERRAR={this}
                  TIPO={"MASTER"}
                >
                </Traking>

                : null
              }

              {this.state.ABRIR_FICHA_FAST ?
                <FichaFast
                  title={traducirPagina("Estimacion_presupuestos").toUpperCase()}
                  ABRIR_FICHA={this.state.ABRIR_FICHA_FAST}
                  CERRAR={this}
                  TIPO={"MASTER"}
                >
                </FichaFast>

                : null
              }

              {this.state.ABRIR_FICHA_MANUALES ?
                <Manuales
                  title={traducirPagina("Manuales_reparacion").toUpperCase()}
                  ABRIR_FICHA={this.state.ABRIR_FICHA_MANUALES}
                  CERRAR={this}
                  TIPO={"MASTER"}
                >
                </Manuales>
                :
                null
              }

              {
                this.state.ABRIR_FICHA_TUTORIAL ?
                  <FichaTutorial
                    title={traducirPagina("Video_tutorial").toUpperCase()}
                    ABRIR_FICHA={this.state.ABRIR_FICHA_TUTORIAL}
                    CERRAR={this}
                    TIPO={"MASTER"}
                  >
                  </FichaTutorial>
                  :
                  null
              }

              {
                this.state.ABRIR_FICHA_AJUSTES ?
                  <FichaAjustes
                    title={traducirPagina("titulo_ajustes").toUpperCase()}
                    ABRIR_FICHA={this.state.ABRIR_FICHA_AJUSTES}
                    CERRAR={this}
                    TIPO={"MASTER"}

                  >

                  </FichaAjustes>
                  :
                  null
              }



              {obtenerTipoDeBusquedaActual() === 'C' ?
                <FichaLubricante title={traducirPagina("Liquidos_y_lubricantes").toUpperCase()}></FichaLubricante>
                :
                null
              }

              {this.state.estadoDrawer.openDrawerFichaGTEstimate ?

                <FichaGTEstimate title={"GT ESTIMATE"} IDENTIFICADOR={""} TIPO_BUSQUEDA={""} ></FichaGTEstimate>
                :
                null
              }

              {
                this.state.ABRIR_FICHA_RMI_FLUIDOS &&
                <RMIFluidos
                  ABRIR_FICHA={this.state.ABRIR_FICHA_RMI_FLUIDOS}
                  CERRAR={this}
                  TIPO_FICHA={"MASTER"}
                  title={traducirPagina("Liquidos_y_lubricantes").toUpperCase()}
                  ID_VEHICULO={ID_VEHICULO_MOTORIZACION}


                ></RMIFluidos>
              }

              {
                this.state.ABRIR_FICHA_RMI_MANUALES &&
                <RMIManuales
                  ABRIR_FICHA={this.state.ABRIR_FICHA_RMI_MANUALES}
                  CERRAR={this}
                  TIPO_FICHA={"MASTER"}
                  title={traducirPagina("Manuales_reparacion").toUpperCase()}
                  ID_VEHICULO={ID_VEHICULO_MOTORIZACION}
                >

                </RMIManuales>
              }

              {
                this.state.ABRIR_FICHA_RMI_AJUSTES &&
                <RMIAjustes
                  ABRIR_FICHA={this.state.ABRIR_FICHA_RMI_AJUSTES}
                  CERRAR={this}
                  TIPO_FICHA={"MASTER"}
                  title={traducirPagina("titulo_ajustes").toUpperCase()}
                  ID_VEHICULO={ID_VEHICULO_MOTORIZACION}
                >
                </RMIAjustes>
              }

              {
                this.state.ABRIR_FICHA_RMI_MANTENIMIENTOS &&
                <RMIMantenimientos
                  ABRIR_FICHA={this.state.ABRIR_FICHA_RMI_MANTENIMIENTOS}
                  CERRAR={this}
                  TIPO_FICHA={"MASTER"}
                  title={traducirPagina("Mantenimientos").toUpperCase()}
                  ID_VEHICULO={ID_VEHICULO_MOTORIZACION}
                ></RMIMantenimientos>
              }

              {
                this.state.ABRIR_FICHA_RMI_TIEMPOS &&
                <RMITiempos
                  ABRIR_FICHA={this.state.ABRIR_FICHA_RMI_TIEMPOS}
                  CERRAR={this}
                  TIPO_FICHA={"MASTER"}
                  title={traducirPagina("Tiempos_de_reparacion").toUpperCase()}
                  ID_VEHICULO={ID_VEHICULO_MOTORIZACION}
                >
                </RMITiempos>
              }



              {
                this.state.ABRIR_FICHA_RMI_DATA &&
                <RMIDATA
                  ABRIR_FICHA={this.state.ABRIR_FICHA_RMI_DATA}
                  CERRAR={this}
                  TIPO_FICHA={"MASTER"}
                  title={"NEXT DATA"}
                  ID_VEHICULO={ID_VEHICULO_MOTORIZACION}
                >

                </RMIDATA>
              }

              {
                this.state.ABRIR_FICHA_PRESUPUESTO_FLOTA ?
                  <PresupuestoFlota
                    title={traducirPagina("txt_presupuesto_flota").toUpperCase()}
                    ABRIR_FICHA={this.state.ABRIR_FICHA_PRESUPUESTO_FLOTA}
                    CERRAR_FICHA={() => {
                      this.setState({ ABRIR_FICHA_PRESUPUESTO_FLOTA: false })

                    }}

                  >

                  </PresupuestoFlota>
                  :
                  null
              }

              {
                this.state.ABRIR_FICHA_PRESUPUESTO_FLOTA_TALLER ?
                  <PresupuestoFlotaTaller
                    title={traducirPagina("txt_presupuesto_flota_taller").toUpperCase()}
                    ABRIR_FICHA={this.state.ABRIR_FICHA_PRESUPUESTO_FLOTA_TALLER}
                    CERRAR_FICHA={() => {
                      this.setState({ ABRIR_FICHA_PRESUPUESTO_FLOTA_TALLER: false })
                    }}
                  >
                  </PresupuestoFlotaTaller>
                  :
                  null
              }




              <Modal
                visible={this.state.abrirModalVehiculo}
                style={{ zIndex: "999999999999999" }}
                width={700}
                centered
                title={traducirPagina("Informacion_matricula_DGT")}
                onOk={() => store.dispatch({ type: "STATE_MODAL_VEHICULO", abrirModalVehiculo: true })}
                onCancel={() => store.dispatch({ type: "STATE_MODAL_VEHICULO", abrirModalVehiculo: false })}
                footer={[

                  <Button className="btnColor" key="submit" type="primary" onClick={() => { store.dispatch({ type: "STATE_MODAL_VEHICULO", abrirModalVehiculo: false }) }}>{traducirPagina("Salir")} </Button>
                ]}>

                {Object.keys(this.state.vehiculoPorMatricula).length > 0 ?
                  this.state.vehiculoPorMatricula.MATRICULA !== null ?
                    <Row style={{ color: '#000', fontSize: '16px', fontWeight: '500' }}>
                      <Col md={24} className="mb-2">
                        <h4 style={{ color: '#4285f4' }}>{traducirPagina("msgDGT_no_info_bastidor")}</h4>
                      </Col>

                      <Col md={12}>
                        <span>{traducirPagina("Bastidor") + ": " + this.state.vehiculoPorMatricula.MATRICULA.Bastidor}</span>
                      </Col>

                      <Col md={12}>
                        <span>{traducirPagina("Carburante") + ": " + this.state.vehiculoPorMatricula.MATRICULA.Carburante}</span>
                      </Col>

                      <Col md={12}>
                        <span>{traducirPagina("Fecha_de_matriculacion") + ": " + this.state.vehiculoPorMatricula.MATRICULA["Fecha Matricula"]}</span>
                      </Col>

                      <Col md={12}>
                        <span>{traducirPagina("Marca") + ": " + this.state.vehiculoPorMatricula.MATRICULA.Marca}</span>
                      </Col>

                      <Col md={12}>
                        <span>{traducirPagina("Matricula") + ": " + this.state.vehiculoPorMatricula.MATRICULA.Matricula}</span>
                      </Col>

                      <Col md={12}>
                        <span>{traducirPagina("Modelo") + ": " + this.state.vehiculoPorMatricula.MATRICULA.Modelo}</span>
                      </Col>

                    </Row>
                    : null
                  : null
                }
              </Modal>

              <Modal
                visible={this.state.ABRIR_MODAL_GT}
                style={{ zIndex: "9999" }}
                width={700}
                centered
                title={"AVISO DE COBRO DE PRESUPUESTO EN LA PLATAFORMA GT ESTIMATE"}
                onCancel={() => this.setState({ ABRIR_MODAL_GT: false })}
                footer={[
                  <Button key="back" onClick={() => { this.setState({ ABRIR_MODAL_GT: false }) }}>
                    Salir
                  </Button>,
                  <Button style={{ backgroundColor: '#000' }} key="submit" type="primary" onClick={() => {

                    store.dispatch(DrawerFichaGTEstimate(true))
                    this.setState({ ABRIR_MODAL_GT: false })

                  }}> Continuar</Button>
                ]}>

                <Row style={{ color: '#000', fontSize: '18px', fontWeight: '500' }}>
                  <Col md={24} className="mb-2">
                    <span style={{ color: '#fd1a1a' }}>{"A partir de este momento la generación de un presupuesto conllevará gastos por parte del cliente"}</span>
                  </Col>
                </Row>
              </Modal>


              <Modal
                visible={this.state.ABRIR_MODAL_OEM}
                style={{ zIndex: "9999" }}
                width={700}
                centered
                title={"AVISO CATÁLOGO ORIGINAL"}
                onCancel={() => this.setState({ ABRIR_MODAL_OEM: false, ABRIR_FICHA_YQ: true, LIMPIAR_COCHE_ANTERIOR_OEM: false })}
                footer={[
                  <Button key="back" onClick={() => { this.setState({ ABRIR_MODAL_OEM: false, ABRIR_FICHA_YQ: true, LIMPIAR_COCHE_ANTERIOR_OEM: false }) }}>
                    NO
                  </Button>,
                  <Button style={{ backgroundColor: '#000' }} key="submit" type="primary" onClick={() => {

                    this.setState({ ABRIR_MODAL_OEM: false, ABRIR_FICHA_YQ: true, LIMPIAR_COCHE_ANTERIOR_OEM: true })
                  }}> SI</Button>
                ]}>

                <Row style={{ color: '#000', fontSize: '18px', fontWeight: '500' }}>
                  <Col md={24} className="mb-2">
                    <span >{"Desea actualizar el catálogo original con el vehículo seleccionado?"}</span>
                  </Col>
                </Row>
              </Modal>




              {
                this.state.ABRIR_FICHA_DOC && (
                  <VisorDoc
                    ABRIR_FICHA={this.state.ABRIR_FICHA_DOC}
                    CERRAR={this}
                    TIPO_FICHA={"MASTER"}
                    URL={this.state.URL_DOCUMENTO}

                  >

                  </VisorDoc>
                )
              }

              {
                this.state.ABRIR_FICHA_CESTA_SERTEC && (
                  <>
                    <CestaCompra
                      title={traducirPagina("Cesta_de_la_compra").toUpperCase()}
                      ABRIR_FICHA={this.state.ABRIR_FICHA_CESTA_SERTEC}
                      CERRAR_FICHA={() => {
                        this.setState({ ABRIR_FICHA_CESTA_SERTEC: false })

                      }}
                    />

                    {/*
                  <CestaSertec
                    title={traducirPagina("Cesta_de_la_compra").toUpperCase()}
                    ABRIR_FICHA={this.state.ABRIR_FICHA_CESTA_SERTEC}
                    CERRAR_FICHA={() => {
                      this.setState({ ABRIR_FICHA_CESTA_SERTEC: false })

                    }}
                  />
                   */}
                  </>
                )

              }


              <link rel="stylesheet" type="text/css" href={"plantillas/" + this.state.plantillaCss} />

            </div>
            :
            <div>
              <link rel="stylesheet" type="text/css" href={"plantillas/preloader.css"} />

              <div className="group" style={{ display: this.state.VerPreloader ? '' : 'none' }}>
                <div className="bigSqr">
                  <div className="square first"></div>
                  <div className="square second"></div>
                  <div className="square third"></div>
                  <div className="square fourth"></div>
                </div>
                <div className="text">{traducirPagina("msgCargando_sertec")}</div>
              </div>

            </div>
          }

        </div>

        :
        <div></div>
    );
  }
}

export default MasterPage;


