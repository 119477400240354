import { Alert, Drawer } from "antd";
import React from "react";
import { MDBBtn } from "mdbreact";

import { CloseOutlined, CloudUploadOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { MSGERROR, generateUUID, traducirPagina, verificarExtension, descargarDocPasandoBase64Generico } from "../../librerias/funciones";
import { PlantillaCargaMasivaEnAPI, ValidarEImportarCargaMasivaEnAPI } from "../../action/actionCreatorsRequests";

const FichaMasivaCliente = ({ ABRIR_FICHA, CERRAR_FICHA, BUSCAR_CLIENTES }) => {
    const [bloqueoPantalla, setBloqueoPantalla] = React.useState({ LOADER: false, MENSAJE: "" })
    const [registroImportacion, setRegistroImportacion] = React.useState({});
    const [payLoadLocal, setPayLoadLocal] = React.useState({
        ID_PROVEEDOR: 0,
        ID_MARCA: 0,
        FECHA_VIGENCIA: "",
        ES_IMPORTE_PVP: true,
        DOCUMENTO_CSV: [],
        VALIDAR_TARIFA: false,
        NOMBRE_DOC: ""
    });

    const descargarPlantilla = () => {
        PlantillaCargaMasivaEnAPI().then(doc => {
            if (doc.CSV !== "" && doc.CSV !== null) {
                descargarDocPasandoBase64Generico(doc.CSV, "csv")
            }
        })
    }


    const eliminarFichero = (fichero) => {
        setRegistroImportacion({})

        let registros = payLoadLocal.DOCUMENTO_CSV.filter((registro) => registro.ID !== fichero.ID);
        payLoadLocal.DOCUMENTO_CSV = [...registros];
        setPayLoadLocal({ ...payLoadLocal })
    }




    const gestionarDocLocal = (input) => {
        const url = input.value;
        const ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
        if (input.files && input.files[0]) {

            if (ext === "csv" || ext === "CSV") {
                const reader = new FileReader();
                reader.onload = (e) => {

                    const registro =
                    {
                        ID: generateUUID(),
                        PROPIEDADES_FICHERO: verificarExtension(ext),

                        NOMBRE: input.files[0].name,
                        EXTENSION: ext,
                        DOCUMENTOS_BYTES: e.target.result.replace('data:', '').replace(/^.+,/, ''),
                        PESO: input.files[0].size,
                    }

                    payLoadLocal.DOCUMENTO_CSV = [...payLoadLocal.DOCUMENTO_CSV, registro];
                    setPayLoadLocal({ ...payLoadLocal })
                    input.value = '';
                }
                reader.readAsDataURL(input.files[0]);

            } else {
                input.value = '';
            }

        } else {
            input.value = '';
        }
    }




    const importarDocumento = () => {
        setRegistroImportacion({})

        if (payLoadLocal.DOCUMENTO_CSV.length > 0) {
            bloqueoPantalla.LOADER = true; bloqueoPantalla.MENSAJE = `${traducirPagina("txtEspera")} ...`; setBloqueoPantalla({ ...bloqueoPantalla })
            const { DOCUMENTO_CSV } = payLoadLocal

            ValidarEImportarCargaMasivaEnAPI(DOCUMENTO_CSV[0].DOCUMENTOS_BYTES).then(usuario => {
                bloqueoPantalla.LOADER = false; bloqueoPantalla.MENSAJE = ""; setBloqueoPantalla({ ...bloqueoPantalla })
                setRegistroImportacion(usuario)
            })

        } else {
            MSGERROR(traducirPagina("Seleccione_fichero"));
        }
    }


    return (
        <Drawer
            width={600}
            onClose={() => {
                if (!bloqueoPantalla.LOADER) {
                    CERRAR_FICHA(false)
                    BUSCAR_CLIENTES();
                }
            }}
            visible={ABRIR_FICHA}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            closable={false}
            style={{ zIndex: '9999999999', color: '#fff' }}>


            <div className="flex-container-Grow-Tercero" >
                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                        if (!bloqueoPantalla.LOADER) {
                            CERRAR_FICHA(false);
                            BUSCAR_CLIENTES();
                        }
                    }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {traducirPagina("txt_importacion_masiva").toUpperCase()}
                </div>
            </div>



            <div className="body_masiva">

                <div className="containerMasiva">
                    <div>
                        <div className='tituloCatalogoNeumatico'>
                            <span>{`1. ${traducirPagina("label_PlantillaImportacion")}`} </span>
                        </div>

                        <div className='bodyCatalogoNeumatico'>
                            <div class="ver_mas_info" style={{ marginTop: '10px' }} onClick={() => descargarPlantilla()}><span>{traducirPagina("label_DescargaPlantilla")}</span></div>
                        </div>
                    </div>



                    <div className="pt-3">
                        <div className='tituloCatalogoNeumatico'>
                            <span>{`2. ${traducirPagina("label_ImportacionDatos")}`} </span>
                        </div>

                        <div className='bodyCatalogoNeumatico' style={{ marginBottom: '20px' }}>
                            <span style={{ fontSize: '14px', fontWeight: 'normal', color: '#999', display: 'block' }}>{traducirPagina("txt_ImportacionDatos")}</span>
                        </div>

                        <div className='fichero_tarifa_proveedor'>
                            <div className="containerFicheros" style={{ margin: '0px 0px 10px 1px' }}>
                                {
                                    payLoadLocal.DOCUMENTO_CSV !== undefined && payLoadLocal.DOCUMENTO_CSV.length > 0 ?
                                        payLoadLocal.DOCUMENTO_CSV.map((fichero, i) =>
                                            <div key={i} className="containerFicheros_card" title={fichero.NOMBRE}  >
                                                <div className='containerFicheros_card_body' style={{ height: '142px', width: '142px' }}>

                                                    <img src={`./imagenes/iconos/csv.png`} alt={fichero.NOMBRE} style={{ height: '80%' }} />

                                                    <div className="image-mask" style={{ height: '142px' }}>
                                                        <div className="image-mask-info" >
                                                            <label><DeleteOutlined onClick={() => { eliminarFichero(fichero) }} style={{ fontSize: '25px', display: 'block' }}></DeleteOutlined> {"Eliminar"}</label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        ) :
                                        null
                                }

                                {
                                    payLoadLocal.DOCUMENTO_CSV !== undefined && payLoadLocal.DOCUMENTO_CSV.length === 0 ?
                                        <>
                                            <label id="getFileLabel" htmlFor="getFile" style={{ fontSize: '18px', height: '140px', width: '140px', marginTop: '0px' }}><PlusOutlined style={{ fontSize: '25px' }}></PlusOutlined> {traducirPagina("txt_Documento")}</label>
                                            <input type="file" id="getFile" accept=".csv" onChange={(e) => { gestionarDocLocal(e.target) }} />
                                        </>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>



                    <div className="pt-3">
                        {
                            Object.keys(registroImportacion).length > 0 && (
                                <>
                                    <div className='tituloCatalogoNeumatico'>
                                        <span>{`3. ${traducirPagina("txt_resultado_importacion")}`} </span>
                                    </div>

                                    <div style={{ marginTop: '20px' }}>
                                        {
                                            registroImportacion.OK ?
                                                <Alert
                                                    message={`${traducirPagina("txt_guardar_clientes")}`}
                                                    type="success"
                                                />
                                                :
                                                registroImportacion.HTML_ERRORES !== undefined && registroImportacion.HTML_ERRORES !== null && registroImportacion.HTML_ERRORES !== "" ?
                                                    <div className="log_masiva">
                                                        <div dangerouslySetInnerHTML={{ __html: registroImportacion.HTML_ERRORES }} />
                                                    </div>
                                                    :
                                                    <Alert
                                                        message={`${registroImportacion.MENSAJE_ERROR}`}
                                                        type="error"
                                                    />
                                        }
                                    </div>

                                </>
                            )
                        }

                    </div>

                </div>


                <div className="footerMasiva" style={{ justifyContent: Object.keys(registroImportacion).length === 0 ?  'space-between' :'end'  }}>
                    <div>
                        <button className="activebtnMenu2" style={{ padding: '0px 20px 0px 20px', height: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', cursor: 'pointer', margin: '0 0 0 auto' }} onClick={(e) => {
                            if (!bloqueoPantalla.LOADER) {

                                CERRAR_FICHA(false)
                                BUSCAR_CLIENTES()
                            }
                        }}><CloseOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Salir").toUpperCase()} </span>  </button>
                    </div>


                    {
                        Object.keys(registroImportacion).length === 0 && (
                            <div>
                                <button style={{ padding: '0px 20px 0px 20px', height: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#fff', cursor: 'pointer', margin: '0 0 0 auto', background: '#4285f4' }} onClick={(e) => importarDocumento()}><CloudUploadOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("txt_Importar").toUpperCase()} </span>  </button>
                            </div>
                        )
                    }


                </div>
            </div>

            {
                bloqueoPantalla.LOADER ?
                    <div className="pantallaBloqueo">
                        <div className="image-mask-info" style={{ fontSize: '18px' }}>

                            <div dangerouslySetInnerHTML={{ __html: bloqueoPantalla.MENSAJE }}>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }

        </Drawer>


    )
}

export default FichaMasivaCliente;