import React from "react";
import { Select } from "antd";
import { traducirPagina } from "../../librerias/funciones";
const { Option } = Select;


const CPSelect = ({ registers = [], value = undefined, allowClear = true, placeholder = `${traducirPagina("Todos")}`, onChange = () => { }, esListaObjeto = false }) => {


    return (
        <Select
            showSearch
            allowClear={allowClear}
            placeholder={placeholder}
            style={{ width: "100%" }}

            optionFilterProp="children"
            value={value}
            onChange={(e) => {
                onChange(e)
            }}
            filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                registers !== null && (
                    esListaObjeto ?
                        registers.map((item, i) => <Option value={item.ID_SELECT} key={i}>{item.DESCRIPCION_SELECT}</Option>)

                        :
                        registers.map((item, i) => <Option value={item} key={i}>{item}</Option>)
                )
            }
        </Select>
    )
}

export default CPSelect;