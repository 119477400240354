import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from 'mdbreact';
import 'antd/dist/antd.css';
import { BusquedaUniversalEnAPI, getFabricantesEnAPI, getModelosTurismosEnAPI, getMotorizacionesTurismosEnAPI, buscarMatriculaCoche, GetSmartPackEnAPI, GetDetalleSmartPackEnAPI } from "../action/actionCreatorsRequests";

import {
    traducirPagina, MSGOK, buscarVehiculoLocal
} from "../librerias/funciones"

import FichaVehiculoGenerico from './FichaVehiculoGenerico'

import {
    CalendarOutlined,
} from '@ant-design/icons';
import { Tabs, Select, Row, Col, Input, Collapse, Modal, Button, Drawer, Layout, Tree, Card } from 'antd';
import VisorBoletines from './VisorBoletines';


const { Content } = Layout;
const { Option } = Select;
const { DirectoryTree } = Tree;
const { Search } = Input;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Meta } = Card;


const FichaBoletines = ({ props, configApp, HISTORIAL_VEHICULO }) => {
    const [hayReferencias, setHayReferencias] = React.useState(false);
    const [hayLicencias, seHayLicencias] = React.useState(false);
    const [txtMatricula, setTxtMatricula] = React.useState("");
    const [marcas, setMarcas] = React.useState([]);
    const [modelos, setModelos] = React.useState([]);
    const [motores, setMotores] = React.useState([]);

    const [smartPack, setSmartPack] = React.useState([]);
    const [detalle, setObtenerDetalle] = React.useState([]);
    const [colapseSeleccionado, setColapseSeleccionado] = React.useState(0);
    const [tabPorDefecto, setTabPorDefecto] = React.useState("1");
    const [fichaVehiculo, setFichaVehiculo] = React.useState(false);
    const [infoDGT, setInfoDGT] = React.useState(false);

    const [datosCoche, setDatosCoche] = React.useState({});
    const [cocheSeleccionado, setCocheSeleccionado] = React.useState({
        MARCA: 0,
        MODELO: 0,
        MOTOR: 0
    });
    const [tienePermiso, setTienePermiso] = React.useState(false);
    const [abrirVisor, setabrirVisor] = React.useState(false);
    const [documentoSeleccionado, setDocumentoSeleccionado] = React.useState({});




    const buscarFabricanteAPI = (fabricante) => {
        getFabricantesEnAPI(fabricante).then((fabricantes) => {
            if (fabricantes !== undefined) {
                if (fabricantes !== null && fabricantes.length > 0) {
                    setMarcas(fabricantes)

                }
            } else {
                setMarcas(null)
            }
        })
    }


    const buscarModelosAPI = (fabricante) => {
        getModelosTurismosEnAPI(fabricante).then((modelo) => {
            if (modelo !== undefined) {
                if (modelo !== null && modelo.length > 0) {
                    setModelos(modelo)
                }
            } else {
                setModelos(null)
            }
        })
    }


    const buscarMotorizacionAPI = (modelo) => {
        getMotorizacionesTurismosEnAPI(modelo).then((motor) => {
            if (motor !== undefined) {
                if (motor !== null && motor.length > 0) {
                    setMotores(motor)
                }
            } else {
                setMotores(null)
            }
        })
    }

    const limpiarCoche = () => {
        setModelos([]);
        setMotores([]);
        setSmartPack([]);
        cocheSeleccionado.MARCA = 0;
        cocheSeleccionado.MODELO = 0;
        cocheSeleccionado.MOTOR = 0;
        setCocheSeleccionado({ ...cocheSeleccionado })
    }

    const buscarMatricula = () => {

        limpiarCoche()
        //5497BNL
        if (txtMatricula !== undefined ? txtMatricula.trim().length > 0 : false) {
            buscarMatriculaCoche(txtMatricula).then(coche => {
                if (coche !== undefined) {
                    if (coche !== null && coche.OK) {
                        setDatosCoche(coche)
                        if (coche.VEHICULOS_TECDOC.length === 0 && coche.MATRICULA !== null) {
                            setInfoDGT(true)

                        } else if (coche.VEHICULOS_TECDOC.length === 1) {
                            vehiculoSeleccionado(coche.VEHICULOS_TECDOC[0])

                        } else {
                            setFichaVehiculo(true)
                        }
                    }
                }
            })
        }
    }

    const obtenerSmartPack = (motor) => {
        setSmartPack([])
        setColapseSeleccionado(0)
        GetSmartPackEnAPI(motor).then(registro => {
            if (registro !== undefined) {
                if (registro.SMART_PACK !== null) {
                    setSmartPack(registro.SMART_PACK)
                } else {
                    setSmartPack(null)
                }
            }
        })
    }


    const obtenerDetalles = (ID_SISTEMA, TIPO) => {
        if (ID_SISTEMA !== undefined) {
            setObtenerDetalle([])
            GetDetalleSmartPackEnAPI(ID_SISTEMA, TIPO).then((detalle) => {
                if (detalle !== undefined) {
                    if (detalle.DETALLE !== null && detalle.DETALLE.length > 0) {
                        setObtenerDetalle(detalle.DETALLE)
                    } else {
                        setObtenerDetalle(null)
                    }
                }
            })
        }
    }


    const vehiculoSeleccionado = async (coche) => {
        let MOTOR_LOCAL = parseInt(coche.ID);

        let modelo = await getModelosTurismosEnAPI(coche.ID_MARCA)
        if (modelo !== undefined) {
            if (modelo !== null && modelo.length > 0) {
                setModelos(modelo)
            }
        } else {
            setModelos(null)
        }

        let motor = await getMotorizacionesTurismosEnAPI(coche.ID_MODELO)
        if (motor !== undefined) {
            if (motor !== null && motor.length > 0) {
                setMotores(motor)
            }
        } else {
            setMotores(null)
        }

        setFichaVehiculo(false);

        cocheSeleccionado.MARCA = coche.ID_MARCA;
        cocheSeleccionado.MODELO = coche.ID_MODELO;
        cocheSeleccionado.MOTOR = MOTOR_LOCAL;

        obtenerSmartPack(MOTOR_LOCAL)
        setCocheSeleccionado({ ...cocheSeleccionado })
    }

    const obtenerVehiculo = async () => {
        let VEHICULO = await buscarVehiculoLocal();
        VEHICULO.HISTORIAL_VEHICULO = HISTORIAL_VEHICULO

        const { MATRICULA, BASTIDOR, VEHICULO_TECDOC } = VEHICULO;
        if(Object.keys(VEHICULO_TECDOC).length > 0){
            if (VEHICULO.HISTORIAL_VEHICULO.length === 3) {
                let ID_MARCA = VEHICULO.HISTORIAL_VEHICULO[0].id
                let ID_MODELO = VEHICULO.HISTORIAL_VEHICULO[1].id
                let ID_MOTOR = VEHICULO.HISTORIAL_VEHICULO[2].id
    
                VEHICULO_TECDOC.ID_MARCA = ID_MARCA
                VEHICULO_TECDOC.ID_MODELO = ID_MODELO

                setTxtMatricula(MATRICULA);
                vehiculoSeleccionado(VEHICULO_TECDOC);
            }
        }
    }

    if (!hayReferencias && props.ABRIR_FICHA) {
        setHayReferencias(true);
        buscarFabricanteAPI(1)


        obtenerVehiculo();

    } else {
        if (!props.ABRIR_FICHA && hayReferencias) {
            setHayReferencias(false);
        }
    }


    return (
        <div>
            <Drawer
                width={"95%"}
                onClose={() => {
                    if (props.TIPO === "MASTER") {
                        props.CERRAR.setState({ ABRIR_FICHA_BOLETINES: false })
                    } else {
                        props.CERRAR_FICHA(false)
                    }
                }}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                closable={false}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>

                <div className="flex-container-Grow-Tercero">
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                            if (props.TIPO === "MASTER") {
                                props.CERRAR.setState({ ABRIR_FICHA_BOLETINES: false })
                            } else {
                                props.CERRAR_FICHA(false)
                            }
                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>
                <>
                    <div style={{ margin: '10px' }}>

                        <div className="contenedorBuscadores">
                            <div>
                                <span className="tituloTxtBoletiones">{traducirPagina("Matricula")} </span>
                                <Search
                                    placeholder={traducirPagina("Ingrese_una_matrícula")}
                                    allowClear
                                    size="large"
                                    enterButton
                                    value={txtMatricula}
                                    onChange={(e) => { setTxtMatricula(e.target.value) }}
                                    onSearch={(e) => {
                                        if (e === "") {
                                            limpiarCoche()
                                        } else {
                                            buscarMatricula(e);
                                        }

                                    }}
                                />
                            </div>

                            <div>
                                <span className="tituloTxtBoletiones">{traducirPagina("Marca")}</span>
                                {marcas !== undefined && marcas !== null && marcas.length > 0 ?
                                    <Select
                                        showSearch
                                        style={{ width: "100%", textAlign: 'left' }}
                                        allowClear={true}
                                        value={cocheSeleccionado.MARCA === 0 ? <span style={{ color: '#bfbfbf' }}>{traducirPagina("Seleccione_una_marca")}</span> : cocheSeleccionado.MARCA}
                                        optionFilterProp="children"
                                        size="large"
                                        onChange={(e) => {
                                            setModelos([]);
                                            setMotores([]);
                                            setSmartPack([]);
                                            setTxtMatricula("")

                                            if (e === undefined) {
                                                cocheSeleccionado.MARCA = 0;
                                                cocheSeleccionado.MODELO = 0;
                                                cocheSeleccionado.MOTOR = 0;
                                                setCocheSeleccionado({ ...cocheSeleccionado })

                                            } else {
                                                buscarModelosAPI(e)

                                                cocheSeleccionado.MARCA = e;
                                                cocheSeleccionado.MODELO = 0;
                                                cocheSeleccionado.MOTOR = 0;
                                                setCocheSeleccionado({ ...cocheSeleccionado })
                                            }
                                        }}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }>
                                        {marcas.map(marca =>
                                            <Option key={marca.ManNo} value={marca.ManNo}>{marca.Term}</Option>
                                        )
                                        }
                                    </Select>
                                    : null
                                }

                            </div>
                            <div>
                                <span className="tituloTxtBoletiones">{traducirPagina("Modelo")}</span>
                                <Select
                                    showSearch
                                    disabled={modelos.length > 0 ? false : true}
                                    style={{ width: "100%", textAlign: 'left' }}
                                    value={cocheSeleccionado.MODELO === 0 ? <span style={{ color: '#bfbfbf' }}>{traducirPagina("Seleccione_un_modelo")}</span> : cocheSeleccionado.MODELO}


                                    optionFilterProp="children"

                                    size="large"
                                    onChange={(e) => {
                                        if (e === undefined) {
                                            cocheSeleccionado.MODELO = 0;
                                            setCocheSeleccionado({ ...cocheSeleccionado })
                                        } else {
                                            setTxtMatricula("")
                                            setSmartPack([])
                                            buscarMotorizacionAPI(e)
                                            cocheSeleccionado.MOTOR = 0;
                                            cocheSeleccionado.MODELO = e;
                                            setCocheSeleccionado({ ...cocheSeleccionado })
                                        }
                                    }}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {modelos !== undefined && modelos !== null && modelos.length > 0 ?
                                        modelos.map(modelo =>
                                            <Option key={modelo.ID} value={modelo.ID}>{modelo.DESCRIPCION}</Option>
                                        )
                                        :
                                        null
                                    }
                                </Select>
                            </div>
                            <div>
                                <span className="tituloTxtBoletiones"> {traducirPagina("Motor")}</span>
                                <Select
                                    showSearch
                                    disabled={motores.length > 0 ? false : true}
                                    style={{ width: "100%", textAlign: 'left' }}
                                    value={cocheSeleccionado.MOTOR === 0 ? <span style={{ color: '#bfbfbf' }}>{traducirPagina("Seleccione_cod_motor")}</span> : cocheSeleccionado.MOTOR}

                                    optionFilterProp="children"

                                    size="large"
                                    onChange={(e) => {
                                        if (e === undefined) {
                                            cocheSeleccionado.MOTOR = 0;
                                            setCocheSeleccionado({ ...cocheSeleccionado })
                                        } else {
                                            setTxtMatricula("")
                                            obtenerSmartPack(e)

                                            cocheSeleccionado.MOTOR = e;
                                            setCocheSeleccionado({ ...cocheSeleccionado })
                                        }
                                    }
                                    }
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>

                                    {motores !== undefined && motores !== null && motores.length > 0 ?
                                        motores.map(motor =>
                                            <Option key={motor.ID} value={motor.ID}>{motor.DESCRIPCION}</Option>
                                        )
                                        :
                                        null
                                    }
                                </Select>
                            </div>
                        </div>

                    </div>

                    {smartPack !== null ?
                        smartPack.length > 0 ?
                            <Tabs style={{ margin: '0px 10px 10px 10px' }} >
                                {smartPack.map((registro, index) =>
                                    <TabPane tab={<span className="tituloTxtBoletiones"> {registro.Descripcion}</span>} key={(index + 1)} >
                                        <div style={{ height: configApp.width <= 900 ? (configApp.height - 440).toString() + "px" : (configApp.height - 240).toString() + "px", overflowY: 'auto', overflowX: 'hidden' }}>
                                            <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000', }}>
                                                <Col md={24} className="conatinerTiempos">
                                                    {registro.SISTEMAS === null ?
                                                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                                            <h4>{"No se han encontrado resultados."}</h4>
                                                        </div>
                                                        :
                                                        registro.SISTEMAS.length > 0 ?
                                                            <Collapse accordion onChange={(e, isActive) => { setColapseSeleccionado(e); obtenerDetalles(e, registro.Categoria) }}
                                                                className="site-collapse-custom-collapse"
                                                                bordered={false} activeKey={[colapseSeleccionado]}>

                                                                {registro.SISTEMAS.map((subnodo, indice) =>
                                                                    <Panel
                                                                        className="site-collapse-custom-panel"
                                                                        header={subnodo.Fecha !== null ?
                                                                            <div className="contenedorBoletin">
                                                                                <div className="fuenteTituloTexto"> {subnodo.Descripcion}</div>
                                                                                <div>
                                                                                    <span className="fechaBoletin"> <CalendarOutlined style={{ fontSize: '20px', verticalAlign: 'text-bottom' }} /> {subnodo.Fecha} </span>
                                                                                    <span className="fuenteSubtituloTexto">{subnodo.CodigoOE} </span>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div> <span className="fuenteTituloTexto"> {subnodo.Descripcion} </span> </div>
                                                                        }
                                                                        key={subnodo.Id}

                                                                    >
                                                                        {subnodo === null ?
                                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '20vh' }}> <h3>{traducirPagina("No_lubricantes")}</h3> </div>
                                                                            :
                                                                            detalle !== null ?
                                                                                detalle.length === 0 ?
                                                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '20vh' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                                                                    :
                                                                                    <ul style={{ paddingLeft: '8px' }}>
                                                                                        {detalle.map((tarea, indice) =>
                                                                                            <li key={indice} style={{ listStyle: 'none' }}>
                                                                                                <div className={"site-collapse-custom-tareas"}  >
                                                                                                    <Row gutter={[8, 8]} style={detalle.length > 1 ? { borderBottom: (detalle.length - 1) === indice ? "" : "1px #dcdcdc solid" } : {}}>
                                                                                                        <Col md={24}>
                                                                                                            <Row gutter={[8, 8]} >
                                                                                                                <Col md={24}>
                                                                                                                    <div style={(detalle.length - 1) === indice ? { padding: '10px', backgroundColor: '#ffff90' } : { padding: '10px' }}>
                                                                                                                        <div className="fuenteTituloTexto">{tarea.Descripcion} </div>

                                                                                                                        {tarea.Boletines.map((boletin, i) =>
                                                                                                                            <>
                                                                                                                                <div className="descripcionItem">{`${boletin.Texto}  ${boletin.Comentario !== null ? boletin.Comentario : ``}`} </div>

                                                                                                                                {boletin.CodigoEOBD !== null ?
                                                                                                                                    <div className="descripcionItem">{boletin.CodigoEOBD + " " + boletin.Comentario} </div>
                                                                                                                                    : null}
                                                                                                                                {boletin.Imagen !== null ?
                                                                                                                                    <div className="contenedorImagen" >
                                                                                                                                        <img alt="Imagen Haynes" src={boletin.Imagen} onClick={() => { setabrirVisor(true); setDocumentoSeleccionado({ TITULO: boletin.Texto, URL: boletin.Imagen }) }} ></img>
                                                                                                                                    </div>
                                                                                                                                    :
                                                                                                                                    null
                                                                                                                                }
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </Col>

                                                                                                            </Row>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </div>
                                                                                            </li>
                                                                                        )}
                                                                                    </ul>
                                                                                :
                                                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '20vh' }}> <h3>{traducirPagina("No_resultados")}</h3> </div>
                                                                        }
                                                                    </Panel>
                                                                )
                                                                }
                                                            </Collapse>
                                                            :
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                    </TabPane>
                                )}
                            </Tabs>
                            :
                            motores.length === 0 ?
                                null
                                :
                                cocheSeleccionado.MOTOR === 0 ?
                                    null
                                    :
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh', color: '#000' }}> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                        :
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <h4>{traducirPagina("No_resultados")}</h4>
                        </div>
                    }



                    {
                        abrirVisor ?
                            <VisorBoletines
                                title={""}
                                ABRIR_VISOR={abrirVisor}
                                CERRAR_VISOR={setabrirVisor}
                                TIPO_DOCUMENTO={"IMG"}
                                PROPIEDADES_DOC={documentoSeleccionado}
                                ZOOM_DOCUMENTO={false}
                            ></VisorBoletines>
                            :
                            null
                    }
                </>
            </Drawer>





            {fichaVehiculo ?
                <FichaVehiculoGenerico
                    title={traducirPagina("Seleccione_un_vehiculo").toUpperCase()}
                    ABRIR_FICHA={fichaVehiculo}
                    CERRAR_FICHA={setFichaVehiculo}
                    DATOS_VEHICULO={datosCoche}
                    MODIFICAR_COCHE={vehiculoSeleccionado}
                >

                </FichaVehiculoGenerico>
                :

                null}



            <Modal
                visible={infoDGT}
                style={{ zIndex: "999" }}
                width={700}
                centered
                title={traducirPagina("Informacion_matricula_DGT")}
                onOk={() => { setInfoDGT(false) }}
                onCancel={() => { setInfoDGT(false) }}
                footer={[
                    <Button className="btnColor" key="submit" type="primary" onClick={() => { setInfoDGT(false) }}>{traducirPagina("Salir")} </Button>
                ]}>

                {Object.keys(datosCoche).length > 0 ?
                    datosCoche.MATRICULA !== null ?

                        <Row style={{ color: '#000', fontSize: '16px', fontWeight: '500' }}>

                            <Col md={24} className="mb-2">
                                <h4 style={{ color: '#4285f4' }}>{traducirPagina("msgDGT_no_info_bastidor")}</h4>
                            </Col>

                            <Col md={12}>
                                <span>{traducirPagina("Bastidor") + ": " + datosCoche.MATRICULA.Bastidor}</span>
                            </Col>

                            <Col md={12}>
                                <span>{traducirPagina("Carburante") + ": " + datosCoche.MATRICULA.Carburante}</span>
                            </Col>

                            <Col md={12}>
                                <span>{traducirPagina("Fecha_de_matriculacion") + ": " + datosCoche.MATRICULA["Fecha Matricula"]}</span>
                            </Col>

                            <Col md={12}>
                                <span>{traducirPagina("Marca") + ": " + datosCoche.MATRICULA.Marca} </span>
                            </Col>

                            <Col md={12}>
                                <span>{traducirPagina("Matricula") + ": " + datosCoche.MATRICULA.Matricula}</span>
                            </Col>

                            <Col md={12}>
                                <span>{traducirPagina("Modelo") + ": " + datosCoche.MATRICULA.Modelo}</span>
                            </Col>



                        </Row>
                        : null
                    : null
                }
            </Modal>



        </div>
    )
}

FichaBoletines.propTypes = {
    TIPO: PropTypes.string,
    CERRAR: PropTypes.object,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    VISCOSIDAD: PropTypes.string
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        filtrosReferencia: state.filtrosReferencia,
        usuario: state.usuario,
        codmotorizacion: state.codmotorizacion,
        clienteSeleccionado: state.clienteSeleccionado,
        listaTree: state.listaTree,
        mostrarComponente: state.mostrarComponente,
        propiedadesVisor: state.propiedadesVisor,
        HISTORIAL_VEHICULO: state.fichaBusquedaVehiculo.historicapp

    };
}

export default connect(mapStateToProps)(FichaBoletines);