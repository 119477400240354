import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from 'mdbreact';
import 'antd/dist/antd.css';
import { Drawer } from 'antd';
import { DrawerHaynesPro, createRipple, } from "../action/actionCreators";
import { loginHaynesProTouch, getIdVehicleByTecdocNumberV2 } from "../action/actionCreatorsRequests";
import store from '../store'

const FichaHaynesPro = ({ props, configApp, openDrawerHaynesPro, fichaBusquedaVehiculo, usuario }) => {
    const [enlaceHaynes, setEnlaceHaynes] = React.useState("");
    const [hayEnlce, setHayEnlce] = React.useState(false);

    if (!hayEnlce && openDrawerHaynesPro && Object.keys(usuario).length > 0) {
        setHayEnlce(true)

        let idCocheTecdoc = fichaBusquedaVehiculo.historicapp.length === 0 ? 0:fichaBusquedaVehiculo.historicapp[2].id;

        loginHaynesProTouch(idCocheTecdoc).then(haynes => {
            if (haynes.OK) {
                setEnlaceHaynes(haynes.REDIRECT_URL);
            } else {
                setEnlaceHaynes(null)
            }
        })
    } else {
        if (!openDrawerHaynesPro && hayEnlce) {
            setEnlaceHaynes("")
            setHayEnlce(false)
        }
    }

/*
    const buscarCocheHaynes = () => {
        if (!hayCoche) {
            setHayCoche(true);
            let fichaCompletaCoche = fichaBusquedaVehiculo
            if (fichaCompletaCoche.historicapp.length === 3) {

                if (document.getElementById("haynespro") !== null) {
                    document.getElementById("haynespro").src = "https://www.workshopdata.com/touch/site/layout/modelDetail?typeId=t_" + vehiculoVivid;
                }

                
                getIdVehicleByTecdocNumberV2(fichaCompletaCoche.historicapp[2].id).then((data) => {
                    if (data !== undefined) {
                        if (data.idVehicle !== null && data.idVehicle !== undefined && data.idVehicle !== "") {
                            if (document.getElementById("haynespro") !== null) {
                                document.getElementById("haynespro").src = "https://www.workshopdata.com/touch/site/layout/modelDetail?typeId=t_" + data.idVehicle;
                            }
                        }
                    }
                })

            }
        }
    }
*/



    return (
        <Drawer
            width={configApp.width - 360}
            onClose={() => { store.dispatch(DrawerHaynesPro(false)); }}
            visible={openDrawerHaynesPro}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '99999999', color: '#fff' }}>

            <div className={"estiloDrawerHeader"}>
                <div>
                    <div className="flex-container-Grow-Tercero" style={{ width: (configApp.width - 360).toString() + "px" }}>
                        <div style={{ textAlign: 'inherit' }}>
                            <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { store.dispatch(DrawerHaynesPro(false)); createRipple(e) }} >
                                <i aria-label="icon: close" className="anticon anticon-close">
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                    </svg>
                                </i>
                            </MDBBtn>
                        </div>

                        <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                            {props.title}
                        </div>
                    </div>
                </div>


                {enlaceHaynes !== null ?
                    enlaceHaynes === "" ?
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div style={{ color: 'black' }} color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                        :
                        <iframe
                            id={"haynespro"}
                            width="100%"
                            height={(configApp.height - 60).toString() + "px"}
                            title={props.title}
                            frameBorder="0"
                            allowtransparency="true"
                            src={enlaceHaynes}
                         

                        />
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>No se puede establecer conexión con HaynesPro.</h3> </div>
                }
            </div>
        </Drawer>

    )
}

FichaHaynesPro.propTypes = {
    onClick: PropTypes.func,
    config: PropTypes.object,
    detallesReferencia: PropTypes.object,
    masInformacion: PropTypes.object,
    fichaAbierta: PropTypes.bool,

    activeItemOuterTabs: PropTypes.string
};

const mapStateToProps = (state, props) => {
    return {
        masInformacion: state.masInformacion,
        configApp: state.configApp,
        props: props,
        openDrawerHaynesPro: state.estadoDrawer.openDrawerHaynesPro,
        fichaBusquedaVehiculo: state.fichaBusquedaVehiculo,
        usuario: state.usuario
    };
}

export default connect(mapStateToProps)(FichaHaynesPro);