import React from "react";
import {
    traducirPagina

} from "../../librerias/funciones"
import { Drawer } from 'antd';
import { MDBBtn } from 'mdbreact';
import { obtenerUrlVehiculoEnAPI } from "../../action/actionCreatorsRequests";

export const RMIDATA = (props) => {
    const [initComponent, setInitComponent] = React.useState({
        REGISTROS: ""
    })


    const obtenerUrlVehiculo = async (ID_VEHICULO) => {
        const registros = await obtenerUrlVehiculoEnAPI(ID_VEHICULO);
        const { OK, URL } = registros === undefined ? { OK: false, URL: null } : registros
        if (OK) {
            initComponent.REGISTROS = URL
        } else {
            initComponent.REGISTROS = null
        }
        setInitComponent({ ...initComponent });

        
    }

    React.useEffect(() => {
        const ID_VEHICULO_TECDOC =  props.ID_VEHICULO != 0 ? props.ID_VEHICULO:""
        obtenerUrlVehiculo(ID_VEHICULO_TECDOC)
    }, [])


    return <div>
        <Drawer
            width={"80%"}
            onClose={() => {
                if (props.TIPO_FICHA === "MASTER") {
                    props.CERRAR.setState({ ABRIR_FICHA_RMI_DATA: false })
                } else {
                    props.CERRAR_FICHA(false)
                }
            }}
            visible={props.ABRIR_FICHA}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            closable={false}
            style={{ color: '#fff' }}>

            <div className="flex-container-Grow-Tercero" >

                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                        onClick={(e) => {
                            if (props.TIPO_FICHA === "MASTER") {
                                props.CERRAR.setState({ ABRIR_FICHA_RMI_DATA: false })
                            } else {
                                props.CERRAR_FICHA(false)
                            }
                        }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>



            <div className="containerRMIData">
                {initComponent.REGISTROS === null ?
                    <div className="center_item_alert" > <h3>{traducirPagina("No_resultados")}</h3> </div>
                    :
                    initComponent.REGISTROS === "" ?
                        <div className="center_item_alert" > <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                        :
                        <iframe
                            width="100%"
                            className="ifr_rmi_data"
                            frameBorder="0"
                            allowtransparency="true"
                            src={initComponent.REGISTROS}

                        />
                }

            </div>
        </Drawer>
    </div>;
};