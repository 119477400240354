import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';
import { ObtenerClientesEnAPI } from "../action/actionCreatorsRequests"
import 'antd/dist/antd.css';
import { Drawer, Pagination } from 'antd';
import { DrawerFichaCliente, createRipple, clienteSeleccionado, efectoBotonRemove } from "../action/actionCreators";


import { traducirPagina, efectoRemoveBuscadorReferencia, volverAMostrarReferenciaPorCliente } from "../librerias/funciones"
import store from '../store'
import {
    CloseOutlined,
    SearchOutlined,
    ReloadOutlined
} from '@ant-design/icons';


const FichaCliente = ({ props, configApp, openDrawerFichaCliente, txtBuscadorReferencia, ClientesMostrador }) => {
    const [hayClientes, setHayClientes] = React.useState(false);
    const [obtenerClientesAux, setObtenerClientesAux] = React.useState([]);
    const [obtenerClientes, setObtenerClientes] = React.useState([]);
    const [textoFiltroCliente, setTextoFiltroCliente] = React.useState("");
    const [datosPaginacion, setDatosPaginacion] = React.useState({ iniPage: 1, inicio: 1, final: 40, numPagina: 40 });



    if (!hayClientes && openDrawerFichaCliente) {
        //setDatosPaginacion({ iniPage: 1, inicio: 1, final: 40, numPagina: 40 })

        if (ClientesMostrador !== null) {
            if (ClientesMostrador.length > 0) {
                setHayClientes(true)
                setObtenerClientes(ClientesMostrador);
                setObtenerClientesAux(ClientesMostrador);
            } else if (ClientesMostrador === null) {
                setHayClientes(true)
                setObtenerClientes(null);

            }
        } else {
            setHayClientes(true)
            setObtenerClientes(null);

        }

    } else {
        if (!openDrawerFichaCliente && hayClientes) {
            if(datosPaginacion.iniPage !== 1){
                setDatosPaginacion({ iniPage: 1, inicio: 1, final: 40, numPagina: 40 })

            }

            //setTextoFiltroCliente("")
            //setObtenerClientes([])
            //setHayClientes(false)
        }
    }
    

    const paginarTabla = (page, pageSize) => {
        if (obtenerClientes.length > 0) {
            let pInicio = (pageSize * page) - (datosPaginacion.numPagina);
            let pFinal = pageSize * page;
            setDatosPaginacion(
                {
                    iniPage: page,
                    inicio: pInicio,
                    final: pFinal,
                    numPagina: datosPaginacion.numPagina
                }
            )
        }
    }

    const updateNumberPage = (current, size) => {
        setDatosPaginacion(
            {
                iniPage: 1,
                inicio: 1,
                final: size,
                numPagina: size
            }
        )
    }


    const cargarClientes = () => {
        setObtenerClientes([]);
        setDatosPaginacion({ iniPage: 1, inicio: 1, final: 40, numPagina: 40 })
        ObtenerClientesEnAPI().then(cliente => {
            if (cliente !== null && cliente.length > 0) {
                setObtenerClientes(cliente);
                setObtenerClientesAux(cliente)
                store.dispatch({ type: "STATE_CLIENTES_MOSTRADOR", ClientesMostrador: cliente })
            } else {
                setObtenerClientes(null);
            }
        })
    }


    const buscarCliente = (palabraABuscar) => {
        let palabra = palabraABuscar.target.value;
        let clientesEncontrado = [];


        if (palabra !== "") {
            obtenerClientesAux.find(function (cliente, i) {
                if (cliente.Cif.toLowerCase().includes(palabra.toLowerCase())) {
                    let existeCliente = clientesEncontrado.find(cli => cli.Codigo === cliente.Codigo)
                    if (existeCliente === undefined) {
                        clientesEncontrado.push(cliente)
                    }

                }

                if (cliente.Codigo.toLowerCase().includes(palabra.toLowerCase())) {
                    let existeCliente = clientesEncontrado.find(cli => cli.Codigo === cliente.Codigo)
                    if (existeCliente === undefined) {
                        clientesEncontrado.push(cliente)
                    }

                }
                if (cliente.Poblacion.toLowerCase().includes(palabra.toLowerCase())) {
                    let existeCliente = clientesEncontrado.find(cli => cli.Codigo === cliente.Codigo)
                    if (existeCliente === undefined) {
                        clientesEncontrado.push(cliente)
                    }

                }
                if (cliente.Razon.toLowerCase().includes(palabra.toLowerCase())) {
                    let existeCliente = clientesEncontrado.find(cli => cli.Codigo === cliente.Codigo)
                    if (existeCliente === undefined) {
                        clientesEncontrado.push(cliente)
                    }

                }
                if (cliente.Telefono.toLowerCase().includes(palabra.toLowerCase())) {
                    let existeCliente = clientesEncontrado.find(cli => cli.Codigo === cliente.Codigo)
                    if (existeCliente === undefined) {
                        clientesEncontrado.push(cliente)
                    }

                }
                if (cliente.Zona.toLowerCase().includes(palabra.toLowerCase())) {
                    let existeCliente = clientesEncontrado.find(cli => cli.Codigo === cliente.Codigo)
                    if (existeCliente === undefined) {
                        clientesEncontrado.push(cliente)
                    }

                }
            })


            if (clientesEncontrado.length > 0) {
                setObtenerClientes(clientesEncontrado);
            } else {
                setObtenerClientes(null);
            }
        } else {
            setObtenerClientes(obtenerClientesAux);
        }

        setDatosPaginacion({ iniPage: 1, inicio: 1, final: 40, numPagina: 40 })

    }



    const gestionarBusquedaCliente = (item) => {
        setObtenerClientes(ClientesMostrador);
        setTextoFiltroCliente("")

        store.dispatch(clienteSeleccionado(item.Razon, item.Codigo, item.Cif, item));

        efectoBotonRemove(item.Razon, "closeBuscadorCliente");
        store.dispatch(DrawerFichaCliente(false));
        volverAMostrarReferenciaPorCliente();
    }


    return (
        <Drawer
            width={1200}
            onClose={() => { store.dispatch(DrawerFichaCliente(false)); setObtenerClientes(ClientesMostrador); setTextoFiltroCliente("") }}
            visible={openDrawerFichaCliente}
            closable={false}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '99999999', color: '#fff' }}>

            <div className="flex-container-Grow-Tercero" >
                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { store.dispatch(DrawerFichaCliente(false)); createRipple(e); setObtenerClientes(ClientesMostrador); setTextoFiltroCliente("") }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>

            <div style={{ padding: '10px' }} >
                <div className="flex-container mdb-skin" style={{ marginBottom: '10px' }}>

                    <div style={{ flexGrow: '6', textAlign: 'left' }}>
                        <div className="grid-container" style={{ backgroundColor: '#eee', marginTop: '2px' }}>
                            <div className="grid-item">
                                <input id="buscadorCliente" className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_cliente")} style={{ backgroundColor: "transparent", width: '400px', border: '0px', height: '100%', color: '#000' }} name="txtInputCliente" autoComplete="off" value={textoFiltroCliente} onChange={(e) => { setTextoFiltroCliente(e.target.value); buscarCliente(e) }} />
                            </div>

                            <div className="grid-item" >
                                <MDBBtn id="closeBuscadorCliente" onClick={e => { createRipple(e); efectoRemoveBuscadorReferencia("buscadorCliente", true); }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: 'hidden' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                            </div>

                            <div className="grid-item barraLateral" ></div>

                            <div className="grid-item">
                                <MDBBtn onClick={e => { createRipple(e); store.dispatch(DrawerFichaCliente(true)); }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                            </div>
                        </div>
                    </div>

                    <div>
                        <button className="activebtnMenu2 " style={{ height: '40px', width: '200px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#1890ff', backgroundColor: '#eee', cursor: 'pointer' }} onClick={() => {
                            cargarClientes()
                        }

                        }><ReloadOutlined style={{ fontSize: '20px', marginRight: '5px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Actualizar_lista")} </span>  </button>
                    </div>

                    <div>
                    </div>
                </div>

                <div style={{ fontSize: '20px', fontWeight: '500', display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                    <div style={{ width: '100%', textAlign: 'right' }}>
                        {obtenerClientes !== null ?
                            obtenerClientes.length > 0 ?
                                <Pagination
                                    showSizeChanger
                                    current={datosPaginacion.iniPage}
                                    onShowSizeChange={(current, size) => { updateNumberPage(current, size) }}
                                    total={obtenerClientes.length}
                                    showTotal={() => ` ${obtenerClientes.length} ${"Clientes"}`}
                                    defaultPageSize={40}
                                    onChange={(page, pageSize) => { paginarTabla(page, pageSize) }}
                                > </Pagination>
                                :
                                null
                            : null
                        }
                    </div>
                </div>


                {obtenerClientes !== null ?
                    obtenerClientes.length > 0 ?
                        <div style={{ height: (configApp.height - 185).toString() + "px", overflow: 'auto' }}>
                            <MDBTable responsive hover>
                                <MDBTableHead>
                                    <tr>
                                        <th style={{ fontWeight: '500' }}>{traducirPagina("Codigo_cliente").toUpperCase()}</th>
                                        <th style={{ fontWeight: '500' }}>{traducirPagina("Razon").toUpperCase()}</th>
                                        <th style={{ fontWeight: '500' }}>{traducirPagina("Cif").toUpperCase()}</th>
                                        <th style={{ fontWeight: '500' }}>{traducirPagina("Poblacion").toUpperCase()}</th>
                                        <th style={{ fontWeight: '500' }}>{traducirPagina("Zona").toUpperCase()}</th>
                                        <th style={{ fontWeight: '500' }}>{traducirPagina("Telefono").toUpperCase()}</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {obtenerClientes.map((item, i) =>
                                        (i + 1) >= datosPaginacion.inicio && (i + 1) <= datosPaginacion.final ?
                                            <tr key={i} style={{ color: item.DefectoCliente ? "blue" : "", fontWeight: item.DefectoCliente ? 'bold' : "" }} onClick={() => { gestionarBusquedaCliente(item) }}>
                                                <td>{item.Codigo}</td>
                                                <td>{item.Razon}</td>
                                                <td>{item.Cif}</td>
                                                <td>{item.Poblacion}</td>
                                                <td>{item.Zona}</td>
                                                <td>{item.Telefono}</td>
                                            </tr>
                                            :
                                            null
                                    )
                                    }
                                </MDBTableBody>
                            </MDBTable>
                        </div>
                        :
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#000' }}> <div color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>Actualmente no existen clientes.</h3> </div>
                }
            </div>
        </Drawer>
    )
}

FichaCliente.propTypes = {
    onClick: PropTypes.func,
    config: PropTypes.object,
    detallesReferencia: PropTypes.object,
    masInformacion: PropTypes.object,
    fichaAbierta: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        openDrawerFichaCliente: state.estadoDrawer.openDrawerFichaCliente,
        ClientesMostrador: state.ClientesMostrador


    };
}

export default connect(mapStateToProps)(FichaCliente);