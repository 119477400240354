import React from "react";
import {
    traducirPagina,
    obtenerDatosUsuarioYLicenciaActual,
    convertirMoneda,
    etiquetaReferenciaSinStock

} from "../librerias/funciones"
import { Drawer } from 'antd';
import { MDBBtn, MDBTable, MDBTableHead, MDBTableBody, MDBContainer } from 'mdbreact';

import { LikeOutlined, DislikeOutlined } from "@ant-design/icons";
import { ObtenerDesgloseStockSocioEnAPI } from "../action/actionCreatorsRequests";

export const DesgloseStockGrupo = (props) => {
    const [initComponent, setInitComponent] = React.useState({
        REGISTROS: [],
        HTML_MANUAL: "",
        ABRIR_MODAL: false,
        TITULO_MODAL: "",
        ID_GRUPO_SELECCIONADO: 0,
        RUTA: []
    })



    const buscarFechaEntrega = (registros) => {
        const resultado = registros.filter((registro) => (registro.FechaEntrega !== undefined && registro.FechaEntrega !== null && registro.FechaEntrega !== ""));
        return resultado;
    }



    const ObtenerDesgloseStockSocio = async (SOCIO, REFERENCIA_TECDOC, MARCA_TECDOC, TIPO_ARTICULO) => {
        let registro = await ObtenerDesgloseStockSocioEnAPI(SOCIO, REFERENCIA_TECDOC, MARCA_TECDOC, TIPO_ARTICULO)
        if (registro !== undefined && registro !== null && registro.Stock.length > 0) {
            initComponent.REGISTROS = registro.Stock
        } else {
            initComponent.REGISTROS = null
        }

        setInitComponent({ ...initComponent })
    }



    React.useEffect(() => {
        const { REFERENCIA_TECDOC, MARCA_TECDOC, TIPO_ARTICULO } = props.DATOS_SELECCIONADO

    
        ObtenerDesgloseStockSocio(props.SOCIO_SELECCIONADO, REFERENCIA_TECDOC, MARCA_TECDOC, TIPO_ARTICULO)

    }, [])



    return <div>
        <Drawer
            width={700}
            onClose={() => {

                props.CERRAR_FICHA(false)

            }}
            visible={props.ABRIR_FICHA}
            placement={"right"}
            closable={false}
            bodyStyle={{ padding: '0px' }}
            style={{ color: '#fff',zIndex: props.Z_INDEX !== "none" ? `${props.Z_INDEX}9`:props.Z_INDEX }}>

            <div className="flex-container-Grow-Tercero" >

                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                        onClick={(e) => {

                            props.CERRAR_FICHA(false)

                        }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>

            <div className="containerStockGenerico">

                {
                    initComponent.REGISTROS === null ?
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{etiquetaReferenciaSinStock()}</h3> </div>
                        :
                        initComponent.REGISTROS.length === 0 ?
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#000' }}> <div color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                            :

                            <MDBTable responsive hover>
                                <MDBTableHead>
                                    <tr>
                                        <th style={{ textAlign: 'left', fontWeight: '500' }}>{traducirPagina("Almacen").toUpperCase()}</th>
                                        {obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE || obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD ? null : <th style={{ textAlign: 'right', width: '100px', fontWeight: '500' }}>{traducirPagina("Cantidad").toUpperCase()}</th>}
                                        <th style={{ textAlign: 'center', width: '100px', fontWeight: '500' }}>{traducirPagina("Disponibilidad").toUpperCase()}</th>


                                        {buscarFechaEntrega(initComponent.REGISTROS).length > 0 ? <th style={{ textAlign: 'center', width: '170px', fontWeight: '500' }}>{traducirPagina("Fecha_entrega").toUpperCase()}</th> : null}

                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {initComponent.REGISTROS.map((item, i) =>
                                        <tr key={i} style={{ color: item.DefectoCliente ? "blue" : "", fontWeight: item.DefectoCliente ? 'bold' : "" }}>
                                            <td style={{ textAlign: 'left' }}>{item.DescripcionAlmacen}</td>

                                            {obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE || obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD ? null : <td style={{ textAlign: 'right' }}>{item.Stock}</td>}

                                            <td style={{ textAlign: 'center' }}>
                                                <button className="activebtnMenu2" style={{ border: '0', borderRadius: '3px', fontSize: '13px', color: item.Stock === 0 ? "red" : '#25c281', backgroundColor: 'transparent', verticalAlign: 'text-bottom' }}>  {item.Stock === 0 ? <DislikeOutlined style={{ fontSize: '20px' }}></DislikeOutlined> : <LikeOutlined style={{ fontSize: '20px' }} />}  </button>
                                            </td>



                                            {buscarFechaEntrega(initComponent.REGISTROS).length > 0 ? <td style={{ textAlign: 'center' }}>  {item.FechaEntrega !== null ? `${item.FechaEntrega} ${item.HoraEntrega}` : ""} </td> : null}

                                        </tr>
                                    )
                                    }
                                </MDBTableBody>
                            </MDBTable>


                }

            </div>


        </Drawer>

    </div>;
};