import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createRipple } from "../../action/actionCreators"
import {
    getPresupuestosTallerFlotasEnAPI, setPresupuestosTallerFlotasEnAPI, ObtenerPresupuestoHTMLFlotasEnAPI, ObtenerPresupuestoPDFFlotasEnAPI,
    DuplicarPresupuestoFlotasEnAPI, PresupuestoFlotaToPresupuestoTallerEnAPI, EnviarPresupuestoFlotasEnAPI,

    PresupuestoToOrdenReparacionEnAPI, ObtenerOrdenReparacionHTMLEnAPI, ObtenerOrdenReparacionPDFEnAPI,
    ObtenerFacturaHTMLEnAPI, ObtenerFacturaPDFEnAPI,PasarAPresupuestoTallerYEnviarMailEnAPI
} from "../../action/actionCreatorsRequests"



import { MSGOK, efectoRemoveBuscadorReferencia, convertirMoneda, obtenerFechaLocal, descargarPdfPasandoBase64, traducirPagina, MSGERROR, añadirPresupuestoNextControl, buscarVehiculoLocal, textoEmailFlota, asuntoEmail, textoEmailTaller } from '../../librerias/funciones'
import { MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from 'mdbreact';
import FichaImpresionGenerico from '../../components/FichaImpresionGenerico'


import {
    PlusOutlined,
    CloseOutlined,
    SearchOutlined,
    EditOutlined,
    DeleteOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined,
    PrinterOutlined,
    CopyOutlined,
    FileDoneOutlined,
    StarOutlined,
    FieldTimeOutlined,
    ShareAltOutlined,
    MailOutlined,

} from '@ant-design/icons';


import { Select, Card, Modal, Button, Input, Row, Col, Drawer } from 'antd';

import store from "../../store";
import MantenimientoDocumento from './MantenimientoDocumento';
import FichaDuplicarDocumento from '../FichaDuplicarDocumento';
import FichaEnvioEmail from '../FichaEnvioEmail';
const { Option } = Select;

const PresupuestoFlota = ({ props, configApp, openDrawerFichaPresupuestoTaller, HISTORIAL_VEHICULO, ABRIR_FICHA, CERRAR_FICHA }) => {
    const [ordenarPorCodigo, setOrdenarPorCodigo] = React.useState(false);
    const [ordenarPorFecha, setOrdenarPorFecha] = React.useState(false);
    const [hayPresupuestos, setHayPresupuestos] = React.useState(false);
    const [presupuestosTaller, setPresupuestosTaller] = React.useState([]);
    const [obtenerPresupuestosAux, setObtenerPresupuestosAux] = React.useState([]);
    const [fechaLocalDesde, setFechaLocalDesde] = React.useState("");
    const [fechaLocalHasta, setFechaLocalHasta] = React.useState("");
    const [estadoSeleccionado, setEstadoSeleccionado] = React.useState("TODOS");
    const [fichaDuplicarDocumento, setFichaDuplicarDocumento] = React.useState(false);
    const [documentoSeleccionado, setDocumentoSeleccionado] = React.useState({});
    const [accion, setAccion] = React.useState("");
    const [fichaTrazabilidad, setFichaTrazabilidad] = React.useState(false);
    const [ABRIR_FICHA_IMPRESION, setABRIR_FICHA_IMPRESION] = React.useState(false);
    const [DOC_HTML, setDOC_HTML] = React.useState("");
    const [idDocTrazabilidad, setIdDocTrazabilidad] = React.useState({});
    const [abrirModalVehiculo, setAbrirModalVehiculo] = React.useState(false);
    const [VEHICULO_LOCAL, setVEHICULO_LOCAL] = React.useState({});

    const [abrirFichaFlota, setAbrirFichaFlota] = React.useState(false)

    const [codigoDocumento, setCodigoDocumento] = React.useState(0)
    const [fichaEnvioMail, setFichaEnvioMail] = React.useState(false)





    const crearPresupuesto = async () => {
        store.dispatch({ type: "UPDATE_CLIENTE_SELECCIONADO_PARA_PRESUPUESTO_TALLER", cliente: {} })
        let VEHICULO = await buscarVehiculoLocal();
        VEHICULO.HISTORIAL_VEHICULO = HISTORIAL_VEHICULO
        const { VEHICULO_TECDOC } = VEHICULO;


        if (Object.keys(VEHICULO_TECDOC).length > 0) {
            setAbrirModalVehiculo(true);
            setVEHICULO_LOCAL(VEHICULO)
        } else {
            /*
            store.dispatch({ type: "UPDATE_MODO_FICHA_PRESUPUESTO_TALLER", esNuevoPresupuesto: true });
            store.dispatch(DrawerFichaMantenimientoPresupuesto(true));
*/
            setAbrirFichaFlota(true)
            setDocumentoSeleccionado({})
        }
    }

    const buscarPresupuestos = (id, ORDERCOLUM, ORDERDIR, ESTADO) => {
        setPresupuestosTaller([])
        setObtenerPresupuestosAux([])

        let obtenerFechaLocalDesde = obtenerFechaLocal(true)
        let obtenerFechaLocalHasta = obtenerFechaLocal(false)

        let enviarEstado = (ESTADO === "TODOS" ? "" : ESTADO)

        getPresupuestosTallerFlotasEnAPI(id, ORDERCOLUM, ORDERDIR, fechaLocalDesde === "" ? obtenerFechaLocalDesde : fechaLocalDesde, fechaLocalHasta === "" ? obtenerFechaLocalHasta : fechaLocalHasta, enviarEstado).then((presupuesto) => {
            if (presupuesto !== undefined) {
                if (presupuesto.PRESUPUESTOS !== null) {
                    setPresupuestosTaller(presupuesto.PRESUPUESTOS)
                    setObtenerPresupuestosAux(presupuesto.PRESUPUESTOS)

                } else {
                    setPresupuestosTaller(null)
                    setObtenerPresupuestosAux(null)
                }
            }
        })
    }





    const ordenarTablaCliente = (ID, ORDERCOLUM, ORDERDIR) => {
        setPresupuestosTaller([])
        getPresupuestosTallerFlotasEnAPI("", ORDERCOLUM, ORDERDIR, fechaLocalDesde, fechaLocalHasta, (estadoSeleccionado !== "TODOS" ? estadoSeleccionado : "")).then((presupuesto) => {
            if (presupuesto !== undefined) {
                if (presupuesto.PRESUPUESTOS !== null) {
                    setPresupuestosTaller(presupuesto.PRESUPUESTOS)
                    setObtenerPresupuestosAux(presupuesto.PRESUPUESTOS)

                } else {
                    setPresupuestosTaller(null)
                    setObtenerPresupuestosAux(null)
                }
            }
        })
    }

    const eliminarCliente = (payload) => {
        setPresupuestosTallerFlotasEnAPI(2, payload).then((respuesta) => {
            if (respuesta.OK) {
                MSGOK(traducirPagina("txt_presupuesto_eliminado"))
                let filteredItems = presupuestosTaller.filter(item => item.ID !== payload.ID)

                if (filteredItems.length > 0) {
                    setPresupuestosTaller(filteredItems)
                    setObtenerPresupuestosAux(filteredItems)
                } else {
                    setPresupuestosTaller(null)
                    setObtenerPresupuestosAux([])
                }
            }
        })
    }


    const verificarFechas = (desde, hasta) => {
        let dateDesde = new Date(desde);
        let dateHasta = new Date(hasta);
        setPresupuestosTaller([])
        //if (dateDesde < dateHasta) {

        setFechaLocalDesde(desde)
        setFechaLocalHasta(hasta)

        let enviarEstado = (estadoSeleccionado === "TODOS" ? "" : estadoSeleccionado)


        getPresupuestosTallerFlotasEnAPI("", 2, "D", desde, hasta, enviarEstado).then((presupuesto) => {
            if (presupuesto !== undefined) {
                if (presupuesto.PRESUPUESTOS !== null) {
                    setPresupuestosTaller(presupuesto.PRESUPUESTOS)
                    setObtenerPresupuestosAux(presupuesto.PRESUPUESTOS)

                } else {
                    setPresupuestosTaller(null)
                    setObtenerPresupuestosAux(null)
                }
            }
        })



    }


    const buscarEnTabla = (palabra, listaCliente) => {
        let registrosEncontrados = [];
        if (palabra !== "") {
            if (listaCliente !== null) {
                listaCliente.find(function (registro, i) {
                    if (registro.CODIGO.toLowerCase().includes(palabra.toLowerCase())) {
                        let existeRegistro = registrosEncontrados.find(regi => regi.ID === registro.ID)
                        if (existeRegistro === undefined) {
                            registrosEncontrados.push(registro)
                        }
                    }

                    if (registro.FECHA.toLowerCase().includes(palabra.toLowerCase())) {
                        let existeRegistro = registrosEncontrados.find(regi => regi.ID === registro.ID)
                        if (existeRegistro === undefined) {
                            registrosEncontrados.push(registro)
                        }
                    }

                    if (registro.VEH_MATRICULA.toLowerCase().includes(palabra.toLowerCase())) {
                        let existeRegistro = registrosEncontrados.find(regi => regi.ID === registro.ID)
                        if (existeRegistro === undefined) {
                            registrosEncontrados.push(registro)
                        }
                    }

                    if (registro.IMPORTE_BASE_IMPONIBLE.toString().toLowerCase().includes(palabra.toLowerCase())) {
                        let existeRegistro = registrosEncontrados.find(regi => regi.ID === registro.ID)
                        if (existeRegistro === undefined) {
                            registrosEncontrados.push(registro)
                        }
                    }

                    if (registro.CLI_NOMBRE.toLowerCase().includes(palabra.toLowerCase())) {
                        let existeRegistro = registrosEncontrados.find(regi => regi.ID === registro.ID)
                        if (existeRegistro === undefined) {
                            registrosEncontrados.push(registro)
                        }
                    }

                    if (registro.IMPORTE_TOTAL.toString().toLowerCase().includes(palabra.toLowerCase())) {
                        let existeRegistro = registrosEncontrados.find(regi => regi.ID === registro.ID)
                        if (existeRegistro === undefined) {
                            registrosEncontrados.push(registro)
                        }
                    }

                    if (registro.ESTADO.toLowerCase().includes(palabra.toLowerCase())) {
                        let existeRegistro = registrosEncontrados.find(regi => regi.ID === registro.ID)
                        if (existeRegistro === undefined) {
                            registrosEncontrados.push(registro)
                        }
                    }

                })
            }
            if (registrosEncontrados.length > 0) {
                setPresupuestosTaller(registrosEncontrados);
            } else {
                setPresupuestosTaller(null);
            }
        } else {
            setPresupuestosTaller(obtenerPresupuestosAux);
        }
    }



    const pasarDocumento = (ID_DOCUMENTO, ID_CLIENTE, ID_VEHICULO, FECHA) => {
        setFichaDuplicarDocumento(false)
        PresupuestoFlotaToPresupuestoTallerEnAPI(ID_DOCUMENTO, ID_CLIENTE, ID_VEHICULO, FECHA).then(documento => {
            if (documento !== undefined) {
                if (documento.OK) {
                    MSGOK("Documento enviado correctamente.");
                    buscarPresupuestos("", 2, "D", estadoSeleccionado);
                }
            }
        })

    }

    const duplicarPresupuesto = (ID_DOCUMENTO, ID_CLIENTE, ID_VEHICULO, FECHA) => {
        setFichaDuplicarDocumento(false)

        DuplicarPresupuestoFlotasEnAPI(ID_DOCUMENTO, ID_CLIENTE, ID_VEHICULO, FECHA).then(documento => {
            if (documento !== undefined) {
                if (documento.OK) {
                    MSGOK("Documento duplicado correctamente.");

                    buscarPresupuestos("", 2, "D", estadoSeleccionado);
                }
            }
        })

    }


    const abrirDocumetoTrazabilidad = (registro) => {
        setIdDocTrazabilidad({ TIPO_DOCUMENTO: registro.TIPO_DOCUMENTO, ID: registro.ID })

        if (registro.TIPO_DOCUMENTO === "PRESUPUESTO") {
            /*
            store.dispatch({ type: "UPDATE_PRESUPUESTO_ID_TALLER", id: registro.ID });
            store.dispatch(DrawerFichaImpresionDocumento(true));
            */
            setABRIR_FICHA_IMPRESION(true)
            buscarDocumento(registro.ID, registro.TIPO_DOCUMENTO)

        } else if (registro.TIPO_DOCUMENTO === "ORDEN REPARACION") {
            setABRIR_FICHA_IMPRESION(true)
            buscarDocumento(registro.ID, registro.TIPO_DOCUMENTO)
        } else {
            setABRIR_FICHA_IMPRESION(true)
            buscarDocumento(registro.ID, registro.TIPO_DOCUMENTO)
        }
    }



    const buscarDocumento = (ID, TIPO) => {
        if (TIPO === "PRESUPUESTO") {
            ObtenerPresupuestoHTMLFlotasEnAPI(ID).then((doc) => {
                if (doc !== undefined) {
                    if (doc !== null || doc !== "") {
                        setDOC_HTML(doc)
                    } else {
                        setDOC_HTML("")
                    }
                }
            })
        } else if (TIPO === "ORDEN REPARACION" || TIPO === "ORDEN REPARACIÓN") {
            ObtenerOrdenReparacionHTMLEnAPI(ID).then((documento) => {
                if (documento !== undefined) {
                    if (documento.HTML !== null && documento.HTML !== "") {
                        setDOC_HTML(documento.HTML)
                    } else {
                        setDOC_HTML("")
                    }
                }
            })
        } else {
            ObtenerFacturaHTMLEnAPI(ID).then((documento) => {
                if (documento !== undefined) {
                    if (documento.HTML !== null && documento.HTML !== "") {
                        setDOC_HTML(documento.HTML)
                    } else {
                        setDOC_HTML("")
                    }
                }
            })
        }

    }


    const buscarDocumentoPDF = () => {
        if (idDocTrazabilidad.TIPO_DOCUMENTO === "PRESUPUESTO") {

            ObtenerPresupuestoPDFFlotasEnAPI(idDocTrazabilidad.ID).then((doc) => {
                if (doc !== undefined) {
                    if (doc !== "" && doc !== null) {
                        descargarPdfPasandoBase64(doc, "Presupuesto")

                    }
                }
            })

        } else if (idDocTrazabilidad.TIPO_DOCUMENTO === "ORDEN REPARACION" || idDocTrazabilidad.TIPO_DOCUMENTO === "ORDEN REPARACIÓN") {
            ObtenerOrdenReparacionPDFEnAPI(idDocTrazabilidad.ID).then((documento) => {
                if (documento !== undefined) {
                    if (documento.BYTES !== null && documento.BYTES !== "") {
                        descargarPdfPasandoBase64(documento.BYTES, "ORDEN_DE_REPARACION")
                    }
                }
            })
        } else {
            ObtenerFacturaPDFEnAPI(idDocTrazabilidad.ID).then((documento) => {
                if (documento !== undefined) {
                    if (documento.BYTES !== null && documento.BYTES !== "") {
                        descargarPdfPasandoBase64(documento.BYTES, "FACTURA")
                    }
                }
            })
        }
    }

    const obtenerPresupuestoParaNextControl = async (ID) => {
        try {
            const peticion = await getPresupuestosTallerFlotasEnAPI(ID, 1, "A", "");
            const { OK, PRESUPUESTOS } = await peticion;
            if (OK) {
                añadirPresupuestoNextControl(PRESUPUESTOS[0])
            }
        } catch (error) {
            MSGERROR(error.message);

        }
    }

    const enviarDocumentoPDF = async (payLoad) => {
        payLoad.ID_PRESUPUESTO = documentoSeleccionado.ID
        //payLoad.CUERPO = "Envio de presupuesto"
        const documento = await EnviarPresupuestoFlotasEnAPI(true, payLoad)
        if (documento !== undefined) {
            if (documento.OK) {
                setFichaEnvioMail(false)
                MSGOK(traducirPagina("Email_enviado"))

                buscarPresupuestos("", 2, "D", estadoSeleccionado);
            }
        }

    }



    const pasarEnviarDocumentoPDF = async (payLoad) => {

        payLoad.ID_PRESUPUESTO = documentoSeleccionado.ID

        payLoad.PR_ID_CLIENTE = documentoSeleccionado.ID_CLIENTE
        payLoad.PR_ID_VEHICULO = documentoSeleccionado.ID_VEHICULO
        payLoad.PR_FECHA = documentoSeleccionado.FECHA


        //payLoad.CUERPO = "Envio de presupuesto"
        const documento = await PasarAPresupuestoTallerYEnviarMailEnAPI(payLoad)
        if (documento !== undefined) {
            if (documento.OK) {
                setFichaEnvioMail(false)
                MSGOK(traducirPagina("Email_enviado"))

                buscarPresupuestos("", 2, "D", estadoSeleccionado);
            }
        }


    }






    useEffect(() => {
        let obtenerFechaLocalDesde = obtenerFechaLocal(true)
        let obtenerFechaLocalHasta = obtenerFechaLocal(false)
        setFechaLocalDesde(obtenerFechaLocalDesde)
        setFechaLocalHasta(obtenerFechaLocalHasta)


        buscarPresupuestos("", 2, "D", estadoSeleccionado);
    }, [])

    return (
        <div>
            <Drawer
                width={1300}
                onClose={() => { CERRAR_FICHA() }}
                visible={ABRIR_FICHA}
                placement={"right"}
                closable={false}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff', zIndex: 1000 }}>


                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                            onClick={(e) => {
                                CERRAR_FICHA()
                                createRipple(e)

                            }}>
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>


                <div className="p-3" style={{ height: (configApp.height - 70).toString() + "px", overflow: 'auto' }}>
                    <div className="flex-container mdb-skin">

                        <div className="grid-container mb-3" style={{ backgroundColor: '#eee' }}>
                            <div className="grid-item">
                                <input id="buscadorPresupuestoTaller" className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_presupuesto")} style={{ backgroundColor: "transparent", width: '300px', border: '0px', height: '100%', color: '#000' }} name="txtInputPresupuesto" autoComplete="off" onChange={(e) => { buscarEnTabla(e.target.value, obtenerPresupuestosAux) }} />
                            </div>

                            <div className="grid-item" >
                                <MDBBtn id="closeBuscadorPresupuestoTaller" onClick={e => { createRipple(e); efectoRemoveBuscadorReferencia("buscadorPresupuestoTaller", true); }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: 'hidden' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                            </div>


                            <div className="grid-item barraLateral" ></div>

                            <div className="grid-item">
                                <MDBBtn onClick={e => { createRipple(e); }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                            </div>
                        </div>

                        <div style={{ backgroundColor: 'transparent', color: '#000', marginTop: '5px', marginLeft: '10px' }}>
                            {openDrawerFichaPresupuestoTaller ?

                                <Select
                                    placeholder={traducirPagina("Filtrar_por_estado").toUpperCase()}
                                    style={{ width: "200px", textAlign: 'left' }}
                                    optionFilterProp="children"
                                    onChange={(estado) => { setEstadoSeleccionado(estado); buscarPresupuestos("", 2, "D", estado); }}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >

                                    <Option value={"TODOS"}>{traducirPagina("Todos").toUpperCase()}</Option>
                                    <Option value={"PEN"}>{traducirPagina("Pendiente").toUpperCase()}</Option>
                                    <Option value={"OR"}>{traducirPagina("Orden_reparacion").toUpperCase()}</Option>
                                    <Option value={"FAC"}>{traducirPagina("Facturado").toUpperCase()}</Option>
                                    <Option value={"REC"}>{traducirPagina("Rechazado").toUpperCase()}</Option>

                                </Select>
                                :
                                null
                            }
                        </div>


                        <div className="grid-item" style={{ backgroundColor: 'transparent', color: '#000', marginTop: '5px', marginLeft: '10px' }}>
                            <input type="date" value={fechaLocalDesde} onChange={(e) => setFechaLocalDesde(e.target.value)}></input>
                        </div>

                        <div className="grid-item" style={{ backgroundColor: 'transparent', color: '#000', marginTop: '5px', marginLeft: '10px' }}>
                            <input type="date" value={fechaLocalHasta} onChange={(e) => { setFechaLocalHasta(e.target.value); }} ></input>
                        </div>

                        <div>
                            <button className="activebtnMenu2 btnEstiloPersonalizado" style={{ height: '40px', width: '100px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#fff', backgroundColor: '#transparent', cursor: 'pointer' }} onClick={() => {
                                verificarFechas(fechaLocalDesde, fechaLocalHasta)
                            }

                            }><SearchOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Filtrar")} </span>  </button>
                        </div>

                        <div>

                            <button className="activebtnMenu2 btnEstiloPersonalizado" style={{ height: '40px', width: '180px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#fff', backgroundColor: 'transparent', cursor: 'pointer' }} onClick={() => {
                                crearPresupuesto();

                            }

                            }><PlusOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Nuevo_presupuesto")} </span>  </button>
                        </div>
                    </div>

                    <Card style={{ height: (configApp.height - 170).toString() + "px" }} className="mb-2 mdb-skin" >
                        {presupuestosTaller !== undefined ?
                            presupuestosTaller === null ?
                                <div style={{ textAlign: 'center' }}>
                                    <h4>{traducirPagina("No_presupuestos")}</h4>
                                </div>
                                :
                                presupuestosTaller.length > 0 ?
                                    <div>
                                        <div style={{ fontSize: '16px', fontWeight: '500', color: "#000", height: (configApp.height - 220).toString() + "px", overflow: 'auto' }} className="row">
                                            <MDBTable responsive hover >
                                                <MDBTableHead>
                                                    <tr>
                                                        <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '110px', verticalAlign: 'baseline', cursor: 'pointer' }} onClick={() => { ordenarTablaCliente("", 1, ordenarPorCodigo ? "A" : "D", estadoSeleccionado); setOrdenarPorCodigo(ordenarPorCodigo ? false : true); setOrdenarPorFecha(false); }} >
                                                            {traducirPagina("Codigo")} {ordenarPorCodigo ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}
                                                        </th>
                                                        <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '100px', verticalAlign: 'baseline', cursor: 'pointer' }} onClick={() => { ordenarTablaCliente("", 2, ordenarPorFecha ? "A" : "D", estadoSeleccionado); setOrdenarPorFecha(ordenarPorFecha ? false : true); setOrdenarPorCodigo(false); }}>{traducirPagina("Fecha")}{ordenarPorFecha ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />} </th>
                                                        <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '120px', verticalAlign: 'baseline', textAlign: 'center' }} >{traducirPagina("Matricula")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", verticalAlign: 'baseline' }}>{traducirPagina("Cliente")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '120px', verticalAlign: 'baseline', textAlign: 'right' }}>{traducirPagina("Total")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '50px', verticalAlign: 'baseline', textAlign: 'center' }}>{traducirPagina("GT").toUpperCase()}</th>

                                                        <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '65px', verticalAlign: 'baseline', textAlign: 'center' }}>{traducirPagina("Estado")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '100px', verticalAlign: 'baseline' }}></th>
                                                    </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                    {presupuestosTaller.map((presupuesto, i) =>
                                                        <tr key={presupuesto.ID}>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{presupuesto.CODIGO}</td>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{presupuesto.FECHA}</td>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle', textAlign: 'center' }}>{presupuesto.VEH_MATRICULA}</td>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{presupuesto.CLI_NOMBRE}</td>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle', textAlign: 'right' }}>{convertirMoneda(presupuesto.IMPORTE_TOTAL, "€")}</td>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle', textAlign: 'center' }}>{presupuesto.ID_PRESUPUESTO_GT_ESTIMATE > 0 ? <StarOutlined style={{ fontSize: '18px', fontWeight: '500', color: '#fcec01' }} /> : ""}</td>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle', textAlign: 'center' }}>{presupuesto.ESTADO}</td>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>

                                                                <div className="flex-container-Grow-Simple">
                                                                    <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                        <MDBBtn title={traducirPagina("Duplicar_presupuesto")} onClick={() => {
                                                                            setFichaDuplicarDocumento(true)
                                                                            setDocumentoSeleccionado(presupuesto)
                                                                            setAccion("DUPLICAR")
                                                                        }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                            <CopyOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                        </MDBBtn>
                                                                    </div>
                                                                    <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                        <MDBBtn title={traducirPagina("Pasar_flota_taller")} onClick={() => {
                                                                            setFichaDuplicarDocumento(true)
                                                                            setDocumentoSeleccionado(presupuesto)
                                                                            setAccion("PASAR")
                                                                        }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                            <FileDoneOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                        </MDBBtn>
                                                                    </div>


                                                                    {/*
                                                                    obtenerDatosUsuarioYLicenciaActual().FACTURACION_LITE ?
                                                                        <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                            <MDBBtn title={traducirPagina("Pasar_OR")} onClick={() => {
                                                                                setFichaDuplicarDocumento(true)
                                                                                setDocumentoSeleccionado(presupuesto)
                                                                                setAccion("PASAR")
                                                                            }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                                <FileDoneOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                            </MDBBtn>
                                                                        </div>
                                                                        :
                                                                        null
                                                                
                                                                  

                                                                    obtenerDatosUsuarioYLicenciaActual().FACTURACION_LITE ?
                                                                        <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                            <MDBBtn title={traducirPagina("Trazabilidad_presupuesto")} onClick={() => {
                                                                                setDocumentoSeleccionado(presupuesto)



                                                                                setFichaTrazabilidad(true)

                                                                            }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                                <FieldTimeOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                            </MDBBtn>
                                                                        </div>
                                                                        :
                                                                        null
                                                                        */  }


                                                                    <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                        <MDBBtn title={traducirPagina("Editar_presupuesto")} onClick={() => {
                                                                            if (presupuesto.ES_MODIFICABLE) {

                                                                                /*
                                                                                                                                                                store.dispatch({ type: "UPDATE_PRESUPUESTO_ID_TALLER", id: presupuesto.ID });

                                                                                                                                                                store.dispatch({ type: "UPDATE_MODO_FICHA_PRESUPUESTO_TALLER", esNuevoPresupuesto: false });
                                                                                                                                                                store.dispatch(DrawerFichaMantenimientoPresupuesto(true));
                                                                                */
                                                                                setCodigoDocumento(presupuesto.ID)
                                                                                setAbrirFichaFlota(true)
                                                                            }
                                                                        }}
                                                                            className="btn-matricula  estiloBotonRemove paddindDeTres">
                                                                            <EditOutlined style={{ color: presupuesto.ES_MODIFICABLE ? '#333' : '#999', cursor: 'pointer', fontSize: '20px' }} />
                                                                        </MDBBtn>
                                                                    </div>

                                                                    <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                        <MDBBtn title={traducirPagina("Eliminar_presupuesto")} onClick={() => {
                                                                            if (presupuesto.ES_ELIMINABLE) {
                                                                                eliminarCliente(presupuesto)
                                                                            }
                                                                        }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                            <DeleteOutlined style={{ color: presupuesto.ES_ELIMINABLE ? '#333' : '#999', cursor: 'pointer', fontSize: '20px' }} />
                                                                        </MDBBtn>
                                                                    </div>


                                                                    <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                        <MDBBtn title={traducirPagina("Imprimir_presupuesto")} onClick={() => {
                                                                            /*
                                                                            store.dispatch({ type: "UPDATE_PRESUPUESTO_ID_TALLER", id: presupuesto.ID });
                                                                            store.dispatch(DrawerFichaImpresionDocumento(true));
*/                                                                            abrirDocumetoTrazabilidad({ TIPO_DOCUMENTO: "PRESUPUESTO", ID: presupuesto.ID })


                                                                            setABRIR_FICHA_IMPRESION(true)

                                                                            //buscarDocumento(payLoadLocal.ID)
                                                                        }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                            <PrinterOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                        </MDBBtn>
                                                                    </div>


                                                                    <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                        <MDBBtn title={traducirPagina("Ficha_enviar_email")}
                                                                            onClick={() => {
                                                                                setDocumentoSeleccionado(presupuesto)
                                                                                setFichaEnvioMail(true)


                                                                            }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                            <MailOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                        </MDBBtn>
                                                                    </div>

                                                                    <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>

                                                                        <MDBBtn title={"NEXT CONTROL"} onClick={() => {
                                                                            obtenerPresupuestoParaNextControl(presupuesto.ID)
                                                                        }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                            <ShareAltOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                        </MDBBtn>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </MDBTableBody>
                                            </MDBTable>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ fontSize: '16px', fontWeight: '500', color: "#000" }} className="row">
                                        <MDBCol md="9">
                                            <div>
                                                <span className="skeleton-box" style={{ width: '900px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '900px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '800px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '800px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '800px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '800px' }}></span>
                                            </div>
                                        </MDBCol>
                                    </div>
                            : null}
                    </Card>
                </div>
            </Drawer>

            {/*openDrawerFichaPresupuestoTaller ?
                <MantenimientoPresupuestoTaller
                    title={traducirPagina("Ficha_de_presupuesto").toUpperCase()}
                    setHayPresupuestos={buscarPresupuestos}
                    VEHICULO_LOCAL={VEHICULO_LOCAL}
                ></MantenimientoPresupuestoTaller>
                :
                null
         */}


            {
                abrirFichaFlota ?
                    <MantenimientoDocumento
                        title={traducirPagina("Ficha_de_presupuesto").toUpperCase()}
                        ABRIR_FICHA={abrirFichaFlota}
                        CERRAR_FICHA={() => { setAbrirFichaFlota(false); setCodigoDocumento(0) }}
                        setHayPresupuestos={buscarPresupuestos}
                        VEHICULO_LOCAL={VEHICULO_LOCAL}
                        CODIGO_DOCUMENTO={codigoDocumento}
                    >

                    </MantenimientoDocumento>
                    :
                    null
            }


            {
                fichaDuplicarDocumento ?
                    <FichaDuplicarDocumento
                        title={accion === "DUPLICAR" ? traducirPagina("Duplicar_documento").toUpperCase() : traducirPagina("Pasar_documento").toUpperCase()}
                        DOCUMENTO={documentoSeleccionado}
                        MOD_DOCUMENTO={setDocumentoSeleccionado}
                        TIPO_DOCUMENTO={"PRESUPUESTO"}
                        ACCION={accion}
                        ABRIR_FICHA={fichaDuplicarDocumento}
                        CERRAR_FICHA={setFichaDuplicarDocumento}
                        ENVIAR={accion === "DUPLICAR" ? duplicarPresupuesto : pasarDocumento}

                        BTN_PASAR_ENVIAR={true}
                        ENVIAR_EMAIL={setFichaEnvioMail}

                    >
                    </FichaDuplicarDocumento>
                    :
                    null
            }




            {
                /*
                abrirPagoFactura ?
                    <PagarFactura
                        title={traducirPagina("Cobros_factura").toUpperCase()}
                        ABRIR_FICHA={abrirPagoFactura}
                        CERRAR_FICHA={setAbrirPagoFactura}
                        REGISTRO_SELECCIONAD0={{ ID: documentoSeleccionado.ID_FACTURA, IMPORTE_TOTAL: 0 }}
                        ES_TRAZABILIDAD={fichaTrazabilidad}
                        IMPRESORA={() =>{}}

                    >
                    </PagarFactura>
                    :
                    null
            */ }


            {ABRIR_FICHA_IMPRESION ?
                <FichaImpresionGenerico
                    title={traducirPagina("Imprimir_documento").toUpperCase()}
                    ABRIR_FICHA={ABRIR_FICHA_IMPRESION}
                    CERRAR_FICHA={setABRIR_FICHA_IMPRESION}
                    DOCUMENTO={DOC_HTML}
                    MODIFICAR_DOCUMENTO={setDOC_HTML}
                    DESCARGAR_DOCUMENTO={buscarDocumentoPDF}
                >
                </FichaImpresionGenerico>
                :
                null
            }

            {
                fichaEnvioMail && (
                    <FichaEnvioEmail
                        title={traducirPagina("Ficha_enviar_email").toUpperCase()}
                        ABRIR_FICHA={fichaEnvioMail}
                        CERRAR_FICHA={setFichaEnvioMail}
                        ENVIAR_EMAIL={fichaDuplicarDocumento ? pasarEnviarDocumentoPDF:enviarDocumentoPDF}

                        PARA={documentoSeleccionado.CLI_MAIL}
                        ASUNTO={asuntoEmail(documentoSeleccionado.CODIGO_AUTORIZACION,documentoSeleccionado.VEH_MATRICULA)}
                        TEXTO_DEFECTO={
                            fichaDuplicarDocumento ?
                            textoEmailTaller(documentoSeleccionado.CODIGO_AUTORIZACION,`${documentoSeleccionado.VEH_MATRICULA}`)
                            :
                            textoEmailFlota(documentoSeleccionado.CODIGO_AUTORIZACION)
                        }

                    >
                    </FichaEnvioEmail>
                )
            }

            {abrirModalVehiculo ?
                <Modal
                    visible={abrirModalVehiculo}
                    width={700}
                    centered
                    title={traducirPagina("FICHA_DEL_VEHÍCULO")}
                    afterClose={() => {
                        /*
                        store.dispatch({ type: "UPDATE_MODO_FICHA_PRESUPUESTO_TALLER", esNuevoPresupuesto: true });
                        store.dispatch(DrawerFichaMantenimientoPresupuesto(true));
*/
                        setAbrirFichaFlota(true)
                        setAbrirModalVehiculo(false);
                        setVEHICULO_LOCAL({});
                    }}
                    onCancel={() => setAbrirModalVehiculo(false)}
                    zIndex={10000}
                    footer={[
                        <Button key="back" onClick={() => {
                            /*
                            store.dispatch({ type: "UPDATE_MODO_FICHA_PRESUPUESTO_TALLER", esNuevoPresupuesto: true });
                            store.dispatch(DrawerFichaMantenimientoPresupuesto(true));
*/
                            setAbrirFichaFlota(true)
                            setAbrirModalVehiculo(false);
                            setVEHICULO_LOCAL({});
                        }}>
                            {traducirPagina("NO")}
                        </Button>,
                        <Button className="btnColor" key="submit" type="primary" onClick={() => {
                            /*
                            store.dispatch({ type: "UPDATE_MODO_FICHA_PRESUPUESTO_TALLER", esNuevoPresupuesto: true });
                            store.dispatch(DrawerFichaMantenimientoPresupuesto(true));
*/
                            setAbrirFichaFlota(true)
                            setAbrirModalVehiculo(false);
                        }}> {traducirPagina("SI")}</Button>
                    ]}
                >

                    <h4 style={{ margin: '0px 0px 24px 0px' }}>{traducirPagina("Msg_presupuesto_actual")}</h4>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <tbody data-test="table-body">
                                <tr>
                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Matricula").toUpperCase()}</td>
                                    <td style={{ textAlign: 'left' }}>{VEHICULO_LOCAL.MATRICULA}</td>
                                </tr>

                                <tr>
                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Bastidor").toUpperCase()}</td>
                                    <td style={{ textAlign: 'left' }}>{VEHICULO_LOCAL.BASTIDOR}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Marca").toUpperCase()}</td>
                                    <td style={{ textAlign: 'left' }}>{VEHICULO_LOCAL.MARCA_VEHICULO}</td></tr>
                                <tr>
                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Modelo").toUpperCase()}</td>
                                    <td style={{ textAlign: 'left' }}>{VEHICULO_LOCAL.MODELO_VEHICULO}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Motor").toUpperCase()}</td>
                                    <td style={{ textAlign: 'left' }}>{VEHICULO_LOCAL.MOTOR_VEHICULO}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </Modal>
                :
                null
            }
        </div>
    )
}


PresupuestoFlota.propTypes = {
    title: PropTypes.string,
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        openDrawerFichaPresupuestoTaller: state.estadoDrawer.openDrawerFichaPresupuestoTaller,
        HISTORIAL_VEHICULO: state.fichaBusquedaVehiculo.historicapp

    };
}

export default connect(mapStateToProps)(PresupuestoFlota);