import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from 'mdbreact';

import 'antd/dist/antd.css';
import { Input, Row, Col, Drawer } from 'antd';
import { DrawerFichaDatosCliente, createRipple } from "../action/actionCreators";
import store from '../store'
import { traducirPagina } from "../librerias/funciones"


const FichaDatosCliente = ({ props, configApp, openDrawerFichaDatosClientes, txtBuscadorReferencia, clienteSeleccionado }) => {

    return (
        <Drawer
            width={600}
            onClose={() => { store.dispatch(DrawerFichaDatosCliente(false)); }}
            visible={openDrawerFichaDatosClientes}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '99999999', color: '#fff' }}>

            <div className="flex-container-Grow-Tercero" >
                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { store.dispatch(DrawerFichaDatosCliente(false)); createRipple(e) }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>

            <div style={{ height: (configApp.height - 190).toString() + "px", }} className="ml-4 mr-4 mt-4" >
                {clienteSeleccionado !== null ?
                clienteSeleccionado !== undefined ?
                Object.keys(clienteSeleccionado).length > 0 ?
                    Object.keys(clienteSeleccionado.registroCliente).length > 0 ?
                        <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                            <Col md={10} >
                                <span>{traducirPagina("Codigo")} </span>
                            </Col>
                            <Col md={14} >
                                <span>{traducirPagina("Cif").toUpperCase()}</span>
                            </Col>
                            <Col md={10} >
                                <Input disabled={true} name="codigo" value={clienteSeleccionado.registroCliente.Codigo} />
                            </Col>

                            <Col md={10} >
                                <Input disabled={true} name="cif" value={clienteSeleccionado.registroCliente.Cif} />
                            </Col>

                            <Col md={24}>
                                <span>{traducirPagina("Razon")} </span>
                            </Col>
                            <Col md={24} >
                                <Input disabled={true} name="nombre" value={clienteSeleccionado.registroCliente.Razon} />
                            </Col>
                            <Col md={24} >
                                <span>{traducirPagina("Poblacion")} </span>
                            </Col>

                            <Col md={24} >
                                <Input disabled={true}  name="telefono2" value={clienteSeleccionado.registroCliente.Poblacion} />
                            </Col>
                            <Col md={24} >
                                <span>{traducirPagina("Zona")} </span>
                            </Col>
                            <Col md={24} >
                                <Input disabled={true}  name="telefono3" value={clienteSeleccionado.registroCliente.Zona} />
                            </Col>

                            <Col md={24} >
                                <span>{traducirPagina("Telefono")} </span>
                            </Col>

                         

                            

                            <Col md={24} >
                                <Input disabled={true}  name="telefono1" value={clienteSeleccionado.registroCliente.Telefono} />
                            </Col>
                          
                          
                           


                        </Row>
                        :
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("No_clientes")}</h3> </div>

                    :
                    null
                    :null
                    :null
                }

            </div>


        </Drawer>

    )
}

FichaDatosCliente.propTypes = {
    onClick: PropTypes.func,
    config: PropTypes.object,
    detallesReferencia: PropTypes.object,
    masInformacion: PropTypes.object,
    fichaAbierta: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        openDrawerFichaDatosClientes: state.estadoDrawer.openDrawerFichaDatosClientes,
        clienteSeleccionado: state.clienteSeleccionado
    };
}

export default connect(mapStateToProps)(FichaDatosCliente);