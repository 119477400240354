import { ReadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { idModeloLatam } from "../../librerias/funciones";
import { Vehiculo_Documentacion_TecnicaEnAPI } from "../../action/actionCreatorsRequests";

const CPBtndoc = ({ EVENTO_ABRIR_VISOR }) => {
    const [urlModelo, setUrlModelo] = useState("")
    const modeloLatam = idModeloLatam()



    const buscarDocumentos = async (modeloLatam) => {
        const registros = await Vehiculo_Documentacion_TecnicaEnAPI(modeloLatam);
        const { OK, URL_DOCUMENTO } = registros === undefined ? { OK: false, URL_DOCUMENTO: null } : registros
        if (OK) {

            const url_final = URL_DOCUMENTO === null || URL_DOCUMENTO === "" ? null : URL_DOCUMENTO
            setUrlModelo(url_final)
        } else {
            setUrlModelo(null)
        }
    }


    useEffect(() => {
        buscarDocumentos(modeloLatam)
    }, [modeloLatam])




    return (
        urlModelo !== null && urlModelo !== "" ?
            <div>
                <button title="DOC" onClick={() => {
                    EVENTO_ABRIR_VISOR(urlModelo)
                }}
                    className="activebtnMenu2" style={{ height: '40px', width: '50px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: '#ccc', marginLeft: '10px' }}> <ReadOutlined style={{ fontSize: '25px' }} />
                </button>
            </div>
            :
            null
    )
}

export default CPBtndoc;