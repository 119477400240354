import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from 'mdbreact';
import 'antd/dist/antd.css';

import { Select, Card, Drawer, Modal, Button, Form, Input, Checkbox, Row, Col, Tabs, Timeline } from 'antd';
import { MSGERROR, MSGOK, traducirPagina, nuevoFormatoFechaCliente, convertirMoneda } from "../librerias/funciones"
import { TrazabilidadDocumentoEnAPI } from "../action/actionCreatorsRequests"


import {
    PlusOutlined,
    SaveOutlined,
    EditOutlined,
    DeleteOutlined,
    SendOutlined,
    EyeOutlined,
    FileAddOutlined,
    SearchOutlined,
    ClockCircleOutlined,
    FilePptOutlined,
    FileDoneOutlined,
    FileProtectOutlined
} from '@ant-design/icons';
import store from '../store'


const { Option } = Select;
const { TabPane } = Tabs;

const FichaTrazabilidad = ({ props, configApp }) => {
    const [hayRegistros, setHayRegistros] = React.useState(false)
    const [tazabilidad, setTazabilidad] = React.useState([])



    const buscarHistorial = (ID_DOCUMENTO, TIPO_DOCUMENTO) => {
        setTazabilidad([])
        TrazabilidadDocumentoEnAPI(ID_DOCUMENTO, TIPO_DOCUMENTO).then((historial) => {
            if (historial !== undefined) {
                if (historial.REGISTROS !== null && historial.REGISTROS.length > 0) {
                    setTazabilidad(historial.REGISTROS)
                } else {
                    setTazabilidad(null)
                }
            }
        })
    }

    const traducirHistorial = (TIPO_DOCUMENTO) => {
        let traducirTipoDocumento = "";
        if (TIPO_DOCUMENTO === "PRESUPUESTO") {
            traducirTipoDocumento = traducirPagina("Presupuesto").toUpperCase();
        } else if (TIPO_DOCUMENTO === "ORDEN REPARACION" || TIPO_DOCUMENTO === "ORDEN REPARACIÓN") {
            traducirTipoDocumento = traducirPagina("Orden_de_reparacion").toUpperCase();
        } else {
            traducirTipoDocumento = traducirPagina("Factura_de_venta").toUpperCase();
        }

        return traducirTipoDocumento;
    }

    if (!hayRegistros && props.ABRIR_FICHA) {
        setHayRegistros(true);
        buscarHistorial(props.ID_DOCUMENTO, props.TIPO_DOCUMENTO);

    } else {
        if (!props.ABRIR_FICHA && hayRegistros) {
            setHayRegistros(false);
            setTazabilidad([])

        }
    }





    return (
        <div>
            <Drawer
                width={600}
                onClose={() => { props.CERRAR_FICHA(false); }}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                bodyStyle={{ padding: '0px', backgroundColor: '#f7f7f7' }}
                style={{ zIndex: '99999999', color: '#fff' }}
            >

                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { props.CERRAR_FICHA(false); store.dispatch({ type: "UPDATE_CLIENTE_SELECCIONADO_PARA_PRESUPUESTO_TALLER", cliente: {} }) }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>


                {/*traducirHistorial(historial.TIPO_DOCUMENTO) */}
                <div style={{ height: (configApp.height - 90).toString() + "px" }} className="mt-4 ml-4 mr-4">
                    {tazabilidad === null ?
                        <h4>{traducirPagina("No_resultados")}</h4>
                        :
                        <Timeline mode="alternate">
                            {tazabilidad.length > 0 ?
                                tazabilidad.map((historial) =>
                                    <Timeline.Item dot={historial.TIPO_DOCUMENTO === "PRESUPUESTO" ? <FilePptOutlined style={{ fontSize: '22px' }} /> : historial.TIPO_DOCUMENTO === "ORDEN REPARACION" || historial.TIPO_DOCUMENTO === "ORDEN REPARACIÓN" ? <FileDoneOutlined style={{ fontSize: '22px' }} /> : <FileProtectOutlined style={{ fontSize: '22px' }} />} >
                                        <Card>
                                            <div>
                                                {historial.TIPO_DOCUMENTO}
                                                <EyeOutlined style={{ fontSize: '20px', marginLeft: '20px', verticalAlign: 'top', color: '#1790ff' }}
                                                    onClick={() => {
                                                        if (historial.TIPO_DOCUMENTO === "COBRO") {
                                                            props.ABRIR_FICHA_COBRO(true);
                                                        } else {
                                                            props.ABRIR_IMPRESION(historial)
                                                        }

                                                    }} />

                                            </div>
                                            <div>
                                                {historial.CODIGO}
                                            </div>
                                            <div>
                                                {historial.FECHA}
                                            </div>
                                            <div>
                                                {convertirMoneda(historial.IMPORTE_TOTAL, "€")}

                                            </div>



                                        </Card>
                                    </Timeline.Item>
                                )

                                :
                                null
                            }
                        </Timeline>
                    }

                </div>


            </Drawer>
        </div>

    )
}

FichaTrazabilidad.propTypes = {
    title: PropTypes.string,
    ID_DOCUMENTO: PropTypes.number,
    TIPO_DOCUMENTO: PropTypes.string,

    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    ABRIR_IMPRESION: PropTypes.func,
    ABRIR_FICHA_COBRO: PropTypes.func
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
    };
}

export default connect(mapStateToProps)(FichaTrazabilidad);