import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';
import { ObtenerSociosEnAPI, ObtenerStockSocioEnAPI, RealizarPedidoSocioEnAPI } from "../action/actionCreatorsRequests"
import 'antd/dist/antd.css';

import { Tabs, Col, Row, Modal, Drawer, Button, Input, Checkbox, List } from 'antd';

import { traducirPagina, convertirMoneda, dynamicSortDesc, dynamicSortAsc, soloNumeros, MSGERROR, etiquetaReferenciaSinStock } from "../librerias/funciones"
import {
    LikeOutlined,
    DislikeOutlined,
    PlusOutlined,
    SmileOutlined,
    FrownOutlined,
    MinusOutlined,
    ShoppingOutlined,
} from '@ant-design/icons';
import { DesgloseStockGrupo } from './DesgloseStockGrupo';
import { DetalleCompraSocio } from './Compra/DetalleCompraSocio';

const { TabPane } = Tabs;

const FichaStockSocio = ({ props, configApp, Z_INDEX = "none" }) => {
    const [modalCompra, setModalCompra] = React.useState({
        ABRIR_MODAL: false,
        REGISTRO_SELECCIONADO: {},
        LOADER_PEDIDO: false,
        RESPUESTA_PEDIDO: "",

        ABRIR_MODAL_PEDIDO: false,
        PAYLOAD_PEDIDO: {
            PEDIDO_POR: "",
            SU_PEDIDO: "",
            OBSERVACIONES: "",
            MAIL_CONFIRMACION: false
        }



    });

    const [hayRegistro, setHayRegistro] = React.useState(false);
    const [registroSocios, setRegistroSocios] = React.useState([]);
    const [abrirDesglose, setAbrirDesglose] = React.useState(false);
    const [abrirCompra, setAbrirCompra] = React.useState(false);






    const RealizaPedidoSocio = (OBJETO_PEDIDO) => {


        modalCompra.LOADER_PEDIDO = true
        setModalCompra({ ...modalCompra })


        RealizarPedidoSocioEnAPI(OBJETO_PEDIDO).then((registro) => {
            modalCompra.LOADER_PEDIDO = false

            if (registro.OK) {
                modalCompra.RESPUESTA_PEDIDO = registro.RESULTADOS
            } else {
                modalCompra.RESPUESTA_PEDIDO = null
            }


            setModalCompra({ ...modalCompra })
        })


    }




    const buscarFechaEntrega = () => {

        ObtenerSociosEnAPI().then((registro) => {
            if (registro !== undefined && registro !== null && registro.SOCIOS !== null && registro.SOCIOS.length > 0) {
                registro.SOCIOS.map((socio) => {
                    socio.PRECIO = null
                    socio.STOCK = null
                    socio.NETO = null
                    socio.DESCUENTO = null
                    socio.COMPRAR = false
                    socio.CANTIDAD = 1


                    socio.ALMACEN = ""
                    socio.CENTRO = ""
                    socio.CLIENTE = ""
                    socio.DESCRIPCION = null
                    socio.EMPRESA = ""
                    socio.ES_NEUMATICO = false
                    socio.MARCA_ERP = ""
                    socio.MARCA_TECDOC = ""
                    socio.REFERENCIA_ERP = ""
                    socio.REFERENCIA_TECDOC = ""
                    socio.TEXTO_LIBRE = false


                })

                setRegistroSocios(registro.SOCIOS)

                ObtenerStockSocio(registro.SOCIOS)

            } else {
                setRegistroSocios(null)
            }
        })
    }


    const ObtenerStockSocio = (LISTA_SOCIO) => {
        const { REFERENCIA_TECDOC, MARCA_TECDOC, TIPO_ARTICULO } = props.DATOS_SELECCIONADO


        let LISTA_SOCIOS_CON_STOCKS = LISTA_SOCIO.map(async (SOCIO) => {

            return await ObtenerStockSocioEnAPI(SOCIO, REFERENCIA_TECDOC, MARCA_TECDOC, TIPO_ARTICULO).then((registro) => {
                if (registro !== undefined && registro !== null && registro.OK) {
                    let ID_REGISTRO = LISTA_SOCIO.findIndex((item) => item.ID_SOCIO === registro.ID_SOCIO);

                    LISTA_SOCIO[ID_REGISTRO].PRECIO = registro.PRECIO
                    LISTA_SOCIO[ID_REGISTRO].STOCK = registro.STOCK
                    LISTA_SOCIO[ID_REGISTRO].NETO = registro.NETO
                    LISTA_SOCIO[ID_REGISTRO].DESCUENTO = registro.DESCUENTO
                    LISTA_SOCIO[ID_REGISTRO].COMPRAR = registro.COMPRAR


                    LISTA_SOCIO[ID_REGISTRO].ALMACEN = registro.ALMACEN
                    LISTA_SOCIO[ID_REGISTRO].CENTRO = registro.CENTRO
                    LISTA_SOCIO[ID_REGISTRO].CLIENTE = registro.CLIENTE
                    LISTA_SOCIO[ID_REGISTRO].DESCRIPCION = registro.DESCRIPCION
                    LISTA_SOCIO[ID_REGISTRO].EMPRESA = registro.EMPRESA
                    LISTA_SOCIO[ID_REGISTRO].ES_NEUMATICO = registro.ES_NEUMATICO
                    LISTA_SOCIO[ID_REGISTRO].MARCA_ERP = registro.MARCA_ERP
                    LISTA_SOCIO[ID_REGISTRO].MARCA_TECDOC = registro.MARCA_TECDOC
                    LISTA_SOCIO[ID_REGISTRO].REFERENCIA_ERP = registro.REFERENCIA_ERP
                    LISTA_SOCIO[ID_REGISTRO].REFERENCIA_TECDOC = registro.REFERENCIA_TECDOC
                    LISTA_SOCIO[ID_REGISTRO].TEXTO_LIBRE = registro.TEXTO_LIBRE

                    setRegistroSocios([...LISTA_SOCIO])
                }
                return registro
            })
        })



        Promise.all(LISTA_SOCIOS_CON_STOCKS).then(registro => {
            LISTA_SOCIO.map((registro) => {
                registro.STOCK = parseInt(registro.STOCK)
            })

            let CON_PRECIO_CON_STOCK = [];
            let SIN_PRECIO_CON_STOCK = [];
            let CON_PRECIO_SIN_STOCK = [];
            let SIN_PRECIO_SIN_STOCK = [];

            CON_PRECIO_CON_STOCK = LISTA_SOCIO.filter((registro) => registro.PRECIO > 0 && registro.STOCK > 0)
            SIN_PRECIO_CON_STOCK = LISTA_SOCIO.filter((registro) => registro.PRECIO === 0 && registro.STOCK > 0)
            CON_PRECIO_SIN_STOCK = LISTA_SOCIO.filter((registro) => registro.PRECIO > 0 && registro.STOCK === 0)
            SIN_PRECIO_SIN_STOCK = LISTA_SOCIO.filter((registro) => registro.PRECIO === 0 && registro.STOCK === 0)


            CON_PRECIO_CON_STOCK.sort(dynamicSortAsc("NETO"))
            SIN_PRECIO_CON_STOCK.sort(dynamicSortDesc("STOCK"))
            CON_PRECIO_SIN_STOCK.sort(dynamicSortAsc("NETO"))
            SIN_PRECIO_SIN_STOCK.sort(dynamicSortDesc("NOMBRE"))

            const LISTA_PRECIOS_FINAL = [...CON_PRECIO_CON_STOCK, ...SIN_PRECIO_CON_STOCK, ...CON_PRECIO_SIN_STOCK, ...SIN_PRECIO_SIN_STOCK]

            setRegistroSocios([...LISTA_PRECIOS_FINAL])

        })
    }

    const gestionarCompra = (REGISTROS) => {
        modalCompra.ABRIR_MODAL_PEDIDO = true;


        let LISTA_ALMACENES = REGISTROS.filter((item) => item.CANTIDAD > 0).map((registro) => {
            return { ALMACEN: registro.CodigoAlmacen, CANTIDAD: registro.CANTIDAD, NOMBRE_ALMACEN: registro.DescripcionAlmacen }
        })


        let objeto = JSON.parse(JSON.stringify(modalCompra.REGISTRO_SELECCIONADO))
        objeto.ALMACENES = LISTA_ALMACENES
        modalCompra.REGISTRO_SELECCIONADO = objeto




        setModalCompra({ ...modalCompra })
    }


    if (!hayRegistro && props.ABRIR_FICHA) {
        setHayRegistro(true);

        buscarFechaEntrega();


    } else {
        if (!props.ABRIR_FICHA && hayRegistro) {
            setHayRegistro(false);
        }
    }



    return (
        <>
            <Drawer
                width={1300}
                onClose={() => { props.CERRAR_FICHA(false) }}
                visible={props.ABRIR_FICHA}
                closable={false}

                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff', zIndex: Z_INDEX }}>

                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { props.CERRAR_FICHA(false) }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>

                <div className="p-3" style={{ height: (configApp.height - 70).toString() + "px", overflow: 'auto' }}>
                    {
                        registroSocios !== null ?
                            registroSocios.length > 0 ?
                                <MDBTable responsive hover>
                                    <MDBTableHead>
                                        <tr>
                                            <th style={{ textAlign: 'left', fontWeight: '500' }}>{traducirPagina("Socio").toUpperCase()}</th>
                                            {/*obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE || obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD ? null : <th style={{ textAlign: 'right', width: '100px', fontWeight: '500' }}>{traducirPagina("Cantidad").toUpperCase()}</th>*/}

                                            <th style={{ textAlign: 'center', width: '100px', fontWeight: '500' }}>{traducirPagina("Precio").toUpperCase()}</th>
                                            <th style={{ textAlign: 'center', width: '50px', fontWeight: '500' }}>{"C"}</th>

                                            <th style={{ textAlign: 'center', width: '100px', fontWeight: '500' }}>{"DESCUENTO"}</th>
                                            <th style={{ textAlign: 'center', width: '100px', fontWeight: '500' }}>{traducirPagina("Neto").toUpperCase()}</th>

                                            <th style={{ textAlign: 'center', width: '100px', fontWeight: '500' }}>{traducirPagina("Cantidad").toUpperCase()}</th>
                                            <th style={{ textAlign: 'center', width: '100px', fontWeight: '500' }}>{traducirPagina("Disponibilidad").toUpperCase()}</th>
                                            <th style={{ textAlign: 'center', width: '100px', fontWeight: '500' }}></th>

                                            <th style={{ textAlign: 'center', width: '100px', fontWeight: '500' }}></th>
                                            <th style={{ textAlign: 'center', width: '50px', fontWeight: '500' }}></th>

                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {registroSocios.map((item, i) =>
                                            <tr key={i} style={{ color: item.DefectoCliente ? "blue" : "", fontWeight: item.DefectoCliente ? 'bold' : "" }}>
                                                <td style={{ textAlign: 'left', color: item.CODIGO_CLIENTE === "" ? "#666" : "#000" }}>{item.NOMBRE}</td>

                                                <td style={{ textAlign: 'right', color: item.CODIGO_CLIENTE === "" ? "#666" : "#000" }}>{item.PRECIO === null ? <span className="skeleton-box" style={{ width: '50px' }}></span> : item.PRECIO === 0 ? "" : convertirMoneda(item.PRECIO, "€")}</td>

                                                <td style={{ textAlign: 'right' }}>{item.CODIGO_CLIENTE === "" ? <FrownOutlined style={{ fontSize: '22px', color: 'red' }}></FrownOutlined> : <SmileOutlined style={{ fontSize: '22px', color: 'green' }}></SmileOutlined>}</td>

                                                <td style={{ textAlign: 'right', color: item.CODIGO_CLIENTE === "" ? "#666" : "#000" }}>{item.DESCUENTO === null ? <span className="skeleton-box" style={{ width: '50px' }}></span> : convertirMoneda(item.DESCUENTO, "%")}</td>
                                                <td style={{ textAlign: 'right', color: item.CODIGO_CLIENTE === "" ? "#666" : "#000" }}>{item.NETO === null ? <span className="skeleton-box" style={{ width: '50px' }}></span> : item.NETO === 0 ? "" : convertirMoneda(item.NETO, "€")}</td>

                                                <td style={{ textAlign: 'right', width: '100px', color: item.CODIGO_CLIENTE === "" ? "#666" : "#000" }}>{item.STOCK === null ? <span className="skeleton-box" style={{ width: '50px' }}></span> : item.STOCK}</td>

                                                <td style={{ textAlign: 'center', width: '100px' }}>
                                                    <button className="activebtnMenu2" style={{ border: '0', borderRadius: '3px', fontSize: '13px', color: parseInt(item.STOCK) === 0 ? "red" : '#25c281', backgroundColor: 'transparent', verticalAlign: 'text-bottom' }}>
                                                        {parseInt(item.STOCK) === 0 ? <DislikeOutlined style={{ fontSize: '20px' }}></DislikeOutlined> : <LikeOutlined style={{ fontSize: '20px' }} />}  </button>
                                                </td>

                                                <td style={{ textAlign: 'center', width: '100px' }}>
                                                    <button className='btnDesglesGrupo' onClick={(e) => {
                                                        //ver desglose
                                                        setAbrirDesglose(true)

                                                        modalCompra.REGISTRO_SELECCIONADO = item
                                                        setModalCompra({ ...modalCompra })
                                                    }}>{traducirPagina("txt_desglose")}</button>
                                                </td>

                                                <td>
                                                    {item.COMPRAR ?
                                                        <span className="next-number-picker next-number-picker-inline next-medium zoro-ui-number-picker number-picker product-number-picker">
                                                            <span className="next-input-group">
                                                                <span className="next-input-group-addon next-before">
                                                                    <button disabled="" type="button" className="next-btn next-medium next-btn-normal" role="button" style={{ height: '27px', width: '27px' }} onClick={() => {
                                                                        if (item.CANTIDAD <= 1) {
                                                                            item.CANTIDAD = 1;
                                                                        } else {
                                                                            item.CANTIDAD = item.CANTIDAD - 1;
                                                                        }

                                                                        setRegistroSocios([...registroSocios])

                                                                    }}>
                                                                        <MinusOutlined style={{ fontSize: '12px' }} />
                                                                    </button>
                                                                </span>
                                                                <span className="next-input next-medium next-input-group-auto-width">
                                                                    <input value={item.CANTIDAD} className="txtCantidad" aria-valuemax="1798" aria-valuemin="1" height="100%" autoComplete="off" style={{ width: '50px', margin: '2px 5px', height: '24px' }} onChange={(e) => {
                                                                        let valor = e.target.value;
                                                                        if (valor === "") {
                                                                            item.CANTIDAD = e.target.value;
                                                                        } else {
                                                                            if (soloNumeros(valor)) {
                                                                                item.CANTIDAD = valor === "0" ? 1 : parseInt(e.target.value)
                                                                            } else {
                                                                                item.CANTIDAD = 1;
                                                                            }
                                                                        }
                                                                        setRegistroSocios([...registroSocios])

                                                                    }} />
                                                                </span>
                                                                <span className="next-input-group-addon next-after">
                                                                    <button type="button" className="next-btn next-medium next-btn-normal" role="button" data-spm-anchor-id="a2g0o.detail.1000016.i3.759e707cqToxAp" style={{ height: '27px', width: '27px' }} onClick={() => {
                                                                        item.CANTIDAD = item.CANTIDAD + 1;
                                                                        setRegistroSocios([...registroSocios])
                                                                    }}
                                                                    >
                                                                        <PlusOutlined style={{ fontSize: '12px' }} />
                                                                    </button>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        :
                                                        null
                                                    }
                                                </td>

                                                <td style={{ textAlign: 'center' }}>{item.COMPRAR ? <ShoppingOutlined style={{ fontSize: '22px', color: '#000' }} onClick={() => {
                                                    if (item.CANTIDAD === "") {
                                                        MSGERROR(traducirPagina("ingrese_cantidad"))
                                                    } else if (parseInt(item.STOCK) === 0 ? false : parseInt(item.CANTIDAD) > parseInt(item.STOCK)) {
                                                        MSGERROR(`${traducirPagina("cantidad_maxima")} ${item.STOCK}`)
                                                    } else {

                                                        //modalCompra.ABRIR_MODAL_PEDIDO = true;
                                                        modalCompra.REGISTRO_SELECCIONADO = item
                                                        setAbrirCompra(true)
                                                        setModalCompra({ ...modalCompra })
                                                    }

                                                }}></ShoppingOutlined> : null}</td>

                                            </tr>
                                        )
                                        }
                                    </MDBTableBody>
                                </MDBTable>
                                :
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#000' }}> <div color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                            :
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{etiquetaReferenciaSinStock()}</h3> </div>
                    }
                </div>
            </Drawer>



            {modalCompra.ABRIR_MODAL ?
                <Modal
                    visible={modalCompra.ABRIR_MODAL}
                    style={modalCompra.LOADER_PEDIDO ? { pointerEvents: 'none', opacity: '0.8', zIndex: "9999" } : { zIndex: "9999" }}
                    width={700}
                    centered
                    afterClose={() => {

                        modalCompra.ABRIR_MODAL = false
                        modalCompra.REGISTRO_SELECCIONADO = {}
                        modalCompra.LOADER_PEDIDO = false
                        modalCompra.RESPUESTA_PEDIDO = ""
                        modalCompra.PAYLOAD_PEDIDO = {
                            PEDIDO_POR: "",
                            SU_PEDIDO: "",
                            OBSERVACIONES: "",
                            MAIL_CONFIRMACION: false
                        }

                        setModalCompra({ ...modalCompra })
                        setAbrirCompra(false)


                    }}
                    title={traducirPagina("AVISO_COMPRA")}
                    onCancel={() => {
                        if (!modalCompra.LOADER_PEDIDO) {

                            modalCompra.ABRIR_MODAL = false
                            modalCompra.REGISTRO_SELECCIONADO = {}
                            modalCompra.LOADER_PEDIDO = false
                            modalCompra.RESPUESTA_PEDIDO = ""
                            modalCompra.PAYLOAD_PEDIDO = {
                                PEDIDO_POR: "",
                                SU_PEDIDO: "",
                                OBSERVACIONES: "",
                                MAIL_CONFIRMACION: false
                            }

                            setModalCompra({ ...modalCompra })

                        }
                    }}
                    footer={[
                        <Button key="back" onClick={() => {
                            if (!modalCompra.LOADER_PEDIDO) {
                                modalCompra.ABRIR_MODAL = false
                                modalCompra.REGISTRO_SELECCIONADO = {}
                                modalCompra.LOADER_PEDIDO = false
                                modalCompra.RESPUESTA_PEDIDO = ""
                                modalCompra.PAYLOAD_PEDIDO = {
                                    PEDIDO_POR: "",
                                    SU_PEDIDO: "",
                                    OBSERVACIONES: "",
                                    MAIL_CONFIRMACION: false
                                }

                                setModalCompra({ ...modalCompra })

                            }
                        }}>
                            {traducirPagina("Salir")}
                        </Button>,

                        <Button style={{ backgroundColor: '#000', display: modalCompra.RESPUESTA_PEDIDO === "" || modalCompra.RESPUESTA_PEDIDO === null ? '' : 'none' }} key="submit" type="primary" onClick={() => {
                            if (!modalCompra.LOADER_PEDIDO) {
                                RealizaPedidoSocio({ ...modalCompra.REGISTRO_SELECCIONADO, ...modalCompra.PAYLOAD_PEDIDO })
                            }

                        }}>{modalCompra.LOADER_PEDIDO ? `${traducirPagina("Realizando_pedido")} ...` : traducirPagina("Continuar")} </Button>

                    ]}>


                    <Row style={{ color: '#000', fontSize: '18px', fontWeight: '500' }}>
                        <Col md={24} className="mb-2">


                            {modalCompra.RESPUESTA_PEDIDO !== undefined && modalCompra.RESPUESTA_PEDIDO !== null && modalCompra.RESPUESTA_PEDIDO !== "" ?
                                <List
                                    header={<div style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>{traducirPagina("Seguimiento_pedidos").toUpperCase()} </div>}
                                    bordered
                                    dataSource={modalCompra.RESPUESTA_PEDIDO}
                                    renderItem={pedido => (
                                        <List.Item>
                                            <div style={{ fontSize: '18px' }}>
                                                <div><span style={{ fontWeight: 'bold' }}>{`${traducirPagina("Almacen").toUpperCase()}:`}</span>   <span>{pedido.ALMACEN}</span> </div>
                                                <div><span style={{ fontWeight: 'bold', color: pedido.REALIZADO ? '#000' : '#b41e23' }}>{(pedido.REALIZADO ? traducirPagina("Pedido_realizado").toUpperCase() : pedido.MENSAJE_ERROR !== undefined && pedido.MENSAJE_ERROR !== null ? `${traducirPagina("Pedido_no_realizado").toUpperCase()}: ${pedido.MENSAJE_ERROR.toUpperCase()}` : "")}</span>  </div>
                                                <div><span style={{ fontWeight: 'bold' }}>{`${traducirPagina("Codigo_pedido").toUpperCase()}:`}</span>  <span>{pedido.CODIGO_PEDIDO}</span> </div>
                                            </div>

                                        </List.Item>

                                    )}
                                />
                                :
                                <span style={{ color: '#000' }}>{traducirPagina("Msg_realizar_pedido")}</span>
                            }
                        </Col>
                    </Row>

                </Modal>
                :
                null
            }





            {modalCompra.ABRIR_MODAL_PEDIDO ?
                <Modal
                    visible={modalCompra.ABRIR_MODAL_PEDIDO}
                    width={700}
                    centered
                    title={traducirPagina("Confirmación_pedido")}
                    afterClose={() => {
                    }}



                    onCancel={() => {

                        modalCompra.ABRIR_MODAL_PEDIDO = false;

                        modalCompra.PAYLOAD_PEDIDO = {
                            PEDIDO_POR: "",
                            SU_PEDIDO: "",
                            OBSERVACIONES: "",
                            MAIL_CONFIRMACION: false
                        }

                        setModalCompra({ ...modalCompra });

                    }}
                    footer={[

                        <Button className="btnColor" key="submit" type="primary" onClick={() => {
                            modalCompra.ABRIR_MODAL_PEDIDO = false;


                            modalCompra.ABRIR_MODAL = true;
                            //modalCompra.REGISTRO_SELECCIONADO = item
                            setModalCompra({ ...modalCompra })

                        }}>{traducirPagina("Finalizar_pedido")} </Button>

                    ]}>
                    <Row>

                        <Col md={12} >
                            <span>{traducirPagina("Pedido_por") + ": "} </span>
                        </Col>
                        <Col md={12}>
                            <span>{traducirPagina("Numero_pedido")}</span>
                        </Col>
                        <Col md={12} >
                            <Input name="pedidoPor" value={modalCompra.PAYLOAD_PEDIDO.PEDIDO_POR} onChange={(e) => { modalCompra.PAYLOAD_PEDIDO.PEDIDO_POR = e.target.value; setModalCompra({ ...modalCompra }) }} style={{ width: '320px' }} />
                        </Col>
                        <Col md={12}>
                            <Input name="numeroPedido" value={modalCompra.PAYLOAD_PEDIDO.SU_PEDIDO} onChange={(e) => { modalCompra.PAYLOAD_PEDIDO.SU_PEDIDO = e.target.value; setModalCompra({ ...modalCompra }) }} style={{ width: '320px' }} />
                        </Col>
                        <Col md={24} className="mt-2">
                            <span>{traducirPagina("Observaciones")} </span>
                        </Col>

                        <Col md={24}>
                            <Input.TextArea name="observacionesPedido" value={modalCompra.PAYLOAD_PEDIDO.OBSERVACIONES} onChange={(e) => { modalCompra.PAYLOAD_PEDIDO.OBSERVACIONES = e.target.value; setModalCompra({ ...modalCompra }) }} rows={5} style={{ width: '645px' }} />
                        </Col>

                        <Col md={12} className="mt-2">
                        </Col>

                        <Col md={12} className="mt-2">
                            <Checkbox name="recibirMailPedido" checked={modalCompra.PAYLOAD_PEDIDO.MAIL_CONFIRMACION} onChange={(e) => { modalCompra.PAYLOAD_PEDIDO.MAIL_CONFIRMACION = e.target.checked; setModalCompra({ ...modalCompra }) }} > {traducirPagina("Recibir_email")}   </Checkbox>
                        </Col>

                    </Row>
                </Modal>
                :
                null
            }

            {
                
                abrirDesglose &&
                <DesgloseStockGrupo
                    title={traducirPagina("Desglose_disponibilidad").toUpperCase()}
                    ABRIR_FICHA={abrirDesglose}
                    CERRAR_FICHA={setAbrirDesglose}

                    REFERENCIA_FABRICANTE={""}
                    REFERENCIA_ERP={""}
                    EAN={""}
                    ID_MARCA={""}

                    DATOS_SELECCIONADO={props.DATOS_SELECCIONADO}
                    SOCIO_SELECCIONADO={modalCompra.REGISTRO_SELECCIONADO}
                    Z_INDEX={Z_INDEX}

                >

                </DesgloseStockGrupo>
                
            }

            {
                
                abrirCompra &&
                <DetalleCompraSocio
                    title={traducirPagina("Desglose_disponibilidad").toUpperCase()}
                    ABRIR_FICHA={abrirCompra}
                    CERRAR_FICHA={setAbrirCompra}

                    DATOS_SELECCIONADO={props.DATOS_SELECCIONADO}
                    SOCIO_SELECCIONADO={modalCompra.REGISTRO_SELECCIONADO}
                    ES_CESTA_COMPRA={false}
                    EVENTRO_COMPRAR={gestionarCompra}

                    Z_INDEX={Z_INDEX}
                >

                </DetalleCompraSocio>
                
            }





        </>

    )
}

FichaStockSocio.propTypes = {
    title: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    DATOS_SELECCIONADO: PropTypes.object,


    marca: PropTypes.number,
    referenciaProveedor: PropTypes.string,
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,

    };
}

export default connect(mapStateToProps)(FichaStockSocio);