import React from 'react';
import axios from 'axios';
import history from '../history';
import store from '../store';
import { updateStateMasterPage, onExpand, generateList, estadoAgrupacionDeMarcas } from "../action/actionCreators";
import alertify from 'alertifyjs';
import {
    obtenerToken,
    MSGERROR,
    buscarPrecioRefOE,
    obtenerDatosUsuarioYLicenciaActual,
    mostarReferenciasEnPantalla,
    obtenerIdioma,
    traducirPagina,
    obtenerTipoDeBusquedaActual,
    verificarSiEsMostrador,
    actualizarNotificaciones,
    permisoMotos,
    OBTENER_TIPO_BUSQUEDA,
    OBTENER_ID_MOTO,
    idModeloLatam,
    gestionarDiccionarios
} from '../librerias/funciones'



let endpoint1 = "";
let controller = new AbortController();


const ObtenerConfiguracion = () => {
    controller = new AbortController();
    return fetch("./configuracion/config.json",)
        .then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                endpoint1 = res.ENDPOINT
                store.dispatch({ type: "STATE_DIRECTORIO_DOCUMENTO", DIRECTORIO_DOCUMENTO: res.DIRECTORIO_DOCUMENTO });
                return res
            }
        })
}

ObtenerConfiguracion();


const loadBrands = () => {
    let fichaVehiculo = store.getState().fichaBusquedaVehiculo.allManufactur;
    return dispatch => {
        if (Object.keys(fichaVehiculo).length > 0) {
            dispatch({
                type: "REPLACE_BRANDS",
                objetoGenerico: fichaVehiculo
            })
        } else {
            var qs = require('qs');
            axios({
                method: 'post',
                url: endpoint1 + 'api/Vehiculo/getFabricantes',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({ "TipoVehiculo": 1, "Token": obtenerToken() })
            }).then(function (res) {
                if (!res.data.OK) {
                    MSGERROR(res.data.MENSAJE_ERROR)
                }

                dispatch({
                    type: "REPLACE_BRANDS",
                    objetoGenerico: res.data.OK ? { original: res.data.MARCASFABRICANTES, aux: res.data.MARCASFABRICANTES } : {}
                })
            })
        }
    }
}

const getModelVehicle = (brands, TIPO_VEHICULO) => {
    return dispatch => {
        axios({
            method: 'post',
            url: endpoint1 + 'api/Vehiculo/getModelosTurismos',
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                "FABRICANTE": (brands.id === undefined ? brands.ID : brands.id),
                "TIPO_VEHICULO": TIPO_VEHICULO,
                "Token": obtenerToken()
            })
        }).then(function (res) {
            if (!res.data.OK) {
                MSGERROR(res.data.MENSAJE_ERROR)
            }

            dispatch({
                type: "REPLACE_VEHICULO_IDMARCA",
                idmarca: brands.id === undefined ? brands.ID : brands.id
            })

            dispatch({
                type: "GET_MODEL_VEHICLE",
                objetoGenerico: { original: res.data.MODELOSTURISMOS, aux: res.data.MODELOSTURISMOS },
            })
        })
    }
}

const getEngineType = (model, TIPO_VEHICULO) => {
    return dispatch => {
        axios({
            method: 'post',
            url: endpoint1 + 'api/Vehiculo/getMotorizacionesTurismos',
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({ "TIPO_VEHICULO": TIPO_VEHICULO, "MODELO": model.ID, "Token": obtenerToken() })
        }).then(function (res) {
            if (!res.data.OK) {
                MSGERROR(res.data.MENSAJE_ERROR)
            }
            var campos = store.getState().identificadorHaynesPro;
            dispatch({
                type: "REPLACE_VEHICULO_IDMODELO",
                idmodelo: model.ID
            })
            /*
            dispatch({
                type: "GET_ENGINE_TYPE",
                objetoGenerico: res.data.OK ? { original: res.data.MOTORESTURISMOS, aux: res.data.MOTORESTURISMOS } : { original: [], aux: [] },
                identificadorHaynesPro: campos,
            })
            */
            dispatch({
                type: "GET_ENGINE_TYPE",
                objetoGenerico: { original: res.data.MOTORESTURISMOS, aux: res.data.MOTORESTURISMOS },
                identificadorHaynesPro: campos,
            })
        })

    }
}

const getIdTecdocMotorByIdHaynes = (codigo, registros) => {

    const { ID_MODELO_CATALOGO } = registros === undefined || registros === null ? { ID_MODELO_CATALOGO: null } : registros

    return dispatch => {
        store.dispatch({ type: "LIST_TREE", listaTree: [] });
        store.dispatch(getListTree(codigo, ID_MODELO_CATALOGO))

        dispatch({
            type: "SET_ID_MOTORIZACION",
            codmotorizacion: codigo,
        })
    }
}




const buscarReferenciaEnAPI = (referenciaABuscar) => {
    controller = new AbortController();

    let licenciaActual = obtenerDatosUsuarioYLicenciaActual();
    let cliente = store.getState().clienteSeleccionado;
    //"Cliente": licenciaActual.CLIENTE,
    return fetch(endpoint1 + 'Api/Articulo/ObtenerArticulosByArtNo', {
        method: 'POST', // or 'PUT'
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                "idSocio": licenciaActual.ID_SOCIO,
                "Empresa": licenciaActual.EMPRESA,
                "Cliente": cliente.codigo,
                "ARTNO": referenciaABuscar,
                "Token": obtenerToken()
            }),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return null;
                } else {
                    return res;
                }
            }
        })
}


const buscarDetallesReferencia = (referencia) => {

    controller = new AbortController();
    let crearLista = new Array();
    let cliente = store.getState().clienteSeleccionado;
    let codmotorizacion = store.getState().codmotorizacion;
    let obtenerIdVehiculo = codmotorizacion === "" ? 0 : codmotorizacion;



    let TIPO_VEHICULO_POR_MODELO = store.getState().TIPO_VEHICULO_POR_MODELO;
    let TIPO_VEH = TIPO_VEHICULO_POR_MODELO === "" ? store.getState().BusquedaPorModelo.tipoBusqueda : TIPO_VEHICULO_POR_MODELO;

    if (referencia.ES_MOTO !== undefined && referencia.ES_MOTO !== null && referencia.ES_MOTO) {
        TIPO_VEH = "MT"
    }


    crearLista.push({ "REFERENCIA": referencia.REFERENCIA.toString(), "MARCA": referencia.CODIGO_MARCA, "CodigoRefereciaERP": referencia.REFERENCIA_PROVEEDOR })

    let payloadPorDefecto = {
        TIPO: TIPO_VEH,
        ID_VEHICULO: obtenerIdVehiculo,
        ARTICULOS: crearLista,
        CLIENTE: cliente.codigo,
        Token: obtenerToken()
    }

    const { TIPO_VEHICULO, CODIGO_MOTOR, ID_GRUPO_MONTAJE, CATALOGO } = referencia
    payloadPorDefecto.CATALOGO = CATALOGO !== undefined && CATALOGO !== null ? CATALOGO : ""

    if (TIPO_VEHICULO !== undefined && TIPO_VEHICULO !== null && TIPO_VEHICULO !== "" && TIPO_VEHICULO === "ARTICULOS_SUGERIDOS") {
        payloadPorDefecto.TIPO = TIPO_VEHICULO
        payloadPorDefecto.CODIGO_MOTOR = CODIGO_MOTOR
        payloadPorDefecto.ID_GRUPO_MONTAJE = ID_GRUPO_MONTAJE
    }



    return fetch(endpoint1 + 'api/Articulo/getPropertiesByReferencias', {
        method: 'POST', // or 'PUT'
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payloadPorDefecto),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {

                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return undefined;
                    //dispatch({ type: "STATE_BRANDS_GROUPING", grupoDeMarcas: { marcas: [] } })
                } else {

                    if (res.PROPERTIES !== null) {
                        return res.PROPERTIES[0];
                    } else {
                        return undefined;
                    }
                }
            }
        })
}

const buscarPrecioReferencia = (referencia) => {
    controller = new AbortController();
    let cliente = store.getState().clienteSeleccionado;
    return fetch(endpoint1 + 'api/Articulo/ObtenerPrecioReferenciaERP', {
        method: 'POST', // or 'PUT'
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "CODIGO_REFERENCIA_ERP": referencia.REFERENCIA_PROVEEDOR.toString(), "CLIENTE": cliente.codigo, "Token": obtenerToken() }),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            let REGISTRO = undefined
            if (res !== undefined && res !== null) {
                if (res.OK) {
                    REGISTRO = res.PRECIO !== null ? res.PRECIO : undefined
                } else {
                    MSGERROR(res.MENSAJE_ERROR)
                }
            }

            return REGISTRO

        })
}


const getListSuplier = (nodeId, ES_MOTO) => {
    let codmotorizacion = store.getState().codmotorizacion;
    let cliente = store.getState().clienteSeleccionado;
    let ID_MODELO_CATALOGO = OBTENER_ID_MOTO()
    if (ID_MODELO_CATALOGO === undefined || ID_MODELO_CATALOGO === null) {
        ID_MODELO_CATALOGO = idModeloLatam()
    }



    let grupoMarcas = store.getState().grupoDeMarcas;
    if (Object.keys(grupoMarcas).length > 0) {
        abortFetch();
    }
    controller = new AbortController();

    let TIPO_VEHICULO_POR_MODELO = store.getState().TIPO_VEHICULO_POR_MODELO;
    let TIPO_VEH = TIPO_VEHICULO_POR_MODELO === "" ? store.getState().BusquedaPorModelo.tipoBusqueda : TIPO_VEHICULO_POR_MODELO;


    if (ES_MOTO) {
        TIPO_VEH = "MT";
    }

    store.dispatch(estadoAgrupacionDeMarcas({ "marcas": [] }))
    store.dispatch({ type: "UPDATE_PAGINATION", pagination: {} });

    fetch(endpoint1 + 'api/Extructura/ObtenerReferenciasByNodoTreeAndID', {
        method: 'POST', // or 'PUT'
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "TIPO": TIPO_VEH,
            "NODO_ID": nodeId.toString(),
            "ID": codmotorizacion,
            "ID_MODELO_CATALOGO": ID_MODELO_CATALOGO,
            "CLIENTE": cliente.codigo,
            "Token": obtenerToken()
        }),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (res.OK) {
                    mostarReferenciasEnPantalla(res.ARTICULOSNODOS, res.CON_LADO_MONTAJE)
                } else {
                    MSGERROR(res.MENSAJE_ERROR)
                }
            }
        })

}



const pad = (num, size) => {
    return (Math.pow(10, size) + ~~num).toString().substring(1);
}



const putSertecHistorico = (Parametro, idmodulo) => {
    var getdatauser = localStorage.getItem("usersertec");
    getdatauser = JSON.parse(getdatauser)
    var qs = require('qs');

    var objeto = { "Usuario": getdatauser.Usuario, "idioma": 'es', "idAPP": 3, "idAPPModulo": idmodulo, "Parametro": Parametro };
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };

    axios.post(endpoint1 + "Api/SertecHistorico/putSertecHistorico", qs.stringify(objeto), { headers });
}



const loginByHaynesPro = () => {
    return dispatch => {
        dispatch({
            type: "CLEAR_URL_HAYNESPRO",
            urlhaynes: ""
        })

        var qs = require('qs');
        axios({
            method: 'post',
            url: endpoint1 + 'Api/HaynesPro/getHPUrl',
            headers: {
                'Content-Type': 'application/json',
            },
            data: qs.stringify({
                companyIdentificaton: "serca_demo_sso",
                distributorPassword: "acaU6C4emc8asKHw",
                username: "daniel@serca.es"
            })

        }).then(function (res) {
            dispatch({
                type: "REPLACE_URL_HAYNESPRO",
                urlhaynes: res.data
            })
        });
    }
}


const getListTree = (tecdocId, ID_MODELO_CATALOGO) => {
    ID_MODELO_CATALOGO = ID_MODELO_CATALOGO === undefined || ID_MODELO_CATALOGO === "" ? null : ID_MODELO_CATALOGO


    //store.dispatch({ type: "UPDATE_PAGINATION", pagination: {} });


    if (ID_MODELO_CATALOGO === null) {
        ID_MODELO_CATALOGO = idModeloLatam()
    }



    let TIPO = obtenerTipoDeBusquedaActual();

    return dispatch => {
        axios({
            method: 'post',
            url: endpoint1 + "Api/Extructura/getGMbyidVehicle",
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                ID_VEHICLE: tecdocId,
                ID_MODELO_CATALOGO: ID_MODELO_CATALOGO,
                TIPO: TIPO,
                Token: obtenerToken()
            })
        }).then(function (result) {
            let crearTree = [];
            if (result !== undefined) {
                if (result.data.OK) {

                    if (result.data.TREE_ESTRUCTURA !== null && result.data.TREE_ESTRUCTURA.length > 0) {
                        result.data.TREE_ESTRUCTURA.map((i) => {
                            let nivel2 = [];
                            i.ESTRUCTURAS.map((x) => {
                                let nivel3 = [];
                                x.ESTRUCTURAS.map((z) => {
                                    let nivel4 = [];
                                    z.ESTRUCTURAS.map((y) => {
                                        let nivel5 = [];
                                        if (y.ESTRUCTURAS !== null) {

                                            y.ESTRUCTURAS.map((m) => {
                                                nivel5.push({ title: m.Term, key: m.NODE_ID, level: m.NIVEL, sortnr: m.NIVEL, children: [], ES_MOTO: m.ES_MOTO })
                                            })

                                        }
                                        nivel4.push({ title: y.Term, key: y.NODE_ID, level: y.NIVEL, sortnr: y.NIVEL, children: nivel5, ES_MOTO: y.ES_MOTO })
                                    })
                                    nivel3.push({ title: z.Term, key: z.NODE_ID, level: z.NIVEL, sortnr: z.NIVEL, children: nivel4, ES_MOTO: z.ES_MOTO })
                                })
                                nivel2.push({ title: x.Term, key: x.NODE_ID, level: x.NIVEL, sortnr: x.NIVEL, children: nivel3, ES_MOTO: x.ES_MOTO })
                            })
                            crearTree.push({ title: i.Term, key: i.NODE_ID, level: i.NIVEL, sortnr: i.NIVEL, children: nivel2, ES_MOTO: i.ES_MOTO })
                        })
                    }

                } else {
                    MSGERROR(result.data.MENSAJE_ERROR)
                }
            }

            //console.log("LISTA_FINAL",crearTree)
            store.dispatch(onExpand(generateList(crearTree)))
            dispatch({
                type: "LIST_TREE",
                listaTree: crearTree
            })
            store.dispatch({ type: "LISTA_ESTRUCTURA", listaEstructura: result.data.LISTA_ESTRUCTURA })


        });
    }
}




const getFichaCocheEnAPI = (id) => {
    let ID_MODELO = idModeloLatam()
    return axios({
        method: 'post',
        url: endpoint1 + "api/Vehiculo/getPropertiesbyidVehicle",
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify({
            "ID_VEHICULO": id,
            ID_MODELO_CATALOGO: ID_MODELO,
            Token: obtenerToken()
        })
    }).then(function (res) {
        if (!res.data.OK) {
            MSGERROR(res.data.MENSAJE_ERROR)
            return res.data.DETALLEVEHICULO;
        } else {
            return res.data.DETALLEVEHICULO;
        }
    });
}



export const getDiccionarioEnAPI = (TOKEN) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/Diccionario', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: TOKEN
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const getDataUser = (user, password) => {
    ObtenerConfiguracion().then(empresa => {
        if (empresa !== null) {

            axios({
                method: 'post',
                url: empresa.ENDPOINT + 'api/Login/getloginSERTEC',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({ "USUARIO": user, "PWD": password })
            }).then(async function (res) {
                if (res.data.OK) {
                    if (res.data.LoginSertec !== null) {
                        const { ID_PAIS, PAISES } = res.data.LoginSertec

                        //GÉSTION DIICIONARIOS
                          await  gestionarDiccionarios(res.data.Token)


                        if (res.data.LoginSertec.List_LicenciaSERTE !== undefined && res.data.LoginSertec.List_LicenciaSERTE.length > 0) {
                            store.dispatch({ type: "STATE_FILTRO_GRUPO_POR_DEFECTO", FiltroGrupoPorDefecto: res.data.LoginSertec.List_LicenciaSERTE[0].MOSTRAR_BOTON_POR_DEFECTO })
                        }

                        //Elimino el json de user porque hay conflicto con otras aplicaciones.
                        localStorage.setItem('usersertec', JSON.stringify(res.data));
                        localStorage.setItem('tokensertec', res.data.Token);

                        store.dispatch(
                            {
                                type: "UPDATE_IDIOMA_SERTEC",
                                idiomaActual: obtenerIdioma(res.data.LoginSertec)
                            }
                        )


                        let REGISTRO_BANDERA = PAISES.find((item) => item.ID_PAIS === ID_PAIS)
                        //Condición si el pais no existe se pone por defecto 1 de españa
                        if (REGISTRO_BANDERA === undefined) {
                            REGISTRO_BANDERA = PAISES.find((item) => item.ID_PAIS === 1)
                        }

                        const { URL_IMAGEN, CODIGO_TECDOC } = REGISTRO_BANDERA

                        if (REGISTRO_BANDERA !== undefined) {
                            store.dispatch({ type: "REPLACE_BANDERA_PAIS", banderaImagen: URL_IMAGEN, codPaisIdioma: CODIGO_TECDOC })
                        } else {
                            store.dispatch({ type: "REPLACE_BANDERA_PAIS", banderaImagen: "https://test.servicenext.eu/PAISES/ESP.png", codPaisIdioma: "E" })
                        }

                        store.dispatch({ type: "STATE_SELECCION_DROP", NAME_Drop: traducirPagina("Matricula"), IDENTIFICADOR: "MAT" })
                        store.dispatch(
                            {
                                type: "SET_SESION_USER",
                                usuario: res.data
                            }
                        )

                        GetEmpresaEnAPI().then(empresa => {
                            if (empresa !== undefined) {
                                if (empresa !== null && empresa.length > 0) {
                                    store.dispatch({ type: "STATE_MODIFICAR_IVA_FACTURACION", ivaPorDefecto: empresa[0].CIENTO_IVA })
                                    store.dispatch({ type: "STATE_MODIFICAR_MANO_OBRA_FACTURACION", manoObraPorDecto: empresa[0].PRECIO_MANO_OBRA })
                                }
                            }
                        })

                        GetUsuariosEnAPI().then(usuario => {
                            if (usuario !== undefined) {
                                if (usuario !== null && usuario.length > 0) {
                                    store.dispatch({ type: "STATE_MODIFICAR_EMAIL_ENVIO_DOCUMENTO", emailEnvioDocumento: usuario[0].CORREO_ENVIO_DOCUMENTOS })

                                }
                            }
                        })

                        if (verificarSiEsMostrador()) {
                            ObtenerClientesEnAPI().then(cliente => {
                                store.dispatch({ type: "STATE_PRELOADAER", PreLoaderPage: true })

                                if (cliente !== null && cliente.length > 0) {
                                    store.dispatch({ type: "STATE_CLIENTES_MOSTRADOR", ClientesMostrador: cliente })
                                } else {
                                    store.dispatch({ type: "STATE_CLIENTES_MOSTRADOR", ClientesMostrador: null })
                                }
                            })
                        } else {
                            store.dispatch({ type: "STATE_PRELOADAER", PreLoaderPage: true })
                        }

                        if (res.data.LoginSertec.List_LicenciaSERTE !== undefined && res.data.LoginSertec.List_LicenciaSERTE.length > 0) {
                            if (res.data.LoginSertec.List_LicenciaSERTE[0].PERMISO_MATRICULAS) {
                                store.dispatch({ type: "STATE_SELECCION_DROP", NAME_Drop: traducirPagina("Matricula"), IDENTIFICADOR: "MAT" })

                            } else if (res.data.LoginSertec.List_LicenciaSERTE[0].PERMISO_BASTIDORES) {
                                store.dispatch({ type: "STATE_SELECCION_DROP", NAME_Drop: traducirPagina("Bastidor"), IDENTIFICADOR: "BAS" })

                            } else {
                                if (!res.data.LoginSertec.List_LicenciaSERTE[0].PERMISO_MATRICULAS && !res.data.LoginSertec.List_LicenciaSERTE[0].PERMISO_BASTIDORES) {
                                    store.dispatch({ type: "STATE_SELECCION_DROP", NAME_Drop: "Codigo motor", IDENTIFICADOR: "CODM" })
                                }
                            }

                        }

                        history.push("/referencia");
                        store.dispatch(updateStateMasterPage(true))
                        
                        actualizarNotificaciones();
                    } else {
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.warning('Usuario o contraseña incorrectos');
                    }
                } else {
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.warning(res.data.MENSAJE_ERROR);
                }
            });
        }
    })



}

const loadAceites = () => {
    let estadoActual = store.getState().identificadorHaynesPro;
    let marca = estadoActual.marca; let modelo = estadoActual.modelo; let tipomotor = estadoActual.tipomotor

    if (Object.keys(estadoActual).length > 0 ? marca !== "" && modelo > 0 && tipomotor > 0 : false) {
        let estadoApp = store.getState();

        let listaMarca = estadoApp.brands.find(item => item.IdMarcaHP === marca);
        let listaModelo = estadoApp.models.find(item => item.id === modelo);
        let listaMotor = estadoApp.enginesType.find(item => item.id === tipomotor);
        let arraymodelo = listaModelo.name.split(" ");
        let arraymotor = listaMotor.name.split(" ");

        let crearurl = endpoint1 + "Api/LubricantsPho/getPhoLubricants/" + listaMarca.Marca + "/Modelo/" + arraymodelo[0] + "/Motor/" + arraymotor[0].replace(".", ",")

        return dispatch => {
            //protocolo + "nextapi.servicenext.eu/Api/LubricantsPho/getPhoLubricants/TOYOTA/Modelo/Aygo/Motor/1,0"
            axios.get(crearurl)
                .then(result => {
                    dispatch({
                        type: "GET_ACEITES",
                        aceites: result.data,
                    })
                });
        }
    } else {
        return {
            type: "GET_ACEITES",
            aceites: [],
        }
    }
}


const getSesionHaynesPro = () => {
    return dispatch => {
        axios.get("https://nextapi.servicenext.eu/" + "Api/Haynespro")
            .then(result => {
                dispatch({
                    type: "SET_SESION_HAYNESPRO",
                    sesionHaynes: result.data,
                })
            })
    }
}


const logOutSesion = () => {

    if (obtenerToken() !== null) {
        axios({
            method: 'post',
            url: endpoint1 + 'api/Login/LogOut',

            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({ "Token": obtenerToken() })
        }).then(function (res) {

        });
    }

}

const loginHaynesProTouch = (ID_VEHICULO_TECDOC) => {
    controller = new AbortController();
    let HEYNES_TOKEN = store.getState().usuario.LoginSertec.HEYNES.TOKEN_TECNICO_DIST;

    return fetch(endpoint1 + 'api/HaynesPro/getHeynesRedirectURL', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                HEYNES_TOKEN: HEYNES_TOKEN,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (res.OK === undefined) {
                    MSGERROR(res.MENSAJE_ERROR);
                    return false;
                } else {
                    if (!res.OK) {
                        MSGERROR(res.MENSAJE_ERROR)
                        return res;
                    } else {

                        return res;
                    }
                }
            }
        })
}



const obtenerImagenHaynesPro = (idVehiculo) => {
    let HEYNES_TOKEN = store.getState().usuario.LoginSertec.HEYNES.TOKEN_TECNICO_DIST;

    return axios({
        method: 'post',
        url: endpoint1 + "api/HaynesPro/getImagebyidVehicle",
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify({
            ID_VEHICLE: idVehiculo,
            HEYNES_TOKEN: HEYNES_TOKEN,
            Token: obtenerToken()
        })
    }).then(function (res) {
        if (!res.data.OK) {
            MSGERROR(res.data.MENSAJE_ERROR)
            return res.data.Image;
        } else {
            return res.data.Image;
        }
    });
}



const buscarMatriculaCoche = (matricula) => {
    let etiquetaPais = store.getState().fichaBusquedaVehiculo;
    /*
        etiquetaPais = etiquetaPais.codPaisIdioma.toUpperCase() === "S" || etiquetaPais.codPaisIdioma.toUpperCase() === "Z" ?
            "CH" : etiquetaPais.codPaisIdioma;
            */

    etiquetaPais = etiquetaPais.codPaisIdioma;

    let getdatauser = localStorage.getItem("usersertec");
    getdatauser = JSON.parse(getdatauser);

    return axios({
        method: 'post',
        url: endpoint1 + "api/Matriculas/getVehiculobyMatricula_2",
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify({
            "ID_SOCIO": getdatauser !== null ? getdatauser.ID_SOCIO : "",
            "ID_TALLER": getdatauser !== null ? getdatauser.ID_TALLER : "",
            "PLATAFORMA": "Sertec",
            "MATRICULA": matricula,
            "PAIS": etiquetaPais,
            "Token": obtenerToken()
        })
    }).then(function (res) {
        if (res.data.OK) {
            res.data.IDENTIFICADOR = matricula;

            OBTENER_TIPO_BUSQUEDA(res.data)
            return res.data
        } else {
            MSGERROR(res.data.MENSAJE_ERROR)
            return null
        }
    });
}



const buscarBastidorCoche = (Bastidor) => {
    let etiquetaPais = store.getState().fichaBusquedaVehiculo;
    /*
    etiquetaPais = etiquetaPais.codPaisIdioma.toUpperCase() === "S" || etiquetaPais.codPaisIdioma.toUpperCase() === "Z" ?
        "CH" : etiquetaPais.codPaisIdioma;
        */

    etiquetaPais = etiquetaPais.codPaisIdioma;


    let getdatauser = localStorage.getItem("usersertec");
    getdatauser = JSON.parse(getdatauser);

    return axios({
        method: 'post',
        url: endpoint1 + "api/Matriculas/getVehiculobyBastidor",
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify({
            "ID_SOCIO": getdatauser !== null ? getdatauser.ID_SOCIO : "",
            "ID_TALLER": getdatauser !== null ? getdatauser.ID_TALLER : "",
            "PLATAFORMA": "Sertec",
            "BASTIDOR": Bastidor,
            "PAIS": etiquetaPais,
            "Token": obtenerToken()
        })
    }).then(function (res) {
        if (res.data.OK) {
            res.data.IDENTIFICADOR = Bastidor;
            OBTENER_TIPO_BUSQUEDA(res.data)

            return res.data
        } else {
            MSGERROR(res.data.MENSAJE_ERROR)
            return null
        }
    });
}


const direccionesByMarca = (marca, CATALOGO) => {
    store.dispatch({
        type: "REPLACE_DIRECCION_MAS_INFORMACION",
        direccion: {}
    })

    return dispatch => {
        axios({
            method: 'post',
            url: endpoint1 + "api/MasInfo/getDireccionesByMarca",
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                "MARCA": parseInt(marca),
                CATALOGO: CATALOGO,
                "Token": obtenerToken()

            })
        }).then(function (res) {
            if (!res.data.OK) {
                MSGERROR(res.data.MENSAJE_ERROR)
            }
            dispatch({
                type: "REPLACE_DIRECCION_MAS_INFORMACION",
                direccion: res.data.OK ? res.data.DIRECCIONES === null ? {} : res.data.DIRECCIONES : {}
            })
        });
    }
}

const documentosByRefMarca = (articulo, marca, CATALOGO) => {
    store.dispatch({
        type: "REPLACE_IMAGENES_MAS_INFORMACION",
        imagenes: {}
    })
    store.dispatch({
        type: "REPLACE_DOCUMENTOS_MAS_INFORMACION",
        documentos: {}
    })

    return dispatch => {
        axios({
            method: 'post',
            url: endpoint1 + "api/MasInfo/getDocumentosByReferenciaMarca",
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                "ARTICULO": articulo,
                "MARCA": marca,
                CATALOGO: CATALOGO,
                "Token": obtenerToken()
            })
        }).then(function (res) {
            if (!res.data.OK) {
                MSGERROR(res.data.MENSAJE_ERROR)
            }

            dispatch({
                type: "REPLACE_IMAGENES_MAS_INFORMACION",
                imagenes: res.data.OK ? res.data.LISTAIMAGENES === null || res.data.LISTAIMAGENES.length === 0 ? null : res.data.LISTAIMAGENES : null,
            })

            dispatch({
                type: "REPLACE_DOCUMENTOS_MAS_INFORMACION",
                documentos: res.data.OK ? res.data.LISTADOCUMENTOS === null || res.data.LISTADOCUMENTOS.length === 0 ? null : res.data.LISTADOCUMENTOS : null
            })
        });
    }
}


const despiezeByReferenciaMarca = (articulo, marca) => {
    return axios({
        method: 'post',
        url: endpoint1 + "api/MasInfo/getDespiezeByReferenciaMarca_2",
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify({
            "ARTICULO": articulo,
            "MARCA": marca,
            "Token": obtenerToken()
        })
    }).then(function (res) {
        if (!res.data.OK) {
            MSGERROR(res.data.MENSAJE_ERROR)
        }

        return res.data
    });
}

const fabricantesByReferenciaMarca = (referencia, marca, CATALOGO) => {
    store.dispatch({
        type: "REPLACE_VEHICULOS_MAS_INFORMACION",
        vehiculos: {}
    })
    return dispatch => {
        axios({
            method: 'post',
            url: endpoint1 + "api//MasInfo/getFabricantesByReferenciaMarca",
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                "REFERENCIA": referencia,
                "MARCA": marca,
                CATALOGO: CATALOGO,
                "Token": obtenerToken()
            })
        }).then(function (res) {
            if (res.data.OK) {
                if (res.data.FABRICANTES === null || res.data.FABRICANTES.length === 0) {
                    MSGERROR(res.data.MENSAJE_ERROR)
                    dispatch({
                        type: "REPLACE_VEHICULOS_MAS_INFORMACION",
                        vehiculos: null
                    })
                } else {
                    dispatch({
                        type: "REPLACE_VEHICULOS_MAS_INFORMACION",
                        vehiculos: res.data.FABRICANTES
                    })
                }

            } else {
                MSGERROR(res.data.MENSAJE_ERROR)
            }
        });
    }
}

const referenciaOEMasInfo = (referencia, marca, CATALOGO) => {
    store.dispatch({
        type: "REPLACE_REFOE_MAS_INFORMACION",
        refOE: {}
    })
    return dispatch => {
        axios({
            method: 'post',
            url: endpoint1 + "api/MasInfo/ObtenerReferenciasOEByArtNo_BrandNo",
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                "ARTNO": referencia,
                "MARCA": marca,
                CATALOGO: CATALOGO,
                "Token": obtenerToken()
            })
        }).then(function (res) {
            dispatch({
                type: "REPLACE_REFOE_MAS_INFORMACION",
                refOE: res.data.OK ? res.data.REFERENCIAS !== null ? res.data.REFERENCIAS : null : {}
            })

            if (res.data.OK) {
                if (res.data.REFERENCIAS !== null) {
                    buscarPrecioRefOE(res.data.REFERENCIAS)
                } else {
                    MSGERROR(res.data.MENSAJE_ERROR)
                }
            } else {
                MSGERROR(res.data.MENSAJE_ERROR)
            }
        });
    }
}

const getIdVehicleByTecdocNumber = (motorizacion) => {
    let HEYNES_TOKEN = store.getState().usuario.LoginSertec.HEYNES.TOKEN_TECNICO_DIST;

    return dispatch => {
        axios({
            method: 'post',
            url: endpoint1 + "api/HaynesPro/getIdVehicleByTecdocNumber",
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                ID_VEHICLE: motorizacion,
                HEYNES_TOKEN: HEYNES_TOKEN,
                Token: obtenerToken()
            })
        }).then(function (res) {
            dispatch({
                type: "CONVERT__MOTORIZACION_BY_HAYNESPRO",
                codigoHaynesPro: res.data === "null" ? "" : res.data
            })
        });
    }
}


const getIdVehicleByTecdocNumberV2 = (motorizacion) => {
    let HEYNES_TOKEN = store.getState().usuario.LoginSertec.HEYNES.TOKEN_TECNICO_DIST;

    return axios({
        method: 'post',
        url: endpoint1 + "api/HaynesPro/getIdVehicleByTecdocNumber",
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify({
            ID_VEHICLE: motorizacion,
            HEYNES_TOKEN: HEYNES_TOKEN,
            Token: obtenerToken()
        })
    }).then(function (res) {
        if (!res.data.OK) {
            MSGERROR(res.data.MENSAJE_ERROR)
        } else {
            return res.data;
        }
    });
}


const getModelosByReferenciaMarcaAndFabricante = (referencia, marca, fabricante) => {
    store.dispatch({
        type: "REPLACE_MODELS_MAS_INFORMACION",
        masInfoModels: {}
    })
    return dispatch => {
        axios({
            method: 'post',
            url: endpoint1 + "api/MasInfo/getModelosByReferenciaMarcaAndFabricante",
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                "REFERENCIA": referencia,
                "MARCA": marca,
                "FABRICANTE": fabricante,
                "Token": obtenerToken()
            })
        }).then(function (res) {
            if (res.data.OK) {
                if (res.data.MODELOS !== null) {
                    dispatch({
                        type: "REPLACE_MODELS_MAS_INFORMACION",
                        masInfoModels: res.data.MODELOS
                    })
                } else {
                    MSGERROR(res.data.MENSAJE_ERROR)
                }
            } else {
                MSGERROR(res.data.MENSAJE_ERROR)
            }
            dispatch({
                type: "REPLACE_MODELS_MAS_INFORMACION",
                masInfoModels: res.data.OK ? res.data.MODELOS : {}
            })
        });
    }
}


const getMotorizacionesByReferenciaAndModelo = (referencia, marca, modelo) => {
    store.dispatch({
        type: "REPLACE_MOTORIZACION_MAS_INFORMACION",
        masInfoMotorizacion: {}
    })
    return dispatch => {
        axios({
            method: 'post',
            url: endpoint1 + "api/MasInfo/ObtenerMotorizacionesByReferenciaAndModelo",
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                "REFERENCIA": referencia,
                "MARCA": marca,
                "MODELO": modelo,
                "Token": obtenerToken()
            })
        }).then(function (res) {
            if (!res.data.OK) {
                MSGERROR(res.data.MENSAJE_ERROR)
            }
            dispatch({
                type: "REPLACE_MOTORIZACION_MAS_INFORMACION",
                masInfoMotorizacion: res.data.OK ? res.data.MOTORES === null ? MSGERROR(res.data.MENSAJE_ERROR) : res.data.MOTORES : {}
            })
        });
    }
}



const getStockDesglose = (referenciaTecdoc, marcaTecdoc, referenciaERP) => {
    const { ES_NEUMATICO, REFERENCIA_FABRICANTE } = { ...store.getState().NEUMATICO }
    if (ES_NEUMATICO) {
        referenciaTecdoc = REFERENCIA_FABRICANTE;
    }


    let cliente = store.getState().clienteSeleccionado;
    let getCookieUser = JSON.parse(localStorage.getItem("usersertec"));
    let accesoLicencia = {};
    if (getCookieUser === null) {
        //MSGERROR("LA PETICION NO ESTA LOGEADA");
        return null;

    } else {
        accesoLicencia = getCookieUser.LoginSertec.List_LicenciaSERTE[0];
        return axios({
            method: 'post',
            url: endpoint1 + "api/ConexionERP/getStockDesglose",
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                "ID_SOCIO": accesoLicencia.ID_SOCIO,
                "EMPRESA": accesoLicencia.EMPRESA,
                "CLIENTE": cliente.codigo,
                "CENTRO": "",

                "ReferenciaTecdoc": referenciaTecdoc,
                "MarcaTecdoc": marcaTecdoc,
                "ReferenciaERP": referenciaERP,
                "ES_NEUMATICO": ES_NEUMATICO,
                "Token": obtenerToken()
            })
        }).then(function (res) {
            if (!res.data.OK) {
                MSGERROR(res.data.MENSAJE_ERROR)
            } else {
                if (res.data.Stock === null || res.data.Stock.length === 0) {
                    MSGERROR(res.data.MENSAJE_ERROR)
                }
                return res.data.Stock;
            }
        });
    }
}







const getPricePageOEM = (listaAtributos) => {
    let listaActualRefOE = store.getState().masInformacion.refOE;

    axios({
        method: 'post',
        url: endpoint1 + "api/MasInfo/getPricePageOEM",
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify({
            ListArticles: listaAtributos,
            "Token": obtenerToken()
        })
    }).then(function (res) {
        if (res.data.OK) {
            if (res.data.ListaPreciosOEM.length === 0 || res.data.ListaPreciosOEM === null) {
                store.dispatch({ type: "REPLACE_REFOE_MAS_INFORMACION", refOE: null })
                MSGERROR(res.data.MENSAJE_ERROR)
            } else {
                listaActualRefOE.map((item, indice) => {
                    let find = res.data.ListaPreciosOEM.find(element => element.PartNo === item.REFERENCIA_ORIGINAL)
                    if (find !== undefined) {
                        item.PRECIO = find.Price
                    }
                })
                store.dispatch({ type: "REPLACE_REFOE_MAS_INFORMACION", refOE: {} })
                store.dispatch({ type: "REPLACE_REFOE_MAS_INFORMACION", refOE: listaActualRefOE })
            }

        } else {
            store.dispatch({ type: "REPLACE_REFOE_MAS_INFORMACION", refOE: res.data.ListaPreciosOEM })
            MSGERROR(res.data.MENSAJE_ERROR)
        }
    });
}


const setHistorialBusqueda = (registro) => {
    controller = new AbortController();

    let ID_VEHICULO_CATALOGO = idModeloLatam()
    return fetch(endpoint1 + 'api/Historial/setHistorialBusqueda', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ACCION: 0,
                ID: "",
                TIPO_BUSQUEDA: 4,
                MATRICULA: "",
                BASTIDOR: "",
                REFERENCIA: "",
                ID_MARCA: registro.MARCA,
                ID_MODELO: registro.MODELO,
                ID_MOTORIZACION: registro.MOTORIZACION,
                MARCA: registro.NOMBRE_MARCA,
                MODELO: registro.NOMBRE_MODELO,
                MOTOR: registro.NOMBRE_MOTOR,
                ID_VEHICULO_CATALOGO: ID_VEHICULO_CATALOGO,
                //CODIGO_MOTOR:registro.CODIGO_MOTOR,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    //MSGERROR(res.MENSAJE_ERROR)
                    return res;
                } else {
                    return res;
                }
            }
        })
}


const getHistorialBusqueda = (cogigoDeBusqueda) => {

    controller = new AbortController();
    return fetch(endpoint1 + 'api/Historial/getHistorialBusqueda', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                REGSPAGE: 40,
                PAGE: 1,
                ORDERCOLUM: 4,
                ORDERDIR: "D",
                TIPO_BUSQUEDA: cogigoDeBusqueda,
                MATRICULA: "",
                BASTIDOR: "",
                REFERENCIA: "",
                MARCA: "",
                MODELO: "",
                MOTOR: "",
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return null
                } else {
                    return res.Lista_HistorialBusqueda;
                }
            }
        })
}

const setCestaLineasEnAPI = (REFERENCIA_ERP, MARCA_ERP, REFERENCIA_TECDOC, MARCA_TECDOC, DESCRIPCION, CANTIDAD, TEXTO_LIBRE, PRECIO, DESCUENTO, NETO, ALMACEN, DESCRIPCION_ALMACEN, ACCION, ID, CLIENTE, VEHICULO, IDENTIFICADOR_LINEA) => {
    controller = new AbortController();
    //let ES_NEUMATICO = NEUMATICO === undefined ? store.getState().ES_NEUMATICO:NEUMATICO;
    const { ES_NEUMATICO } = { ...store.getState().NEUMATICO }
    /*
    if(ES_NEUMATICO){
        referenciaTecdoc = REFERENCIA_FABRICANTE;
    }
    */



    let VEHICULO_ACTUAL = VEHICULO === undefined || VEHICULO === null || Object.keys(VEHICULO).length === 0 ? { MATRICULA: "", BASTIDOR: "", MARCA_VEHICULO: "", MODELO_VEHICULO: "", MOTOR_VEHICULO: "", VEHICULO_TECDOC: { ID: 0 } } : VEHICULO
    let cliente = store.getState().clienteSeleccionado;

    //console.log("VEHICULO_ACTUAL",VEHICULO_ACTUAL)

    let codmotorizacion = store.getState().codmotorizacion;

    return fetch(endpoint1 + 'api/Cesta/setCestaLineas', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID: ID,
                CLIENTE: CLIENTE === undefined || CLIENTE === null || CLIENTE === "" ? cliente.codigo : CLIENTE,
                ACCION: ACCION,
                REFERENCIA_ERP: REFERENCIA_ERP,
                MARCA_ERP: MARCA_ERP,
                REFERENCIA_TECDOC: REFERENCIA_TECDOC,
                MARCA_TECDOC: MARCA_TECDOC,
                DESCRIPCION: DESCRIPCION,
                CANTIDAD: CANTIDAD,
                TEXTO_LIBRE: TEXTO_LIBRE,
                PRECIO: PRECIO,
                DESCUENTO: DESCUENTO,
                NETO: NETO,
                ALMACEN: ALMACEN,
                DESCRIPCION_ALMACEN: DESCRIPCION_ALMACEN,

                MATRICULA: VEHICULO_ACTUAL.MATRICULA,
                BASTIDOR: VEHICULO_ACTUAL.BASTIDOR,
                MARCA_VEHICULO: VEHICULO_ACTUAL.MARCA_VEHICULO,
                MODELO_VEHICULO: VEHICULO_ACTUAL.MODELO_VEHICULO,
                MOTOR_VEHICULO: VEHICULO_ACTUAL.MOTOR_VEHICULO,
                ES_NEUMATICO: ES_NEUMATICO,
                IDENTIFICADOR_LINEA: IDENTIFICADOR_LINEA,
                ID_VEHICULO_TECDOC: VEHICULO_ACTUAL.VEHICULO_TECDOC === undefined || VEHICULO_ACTUAL.VEHICULO_TECDOC.ID === undefined ? 0 : VEHICULO_ACTUAL.VEHICULO_TECDOC.ID,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return res;
                } else {
                    actualizarNotificaciones();

                    return res;
                }
            }
        })
}

const eliminarCestaLineasEnAPI = (ID) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Cesta/setCestaLineas', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ACCION: 2,
                ID: ID,
                REFERENCIA_ERP: "",
                MARCA_ERP: "",
                REFERENCIA_TECDOC: "",
                MARCA_TECDOC: "",
                DESCRIPCION: "",
                CANTIDAD: "",
                TEXTO_LIBRE: "",
                ALMACEN: "",
                DESCRIPCION_ALMACEN: "",
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (res.OK === undefined) {
                    MSGERROR("Problema al eliminar la linea.");
                    return false;
                } else {
                    if (!res.OK) {
                        MSGERROR(res.MENSAJE_ERROR)
                        return res.OK;
                    } else {

                        actualizarNotificaciones()

                        return res.OK;
                    }
                }
            }
        })
}



const vaciarCestaLineasEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Cesta/setCestaLineas', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ACCION: 3,
                ID: "",
                REFERENCIA_ERP: "",
                MARCA_ERP: "",
                REFERENCIA_TECDOC: "",
                MARCA_TECDOC: "",
                DESCRIPCION: "",
                CANTIDAD: "",
                TEXTO_LIBRE: "",
                ALMACEN: "",
                DESCRIPCION_ALMACEN: "",
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {

            if (res !== undefined) {
                if (res.OK === undefined) {
                    MSGERROR("Problema al vaciar la cesta.");
                    return false;
                } else {
                    if (!res.OK) {
                        MSGERROR(res.MENSAJE_ERROR)
                        return res.OK;
                    } else {
                        actualizarNotificaciones()
                        return res.OK;
                    }
                }
            }
        })
}





const getCestaLineasEnAPI = () => {
    let cliente = store.getState().clienteSeleccionado;
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Cesta/getCestaLineas', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                CLIENTE: cliente.codigo,
                REGSPAGE: 1000,
                PAGE: 1,
                ORDERCOLUM: 1,
                ORDERDIR: "A",
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return null;
                } else {
                    return res.CESTA;
                }
            }
        })
}

const ObtenerReferenciasByNodoGraphicAndVehicleEnAPI = (NODO_ID, VEHICLE_ID) => {
    controller = new AbortController();
    let cliente = store.getState().clienteSeleccionado;

    let { es_accesorio } = store.getState().nodoGraficoSeleccionado
    let ES_MOTO = obtenerTipoDeBusquedaActual() === 'MT'
    let ID_VEHICULO = es_accesorio ? 0 : VEHICLE_ID

    let ID_MODELO_CATALOGO = idModeloLatam()



    return fetch(endpoint1 + 'api/Extructura/ObtenerReferenciasByNodoGraphicAndVehicle', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                CLIENTE: cliente.codigo,
                NODO_GRAFICO_ID: NODO_ID,
                VEHICLE_ID: ID_VEHICULO,
                TIPO: ES_MOTO ? "MT" : "C",
                ID_MODELO_CATALOGO: ID_MODELO_CATALOGO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return null;
                } else {
                    return res;
                }
            }
        })
}

const ObtenerReferenciasByNodoGraphicCarroceriaAndVehicleEnAPI = (NODO_ID, VEHICLE_ID) => {
    controller = new AbortController();
    let cliente = store.getState().clienteSeleccionado;

    return fetch(endpoint1 + 'api/Extructura/ObtenerReferenciasByNodoGraphicCarroceriaAndVehicle', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                CLIENTE: cliente.codigo,
                NODO_GRAFICO_ID: NODO_ID,
                VEHICLE_ID: VEHICLE_ID,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return null;
                } else {
                    return res;
                }
            }
        })
}




const ObtenerArticuloByArtNoBrandNoEnAPI = (referencia, marca, CATALOGO) => {
    controller = new AbortController();
    let cliente = store.getState().clienteSeleccionado;
    return fetch(endpoint1 + 'api/Articulo/ObtenerArticuloByArtNoBrandNo', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ARTNO: referencia,
                BRANDNO: marca,
                CATALOGO: CATALOGO,
                Token: obtenerToken(),
                CLIENTE: cliente.codigo
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return null;
                } else {
                    return res.ARTICULO;
                }
            }
        })
}


const ObtenerClientesEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/ConexionERP/ObtenerClienteERP', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return null;
                } else {
                    return res.CLIENTES;
                }
            }
        })
}


const setClienteTallerEnAPI = (accion, datosCliente) => {
    //http://86.109.106.105/SERTEC2
    controller = new AbortController();
    return fetch(endpoint1 + "api/Gestion/setCliente", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ACCION: accion,
                ID: datosCliente.id,
                CODIGO: datosCliente.codigo,
                NOMBRE: datosCliente.nombre,
                DIRECCION: datosCliente.direccion,
                POBLACION: datosCliente.poblacion,
                PROVINCIA: datosCliente.provincia,
                CP: datosCliente.cp,
                PAIS: datosCliente.pais,
                TELEFONO: datosCliente.telefono1,
                TELEFONO_2: datosCliente.telefono2,
                TELEFONO_3: datosCliente.telefono3,
                CIF: datosCliente.cif,
                MAIL: datosCliente.email,
                OBSERVACIONES: datosCliente.observacion,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return res;
                } else {
                    return res;
                }
            }
        })
}

const setClienteVehiculoTallerEnAPI = (accion, datosVehiculo) => {
    controller = new AbortController();
    return fetch(endpoint1 + "api/Gestion/setClientesVehiculos", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ACCION: accion,
                ID: datosVehiculo.ID,
                ID_CLIENTE: datosVehiculo.ID_CLIENTE,
                ID_TECDOC: datosVehiculo.ID_TECDOC,
                MATRICULA: datosVehiculo.MATRICULA,
                DESCRIPCION: datosVehiculo.DESCRIPCION,
                BASTIDOR: datosVehiculo.BASTIDOR,
                MARCA: datosVehiculo.MARCA,
                MODELO: datosVehiculo.MODELO,
                MOTOR: datosVehiculo.MOTOR,
                OBSERVACIONES: datosVehiculo.OBSERVACIONES,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return res;
                } else {
                    return res;
                }
            }
        })
}


const getClienteTallerEnAPI = (id, ORDERCOLUM, ORDERDIR) => {
    controller = new AbortController();
    return fetch(endpoint1 + "api/Gestion/getClientes", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                REGSPAGE: 10000,
                PAGE: 1,
                ORDERCOLUM: ORDERCOLUM,
                ORDERDIR: ORDERDIR,
                ID: id,
                CODIGO: "",
                NOMBRE: "",
                CIF: "",
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return res;
                } else {
                    return res;
                }
            }
        })
}

const getClienteVehiculosEnAPI = (id, ORDERCOLUM, ORDERDIR, ID_CLIENTE) => {
    controller = new AbortController();
    return fetch(endpoint1 + "api/Gestion/getClientesVehiculos", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                REGSPAGE: 10000,
                PAGE: 1,
                ORDERCOLUM: ORDERCOLUM,
                ORDERDIR: ORDERDIR,
                ID: id,
                ID_CLIENTE: ID_CLIENTE,
                MATRICULA: "",
                BASTIDOR: "",
                DESCRIPCION: "",
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return res;
                } else {
                    return res;
                }
            }
        })
}


const getPresupuestosTallerEnAPI = (id, ORDERCOLUM, ORDERDIR, DESDE, HASTA, ESTADO) => {
    controller = new AbortController();
    return fetch(endpoint1 + "api/Gestion/getPresupuestos", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                REGSPAGE: 10000,
                PAGE: 1,
                ORDERCOLUM: ORDERCOLUM,
                ORDERDIR: ORDERDIR,
                ID: id,
                CODIGO: "",
                FECHA_DESDE: DESDE,
                FECHA_HASTA: HASTA,
                ID_VEHICULO: "",
                VEH_MATRICULA: "",
                ID_CLIENTE: "",
                CLI_NOMBRE: "",
                ESTADO: ESTADO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return res;
                } else {
                    return res;
                }
            }
        })
}


const setPresupuestosTallerEnAPI = (ACCION, DATOS_USUARIO, LineasManoObra) => {
    controller = new AbortController();
    DATOS_USUARIO.VALOR_IVA_1 = typeof DATOS_USUARIO.VALOR_IVA_1 === 'number' ? DATOS_USUARIO.VALOR_IVA_1 : DATOS_USUARIO.VALOR_IVA_1.replace("%", "");


    return fetch(endpoint1 + "api/Gestion/setPresupuestos", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ACCION: ACCION,
                ID: DATOS_USUARIO.ID,
                CODIGO: DATOS_USUARIO.CODIGO,
                FECHA: DATOS_USUARIO.FECHA,
                ID_VEHICULO: DATOS_USUARIO.ID_VEHICULO,
                VEH_BASTIDOR: DATOS_USUARIO.VEH_BASTIDOR,
                //BASTIDOR: DATOS_USUARIO.BASTIDOR,

                VEH_MATRICULA: DATOS_USUARIO.VEH_MATRICULA,
                VEH_DENOMINACION: DATOS_USUARIO.VEH_DENOMINACION,
                VEH_ID_TECDOC: DATOS_USUARIO.VEH_ID_TECDOC,
                ID_CLIENTE: DATOS_USUARIO.ID_CLIENTE,
                CLI_NOMBRE: DATOS_USUARIO.CLI_NOMBRE,
                CLI_CIF: DATOS_USUARIO.CLI_CIF,
                CLI_DIRECCION: DATOS_USUARIO.CLI_DIRECCION,
                CLI_POBLACION: DATOS_USUARIO.CLI_POBLACION,
                CLI_PROVINCIA: DATOS_USUARIO.CLI_PROVINCIA,
                CLI_CP: DATOS_USUARIO.CLI_CP,
                CLI_PAIS: DATOS_USUARIO.CLI_PAIS,
                CLI_TELEFONO: DATOS_USUARIO.CLI_TELEFONO,
                CLI_TELEFONO_2: DATOS_USUARIO.CLI_TELEFONO_2,
                CLI_TELEFONO_3: DATOS_USUARIO.CLI_TELEFONO_3,
                CLI_MAIL: DATOS_USUARIO.CLI_MAIL,
                VALOR_DTO_GENERAL: DATOS_USUARIO.VALOR_DTO_GENERAL,
                VALOR_IVA_1: DATOS_USUARIO.VALOR_IVA_1,
                VALOR_IVA_2: DATOS_USUARIO.VALOR_IVA_2,
                VALOR_IVA_3: DATOS_USUARIO.VALOR_IVA_3,
                IMPORTE_BRUTO_1: DATOS_USUARIO.IMPORTE_BRUTO_1,
                IMPORTE_BRUTO_2: DATOS_USUARIO.IMPORTE_BRUTO_2,
                IMPORTE_BRUTO_3: DATOS_USUARIO.IMPORTE_BRUTO_3,
                IMPORTE_BRUTO: DATOS_USUARIO.IMPORTE_BRUTO,
                IMPORTE_DESCUENTOS_1: DATOS_USUARIO.IMPORTE_DESCUENTOS_1,
                IMPORTE_DESCUENTOS_2: DATOS_USUARIO.IMPORTE_DESCUENTOS_2,
                IMPORTE_DESCUENTOS_3: DATOS_USUARIO.IMPORTE_DESCUENTOS_3,
                IMPORTE_DESCUENTOS: DATOS_USUARIO.IMPORTE_DESCUENTOS,
                IMPORTE_BASE_IMPONIBLE_1: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE_1,
                IMPORTE_BASE_IMPONIBLE_2: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE_2,
                IMPORTE_BASE_IMPONIBLE_3: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE_3,
                IMPORTE_BASE_IMPONIBLE: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE,
                IMPORTE_IMPUESTOS_1: DATOS_USUARIO.IMPORTE_IMPUESTOS_1,
                IMPORTE_IMPUESTOS_2: DATOS_USUARIO.IMPORTE_IMPUESTOS_2,
                IMPORTE_IMPUESTOS_3: DATOS_USUARIO.IMPORTE_IMPUESTOS_3,
                IMPORTE_TOTAL_1: DATOS_USUARIO.IMPORTE_TOTAL_1,
                IMPORTE_TOTAL_2: DATOS_USUARIO.IMPORTE_TOTAL_2,
                IMPORTE_TOTAL_3: DATOS_USUARIO.IMPORTE_TOTAL_3,
                IMPORTE_TOTAL: DATOS_USUARIO.IMPORTE_TOTAL,
                OBSERVACIONES_PRESUPUESTO: DATOS_USUARIO.OBSERVACIONES_PRESUPUESTO,
                IMPRIMIDO: DATOS_USUARIO.IMPRIMIDO,
                ENVIADO: DATOS_USUARIO.ENVIADO,
                FECHA_IMPRESION: DATOS_USUARIO.FECHA_IMPRESION,
                FECHA_ENVIO: DATOS_USUARIO.FECHA_ENVIO,
                ESTADO: DATOS_USUARIO.ESTADO,
                ID_PRESUPUESTO_GT_ESTIMATE: DATOS_USUARIO.ID_PRESUPUESTO_GT_ESTIMATE,
                LINEAS: LineasManoObra,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return res;
                } else {
                    return res;
                }
            }
        })
}


const ObtenerEquivalenciasByArtNoBrandNoEnAPI = (MARCA, ARTNO, CATALOGO) => {
    controller = new AbortController();
    let cliente = store.getState().clienteSeleccionado;

    return fetch(endpoint1 + 'api/Articulo/ObtenerEquivalenciasByArtNoBrandNo', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                CLIENTE: cliente.codigo,
                ARTNO: ARTNO,
                MARCA: MARCA,

                CATALOGO: CATALOGO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerMarcasdeUnVehiculoEnAPI = (ID_VEHICULO, TIPO, ID_MODELO_CATALOGO) => {
    //let codmotorizacion = store.getState().codmotorizacion;
    controller = new AbortController();
    let cliente = store.getState().clienteSeleccionado;

    return fetch(endpoint1 + 'api/Vehiculo/ObtenerMarcasdeUnVehiculo', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                CLIENTE: cliente.codigo,
                ID_VEHICULO: ID_VEHICULO,
                TIPO: TIPO,
                ID_MODELO_CATALOGO: ID_MODELO_CATALOGO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.MARCAS
            }
        })
}

const ObtenerReferenciasMarcadeUnVehiculoEnAPI = (ID_MARCA, ID_VEHICULO, ID_MARCA_CATALOGO, ID_MODELO_CATALOGO) => {
    controller = new AbortController();
    let cliente = store.getState().clienteSeleccionado;

    return fetch(endpoint1 + 'api/Vehiculo/ObtenerReferenciasMarcadeUnVehiculo', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                CLIENTE: cliente.codigo,
                ID_MARCA: ID_MARCA,
                ID_VEHICULO: ID_VEHICULO,
                ID_MARCA_CATALOGO: ID_MARCA_CATALOGO,
                ID_MODELO_CATALOGO: ID_MODELO_CATALOGO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerEquivalenciasArticuloByArtoOEMEnAPI = (ID_MARCA, REFERENCIA, ARTNO) => {
    controller = new AbortController();
    let cliente = store.getState().clienteSeleccionado;

    return fetch(endpoint1 + 'api/Articulo/ObtenerEquivalenciasArticuloByArtoOEM', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ARTNO: ARTNO,
                OEM: REFERENCIA,
                MARCA: ID_MARCA,
                CLIENTE: cliente.codigo,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const GetVehiculosHaynesByIdTecdocEnAPI = (ID_VEHICULO_TECDOC, CATALOGO) => {
    controller = new AbortController();
    let HEYNES_TOKEN = store.getState().usuario.LoginSertec.HEYNES.TOKEN_TECNICO_DIST;

    return fetch(endpoint1 + 'api/HaynesPro/GetVehiculosHeynesByIdTecdoc', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                HEYNES_TOKEN: HEYNES_TOKEN,
                CATALOGO: CATALOGO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.VEHICULOS
            }
        })
}

const ObtenerInfoTareasTiempoReparacionEnAPI = (ID_TIEMPO_REPARACION, NODO_ID, TIENE_INFO) => {
    controller = new AbortController();
    let HEYNES_TOKEN = store.getState().usuario.LoginSertec.HEYNES.TOKEN_TECNICO_DIST;

    return fetch(endpoint1 + 'api/HaynesPro/ObtenerInfoTareasTiempoTeparacion', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_TIEMPO_REPARACION: ID_TIEMPO_REPARACION,
                NODO_ID: NODO_ID,
                TIENE_INFO: TIENE_INFO,
                HEYNES_TOKEN: HEYNES_TOKEN,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.TIEMPO_REPARACON_INFO
            }
        })
}



const GetTiemposReparacionByGMAndVehicleEnAPI = (ID_TIEMPO_REPARACION, GRUPO_MONTAJE, RAPID, CATALOGO) => {
    controller = new AbortController();
    let HEYNES_TOKEN = store.getState().usuario.LoginSertec.HEYNES.TOKEN_TECNICO_DIST;

    return fetch(endpoint1 + 'api/HaynesPro/GetTiemposReparacionByGMAndVehicle', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_TIEMPO_REPARACION: ID_TIEMPO_REPARACION,
                GRUPO_MONTAJE: GRUPO_MONTAJE,
                HEYNES_TOKEN: HEYNES_TOKEN,
                RAPID: RAPID,
                CATALOGO: CATALOGO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.TIEMPOS_REPARACION_ARTICULO
            }
        })
}

const GetNodosRaizTiemposReparacionEnAPI = (ID_TIEMPO_REPARACION) => {
    controller = new AbortController();
    let HEYNES_TOKEN = store.getState().usuario.LoginSertec.HEYNES.TOKEN_TECNICO_DIST;

    return fetch(endpoint1 + 'api/HaynesPro/ObtenerNodosRaizTiemposReparacion', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_TIEMPO_REPARACION: ID_TIEMPO_REPARACION,
                HEYNES_TOKEN: HEYNES_TOKEN,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.NODOS
            }
        })
}



const GetSubNodosTiempoReparacioEnAPI = (ID_TIEMPO_REPARACION, NODO_ID) => {
    controller = new AbortController();
    let HEYNES_TOKEN = store.getState().usuario.LoginSertec.HEYNES.TOKEN_TECNICO_DIST;

    return fetch(endpoint1 + 'api/HaynesPro/ObtenerSubNodosTiempoReparacion', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_TIEMPO_REPARACION: ID_TIEMPO_REPARACION,
                NODO_ID: NODO_ID,
                HEYNES_TOKEN: HEYNES_TOKEN,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.NODOS
            }
        })
}


const ObtenerTareasTiempoReparacionEnnAPI = (ID_TIEMPO_REPARACION, NODO_ID, TIENE_INFO) => {
    controller = new AbortController();
    let HEYNES_TOKEN = store.getState().usuario.LoginSertec.HEYNES.TOKEN_TECNICO_DIST;

    return fetch(endpoint1 + 'api/HaynesPro/ObtenerTareasTiempoReparacion', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_TIEMPO_REPARACION: ID_TIEMPO_REPARACION,
                NODO_ID: NODO_ID,
                TIENE_INFO: TIENE_INFO,
                HEYNES_TOKEN: HEYNES_TOKEN,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.TAREAS_TIEMPO_REPARACION
            }
        })
}




const CrearPedidoEnAPI = (DATOS_PEDIDO, lineasPedido, CLIENTE, CENTRO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/ConexionERP/CrearPedido', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Cliente: CLIENTE,
                Centro: CENTRO,
                Almacen: "",
                Observaciones: DATOS_PEDIDO.observacionesPedido,
                SuPedido: DATOS_PEDIDO.numeroPedido,
                PedidoPor: DATOS_PEDIDO.pedidoPor,
                MailConfirmacion: DATOS_PEDIDO.recibirMailPedido,
                LineasSeleccionadas: lineasPedido,
                RecogerEnTienda: DATOS_PEDIDO.recogerArticulo,

                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const CrearPresupuestoEnAPI = (DATOS_PEDIDO, lineasPedido, CLIENTE, CENTRO) => {

    controller = new AbortController();
    return fetch(endpoint1 + 'api/ConexionERP/CrearPresupuesto', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Cliente: CLIENTE,
                Centro: CENTRO,
                Almacen: "",
                Observaciones: DATOS_PEDIDO.observacionesPedido,
                SuPedido: DATOS_PEDIDO.numeroPedido,
                PedidoPor: DATOS_PEDIDO.pedidoPor,
                MailConfirmacion: DATOS_PEDIDO.recibirMailPedido,
                LineasSeleccionadas: lineasPedido,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const ObtenerAlbaranesVentaEnAPI = (desde, hasta) => {
    let cliente = store.getState().clienteSeleccionado;
    controller = new AbortController();
    //"05/05/2021"
    return fetch(endpoint1 + 'api/ConexionERP/ObtenerAlbaranesVenta', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                CifCliente: cliente.cif,
                CodigoCliente: cliente.codigo,
                Desde: desde,
                Hasta: hasta,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.ALBARAN_VENTAS
            }
        })
}


const GetUsuariosEnAPI = () => {
    let getCookieUser = JSON.parse(localStorage.getItem("usersertec"));
    let accesoLicencia = getCookieUser.LoginSertec;
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Usuario/GetUsuarios', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                REGSPAGE: 1,
                PAGE: 1,
                ORDERCOLUM: 1,
                ORDERDIR: "A",
                ID: accesoLicencia !== null ? accesoLicencia.ID : "",
                ID_TALLER: accesoLicencia !== null ? accesoLicencia.ID_TALLER : "",
                BAJA: 0,
                RAZON_SOCIO: "",
                NOMBRE_TALLER: "",
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.USUARIOS
            }
        })
}


const SetUsuarioEnAPI = (payload, passwordUser, idioma) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Usuario/SetUsuario', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ACCION: 1,
                ID: payload.ID,
                USUARIO: payload.USUARIO,
                PWD: passwordUser,
                NOMBRE: payload.NOMBRE,
                ID_IDIOMA: idioma,
                BAJA: payload.BAJA,
                CORREO_RECUPERACION_PWD: payload.CORREO_RECUPERACION_PWD,
                CORREO_ENVIO_DOCUMENTOS: payload.CORREO_ENVIO_DOCUMENTOS,
                CORREO_RECEPCION_PEDIDOS: payload.CORREO_RECEPCION_PEDIDOS,
                OBSERVACIONES: payload.OBSERVACIONES,
                ID_TELEGRAM: payload.ID_TELEGRAM,
                MOSTRADOR_POR_DEFECTO_TELEGRAM: payload.MOSTRADOR_POR_DEFECTO_TELEGRAM,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const GetEmpresaEnAPI = () => {
    let getCookieUser = JSON.parse(localStorage.getItem("usersertec"));
    let accesoLicencia = getCookieUser.LoginSertec;
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/getEmpresas', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                REGSPAGE: 1000,
                PAGE: 1,
                ORDERCOLUM: 1,
                ORDERDIR: "A",
                ID: "",
                ID_USUARIO_SERTEC: accesoLicencia.ID,
                ID_TALLER: accesoLicencia.ID_TALLER,
                RAZON: "",
                CIF: "",
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.EMPRESAS
            }
        })
}





const SetEmpresaEnAPI = (payload, logotipo) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/setEmpresa', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ACCION: 1,
                ID: payload.ID,
                ID_USUARIO_SERTEC: payload.ID_USUARIO_SERTEC,
                ID_TALLER: payload.ID_TALLER,
                RAZON: payload.RAZON,
                CIF: payload.CIF,
                DIRECCION: payload.DIRECCION,
                POBLACION: payload.POBLACION,
                PROVINCIA: payload.PROVINCIA,
                CP: payload.CP,
                PAIS: payload.PAIS,
                TELEFONO: payload.TELEFONO,
                TELEFONO_2: payload.TELEFONO_2,
                TELEFONO_3: payload.TELEFONO_3,
                MAIL: payload.MAIL,
                OBSERVACIONES: payload.OBSERVACIONES,
                EMAIL: payload.EMAIL,
                PRECIO_MANO_OBRA: payload.PRECIO_MANO_OBRA,
                CIENTO_IVA: payload.CIENTO_IVA,
                LOGOTIPO: logotipo.modificado,
                COMENTARIOS_PIE_PRESUPUESTO: payload.COMENTARIOS_PIE_PRESUPUESTO,
                REGISTRO_MERCANTIL: payload.REGISTRO_MERCANTIL,
                TIPO_LOGOTIPO: logotipo.tipoLogotipo,
                MARGEN_NEUMATICOS_FIJO: payload.MARGEN_NEUMATICOS_FIJO,
                MARGEN_NEUMATICOS_PORCENTAJE: payload.MARGEN_NEUMATICOS_PORCENTAJE,
                Token: obtenerToken()

            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const GetIdiomasEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Usuario/GetIdiomas', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID: null,
                CODIGO: null,
                NOMBRE: null,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.IDIOMAS
            }
        })
}


const getFabricantesEnAPI = (TIPO_VEHICULO) => {
    const VEHICULO = TIPO_VEHICULO === undefined && TIPO_VEHICULO === null && TIPO_VEHICULO === "" ? 12 : TIPO_VEHICULO
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Vehiculo/getFabricantes', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                TIPO_VEHICULO: VEHICULO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.MARCASFABRICANTES
            }
        })
}

const getModelosTurismosEnAPI = (FABRICNATE) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Vehiculo/getModelosTurismos', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                FABRICANTE: FABRICNATE,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.MODELOSTURISMOS
            }
        })
}

const getMotorizacionesTurismosEnAPI = (MODELO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Vehiculo/getMotorizacionesTurismos', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                MODELO: MODELO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.MOTORESTURISMOS
            }
        })
}


const EnviarPresupuestoEnAPI = (envio, payLoadLocal) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/EnviarPresupuesto', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_PRESUPUESTO: payLoadLocal.ID_PRESUPUESTO,
                REMITENTE: payLoadLocal.DESDE,
                DESTINATARIO: payLoadLocal.PARA,
                ASUNTO: payLoadLocal.ASUNTO,
                MENSAJE: payLoadLocal.CUERPO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const EnviarFacturaEnAPI = (envio, payLoadLocal) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/EnviarFactura', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_FACTURA: payLoadLocal.ID_FACTURA,
                REMITENTE: payLoadLocal.DESDE,
                DESTINATARIO: payLoadLocal.PARA,
                ASUNTO: payLoadLocal.ASUNTO,
                MENSAJE: payLoadLocal.CUERPO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const EnviarOrdenReparacionEnAPI = (envio, payLoadLocal) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/EnviarOrdenReparacion', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_ORDEN: payLoadLocal.ID_ORDEN,
                REMITENTE: payLoadLocal.DESDE,
                DESTINATARIO: payLoadLocal.PARA,
                ASUNTO: payLoadLocal.ASUNTO,
                MENSAJE: payLoadLocal.CUERPO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const ObtenerPresupuestoHTMLEnAPI = (ID_PRESUPUESTO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/ObtenerPresupuestoHTML', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_PRESUPUESTO: ID_PRESUPUESTO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.HTML
            }
        })
}

const ObtenerPresupuestoPDFEnAPI = (ID_PRESUPUESTO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/ObtenerPresupuestoPDF', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_PRESUPUESTO: ID_PRESUPUESTO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.BYTES
            }
        })
}

const ObtenerFacturaHTMLEnAPI = (ID_FACTURA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/ObtenerFacturaHTML', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_FACTURA: ID_FACTURA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerFacturaPDFEnAPI = (ID_FACTURA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/ObtenerFacturaPDF', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_FACTURA: ID_FACTURA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ModificarCestaTiempoReparacionEnAPI = (ID_TIEMPO_REPARACION, ID_VEHICULO_HEYNES, TAREAS_REPARACION_IDS) => {
    controller = new AbortController();
    let HEYNES_TOKEN = store.getState().usuario.LoginSertec.HEYNES.TOKEN_TECNICO_DIST;
    return fetch(endpoint1 + 'api/HaynesPro/ModificarCestaTiempoReparacion', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_TIEMPO_REPARACION: ID_TIEMPO_REPARACION,
                ID_VEHICULO_HEYNES: ID_VEHICULO_HEYNES,
                TAREAS_REPARACION_IDS: TAREAS_REPARACION_IDS,
                HEYNES_TOKEN: HEYNES_TOKEN,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.CESTA
            }
        })
}


const ObtenerReferenciasByGMAndVehicleEnAPI = (GRUPO_MONTAJE, VEHICLE_ID) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Extructura/ObtenerReferenciasByGMAndVehicle', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                GRUPO_MONTAJE: GRUPO_MONTAJE,
                VEHICLE_ID: VEHICLE_ID,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const ObtenerSistemasMantenimientoEnAPI = (ID_VIVID_VEHICULO, ID_TIEMPO_REPARACION) => {
    controller = new AbortController();
    let HEYNES_TOKEN = store.getState().usuario.LoginSertec.HEYNES.TOKEN_TECNICO_DIST;

    return fetch(endpoint1 + 'api/HaynesPro/ObtenerSistemasMantenimiento', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VIVID_VEHICULO: ID_VIVID_VEHICULO,
                ID_TIEMPO_REPARACION: ID_TIEMPO_REPARACION,
                HEYNES_TOKEN: HEYNES_TOKEN,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.MANTENIMIENTOS
            }
        })
}

const ObtenerTareasMantenimientoEnAPI = (ID_VIVID_VEHICULO, ID_TIEMPO_REPARACION, ID_SISTEMA_MANTENIMIENTO, ID_PERIODO) => {
    controller = new AbortController();
    let HEYNES_TOKEN = store.getState().usuario.LoginSertec.HEYNES.TOKEN_TECNICO_DIST;

    return fetch(endpoint1 + 'api/HaynesPro/ObtenerTareasMantenimiento', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VIVID_VEHICULO: ID_VIVID_VEHICULO,
                ID_TIEMPO_REPARACION: ID_TIEMPO_REPARACION,
                ID_SISTEMA_MANTENIMIENTO: ID_SISTEMA_MANTENIMIENTO,
                ID_PERIODO: ID_PERIODO,
                HEYNES_TOKEN: HEYNES_TOKEN,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.TAREAS_MANTENIMIENTO
            }
        })
}


const SolicitarInfoServicioEnAPI = (SERVICIO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/SolicitarInfoServicio', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                SERVICIO: SERVICIO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const RecuperarPwdEnAPI = (EMAIL) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Login/RecuperarPwd', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                EMAIL: EMAIL
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const getFabricantesV2EnAPI = (TIPO_VEHICULO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Vehiculo/getFabricantes', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                TIPO_VEHICULO: TIPO_VEHICULO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}





const ObtenerMotoresByFabricanteEnAPI = (FABRICANTE) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Vehiculo/ObtenerMotoresByFabricante', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                FABRICANTE: FABRICANTE,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const ObtenerMotorByCodigoMotorEnAPI = (CODIGO_MOTOR) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Vehiculo/ObtenerMotorByCodigoMotor', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                CODIGO_MOTOR: CODIGO_MOTOR,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerLubricantesByIdVehiculoTecdocEnAPI = (ID_VEHICULO_TECDOC) => {
    controller = new AbortController();
    let HEYNES_TOKEN = store.getState().usuario.LoginSertec.HEYNES.TOKEN_TECNICO_DIST;


    //return fetch(endpoint1 + 'api/HaynesPro/ObtenerLubricantesByIdVehiculoTecdoc'
    return fetch(endpoint1 + 'api/HaynesPro/ObtenerLubricantesConCapacidadesByIdVehTecdoc', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                HEYNES_TOKEN: HEYNES_TOKEN,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerModelosEjesEnAPI = (FABRICANTE) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Vehiculo/ObtenerModelosEjes', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                FABRICANTE: FABRICANTE,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerEjesByModeloEnAPI = (MODELO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Vehiculo/ObtenerEjesByModelo', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                MODELO: MODELO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const ObtenerArticuloERPEnAPI = (EQUIVALENCIAS, REFERENCIA, DESCRIPCION, CLIENTE,CANTIDAD) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/ConexionERP/ObtenerArticuloERP', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                CLIENTE: CLIENTE,
                EQUIVALENCIAS: EQUIVALENCIAS,
                REFERENCIA: REFERENCIA,
                DESCRIPCION: DESCRIPCION,
                CANTIDAD:CANTIDAD,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.ARTICULOS
            }
        })
}


const SetPortapapelesPresupuestosEnAPI = (ACCION, PAYLOAD) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/SetPortapapelesPresupuestos', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ACCION: ACCION,
                LINEA: {
                    ID: PAYLOAD.ID,
                    ID_USUARIO_SERTEC: PAYLOAD.ID_USUARIO_SERTEC,
                    TIPO_LINEA: PAYLOAD.TIPO_LINEA,
                    REFERENCIA_TECDOC: PAYLOAD.REFERENCIA_TECDOC,
                    REFERENCIA_SOCIO: PAYLOAD.REFERENCIA_SOCIO,
                    MARCA_SOCIO: PAYLOAD.MARCA_SOCIO,
                    MARCA_SOCIO_DESCRIPCION: PAYLOAD.MARCA_SOCIO_DESCRIPCION,
                    MARCA_TECDOC: PAYLOAD.MARCA_TECDOC,
                    MARCA_TECDOC_DESCRIPCION: PAYLOAD.MARCA_TECDOC_DESCRIPCION,
                    REFERENCIA_DESCRIPCION: PAYLOAD.REFERENCIA_DESCRIPCION,
                    MANO_OBRA: PAYLOAD.MANO_OBRA,
                    IVA_VALOR: PAYLOAD.IVA_VALOR,
                    DTO_VALOR: PAYLOAD.DTO_VALOR,
                    CANTIDAD: PAYLOAD.CANTIDAD,
                    IMPORTE_BRUTO: PAYLOAD.IMPORTE_BRUTO,
                    IMPORTE_DESCUENTOS: PAYLOAD.IMPORTE_DESCUENTOS,
                    IMPORTE_BASE_IMPONIBLE: PAYLOAD.IMPORTE_BASE_IMPONIBLE,
                    IMPORTE_IMPUESTOS: PAYLOAD.IMPORTE_IMPUESTOS,
                    IMPORTE_TOTAL: PAYLOAD.IMPORTE_TOTAL,
                    PRECIO: PAYLOAD.PRECIO,
                    DESCUENTO_COMPRA_TALLER: PAYLOAD.DESCUENTO_COMPRA_TALLER,

                    MATRICULA_VEHICULO: PAYLOAD.MATRICULA_VEHICULO,
                    BASTIDOR_VEHICULO: PAYLOAD.BASTIDOR_VEHICULO,
                    MARCA_VEHICULO: PAYLOAD.MARCA_VEHICULO,
                    MODELO_VEHICULO: PAYLOAD.MODELO_VEHICULO,
                    MOTOR_VEHICULO: PAYLOAD.MOTOR_VEHICULO,
                    ID_VEHICULO_TECDOC: PAYLOAD.ID_VEHICULO_TECDOC,
                    ES_NEUMATICO: PAYLOAD.ES_NEUMATICO

                },
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const LimpiarPortapapelesPresupuestosEnAPI = (ACCION) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/SetPortapapelesPresupuestos', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ACCION: ACCION,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const GetPortapapelesPresupuestosEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/GetPortapapelesPresupuestos', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                REGSPAGE: 1000,
                PAGE: 1,
                ORDERCOLUM: 1,
                ORDERDIR: "A",
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerNeumaticosAnchuraEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Neumaticos/ObtenerNeumaticosAnchura', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.REGISTROS
            }
        })
}

const ObtenerNeumaticosAlturaEnAPI = (ANCHURA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Neumaticos/ObtenerNeumaticosAltura', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ANCHURA: ANCHURA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.REGISTROS
            }
        })
}


const ObtenerNeumaticosDiametroEnAPI = (ANCHURA, ALTURA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Neumaticos/ObtenerNeumaticosDiametro', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ANCHURA: ANCHURA,
                ALTURA: ALTURA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.REGISTROS
            }
        })
}





const ObtenerNeumaticosVelocidadEnAPI = (ANCHURA, ALTURA, DIAMETRO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Neumaticos/ObtenerNeumaticosVelocidad', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ANCHURA: ANCHURA,
                ALTURA: ALTURA,
                DIAMETRO: DIAMETRO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.REGISTROS
            }
        })
}

const ObtenerNeumaticosCargaEnAPI = (ANCHURA, ALTURA, DIAMETRO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Neumaticos/ObtenerNeumaticosCarga', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ANCHURA: ANCHURA,
                ALTURA: ALTURA,
                DIAMETRO: DIAMETRO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.REGISTROS
            }
        })
}

const ObtenerNeumaticosEnAPI = (PAGE, ORDERCOLUM, ORDERDIR, PAYLOAD) => {
    let cliente = store.getState().clienteSeleccionado;

    controller = new AbortController();
    return fetch(endpoint1 + 'api/Neumaticos/ObtenerNeumaticos', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                REGSPAGE: 10000,
                CLIENTE: cliente.codigo,
                PAGE: PAGE,
                ORDERCOLUM: ORDERCOLUM,
                ORDERDIR: ORDERDIR,
                ID_NEUMATICO: PAYLOAD.ID_NEUMATICO,
                ANCHO_LARGO_LLANTA: PAYLOAD.ANCHO_LARGO_LLANTA,
                REFERENCIA_FABRICANTE: PAYLOAD.REFERENCIA_FABRICANTE,
                ANCHO: PAYLOAD.ANCHO,
                LARGO: PAYLOAD.LARGO,
                LLANTA: PAYLOAD.LLANTA,
                CARGA: PAYLOAD.CARGA,
                VELOCIDAD: PAYLOAD.VELOCIDAD,
                MARCA: PAYLOAD.MARCA,
                TEMPORADA: PAYLOAD.TEMPORADA,
                RUNFLAT: PAYLOAD.RUNFLAT,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const ObtenerNeumaticoImagenEnAPI = (ID_NEUMATICO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Neumaticos/ObtenerNeumaticoImagen', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_NEUMATICO: ID_NEUMATICO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerEngNoByEngCodeEnAPI = (ENGCODE) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Motorizaciones/ObtenerEngNoByEngCode', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ENGCODE: ENGCODE,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const ObtenerMotorizacionesTurismosByEngNoEnAPI = (ENGNO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Motorizaciones/ObtenerMotorizacionesTurismosByEngNo', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ENGNO: ENGNO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerHistoricoPedidosEnAPI = (PAGE, ORDERCOLUM, ORDERDIR, PAYLOAD) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/ObtenerHistoricoPedidos', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                REGSPAGE: 10000,
                PAGE: PAGE,
                ORDERCOLUM: ORDERCOLUM,
                ORDERDIR: ORDERDIR,
                ID: PAYLOAD.ID,
                CODIGO: PAYLOAD.CODIGO,
                FECHA_DESDE: PAYLOAD.FECHA_DESDE,
                FECHA_HASTA: PAYLOAD.FECHA_HASTA,
                REFERENCIA: PAYLOAD.REFERENCIA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const ObtenerCentroClienteERPEnAPI = () => {
    let cliente = store.getState().clienteSeleccionado;

    controller = new AbortController();

    return fetch(endpoint1 + 'api/ConexionERP/ObtenerCentroClienteERP', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                CLIENTE: cliente.codigo,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerAlbaranHTMLEnAPI = (PAYLOAD) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/ConexionERP/ObtenerAlbaranHTML', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ALBARAN: PAYLOAD,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}
const ObtenerAlbaranPDFEnAPI = (PAYLOAD) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/ConexionERP/ObtenerAlbaranPDF', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ALBARAN: PAYLOAD,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const ObtenerPedidoPDFPDFEnAPI = (ID_PEDIDO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/ObtenerPedidoPDF', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_PEDIDO: ID_PEDIDO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const ObtenerPedidoHTMLEnAPI = (ID_PEDIDO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/ObtenerPedidoHTML', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_PEDIDO: ID_PEDIDO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const getProveedoresEnAPI = (PAGE, ORDERCOLUM, ORDERDIR, ID, CODIGO, NOMBRE, CIF) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/getProveedores', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                REGSPAGE: 1000,
                PAGE: PAGE,
                ORDERCOLUM: ORDERCOLUM,
                ORDERDIR: ORDERDIR,
                ID: ID,
                CODIGO: CODIGO,
                NOMBRE: NOMBRE,
                CIF: CIF,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return null
                } else {
                    return res;
                }
            }
        })
}

const setProveedoresEnAPI = (ACCION, PAYLOAD) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/setProveedores', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ACCION: ACCION,
                ID: PAYLOAD.ID,
                CODIGO: PAYLOAD.CODIGO,
                NOMBRE: PAYLOAD.NOMBRE,
                DIRECCION: PAYLOAD.DIRECCION,
                POBLACION: PAYLOAD.POBLACION,
                PROVINCIA: PAYLOAD.PROVINCIA,
                CP: PAYLOAD.CP,
                CIF: PAYLOAD.CIF,
                PAIS: PAYLOAD.PAIS,
                TELEFONO: PAYLOAD.TELEFONO,
                TELEFONO_2: PAYLOAD.TELEFONO_2,
                TELEFONO_3: PAYLOAD.TELEFONO_3,
                MAIL: PAYLOAD.MAIL,
                OBSERVACIONES: PAYLOAD.OBSERVACIONES,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return null
                } else {
                    return res;
                }
            }
        })
}


const getOrdenesReparacionTallerEnAPI = (id, ORDERCOLUM, ORDERDIR, DESDE, HASTA, ESTADO) => {
    controller = new AbortController();
    return fetch(endpoint1 + "api/Gestion/getOrdenesReparacion", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                REGSPAGE: 10000,
                PAGE: 1,
                ORDERCOLUM: ORDERCOLUM,
                ORDERDIR: ORDERDIR,
                ID: id,
                CODIGO: "",
                FECHA_DESDE: DESDE,
                FECHA_HASTA: HASTA,
                ID_VEHICULO: "",
                VEH_MATRICULA: "",
                ID_CLIENTE: "",
                CLI_NOMBRE: "",
                ESTADO: ESTADO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return res;
                } else {
                    return res;
                }
            }
        })
}


const setOrdenesReparacionTallerEnAPI = (ACCION, DATOS_USUARIO, LINEAS) => {
    controller = new AbortController();

    DATOS_USUARIO.VALOR_IVA_1 = typeof DATOS_USUARIO.VALOR_IVA_1 === 'number' ? DATOS_USUARIO.VALOR_IVA_1 : DATOS_USUARIO.VALOR_IVA_1.replace("%", "");


    return fetch(endpoint1 + "api/Gestion/setOrdenesReparacion", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ACCION: ACCION,
                ID: DATOS_USUARIO.ID,
                ID_FACTURA: DATOS_USUARIO.ID_FACTURA,
                VEH_FECHA_ENTRADA: DATOS_USUARIO.VEH_FECHA_ENTRADA,
                VEH_FECHA_PREVISTA_SALIDA: DATOS_USUARIO.VEH_FECHA_PREVISTA_SALIDA,
                VEH_KILOMETRAJE: DATOS_USUARIO.VEH_KILOMETRAJE,
                VEH_BASTIDOR: DATOS_USUARIO.VEH_BASTIDOR,

                CODIGO: DATOS_USUARIO.CODIGO,
                FECHA: DATOS_USUARIO.FECHA,
                ID_VEHICULO: DATOS_USUARIO.ID_VEHICULO,
                VEH_MATRICULA: DATOS_USUARIO.VEH_MATRICULA,
                VEH_DENOMINACION: DATOS_USUARIO.VEH_DENOMINACION,
                VEH_ID_TECDOC: DATOS_USUARIO.VEH_ID_TECDOC,
                ID_CLIENTE: DATOS_USUARIO.ID_CLIENTE,
                CLI_NOMBRE: DATOS_USUARIO.CLI_NOMBRE,
                CLI_CIF: DATOS_USUARIO.CLI_CIF,
                CLI_DIRECCION: DATOS_USUARIO.CLI_DIRECCION,
                CLI_POBLACION: DATOS_USUARIO.CLI_POBLACION,
                CLI_PROVINCIA: DATOS_USUARIO.CLI_PROVINCIA,
                CLI_CP: DATOS_USUARIO.CLI_CP,
                CLI_PAIS: DATOS_USUARIO.CLI_PAIS,
                CLI_TELEFONO: DATOS_USUARIO.CLI_TELEFONO,
                CLI_TELEFONO_2: DATOS_USUARIO.CLI_TELEFONO_2,
                CLI_TELEFONO_3: DATOS_USUARIO.CLI_TELEFONO_3,
                CLI_MAIL: DATOS_USUARIO.CLI_MAIL,
                VALOR_DTO_GENERAL: DATOS_USUARIO.VALOR_DTO_GENERAL,
                VALOR_IVA_1: DATOS_USUARIO.VALOR_IVA_1,
                VALOR_IVA_2: DATOS_USUARIO.VALOR_IVA_2,
                VALOR_IVA_3: DATOS_USUARIO.VALOR_IVA_3,
                IMPORTE_BRUTO_1: DATOS_USUARIO.IMPORTE_BRUTO_1,
                IMPORTE_BRUTO_2: DATOS_USUARIO.IMPORTE_BRUTO_2,
                IMPORTE_BRUTO_3: DATOS_USUARIO.IMPORTE_BRUTO_3,
                IMPORTE_BRUTO: DATOS_USUARIO.IMPORTE_BRUTO,
                IMPORTE_DESCUENTOS_1: DATOS_USUARIO.IMPORTE_DESCUENTOS_1,
                IMPORTE_DESCUENTOS_2: DATOS_USUARIO.IMPORTE_DESCUENTOS_2,
                IMPORTE_DESCUENTOS_3: DATOS_USUARIO.IMPORTE_DESCUENTOS_3,
                IMPORTE_DESCUENTOS: DATOS_USUARIO.IMPORTE_DESCUENTOS,
                IMPORTE_BASE_IMPONIBLE_1: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE_1,
                IMPORTE_BASE_IMPONIBLE_2: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE_2,
                IMPORTE_BASE_IMPONIBLE_3: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE_3,
                IMPORTE_BASE_IMPONIBLE: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE,
                IMPORTE_IMPUESTOS_1: DATOS_USUARIO.IMPORTE_IMPUESTOS_1,
                IMPORTE_IMPUESTOS_2: DATOS_USUARIO.IMPORTE_IMPUESTOS_2,
                IMPORTE_IMPUESTOS_3: DATOS_USUARIO.IMPORTE_IMPUESTOS_3,
                IMPORTE_TOTAL_1: DATOS_USUARIO.IMPORTE_TOTAL_1,
                IMPORTE_TOTAL_2: DATOS_USUARIO.IMPORTE_TOTAL_2,
                IMPORTE_TOTAL_3: DATOS_USUARIO.IMPORTE_TOTAL_3,
                IMPORTE_TOTAL: DATOS_USUARIO.IMPORTE_TOTAL,
                OBSERVACIONES_ORDEN_REPARACION: DATOS_USUARIO.OBSERVACIONES_ORDEN_REPARACION,
                IMPRIMIDO: DATOS_USUARIO.IMPRIMIDO,
                ENVIADO: DATOS_USUARIO.ENVIADO,
                FECHA_IMPRESION: DATOS_USUARIO.FECHA_IMPRESION,
                FECHA_ENVIO: DATOS_USUARIO.FECHA_ENVIO,
                ESTADO: DATOS_USUARIO.ESTADO,
                LINEAS: LINEAS,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return res;
                } else {
                    return res;
                }
            }
        })
}


const getFacturasEnAPI = (id, ORDERCOLUM, ORDERDIR, DESDE, HASTA, ESTADO) => {
    controller = new AbortController();
    return fetch(endpoint1 + "api/Gestion/getFacturas", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                REGSPAGE: 10000,
                PAGE: 1,
                ORDERCOLUM: ORDERCOLUM,
                ORDERDIR: ORDERDIR,
                ID: id,
                CODIGO: "",
                FECHA_DESDE: DESDE,
                FECHA_HASTA: HASTA,
                ID_VEHICULO: "",
                VEH_MATRICULA: "",
                ID_CLIENTE: "",
                CLI_NOMBRE: "",
                ESTADO: ESTADO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return res;
                } else {
                    return res;
                }
            }
        })
}


const setFacturaEnAPI = (ACCION, DATOS_USUARIO, LINEAS) => {
    controller = new AbortController();
    DATOS_USUARIO.VALOR_IVA_1 = typeof DATOS_USUARIO.VALOR_IVA_1 === 'number' ? DATOS_USUARIO.VALOR_IVA_1 : DATOS_USUARIO.VALOR_IVA_1.replace("%", "");


    return fetch(endpoint1 + "api/Gestion/setFactura", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ACCION: ACCION,
                ID: DATOS_USUARIO.ID,
                CODIGO: DATOS_USUARIO.CODIGO,
                FECHA: DATOS_USUARIO.FECHA,
                ID_VEHICULO: DATOS_USUARIO.ID_VEHICULO,
                VEH_BASTIDOR: DATOS_USUARIO.VEH_BASTIDOR,

                VEH_MATRICULA: DATOS_USUARIO.VEH_MATRICULA,
                VEH_DENOMINACION: DATOS_USUARIO.VEH_DENOMINACION,
                VEH_ID_TECDOC: DATOS_USUARIO.VEH_ID_TECDOC,
                ID_CLIENTE: DATOS_USUARIO.ID_CLIENTE,
                CLI_NOMBRE: DATOS_USUARIO.CLI_NOMBRE,
                CLI_CIF: DATOS_USUARIO.CLI_CIF,
                CLI_DIRECCION: DATOS_USUARIO.CLI_DIRECCION,
                CLI_POBLACION: DATOS_USUARIO.CLI_POBLACION,
                CLI_PROVINCIA: DATOS_USUARIO.CLI_PROVINCIA,
                CLI_CP: DATOS_USUARIO.CLI_CP,
                CLI_PAIS: DATOS_USUARIO.CLI_PAIS,
                CLI_TELEFONO: DATOS_USUARIO.CLI_TELEFONO,
                CLI_TELEFONO_2: DATOS_USUARIO.CLI_TELEFONO_2,
                CLI_TELEFONO_3: DATOS_USUARIO.CLI_TELEFONO_3,
                CLI_MAIL: DATOS_USUARIO.CLI_MAIL,
                VALOR_DTO_GENERAL: DATOS_USUARIO.VALOR_DTO_GENERAL,
                VALOR_IVA_1: DATOS_USUARIO.VALOR_IVA_1,
                VALOR_IVA_2: DATOS_USUARIO.VALOR_IVA_2,
                VALOR_IVA_3: DATOS_USUARIO.VALOR_IVA_3,
                IMPORTE_BRUTO_1: DATOS_USUARIO.IMPORTE_BRUTO_1,
                IMPORTE_BRUTO_2: DATOS_USUARIO.IMPORTE_BRUTO_2,
                IMPORTE_BRUTO_3: DATOS_USUARIO.IMPORTE_BRUTO_3,
                IMPORTE_BRUTO: DATOS_USUARIO.IMPORTE_BRUTO,
                IMPORTE_DESCUENTOS_1: DATOS_USUARIO.IMPORTE_DESCUENTOS_1,
                IMPORTE_DESCUENTOS_2: DATOS_USUARIO.IMPORTE_DESCUENTOS_2,
                IMPORTE_DESCUENTOS_3: DATOS_USUARIO.IMPORTE_DESCUENTOS_3,
                IMPORTE_DESCUENTOS: DATOS_USUARIO.IMPORTE_DESCUENTOS,
                IMPORTE_BASE_IMPONIBLE_1: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE_1,
                IMPORTE_BASE_IMPONIBLE_2: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE_2,
                IMPORTE_BASE_IMPONIBLE_3: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE_3,
                IMPORTE_BASE_IMPONIBLE: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE,
                IMPORTE_IMPUESTOS_1: DATOS_USUARIO.IMPORTE_IMPUESTOS_1,
                IMPORTE_IMPUESTOS_2: DATOS_USUARIO.IMPORTE_IMPUESTOS_2,
                IMPORTE_IMPUESTOS_3: DATOS_USUARIO.IMPORTE_IMPUESTOS_3,
                IMPORTE_TOTAL_1: DATOS_USUARIO.IMPORTE_TOTAL_1,
                IMPORTE_TOTAL_2: DATOS_USUARIO.IMPORTE_TOTAL_2,
                IMPORTE_TOTAL_3: DATOS_USUARIO.IMPORTE_TOTAL_3,
                IMPORTE_TOTAL: DATOS_USUARIO.IMPORTE_TOTAL,
                OBSERVACIONES_FACTURA: DATOS_USUARIO.OBSERVACIONES_FACTURA,
                IMPRIMIDO: DATOS_USUARIO.IMPRIMIDO,
                ENVIADO: DATOS_USUARIO.ENVIADO,
                FECHA_IMPRESION: DATOS_USUARIO.FECHA_IMPRESION,
                FECHA_ENVIO: DATOS_USUARIO.FECHA_ENVIO,
                ESTADO: DATOS_USUARIO.ESTADO,
                LINEAS: LINEAS,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return res;
                } else {
                    return res;
                }
            }
        })
}

const ObtenerOrdenReparacionPDFEnAPI = (ID_ORDEN) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/ObtenerOrdenReparacionPDF', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_ORDEN: ID_ORDEN,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const ObtenerOrdenReparacionHTMLEnAPI = (ID_ORDEN) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/ObtenerOrdenReparacionHTML', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_ORDEN: ID_ORDEN,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const ObtenerFormulariosOrHTMLEnAPI = (ID_ORDEN, TIPO_FORMULARIO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/ObtenerFormulariosOrHTML', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_ORDEN: ID_ORDEN,
                TIPO_FORMULARIO: TIPO_FORMULARIO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const ObtenerFormulariosOrPDFEnAPI = (ID_ORDEN, TIPO_FORMULARIO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/ObtenerFormulariosOrPDF', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_ORDEN: ID_ORDEN,
                TIPO_FORMULARIO: TIPO_FORMULARIO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




const PresupuestoToOrdenReparacionEnAPI = (ID_PRESUPUESTO, ID_CLIENTE, ID_VEHICULO, FECHA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/PresupuestoToOrdenReparacion', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_PRESUPUESTO: ID_PRESUPUESTO,
                OR_FECHA: FECHA,
                OR_ID_CLIENTE: ID_CLIENTE,
                OR_ID_VEHICULO: ID_VEHICULO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const OrdenReparacionToFacturaEnAPI = (ID_ORDEN_REPARACION, ID_CLIENTE, ID_VEHICULO, FECHA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/OrdenReparacionToFactura', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_ORDEN_REPARACION: ID_ORDEN_REPARACION,
                FAC_FECHA: FECHA,
                FAC_ID_CLIENTE: ID_CLIENTE,
                FAC_ID_VEHICULO: ID_VEHICULO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const GetUrlGTmotiveEnAPI = (VIN, MATRICULA, CODIGO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/GTMotive/GetUrlGTEstimate', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                VIN: VIN,
                MATRICULA: MATRICULA,
                ID_PRESUPUESTO_GTESTIMATE: CODIGO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const GetPresupuestoGTEnAPI = (CODIGO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/GTMotive/GetPresupuesto', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                CODIGO_DOCUMENTO: CODIGO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const DuplicarFacturaEnAPI = (ID_FACTURA, ID_CLIENTE, ID_VEHICULO, FECHA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/DuplicarFactura', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_FACTURA: ID_FACTURA,
                FAC_FECHA: FECHA,
                FAC_ID_CLIENTE: ID_CLIENTE,
                FAC_ID_VEHICULO: ID_VEHICULO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const DuplicarOrdenReparacionEnAPI = (ID_ORDEN_REPARACION, ID_CLIENTE, ID_VEHICULO, FECHA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/DuplicarOrdenReparacion', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_ORDEN_REPARACION: ID_ORDEN_REPARACION,
                OR_FECHA: FECHA,
                OR_ID_CLIENTE: ID_CLIENTE,
                OR_ID_VEHICULO: ID_VEHICULO,

                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const DuplicarPresupuestoEnAPI = (ID_PRESUPUESTO, ID_CLIENTE, ID_VEHICULO, FECHA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/DuplicarPresupuesto', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_PRESUPUESTO: ID_PRESUPUESTO,
                PRE_DUP_FECHA: FECHA,
                PRE_DUP_ID_CLIENTE: ID_CLIENTE,
                PRE_DUP_ID_VEHICULO: ID_VEHICULO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const TrazabilidadDocumentoEnAPI = (ID_DOCUMENTO, TIPO_DOCUMENTO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/TrazabilidadDocumento', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_DOCUMENTO: ID_DOCUMENTO,
                TIPO_DOCUMENTO: TIPO_DOCUMENTO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const AbonarFacturaEnAPI = (ID_DOCUMENTO, ID_CLIENTE, ID_VEHICULO, FECHA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/AbonarFactura', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_FACTURA: ID_DOCUMENTO,
                FECHA: FECHA,
                ID_CLIENTE: ID_CLIENTE,
                ID_VEHICULO: ID_VEHICULO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerLubricantesERPEnAPI = (VISCOSIDAD, CALIDAD) => {
    controller = new AbortController();
    let cliente = store.getState().clienteSeleccionado;
    //return LUBRICANTES

    return fetch(endpoint1 + 'api/ConexionERP/ObtenerLubricantesERP', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                CLIENTE: cliente.codigo,
                VISCOSIDAD: VISCOSIDAD,
                CALIDAD: CALIDAD,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })


}

const ObtenerBateriasERPEnAPI = (VOLTAJE, AMPERIOS, POTENCIA_ARRANQUE) => {
    controller = new AbortController();
    let cliente = store.getState().clienteSeleccionado;

    return fetch(endpoint1 + 'api/ConexionERP/ObtenerBateriasERP', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                CLIENTE: cliente.codigo,
                VOLTAJE: VOLTAJE,
                AMPERIOS: AMPERIOS,
                POTENCIA_ARRANQUE: POTENCIA_ARRANQUE,

                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const ObtenerMarcasIQEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + "api/CatalogoOEM/ObtenerMarcas", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerModelosIQEnAPI = (FILTRO, MARCA) => {
    controller = new AbortController();
    return fetch(endpoint1 + "api/CatalogoOEM/ObtenerModelos", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                FILTRO: FILTRO,
                MARCA: MARCA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const BucarVehiculoPorModeloEnAPI = (SSD, MARCA) => {
    controller = new AbortController();
    return fetch(endpoint1 + "/api/CatalogoOEM/BucarVehiculoPorModelo", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                SSD: SSD,
                MARCA: MARCA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerCategoriasEnAPI = (ID_VEHICULO, SSD, MARCA) => {
    controller = new AbortController();
    return fetch(endpoint1 + "/api/CatalogoOEM/ObtenerCategorias", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO: ID_VEHICULO,
                SSD: SSD,
                MARCA: MARCA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const BusquedaUniversalEnAPI = (ID_VEHICULO, SSD, MARCA) => {
    controller = new AbortController();
    return fetch(endpoint1 + "/api/CatalogoOEM/BusquedaUniversal", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO: ID_VEHICULO,
                SSD: SSD,
                MARCA: MARCA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerGraficosEnAPI = (ID_VEHICULO, ID_CATEGORIA, SSD, MARCA) => {
    controller = new AbortController();
    return fetch(endpoint1 + "/api/CatalogoOEM/ObtenerGraficos", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO: ID_VEHICULO,
                ID_CATEGORIA: ID_CATEGORIA,
                SSD: SSD,
                MARCA: MARCA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const BuscarVehiculoPorVinMarcaEnAPI = (VIN, MARCA) => {
    controller = new AbortController();
    return fetch(endpoint1 + "/api/CatalogoOEM/BuscarVehiculoPorVinMarca", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                VIN: VIN,
                MARCA: MARCA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const ObtenerListaDetalleRapidoEnAPI = (ID_VEHICULO, ID_GRUPO, SSD, MARCA) => {
    controller = new AbortController();
    return fetch(endpoint1 + "/api/CatalogoOEM/ObtenerListaDetalleRapido", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                MARCA: MARCA,
                ID_VEHICULO: ID_VEHICULO,
                SSD: SSD,
                ID_GRUPO: ID_GRUPO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerDetalleGraficoEnAPI = (ID_UNIDAD, MARCA, SSD) => {
    controller = new AbortController();
    return fetch(endpoint1 + "/api/CatalogoOEM/ObtenerDetalleGrafico", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_UNIDAD: ID_UNIDAD,
                MARCA: MARCA,
                SSD: SSD,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




const ObtenerListaMapaGraficosEnAPI = (ID_UNIDAD, MARCA, SSD) => {
    controller = new AbortController();
    return fetch(endpoint1 + "/api/CatalogoOEM/ObtenerListaMapaGraficos", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_UNIDAD: ID_UNIDAD,
                MARCA: MARCA,
                SSD: SSD,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerConversacionesEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + "/api/Telegram/ObtenerConversaciones", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const ObtenerMensajesnAPI = (ID_CONVERSACION, FECHA) => {
    controller = new AbortController();
    return fetch(endpoint1 + "/api/Telegram/ObtenerMensajes", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_CONVERSACION: ID_CONVERSACION,
                FECHA: FECHA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const EnviarMensajeEnAPI = (ID_CONVERSACION, ID_USUARIO_TELEGRAM_TO, MENSAJE) => {
    controller = new AbortController();
    return fetch(endpoint1 + "/api/Telegram/EnviarMensaje", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_CONVERSACION: ID_CONVERSACION,
                ID_USUARIO_TELEGRAM_TO: ID_USUARIO_TELEGRAM_TO,
                MENSAJE: MENSAJE,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const ObtenerNotificacionesEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + "/api/Gestion/ObtenerNotificaciones", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ActualizarNotificacionesClienteEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + "/api/Gestion/ActualizarNotificacionesCliente", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerArchivoEnAPI = (ID_ARCHIVO, NOMBRE_ARCHIVO, TIPO_ARCHIVO, EXTENSION_ARCHIVO) => {
    controller = new AbortController();
    return fetch(endpoint1 + "/api/Telegram/ObtenerArchivo", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_ARCHIVO: ID_ARCHIVO,
                NOMBRE_ARCHIVO: NOMBRE_ARCHIVO,
                TIPO_ARCHIVO: TIPO_ARCHIVO,
                EXTENSION_ARCHIVO: EXTENSION_ARCHIVO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const EnviarArchivoEnAPI = (ID_CONVERSACION, ID_USUARIO_TELEGRAM_TO, MENSAJE, NOMBRE_ARCHIVO, TIPO_ARCHIVO, EXTENSION_ARCHIVO, ES_IMAGEN_ARCHIVO, ARCHIVO) => {
    controller = new AbortController();
    return fetch(endpoint1 + "/api/Telegram/EnviarArchivo", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_CONVERSACION: ID_CONVERSACION,
                ID_USUARIO_TELEGRAM_TO: ID_USUARIO_TELEGRAM_TO,
                MENSAJE: MENSAJE,
                NOMBRE_ARCHIVO: NOMBRE_ARCHIVO,
                TIPO_ARCHIVO: TIPO_ARCHIVO,
                EXTENSION_ARCHIVO: EXTENSION_ARCHIVO,
                ES_IMAGEN_ARCHIVO: ES_IMAGEN_ARCHIVO,
                ARCHIVO: ARCHIVO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const ObtenerReferenciasBateriasByVehicleEnAPI = (ID_VEHICULO) => {
    let cliente = store.getState().clienteSeleccionado;
    let TIPO_VEHICULO = store.getState().TIPO_VEHICULO_POR_MODELO
    controller = new AbortController();
    return fetch(endpoint1 + "/api/Articulo/ObtenerReferenciasBateriasByVehicle", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO: ID_VEHICULO,
                CLIENTE: cliente.codigo,
                TIPO_VEHICULO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const VerificarPresusupuestoGTEnAPI = (ID_PRESUPUESTO_SERTEC, ID_PRESUPUESTO, CODIGO_PRESUPUESTO_GTESTIMATE) => {
    controller = new AbortController();
    return fetch(endpoint1 + "/api/GTMotive/ExistePresupuesto", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_PRESUPUESTO: ID_PRESUPUESTO,
                ID_PRESUPUESTO_SERTEC: ID_PRESUPUESTO_SERTEC,
                CODIGO_PRESUPUESTO_GTESTIMATE: CODIGO_PRESUPUESTO_GTESTIMATE,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const PermisoConsultaOEMEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + "/api/CatalogoOEM/PermisoConsultaOEM", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




const SetHistorialBusquedaOEMEnAPI = (TIPO_BUSQUEDA, BASTIDOR, MARCA, NOMBRE_VEHICULO) => {
    controller = new AbortController();
    return fetch(endpoint1 + "/api/CatalogoOEM/SetHistorialBusquedaOEM", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                TIPO_BUSQUEDA: TIPO_BUSQUEDA,
                BASTIDOR: BASTIDOR,
                MARCA: MARCA,
                NOMBRE_VEHICULO: NOMBRE_VEHICULO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const GetSmartPackEnAPI = (ID_VEHICULO) => {
    controller = new AbortController();
    let HEYNES_TOKEN = store.getState().usuario.LoginSertec.HEYNES.TOKEN_TECNICO_SMART;

    return fetch(endpoint1 + 'api/HaynesPro/GetSmartPack', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ID_VEHICULO: ID_VEHICULO,
            GRUPO: "",
            HEYNES_TOKEN: HEYNES_TOKEN,

            Token: obtenerToken()
        }

        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const GetDetalleSmartPackEnAPI = (ID_SISTEMA, TIPO_TBS) => {
    controller = new AbortController();
    let HEYNES_TOKEN = store.getState().usuario.LoginSertec.HEYNES.TOKEN_TECNICO_SMART;

    return fetch(endpoint1 + 'api/HaynesPro/GetDetalleSmartPack', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_SISTEMA: ID_SISTEMA,
                TIPO_TBS: TIPO_TBS,

                HEYNES_TOKEN: HEYNES_TOKEN,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const ImprimirFacturaEnAPI = (ID_FACTURA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/ImprimirFactura', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_FACTURA: ID_FACTURA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const ObtenerExpedicionesEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Reparto/ObtenerExpediciones', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {

                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




const ObtenerManualesReparacionEnAPI = (ID_VEHICULO_TECDOC) => {
    controller = new AbortController();
    let TOKEN_TECNICO_DIST_RM = store.getState().usuario.LoginSertec.HEYNES.TOKEN_TECNICO_DIST_RM;

    return fetch(endpoint1 + 'api/HaynesPro/ObtenerManualesReparacion', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                HEYNES_TOKEN_DIST_RM: TOKEN_TECNICO_DIST_RM,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const ObtenerManualesReparacionByGmEnAPI = (ID_VEHICULO_TECDOC, ID_MANUAL) => {
    controller = new AbortController();
    let TOKEN_TECNICO_DIST_RM = store.getState().usuario.LoginSertec.HEYNES.TOKEN_TECNICO_DIST_RM;

    return fetch(endpoint1 + 'api/HaynesPro/ObtenerManualReparacion', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                ID_MANUAL: ID_MANUAL,
                HEYNES_TOKEN_DIST_RM: TOKEN_TECNICO_DIST_RM,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const ObtenerReferenciasByVehicleFASTEnAPI = (ID_VEHICULO, TIPO_FAST) => {
    controller = new AbortController();
    let cliente = store.getState().clienteSeleccionado;

    return fetch(endpoint1 + 'api/Articulo/ObtenerReferenciasByVehicleFAST', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Cliente: cliente.codigo,
                ID_VEHICULO: ID_VEHICULO,
                TIPO_FAST: TIPO_FAST,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerCobrosEnAPI = (ID, CODIGO, ID_DOCUMENTO, TIPO_DOCUMENTO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Cobros/ObtenerCobros', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID: ID,
                CODIGO: CODIGO,
                ID_DOCUMENTO: ID_DOCUMENTO,
                TIPO_DOCUMENTO: TIPO_DOCUMENTO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const GuardarCobroEnAPI = (PAYLOAD) => {
    controller = new AbortController();

    return fetch(endpoint1 + 'api/Cobros/GuardarCobro', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ACCION: PAYLOAD.ACCION,
                ID: PAYLOAD.ID,
                ID_DOCUMENTO: PAYLOAD.ID_DOCUMENTO,
                TIPO_DOCUMENTO: PAYLOAD.TIPO_DOCUMENTO,
                FECHA: PAYLOAD.FECHA,
                OBSERVACIONES: PAYLOAD.OBSERVACIONES,
                IMPORTE: PAYLOAD.IMPORTE,
                FORMA_PAGO: PAYLOAD.FORMA_PAGO,
                DESCRIPCION: "RT",
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const FormasPagoEnAPI = () => {
    controller = new AbortController();

    return fetch(endpoint1 + 'api/Cobros/FormasPago', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const pagoTpvEnApi = async (PAYLOAD) => {
    controller = new AbortController();
    try {
        await fetch("https://172.16.235.246:2001/v1/transactions/init", {
            method: 'POST',
            signal: controller.signal,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
            dataType: "json",
        })


        const pago = await fetch("https://172.16.235.246:2001/v1/transactions/payment", {
            method: 'POST',
            signal: controller.signal,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "orderId": PAYLOAD.ID_DOCUMENTO,
                "amount": PAYLOAD.IMPORTE,
                "description": PAYLOAD.OBSERVACIONES
            }),
            dataType: "json",
        })

        return pago;

    } catch (error) {
        console.log("err", error.message)
    }
}




const GetAnuncioEnAPI = (ID, TIPO) => {
    controller = new AbortController();

    return fetch(endpoint1 + 'api/Marketing/GetAnuncio', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID: ID,
                TIPO: TIPO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const setHistorialDocumentacionTecnicaEnAPI = (ID_VEHICULO_TECDOC, TIPO_BUSQUEDA) => {
    controller = new AbortController();

    return fetch(endpoint1 + 'api/Historial/setHistorialDocumentacionTecnica', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                TIPO_BUSQUEDA: TIPO_BUSQUEDA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}





const ObtenerSociosEnAPI = () => {
    controller = new AbortController();

    return fetch(endpoint1 + 'api/Stocks/ObtenerSocios', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




const ObtenerStockSocioEnAPI = (SOCIO, REFERENCIA_TECDOC, MARCA_TECDOC, TIPO_ARTICULO) => {
    controller = new AbortController();

    return fetch(endpoint1 + 'api/Stocks/ObtenerStockSocio', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                SOCIO: SOCIO,
                REFERENCIA_TECDOC: REFERENCIA_TECDOC,
                MARCA_TECDOC: MARCA_TECDOC,
                TIPO_ARTICULO: TIPO_ARTICULO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}





const RealizaPedidoSocioEnAPI = (OBJETO_PEDIDO) => {
    controller = new AbortController();
    OBJETO_PEDIDO.Token = obtenerToken()

    return fetch(endpoint1 + 'api/Stocks/RealizaPedidoSocio', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            OBJETO_PEDIDO
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerFabricantesEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/CatalogoMotos/ObtenerFabricantes', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const ObtenerModelosEnAPI = (ID_FABRICANTE) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/CatalogoMotos/ObtenerModelos', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_FABRICANTE: ID_FABRICANTE,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const ObtenerAjustesByVehiculoTecdocEnAPI = (ID_VEHICULO_TECDOC, HEYNES_TOKEN_DIST) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/HaynesPro/ObtenerAjustesByVehiculoTecdoc', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                HEYNES_TOKEN_DIST: HEYNES_TOKEN_DIST,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




const ObtenerNeumaticosVehiculoEnAPI = (ID_VEHICULO_TECDOC, HEYNES_TOKEN_DIST) => {
    let cliente = store.getState().clienteSeleccionado;

    controller = new AbortController();
    return fetch(endpoint1 + 'api/Neumaticos/ObtenerNeumaticosVehiculo', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                HEYNES_TOKEN_DIST: HEYNES_TOKEN_DIST,
                CLIENTE: cliente.codigo,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const ObtenerNeumaticoInfoEnAPI = (ID_NEUMATICO, EAN, LIPKO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Neumaticos/ObtenerNeumaticoInfo', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_NEUMATICO: ID_NEUMATICO,
                EAN: EAN,
                LIPKO: LIPKO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




const ObtenerImagenNeumaticoEnAPI = (URL_IMAGEN) => {

    controller = new AbortController();
    let myHeaders = new Headers();

    return fetch(URL_IMAGEN, {
        method: 'GET',
        signal: controller.signal,
        headers: myHeaders,
    }).then(res => res.text())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }

                return res
            }
        })
}




const ObtenerNeumaticoImagenOriginalEnAPI = (URL) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Neumaticos/ObtenerNeumaticoImagenOriginal', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                URL: URL,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {

                return res
            }
        })
}









const ObtenerNeumaticosCatalogoEnAPI = (REGSPAGE, PAGE, ORDERCOLUM, ORDERDIR, PAYLOAD) => {
    let cliente = store.getState().clienteSeleccionado;

    controller = new AbortController();
    return fetch(endpoint1 + 'api/Neumaticos/ObtenerNeumaticosCatalogo', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                REGSPAGE: REGSPAGE,
                CLIENTE: cliente.codigo,
                PAGE: PAGE,
                ORDERCOLUM: ORDERCOLUM,
                ORDERDIR: ORDERDIR,
                ID_NEUMATICO: PAYLOAD.ID_NEUMATICO,
                ANCHO_LARGO_LLANTA: PAYLOAD.ANCHO_LARGO_LLANTA,
                REFERENCIA_FABRICANTE: PAYLOAD.REFERENCIA_FABRICANTE,
                ANCHO: PAYLOAD.ANCHO,
                LARGO: PAYLOAD.LARGO,
                LLANTA: PAYLOAD.LLANTA,
                CARGA: PAYLOAD.CARGA,
                VELOCIDAD: PAYLOAD.VELOCIDAD,
                MARCA: PAYLOAD.MARCA,
                TEMPORADA: PAYLOAD.TEMPORADA,
                RUNFLAT: PAYLOAD.RUNFLAT,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const ObtenerNeumaticosMarcaEnAPI = (MEDIDAS_UNIFICADAS) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Neumaticos/ObtenerNeumaticosMarca', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                MEDIDAS_UNIFICADAS: MEDIDAS_UNIFICADAS,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerNeumaticosAnchoEnAPI = (ID_MARCA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Neumaticos/ObtenerNeumaticosAncho', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_MARCA: ID_MARCA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerNeumaticosLargoEnAPI = (ANCHO, ID_MARCA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Neumaticos/ObtenerNeumaticosLargo', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ANCHO: ANCHO,
                ID_MARCA: ID_MARCA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerNeumaticosLlantaEnAPI = (ANCHO, LARGO, ID_MARCA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Neumaticos/ObtenerNeumaticosLlanta', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ANCHO: ANCHO,
                LARGO, LARGO,
                ID_MARCA: ID_MARCA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerNeumaticoVelocidadEnAPI = (ANCHO, LARGO, LLANTA, CARGA, ID_MARCA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Neumaticos/ObtenerNeumaticoVelocidad', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ANCHO: ANCHO,
                LARGO: LARGO,
                LLANTA: LLANTA,
                CARGA: CARGA,
                ID_MARCA: ID_MARCA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerNeumaticoCargaEnAPI = (ANCHO, LARGO, LLANTA, ID_MARCA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Neumaticos/ObtenerNeumaticoCarga', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ANCHO: ANCHO,
                LARGO: LARGO,
                LLANTA: LLANTA,
                ID_MARCA: ID_MARCA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




const ObtenerGMPorIdModeloEnAPI = (ID_MODELO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/CatalogoMotos/ObtenerGMPorIdModelo', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_MODELO: ID_MODELO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const ObtenerArticulosByDescripcionEnAPI = (DESCRIPCION) => {
    let cliente = store.getState().clienteSeleccionado;

    controller = new AbortController();
    return fetch(endpoint1 + 'api/CatalogoMotos/ObtenerArticulosByDescripcion', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                DESCRIPCION: DESCRIPCION,
                CLIENTE: cliente.codigo,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}





const obtenerLlubricantesFluidosEnAPI = (ID_VEHICULO_TECDOC) => {
    let TOKEN_ESTIMATOR_TOOLS = store.getState().usuario.LoginSertec.TECRMI.TOKEN_ESTIMATOR_TOOLS;

    controller = new AbortController();
    return fetch(endpoint1 + 'api/TECRMI/ObtenerLubricantesFluidos', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                TOKEN_ESTIMATOR_TOOLS: TOKEN_ESTIMATOR_TOOLS,
                Token: obtenerToken()

            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




const ObtenerListaManualEnAPI = (ID_VEHICULO_TECDOC) => {
    let TOKEN_ESTIMATOR_TOOLS = store.getState().usuario.LoginSertec.TECRMI.TOKEN_ESTIMATOR_TOOLS;

    controller = new AbortController();
    return fetch(endpoint1 + 'api/TECRMI/ObtenerListaManual', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                TOKEN_ESTIMATOR_TOOLS: TOKEN_ESTIMATOR_TOOLS,
                Token: obtenerToken()

            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




const ObtenerUnManualEnAPI = (ID_VEHICULO_TECDOC, ID_MANUAL) => {
    let TOKEN_ESTIMATOR_TOOLS = store.getState().usuario.LoginSertec.TECRMI.TOKEN_ESTIMATOR_TOOLS;

    controller = new AbortController();
    return fetch(endpoint1 + 'api/TECRMI/ObtenerUnManual', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                TOKEN_ESTIMATOR_TOOLS: TOKEN_ESTIMATOR_TOOLS,
                ID_MANUAL: ID_MANUAL,
                Token: obtenerToken()

            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const ObtenerListaDatosTecnicosEnAPI = (ID_VEHICULO_TECDOC) => {
    let TOKEN_ESTIMATOR_TOOLS = store.getState().usuario.LoginSertec.TECRMI.TOKEN_ESTIMATOR_TOOLS;

    controller = new AbortController();
    return fetch(endpoint1 + 'api/TECRMI/ObtenerListaDatosTecnicos', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                TOKEN_ESTIMATOR_TOOLS: TOKEN_ESTIMATOR_TOOLS,
                Token: obtenerToken()

            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




const ObtenerUnDatoTecnicoHTMLEnAPI = (ID_VEHICULO_TECDOC, ID_GRUPO_PRINCIPAL, ID_SUBGRUPO, ID_ELEMENTO) => {
    let TOKEN_ESTIMATOR_TOOLS = store.getState().usuario.LoginSertec.TECRMI.TOKEN_ESTIMATOR_TOOLS;

    controller = new AbortController();
    return fetch(endpoint1 + 'api/TECRMI/ObtenerUnDatoTecnicoHTML', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                ID_GRUPO_PRINCIPAL: ID_GRUPO_PRINCIPAL,
                ID_SUBGRUPO: ID_SUBGRUPO,
                ID_ELEMENTO: ID_ELEMENTO,
                TOKEN_ESTIMATOR_TOOLS: TOKEN_ESTIMATOR_TOOLS,
                Token: obtenerToken()

            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




const obtenerTodosMantenimientosEnAPI = (ID_VEHICULO_TECDOC) => {
    let TOKEN_ESTIMATOR_TOOLS = store.getState().usuario.LoginSertec.TECRMI.TOKEN_ESTIMATOR_TOOLS;

    controller = new AbortController();
    return fetch(endpoint1 + 'api/TECRMI/obtenerTodosMantenimientos', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                TOKEN_ESTIMATOR_TOOLS: TOKEN_ESTIMATOR_TOOLS,
                Token: obtenerToken()

            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const ObtenerManualMantenimientoHTMLEnAPI = (ID_VEHICULO_TECDOC) => {
    let TOKEN_ESTIMATOR_TOOLS = store.getState().usuario.LoginSertec.TECRMI.TOKEN_ESTIMATOR_TOOLS;

    controller = new AbortController();
    return fetch(endpoint1 + 'api/TECRMI/ObtenerManualMantenimientoHTML', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                TOKEN_ESTIMATOR_TOOLS: TOKEN_ESTIMATOR_TOOLS,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const ObtenerPiezasTrabajosEnAPI = (ID_VEHICULO_TECDOC, ID_TRABAJOS_SELECCIONADOS) => {
    let TOKEN_ESTIMATOR_TOOLS = store.getState().usuario.LoginSertec.TECRMI.TOKEN_ESTIMATOR_TOOLS;

    controller = new AbortController();
    return fetch(endpoint1 + 'api/TECRMI/ObtenerPiezasTrabajos', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                ID_TRABAJOS_SELECCIONADOS: ID_TRABAJOS_SELECCIONADOS,
                TOKEN_ESTIMATOR_TOOLS: TOKEN_ESTIMATOR_TOOLS,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}







const obtenerUrlVehiculoEnAPI = (ID_VEHICULO_TECDOC) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/TECRMI/ObtenerURL', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (res.OK !== undefined && !res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }

                return res
            }
        })
}

const ObtenerTiemposReparacionEnAPI = (ID_VEHICULO_TECDOC) => {
    let TOKEN_ESTIMATOR_TOOLS = store.getState().usuario.LoginSertec.TECRMI.TOKEN_ESTIMATOR_TOOLS;

    controller = new AbortController();
    return fetch(endpoint1 + 'api/TECRMI/ObtenerTiemposReparacion', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                TOKEN_ESTIMATOR_TOOLS: TOKEN_ESTIMATOR_TOOLS,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (res.OK !== undefined && !res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }

                return res
            }
        })
}


const ObtenerTiemposReparacionGrupoMontajeVehiculoEnAPI = (ID_VEHICULO_TECDOC, ID_GRUPO_MONTAJE) => {
    let TOKEN_ESTIMATOR_TOOLS = store.getState().usuario.LoginSertec.TECRMI.TOKEN_ESTIMATOR_TOOLS;

    controller = new AbortController();
    return fetch(endpoint1 + 'api/TECRMI/ObtenerTiemposReparacionGrupoMontajeVehiculo', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                ID_GRUPO_MONTAJE: ID_GRUPO_MONTAJE,
                TOKEN_ESTIMATOR_TOOLS: TOKEN_ESTIMATOR_TOOLS,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (res.OK !== undefined && !res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }

                return res
            }
        })
}



const obtenerTiemposReparacionDetalleEnAPI = (BODYQUALCOLID, TIPO_TRABAJO_ID, ID_TIPO_TRABAJO_TIEMPO_REPARACION, ID_VEHICULO_TECRMI, ID_GRUPO_MONTAJE) => {
    let TOKEN_ESTIMATOR_TOOLS = store.getState().usuario.LoginSertec.TECRMI.TOKEN_ESTIMATOR_TOOLS;

    controller = new AbortController();
    return fetch(endpoint1 + 'api/TECRMI/obtenerTiemposReparacionDetalle', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                BODYQUALCOLID: BODYQUALCOLID,
                TIPO_TRABAJO_ID: TIPO_TRABAJO_ID,
                ID_TIPO_TRABAJO_TIEMPO_REPARACION: ID_TIPO_TRABAJO_TIEMPO_REPARACION,
                ID_VEHICULO_TECRMI: ID_VEHICULO_TECRMI,
                ID_GRUPO_MONTAJE: ID_GRUPO_MONTAJE,

                TOKEN_ESTIMATOR_TOOLS: TOKEN_ESTIMATOR_TOOLS,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (res.OK !== undefined && !res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }

                return res
            }
        })
}


const ObtenerPiezasCestaEnAPI = (ID_VEHICULO_TECDOC, CESTA) => {
    let TOKEN_ESTIMATOR_TOOLS = store.getState().usuario.LoginSertec.TECRMI.TOKEN_ESTIMATOR_TOOLS;

    controller = new AbortController();
    return fetch(endpoint1 + 'api/TECRMI/ObtenerPiezasCesta', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {

                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                CESTA: CESTA,
                TOKEN_ESTIMATOR_TOOLS: TOKEN_ESTIMATOR_TOOLS,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (res.OK !== undefined && !res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }

                return res
            }
        })
}







const InfotechniLogincEnAPI = (USUARIO, PWD, PKTYPE) => {

    controller = new AbortController();
    return fetch('https://api.infotechnic.es/bridge/autoLogin.php', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                username: USUARIO,
                password: PWD,
                pktype: PKTYPE,
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const ObtenerDesgloseStockNeumaticoEnAPI = (REFERENCIA_FABRICANTE, REFERENCIA_ERP, EAN, ID_MARCA) => {
    let cliente = store.getState().clienteSeleccionado;

    controller = new AbortController();
    return fetch(endpoint1 + 'api/Neumaticos/ObtenerDesgloseStock', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                cliente: cliente.codigo,
                REFERENCIAFABRICANTE: REFERENCIA_FABRICANTE,
                REFERENCIAERP: REFERENCIA_ERP,
                EAN: EAN,
                IdMarca: ID_MARCA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const ComprobarDisponibilidadEnAPI = (REFERENCIA, PROVEEDOR_MARCA_ID, VEHICULO_ACTUAL) => {
    let cliente = store.getState().clienteSeleccionado;

    controller = new AbortController();
    return fetch(endpoint1 + 'api/CompraOEM/ComprobarDisponibilidad', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {

                REFERENCIA: REFERENCIA,
                PROVEEDOR_MARCA_ID: PROVEEDOR_MARCA_ID,
                PROVEEDOR: "MYCAREADY",

                MATRICULA: VEHICULO_ACTUAL.MATRICULA,
                BASTIDOR: VEHICULO_ACTUAL.BASTIDOR,
                MARCA_VEHICULO: VEHICULO_ACTUAL.MARCA_VEHICULO,
                ID_VEHICULO_TECDOC: VEHICULO_ACTUAL.VEHICULO_TECDOC === undefined || VEHICULO_ACTUAL.VEHICULO_TECDOC.ID === undefined ? 0 : VEHICULO_ACTUAL.VEHICULO_TECDOC.ID,
                NOMBRE_VEHICULO: "",
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                /*
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                */
                return res
            }
        })
}


const ComprobarMailUsuarioEnAPI = () => {

    controller = new AbortController();
    let myHeaders = new Headers();

    let URL = `${endpoint1}api/CompraOEM/ComprobarMailUsuario/${window.btoa(obtenerToken())}`

    return fetch(URL, {
        method: 'GET',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                return res
            }
        })
}




const RealizarPedidoEnAPI = (PAYLOAD, VEHICULO_ACTUAL,CLIENTE,CENTRO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/CompraOEM/RealizarPedido', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {

                PEDIDO_POR: PAYLOAD.PEDIDO_POR,
                SU_PEDIDO: PAYLOAD.SU_PEDIDO,
                OBSERVACIONES: PAYLOAD.OBSERVACIONES,
                NUMERO_PRESUPUESTO: PAYLOAD.NUMERO_PRESUPUESTO,
                ID_DIRECCION_ENVIO: PAYLOAD.ID_DIRECCION_ENVIO,
                UNIDADES: PAYLOAD.UNIDADES,
                DIRECCION_ENVIO: PAYLOAD.DIRECCION_ENVIO,
                PROVEEDOR: "MYCAREADY",

                MATRICULA: VEHICULO_ACTUAL.MATRICULA,
                BASTIDOR: VEHICULO_ACTUAL.BASTIDOR,
                MARCA_VEHICULO: VEHICULO_ACTUAL.MARCA_VEHICULO,
                ID_VEHICULO_TECDOC: VEHICULO_ACTUAL.VEHICULO_TECDOC === undefined || VEHICULO_ACTUAL.VEHICULO_TECDOC.ID === undefined ? 0 : VEHICULO_ACTUAL.VEHICULO_TECDOC.ID,
                NOMBRE_VEHICULO: "",


                ARTICULO_DESCRIPCION: PAYLOAD.ARTICULO_DESCRIPCION,
                ARTICULO_MARCA: PAYLOAD.ARTICULO_MARCA,
                ARTICULO_REFERENCIA: PAYLOAD.ARTICULO_REFERENCIA,
                ARTICULO_PRECIOCOSTE: PAYLOAD.ARTICULO_PRECIOCOSTE,
                ARTICULO_PVP: PAYLOAD.ARTICULO_PVP,


                CLIENTE: CLIENTE,
                CLIENTE_CENTRO: CENTRO,

                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}





const ObtenerDesgloseStockSocioEnAPI = (SOCIO, REFERENCIA_TECDOC, MARCA_TECDOC, TIPO_ARTICULO) => {
    controller = new AbortController();

    return fetch(endpoint1 + 'api/Stocks/ObtenerDesgloseStockSocio', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                SOCIO: SOCIO,
                REFERENCIA_TECDOC: REFERENCIA_TECDOC,
                MARCA_TECDOC: MARCA_TECDOC,
                TIPO_ARTICULO: TIPO_ARTICULO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




export const RealizarPedidoSocioEnAPI = (OBJETO_PEDIDO) => {
    controller = new AbortController();
    OBJETO_PEDIDO.Token = obtenerToken()

    return fetch(endpoint1 + 'api/Stocks/RealizarPedidoSocio', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            OBJETO_PEDIDO
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


export const ObtenerArticulosSugeridosEnAPI = (CODIGO_MOTOR, ID_GRUPO_MONTAJE) => {
    controller = new AbortController();
    let cliente = store.getState().clienteSeleccionado;

    return fetch(endpoint1 + 'api/Articulo/ObtenerArticulosSugeridos', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                cliente: cliente.codigo,
                CODIGO_MOTOR: CODIGO_MOTOR,
                ID_GRUPO_MONTAJE: ID_GRUPO_MONTAJE,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



export const ObtenerPosibleCodigoCajaCambiosEnAPI = (ID_VEHICULO_TECDOC) => {
    controller = new AbortController();
    let TOKEN_ESTIMATOR_TOOLS = store.getState().usuario.LoginSertec.TECRMI.TOKEN_ESTIMATOR_TOOLS;


    return fetch(endpoint1 + 'api/TecRmi/ObtenerPosibleCodigoCajaCambios', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                TOKEN_ESTIMATOR_TOOLS: TOKEN_ESTIMATOR_TOOLS,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




export const ObtenerPosiblesParametrosFiltroEnAPI = (ID_VEHICULO_TECDOC) => {
    controller = new AbortController();
    let TOKEN_ESTIMATOR_TOOLS = store.getState().usuario.LoginSertec.TECRMI.TOKEN_ESTIMATOR_TOOLS;


    return fetch(endpoint1 + 'api/TecRmi/ObtenerPosiblesParametrosFiltro', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                TOKEN_ESTIMATOR_TOOLS: TOKEN_ESTIMATOR_TOOLS,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

export const ObtenerPropuestaServicioCooGruposEnAPI = (ID_VEHICULO_TECDOC, LISTA_TIPO_FILTROS, FECHA_REGISTRO_INICIAL) => {
    controller = new AbortController();
    let TOKEN_ESTIMATOR_TOOLS = store.getState().usuario.LoginSertec.TECRMI.TOKEN_ESTIMATOR_TOOLS;
    return fetch(endpoint1 + 'api/TecRmi/ObtenerPropuestaServicioCooGrupos', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                LISTA_TIPO_FILTROS: LISTA_TIPO_FILTROS,
                FECHA_REGISTRO_INICIAL: FECHA_REGISTRO_INICIAL,



                ESTANDAR_EUROPEO_EMISION_ESCAPE: 0,
                VIN: "",
                TIPO_CAJA_CAMBIOS: LISTA_TIPO_FILTROS.length > 1 ? 0 : LISTA_TIPO_FILTROS[0],
                TOKEN_ESTIMATOR_TOOLS: TOKEN_ESTIMATOR_TOOLS,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

export const ObtenerServicioPropuestoEnAPI = (ID_VEHICULO_TECDOC, ID_COO_GRUPO, ID_CARROCERIA_QUALCOL, KILOMETRAJE_ACTUAL, FECHA_REGISTRO_INICIAL) => {
    controller = new AbortController();
    let TOKEN_ESTIMATOR_TOOLS = store.getState().usuario.LoginSertec.TECRMI.TOKEN_ESTIMATOR_TOOLS;
    return fetch(endpoint1 + 'api/TecRmi/ObtenerServicioPropuesto', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                ID_COO_GRUPO: ID_COO_GRUPO,
                ID_CARROCERIA_QUALCOL: ID_CARROCERIA_QUALCOL,
                KILOMETRAJE_ACTUAL: KILOMETRAJE_ACTUAL,
                FECHA_REGISTRO_INICIAL: FECHA_REGISTRO_INICIAL,

                TOKEN_ESTIMATOR_TOOLS: TOKEN_ESTIMATOR_TOOLS,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




export const ObtenerMantenimientosEnAPI = (ID_VEHICULO_TECDOC, ID_COO_GRUPO, ID_CARROCERIA_QUALCOL, KILOMETRAJE_ACTUAL, FECHA_REGISTRO_INICIAL) => {
    controller = new AbortController();
    let TOKEN_ESTIMATOR_TOOLS = store.getState().usuario.LoginSertec.TECRMI.TOKEN_ESTIMATOR_TOOLS;
    return fetch(endpoint1 + 'api/TecRmi/ObtenerMantenimientos', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                ID_COO_GRUPO: ID_COO_GRUPO,
                ID_CARROCERIA_QUALCOL: ID_CARROCERIA_QUALCOL,
                KILOMETRAJE_ACTUAL: KILOMETRAJE_ACTUAL,
                FECHA_REGISTRO_INICIAL: FECHA_REGISTRO_INICIAL,

                TOKEN_ESTIMATOR_TOOLS: TOKEN_ESTIMATOR_TOOLS,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}





export const Obtener_CatalogoEnAPI = (REGSPAGE, PAGE, ORDERCOLUM, ORDERDIR, PAYLOAD) => {
    controller = new AbortController();
    let cliente = store.getState().clienteSeleccionado;

    return fetch(endpoint1 + 'api/Baterias/Obtener_Catalogo', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            REGSPAGE: REGSPAGE,
            PAGE: PAGE,
            ORDERCOLUM: ORDERCOLUM,
            ORDERDIR: ORDERDIR,
            REFERENCIA: PAYLOAD.REFERENCIA,
            ID_MARCA: PAYLOAD.ID_MARCA,
            VOLTAJE: PAYLOAD.VOLTAJE,
            AMPERIOS: PAYLOAD.AMPERIOS,
            CAPACIDAD: PAYLOAD.CAPACIDAD,
            LONGITUD: PAYLOAD.LONGITUD,
            ANCHO: PAYLOAD.ANCHO,
            ALTURA: PAYLOAD.ALTURA,

            cliente: cliente.codigo,
            Token: obtenerToken()
        }),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined && res !== null) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



export const Obtener_Baterias_AlturaEnAPI = (ID_MARCA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Baterias/Obtener_Baterias_Altura', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({

            ID_MARCA: ID_MARCA,
            Token: obtenerToken()
        }),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined && res !== null) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


export const Obtener_Baterias_AmperiosEnAPI = (ID_MARCA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Baterias/Obtener_Baterias_Amperios', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({

            ID_MARCA: ID_MARCA,
            Token: obtenerToken()
        }),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined && res !== null) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


export const Obtener_Baterias_AnchoEnAPI = (ID_MARCA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Baterias/Obtener_Baterias_Ancho', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({

            ID_MARCA: ID_MARCA,
            Token: obtenerToken()
        }),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined && res !== null) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


export const Obtener_Baterias_LongitudEnAPI = (ID_MARCA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Baterias/Obtener_Baterias_Longitud', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({

            ID_MARCA: ID_MARCA,
            Token: obtenerToken()
        }),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined && res !== null) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



export const Obtener_Baterias_VoltajeEnAPI = (ID_MARCA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Baterias/Obtener_Baterias_Voltaje', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({

            ID_MARCA: ID_MARCA,
            Token: obtenerToken()
        }),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined && res !== null) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



export const Obtener_Baterias_MarcasEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Baterias/Obtener_Baterias_Marcas', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Token: obtenerToken()
        }),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined && res !== null) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



export const Obtener_FiltrosEnAPI = ({ MARCA_SELECCIONADO, VOLTAJE_SELECCIONADO, AMPERIOS_SELECCIONADO, LONGITUD_SELECCIONADO, ANCHO_SELECCIONADO, ALTURA_SELECCIONADO, CAPACIDAD_SELECCIONADO }, ID_SOCIO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Baterias/Obtener_Filtros', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ID_MARCA: MARCA_SELECCIONADO,
            VOLTAJE: VOLTAJE_SELECCIONADO,
            AMPERIOS: AMPERIOS_SELECCIONADO,
            CAPACIDAD: CAPACIDAD_SELECCIONADO,
            LONGITUD: LONGITUD_SELECCIONADO,
            ANCHO: ANCHO_SELECCIONADO,
            ALTURA: ALTURA_SELECCIONADO,
            ID_SOCIO: ID_SOCIO,
            Token: obtenerToken()
        }),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined && res !== null) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



export const Vehiculo_Documentacion_TecnicaEnAPI = (ID_VEHICULO_CATALOGO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Catalogos/Vehiculo_Documentacion_Tecnica', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ID_VEHICULO_CATALOGO: ID_VEHICULO_CATALOGO,
            Token: obtenerToken()
        }),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined && res !== null) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




export const Obtener_Fabricantes_ImagenesEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Catalogos/Obtener_Fabricantes_Imagenes', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Token: obtenerToken()
        }),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined && res !== null) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




export const ObtenerDOCVehiculoEnAPI = () => {

    controller = new AbortController();
    let myHeaders = new Headers();

    return fetch("https://promotive-public-images.s3.sa-east-1.amazonaws.com/Z_MANUALES/Manual_Taller-Aire-Acondicionado-Chevrolet-Spark.pdf"
        , {
            method: 'GET',
            signal: controller.signal,
            headers: myHeaders,
        }).then(res => res.text())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }

                return res
            }
        })
}





export const PlantillaCargaMasivaEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/PlantillaCargaMasiva', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Token: obtenerToken()
        }),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined && res !== null) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




export const ValidarEImportarCargaMasivaEnAPI = (CSV) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/ValidarEImportarCargaMasiva', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            CSV: CSV,
            // DESCRIPCION_CARGA:"",
            Token: obtenerToken()
        }),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined && res !== null) {
                if (!res.OK) {
                    // MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}





export const Obtener_Filtros_LubricantesEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Lubricantes/Obtener_Filtros', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Token: obtenerToken()
        }),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined && res !== null) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}






/**
 * Presupuesto flota
 */


export const getPresupuestosTallerFlotasEnAPI = (id, ORDERCOLUM, ORDERDIR, DESDE, HASTA, ESTADO) => {
    controller = new AbortController();
    return fetch(endpoint1 + "api/Gestion/getPresupuestos_FLOTAS", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                REGSPAGE: 10000,
                PAGE: 1,
                ORDERCOLUM: ORDERCOLUM,
                ORDERDIR: ORDERDIR,
                ID: id,
                CODIGO: "",
                FECHA_DESDE: DESDE,
                FECHA_HASTA: HASTA,
                ID_VEHICULO: "",
                VEH_MATRICULA: "",
                ID_CLIENTE: "",
                CLI_NOMBRE: "",
                ESTADO: ESTADO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return res;
                } else {
                    return res;
                }
            }
        })
}

export const setPresupuestosTallerFlotasEnAPI = (ACCION, DATOS_USUARIO, LineasManoObra) => {
    controller = new AbortController();
    DATOS_USUARIO.VALOR_IVA_1 = typeof DATOS_USUARIO.VALOR_IVA_1 === 'number' ? DATOS_USUARIO.VALOR_IVA_1 : DATOS_USUARIO.VALOR_IVA_1.replace("%", "");


    return fetch(endpoint1 + "api/Gestion/setPresupuestos_FLOTAS", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ACCION: ACCION,
                ID: DATOS_USUARIO.ID,
                CODIGO: DATOS_USUARIO.CODIGO,
                FECHA: DATOS_USUARIO.FECHA,
                ID_VEHICULO: DATOS_USUARIO.ID_VEHICULO,
                VEH_BASTIDOR: DATOS_USUARIO.VEH_BASTIDOR,
                //BASTIDOR: DATOS_USUARIO.BASTIDOR,

                VEH_MATRICULA: DATOS_USUARIO.VEH_MATRICULA,
                VEH_DENOMINACION: DATOS_USUARIO.VEH_DENOMINACION,
                VEH_ID_TECDOC: DATOS_USUARIO.VEH_ID_TECDOC,
                ID_CLIENTE: DATOS_USUARIO.ID_CLIENTE,
                CLI_NOMBRE: DATOS_USUARIO.CLI_NOMBRE,
                CLI_CIF: DATOS_USUARIO.CLI_CIF,
                CLI_DIRECCION: DATOS_USUARIO.CLI_DIRECCION,
                CLI_POBLACION: DATOS_USUARIO.CLI_POBLACION,
                CLI_PROVINCIA: DATOS_USUARIO.CLI_PROVINCIA,
                CLI_CP: DATOS_USUARIO.CLI_CP,
                CLI_PAIS: DATOS_USUARIO.CLI_PAIS,
                CLI_TELEFONO: DATOS_USUARIO.CLI_TELEFONO,
                CLI_TELEFONO_2: DATOS_USUARIO.CLI_TELEFONO_2,
                CLI_TELEFONO_3: DATOS_USUARIO.CLI_TELEFONO_3,
                CLI_MAIL: DATOS_USUARIO.CLI_MAIL,
                VALOR_DTO_GENERAL: DATOS_USUARIO.VALOR_DTO_GENERAL,
                VALOR_IVA_1: DATOS_USUARIO.VALOR_IVA_1,
                VALOR_IVA_2: DATOS_USUARIO.VALOR_IVA_2,
                VALOR_IVA_3: DATOS_USUARIO.VALOR_IVA_3,
                IMPORTE_BRUTO_1: DATOS_USUARIO.IMPORTE_BRUTO_1,
                IMPORTE_BRUTO_2: DATOS_USUARIO.IMPORTE_BRUTO_2,
                IMPORTE_BRUTO_3: DATOS_USUARIO.IMPORTE_BRUTO_3,
                IMPORTE_BRUTO: DATOS_USUARIO.IMPORTE_BRUTO,
                IMPORTE_DESCUENTOS_1: DATOS_USUARIO.IMPORTE_DESCUENTOS_1,
                IMPORTE_DESCUENTOS_2: DATOS_USUARIO.IMPORTE_DESCUENTOS_2,
                IMPORTE_DESCUENTOS_3: DATOS_USUARIO.IMPORTE_DESCUENTOS_3,
                IMPORTE_DESCUENTOS: DATOS_USUARIO.IMPORTE_DESCUENTOS,
                IMPORTE_BASE_IMPONIBLE_1: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE_1,
                IMPORTE_BASE_IMPONIBLE_2: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE_2,
                IMPORTE_BASE_IMPONIBLE_3: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE_3,
                IMPORTE_BASE_IMPONIBLE: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE,
                IMPORTE_IMPUESTOS_1: DATOS_USUARIO.IMPORTE_IMPUESTOS_1,
                IMPORTE_IMPUESTOS_2: DATOS_USUARIO.IMPORTE_IMPUESTOS_2,
                IMPORTE_IMPUESTOS_3: DATOS_USUARIO.IMPORTE_IMPUESTOS_3,
                IMPORTE_TOTAL_1: DATOS_USUARIO.IMPORTE_TOTAL_1,
                IMPORTE_TOTAL_2: DATOS_USUARIO.IMPORTE_TOTAL_2,
                IMPORTE_TOTAL_3: DATOS_USUARIO.IMPORTE_TOTAL_3,
                IMPORTE_TOTAL: DATOS_USUARIO.IMPORTE_TOTAL,
                OBSERVACIONES_PRESUPUESTO: DATOS_USUARIO.OBSERVACIONES_PRESUPUESTO,
                IMPRIMIDO: DATOS_USUARIO.IMPRIMIDO,
                ENVIADO: DATOS_USUARIO.ENVIADO,
                FECHA_IMPRESION: DATOS_USUARIO.FECHA_IMPRESION,
                FECHA_ENVIO: DATOS_USUARIO.FECHA_ENVIO,
                ESTADO: DATOS_USUARIO.ESTADO,
                ID_PRESUPUESTO_GT_ESTIMATE: DATOS_USUARIO.ID_PRESUPUESTO_GT_ESTIMATE,
                LINEAS: LineasManoObra,
                CODIGO_AUTORIZACION: DATOS_USUARIO.CODIGO_AUTORIZACION,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return res;
                } else {
                    return res;
                }
            }
        })
}

export const ObtenerPresupuestoHTMLFlotasEnAPI = (ID_PRESUPUESTO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/ObtenerPresupuestoHTML_FLOTAS', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_PRESUPUESTO: ID_PRESUPUESTO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.HTML
            }
        })
}

export const ObtenerPresupuestoPDFFlotasEnAPI = (ID_PRESUPUESTO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/ObtenerPresupuestoPDF_FLOTAS', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_PRESUPUESTO: ID_PRESUPUESTO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.BYTES
            }
        })
}

export const DuplicarPresupuestoFlotasEnAPI = (ID_PRESUPUESTO, ID_CLIENTE, ID_VEHICULO, FECHA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/DuplicarPresupuesto_FLOTAS', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_PRESUPUESTO: ID_PRESUPUESTO,
                PRE_DUP_FECHA: FECHA,
                PRE_DUP_ID_CLIENTE: ID_CLIENTE,
                PRE_DUP_ID_VEHICULO: ID_VEHICULO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

export const EnviarPresupuestoFlotasEnAPI = (envio, payLoadLocal) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/EnviarPresupuesto_FLOTAS', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_PRESUPUESTO: payLoadLocal.ID_PRESUPUESTO,
                REMITENTE: payLoadLocal.DESDE,
                DESTINATARIO: payLoadLocal.PARA,
                ASUNTO: payLoadLocal.ASUNTO,
                MENSAJE: payLoadLocal.CUERPO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

export const PresupuestoFlotaToPresupuestoTallerEnAPI = (ID_PRESUPUESTO, ID_CLIENTE, ID_VEHICULO, FECHA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/PresupuestoFlotasToPresupuestoTaller_FLOTAS', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_PRESUPUESTO: ID_PRESUPUESTO,
                OR_FECHA: FECHA,
                OR_ID_CLIENTE: ID_CLIENTE,
                OR_ID_VEHICULO: ID_VEHICULO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}





export const SetPortapapelesPresupuestosFlotasEnAPI = (ACCION, PAYLOAD) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/SetPortapapelesPresupuestos_FLOTAS', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ACCION: ACCION,
                LINEA: {
                    ID: PAYLOAD.ID,
                    ID_USUARIO_SERTEC: PAYLOAD.ID_USUARIO_SERTEC,
                    TIPO_LINEA: PAYLOAD.TIPO_LINEA,
                    REFERENCIA_TECDOC: PAYLOAD.REFERENCIA_TECDOC,
                    REFERENCIA_SOCIO: PAYLOAD.REFERENCIA_SOCIO,
                    MARCA_SOCIO: PAYLOAD.MARCA_SOCIO,
                    MARCA_SOCIO_DESCRIPCION: PAYLOAD.MARCA_SOCIO_DESCRIPCION,
                    MARCA_TECDOC: PAYLOAD.MARCA_TECDOC,
                    MARCA_TECDOC_DESCRIPCION: PAYLOAD.MARCA_TECDOC_DESCRIPCION,
                    REFERENCIA_DESCRIPCION: PAYLOAD.REFERENCIA_DESCRIPCION,
                    MANO_OBRA: PAYLOAD.MANO_OBRA,
                    IVA_VALOR: PAYLOAD.IVA_VALOR,
                    DTO_VALOR: PAYLOAD.DTO_VALOR,
                    CANTIDAD: PAYLOAD.CANTIDAD,
                    IMPORTE_BRUTO: PAYLOAD.IMPORTE_BRUTO,
                    IMPORTE_DESCUENTOS: PAYLOAD.IMPORTE_DESCUENTOS,
                    IMPORTE_BASE_IMPONIBLE: PAYLOAD.IMPORTE_BASE_IMPONIBLE,
                    IMPORTE_IMPUESTOS: PAYLOAD.IMPORTE_IMPUESTOS,
                    IMPORTE_TOTAL: PAYLOAD.IMPORTE_TOTAL,
                    PRECIO: PAYLOAD.PRECIO,
                    DESCUENTO_COMPRA_TALLER: PAYLOAD.DESCUENTO_COMPRA_TALLER,

                    MATRICULA_VEHICULO: PAYLOAD.MATRICULA_VEHICULO,
                    BASTIDOR_VEHICULO: PAYLOAD.BASTIDOR_VEHICULO,
                    MARCA_VEHICULO: PAYLOAD.MARCA_VEHICULO,
                    MODELO_VEHICULO: PAYLOAD.MODELO_VEHICULO,
                    MOTOR_VEHICULO: PAYLOAD.MOTOR_VEHICULO,
                    ID_VEHICULO_TECDOC: PAYLOAD.ID_VEHICULO_TECDOC,
                    ES_NEUMATICO: PAYLOAD.ES_NEUMATICO

                },
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

export const LimpiarPortapapelesPresupuestosFlotasEnAPI = (ACCION) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/SetPortapapelesPresupuestos_FLOTAS', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ACCION: ACCION,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

export const GetPortapapelesPresupuestosFlotasEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/GetPortapapelesPresupuestos_FLOTAS', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                REGSPAGE: 1000,
                PAGE: 1,
                ORDERCOLUM: 1,
                ORDERDIR: "A",
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

/**
 * ==========================Fin Presupuesto flota
 */



/**
 * Presupuesto flota to taller
 */




export const getPresupuestosFlotaTallerEnAPI = (id, ORDERCOLUM, ORDERDIR, DESDE, HASTA, ESTADO) => {
    controller = new AbortController();
    return fetch(endpoint1 + "api/Gestion/getPresupuestos_TALLER", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                REGSPAGE: 10000,
                PAGE: 1,
                ORDERCOLUM: ORDERCOLUM,
                ORDERDIR: ORDERDIR,
                ID: id,
                CODIGO: "",
                FECHA_DESDE: DESDE,
                FECHA_HASTA: HASTA,
                ID_VEHICULO: "",
                VEH_MATRICULA: "",
                ID_CLIENTE: "",
                CLI_NOMBRE: "",
                ESTADO: ESTADO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return res;
                } else {
                    return res;
                }
            }
        })
}

export const setPresupuestosFlotaTallerEnAPI = (ACCION, DATOS_USUARIO, LineasManoObra) => {
    controller = new AbortController();
    DATOS_USUARIO.VALOR_IVA_1 = typeof DATOS_USUARIO.VALOR_IVA_1 === 'number' ? DATOS_USUARIO.VALOR_IVA_1 : DATOS_USUARIO.VALOR_IVA_1.replace("%", "");


    return fetch(endpoint1 + "api/Gestion/setPresupuestos_TALLER", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ACCION: ACCION,
                ID: DATOS_USUARIO.ID,
                CODIGO: DATOS_USUARIO.CODIGO,
                FECHA: DATOS_USUARIO.FECHA,
                ID_VEHICULO: DATOS_USUARIO.ID_VEHICULO,
                VEH_BASTIDOR: DATOS_USUARIO.VEH_BASTIDOR,
                //BASTIDOR: DATOS_USUARIO.BASTIDOR,

                VEH_MATRICULA: DATOS_USUARIO.VEH_MATRICULA,
                VEH_DENOMINACION: DATOS_USUARIO.VEH_DENOMINACION,
                VEH_ID_TECDOC: DATOS_USUARIO.VEH_ID_TECDOC,
                ID_CLIENTE: DATOS_USUARIO.ID_CLIENTE,
                CLI_NOMBRE: DATOS_USUARIO.CLI_NOMBRE,
                CLI_CIF: DATOS_USUARIO.CLI_CIF,
                CLI_DIRECCION: DATOS_USUARIO.CLI_DIRECCION,
                CLI_POBLACION: DATOS_USUARIO.CLI_POBLACION,
                CLI_PROVINCIA: DATOS_USUARIO.CLI_PROVINCIA,
                CLI_CP: DATOS_USUARIO.CLI_CP,
                CLI_PAIS: DATOS_USUARIO.CLI_PAIS,
                CLI_TELEFONO: DATOS_USUARIO.CLI_TELEFONO,
                CLI_TELEFONO_2: DATOS_USUARIO.CLI_TELEFONO_2,
                CLI_TELEFONO_3: DATOS_USUARIO.CLI_TELEFONO_3,
                CLI_MAIL: DATOS_USUARIO.CLI_MAIL,
                VALOR_DTO_GENERAL: DATOS_USUARIO.VALOR_DTO_GENERAL,
                VALOR_IVA_1: DATOS_USUARIO.VALOR_IVA_1,
                VALOR_IVA_2: DATOS_USUARIO.VALOR_IVA_2,
                VALOR_IVA_3: DATOS_USUARIO.VALOR_IVA_3,
                IMPORTE_BRUTO_1: DATOS_USUARIO.IMPORTE_BRUTO_1,
                IMPORTE_BRUTO_2: DATOS_USUARIO.IMPORTE_BRUTO_2,
                IMPORTE_BRUTO_3: DATOS_USUARIO.IMPORTE_BRUTO_3,
                IMPORTE_BRUTO: DATOS_USUARIO.IMPORTE_BRUTO,
                IMPORTE_DESCUENTOS_1: DATOS_USUARIO.IMPORTE_DESCUENTOS_1,
                IMPORTE_DESCUENTOS_2: DATOS_USUARIO.IMPORTE_DESCUENTOS_2,
                IMPORTE_DESCUENTOS_3: DATOS_USUARIO.IMPORTE_DESCUENTOS_3,
                IMPORTE_DESCUENTOS: DATOS_USUARIO.IMPORTE_DESCUENTOS,
                IMPORTE_BASE_IMPONIBLE_1: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE_1,
                IMPORTE_BASE_IMPONIBLE_2: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE_2,
                IMPORTE_BASE_IMPONIBLE_3: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE_3,
                IMPORTE_BASE_IMPONIBLE: DATOS_USUARIO.IMPORTE_BASE_IMPONIBLE,
                IMPORTE_IMPUESTOS_1: DATOS_USUARIO.IMPORTE_IMPUESTOS_1,
                IMPORTE_IMPUESTOS_2: DATOS_USUARIO.IMPORTE_IMPUESTOS_2,
                IMPORTE_IMPUESTOS_3: DATOS_USUARIO.IMPORTE_IMPUESTOS_3,
                IMPORTE_TOTAL_1: DATOS_USUARIO.IMPORTE_TOTAL_1,
                IMPORTE_TOTAL_2: DATOS_USUARIO.IMPORTE_TOTAL_2,
                IMPORTE_TOTAL_3: DATOS_USUARIO.IMPORTE_TOTAL_3,
                IMPORTE_TOTAL: DATOS_USUARIO.IMPORTE_TOTAL,
                OBSERVACIONES_PRESUPUESTO: DATOS_USUARIO.OBSERVACIONES_PRESUPUESTO,
                IMPRIMIDO: DATOS_USUARIO.IMPRIMIDO,
                ENVIADO: DATOS_USUARIO.ENVIADO,
                FECHA_IMPRESION: DATOS_USUARIO.FECHA_IMPRESION,
                FECHA_ENVIO: DATOS_USUARIO.FECHA_ENVIO,
                ESTADO: DATOS_USUARIO.ESTADO,
                ID_PRESUPUESTO_GT_ESTIMATE: DATOS_USUARIO.ID_PRESUPUESTO_GT_ESTIMATE,
                CODIGO_AUTORIZACION: DATOS_USUARIO.CODIGO_AUTORIZACION,
                LINEAS: LineasManoObra,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                    return res;
                } else {
                    return res;
                }
            }
        })
}

export const ObtenerPresupuestoHTMLFlotaTallerEnAPI = (ID_PRESUPUESTO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/ObtenerPresupuestoHTML_TALLER', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_PRESUPUESTO: ID_PRESUPUESTO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.HTML
            }
        })
}

export const ObtenerPresupuestoPDFFlotaTallerEnAPI = (ID_PRESUPUESTO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/ObtenerPresupuestoPDF_TALLER', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_PRESUPUESTO: ID_PRESUPUESTO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res.BYTES
            }
        })
}

export const DuplicarPresupuestoFlotaTallerEnAPI = (ID_PRESUPUESTO, ID_CLIENTE, ID_VEHICULO, FECHA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/DuplicarPresupuesto_TALLER', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_PRESUPUESTO: ID_PRESUPUESTO,
                PRE_DUP_FECHA: FECHA,
                PRE_DUP_ID_CLIENTE: ID_CLIENTE,
                PRE_DUP_ID_VEHICULO: ID_VEHICULO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

export const EnviarPresupuestoFlotaTallerEnAPI = (envio, payLoadLocal) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/EnviarPresupuesto_TALLER', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_PRESUPUESTO: payLoadLocal.ID_PRESUPUESTO,
                REMITENTE: payLoadLocal.DESDE,
                DESTINATARIO: payLoadLocal.PARA,
                ASUNTO: payLoadLocal.ASUNTO,
                MENSAJE: payLoadLocal.CUERPO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



export const PasarAPresupuestoTallerYEnviarMailEnAPI = (payLoadLocal) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/Gestion/PasarAPresupuestoTallerYEnviarMail', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_PRESUPUESTO: payLoadLocal.ID_PRESUPUESTO,

                PR_ID_CLIENTE: payLoadLocal.PR_ID_CLIENTE,
                PR_ID_VEHICULO: payLoadLocal.PR_ID_VEHICULO,
                PR_FECHA: payLoadLocal.PR_FECHA,

                REMITENTE: payLoadLocal.DESDE,
                DESTINATARIO: payLoadLocal.PARA,
                ASUNTO: payLoadLocal.ASUNTO,
                MENSAJE: payLoadLocal.CUERPO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


/**
 * ==========================Fin Presupuesto flota to taller
 */





export const referenciaOEMasInfov2EnAPI = (REFERENCIA, MARCA, CATALOGO) => {
    return fetch(endpoint1 + 'api/MasInfo/ObtenerReferenciasOEByArtNo_BrandNo', {
        method: 'POST',

        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ARTNO: REFERENCIA,
                MARCA: MARCA,
                CATALOGO: CATALOGO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



export const getPriceOEMEnAPI = (listaAtributos) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'api/MasInfo/getPricePageOEM', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ListArticles: listaAtributos,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const abortFetch = () => {
    controller.abort()
}

export {
    loadBrands, getModelVehicle, getEngineType, getListTree, putSertecHistorico, getListSuplier, loginByHaynesPro, getIdTecdocMotorByIdHaynes, getDataUser, loadAceites, getSesionHaynesPro, pad, buscarReferenciaEnAPI,
    buscarDetallesReferencia, logOutSesion, loginHaynesProTouch, obtenerImagenHaynesPro, abortFetch, buscarMatriculaCoche, buscarBastidorCoche, direccionesByMarca, documentosByRefMarca, despiezeByReferenciaMarca, fabricantesByReferenciaMarca, referenciaOEMasInfo,
    getIdVehicleByTecdocNumber, getModelosByReferenciaMarcaAndFabricante, getMotorizacionesByReferenciaAndModelo, getStockDesglose, getPricePageOEM, setHistorialBusqueda, getHistorialBusqueda, setCestaLineasEnAPI, getCestaLineasEnAPI, eliminarCestaLineasEnAPI,
    vaciarCestaLineasEnAPI, ObtenerReferenciasByNodoGraphicAndVehicleEnAPI, ObtenerArticuloByArtNoBrandNoEnAPI, ObtenerClientesEnAPI, getFichaCocheEnAPI, getIdVehicleByTecdocNumberV2, setClienteTallerEnAPI, getClienteTallerEnAPI, getClienteVehiculosEnAPI,
    setClienteVehiculoTallerEnAPI, ObtenerEquivalenciasByArtNoBrandNoEnAPI, getPresupuestosTallerEnAPI, ObtenerMarcasdeUnVehiculoEnAPI, ObtenerReferenciasMarcadeUnVehiculoEnAPI, ObtenerEquivalenciasArticuloByArtoOEMEnAPI, GetVehiculosHaynesByIdTecdocEnAPI,
    GetTiemposReparacionByGMAndVehicleEnAPI, CrearPedidoEnAPI, ObtenerAlbaranesVentaEnAPI, setPresupuestosTallerEnAPI, GetUsuariosEnAPI, SetUsuarioEnAPI, GetEmpresaEnAPI, SetEmpresaEnAPI, GetIdiomasEnAPI, getMotorizacionesTurismosEnAPI, getFabricantesEnAPI,
    getModelosTurismosEnAPI, EnviarPresupuestoEnAPI, ObtenerPresupuestoHTMLEnAPI, ObtenerPresupuestoPDFEnAPI, GetNodosRaizTiemposReparacionEnAPI, GetSubNodosTiempoReparacioEnAPI, ObtenerTareasTiempoReparacionEnnAPI, ObtenerInfoTareasTiempoReparacionEnAPI,
    ModificarCestaTiempoReparacionEnAPI, ObtenerReferenciasByGMAndVehicleEnAPI, ObtenerSistemasMantenimientoEnAPI, ObtenerTareasMantenimientoEnAPI, SolicitarInfoServicioEnAPI, ObtenerReferenciasByNodoGraphicCarroceriaAndVehicleEnAPI, RecuperarPwdEnAPI,
    getFabricantesV2EnAPI, ObtenerMotoresByFabricanteEnAPI, ObtenerMotorByCodigoMotorEnAPI, ObtenerLubricantesByIdVehiculoTecdocEnAPI, ObtenerModelosEjesEnAPI, ObtenerEjesByModeloEnAPI, ObtenerArticuloERPEnAPI, SetPortapapelesPresupuestosEnAPI,
    GetPortapapelesPresupuestosEnAPI, LimpiarPortapapelesPresupuestosEnAPI, ObtenerNeumaticosAnchuraEnAPI, ObtenerNeumaticosAlturaEnAPI, ObtenerNeumaticosDiametroEnAPI, ObtenerNeumaticosVelocidadEnAPI, ObtenerNeumaticosCargaEnAPI, ObtenerNeumaticosEnAPI, ObtenerNeumaticoImagenEnAPI,
    buscarPrecioReferencia, ObtenerEngNoByEngCodeEnAPI, ObtenerMotorizacionesTurismosByEngNoEnAPI, ObtenerHistoricoPedidosEnAPI, ObtenerCentroClienteERPEnAPI, ObtenerAlbaranHTMLEnAPI, ObtenerAlbaranPDFEnAPI, ObtenerPedidoPDFPDFEnAPI, ObtenerPedidoHTMLEnAPI,
    getProveedoresEnAPI, setProveedoresEnAPI, setOrdenesReparacionTallerEnAPI, getOrdenesReparacionTallerEnAPI, getFacturasEnAPI, setFacturaEnAPI, ObtenerOrdenReparacionPDFEnAPI, ObtenerOrdenReparacionHTMLEnAPI, GetUrlGTmotiveEnAPI,
    EnviarOrdenReparacionEnAPI, EnviarFacturaEnAPI, ObtenerFacturaHTMLEnAPI, ObtenerFacturaPDFEnAPI, PresupuestoToOrdenReparacionEnAPI, OrdenReparacionToFacturaEnAPI, DuplicarFacturaEnAPI, DuplicarOrdenReparacionEnAPI, GetPresupuestoGTEnAPI,
    DuplicarPresupuestoEnAPI, TrazabilidadDocumentoEnAPI, ObtenerFormulariosOrHTMLEnAPI, ObtenerFormulariosOrPDFEnAPI, AbonarFacturaEnAPI, ObtenerLubricantesERPEnAPI, ObtenerBateriasERPEnAPI, ObtenerMarcasIQEnAPI, ObtenerModelosIQEnAPI,
    BucarVehiculoPorModeloEnAPI, ObtenerCategoriasEnAPI, BusquedaUniversalEnAPI, ObtenerGraficosEnAPI, BuscarVehiculoPorVinMarcaEnAPI, ObtenerListaDetalleRapidoEnAPI, ObtenerDetalleGraficoEnAPI, ObtenerListaMapaGraficosEnAPI, ObtenerConversacionesEnAPI,
    ObtenerMensajesnAPI, EnviarMensajeEnAPI, ObtenerNotificacionesEnAPI, ActualizarNotificacionesClienteEnAPI, ObtenerArchivoEnAPI, EnviarArchivoEnAPI, ObtenerReferenciasBateriasByVehicleEnAPI, CrearPresupuestoEnAPI, VerificarPresusupuestoGTEnAPI,
    PermisoConsultaOEMEnAPI, SetHistorialBusquedaOEMEnAPI, GetSmartPackEnAPI, GetDetalleSmartPackEnAPI, ImprimirFacturaEnAPI, ObtenerExpedicionesEnAPI, ObtenerManualesReparacionByGmEnAPI, ObtenerReferenciasByVehicleFASTEnAPI,
    ObtenerManualesReparacionEnAPI, ObtenerCobrosEnAPI, GuardarCobroEnAPI, pagoTpvEnApi, FormasPagoEnAPI, GetAnuncioEnAPI, setHistorialDocumentacionTecnicaEnAPI, ObtenerSociosEnAPI, ObtenerStockSocioEnAPI,
    RealizaPedidoSocioEnAPI, ObtenerFabricantesEnAPI, ObtenerModelosEnAPI, ObtenerAjustesByVehiculoTecdocEnAPI, ObtenerNeumaticosVehiculoEnAPI, ObtenerNeumaticoInfoEnAPI, ObtenerImagenNeumaticoEnAPI, ObtenerNeumaticoImagenOriginalEnAPI,
    ObtenerNeumaticosMarcaEnAPI, ObtenerNeumaticosCatalogoEnAPI, ObtenerNeumaticosAnchoEnAPI, ObtenerNeumaticosLargoEnAPI, ObtenerNeumaticosLlantaEnAPI, ObtenerNeumaticoVelocidadEnAPI, ObtenerNeumaticoCargaEnAPI,
    ObtenerGMPorIdModeloEnAPI, ObtenerArticulosByDescripcionEnAPI, obtenerLlubricantesFluidosEnAPI, obtenerUrlVehiculoEnAPI, ObtenerListaManualEnAPI, ObtenerUnManualEnAPI, ObtenerListaDatosTecnicosEnAPI, ObtenerUnDatoTecnicoHTMLEnAPI,
    obtenerTodosMantenimientosEnAPI, ObtenerManualMantenimientoHTMLEnAPI, ObtenerPiezasTrabajosEnAPI, InfotechniLogincEnAPI, ObtenerDesgloseStockNeumaticoEnAPI, ComprobarDisponibilidadEnAPI,
    ComprobarMailUsuarioEnAPI, RealizarPedidoEnAPI, ObtenerDesgloseStockSocioEnAPI, ObtenerTiemposReparacionEnAPI, obtenerTiemposReparacionDetalleEnAPI, ObtenerTiemposReparacionGrupoMontajeVehiculoEnAPI,
    ObtenerPiezasCestaEnAPI
};