import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createRipple, DrawerFichaMantenimientoClienteTaller, DrawerFichaMantenimientoVehiculoTaller } from "../action/actionCreators"
import { setClienteTallerEnAPI, getClienteTallerEnAPI, getClienteVehiculosEnAPI, setClienteVehiculoTallerEnAPI } from "../action/actionCreatorsRequests"
import { MSGOK } from '../librerias/funciones'
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBCol } from 'mdbreact';
import FichaMantenimientoVehiculoTaller from '../components/MantenimientoVehiculoTaller'

import {
    PlusOutlined,
    SaveOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';

import { Card, Input, Row, Col, Drawer, Tabs } from 'antd';
import store from "../store";
import {  traducirPagina } from '../librerias/funciones'


const { TabPane } = Tabs;

const MantenimientoClienteTaller = ({ props, configApp, clienteTallerSeleccionado, VehiculoTallerSeleccionado, openDrawerFichaMantenimientoVehiculo }) => {
    const [hayClientes, setHayClientes] = React.useState(false);
    const [tabPorDefecto, setTabPorDefecto] = React.useState("1");
    const [vehiculos, setVehiculos] = React.useState([]);
    const [actualizarVehiculos, setActualizarVehiculos] = React.useState([]);
    const [mantenimientoVehiculo, setMantenimientoVehiculo] = React.useState(false);

    const [datosCliente, setDatosCliente] = React.useState(
        {
            id: null,
            codigo: null,
            nombre: "",
            direccion: "",
            poblacion: "",
            provincia: "",
            cp: "",
            pais: "",
            telefono1: "",
            telefono2: "",
            telefono3: "",
            email: "",
            observacion: "",
            cif: ""
        }
    );

    if (!hayClientes && props.ABRIR_FICHA) {
        setHayClientes(true);
        if (!clienteTallerSeleccionado.esNuevoCliente) {
            getClienteTallerEnAPI(clienteTallerSeleccionado.id, 1, "A").then((cli) => {
                if (cli.CLIENTES !== null) {
                    let datosAPI = cli.CLIENTES[0];
                    setDatosCliente({
                        id: datosAPI.ID,
                        codigo: datosAPI.CODIGO,
                        nombre: datosAPI.NOMBRE,
                        direccion: datosAPI.DIRECCION,
                        poblacion: datosAPI.POBLACION,
                        provincia: datosAPI.PROVINCIA,
                        cp: datosAPI.CP,
                        pais: datosAPI.PAIS,
                        telefono1: datosAPI.TELEFONO,
                        telefono2: datosAPI.TELEFONO_2,
                        telefono3: datosAPI.TELEFONO_3,
                        email: datosAPI.MAIL,
                        observacion: datosAPI.OBSERVACIONES,
                        cif: datosAPI.CIF
                    })
                }
            })

            getClienteVehiculosEnAPI("", 1, "A", clienteTallerSeleccionado.id).then((coches) => {
                if (coches.CLIENTES_VEHICULOS !== null && coches.CLIENTES_VEHICULOS.length > 0) {
                    setVehiculos(coches.CLIENTES_VEHICULOS);
                } else {
                    setVehiculos(null);
                }
            })
        }
    } else {
        if (!props.ABRIR_FICHA && hayClientes) {
            setHayClientes(false);
            setVehiculos([])
            setTabPorDefecto("1");
            setDatosCliente({
                id: null,
                codigo: null,
                nombre: "",
                direccion: "",
                poblacion: "",
                provincia: "",
                cp: "",
                pais: "",
                telefono1: "",
                telefono2: "",
                telefono3: "",
                email: "",
                observacion: "",
                cif: ""
            })
        }
    }

    if (!actualizarVehiculos && VehiculoTallerSeleccionado.actualizarListaVehiculo) {
        store.dispatch({ type: "UPDATE_LISTA_CLIENTE_VEHICULO", actualizarListaVehiculo: false })
        setActualizarVehiculos(true);
        if (VehiculoTallerSeleccionado.esNuevoVehiculo) {
            getClienteVehiculosEnAPI("", 1, "A", clienteTallerSeleccionado.id).then((coches) => {
                if (coches.CLIENTES_VEHICULOS !== null) {
                    setVehiculos(coches.CLIENTES_VEHICULOS);
                }
            })
        } else {
            let modificarVehiculo = VehiculoTallerSeleccionado.clienteVehiculoTaller;
            vehiculos.map((item, index) => {
                if (item.ID == modificarVehiculo.ID) {
                    vehiculos[index].ID = modificarVehiculo.ID;
                    vehiculos[index].ID_TALLER = modificarVehiculo.ID_TALLER;
                    vehiculos[index].ID_TECDOC = modificarVehiculo.ID_TECDOC;
                    vehiculos[index].ID_CLIENTE = modificarVehiculo.ID_CLIENTE;
                    vehiculos[index].MATRICULA = modificarVehiculo.MATRICULA;
                    vehiculos[index].DESCRIPCION = modificarVehiculo.DESCRIPCION;
                    vehiculos[index].BASTIDOR = modificarVehiculo.BASTIDOR;
                    vehiculos[index].MARCA = modificarVehiculo.MARCA;
                    vehiculos[index].MODELO = modificarVehiculo.MODELO;
                    vehiculos[index].MOTOR = modificarVehiculo.MOTOR;
                    vehiculos[index].OBSERVACIONES = modificarVehiculo.OBSERVACIONES;
                }
            })
            setVehiculos(vehiculos)
        }
        store.dispatch({ type: "UPDATE_CLIENTE_VEHICULO_TALLER", clienteVehiculoTaller: {} })
    } else {
        if (!VehiculoTallerSeleccionado.actualizarListaVehiculo && actualizarVehiculos) {
            setActualizarVehiculos(false);
        }
    }


    const guardarDatosCliente = (e) => {

        const ID = e.target.name === 'id' ? e.target.value : datosCliente.id;
        const CODIGO = e.target.name === 'codigo' ? e.target.value : datosCliente.codigo;
        const NOMBRE = e.target.name === 'nombre' ? e.target.value : datosCliente.nombre;
        const DIRECCION = e.target.name === 'direccion' ? e.target.value : datosCliente.direccion;
        const POBLACION = e.target.name === 'poblacion' ? e.target.value : datosCliente.poblacion;
        const PROVINCIA = e.target.name === 'provincia' ? e.target.value : datosCliente.provincia;
        const CP = e.target.name === 'cp' ? e.target.value : datosCliente.cp;
        const PAIS = e.target.name === 'pais' ? e.target.value : datosCliente.pais;
        const TELEFONO1 = e.target.name === 'telefono1' ? e.target.value : datosCliente.telefono1;
        const TELEFONO2 = e.target.name === 'telefono2' ? e.target.value : datosCliente.telefono2;
        const TELEFONO3 = e.target.name === 'telefono3' ? e.target.value : datosCliente.telefono3;
        const EMAIL = e.target.name === 'email' ? e.target.value : datosCliente.email;
        const OBSERVACION = e.target.name === 'observacion' ? e.target.value : datosCliente.observacion;
        const CIF = e.target.name === 'cif' ? e.target.value : datosCliente.cif;


        let modificar = {
            id: ID,
            codigo: CODIGO,
            nombre: NOMBRE,
            direccion: DIRECCION,
            poblacion: POBLACION,
            provincia: PROVINCIA,
            cp: CP,
            pais: PAIS,
            telefono1: TELEFONO1,
            telefono2: TELEFONO2,
            telefono3: TELEFONO3,
            email:EMAIL,
            observacion: OBSERVACION,
            cif: CIF
        }

        setDatosCliente({...modificar})
/*
        let modificar = {
            id: null,
            codigo: null,
            nombre: "",
            direccion: "",
            poblacion: "",
            provincia: "",
            cp: "",
            pais: "",
            telefono1: "",
            telefono2: "",
            telefono3: "",
            email: "",
            observacion: "",
            cif: ""
        }

        switch (e.target.name) {
            case "codigo":
                modificar.codigo = e.target.value;
                modificar.id = datosCliente.id;
                modificar.nombre = datosCliente.nombre;
                modificar.direccion = datosCliente.direccion;
                modificar.poblacion = datosCliente.poblacion;
                modificar.provincia = datosCliente.provincia;
                modificar.cp = datosCliente.cp;
                modificar.telefono1 = datosCliente.telefono1;
                modificar.telefono2 = datosCliente.telefono2;
                modificar.telefono3 = datosCliente.telefono3;
                modificar.email = datosCliente.email;
                modificar.observacion = datosCliente.observacion;
                modificar.cif = datosCliente.cif;
                modificar.pais = datosCliente.pais;
                setDatosCliente(modificar)
                break;
            case "nombre":
                modificar.nombre = e.target.value;
                modificar.id = datosCliente.id;
                modificar.codigo = datosCliente.codigo;
                modificar.direccion = datosCliente.direccion;
                modificar.poblacion = datosCliente.poblacion;
                modificar.provincia = datosCliente.provincia;
                modificar.cp = datosCliente.cp;
                modificar.telefono1 = datosCliente.telefono1;
                modificar.telefono2 = datosCliente.telefono2;
                modificar.telefono3 = datosCliente.telefono3;
                modificar.email = datosCliente.email;
                modificar.observacion = datosCliente.observacion;
                modificar.cif = datosCliente.cif;
                modificar.pais = datosCliente.pais;
                setDatosCliente(modificar)
                break;
            case "direccion":
                modificar.direccion = e.target.value;
                modificar.id = datosCliente.id;
                modificar.codigo = datosCliente.codigo;
                modificar.nombre = datosCliente.nombre;
                modificar.poblacion = datosCliente.poblacion;
                modificar.provincia = datosCliente.provincia;
                modificar.cp = datosCliente.cp;
                modificar.telefono1 = datosCliente.telefono1;
                modificar.telefono2 = datosCliente.telefono2;
                modificar.telefono3 = datosCliente.telefono3;
                modificar.email = datosCliente.email;
                modificar.observacion = datosCliente.observacion;
                modificar.cif = datosCliente.cif;
                modificar.pais = datosCliente.pais;
                setDatosCliente(modificar)
                break;
            case "poblacion":
                modificar.poblacion = e.target.value;
                modificar.id = datosCliente.id;
                modificar.codigo = datosCliente.codigo;
                modificar.nombre = datosCliente.nombre;
                modificar.direccion = datosCliente.direccion;
                modificar.provincia = datosCliente.provincia;
                modificar.cp = datosCliente.cp;
                modificar.telefono1 = datosCliente.telefono1;
                modificar.telefono2 = datosCliente.telefono2;
                modificar.telefono3 = datosCliente.telefono3;
                modificar.email = datosCliente.email;
                modificar.observacion = datosCliente.observacion;
                modificar.cif = datosCliente.cif;
                modificar.pais = datosCliente.pais;
                setDatosCliente(modificar)
                break;
            case "provincia":
                modificar.provincia = e.target.value;
                modificar.id = datosCliente.id;
                modificar.codigo = datosCliente.codigo;
                modificar.nombre = datosCliente.nombre;
                modificar.direccion = datosCliente.direccion;
                modificar.poblacion = datosCliente.poblacion;
                modificar.cp = datosCliente.cp;
                modificar.telefono1 = datosCliente.telefono1;
                modificar.telefono2 = datosCliente.telefono2;
                modificar.telefono3 = datosCliente.telefono3;
                modificar.email = datosCliente.email;
                modificar.observacion = datosCliente.observacion;
                modificar.cif = datosCliente.cif;
                modificar.pais = datosCliente.pais;
                setDatosCliente(modificar)
                break;
            case "cp":
                modificar.cp = e.target.value;
                modificar.id = datosCliente.id;
                modificar.codigo = datosCliente.codigo;
                modificar.nombre = datosCliente.nombre;
                modificar.direccion = datosCliente.direccion;
                modificar.poblacion = datosCliente.poblacion;
                modificar.provincia = datosCliente.provincia;
                modificar.telefono1 = datosCliente.telefono1;
                modificar.telefono2 = datosCliente.telefono2;
                modificar.telefono3 = datosCliente.telefono3;
                modificar.email = datosCliente.email;
                modificar.observacion = datosCliente.observacion;
                modificar.cif = datosCliente.cif;
                modificar.pais = datosCliente.pais;
                setDatosCliente(modificar)
                break;
            case "pais":
                modificar.pais = e.target.value;
                modificar.id = datosCliente.id;
                modificar.codigo = datosCliente.codigo;
                modificar.nombre = datosCliente.nombre;
                modificar.direccion = datosCliente.direccion;
                modificar.poblacion = datosCliente.poblacion;
                modificar.provincia = datosCliente.provincia;
                modificar.cp = datosCliente.cp;
                modificar.telefono1 = datosCliente.telefono1;
                modificar.telefono2 = datosCliente.telefono2;
                modificar.telefono3 = datosCliente.telefono3;
                modificar.email = datosCliente.email;
                modificar.observacion = datosCliente.observacion;
                modificar.cif = datosCliente.cif;

                setDatosCliente(modificar)
                break;
            case "telefono1":
                modificar.telefono1 = e.target.value;
                modificar.id = datosCliente.id;
                modificar.codigo = datosCliente.codigo;
                modificar.nombre = datosCliente.nombre;
                modificar.direccion = datosCliente.direccion;
                modificar.poblacion = datosCliente.poblacion;
                modificar.provincia = datosCliente.provincia;
                modificar.cp = datosCliente.cp;

                modificar.telefono2 = datosCliente.telefono2;
                modificar.telefono3 = datosCliente.telefono3;
                modificar.email = datosCliente.email;
                modificar.observacion = datosCliente.observacion;
                modificar.cif = datosCliente.cif;
                modificar.pais = datosCliente.pais;
                setDatosCliente(modificar)
                break;
            case "telefono2":
                modificar.telefono2 = e.target.value;
                modificar.id = datosCliente.id;
                modificar.codigo = datosCliente.codigo;
                modificar.nombre = datosCliente.nombre;
                modificar.direccion = datosCliente.direccion;
                modificar.poblacion = datosCliente.poblacion;
                modificar.provincia = datosCliente.provincia;
                modificar.cp = datosCliente.cp;
                modificar.telefono1 = datosCliente.telefono1;

                modificar.telefono3 = datosCliente.telefono3;
                modificar.email = datosCliente.email;
                modificar.observacion = datosCliente.observacion;
                modificar.cif = datosCliente.cif;
                modificar.pais = datosCliente.pais;
                setDatosCliente(modificar)
                break;
            case "telefono3":
                modificar.telefono3 = e.target.value;
                modificar.id = datosCliente.id;
                modificar.codigo = datosCliente.codigo;
                modificar.nombre = datosCliente.nombre;
                modificar.direccion = datosCliente.direccion;
                modificar.poblacion = datosCliente.poblacion;
                modificar.provincia = datosCliente.provincia;
                modificar.cp = datosCliente.cp;
                modificar.telefono1 = datosCliente.telefono1;
                modificar.telefono2 = datosCliente.telefono2;

                modificar.email = datosCliente.email;
                modificar.observacion = datosCliente.observacion;
                modificar.cif = datosCliente.cif;
                modificar.pais = datosCliente.pais;
                setDatosCliente(modificar)
                break;

            case "email":
                modificar.email = e.target.value;
                modificar.id = datosCliente.id;
                modificar.codigo = datosCliente.codigo;
                modificar.nombre = datosCliente.nombre;
                modificar.direccion = datosCliente.direccion;
                modificar.poblacion = datosCliente.poblacion;
                modificar.provincia = datosCliente.provincia;
                modificar.cp = datosCliente.cp;
                modificar.telefono1 = datosCliente.telefono1;
                modificar.telefono2 = datosCliente.telefono2;
                modificar.telefono3 = datosCliente.telefono3;
                modificar.observacion = datosCliente.observacion;
                modificar.cif = datosCliente.cif;
                modificar.pais = datosCliente.pais;
                setDatosCliente(modificar)
                break;

            case "observacion":
                modificar.observacion = e.target.value;
                modificar.id = datosCliente.id;
                modificar.codigo = datosCliente.codigo;
                modificar.nombre = datosCliente.nombre;
                modificar.direccion = datosCliente.direccion;
                modificar.poblacion = datosCliente.poblacion;
                modificar.provincia = datosCliente.provincia;
                modificar.cp = datosCliente.cp;
                modificar.telefono1 = datosCliente.telefono1;
                modificar.telefono2 = datosCliente.telefono2;
                modificar.telefono3 = datosCliente.telefono3;
                modificar.email = datosCliente.email;
                modificar.cif = datosCliente.cif;
                modificar.pais = datosCliente.pais;
                setDatosCliente(modificar)
                break;
            case "cif":
                modificar.cif = e.target.value;
                modificar.id = datosCliente.id;
                modificar.nombre = datosCliente.nombre;
                modificar.codigo = datosCliente.codigo;
                modificar.direccion = datosCliente.direccion;
                modificar.poblacion = datosCliente.poblacion;
                modificar.provincia = datosCliente.provincia;
                modificar.cp = datosCliente.cp;
                modificar.telefono1 = datosCliente.telefono1;
                modificar.telefono2 = datosCliente.telefono2;
                modificar.telefono3 = datosCliente.telefono3;
                modificar.email = datosCliente.email;
                modificar.observacion = datosCliente.observacion;
                modificar.pais = datosCliente.pais;
                setDatosCliente(modificar)
                break;
        }
*/
        //let previa = {[e.target.name]: e.target.value 
    }

    const insertarCliente = () => {
        //Verificar campos necesarios.
        setClienteTallerEnAPI(clienteTallerSeleccionado.esNuevoCliente ? 0 : 1, datosCliente).then((respuesta) => {
            if (respuesta.OK) {
                store.dispatch({ type: "UPDATE_CLIENTE_TALLER", clienteTaller: datosCliente })
                store.dispatch({ type: "UPDATE_LISTA_TALLER_CLIENTE", actualizarListaCliente: true })
                
                store.dispatch(DrawerFichaMantenimientoClienteTaller(false));
                props.CERRAR_FICHA(false);

                if (clienteTallerSeleccionado.esNuevoCliente) {
                    MSGOK("Cliente añadido correctamente.")
                } else {
                    MSGOK("Cliente modificado correctamente.")
                }
            }
        })
    }

    const eliminarVehiculoCliente = (ID, ID_CLIENTE, MATRICULA) => {
        let payload = {
            ID: ID,
            ID_TALLER: 0,
            ID_TECDOC: 0,
            ID_CLIENTE: ID_CLIENTE,
            MATRICULA: MATRICULA,
            DESCRIPCION: "",
            BASTIDOR: "",
            MARCA: "",
            MODELO: "",
            MOTOR: "",
            OBSERVACIONES: ""
        }
        setClienteVehiculoTallerEnAPI(2, payload).then((respuesta) => {
            if (respuesta.OK) {
                MSGOK("Vehículo eliminado correctamente.")
                let filteredItems = vehiculos.filter(item => item.ID !== ID)
                if (filteredItems.length > 0) {
                    setVehiculos(filteredItems)
                } else {
                    setVehiculos(null)
                }
            }
        })
    }

    return (
        <div>
            <Drawer
                width={600}
                onClose={() => {
                    store.dispatch(DrawerFichaMantenimientoClienteTaller(false));
                    store.dispatch({ type: "UPDATE_ID_CLIENTE_ID", id: "" });
                    store.dispatch({ type: "UPDATE_MODO_FICHA_CLIENTE_TALLER", esNuevoCliente: false })
                    props.CERRAR_FICHA(false);
                }}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ zIndex: '9999999999', color: '#fff' }}>

                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                            onClick={(e) => { store.dispatch(DrawerFichaMantenimientoClienteTaller(false)); createRipple(e); store.dispatch({ type: "UPDATE_ID_CLIENTE_ID", id: "" }); store.dispatch({ type: "UPDATE_MODO_FICHA_CLIENTE_TALLER", esNuevoCliente: false }); props.CERRAR_FICHA(false); }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>


                 
                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {traducirPagina("Ficha_de_cliente").toUpperCase()}
                    </div>
                </div>

                <div className="p-2" style={{ height: (configApp.height - 70).toString() + "px", overflow: 'auto' }}>
                    <Tabs defaultActiveKey={"1"} activeKey={tabPorDefecto} onChange={(tab) => setTabPorDefecto(tab)} >
                        <TabPane tab={traducirPagina("Datos_generales")} key="1" >
                            <div style={{ height: (configApp.height - 190).toString() + "px", }} className="ml-4 mr-4" >
                                <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                                    <Col md={10} >
                                        <span>{traducirPagina("Codigo")}</span>
                                    </Col>
                                    <Col md={14} >
                                        <span>{traducirPagina("Cif").toUpperCase()}</span>
                                    </Col>
                                    <Col md={10} >
                                        <Input disabled={true} name="codigo" value={datosCliente.codigo} onChange={(e) => guardarDatosCliente(e)} />
                                    </Col>

                                    <Col md={10} >
                                        <Input name="cif" value={datosCliente.cif} onChange={(e) => guardarDatosCliente(e)} />
                                    </Col>

                                    <Col md={24}>
                                        <span>{traducirPagina("Nombre")} </span>
                                    </Col>
                                    <Col md={24} >
                                        <Input name="nombre" value={datosCliente.nombre} onChange={(e) => guardarDatosCliente(e)} />
                                    </Col>

                                    <Col md={8} >
                                        <span>{traducirPagina("Telefono_1")} </span>
                                    </Col>

                                    <Col md={8} >
                                        <span>{traducirPagina("Telefono_2")} </span>
                                    </Col>

                                    <Col md={8} >
                                        <span>{traducirPagina("Telefono_3")} </span>
                                    </Col>

                                    <Col md={8} >
                                        <Input name="telefono1" value={datosCliente.telefono1} onChange={(e) => guardarDatosCliente(e)} />
                                    </Col>
                                    <Col md={8} >
                                        <Input name="telefono2" value={datosCliente.telefono2} onChange={(e) => guardarDatosCliente(e)} />
                                    </Col>
                                    <Col md={8} >
                                        <Input name="telefono3" value={datosCliente.telefono3} onChange={(e) => guardarDatosCliente(e)} />
                                    </Col>
                                    <Col md={24}>
                                        <span>{traducirPagina("Email")}</span>
                                    </Col>

                                    <Col md={24}>
                                        <Input name="email" value={datosCliente.email} onChange={(e) => guardarDatosCliente(e)} />
                                    </Col>

                                    <Col md={24}>
                                        <span>{traducirPagina("Observaciones")}</span>
                                    </Col>

                                    <Col md={24}>
                                        <Input.TextArea allowClear rows={7} name="observacion" value={datosCliente.observacion} onChange={(e) => guardarDatosCliente(e)} />
                                    </Col>
                                </Row>
                            </div>

                            <div style={{ flexGrow: '6', textAlign: 'right' }} className="ml-4 mr-4" >
                                <button className="activebtnMenu2" onClick={() => {

                                    if (props.GUARDAR_INTERNO) {
                                        insertarCliente();
                                        store.dispatch(DrawerFichaMantenimientoClienteTaller(false))
                                        props.CERRAR_FICHA(false);
                                    } else {
                                        props.GUARDAR_REGISTRO(datosCliente);
                                    }


                                }} style={{ height: '40px', width: '220px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', cursor: 'pointer' }} ><SaveOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Guardar_y_salir").toUpperCase()} </span>  </button>
                            </div>
                        </TabPane>

                        <TabPane tab={traducirPagina("Direccion_fiscal")} key="2" >
                            <div style={{ height: (configApp.height - 203).toString() + "px", }} className="mb-2" >
                                <div style={{ height: (configApp.height - 190).toString() + "px", }} className="ml-4 mr-4" >
                                    <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                                        <Col md={24} >
                                            <span>{traducirPagina("Pais")} </span>
                                        </Col>
                                        <Col md={24}>
                                            <Input name="pais" value={datosCliente.pais} onChange={(e) => guardarDatosCliente(e)} />
                                        </Col>

                                        <Col md={24}>
                                            <span>{traducirPagina("Provincia")}</span>
                                        </Col>
                                        <Col md={24} >
                                            <Input name="provincia" value={datosCliente.provincia} onChange={(e) => guardarDatosCliente(e)} />
                                        </Col>

                                        <Col md={24}>
                                            <span>{traducirPagina("Poblacion")}</span>
                                        </Col>

                                        <Col md={24} >
                                            <Input name="poblacion" value={datosCliente.poblacion} onChange={(e) => guardarDatosCliente(e)} />
                                        </Col>

                                        <Col md={20} >
                                            <span>{traducirPagina("Direccion")} </span>
                                        </Col>

                                        <Col md={4}>
                                            <span>{traducirPagina("CP").toUpperCase()}</span>
                                        </Col>

                                        <Col md={20} >
                                            <Input name="direccion" value={datosCliente.direccion} onChange={(e) => guardarDatosCliente(e)} />
                                        </Col>

                                        <Col md={4} >
                                            <Input name="cp" value={datosCliente.cp} onChange={(e) => guardarDatosCliente(e)} />
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div style={{ flexGrow: '6', textAlign: 'right' }} className="ml-4 mr-4" >
                                <button className="activebtnMenu2" onClick={() => {
                                    if (props.GUARDAR_INTERNO) {
                                        insertarCliente();
                                        store.dispatch(DrawerFichaMantenimientoClienteTaller(false))
                                        props.CERRAR_FICHA(false);
                                    } else {
                                        props.GUARDAR_REGISTRO(datosCliente);
                                    }

                                }} style={{ height: '40px', width: '220px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', cursor: 'pointer' }} ><SaveOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Guardar_y_salir").toUpperCase()} </span>  </button>
                            </div>
                        </TabPane>

                        <TabPane tab={traducirPagina("Vehiculos")} key="3" disabled={clienteTallerSeleccionado.esNuevoCliente}>
                            <div className="flex-container">
                                <div style={{ flexGrow: '6', textAlign: 'right' }} className="mb-2">
                                    <button className="activebtnMenu2 btnEstiloPersonalizado" style={{ height: '40px', width: '180px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#1890ff', backgroundColor: 'transparent', cursor: 'pointer' }} onClick={() => {
                                        store.dispatch({ type: "UPDATE_MODO_FICHA_VEHICULO_TALLER", esNuevoVehiculo: true });
                                        setMantenimientoVehiculo(true)
                                        store.dispatch(DrawerFichaMantenimientoVehiculoTaller(true))
                                    }
                                    }><PlusOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Nuevo_vehiculo")} </span>  </button>
                                </div>
                            </div>

                            <div style={{ height: (configApp.height - 190).toString() + "px", }} className="ml-4 mr-4" >
                                {vehiculos === null ?
                                    <div style={{ textAlign: 'center' }}>
                                        <h4>{traducirPagina("No_vehiculos")}</h4>
                                    </div>
                                    :
                                    vehiculos.length > 0 ?
                                        <MDBTable responsive hover className="mdb-skin">
                                            <MDBTableHead>
                                                <tr>
                                                    <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '110px', verticalAlign: 'baseline' }}>{traducirPagina("Matricula")}</th>
                                                    <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '200px', verticalAlign: 'baseline' }}>{traducirPagina("Bastidor")}</th>
                                                    <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", verticalAlign: 'baseline' }}>{traducirPagina("Descripcion")}</th>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                {vehiculos.map((vehiculo, i) =>
                                                    <tr key={i}>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", verticalAlign: 'middle' }}>{vehiculo.MATRICULA}</td>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", verticalAlign: 'middle' }}>{vehiculo.BASTIDOR}</td>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", verticalAlign: 'middle' }}>{vehiculo.DESCRIPCION.substring(0, vehiculo.DESCRIPCION.length > 7 ? 7 : vehiculo.DESCRIPCION.length) + " ..."}</td>

                                                        <td style={{ paddingLeft: 0, fontSize: "16px", width: '100px', textAlign: 'right', verticalAlign: 'middle' }}>
                                                            <div className="flex-container-Grow-Simple">
                                                                <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>

                                                                    <MDBBtn onClick={() => {

                                                                        store.dispatch({ type: "UPDATE_VEHICLE_ID_TALLER", id: vehiculo.ID });
                                                                        store.dispatch({ type: "UPDATE_MODO_FICHA_VEHICULO_TALLER", esNuevoVehiculo: false });
                                                                        setMantenimientoVehiculo(true)
                                                                        store.dispatch(DrawerFichaMantenimientoVehiculoTaller(true));
                                                                    }
                                                                    }
                                                                        className="btn-matricula  estiloBotonRemove paddindDeTres">
                                                                        <EditOutlined style={{ color: '#999', cursor: 'pointer', fontSize: '20px' }} />
                                                                    </MDBBtn>
                                                                </div>
                                                                <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                    <MDBBtn className="btn-matricula  estiloBotonRemove paddindDeTres" onClick={() => eliminarVehiculoCliente(vehiculo.ID, vehiculo.ID_CLIENTE, vehiculo.MATRICULA)} >
                                                                        <DeleteOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                    </MDBBtn>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </MDBTableBody>
                                        </MDBTable>
                                        :
                                        <div style={{ fontSize: '16px', fontWeight: '500', color: "#000" }} className="row">
                                            <MDBCol md="9">
                                                <div>
                                                    <span className="skeleton-box" style={{ width: '500px' }}></span>
                                                    <br />
                                                    <span className="skeleton-box" style={{ width: '500px' }}></span>
                                                    <br />
                                                    <span className="skeleton-box" style={{ width: '500px' }}></span>
                                                    <br />
                                                    <span className="skeleton-box" style={{ width: '400px' }}></span>
                                                    <br />
                                                    <span className="skeleton-box" style={{ width: '400px' }}></span>
                                                    <br />
                                                    <span className="skeleton-box" style={{ width: '400px' }}></span>
                                                </div>
                                            </MDBCol>
                                        </div>
                                }
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </Drawer>

            {mantenimientoVehiculo ?
                <FichaMantenimientoVehiculoTaller
                    ABRIR_FICHA={mantenimientoVehiculo}
                    CERRAR_FICHA={setMantenimientoVehiculo}
                    GUARDAR_INTERNO={true}

                ></FichaMantenimientoVehiculoTaller>

                : null
            }


        </div>
    )
}


MantenimientoClienteTaller.propTypes = {
    title: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    GUARDAR_INTERNO: PropTypes.bool,
    GUARDAR_REGISTRO: PropTypes.func
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        clienteTallerSeleccionado: state.clienteTallerSeleccionado,
        VehiculoTallerSeleccionado: state.VehiculoTallerSeleccionado,
        openDrawerFichaMantenimientoVehiculo: state.estadoDrawer.openDrawerFichaMantenimientoVehiculo

    };
}

export default connect(mapStateToProps)(MantenimientoClienteTaller);