import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBNav, MDBNavItem, MDBCol } from 'mdbreact';
import 'antd/dist/antd.css';
import { Drawer, Col, Layout, Pagination, Input, Select, Row, Button } from 'antd';
import { getStockDesglose, ObtenerBateriasERPEnAPI } from "../action/actionCreatorsRequests";
import { DrawerStockAlmacen} from "../action/actionCreators";
import {
    traducirPagina, referenciaActualSelect, verificarSiEsMostrador,
    verficarSiEsCatalogo, copiarAlPortapapeles, crearLineasParaElPresupuesto, MSGERROR, obtenerDatosUsuarioYLicenciaActual,
    soloNumeros, dynamicSortMultipleDesc, convertirMoneda, verficarSiEsPVP, buscarVehiculoLocal, condicionesAbrirCesta,
    cestaAutomatica, dynamicSortAsc, condicionesAbrirCestav2
} from "../librerias/funciones"
import FichaAñadirCesta from "./FichaAñadirCesta.jsx";
import FichaStockSocio from "./FichaStockSocio.jsx";
import store from '../store'
import {
    ShoppingOutlined,
    EyeOutlined,
    LikeOutlined,
    DislikeOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    EyeInvisibleOutlined,
    CopyOutlined,
    MinusOutlined,
    PlusOutlined,
    FileAddOutlined
} from '@ant-design/icons';

const { Content } = Layout;
const { Option } = Select;

const FichaBaterias = ({ props, configApp, usuario, clienteSeleccionado }) => {
    const [abrirFichaStockSocio, setAbrirFichaStockSocio] = React.useState(false);
    const [datosPaginacion, setDatosPaginacion] = React.useState({ iniPage: 1, inicio: 1, final: 40, numPagina: 40 });
    const [colapseMarca, setColapseMarca] = React.useState(true);
    const [visualizacionDescuento, setVisualizacionDescuento] = React.useState(true);
    const [hayReferencias, setHayReferencias] = React.useState(false);

    const [verFiltros, setVerFiltros] = React.useState(true);
    const [mostrarFilrosComponente, setMostrarFilrosComponente] = React.useState(true);
    
    const [referencias, setReferencia] = React.useState([]);
    const [referenciasAux, setReferenciaAux] = React.useState([]);
    const [marcas, setMarcas] = React.useState([]);
    const [fichaCesta, setFichaCesta] = React.useState(false);
    const [referenciaSeleccionada, setReferenciaSeleccionada] = React.useState({});



    const [payloadLocal, setPayloadLocal] = React.useState({
        VOLTAJE: "",
        AMPERIOS: "",
        POTENCIA_ARRANQUE: ""
    });


    const inputRef = React.useRef(null);
    const sharedProps = {
        ref: inputRef,
    };
    
    const guardarDatos = (e) => {
        const VOLTAJE = e.target.name === 'VOLTAJE' ? e.target.value : payloadLocal.VOLTAJE;
        const AMPERIOS = e.target.name === 'AMPERIOS' ? e.target.value : payloadLocal.AMPERIOS;
        const POTENCIA_ARRANQUE = e.target.name === 'POTENCIA_ARRANQUE' ? e.target.value : payloadLocal.POTENCIA_ARRANQUE;

        setPayloadLocal(
            {
                VOLTAJE: VOLTAJE,
                AMPERIOS: AMPERIOS,
                POTENCIA_ARRANQUE: POTENCIA_ARRANQUE
            }
        )
    }



    const paginarBaterias = (page, pageSize) => {
        if (referencias.length > 0) {
            let pInicio = (pageSize * page) - (datosPaginacion.numPagina);
            let pFinal = pageSize * page;
            setDatosPaginacion(
                {
                    iniPage: page,
                    inicio: pInicio,
                    final: pFinal,
                    numPagina: datosPaginacion.numPagina
                }
            )

        }
    }


    const filtrarReferencias = (registros, filtros) => {
        let listaDeRegistrosFiltrados = []
        if (registros !== null) {
            if (registros.length > 0) {
                if (filtros.MARCA === undefined) {
                    listaDeRegistrosFiltrados = referenciasAux
                } else if (filtros.MARCA !== "") {
                    listaDeRegistrosFiltrados = registros.filter(registro => registro.MARCA === filtros.MARCA);
                }

            }
        }

        if (listaDeRegistrosFiltrados.length > 0) {
            setReferencia(listaDeRegistrosFiltrados)

        } else {
            setReferencia(null)
        }


    }


    const updateNumberPage = (current, size) => {
        setDatosPaginacion(
            {
                iniPage: 1,
                inicio: 1,
                final: size,
                numPagina: size
            }
        )

    }

    const añadirALaCesta = async (registro, CANTIDAD) => {
        if (CANTIDAD === "") {
            MSGERROR(traducirPagina("ingrese_cantidad"))
        } else if (registro.STOCK_TOTAL === 0 ? false : parseInt(CANTIDAD) > registro.STOCK_TOTAL) {
            MSGERROR(`${traducirPagina("cantidad_maxima")} ${registro.STOCK_TOTAL}`)
        } else {
            let esMostrador = verificarSiEsMostrador()
            if (esMostrador && clienteSeleccionado.nombre === "") {
                //setVisible(true)
                MSGERROR(traducirPagina("Msg_vender"))
            } else {
                const VEHICULO = await buscarVehiculoLocal();
                const { TIPO_STOCK, ABRIR_FICHA } = condicionesAbrirCestav2(registro, CANTIDAD);
                if (ABRIR_FICHA) {
                    setReferenciaSeleccionada(
                        {
                            CANTIDAD_SELECCIONADA: CANTIDAD,
                            REFERENCIA: "",
                            CODIGO_MARCA: "",
                            REFERENCIA_PROVEEDOR: registro.CODIGO,
                            REGISTROS: {}
                        }
                    )


                    setFichaCesta(true)

                   
                } else {
                    getStockDesglose("", "", registro.CODIGO).then(stock => {
                        const REGISTRO = {
                            REFERENCIA_PROVEEDOR: registro.CODIGO,
                            CODIGO_MARCA: registro.MARCA,
                            REFERENCIA: registro.REFERENCIA_COMERCIAL,
                            CODIGO_MARCA: "0",
                            NOMRE_MARCA: registro.DESCRIPCION,
                            CANTIDAD: CANTIDAD,
                            PRECIO: registro.PRECIO,
                            DESCUENTO: registro.DESCUENTO,
                            NETO: registro.NETO,
                            DESCRIPCION_CESTA: "[ " + registro.MARCA.toUpperCase() + " ] - " + registro.DESCRIPCION.toUpperCase(),

                        }
                        cestaAutomatica(stock, TIPO_STOCK, CANTIDAD, REGISTRO, VEHICULO);

                    })
                   
                }
                /*

                if (!ABRIR_FICHA) {

                    getStockDesglose("", "", registro.CODIGO).then(stock => {
                        const REGISTRO = {
                            REFERENCIA_PROVEEDOR: registro.CODIGO,
                            CODIGO_MARCA: registro.MARCA,
                            REFERENCIA: registro.REFERENCIA_COMERCIAL,
                            CODIGO_MARCA: "0",
                            NOMRE_MARCA: registro.DESCRIPCION,
                            CANTIDAD: CANTIDAD,
                            PRECIO: registro.PRECIO,
                            DESCUENTO: registro.DESCUENTO,
                            NETO: registro.NETO,
                            DESCRIPCION_CESTA: "[ " + registro.MARCA.toUpperCase() + " ] - " + registro.DESCRIPCION.toUpperCase(),

                        }
                        cestaAutomatica(stock, TIPO_STOCK, CANTIDAD, REGISTRO, VEHICULO);

                    })
                } else {

                    setReferenciaSeleccionada(
                        {
                            CANTIDAD_SELECCIONADA: CANTIDAD,
                            REFERENCIA: "",
                            CODIGO_MARCA: "",
                            REFERENCIA_PROVEEDOR: registro.CODIGO,
                            REGISTROS: {}
                        }
                    )


                    setFichaCesta(true)
                }

*/
            }
        }
    }


    const abrirDesglose = (item) => {
        if (!obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE) {
            store.dispatch(DrawerStockAlmacen(true));
            referenciaActualSelect({ REFERENCIA: "", CODIGO_MARCA: "", REFERENCIA_PROVEEDOR: item.CODIGO, REGISTROS: {} });
        }
    }


    const buscarMarcas = (registros) => {
        let agrupacionMarcas = registros.map(item => item.MARCA)
            .filter((value, index, self) => self.indexOf(value) === index)
        agrupacionMarcas = agrupacionMarcas.sort((a, b) => a.localeCompare(b));

        setMarcas(agrupacionMarcas)
    }

    const agregarAPresupuesto = (ITEM) => {
        let objeto = {
            REFERENCIA: "",
            REFERENCIA_PROVEEDOR: "",
            CODIGO_MARCA_ERP: "",
            NOMRE_MARCA: "",
            CODIGO_MARCA: 0,
            NOMBRE_GRUPO_MONTAJE: "",
            PRECIO: 0,
            CANTIDAD: 1,
        };

        objeto.REFERENCIA = ITEM.REFERENCIA_COMERCIAL;
        objeto.REFERENCIA_PROVEEDOR = ITEM.REFERENCIA_COMERCIAL;
        objeto.CODIGO_MARCA_ERP = "";
        objeto.NOMRE_MARCA = ITEM.MARCA;
        objeto.CODIGO_MARCA = 0;
        objeto.NOMBRE_GRUPO_MONTAJE = traducirPagina("Lubricante").toUpperCase() + ": " + ITEM.DESCRIPCION;
        objeto.PRECIO = ITEM.PRECIO;
        objeto.CANTIDAD = ITEM.CANTIDAD;

        crearLineasParaElPresupuesto(objeto, "A");
    }


    const vistaPrevia = () => {

        buscarBaterias(payloadLocal.VOLTAJE, payloadLocal.AMPERIOS, payloadLocal.POTENCIA_ARRANQUE)
    }


    const cantidadInicial = (BATERIAS, datosPaginacion) => {
        BATERIAS.map((registro, i) =>
            (i + 1) >= datosPaginacion.inicio && (i + 1) <= datosPaginacion.final ?

                document.getElementById("CANTIDAD_BATERIA" + registro.MARCA + registro.REFERENCIA_COMERCIAL) !== null ?
                    document.getElementById("CANTIDAD_BATERIA" + registro.MARCA + registro.REFERENCIA_COMERCIAL).value = "1"
                    :
                    null

                :
                null
        )
    }

    const buscarBaterias = (VOLTAJE, AMPERIOS, POTENCIA_ARRANQUE) => {
        setReferencia([])
        setReferenciaAux([])
        setDatosPaginacion({ iniPage: 1, inicio: 1, final: 40, numPagina: 40 })

        ObtenerBateriasERPEnAPI(VOLTAJE, AMPERIOS, POTENCIA_ARRANQUE).then(registros => {
            if (registros !== undefined) {
                if (registros.BATERIAS !== null && registros.BATERIAS.length > 0) {
                    setReferencia(registros.BATERIAS)
                    setReferenciaAux(registros.BATERIAS)
                    buscarMarcas(registros.BATERIAS)

                    cantidadInicial(registros.BATERIAS, datosPaginacion)


                } else {
                    setReferencia(null)
                    setReferenciaAux([])
                }
            }
        })
    }








    if (!hayReferencias && props.ABRIR_FICHA) {
        setHayReferencias(true);

        setTimeout(() => {
            inputRef.current.focus({
                cursor: 'start',
            });
        }, 100)


        if (payloadLocal.VOLTAJE > 0 && payloadLocal.AMPERIOS > 0 && payloadLocal.POTENCIA_ARRANQUE > 0) {
            buscarBaterias(payloadLocal.VOLTAJE, payloadLocal.AMPERIOS, payloadLocal.POTENCIA_ARRANQUE)
        } else {
            setReferencia(null)
            setReferenciaAux([])
        }


    } else {
        if (!props.ABRIR_FICHA && hayReferencias) {
            setDatosPaginacion({ iniPage: 1, inicio: 1, final: 40, numPagina: 40 })

            setHayReferencias(false);
            setReferencia([])
            setReferenciaAux([])
            setMarcas([])
            setPayloadLocal(
                {
                    VOLTAJE: "",
                    AMPERIOS: "",
                    POTENCIA_ARRANQUE: ""
                }
            )
        }
    }


    const filtroReferenciaLocal = (listaDeReferenciasDeLaPaginaActual) => {

        listaDeReferenciasDeLaPaginaActual = listaDeReferenciasDeLaPaginaActual.sort(dynamicSortMultipleDesc("STOCK_ALMACEN", "STOCK_TOTAL"))


        let nuevasReferencia = [];
        let GRUPO = { nombreMarca: "", referencias: [] }
        let verdes = []
        let naranjas = []
        let rojos = []
        let rojos_sin_precio = []
        let rojos_sin_precio_sin_proveedor = []



        listaDeReferenciasDeLaPaginaActual.map((item) => {
            if (item.STOCK_ALMACEN > 0) {
                verdes.push(item)
            }

            if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL > 0) {
                naranjas.push(item)
            }

            if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 && item.PRECIO > 0) {
                rojos.push(item)
            }
            if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 && item.PRECIO === 0 && item.REFERENCIA_COMERCIAL !== "") {
                rojos_sin_precio.push(item)
            }

            if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 && item.PRECIO === 0 && item.REFERENCIA_COMERCIAL === "") {
                rojos_sin_precio_sin_proveedor.push(item)
            }
        })

        verdes.sort(dynamicSortAsc("NETO"))
        naranjas.sort(dynamicSortAsc("NETO"))
        rojos.sort(dynamicSortAsc("NETO"))

        let preciosFinal = [...verdes, ...naranjas, ...rojos, ...rojos_sin_precio, ...rojos_sin_precio_sin_proveedor];


        return preciosFinal

    }





    return (
        <div>
            <Drawer
                width={1250}
                onClose={() => {
                    if (props.TIPO === "MASTER") {
                        props.CERRAR.setState({ ABRIR_FICHA_BATERIAS: false })
                    } else {
                        props.CERRAR_FICHA(false)
                    }

                }}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                closable={false}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>



                <div className="flex-container-Grow-Tercero">
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                            if (props.TIPO === "MASTER") {
                                props.CERRAR.setState({ ABRIR_FICHA_BATERIAS: false })
                            } else {
                                props.CERRAR_FICHA(false)
                            }
                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>



                <div className="p-3">
                    <Content>
                        <div className="flex-container p-2" style={{ backgroundColor: '#eee' }} >
                            <div className="flex-item-left" style={{ textAlign: 'left', padding: '0px', flex: '40%' }}>
                                <div className="classic-tabs">
                                    <MDBNav classicTabs color="#808080" style={{ backgroundColor: '#eee' }} >
                                        <MDBNavItem>
                                            {
                                                colapseMarca ?
                                                    <div style={{ padding: '8px', cursor: 'pointer', color: '#000', visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }} onClick={() => setColapseMarca(false)} >
                                                        <FullscreenOutlined className="estiloIcono" />
                                                    </div>
                                                    :
                                                    <div style={{ padding: '8px', cursor: 'pointer', color: '#000', visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }} onClick={() => { setColapseMarca(true); }} >
                                                        <FullscreenExitOutlined className="estiloIcono" />
                                                    </div>

                                            }
                                        </MDBNavItem>

                                        <MDBNavItem>
                                            {
                                                visualizacionDescuento ?
                                                    <div style={{ padding: '8px', cursor: 'pointer', color: '#000', visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }} onClick={() => setVisualizacionDescuento(false)} >
                                                        <EyeOutlined className="estiloIcono" />
                                                    </div>
                                                    :
                                                    <div style={{ padding: '8px', cursor: 'pointer', color: '#000', visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }} onClick={() => { setVisualizacionDescuento(true) }} >
                                                        <EyeInvisibleOutlined className="estiloIcono" />
                                                    </div>

                                            }
                                        </MDBNavItem>
                                    </MDBNav>
                                </div>
                            </div>

                            <div className="flex-item-right" style={{ textAlign: 'right', padding: '0px', flex: '90%' }}>

                                {referencias !== null ?
                                    referencias.length > 0 ?
                                        <Pagination style={{ marginTop: '5px' }}
                                            showSizeChanger
                                            current={datosPaginacion.iniPage}
                                            onShowSizeChange={(current, size) => { updateNumberPage(current, size) }}
                                            total={referencias.length}
                                            showTotal={() => ` ${referencias.length} ${traducirPagina("Referencias")}`}
                                            defaultPageSize={40}
                                            onChange={(page, pageSize) => { paginarBaterias(page, pageSize) }}
                                        > </Pagination>
                                        :
                                        null
                                    : null
                                }
                            </div>
                        </div>

                        <div>
                            <Col span={verFiltros ? 5 : 0} style={{ backgroundColor: '#fff', visibility: mostrarFilrosComponente ? 'visible' : 'hidden', height: (configApp.height - 135) + "px", overflow: 'auto', overflowX: 'hidden', paddingTop: '10px' }}>
                                {verFiltros ?
                                    <div className="ml-1 mr-1" >
                                        <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>

                                            <Col md={24}>
                                                <span>{traducirPagina("Marcas")} </span>
                                            </Col>

                                            <Col md={24}>
                                                {marcas === null ?
                                                    <div></div>
                                                    :
                                                    <Select
                                                        showSearch
                                                        allowClear={true}
                                                        placeholder={traducirPagina("Seleccione_una_marca")}
                                                        style={{ width: "100%", textAlign: 'left', marginBottom: '10px' }}
                                                        optionFilterProp="children"
                                                        onChange={(e) => {
                                                            filtrarReferencias(referenciasAux, { MARCA: e });
                                                        }}
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {marcas.length > 0 ?
                                                            marcas.map((registro, i) =>
                                                                <Option value={registro} key={i}>{registro}</Option>
                                                            )
                                                            :
                                                            null
                                                        }
                                                    </Select>
                                                }
                                            </Col>


                                            <Col md={24}>{traducirPagina("Voltaje")}</Col>

                                            <Col md={24}>
                                                <Input type="number" allowClear  {...sharedProps} name={"VOLTAJE"} onChange={(e) => guardarDatos(e.target.value === null ? "" : e)} value={payloadLocal.VOLTAJE} placeholder={traducirPagina("Ingrese_el_voltaje")} style={{ marginBottom: '10px' }} onKeyPress={(e) => e.key === 'Enter' ? vistaPrevia() : {}} />
                                            </Col>

                                            <Col md={24}>{traducirPagina("Amperios")}</Col>

                                            <Col md={24}>
                                                <Input type="number" allowClear name={"AMPERIOS"} onChange={(e) => guardarDatos(e.target.value === null ? "" : e)} value={payloadLocal.AMPERIOS} placeholder={traducirPagina("Ingrese_los_amperios")} style={{ marginBottom: '10px' }} onKeyPress={(e) => e.key === 'Enter' ? vistaPrevia() : {}} />
                                            </Col>

                                            <Col md={24}>{traducirPagina("Potencia")}</Col>

                                            <Col md={24}>
                                                <Input type="number" allowClear name={"POTENCIA_ARRANQUE"} onChange={(e) => guardarDatos(e.target.value === null ? "" : e)} value={payloadLocal.POTENCIA_ARRANQUE} placeholder={traducirPagina("Ingrese_la_potencia")} style={{ marginBottom: '10px' }} onKeyPress={(e) => e.key === 'Enter' ? vistaPrevia() : {}} />
                                            </Col>

                                            <Col md={24}>
                                                <Button key="submit" type="primary" block onClick={() => {
                                                    vistaPrevia();

                                                }}>{traducirPagina("Buscar")}</Button>

                                            </Col>
                                        </Row>
                                    </div>
                                    :
                                    null
                                }
                            </Col>
                            <Col span={verFiltros ? 19 : 24} style={{ paddingLeft: '10px', height: (configApp.height - 135) + "px", overflow: 'auto', overflowX: 'hidden' }}>
                                <div>
                                    {referencias === null ?
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#fff' }}> <h3>{traducirPagina("Sin_referencias")}</h3> </div>
                                        :
                                        referencias.length > 0 ?
                                            colapseMarca ?
                                                <div>
                                                    {
                                                        filtroReferenciaLocal(referencias).map((item, i) =>
                                                            (i + 1) >= datosPaginacion.inicio && (i + 1) <= datosPaginacion.final ?

                                                                <div key={i}>
                                                                    <div className="row" style={{ padding: '10px', color: item.REFERENCIA_COMERCIAL === "" ? '#999' : '#000' }}>


                                                                        <MDBCol md="2">
                                                                            <div style={{ cursor: 'pointer' }}>
                                                                                <img
                                                                                    src={"./imagenes/bateria_coche.png"}
                                                                                    className="img-fluid z-depth-0" style={{ verticalAlign: 'text-top' }} />
                                                                                <div style={{ textAlign: 'center', fontSize: '16px', fontWeight: '500' }}>
                                                                                    {item.MARCA}
                                                                                </div>
                                                                            </div>
                                                                        </MDBCol>

                                                                        <MDBCol md="5">
                                                                            <div className="nombreArticulo">
                                                                                <strong>
                                                                                    <span id={"referenciaFABRICopiarAPortapapel" + item.REFERENCIA_COMERCIAL} className="refComercial"> {item.REFERENCIA_COMERCIAL} </span>

                                                                                    <CopyOutlined onClick={(e) => { copiarAlPortapapeles("referenciaFABRICopiarAPortapapel" + item.REFERENCIA_COMERCIAL) }} style={{ fontSize: '18px', color: '#000', verticalAlign: 'text-bottom', cursor: 'pointer', paddingRight: '10px' }} />


                                                                                </strong>
                                                                                <br />


                                                                                {item.CODIGO !== null ? <span id={"referenciaDisCopiarAPortapapel" + item.CODIGO}>{traducirPagina("Referencia_distribuidor") + ": " + item.CODIGO}</span> : null}
                                                                                {item.CODIGO !== null ? <CopyOutlined onClick={(e) => { copiarAlPortapapeles("referenciaDisCopiarAPortapapel" + item.CODIGO) }} style={{ fontSize: '18px', color: '#000', verticalAlign: 'text-bottom', cursor: 'pointer', paddingRight: '10px' }} /> : null}

                                                                                <div className="clearfix"></div>
                                                                            </div>

                                                                            <div className="clearfix"></div>
                                                                            <div className="atributosMarca" >
                                                                                <span> {item.DESCRIPCION !== "" ? item.DESCRIPCION : ""} </span>

                                                                            </div>

                                                                        </MDBCol>


                                                                        <MDBCol md="2" >
                                                                            {item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 ?
                                                                                null
                                                                                :
                                                                                <span className="next-number-picker next-number-picker-inline next-medium zoro-ui-number-picker number-picker product-number-picker" >
                                                                                    <span className="next-input-group">
                                                                                        <span className="next-input-group-addon next-before">
                                                                                            <button disabled="" type="button" className="next-btn next-medium next-btn-normal" role="button" onClick={() => {

                                                                                                if (document.getElementById("CANTIDAD_BATERIA" + item.MARCA + item.REFERENCIA_COMERCIAL) !== null) {

                                                                                                    let valor = document.getElementById("CANTIDAD_BATERIA" + item.MARCA + item.REFERENCIA_COMERCIAL).value
                                                                                                    if (valor) {
                                                                                                        if (parseInt(valor) <= 1) {
                                                                                                            document.getElementById("CANTIDAD_BATERIA" + item.MARCA + item.REFERENCIA_COMERCIAL).value = "1";
                                                                                                        } else {
                                                                                                            document.getElementById("CANTIDAD_BATERIA" + item.MARCA + item.REFERENCIA_COMERCIAL).value = parseInt(valor) - 1;
                                                                                                        }
                                                                                                    } else {
                                                                                                        document.getElementById("CANTIDAD_BATERIA" + item.MARCA + item.REFERENCIA_COMERCIAL).value = "1";
                                                                                                    }
                                                                                                }
                                                                                            }} >
                                                                                                <MinusOutlined style={{ fontSize: '16px' }} />
                                                                                            </button>
                                                                                        </span>
                                                                                        <span className="next-input next-medium next-input-group-auto-width">


                                                                                            <input id={"CANTIDAD_BATERIA" + item.MARCA + item.REFERENCIA_COMERCIAL} className="txtCantidad" aria-valuemax="1798" aria-valuemin="1" height="100%" autoComplete="off" style={{ width: '50px', margin: '5px', height: '24px' }} onChange={(e) => {
                                                                                                if (document.getElementById("CANTIDAD_BATERIA" + item.MARCA + item.REFERENCIA_COMERCIAL) !== null) {

                                                                                                    let valor = document.getElementById("CANTIDAD_BATERIA" + item.MARCA + item.REFERENCIA_COMERCIAL).value
                                                                                                    if (valor === "") {
                                                                                                        document.getElementById("CANTIDAD_BATERIA" + item.MARCA + item.REFERENCIA_COMERCIAL).value = e.target.value;
                                                                                                    } else {
                                                                                                        if (soloNumeros(valor)) {
                                                                                                            document.getElementById("CANTIDAD_BATERIA" + item.MARCA + item.REFERENCIA_COMERCIAL).value = valor === "0" ? "1" : e.target.value
                                                                                                        } else {
                                                                                                            document.getElementById("CANTIDAD_BATERIA" + item.MARCA + item.REFERENCIA_COMERCIAL).value = "1";
                                                                                                        }
                                                                                                    }

                                                                                                }
                                                                                            }} />


                                                                                        </span>
                                                                                        <span className="next-input-group-addon next-after">
                                                                                            <button type="button" className="next-btn next-medium next-btn-normal" role="button" data-spm-anchor-id="a2g0o.detail.1000016.i3.759e707cqToxAp" onClick={() => {
                                                                                                if (document.getElementById("CANTIDAD_BATERIA" + item.MARCA + item.REFERENCIA_COMERCIAL) !== null) {
                                                                                                    let valor = document.getElementById("CANTIDAD_BATERIA" + item.MARCA + item.REFERENCIA_COMERCIAL).value
                                                                                                    if (valor) {
                                                                                                        document.getElementById("CANTIDAD_BATERIA" + item.MARCA + item.REFERENCIA_COMERCIAL).value = parseInt(valor) + 1;
                                                                                                    } else {
                                                                                                        document.getElementById("CANTIDAD_BATERIA" + item.MARCA + item.REFERENCIA_COMERCIAL).value = "1";
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                            >
                                                                                                <PlusOutlined style={{ fontSize: '16px' }} />
                                                                                            </button>
                                                                                        </span>
                                                                                    </span>

                                                                                </span>
                                                                            }

                                                                        </MDBCol>


                                                                        <MDBCol md="3" style={{ textAlign: 'right' }}>
                                                                            <button className="activebtnMenu2 col-md-12" style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'dcdcdc', marginBottom: '5px', display: verficarSiEsCatalogo(usuario) ? 'none' : '' }} onClick={(e) => {
                                                                                añadirALaCesta(item, document.getElementById("CANTIDAD_BATERIA" + item.MARCA + item.REFERENCIA_COMERCIAL) === null ? "1" : document.getElementById("CANTIDAD_BATERIA" + item.MARCA + item.REFERENCIA_COMERCIAL).value)
                                                                            }}>
                                                                                <ShoppingOutlined style={{ fontSize: '20px' }} />
                                                                                <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{!verificarSiEsMostrador() ? traducirPagina("Comprar") : "Vender"}</span>

                                                                            </button>

                                                                            {/*
                                                                        <button className="activebtnMenu2 col-md-12" style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'dcdcdc', marginBottom: '5px', display: verficarSiEsCatalogo(usuario) ? 'none' : '' }} onClick={(e) => {
                                                                            añadirALaCesta(item)

                                                                        }}>
                                                                            <ShoppingOutlined style={{ fontSize: '20px' }} />
                                                                            <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{!verificarSiEsMostrador() ? traducirPagina("Comprar") : traducirPagina("Vender")}</span>

                                                                        </button>
 */}

                                                                            {!verificarSiEsMostrador() ?
                                                                                <button className="activebtnMenu2 col-md-12" style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'dcdcdc', marginBottom: '5px', /*display: verficarSiEsCatalogo(usuario) ? 'none' : '' */ } } onClick={(e) => {
                                                                                    item.CANTIDAD = document.getElementById("CANTIDAD_BATERIA" + item.MARCA + item.REFERENCIA_COMERCIAL) === null ? "1" : document.getElementById("CANTIDAD_BATERIA" + item.MARCA + item.REFERENCIA_COMERCIAL).value;


                                                                                    agregarAPresupuesto(item)

                                                                                }}>
                                                                                    <FileAddOutlined style={{ fontSize: '20px' }} />
                                                                                    <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Añadir_presupuesto")}</span>
                                                                                </button>
                                                                                :
                                                                                null
                                                                            }


                                                                            <button onClick={(e) => {
                                                                                abrirDesglose(item)
                                                                            }} className="activebtnMenu2" style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: item.STOCK_ALMACEN > 0 ? '#25c281' : item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL > 0 ? '#ff8000' : 'red', backgroundColor: 'transparent', display: verficarSiEsCatalogo(usuario) ? 'none' : '' }}>
                                                                                {item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 ?
                                                                                    <DislikeOutlined style={{ fontSize: '20px', verticalAlign: 'sub' }} />
                                                                                    : <LikeOutlined style={{ fontSize: '20px', verticalAlign: 'baseline' }} />}

                                                                                <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>
                                                                                    {item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 ? " " + traducirPagina("No_disponible") + " " : obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE ? traducirPagina("disponible") : obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD ? traducirPagina("disponible") : " " + traducirPagina("Stock") + " " + item.STOCK_ALMACEN + "/" + item.STOCK_TOTAL}
                                                                                </span>
                                                                            </button>


                                                                            <div>
                                                                                {
                                                                                    /*
                                                                                    verificarSiEsMostrador() && visualizarStocksRemotos() ?
                                                                                        <div>
                                                                                            <button className="activebtnMenu2" style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'transparent', marginBottom: '5px' }}
                                                                                                onClick={(e) => {

                                                                                                    setRegistroStockSocio({ REFERENCIA_TECDOC: item.REFERENCIA_COMERCIAL, MARCA_TECDOC: "", TIPO_ARTICULO:2 })
                                                                                                    setAbrirFichaStockSocio(true)


                                                                                                }}>
                                                                                                <DeploymentUnitOutlined style={{ fontSize: '24px', marginRight: '5px' }} />

                                                                                                <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Stock_grupo")}</span>
                                                                                            </button>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                        */
                                                                                }
                                                                            </div>



                                                                            <div>
                                                                                <div style={{ height: '25px', width: '100%', display: verficarSiEsPVP(usuario) ? '' : 'none' }} >{traducirPagina("PVP").toUpperCase() + ": " + convertirMoneda(item.PRECIO, " €")}</div>
                                                                                <div style={{ height: '25px' }} >{traducirPagina("Precio") + ": " + convertirMoneda(item.PRECIO, " €")} </div>

                                                                                <div style={{ display: visualizacionDescuento ? '' : 'none' }}>
                                                                                    <span style={{ height: '25px' }} >{traducirPagina("Dto") + ": " + item.DESCUENTO + " %"} </span>
                                                                                    <br />
                                                                                    <span style={{ height: '25px' }} >{traducirPagina("Neto") + ": " + convertirMoneda(item.NETO, " €")}</span>
                                                                                    <br />
                                                                                </div>
                                                                            </div>

                                                                        </MDBCol>
                                                                    </div>
                                                                    <hr></hr>
                                                                </div>
                                                                :
                                                                null
                                                        )}
                                                </div>
                                                :
                                                null :
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#000' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                    }
                                </div>
                            </Col>
                        </div>

                    </Content>
                </div>
            </Drawer>

            {fichaCesta ?
                <FichaAñadirCesta
                    title={traducirPagina("Añadir_referencia_a_la_cesta").toUpperCase()}
                    ABRIR_FICHA={fichaCesta}
                    CERRAR_FICHA={setFichaCesta}
                    DATOS_REFERENCIA={referenciaSeleccionada}
                    ES_CESTA_COMPRA={false}
                >
                </FichaAñadirCesta>
                :
                null
            }

            {
                abrirFichaStockSocio ?
                    <FichaStockSocio
                        title={`${traducirPagina("Stock_grupo").toUpperCase()}`}
                        ABRIR_FICHA={abrirFichaStockSocio}
                        CERRAR_FICHA={setAbrirFichaStockSocio}
                        DATOS_SELECCIONADO={{}}
                    >
                    </FichaStockSocio>
                    :
                    null
            }

        </div>
    )
}

FichaBaterias.propTypes = {
    TIPO: PropTypes.string,
    CERRAR: PropTypes.object,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    VISCOSIDAD: PropTypes.string,
    ID_VEHICULO: PropTypes.string,
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        filtrosReferencia: state.filtrosReferencia,
        usuario: state.usuario,
        codmotorizacion: state.codmotorizacion,
        clienteSeleccionado: state.clienteSeleccionado


    };
}

export default connect(mapStateToProps)(FichaBaterias);