import React from "react";
import {
    MDBNavItem
} from 'mdbreact';
import {
 filtroLadoMontaje
} from "../../librerias/funciones";
import { useState } from "react";

const CPLadoMontaje = ({ LADO_MONTAJE = false, REGISTROS_REFERENCIA = [], EVENTO_SELECCIONADO }) => {
    const estadoInicial = {
        DELANTERO: false,
        DERECHO: false,
        IZQUIERDO: false,
        TRASERO: false
    }

    const posicion = [
        { key: 4, id: 'H', descripcion: 'ATRAS' },
        { key: 1, id: 'V', descripcion: 'ADELANTE' },
        { key: 2, id: 'D', descripcion: 'DERECHA' },
        { key: 3, id: 'L', descripcion: 'IZQUIERDA' },

        { key: 4, id: 'HD', descripcion: 'ATRAS DERECHA' },
        { key: 4, id: 'HL', descripcion: 'ATRAS IZQUIERDA' },
        { key: 1, id: 'VD', descripcion: 'DELANTE DERECHA' },
        { key: 1, id: 'VL', descripcion: 'DELANTE IZQUIERDA' },
    ]
    const [estadoLado, setEstadoLado] = useState(estadoInicial)


    const verLadosSeleccionadosV2 = (estadoLado) => {
        let lados = []

        const { DELANTERO, DERECHO, IZQUIERDO, TRASERO } = estadoLado
        const registros = [DELANTERO, DERECHO, IZQUIERDO, TRASERO]

        registros.map((item, i) => {
            if (item) {
                let filtrarLado = posicion.filter((item) => item.key === (i + 1))
                lados = [...lados, ...filtrarLado]
            }
        })

        if (DELANTERO && DERECHO) {
            let filtrarLado = lados.filter((item) => item.id !== "VL" && item.id !== "D")
            lados = [...filtrarLado]
        }

        if (DELANTERO && IZQUIERDO) {
            let filtrarLado = lados.filter((item) => item.id !== "VD" && item.id !== "L")
            lados = [...filtrarLado]
        }

        if (DERECHO && TRASERO) {
            let filtrarLado = lados.filter((item) => item.id !== "HL" && item.id !== "D")
            lados = [...filtrarLado]
        }

        if (IZQUIERDO && TRASERO) {
            let filtrarLado = lados.filter((item) => item.id !== "HD" && item.id !== "L")
            lados = [...filtrarLado]
        }

        lados = lados.map((item) => item.id)

        return lados;
    }




    const actualizarEstadoLado = (actual, estadoLado) => {
        switch (actual) {
            case 1:
                estadoLado.DELANTERO = !estadoLado.DELANTERO
                break;
            case 2:
                estadoLado.DERECHO = !estadoLado.DERECHO
                break;
            case 3:
                estadoLado.IZQUIERDO = !estadoLado.IZQUIERDO
                break;
            case 4:
                estadoLado.TRASERO = !estadoLado.TRASERO
                break;
        }

        setEstadoLado({ ...estadoLado })
        return estadoLado;
    }


    const verificarEstado = (actual) => {
        let lados = []
        let nuevoEstado = {}
        nuevoEstado = actualizarEstadoLado(actual, estadoLado)

        const { DELANTERO, DERECHO, IZQUIERDO, TRASERO } = estadoLado
        const registros = [DELANTERO, DERECHO, IZQUIERDO, TRASERO]
        const seleccionados = registros.filter(item => item === true)
        let nuevoRegistros = estadoLado

        if (seleccionados.length === 3) {
            nuevoRegistros = { ...estadoLado, ...estadoInicial }
            setEstadoLado(nuevoRegistros)
            nuevoEstado = actualizarEstadoLado(actual, nuevoRegistros)
        }

        lados = verLadosSeleccionadosV2(nuevoEstado)        
        EVENTO_SELECCIONADO(lados)

        //console.log("LADOS",lados)
    }


    const ladosMontaje = (boton) => {
        let lados = posicion.filter((item) => item.key === boton).map((registro) => registro.id)
        return lados;
    }



    return (
        LADO_MONTAJE &&
        (
            <>
                <MDBNavItem style={{ marginTop: '8px', marginLeft: '10px' }}>
                    <img
                        title={filtroLadoMontaje("V", REGISTROS_REFERENCIA, ladosMontaje(1))}
                        src="./imagenes/delantero.svg"
                        className={`${estadoLado.DELANTERO ? "lado_activo" : "lado_desactivo"}`}
                        style={{ WebkitMask: 'url(./imagenes/delantero.svg) center center no-repeat' }}
                        onClick={() => {
                            verificarEstado(1)
                        }}></img>
                </MDBNavItem>
                
                <MDBNavItem style={{ marginTop: '8px', marginLeft: '10px' }}>
                    <img
                        title={filtroLadoMontaje("D", REGISTROS_REFERENCIA, ladosMontaje(2))}
                        src="./imagenes/derecha.svg"
                        className={`${estadoLado.DERECHO ? "lado_activo" : "lado_desactivo"}`}
                        style={{ WebkitMask: 'url(./imagenes/derecha.svg) center center no-repeat' }}
                        onClick={() => {
                            verificarEstado(2)
                        }}></img>
                </MDBNavItem>

                <MDBNavItem style={{ marginTop: '8px', marginLeft: '10px' }}>
                    <img
                        title={filtroLadoMontaje("L", REGISTROS_REFERENCIA, ladosMontaje(3))}
                        src="./imagenes/izquierda.svg"
                        className={`${estadoLado.IZQUIERDO ? "lado_activo" : "lado_desactivo"}`}
                        style={{ WebkitMask: 'url(./imagenes/izquierda.svg) center center no-repeat' }}
                        onClick={() => {
                            verificarEstado(3)
                        }}></img>

                </MDBNavItem>

                <MDBNavItem style={{ marginTop: '8px', marginLeft: '10px' }}>
                    <img
                        title={filtroLadoMontaje("H", REGISTROS_REFERENCIA, ladosMontaje(4))}
                        src="./imagenes/trasero.svg"
                        className={`${estadoLado.TRASERO ? "lado_activo" : "lado_desactivo"}`}
                        style={{ WebkitMask: 'url(./imagenes/trasero.svg) center center no-repeat' }}
                        onClick={() => {
                            verificarEstado(4)
                        }}></img>
                </MDBNavItem>
            </>
        )
    )
}

export default CPLadoMontaje;