import React from 'react';
import { changeStateImageViewer } from "../action/actionCreators";

import { connect } from 'react-redux';
import store from '../store'

import {
  SearchOutlined,
  CloseOutlined,
} from '@ant-design/icons';

const CptImageView = (props) => {
  const [estadoDocumento, setEstadoDocumento] = React.useState(false);

  let altura = (props.config.height - 58).toString() + "px";


  const elDocumentoEstaDisponible = async (enlace) => {
    setEstadoDocumento(true)
/*
    try {
      
      const peticion = await fetch(enlace, { method: 'HEAD' });
      if(peticion.url === enlace){
        setEstadoDocumento(true)
      }else{
        setEstadoDocumento(null)
      }
    }
    catch (err) {
      setEstadoDocumento(null)
    }
    */
    
  }

  //rgba(0, 0, 0, 0.9)
  return (
    <div style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }} className="react-simple-image-viewer__modal" id="container_viewer" onClick={() => { document.getElementById("container_viewer").style.display = "none"; store.dispatch(changeStateImageViewer(false, "")) }}>
      <span className="react-simple-image-viewer__close" onClick={() => { document.getElementById("container_viewer").style.display = "none"; store.dispatch(changeStateImageViewer(false, "")) }}> <CloseOutlined /> </span>
      <span className="react-simple-image-viewer__span">{props.titulo}</span>

      <div className="react-simple-image-viewer__modal-content">
        {props.name.includes("DOC") ?
          <div>
            
             <iframe onLoad={(e) => elDocumentoEstaDisponible(props.name)} src={props.name} style={{ width: '900px', height: altura, border: 0, marginTop: '62px', display:estadoDocumento ? '':'none' }} ></iframe>


            {estadoDocumento === null ?
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#fff' }}> <h3 style={{color:'#fff'}}>{"No se encontró el documento."}</h3> </div>

              :
              estadoDocumento ?
              null
                :
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#fff' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>

            }

          </div>
          :
          
          <div><img src={props.name} alt=""></img></div>
        }
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    configApp: state.configApp
  };
}

export default connect(mapStateToProps)(CptImageView); 