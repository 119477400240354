import React from "react";
import {
    traducirPagina,
    generateUUID,
    convertirMoneda,
    crearLineasParaElPresupuesto,
    MSGOK

} from "../../librerias/funciones"
import { Drawer, Modal, Radio, Button } from 'antd';
import store from '../../store'

import { CloseCircleOutlined, DownOutlined, PlusCircleOutlined, RightOutlined, EyeOutlined, SearchOutlined, FileAddOutlined } from "@ant-design/icons";
import { MDBBtn } from 'mdbreact';
import { ObtenerPiezasTrabajosEnAPI, ObtenerTiemposReparacionEnAPI, obtenerTiemposReparacionDetalleEnAPI, ObtenerPiezasCestaEnAPI } from "../../action/actionCreatorsRequests";
import { DrawerFichaGMVehiculoHaynes } from "../../action/actionCreators";

import FichaGMYVehiculoHaynesPro from "../FichaGMYVehiculoHaynesPro";

export const RMITiempos = (props) => {
    const [verDetalles, setVerDetalles] = React.useState(false);
    const [grupoMontaje, setGrupoMontaje] = React.useState(false);
    const [visible, setVisible] = React.useState(false);

    const [lineasCesta, setLineasCesta] = React.useState({
        TRABAJOS: [],
        PIEZAS: [],

        TOTAL_PIEZAS: 0,
        TOTAL_TIEMPO: 0,
        TOTAL_TRABAJO: 0
    });


    const [initComponent, setInitComponent] = React.useState({
        REGISTROS: "",
        ID_VEHICULO_TECRMI: "",
        TIEMPOS: [],
        ID_GRUPO_SELECCIONADO: 0,
        HISTORIAL: [],


        ARTICULOS_MODAL: {},
        ABRIR_MODAL: false,
        TITULO_MODAL: "",
    })





    const ObtenerTiemposReparacion = async () => {
        const ID_VEHICULO_TECDOC = props.ID_VEHICULO != 0 ? props.ID_VEHICULO : ""
        const registros = await ObtenerTiemposReparacionEnAPI(ID_VEHICULO_TECDOC);
        let RESULTADO = registros === undefined ? { OK: false, TIEMPOS_REPARACION: null } : registros

        const { OK, TIEMPOS_REPARACION, ID_VEHICULO_TECRMI } = registros === undefined ? { OK: false, TIEMPOS_REPARACION: null, ID_VEHICULO_TECRMI: null } : registros
        if (OK && TIEMPOS_REPARACION.length > 0) {

            TIEMPOS_REPARACION.map((registro) => registro.SUBGRUPOS.map((item, i) => {
                let id_generado = generateUUID()
                item.ID = id_generado;
                item.TIEMPOS.map(async (registro) => {
                    registro.SELECCIONADO = false; registro.TRABAJOS = [];
                })
            }))

            let cambios = TIEMPOS_REPARACION.length > 0 ? TIEMPOS_REPARACION[0].SUBGRUPOS : []
            initComponent.ID_GRUPO_SELECCIONADO = cambios.length > 0 ? cambios[0].ID : 0
            initComponent.TIEMPOS = cambios.length > 0 ? cambios[0].TIEMPOS : []


            initComponent.REGISTROS = TIEMPOS_REPARACION
            initComponent.ID_VEHICULO_TECRMI = ID_VEHICULO_TECRMI
        } else {
            initComponent.REGISTROS = null
            initComponent.ID_VEHICULO_TECRMI = null

        }

        setInitComponent({ ...initComponent });
        return RESULTADO;
    }


    const obtenerTiemposReparacionDetalle = async (TIPO_TRABAJO_ID, ID_TIPO_TRABAJO_TIEMPO_REPARACION, ID_VEHICULO_TECRMI, ID_GRUPO_MONTAJE) => {
        const registros = await obtenerTiemposReparacionDetalleEnAPI(0, TIPO_TRABAJO_ID, ID_TIPO_TRABAJO_TIEMPO_REPARACION, ID_VEHICULO_TECRMI, ID_GRUPO_MONTAJE);
        let RESULTADO = registros === undefined ? { OK: false, TRABAJOS: null } : registros
        return RESULTADO;
    }

    const obtenerTrabajos = async (registro) => {
        if (registro.TRABAJOS.length === 0) {
            let item = await obtenerTiemposReparacionDetalle(registro.ID_TIPO_TRABAJO, 0,
                initComponent.ID_VEHICULO_TECRMI, registro.ID_GRUPO_MONTAJE)

            if (item !== undefined && item !== null && item.TRABAJOS !== null) { item.TRABAJOS.map((registro) => { registro.SELECCIONADO = false; registro.COMPRAR = false; registro.PIEZA_TRABAJO = null; return registro }) }
            registro.TRABAJOS = item.TRABAJOS
            setInitComponent({ ...initComponent })
        }
    }

    const ObtenerPiezasTrabajos = async (ID_VEHICULO_TECDOC, ID_TRABAJOS_SELECCIONADOS) => {
        const registros = await ObtenerPiezasTrabajosEnAPI(ID_VEHICULO_TECDOC, ID_TRABAJOS_SELECCIONADOS);
        let RESULTADO = registros === undefined ? { OK: false, PIEZA_TRABAJO: null } : registros
        return RESULTADO;
    }

    const ObtenerPiezasCesta = async (TRABAJOS_PIEZAS) => {

        const ID_VEHICULO_TECDOC = props.ID_VEHICULO != 0 ? props.ID_VEHICULO : ""
        const registros = await ObtenerPiezasCestaEnAPI(ID_VEHICULO_TECDOC, TRABAJOS_PIEZAS);
        let RESULTADO = registros === undefined ? { OK: false, TRABAJOS: null, PIEZAS: null, TOTALES: null } : registros
        const { TOTAL_PIEZAS, TOTAL_TIEMPO, TOTAL_TRABAJO } = RESULTADO.TOTALES === null ? { TOTAL_PIEZAS: 0, TOTAL_TIEMPO: 0, TOTAL_TRABAJO: 0 } : RESULTADO.TOTALES


        lineasCesta.TRABAJOS = RESULTADO.TRABAJOS !== null ? RESULTADO.TRABAJOS : []
        lineasCesta.PIEZAS = RESULTADO.PIEZAS !== null ? RESULTADO.PIEZAS.map((item) => {
            item.PRECIO = 0;
            item.DESCRIPCION = "";
            item.REFERENCIA = "";
            item.MARCA = "";
            item.PRECIO = "";
            item.CANTIDAD_PIEZA = 1
            item.REGISTRO_SELECCIONADO = {};

            return item
        }) : []
        lineasCesta.TOTAL_PIEZAS = TOTAL_PIEZAS
        lineasCesta.TOTAL_TIEMPO = TOTAL_TIEMPO
        lineasCesta.TOTAL_TRABAJO = TOTAL_TRABAJO

        setLineasCesta({ ...lineasCesta })

    }



    function addTrabajo({ ID_TRABAJO, PIEZA_TRABAJO }) {
        let PIEZAS = []
        if (PIEZA_TRABAJO !== null) {
            PIEZAS = PIEZA_TRABAJO.filter((reg) => reg.CONJUNTO_PIEZAS_QUALCOL.length === 1).map((item) => {
                return {
                    ID_GRUPO_MONTAJE_PIEZA: item.ID_GRUPO_MONTAJE,
                    ID_QUALCOL_CONJUNTO_PIEZA: item.CONJUNTO_PIEZAS_QUALCOL[0].ID_QUALCOL
                }
            })
        }
        initComponent.HISTORIAL = [...initComponent.HISTORIAL, { ID_TRABAJO: ID_TRABAJO, PIEZAS: PIEZAS }]
        ObtenerPiezasCesta(initComponent.HISTORIAL)
    }

    function deleteTrabajo(ID_TRABAJO) {
        initComponent.HISTORIAL = initComponent.HISTORIAL.filter((item) => item.ID_TRABAJO !== ID_TRABAJO);
        if (initComponent.HISTORIAL.length === 0) {
            setVerDetalles(false)
        }
        ObtenerPiezasCesta(initComponent.HISTORIAL)
        setInitComponent({ ...initComponent })
    }

    function deleteTrabajoCesta(ID_TRABAJO) {
        let trabajos = []
        initComponent.TIEMPOS.forEach((registro) => trabajos = [...trabajos, ...registro.TRABAJOS])
        let item = trabajos.find((registro) => registro.ID_TRABAJO === ID_TRABAJO)

        if (item !== undefined) {

            item.COMPRAR = !item.COMPRAR
            item.SELECCIONADO = true
            item.PIEZA_TRABAJO = null
            deleteTrabajo(item.ID_TRABAJO)
            setInitComponent({ ...initComponent })
        }
    }


    function addPieza({ ID_TRABAJO, ID_GRUPO_MONTAJE }, ID_QUALCOL) {
        let registos = initComponent.HISTORIAL.find((item) => item.ID_TRABAJO === ID_TRABAJO)
        if (registos !== undefined) {
            registos.PIEZAS = [...registos.PIEZAS, {
                ID_GRUPO_MONTAJE_PIEZA: ID_GRUPO_MONTAJE,
                ID_QUALCOL_CONJUNTO_PIEZA: ID_QUALCOL
            }
            ]
            setInitComponent({ ...initComponent })
            ObtenerPiezasCesta(initComponent.HISTORIAL)
        }
    }

    function actualizarRegistroPiezaTECDOC(registro) {
        const { NOMBRE_GRUPO_MONTAJE, REFERENCIA, NOMRE_MARCA, PRECIO } = registro
        let PIEZA = lineasCesta.PIEZAS.find((item) => item.ID_GRUPO_MONTAJE === grupoMontaje)
        if (PIEZA !== undefined) {
            PIEZA.DESCRIPCION = NOMBRE_GRUPO_MONTAJE
            PIEZA.REFERENCIA = REFERENCIA
            PIEZA.MARCA = NOMRE_MARCA
            PIEZA.PRECIO = PRECIO
            PIEZA.REGISTRO_SELECCIONADO = registro
            setLineasCesta({ ...lineasCesta })
        }
    }


    function deletePieza({ ID_TRABAJO, ID_GRUPO_MONTAJE }, ID_QUALCOL, PERMITIR_ELIMINAR) {
        let registos = initComponent.HISTORIAL.find((item) => item.ID_TRABAJO === ID_TRABAJO)
        if (registos !== undefined) {
            registos.PIEZAS = registos.PIEZAS.filter((item) => (item.ID_GRUPO_MONTAJE_PIEZA !== ID_GRUPO_MONTAJE || item.ID_QUALCOL_CONJUNTO_PIEZA !== ID_QUALCOL))

            if (PERMITIR_ELIMINAR) {
                ObtenerPiezasCesta(initComponent.HISTORIAL)
            }
        }
        setInitComponent({ ...initComponent })
    }


    const modificarEstadoPiezas = async (item) => {
        item.COMPRAR = !item.COMPRAR
        item.SELECCIONADO = true
        if (item.COMPRAR) {
            item.PIEZA_TRABAJO = []

            ObtenerPiezasTrabajos(props.ID_VEHICULO, [item.ID_TRABAJO]).then((piezas) => {
                let RESULTADO = piezas === undefined ? { OK: false, PIEZA_TRABAJO: null } : piezas
                const { PIEZA_TRABAJO } = RESULTADO

                item.PIEZA_TRABAJO = PIEZA_TRABAJO === null || PIEZA_TRABAJO.length === 0 ? null : PIEZA_TRABAJO;

                if (piezas.PIEZA_TRABAJO !== null && piezas.PIEZA_TRABAJO.length > 0) {
                    piezas.PIEZA_TRABAJO.map((reg) => { reg.CONJUNTO_PIEZAS_QUALCOL.map((item) => item.COMPRAR = reg.CONJUNTO_PIEZAS_QUALCOL.length === 1) })
                    let abrir_modal_articulo = piezas.PIEZA_TRABAJO.filter((item) => item.CONJUNTO_PIEZAS_QUALCOL.length > 1)
                    if (abrir_modal_articulo.length > 0) {
                        initComponent.ABRIR_MODAL = true;
                        initComponent.ARTICULOS_MODAL = item
                    }
                }

                addTrabajo(item, initComponent.HISTORIAL);
                setInitComponent({ ...initComponent })
            })
        } else {
            item.PIEZA_TRABAJO = null
            deleteTrabajo(item.ID_TRABAJO)

        }
        setInitComponent({ ...initComponent })
    }


    const comprobarSiHayPiezasSinReferencia = () => {
        let articulos = lineasCesta.PIEZAS;
        let continuar = false;
        let verficarSiHayPrecio = articulos.filter(articulo => Object.keys(articulo.REGISTRO_SELECCIONADO).length === 0)
        if (verficarSiHayPrecio.length === 0) {
            continuar = true;
        }
        return continuar
    }



    const añadirApresupuesto = () => {

        lineasCesta.TRABAJOS.forEach((tarea) => {
            let Obra = {
                Tiempo: tarea.TIEMPO,
                Descripcion: tarea.NOMBRE_TRABAJO,
                TAREAS_REPARACION: tarea.PASOS_TRABAJO.map((tar) => { return { Descripcion: tar.NOMBRE_TRABAJO } })
            };
            crearLineasParaElPresupuesto(Obra, "T", true);
        })


        lineasCesta.PIEZAS.forEach((articulo) => {

            let articuloFinal = {
                PRECIO: 0,
                REFERENCIA_PROVEEDOR: "",
                REFERENCIA: "",
                CODIGO_MARCA_ERP: "",
                NOMRE_MARCA: "",
                CODIGO_MARCA: 0,
                NOMBRE_GRUPO_MONTAJE: articulo.NOMBRE_PIEZA,
                DESCUENTO: 0
            };

            if (Object.keys(articulo.REGISTRO_SELECCIONADO).length > 0) {
                articuloFinal = Object.assign(
                    {
                        PRECIO: articulo.REGISTRO_SELECCIONADO.PRECIO,
                        REFERENCIA_PROVEEDOR: articulo.REGISTRO_SELECCIONADO.REFERENCIA_PROVEEDOR,
                        REFERENCIA: articulo.REGISTRO_SELECCIONADO.REFERENCIA,
                        CODIGO_MARCA_ERP: articulo.REGISTRO_SELECCIONADO.CODIGO_MARCA_ERP,
                        NOMRE_MARCA: articulo.REGISTRO_SELECCIONADO.NOMRE_MARCA,
                        CODIGO_MARCA: articulo.REGISTRO_SELECCIONADO.CODIGO_MARCA,
                        NOMBRE_GRUPO_MONTAJE: articulo.REGISTRO_SELECCIONADO.NOMBRE_GRUPO_MONTAJE,
                        DESCUENTO: articulo.REGISTRO_SELECCIONADO.DESCUENTO,
                    }
                )
            }
            crearLineasParaElPresupuesto(articuloFinal, "A", true);
        })

        MSGOK(traducirPagina("Referencia_agregada"))
    }


    const seleccionarPiezasAdicionales = ({ SELECCIONADO, PIEZA_TRABAJO, POSICIONES_TRABAJO_EXCLUSIVAS }) => {
        return (
            SELECCIONADO ?
                <>
                    {
                        PIEZA_TRABAJO === null ?
                            null :
                            PIEZA_TRABAJO.length === 0 ?
                                <div className="center_item_alert" style={{ height: '100%' }} > <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                                :

                                <div className="container_tiempo_pieza">
                                    {
                                        PIEZA_TRABAJO.map((registro) =>
                                            registro.CONJUNTO_PIEZAS_QUALCOL.map((item) =>
                                                <div className="sub_item_tiempo_desglose" onClick={() => {
                                                    item.COMPRAR = !item.COMPRAR

                                                    if (item.COMPRAR) {
                                                        addPieza(registro, item.ID_QUALCOL)
                                                    } else {
                                                        deletePieza(registro, item.ID_QUALCOL, true)
                                                    }
                                                    setInitComponent({ ...initComponent })
                                                }}>
                                                    <div>
                                                        {`${registro.TEXTO_GRUPO_MONTAJE} ${item.TEXTO_QUALCOL}`}
                                                    </div>

                                                    <div>{item.COMPRAR ? <CloseCircleOutlined style={{ fontSize: '22px', color: 'red' }} /> : <PlusCircleOutlined style={{ fontSize: '22px', color: 'green' }} />}</div>
                                                </div>
                                            )
                                        )
                                    }
                                </div>
                    }


                    {POSICIONES_TRABAJO_EXCLUSIVAS.length > 0 &&
                        <div className="sub_item_tiempo_desglose">
                            <div>{`${traducirPagina("txt_contiene")}:`}</div>
                        </div>}

                    {POSICIONES_TRABAJO_EXCLUSIVAS.map((reg) =>
                        <div className="sub_item_tiempo_desglose">
                            <div>
                                {`${reg.TEXTO_GRUPO_MONTAJE} ${reg.TEXTO_TIPO_TRABAJO}`}
                            </div>

                            <div>{`${convertirMoneda(reg.TIEMPO_TRABAJO, "")} ${traducirPagina("Horas").toLowerCase()}`}</div>
                        </div>
                    )}
                </>
                :
                null

        );
    }



    React.useEffect(() => {
        ObtenerTiemposReparacion()
    }, [])


    const { REGISTROS, ID_GRUPO_SELECCIONADO, TIEMPOS, ARTICULOS_MODAL } = initComponent
    const { TRABAJOS, PIEZAS, TOTAL_PIEZAS, TOTAL_TIEMPO, TOTAL_TRABAJO } = lineasCesta
    const { PIEZA_TRABAJO } = ARTICULOS_MODAL


    return <div>
        <Drawer
            width={1250}
            onClose={() => {
                if (props.TIPO_FICHA === "MASTER") {
                    props.CERRAR.setState({ ABRIR_FICHA_RMI_TIEMPOS: false })
                } else {
                    props.CERRAR_FICHA(false)
                }
            }}
            visible={props.ABRIR_FICHA}
            placement={"right"}
            closable={false}
            bodyStyle={{ padding: '0px' }}
            style={{ color: '#fff' }}>
            <>

                <div className="flex-container-Grow-Tercero" >

                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                            onClick={(e) => {
                                if (props.TIPO_FICHA === "MASTER") {
                                    props.CERRAR.setState({ ABRIR_FICHA_RMI_TIEMPOS: false })
                                } else {
                                    props.CERRAR_FICHA(false)
                                }
                            }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>

                    <div style={{ flexGrow: '1', textAlign: 'end', color: '#fff', verticalAlign: 'middle', paddingRight: '10px', fontSize: '16px' }}>
                        <button className={`col-md-12 ${!TOTAL_TRABAJO > 0 ? 'colorBloqueoBotoneraFacturacion' : 'btnColor'}`} disabled={TOTAL_TRABAJO > 0 ? false : true}
                            style={{
                                height: '40px', width: '300px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top',
                                color: TOTAL_TRABAJO ? '#fff' : '#999',
                                marginBottom: '5px', marginRight: '5px'
                            }} onClick={(e) => {
                                let verificar = comprobarSiHayPiezasSinReferencia();
                                if (verificar) {
                                    añadirApresupuesto();
                                } else {
                                    setVisible(true);
                                }
                            }}>
                            <FileAddOutlined style={{ fontSize: '20px' }} />
                            <span style={{ verticalAlign: 'middle', fontSize: '16px', marginLeft: '10px' }}>{traducirPagina("Añadir_presupuesto").toUpperCase()}</span>
                        </button>
                    </div>
                </div>




                {/**  Header donde estan los totales y el detalle de la cesta  */}
                <div className="headerTiempoRMI">
                    <div className="item1_tiempoRMI">
                        {traducirPagina("Total_tiempos_reparacion")}
                    </div>

                    <div className="item2_tiempoRMI">
                        <div>
                            <span>{traducirPagina("Trabajos")} </span>
                            <br />
                            <span >{convertirMoneda(TOTAL_TRABAJO)} </span>
                        </div>
                        <div>
                            <span>{traducirPagina("Tiempo_total_H")} </span>
                            <br />
                            <span >{convertirMoneda(TOTAL_TIEMPO)} </span>
                        </div>
                        <div>
                            <span>{traducirPagina("Piezas_necesarias")} </span>
                            <br />
                            <span >{convertirMoneda(TOTAL_PIEZAS)} </span>
                        </div>

                        <button onClick={(e) => { setVerDetalles(!verDetalles) }} className={"btn_detalles_lineas_active"} style={{ visibility: lineasCesta !== null && TRABAJOS.length > 0 || PIEZAS.length > 0 ? 'visible' : 'hidden' }}>
                            <EyeOutlined /> {traducirPagina("Ver_detalles")}
                        </button>
                    </div>
                </div>



                <div className={verDetalles ? "repair-times-detail active" : "repair-times-detail"} style={{ marginTop: '-12px', marginLeft: '30px' }}>
                    {verDetalles ?
                        <div>
                            {
                                TRABAJOS.map((item, i) =>
                                    <>
                                        {
                                            i === 0 ?
                                                <h4 style={{ margin: '0px 20px 10px 20px' }}>{traducirPagina("Trabajos")}</h4>
                                                :
                                                null
                                        }

                                        <div className="container_lineas">
                                            <div className="item1_cesta">
                                                {item.NOMBRE_TRABAJO}
                                            </div>

                                            <div className="item2_cesta">
                                                <div>{`${convertirMoneda(item.TIEMPO, "")} ${traducirPagina("Horas").toLowerCase()}`}</div>
                                                <div onClick={() => { deleteTrabajoCesta(item.ID_TRABAJO) /*deleteTrabajo(item.ID_TRABAJO)*/ }}>
                                                    <CloseCircleOutlined style={{ fontSize: '22px', color: 'red', cursor: 'pointer' }} />
                                                </div>

                                            </div>
                                        </div>

                                    </>
                                )
                            }

                            {
                                PIEZAS.map((articulo, i) =>
                                    <>
                                        {
                                            i === 0 ?
                                                <h4 style={{ margin: '0px 20px 10px 20px' }}>{traducirPagina("Piezas_necesarias")}</h4>
                                                :
                                                null
                                        }

                                        <div className="container_lineas">
                                            <div className="item1_cesta">
                                                {articulo.DESCRIPCION === "" ? `${articulo.NOMBRE_PIEZA}` : `${articulo.DESCRIPCION} (${articulo.REFERENCIA} - ${articulo.MARCA})`}
                                            </div>

                                            <div className="item2_cesta">
                                                <div style={{ width: '80px', textAlign: 'right' }}>{convertirMoneda(articulo.PRECIO, "€")}</div>

                                                <div style={{ width: '30px' }}>{convertirMoneda(articulo.CANTIDAD_PIEZA)}</div>
                                                <div className="buscar_referencia_tecrmi" onClick={(e) => { setGrupoMontaje(articulo.ID_GRUPO_MONTAJE); store.dispatch(DrawerFichaGMVehiculoHaynes(true)); }}>
                                                    <SearchOutlined />
                                                    <div>
                                                        {traducirPagina("Buscar_referencia")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                        </div>
                        :
                        null}
                </div>

                {/**  Fin de Header */}



                <div className="containerRMITiempos">

                    {/** Listado Izquierdo */}
                    <div className='item_1_TiemposRMI'>
                        {
                            REGISTROS === null ?
                                <div className="center_item_alert" style={{ height: '100%' }}> <h3>{traducirPagina("No_resultados")}</h3> </div>
                                :
                                REGISTROS === "" ?
                                    <div className="center_item_alert" style={{ height: '100%' }} > <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                                    :
                                    <div className="container_listado">
                                        <ul>
                                            {
                                                REGISTROS.map((registro, i) =>
                                                    <>
                                                        <li key={i} className={`item_titulo_listado`} >{registro.NOMBRE}</li>
                                                        {
                                                            registro.SUBGRUPOS.map((item, i) =>
                                                                <li key={i} className={`item_listado ${ID_GRUPO_SELECCIONADO === item.ID ? "item_listado_seleccionado" : ""}`}
                                                                    onClick={() => {
                                                                        // obtenerTrabajos(item.TIEMPOS)
                                                                        initComponent.TIEMPOS = item.TIEMPOS
                                                                        initComponent.ID_GRUPO_SELECCIONADO = item.ID
                                                                        setInitComponent({ ...initComponent })
                                                                    }}>{item.NOMBRE_SUBGRUPO}</li>
                                                            )}
                                                    </>
                                                )
                                            }
                                        </ul>
                                    </div>
                        }
                    </div>
                    {/** Fin del Listado Izquierdo */}



                    {/** Piezas y trabajos */}

                    <div className='item_2_TiemposRMI'>
                        {
                            TIEMPOS !== null &&
                            TIEMPOS.map((registro) =>
                                <>
                                    <div className="item_tiempo" key={registro.ID_TIPO_TRABAJO}>

                                        <div className="item_tiempo_item" onClick={() => {
                                            registro.SELECCIONADO = !registro.SELECCIONADO
                                            setInitComponent({ ...initComponent })
                                            obtenerTrabajos(registro)
                                        }}>
                                            {registro.SELECCIONADO ? <DownOutlined></DownOutlined> : <RightOutlined></RightOutlined>}
                                            {`${registro.TEXTO_GRUPO_MONTAJE}, ${registro.TEXTO_TIPO_TRABAJO}`}
                                        </div>


                                        {
                                            registro.SELECCIONADO ?
                                                registro.TRABAJOS === null ?
                                                    <div className="center_item_alert" style={{ height: '100%' }}> <h3>{traducirPagina("No_resultados")}</h3> </div>
                                                    :
                                                    registro.TRABAJOS.length === 0 ?
                                                        <div className="center_item_alert" style={{ height: '100%' }} > <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                                                        :
                                                        registro.TRABAJOS.map((item) =>
                                                            <>
                                                                <div style={{ padding: '0px 20px', paddingBottom: '10px' }}>
                                                                    <div className="item_tiempo" style={{ background: '#fff', padding: '10px' }}>
                                                                        <div className="sub_item_tiempo">
                                                                            <div onClick={() => {
                                                                               
                                                                                if (item.POSICIONES_TRABAJO_EXCLUSIVAS.length > 0) {
                                                                                    item.SELECCIONADO = !item.SELECCIONADO
                                                                                    setInitComponent({ ...initComponent })
                                                                                }
                                                                            }}>

                                                                                {item.POSICIONES_TRABAJO_EXCLUSIVAS.length > 0 ? item.SELECCIONADO ? <DownOutlined /> : <RightOutlined /> : ""}
                                                                                {`${item.TEXTO_GRUPO_MONTAJE} ${item.TEXTO_TIPO_TRABAJO} ${item.ID_QUALCOL !== 0 ? `, ${item.TEXTO_QUALCOL}` : ""}`}
                                                                            </div>

                                                                            <div onClick={() => { modificarEstadoPiezas(item) }}>
                                                                                {`${convertirMoneda(item.TIEMPO_TRABAJO, "")} ${traducirPagina("Horas").toLowerCase()}`}
                                                                                {item.COMPRAR ? <CloseCircleOutlined style={{ fontSize: '22px', color: 'red' }} /> : <PlusCircleOutlined style={{ fontSize: '22px', color: 'green' }} />}
                                                                            </div>
                                                                        </div>


                                                                        {seleccionarPiezasAdicionales(item)}
                                                                    </div>
                                                                </div>

                                                            </>
                                                        )
                                                :
                                                null
                                        }
                                    </div>
                                </>
                            )
                        }
                    </div>

                    {/** Fin de Piezas y trabajos */}

                </div>


            </>
        </Drawer>


        {
            /*Componente para buscar la referencia y detalles de la misma, se utiliza en la cesta al buscar una referencia */
            <FichaGMYVehiculoHaynesPro
                title={traducirPagina("Buscar_referencias").toUpperCase()}
                vehiculoId={props.ID_VEHICULO.toString()}
                grupoMontaje={grupoMontaje.toString()}
                listaTiempoReparacion={[]}
                componentePadre={"TECRMI"}
                registroSeleccionado={actualizarRegistroPiezaTECDOC}
            >

            </FichaGMYVehiculoHaynesPro>
        }

        {
            /**MOdal informativo, avisa la usuario que no a seleccionado referencia */
            <Modal
                visible={visible}
                width={600}
                centered
                title={traducirPagina("Tiene_articulos_ sinreferenciar")}
                afterClose={() => { }}
                onCancel={() => setVisible(false)}
                style={{ zIndex: '9999' }}
                footer={[
                    <Button key="back" onClick={() => { setVisible(false) }}>
                        {traducirPagina("NO")}
                    </Button>,
                    <Button className="btnColor" key="submit" type="primary" onClick={() => {
                        añadirApresupuesto();
                        setVisible(false)
                    }}> {traducirPagina("SI")}</Button>
                ]}

            >
                <p>{traducirPagina("msgManPre_material_no_referenciado_01")}</p>
                <p>{traducirPagina("msgManPre_material_no_referenciado_02")}</p>
                <p>{traducirPagina("msgManPre_material_no_referenciado_03")}</p>
                <p>{traducirPagina("msgManPre_material_no_referenciado_04")}</p>
            </Modal>
        }



        {
            /*Modal de TECRMI para seleccionar una pieza */
            initComponent.ABRIR_MODAL &&
            <Modal
                title={<div className="containerModalTitulo" style={{ fontSize: '16px' }}>{traducirPagina("txt_seleccion_articulos")}</div>}
                centered
                visible={initComponent.ABRIR_MODAL}
                onOk={() => { initComponent.ABRIR_MODAL = false; setInitComponent({ ...initComponent }) }}
                onCancel={() => { initComponent.ABRIR_MODAL = false; setInitComponent({ ...initComponent }) }}
                footer={null}
                width={700}
            >

                <div className="container_articulo_tiempo">
                    {
                        PIEZA_TRABAJO !== undefined &&
                            PIEZA_TRABAJO === null ?
                            <div className="center_item_alert"  > <h3>{traducirPagina("No_resultados")}</h3> </div>
                            :
                            PIEZA_TRABAJO.length === 0 ?
                                <div className="center_item_alert"> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                                :
                                <div className="articulo_tiempo" >
                                    <h2>{initComponent.TITULO_MODAL}</h2>
                                    {
                                        PIEZA_TRABAJO.filter((item) => item.CONJUNTO_PIEZAS_QUALCOL.length > 1).map((registro) =>
                                            <div className="item_articulo_tiempo">
                                                <Radio.Group >
                                                    {registro.CONJUNTO_PIEZAS_QUALCOL.map((item, i) =>
                                                        <Radio value={i}
                                                            onClick={(e) => {
                                                                registro.CONJUNTO_PIEZAS_QUALCOL.map((reg) => { reg.COMPRAR = false; deletePieza(registro, reg.ID_QUALCOL, false) });
                                                                addPieza(registro, item.ID_QUALCOL)

                                                                item.COMPRAR = !item.COMPRAR;
                                                                setInitComponent({ ...initComponent })
                                                            }}
                                                        > {`${registro.TEXTO_GRUPO_MONTAJE} ${item.TEXTO_QUALCOL}`}</Radio>
                                                    )}
                                                </Radio.Group>
                                            </div>
                                        )
                                    }
                                </div>
                    }
                </div>

                <div className="btn_articulo_tiempos">
                    <button onClick={() => { initComponent.ABRIR_MODAL = false; setInitComponent({ ...initComponent }) }}>OK</button>
                </div>
            </Modal>
        }
        
    </div>;
};