import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createRipple, DrawerFichaClienteTaller, DrawerFichaProveedores } from "../action/actionCreators"
import { setProveedoresEnAPI, getProveedoresEnAPI } from "../action/actionCreatorsRequests"
import { traducirPagina, MSGOK, efectoRemoveBuscadorReferencia } from '../librerias/funciones'
import { MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from 'mdbreact';
import MantenimientoProveedor from './MantenimientoProveedor';
import {
    PlusOutlined,
    CloseOutlined,
    SearchOutlined,
    EditOutlined,
    DeleteOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined
} from '@ant-design/icons';
import { Select, Card, Drawer } from 'antd';

import store from "../store";
const { Option } = Select;
const FichaProveedores = ({ props, configApp, openDrawerFichaProveedores }) => {
    const [ordenarPorCodigo, setOrdenarPorCodigo] = React.useState(false);
    const [ordenarPorNombre, setOrdenarPorNombre] = React.useState(false);
    const [ordenarPorCIF, setOrdenarPorCIF] = React.useState(false);
    const [hayRegistros, setHayRegistros] = React.useState(false);
    const [proveedores, setProveedores] = React.useState([]);
    const [proveedoresAUX, setProveedoresAUX] = React.useState([]);
    const [textoFiltroCliente, setTextoFiltroCliente] = React.useState("");
    const [abrirFicha, setAbrirFicha] = React.useState(false);
    const [proveedorSeleccionado, setProveedorSeleccionado] = React.useState({});


    const mostrarProveedores = (PAGE, ORDERCOLUM, ORDERDIR, ID, CODIGO, NOMBRE, CIF) =>{
        setProveedores([]);setProveedoresAUX([])
        getProveedoresEnAPI(PAGE, ORDERCOLUM, ORDERDIR, ID, CODIGO, NOMBRE, CIF).then((proveedor) => {
            if (proveedor !== undefined && proveedor !==  null ) {
                if (proveedor.PROVEEDORES !== null && proveedor.PROVEEDORES.length > 0) {
                    setProveedores(proveedor.PROVEEDORES)
                    setProveedoresAUX(proveedor.PROVEEDORES)
                } else {
                    setProveedores(null)
                    setProveedoresAUX([])
                }
            }
        })
    }


    if (!hayRegistros && openDrawerFichaProveedores) {
        setHayRegistros(true);
        mostrarProveedores(1, 1, "1", null, null, "", "");
    } else {
        if (!openDrawerFichaProveedores && hayRegistros) {
            setTextoFiltroCliente("")
            setHayRegistros(false);
            setProveedores([])
            setAbrirFicha(false)
            setProveedorSeleccionado({})
        }
    }



    const ordenarTabla = (ID, ORDERCOLUM, ORDERDIR) => {
        setProveedores([])
        getProveedoresEnAPI(1, ORDERCOLUM, ORDERDIR, ID, null, "", "").then((proveedor) => {
            if (proveedor !== undefined) {
                if (proveedor.PROVEEDORES !== null || proveedor.PROVEEDORES.length > 0) {
                    setProveedores(proveedor.PROVEEDORES)
                } else {
                    setProveedores(null)
                }
            }
        })
    }



    const eliminarCliente = (PAYLOAD) => {
        setProveedoresEnAPI(2, PAYLOAD).then((respuesta) => {
            if (respuesta !== undefined) {
                if (respuesta.OK) {
                    MSGOK("Proveedor eliminado correctamente.")
                    mostrarProveedores(1, 1, "1", null, null, "", "")
                }
            }
        })
    }


    const buscarCliente = (palabra, listaCliente) => {

        if (palabra !== "") {
            if (listaCliente !== null) {
                let resultadoFiltro = listaCliente.filter((item) => {
                    return item.CODIGO.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.NOMBRE.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.CIF.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.MAIL.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.TELEFONO.toUpperCase().includes(palabra.toUpperCase())
                })

                if (resultadoFiltro.length > 0) {
                    setProveedores(resultadoFiltro);
                } else {
                    setProveedores(null);
                }
            }
        } else {
            setProveedores(proveedoresAUX);
        }
       
    }
    setTimeout(() => { document.querySelectorAll(".ant-drawer-header-no-title").forEach(el => el.remove()) }, 100)


    return (
        <div>


            <Drawer
                width={900}
                onClose={() => { store.dispatch(DrawerFichaProveedores(false)); }}
                visible={openDrawerFichaProveedores}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ zIndex: '9999', color: '#fff' }}>


                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                            onClick={(e) => {
                                store.dispatch(DrawerFichaProveedores(false));
                                createRipple(e)
                            }}>
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>

                <div className="p-3" style={{ height: (configApp.height - 70).toString() + "px", overflowX: 'hidden', overflowY: 'auto' }}>
                    <div className="flex-container mdb-skin">
                        <div style={{ flexGrow: '6', textAlign: 'left' }}>
                            <div className="grid-container mb-3" style={{ backgroundColor: '#eee' }}>
                                <div className="grid-item">
                                    <input className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_proveedor")} style={{ backgroundColor: "transparent", width: '400px', border: '0px', height: '100%', color: '#000' }} autoComplete="off" value={textoFiltroCliente} onChange={(e) => { setTextoFiltroCliente(e.target.value); buscarCliente(e.target.value, proveedoresAUX) }} />
                                </div>

                                <div className="grid-item" >
                                    <MDBBtn id="closeBuscadorClienteTaller" onClick={e => { createRipple(e); }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: 'hidden' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                </div>

                                <div className="grid-item barraLateral" ></div>

                                <div className="grid-item">
                                    <MDBBtn onClick={e => { createRipple(e); }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: '' }}>
                            <button className="activebtnMenu2 btnEstiloPersonalizado" style={{ height: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#1890ff', backgroundColor: 'transparent', cursor: 'pointer' }} onClick={() => {
                                  setAbrirFicha(true)
                                  setProveedorSeleccionado({})
                            }
                            }><PlusOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Nuevo_proveedor")} </span>  </button>
                        </div>
                    </div>

                    <Card style={{ height: (configApp.height - 170).toString() + "px" }} className="mb-2 mdb-skin" >
                        {proveedores === null ?
                            <div style={{ textAlign: 'center' }}>
                                <h4>{traducirPagina("No_proveedores")}</h4>
                            </div>
                            :
                            proveedores.length > 0 ?
                                <div style={{ fontSize: '16px', fontWeight: '500', color: "#000", height: (configApp.height - 225).toString() + "px", overflowX: 'hidden', overflowY: 'auto' }} className="row">
                                    <MDBTable responsive hover >
                                        <MDBTableHead>
                                            <tr>
                                                <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '110px', verticalAlign: 'baseline', cursor: 'pointer' }} onClick={() => { ordenarTabla("", 1, ordenarPorCodigo ? "A" : "D"); setOrdenarPorCodigo(ordenarPorCodigo ? false : true); setOrdenarPorNombre(false); setOrdenarPorCIF(false) }} > {traducirPagina("Codigo")} {ordenarPorCodigo ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}</th>
                                                <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", verticalAlign: 'baseline', cursor: 'pointer' }} onClick={() => { ordenarTabla("", 2, ordenarPorNombre ? "A" : "D"); setOrdenarPorNombre(ordenarPorNombre ? false : true); setOrdenarPorCodigo(false); setOrdenarPorCIF(false) }}>{traducirPagina("Nombre")}{ordenarPorNombre ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />} </th>
                                                <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '110px', verticalAlign: 'baseline', cursor: 'pointer' }} onClick={() => { ordenarTabla("", 3, ordenarPorCIF ? "A" : "D"); setOrdenarPorCIF(ordenarPorCIF ? false : true); setOrdenarPorCodigo(false); setOrdenarPorNombre(false) }}>{traducirPagina("Cif").toUpperCase()}{ordenarPorCIF ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}</th>
                                                <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '200px', verticalAlign: 'baseline' }}>{traducirPagina("Email")}</th>
                                                <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '200px', verticalAlign: 'baseline' }}>{traducirPagina("Telefono")}</th>
                                                <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '100px', verticalAlign: 'baseline' }}></th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {proveedores.map((proveedor, i) =>
                                                <tr key={proveedor.ID} onClick={(e) => {

                                                }}>
                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{proveedor.CODIGO}</td>
                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{proveedor.NOMBRE}</td>
                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{proveedor.CIF}</td>
                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{proveedor.MAIL}</td>
                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{proveedor.TELEFONO}</td>
                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>

                                                        <div className="flex-container-Grow-Simple">
                                                            <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                <MDBBtn onClick={() => {
                                                                    setAbrirFicha(true)
                                                                    setProveedorSeleccionado(proveedor)
                                                                }}
                                                                    className="btn-matricula  estiloBotonRemove paddindDeTres">
                                                                    <EditOutlined style={{ color: '#999', cursor: 'pointer', fontSize: '20px' }} />
                                                                </MDBBtn>
                                                            </div>

                                                            <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                <MDBBtn onClick={() => {
                                                                    eliminarCliente(proveedor)
                                                                }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                    <DeleteOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                </MDBBtn>
                                                            </div>
                                                        </div>

                                                    </td>
                                                </tr>
                                            )}
                                        </MDBTableBody>
                                    </MDBTable>
                                </div>

                                :
                                <div style={{ fontSize: '16px', fontWeight: '500', color: "#000" }} className="row">
                                    <MDBCol md="9">
                                        <div>
                                            <span className="skeleton-box" style={{ width: '900px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '900px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '800px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '800px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '800px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '800px' }}></span>
                                        </div>
                                    </MDBCol>
                                </div>
                        }
                    </Card>
                </div>
            </Drawer>

            {abrirFicha ?
                <MantenimientoProveedor title={traducirPagina("Ficha_de_proveedor").toUpperCase()} ABRIR_FICHA={abrirFicha} CERRAR_FICHA={setAbrirFicha} PAYLOAD={proveedorSeleccionado} MOSTRAR_PROV={mostrarProveedores}></MantenimientoProveedor>
                :
                null
            }


        </div>
    )
}


FichaProveedores.propTypes = {
    title: PropTypes.string,
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        openDrawerFichaProveedores: state.estadoDrawer.openDrawerFichaProveedores
    };
}

export default connect(mapStateToProps)(FichaProveedores);