import React from "react";
import {
    traducirPagina,
    obtenerDatosUsuarioYLicenciaActual,
    convertirMoneda,
    MSGOK,
    MSGERROR,
    soloNumeros,
    verificarSiEsMostrador

} from "../../librerias/funciones"

import { Drawer, Input, Alert, Row, Col, Modal, Button, List, Select } from 'antd';
import { MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';

import { MinusOutlined, PlusOutlined, FieldTimeOutlined } from "@ant-design/icons";
import { ComprobarDisponibilidadEnAPI, ComprobarMailUsuarioEnAPI, RealizarPedidoEnAPI, ObtenerCentroClienteERPEnAPI } from "../../action/actionCreatorsRequests";
import store from "../../store";
const { Option } = Select;

export const FichaDisponibilidad = (props) => {
    const [centros, SetCentros] = React.useState([]);
    const [centroSeleccionado, SetCentroSeleccionado] = React.useState("");
    const [clienteSeleccionado, SetClienteSeleccionado] = React.useState("");

    const [payLoadLocal, setPayLoadLocal] = React.useState({
        CIF: "",
        CODEBRAND: "",
        DIRECCIONES: [],
        ENTREGA: "",
        PARCODE: "",
        PARTDESCRIPTION: "",
        PRECIO: "",
        PVP: "",
        PRESUP_NUMBER: "",
        STOCK: null,
        OK: true,
        MENSAJE_ERROR: "",
        DTO: ""

    })
    const [esperarPedido, setEsperarPedido] = React.useState(false)

    const [cerrarPedido, setCerrarPedido] = React.useState(1)

    const [infoPedido, setInfoPedido] = React.useState({
        ABRIR_MODAL: false,
        LISTA_REGISTRO: []
    })

    const [stockSeleccionado, setStockSeleccionado] = React.useState(-1)

    const [registroPedido, setRegistroPedido] = React.useState({
        PEDIDO_POR: "",
        SU_PEDIDO: "",
        OBSERVACIONES: "",
        NUMERO_PRESUPUESTO: "",
        ID_DIRECCION_ENVIO: "",
        UNIDADES: 0,
        DIRECCION_ENVIO: {},


        ARTICULO_DESCRIPCION: "",
        ARTICULO_MARCA: "",
        ARTICULO_REFERENCIA: "",
        ARTICULO_PRECIOCOSTE: "",
        ARTICULO_PVP: ""
    })




    const [initComponent, setInitComponent] = React.useState({
        REGISTROS: [],
        HTML_MANUAL: "",
        ABRIR_MODAL: false,
        TITULO_MODAL: "",
        ID_GRUPO_SELECCIONADO: 0,
        ALERTA: "",
        RUTA: []
    })


    const esMostrador = verificarSiEsMostrador();





    const cargarInformacion = async (REFERENCIA, MCR_ID) => {

        let registros = await ComprobarDisponibilidadEnAPI(REFERENCIA, MCR_ID, props.VEHICULO);
        const { Message } = registros


        if (registros !== undefined && Message === undefined) {

            payLoadLocal.CIF = registros.CIF
            payLoadLocal.CODEBRAND = registros.CODEBRAND
            payLoadLocal.ENTREGA = registros.ENTREGA
            payLoadLocal.PARCODE = registros.PARTCODE
            payLoadLocal.PARTDESCRIPTION = registros.PARTDESCRIPTION
            payLoadLocal.PRECIO = registros.PRECIO
            payLoadLocal.PVP = registros.PVP
            payLoadLocal.PRESUP_NUMBER = registros.PRESUP_NUMBER
            payLoadLocal.STOCK = registros.STOCK
            payLoadLocal.OK = registros.OK
            payLoadLocal.MENSAJE_ERROR = registros.MENSAJE_ERROR
            payLoadLocal.DTO = registros.DTO



            if (registros.DIRECCIONES !== undefined && registros.DIRECCIONES !== null && registros.DIRECCIONES.length > 0) {

                if (!payLoadLocal.OK) {
                    payLoadLocal.DIRECCIONES = null;

                } else {
                    registros.DIRECCIONES.map((item) => item.CANTIDAD = 0)
                    payLoadLocal.DIRECCIONES = registros.DIRECCIONES;
                }

            } else {
                payLoadLocal.DIRECCIONES = null;
            }

            setPayLoadLocal({ ...payLoadLocal })
        } else {
            payLoadLocal.DIRECCIONES = null;
            setPayLoadLocal({ ...payLoadLocal })

        }

    }

    const verificarMail = async () => {
        let registros = await ComprobarMailUsuarioEnAPI();

        initComponent.ALERTA = "";

        if (registros !== undefined && registros.MENSAJE_ERROR !== null && registros.MENSAJE_ERROR !== "") {
            initComponent.ALERTA = registros.MENSAJE_ERROR;
            setInitComponent({ ...initComponent })
        }
    }

    const crearPedido = async (payload) => {




        setCerrarPedido(2)
        let registros = await RealizarPedidoEnAPI(payload, props.VEHICULO, clienteSeleccionado, centroSeleccionado);
        setCerrarPedido(1)


        if (registros !== undefined) {
            setEsperarPedido(false)

            if (registros.RESULTADOS !== undefined && registros.RESULTADOS !== null) {
                infoPedido.ABRIR_MODAL = true
                infoPedido.LISTA_REGISTRO = registros.RESULTADOS
                setInfoPedido({ ...infoPedido })
            }

        }
    }

    React.useEffect(() => {
        //verificarMail();
        let PROVEEDOR_MARCA_ID = props.MCR_ID === undefined || props.MCR_ID === null ? "" : props.MCR_ID;
        cargarInformacion(props.REFERENCIA, PROVEEDOR_MARCA_ID)


        if (verificarSiEsMostrador()) {
            ObtenerCentroClienteERPEnAPI().then((registros) => {
                if (registros !== undefined && registros.CENTROS !== undefined && registros.CENTROS !== null && registros.CENTROS.length > 0) {
                    SetCentros(registros.CENTROS)
                    SetCentroSeleccionado(registros.CENTROS[0].CODIGO)
                } else {
                    SetCentros(null)
                }
            })

            let cliente = store.getState().clienteSeleccionado;
            SetClienteSeleccionado(cliente.codigo)

        }

        //console.log(props.VEHICULO)
    }, [])




    const {
        PARCODE,
        CODEBRAND,
        DIRECCIONES,
        ENTREGA,
        PARTDESCRIPTION,
        PRECIO,
        PVP,
        PRESUP_NUMBER,
        STOCK,
        DTO,
        MENSAJE_ERROR
    } = payLoadLocal




    return <div>
        <Drawer
            width={700}
            onClose={() => {

                props.CERRAR_FICHA(false)

            }}
            visible={props.ABRIR_FICHA}
            placement={"right"}
            closable={false}
            bodyStyle={{ padding: '0px' }}
            style={{ color: '#fff', zIndex: "99999999999" }}>

            <div className="flex-container-Grow-Tercero" >

                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                        onClick={(e) => {

                            props.CERRAR_FICHA(false)

                        }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>


            <div className="containerArticuloGenerico" style={{ overflow: 'auto' }}>

                <div className="ContainerDetalles" style={{ height: '200px' }}>

                    <div className="containermycar">

                        <div className="infoDetalleReferencia">
                            <div>{PARCODE} <span>{ }</span></div>
                            <div> <span>{PARTDESCRIPTION}</span></div>
                        </div>


                        <div className="infoDetalleReferencia">
                            <div>{``} </div>

                            {esMostrador ?
                                <div className="txtprecio_personal">
                                    {`${PRECIO === null || PRECIO === "" ? "" : convertirMoneda(PRECIO, " €")}`}

                                    <span >{`${PVP === null || PVP === "" ? "" : ` (${traducirPagina("PVP")}: ${convertirMoneda(PVP, " €")} | ${traducirPagina("Dto").toUpperCase()}: ${DTO}%)`}`}</span>

                                </div>
                                :
                                <div className="txtprecio_personal">
                                    <span >{`${PVP === null || PVP === "" ? "" : ` ${traducirPagina("PVP")}: ${convertirMoneda(PVP, " €")} `}`}</span>
                                </div>
                            }

                            <div className="label_entrega"> {ENTREGA === null || ENTREGA === "" ? "" : <FieldTimeOutlined></FieldTimeOutlined>}   <span>{ENTREGA}</span> </div>

                        </div>

                    </div>

                </div>


                <div className="ContainerDetalles" style={{ marginBottom: "0px", display: 'block', height: DIRECCIONES === null ? "" : 'calc(100% - 220px)' }}>
                    {

                        DIRECCIONES === null ?
                            null
                            :
                            DIRECCIONES.length === 0 ?
                                <div style={{ display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center', textAlign: 'center', color: '#000' }}> <div color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                :
                                <div style={{ height: 'calc(100% - 50px)', overflow: 'auto' }}>
                                    <MDBTable responsive hover>
                                        <MDBTableHead>
                                            <tr>
                                                <th></th>
                                                <th style={{ textAlign: 'left', fontWeight: '500' }}>{traducirPagina("Direccion_entrega").toUpperCase()}</th>

                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {DIRECCIONES.map((item, i) =>
                                                <tr key={i} style={stockSeleccionado === -1 ? {} : { pointerEvents: item.ID === stockSeleccionado ? 'all' : 'none', opacity: item.ID === stockSeleccionado ? 'none' : '0.5' }}>

                                                    <td style={{ textAlign: 'left' }}>
                                                        {

                                                            <span className="next-number-picker next-number-picker-inline next-medium zoro-ui-number-picker number-picker product-number-picker" style={{ color: '#000' }}>
                                                                <span className="next-input-group">
                                                                    <span className="next-input-group-addon next-before">
                                                                        <button disabled="" type="button" className="next-btn next-medium next-btn-normal" role="button" style={{ height: '27px', width: '27px' }} onClick={() => {

                                                                            if (item.CANTIDAD > 0) {
                                                                                item.CANTIDAD = item.CANTIDAD - 1;

                                                                                payLoadLocal.DIRECCIONES = payLoadLocal.DIRECCIONES
                                                                                setPayLoadLocal({ ...payLoadLocal })
                                                                                setStockSeleccionado(item.CANTIDAD === 0 ? -1 : item.ID)
                                                                            }

                                                                        }}>
                                                                            <MinusOutlined style={{ fontSize: '12px' }} />
                                                                        </button>
                                                                    </span>
                                                                    <span className="next-input next-medium next-input-group-auto-width">
                                                                        <input value={item.CANTIDAD} className="txtCantidad" aria-valuemax="1798" aria-valuemin="1" height="100%" autoComplete="off" style={{ width: '50px', margin: '2px 5px', height: '24px' }} onChange={(e) => {
                                                                            let valor = e.target.value;
                                                                            if (valor === "") {
                                                                                item.CANTIDAD = e.target.value;

                                                                            } else {
                                                                                if (soloNumeros(valor)) {
                                                                                    let convertir = parseInt(e.target.value);

                                                                                    //let resultado = STOCK > convertir ? e.target.value : STOCK;

                                                                                    item.CANTIDAD = valor === "0" ? 0 : parseInt(convertir)
                                                                                } else {
                                                                                    item.CANTIDAD = 0;
                                                                                }
                                                                            }

                                                                            setStockSeleccionado(item.CANTIDAD === 0 ? -1 : item.ID)


                                                                            payLoadLocal.DIRECCIONES = payLoadLocal.DIRECCIONES
                                                                            setPayLoadLocal({ ...payLoadLocal })

                                                                        }} />
                                                                    </span>
                                                                    <span className="next-input-group-addon next-after">
                                                                        <button type="button" className="next-btn next-medium next-btn-normal" role="button" data-spm-anchor-id="a2g0o.detail.1000016.i3.759e707cqToxAp" style={{ height: '27px', width: '27px' }} onClick={() => {
                                                                            if (item.CANTIDAD >= 0) {
                                                                                item.CANTIDAD = item.CANTIDAD + 1;

                                                                                payLoadLocal.DIRECCIONES = payLoadLocal.DIRECCIONES
                                                                                setPayLoadLocal({ ...payLoadLocal })

                                                                                setStockSeleccionado(item.CANTIDAD === 0 ? -1 : item.ID)

                                                                            }

                                                                        }}
                                                                        >
                                                                            <PlusOutlined style={{ fontSize: '12px' }} />
                                                                        </button>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        }
                                                    </td>
                                                    <td style={{ textAlign: 'left' }}>{item.DIRECCION}</td>

                                                </tr>
                                            )
                                            }
                                        </MDBTableBody>
                                    </MDBTable>

                                </div>

                    }

                    {

                        DIRECCIONES === null ?
                            <>
                                <div style={{ fontSize: '22px', fontWeight: '500', color: "#dc3545" }} className="grupoBtnArticulo">
                                    <div>
                                        <img src="./imagenes/no_disponible.svg" style={{ height: '170px' }}></img>
                                    </div>
                                    <div className="col-md-6">
                                        <span>{MENSAJE_ERROR}</span>
                                    </div>
                                </div>


                            </>
                            :
                            DIRECCIONES.length > 0 &&
                            <div className="grupoBtnArticulo">
                                <div style={{ marginTop: '10px' }}>
                                    <button className="activebtnMenu3" style={{ height: '40px', width: '250px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#fff', backgroundColor: '#4285f4' }}
                                        onClick={() => {
                                            if (verificarSiEsMostrador()) {
                                                if (clienteSeleccionado === "") {
                                                    MSGERROR(traducirPagina("msgTiene_seleccionar_cliente"))
                                                    return null
                                                }
                                            }


                                            let verificarCantidad = DIRECCIONES.find((item) => item.CANTIDAD > 0);

                                            if (verificarCantidad !== undefined) {
                                                verificarMail();
                                                setEsperarPedido(true)
                                            } else {
                                                MSGERROR(traducirPagina("ingrese_cantidad"));
                                            }




                                        }}>   <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{
                                            traducirPagina("Comprar")
                                        }
                                        </span>
                                    </button>
                                </div>
                            </div>

                    }
                </div>


            </div>


            <Modal
                visible={esperarPedido}
                width={700}
                centered
                title={traducirPagina("Confirmación_pedido")}
                zIndex={99999999999}

                afterClose={() => {
                    if (cerrarPedido === 1) {
                        registroPedido.PEDIDO_POR = ""
                        registroPedido.SU_PEDIDO = ""
                        registroPedido.OBSERVACIONES = ""
                        registroPedido.NUMERO_PRESUPUESTO = ""
                        registroPedido.ID_DIRECCION_ENVIO = ""
                        registroPedido.UNIDADES = 0


                        registroPedido.ARTICULO_DESCRIPCION = ""
                        registroPedido.ARTICULO_MARCA = ""
                        registroPedido.ARTICULO_REFERENCIA = ""
                        registroPedido.ARTICULO_PRECIOCOSTE = ""
                        registroPedido.ARTICULO_PVP = ""
                        setRegistroPedido({ ...registroPedido })
                    }



                }}

                onCancel={() => {
                    if (cerrarPedido === 1) {
                        setEsperarPedido(false)
                    }
                }}
                footer={[
                    cerrarPedido === 2 ?
                        <span style={{ fontSize: '20px', fontWeight: '500', color: '#4285f4' }}>{traducirPagina("Msg_espera_pedido")}</span>
                        :
                        <div className='container_Pedido_final'>
                            <div className='pedido_final_item2'>

                                <div>
                                    <Button className="btnColor" key="submit" type="primary" onClick={() => {
                                        let verificarCantidad = DIRECCIONES.find((item) => item.CANTIDAD > 0);

                                        const { ID, CANTIDAD } = verificarCantidad;
                                        registroPedido.NUMERO_PRESUPUESTO = payLoadLocal.PRESUP_NUMBER
                                        registroPedido.ID_DIRECCION_ENVIO = ID
                                        registroPedido.UNIDADES = CANTIDAD
                                        registroPedido.DIRECCION_ENVIO = verificarCantidad
                                        

                                        registroPedido.ARTICULO_DESCRIPCION = PARTDESCRIPTION
                                        registroPedido.ARTICULO_MARCA = props.MARCA_VEH
                                        registroPedido.ARTICULO_REFERENCIA = PARCODE
                                        registroPedido.ARTICULO_PRECIOCOSTE = PRECIO
                                        registroPedido.ARTICULO_PVP = PVP


                                        setRegistroPedido({ ...registroPedido })

                                        crearPedido(registroPedido)

                                    }}>{traducirPagina("Finalizar_pedido")} </Button>
                                </div>
                            </div>
                        </div>

                ]}>
                <Row>
                    <Col md={24} >
                        {centros !== null && centros.length > 1 ?
                            <span>{`${traducirPagina("Direccion_entrega")}: `} </span>
                            :
                            null
                        }
                    </Col>

                    <Col md={24}>
                        {centros !== null && centros.length > 1 ?
                            <select value={centroSeleccionado} className="custom-select select_centro_disponibles" style={{ width: "645px" }}
                                onChange={(e) => SetCentroSeleccionado(e.target.value)}>
                                {
                                    centros.map((registro, i) =>
                                        <option key={i} value={registro.CODIGO}>{registro.DIRECCION}</option>
                                    )
                                }
                            </select>
                            :
                            null
                        }

                    </Col>

                    <Col md={12} >
                        <span>{traducirPagina("Pedido_por") + ": "} </span>
                    </Col>
                    <Col md={12}>
                        <span>{traducirPagina("Numero_pedido")}</span>
                    </Col>
                    <Col md={12} >
                        <Input name="pedidoPor" value={registroPedido.PEDIDO_POR} onChange={(e) => { registroPedido.PEDIDO_POR = e.target.value; setRegistroPedido({ ...registroPedido }) }} style={{ width: '320px' }} />
                    </Col>
                    <Col md={12}>
                        <Input name="numeroPedido" value={registroPedido.SU_PEDIDO} onChange={(e) => { registroPedido.SU_PEDIDO = e.target.value; setRegistroPedido({ ...registroPedido }) }} style={{ width: '320px' }} />
                    </Col>
                    <Col md={24} className="mt-2">
                        <span>{traducirPagina("Observaciones")} </span>
                    </Col>

                    <Col md={24}>
                        <Input.TextArea name="observacionesPedido" value={registroPedido.OBSERVACIONES} onChange={(e) => { registroPedido.OBSERVACIONES = e.target.value; setRegistroPedido({ ...registroPedido }) }} rows={5} style={{ width: '645px' }} />
                    </Col>

                    <Col md={24} className="mt-3">
                        {
                            initComponent.ALERTA !== "" ?
                                <Alert message={initComponent.ALERTA}
                                    type="warning"
                                >
                                </Alert>
                                : null
                        }

                    </Col>

                </Row>
            </Modal>


            <Modal
                visible={infoPedido.ABRIR_MODAL}
                width={1200}
                zIndex={99999999999}
                centered
                title={traducirPagina("Respuesta_realizar_pedido").toUpperCase()}
                onCancel={() => { infoPedido.ABRIR_MODAL = false; setInfoPedido({ ...infoPedido }) }}
                footer={null}

                afterClose={() => {
                    props.CERRAR_FICHA(false)

                }}
            >

                <List
                    header={<div style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>{traducirPagina("Seguimiento_pedidos").toUpperCase()} </div>}
                    bordered
                    dataSource={infoPedido.LISTA_REGISTRO}
                    renderItem={pedido => (
                        <List.Item>
                            <div style={{ fontSize: '18px' }}>
                                <div><span style={{ fontWeight: 'bold' }}>{`${traducirPagina("Almacen").toUpperCase()}:`}</span>   <span>{pedido.ALMACEN}</span> </div>
                                <div><span style={{ fontWeight: 'bold', color: pedido.REALIZADO ? '#000' : '#b41e23' }}>{(pedido.REALIZADO ? traducirPagina("Pedido_realizado").toUpperCase() : pedido.MENSAJE_ERROR !== undefined && pedido.MENSAJE_ERROR !== null ? `${traducirPagina("Pedido_no_realizado").toUpperCase()}: ${pedido.MENSAJE_ERROR.toUpperCase()}` : "")}</span>  </div>
                                <div><span style={{ fontWeight: 'bold' }}>{`${traducirPagina("Codigo_pedido").toUpperCase()}:`}</span>  <span>{pedido.CODIGO_PEDIDO}</span> </div>
                            </div>

                        </List.Item>

                    )}
                />
            </Modal>
        </Drawer>


    </div>;
};