import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBNav, MDBNavItem, MDBNavLink, MDBTabPane, MDBTabContent, MDBIcon, MDBCol, MDBCardBody, MDBCard, MDBCardText, MDBRow, MDBTable, MDBTableHead, MDBTableBody, MDBInput } from 'mdbreact';
import { addDefaultSrc } from "../librerias/herramientas.js"
import 'antd/dist/antd.css';

import { Select, Card, Drawer, Modal, Button, Form, Input, Checkbox, Row, Col, Tabs } from 'antd';
import { DrawerPerfilUsuario, createRipple } from "../action/actionCreators";
import { GetUsuariosEnAPI, SetUsuarioEnAPI, GetIdiomasEnAPI } from "../action/actionCreatorsRequests";

import { MSGERROR, MSGOK, traducirPagina, verificarSiEsMostrador } from "../librerias/funciones"
import {
    PlusOutlined,
    SaveOutlined,
    EditOutlined,
    DeleteOutlined,
    FlagFilled
} from '@ant-design/icons';
import store from '../store'

const { Option } = Select;
const { TabPane } = Tabs;

const FichaPerfil = ({ props, configApp, openDrawerPerfilUsuario, usuario }) => {
    const [idiomas, setIdiomas] = React.useState({});
    const [textoIdioma, setTextoIdioma] = React.useState(1);
    const [hayCliente, setHayCliente] = React.useState(false);
    const [tabPorDefecto, setTabPorDefecto] = React.useState("1");
    const [idiomasLocal, setIdiomasLocal] = React.useState([]);
    const [passwordUsuario, setPasswordUsuario] = React.useState("");
    const [comunicarTelegram, setComunicarTelegram] = React.useState(true);

    const { LoginSertec } = usuario
    const { PERMISO_TELEGRAM } = LoginSertec !== undefined && LoginSertec !== null ? LoginSertec : { PERMISO_TELEGRAM: false }


    const [payLoadLocal, setPayLoadLocal] = React.useState(
        {
            ID: 0,
            NOMBRE: "",
            CORREO_RECUPERACION_PWD: "",
            CORREO_RECEPCION_PEDIDOS: "",
            CORREO_ENVIO_DOCUMENTOS: "",
            ID_TELEGRAM: "",
            USUARIO: "",
            PWD_OLD: "",
            PWD_NEW: "",
            PWD: "",
            OBSERVACIONES: "",
            BAJA: false,
            ID_IDIOMA: "1",
            MOSTRADOR_POR_DEFECTO_TELEGRAM: false,
            NOMBRE_PAIS:""
        }
    )

    if (!hayCliente && openDrawerPerfilUsuario) {
        setHayCliente(true);
        GetUsuariosEnAPI().then(usuario => {
            if (usuario !== undefined) {
                if (usuario !== null && usuario.length > 0) {
                    setPayLoadLocal({
                        ID: usuario[0].ID,
                        NOMBRE: usuario[0].NOMBRE_USUARIO,
                        CORREO_RECUPERACION_PWD: usuario[0].CORREO_RECUPERACION_PWD,
                        CORREO_RECEPCION_PEDIDOS: usuario[0].CORREO_RECEPCION_PEDIDOS,
                        CORREO_ENVIO_DOCUMENTOS: usuario[0].CORREO_ENVIO_DOCUMENTOS,
                        ID_TELEGRAM: usuario[0].ID_TELEGRAM,
                        USUARIO: usuario[0].USUARIO,
                        PWD_OLD: "",
                        PWD_NEW: "",
                        PWD: "",
                        OBSERVACIONES: usuario[0].OBSERVACIONES,
                        BAJA: usuario[0].BAJA,
                        ID_IDIOMA: usuario[0].ID_IDIOMA,
                        MOSTRADOR_POR_DEFECTO_TELEGRAM:usuario[0].MOSTRADOR_POR_DEFECTO_TELEGRAM,
                        NOMBRE_PAIS: usuario[0].NOMBRE_PAIS
                    })

                    let condicion = usuario[0].ID_TELEGRAM === "" ? false : true
                    setComunicarTelegram(condicion)
                    setPasswordUsuario(usuario[0].PWD)
                    setTextoIdioma(usuario[0].ID_IDIOMA)


                } else {
                    setPayLoadLocal(null)
                }
            }
        })

        GetIdiomasEnAPI().then(idiomas => {
            if (idiomas !== null && idiomas.length > 0) {
                setIdiomasLocal(idiomas)
            } else {
                setIdiomasLocal(null)
            }
        })

    } else {
        if (!openDrawerPerfilUsuario && hayCliente) {
            setHayCliente(false);
            setTextoIdioma(1)
            setTabPorDefecto("1")
            setIdiomasLocal([])
            setPasswordUsuario("")
            setPayLoadLocal({
                ID: 0,
                NOMBRE: "",
                CORREO_RECUPERACION_PWD: "",
                CORREO_RECEPCION_PEDIDOS: "",
                CORREO_ENVIO_DOCUMENTOS: "",
                ID_TELEGRAM: "",
                USUARIO: "",
                PWD_OLD: "",
                PWD_NEW: "",
                PWD: "",
                OBSERVACIONES: "",
                BAJA: false,
                ID_IDIOMA: "",
                MOSTRADOR_POR_DEFECTO_TELEGRAM:false,
                NOMBRE_PAIS:""
            })
        }
    }



    const modificarUsuario = () => {
        if (tabPorDefecto === "2") {
            let modificarContraseña = passwordUsuario
            let permitirGuardarContraseña = false

            if (payLoadLocal.PWD_OLD.trim() === "") {
                permitirGuardarContraseña = true
                modificarContraseña = passwordUsuario
            } else {
                if (payLoadLocal.PWD_OLD === modificarContraseña) {
                    if (payLoadLocal.PWD_NEW.trim() !== "") {
                        if (payLoadLocal.PWD_NEW === payLoadLocal.PWD) {
                            permitirGuardarContraseña = true
                            setPasswordUsuario(payLoadLocal.PWD_NEW);

                            modificarContraseña = payLoadLocal.PWD_NEW;
                        } else {
                            MSGERROR(traducirPagina("nueva_contrasenia_coincidir"))
                        }
                    } else {
                        MSGERROR(traducirPagina("nueva_contrasenia_vacia"))
                    }

                } else {
                    MSGERROR(traducirPagina("no_coincide_contrasenia"))
                }
            }

            if (permitirGuardarContraseña) {
                SetUsuarioEnAPI(payLoadLocal, modificarContraseña, textoIdioma).then(usuario => {
                    if (usuario.OK) {
                        MSGOK("Usuario modificado correctamente.")
                        store.dispatch(DrawerPerfilUsuario(false));
                    }
                })
            }

        } else {
            SetUsuarioEnAPI(payLoadLocal, passwordUsuario, textoIdioma).then(usuario => {
                if (usuario.OK) {
                    MSGOK("Usuario modificado correctamente.")
                    store.dispatch(DrawerPerfilUsuario(false));
                    store.dispatch({ type: "STATE_MODIFICAR_EMAIL_ENVIO_DOCUMENTO", emailEnvioDocumento: payLoadLocal.CORREO_ENVIO_DOCUMENTOS })
                }
            })
        }
    }



    const guardarEstadoFormulario = (e) => {
        const NOMBRE = e.target.name === 'NOMBRE' ? e.target.value : payLoadLocal.NOMBRE;
        const CORREO_RECUPERACION_PWD = e.target.name === 'CORREO_RECUPERACION_PWD' ? e.target.value : payLoadLocal.CORREO_RECUPERACION_PWD;
        const CORREO_RECEPCION_PEDIDOS = e.target.name === 'CORREO_RECEPCION_PEDIDOS' ? e.target.value : payLoadLocal.CORREO_RECEPCION_PEDIDOS;
        const CORREO_ENVIO_DOCUMENTOS = e.target.name === 'CORREO_ENVIO_DOCUMENTOS' ? e.target.value : payLoadLocal.CORREO_ENVIO_DOCUMENTOS;
        const ID_TELEGRAM = e.target.name === 'ID_TELEGRAM' ? e.target.value : payLoadLocal.ID_TELEGRAM;


        const USUARIO = e.target.name === 'USUARIO' ? e.target.value : payLoadLocal.USUARIO;
        const PWD_OLD = e.target.name === 'PWD_OLD' ? e.target.value : payLoadLocal.PWD_OLD;
        const PWD_NEW = e.target.name === 'PWD_NEW' ? e.target.value : payLoadLocal.PWD_NEW;
        const PWD = e.target.name === 'PWD' ? e.target.value : payLoadLocal.PWD;
        const OBSERVACIONES = e.target.name === 'OBSERVACIONES' ? e.target.value : payLoadLocal.OBSERVACIONES;
        const ID = e.target.name === 'ID' ? e.target.value : payLoadLocal.ID;
        const BAJA = e.target.name === 'BAJA' ? e.target.value : payLoadLocal.BAJA;
        const ID_IDIOMA = e.target.name === 'ID_IDIOMA' ? e.target.value : payLoadLocal.ID_IDIOMA;
        const MOSTRADOR_POR_DEFECTO_TELEGRAM = e.target.name === 'MOSTRADOR_POR_DEFECTO_TELEGRAM' ? e.target.checked : payLoadLocal.MOSTRADOR_POR_DEFECTO_TELEGRAM;
        const NOMBRE_PAIS = e.target.name === 'NOMBRE_PAIS' ? e.target.checked : payLoadLocal.NOMBRE_PAIS;


        

        setPayLoadLocal(
            {
                ID: ID,
                NOMBRE: NOMBRE,
                CORREO_RECUPERACION_PWD: CORREO_RECUPERACION_PWD,
                CORREO_RECEPCION_PEDIDOS: CORREO_RECEPCION_PEDIDOS,
                CORREO_ENVIO_DOCUMENTOS: CORREO_ENVIO_DOCUMENTOS,
                USUARIO: USUARIO,
                PWD_OLD: PWD_OLD,
                PWD_NEW: PWD_NEW,
                PWD: PWD,
                OBSERVACIONES: OBSERVACIONES,
                BAJA: BAJA,
                ID_IDIOMA: ID_IDIOMA,
                ID_TELEGRAM: ID_TELEGRAM,
                MOSTRADOR_POR_DEFECTO_TELEGRAM:MOSTRADOR_POR_DEFECTO_TELEGRAM,
                NOMBRE_PAIS:NOMBRE_PAIS
            }
        )
    }

    const modificarIdioma = (e) => {
        payLoadLocal.ID_IDIOMA = e;
        setPayLoadLocal(payLoadLocal)
        setTextoIdioma(e)
    }



    return (
        <Drawer
            width={480}
            onClose={() => { store.dispatch(DrawerPerfilUsuario(false)); }}
            closable={false}

            visible={openDrawerPerfilUsuario}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '99999999', color: '#fff' }}>
            <div className="flex-container-Grow-Tercero" >
                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { store.dispatch(DrawerPerfilUsuario(false)); createRipple(e) }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>
            {payLoadLocal === null ?
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <h4>{traducirPagina("msgFicPerf_no_info_usu")}</h4>
                </div>
                :
                <Tabs defaultActiveKey={"1"} activeKey={tabPorDefecto} onChange={(tab) => setTabPorDefecto(tab)} >
                    <TabPane tab={traducirPagina("Configuracion")} key="1" >
                        <div style={{ height: (configApp.height - 190).toString() + "px", }} className="ml-4 mr-4" >
                            <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>

                                <Col md={24}>
                                    <span>{traducirPagina("Nombre_de_usuario")} </span>
                                </Col>
                                <Col md={24} >
                                    <Input name="NOMBRE" value={payLoadLocal.NOMBRE} onChange={(e) => guardarEstadoFormulario(e)} />
                                </Col>

                                <Col md={24}>
                                    <span>{traducirPagina("Correo_recuperacion_contrasenya")} </span>
                                </Col>

                                <Col md={24} >
                                    <Input name="CORREO_RECUPERACION_PWD" value={payLoadLocal.CORREO_RECUPERACION_PWD} onChange={(e) => guardarEstadoFormulario(e)} />
                                </Col>
                                <Col md={24}>
                                    <span>{traducirPagina("Correo_recepcion_pedidos")} </span>
                                </Col>

                                <Col md={24} >
                                    <Input name="CORREO_RECEPCION_PEDIDOS" value={payLoadLocal.CORREO_RECEPCION_PEDIDOS} onChange={(e) => guardarEstadoFormulario(e)} />
                                </Col>

                                <Col md={24}>
                                    <span>{traducirPagina("Correo_envio_documentos")} </span>
                                </Col>

                                <Col md={24} >
                                    <Input name="CORREO_ENVIO_DOCUMENTOS" value={payLoadLocal.CORREO_ENVIO_DOCUMENTOS} onChange={(e) => guardarEstadoFormulario(e)} />
                                </Col>



                                <Col md={24}>
                                    <span>{traducirPagina("Seleccione_idioma_defecto")} </span>
                                </Col>
                                {/* ID_IDIOMA */}
                                <Col md={24} >
                                    {idiomasLocal !== null ?
                                        <select value={textoIdioma} className="custom-select" style={{ width: "400px" }}
                                            onChange={(e) => modificarIdioma(e.target.value)}>
                                            {idiomasLocal.length > 0 ?
                                                idiomasLocal.map((idioma) =>
                                                    <option key={idioma.ID} value={idioma.ID}>{idioma.NOMBRE}</option>
                                                )
                                                :
                                                null
                                            }
                                        </select>
                                        :
                                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                            <h4>{traducirPagina("Actualmente_no_idiomas")}</h4>
                                        </div>
                                    }
                                </Col>

                                <Col md={24}>
                                    <span>{traducirPagina("Pais")} </span>
                                </Col>

                                <Col md={24} >
                                    <Input disabled={true} name="NOMBRE_PAIS" value={payLoadLocal.NOMBRE_PAIS} onChange={(e) => guardarEstadoFormulario(e)} />
                                </Col>


                                <div style={{ display: PERMISO_TELEGRAM ? '' : 'none' }}>
                                    {verificarSiEsMostrador() ?
                                        <Col md={24} style={{ marginTop: '10px' }}>
                                            <Checkbox  name="MOSTRADOR_POR_DEFECTO_TELEGRAM"  checked={payLoadLocal.MOSTRADOR_POR_DEFECTO_TELEGRAM} onChange={(e) => {
                                                /*
                                                setComunicarTelegram(e.target.checked);
                                                if (!e.target.checked) {
                                                    payLoadLocal.ID_TELEGRAM = "";
                                                }
                                                */
                                                guardarEstadoFormulario(e)
                                            }} ><span style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>{traducirPagina("Msg_mostrador_telegram")}</span> </Checkbox>
                                        </Col>
                                        :
                                        <div>
                                            <Col md={24} style={{ marginTop: '10px' }}>
                                                <Checkbox checked={comunicarTelegram} onChange={(e) => {
                                                    setComunicarTelegram(e.target.checked);
                                                    if (!e.target.checked) {
                                                        payLoadLocal.ID_TELEGRAM = "";
                                                    }
                                                }} ><span style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>{traducirPagina("Deseo_comunicarme_con_Telegram")}</span> </Checkbox>
                                            </Col>
                                            <Col md={24} >
                                                <span >{traducirPagina("Identificador_de_Telegram")} </span>
                                            </Col>

                                            <Col md={24} >
                                                <Input disabled={!comunicarTelegram} name="ID_TELEGRAM" value={payLoadLocal.ID_TELEGRAM} onChange={(e) => guardarEstadoFormulario(e)} />
                                            </Col>
                                        </div>
                                    }
                                </div>


                            </Row>
                        </div>

                        <div style={{ flexGrow: '6', textAlign: 'right' }} className="ml-4 mr-4" >
                            <button className="activebtnMenu2" style={{ height: '40px', width: '220px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', cursor: 'pointer' }} onClick={(e) => modificarUsuario()}><SaveOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Guardar_y_salir").toUpperCase()} </span>  </button>
                        </div>
                    </TabPane>
                    <TabPane tab={traducirPagina("Credenciales")} key="2" >
                        <div style={{ height: (configApp.height - 203).toString() + "px", }} className="mb-2" >
                            <div style={{ height: (configApp.height - 190).toString() + "px", }} className="ml-4 mr-4" >
                                <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                                    <Col md={24}>
                                        <span>{traducirPagina("Usuario")} </span>
                                    </Col>
                                    <Col md={24} >
                                        <Input name="USUARIO" value={payLoadLocal.USUARIO} onChange={(e) => guardarEstadoFormulario(e)} />
                                    </Col>

                                    <Col md={24}>
                                        <span style={{ color: '#999' }}>{traducirPagina("msgFicPer_Mod_contrasenya")} </span>
                                    </Col>

                                    <Col md={24}>
                                        <span>{traducirPagina("Contrasenya_actual")} </span>
                                    </Col>
                                    <Col md={24}>
                                        <Input type="password" name="PWD_OLD" value={payLoadLocal.PWD_OLD} autoComplete="new-password" onChange={(e) => guardarEstadoFormulario(e)} />
                                    </Col>

                                    <Col md={24}>
                                        <span>{traducirPagina("Nueva_contrasenya")} </span>
                                    </Col>
                                    <Col md={24} >
                                        <Input type="password" name="PWD_NEW" value={payLoadLocal.PWD_NEW} autoComplete="new-password" onChange={(e) => guardarEstadoFormulario(e)} />
                                    </Col>

                                    <Col md={24}>
                                        <span>{traducirPagina("Confirmar_contrasenya")} </span>
                                    </Col>
                                    <Col md={24} >
                                        <Input type="password" name="PWD" value={payLoadLocal.PWD} autoComplete="new-password" onChange={(e) => guardarEstadoFormulario(e)} />
                                    </Col>

                                </Row>
                            </div>
                        </div>

                        <div style={{ flexGrow: '6', textAlign: 'right' }} className="ml-4 mr-4" >
                            <button className="activebtnMenu2" style={{ height: '40px', width: '220px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', cursor: 'pointer' }} onClick={(e) => modificarUsuario()}><SaveOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Guardar_y_salir").toUpperCase()} </span>  </button>
                        </div>
                    </TabPane>

                    <TabPane tab={traducirPagina("Observaciones")} key="3">

                        <div style={{ height: (configApp.height - 203).toString() + "px", }} className="mb-2" >
                            <div style={{ height: (configApp.height - 190).toString() + "px", }} className="ml-4 mr-4" >
                                <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                                    <Col md={24}>
                                        <span>{traducirPagina("Observaciones")} </span>
                                    </Col>
                                    <Col md={24} >
                                        <Input.TextArea name="OBSERVACIONES" rows={20} value={payLoadLocal.OBSERVACIONES} onChange={(e) => guardarEstadoFormulario(e)} />
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div style={{ flexGrow: '6', textAlign: 'right' }} className="ml-4 mr-4" >
                            <button className="activebtnMenu2" style={{ height: '40px', width: '220px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', cursor: 'pointer' }} onClick={(e) => modificarUsuario()}><SaveOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Guardar_y_salir").toUpperCase()} </span>  </button>
                        </div>


                    </TabPane>
                </Tabs>
            }
        </Drawer >
    )
}

FichaPerfil.propTypes = {
    config: PropTypes.object,
    detallesReferencia: PropTypes.object,
    masInformacion: PropTypes.object,
    fichaAbierta: PropTypes.bool,

    activeItemOuterTabs: PropTypes.string
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        openDrawerPerfilUsuario: state.estadoDrawer.openDrawerPerfilUsuario,
        usuario: state.usuario

    };
}

export default connect(mapStateToProps)(FichaPerfil);