import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
//import { ProtectedRoute } from "./protected.route";
import { BrowserRouter } from "react-router-dom";


import App from './App?v=1.8.4';
import 'alertifyjs//build/alertify.min.js';
import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import 'antd/dist/antd.css';
import './index.css'

import store from './store'
import { Provider } from 'react-redux';
import { updateStateMasterPage } from './action/actionCreators';


window.onpopstate = function (event) {
  // Code to handle back button or prevent from navigation

  if (window.location.pathname === "/") {
    store.dispatch(updateStateMasterPage(false))

  } else {
    store.dispatch(updateStateMasterPage(true))
  }

}

ReactDOM.render(

  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,

  document.getElementById("root")
);


