import axios from 'axios';
import store from '../store';

export function addDefaultSrc(ev) {
  ev.target.src = './imagenes/sinimagen.jpg'
}

export function newFormatDate(fecha) {
  var diaActual = new Date(fecha);

  var day = diaActual.getDate() < 10 ? "0" + diaActual.getDate() : diaActual.getDate();
  var month = (diaActual.getMonth() + 1) < 10 ? "0" + (diaActual.getMonth() + 1) : (diaActual.getMonth() + 1);
  var year = diaActual.getFullYear();

  var hora = diaActual.getHours() < 10 ? "0" + diaActual.getHours() : diaActual.getHours();
  var minutos = diaActual.getMinutes() < 10 ? "0" + diaActual.getMinutes() : diaActual.getMinutes();

  return fecha = day + '-' + month + '-' + year + ' ' + hora + ':' + minutos;
}

export function getDateToday() {
  var diaActual = new Date();

  var day = diaActual.getDate() < 10 ? "0" + diaActual.getDate() : diaActual.getDate();
  var month = (diaActual.getMonth() + 1) < 10 ? "0" + (diaActual.getMonth() + 1) : (diaActual.getMonth() + 1);
  var year = diaActual.getFullYear();

  var hora = diaActual.getHours() < 10 ? "0" + diaActual.getHours() : diaActual.getHours();
  var minutos = diaActual.getMinutes() < 10 ? "0" + diaActual.getMinutes() : diaActual.getMinutes();

  return year + '/' + month + '/' + day + ' ' + hora + ':' + minutos;
}

export function sortFunction(a, b) {
  var dateA = new Date(a.fecha).getTime();
  var dateB = new Date(b.fecha).getTime();
  return dateA > dateB ? 1 : -1;
};




export function setDimensionsImage(lista) {
  if (lista.length > 0) {
    setTimeout(() => {
      lista.map((item, posicion) => {
        var myImage = document.getElementById(item.IdMarcaHP)
        if (myImage !== null) {
          var ancho = myImage.width;
          var alto = myImage.height;
          if (alto < 30) {
            document.getElementById(item.IdMarcaHP).style.marginTop = "20px";
            document.getElementById(item.IdMarcaHP).style.maxHeight = "50px";
          } else if (alto > 30 && alto < 60) {
            document.getElementById(item.IdMarcaHP).style.marginTop = "10px";
            document.getElementById(item.IdMarcaHP).style.maxHeight = "50px";
          }
        }
      })
    }, 100)

  }
}

export function getIam(referencia) {
  //IAM
  axios.get(`http://81.46.206.90:846/Api/Articulo/getArticleByIdSocioLanguage/` + referencia + "/idSocio/085/idLanguage/es")
    .then(res => {
      const oem = res.data;
      var listaIam = []
      for (var i in oem) {
        var linea = { src: oem[i].Imagen, title: oem[i].NombreProveedor, subTitle: oem[i].ArtNr, color: "", price: "", qty: "" }
        listaIam.push(linea);
      }
      this.setState({ referencias: listaIam });
    })
}


export function getDataSocio(datos, promises, tempReferences,tipo) {
  let counterPromise = 0; var listaReference = [];
  if (datos.length === 0) {

    if(tipo === "ListSuplier"){
      store.dispatch({
        type: "GET_LIST_SUPLIERBYMODEL",
        references: listaReference,
        stateiam: listaReference.length > 0 ? "OK" : "WITHOUT_IAM",
        stateoem: listaReference.length > 0 ? "OK" : "WITHOUT_OEM"
      })
    }else if(tipo === "Favorite"){
      store.dispatch({
        type: "REPLACE_REFERENCES",
        references: listaReference,
        stateiam: listaReference.length > 0 ? "OK" : "WITHOUT_IAM",
      })
      store.dispatch({ type: "ADD_TO_REFERENCES", stateoem: "OK" });
    }
    
  } else {
    if(promises.length > 0){

    for (var x in promises) {
      Promise.resolve(promises[x].call).then(responses => {
        let resLinea = tempReferences.find(item => item.id === promises[counterPromise].id);
        
        if(Object.keys(responses.data).length === 0){
          resLinea.stock = "0";
          listaReference.push(resLinea);

          if (parseInt(x) === counterPromise) {
            if(tipo === "ListSuplier"){
              store.dispatch({
                type: "GET_LIST_SUPLIERBYMODEL",
                references: listaReference,
                stateiam: listaReference.length > 0 ? "OK" : "WITHOUT_IAM",
                stateoem: listaReference.length > 0 ? "OK" : "WITHOUT_OEM"
              })
        
            }else if(tipo === "Favorite"){
              store.dispatch({
                type: "REPLACE_REFERENCES",references: listaReference, stateiam: listaReference.length > 0 ? "OK" : "WITHOUT_IAM"})
              store.dispatch({ type: "ADD_TO_REFERENCES", stateoem: "OK" });
            }
  
          }
        }else{
          resLinea.stock = responses.data;
          listaReference.push(resLinea);
  
          if (parseInt(x) === counterPromise) {
            if(tipo === "ListSuplier"){
              store.dispatch({
                type: "GET_LIST_SUPLIERBYMODEL",
                references: listaReference,
                stateiam: listaReference.length > 0 ? "OK" : "WITHOUT_IAM",
                stateoem: listaReference.length > 0 ? "OK" : "WITHOUT_OEM"
              })
            }else if(tipo === "Favorite"){
            
              
              store.dispatch({
                type: "REPLACE_REFERENCES",references: listaReference, stateiam: listaReference.length > 0 ? "OK" : "WITHOUT_IAM"})
              store.dispatch({ type: "ADD_TO_REFERENCES", stateoem: "OK" });
            }
          }
        }
        counterPromise += 1;
      });
    }
  }else{
   
    store.dispatch({
      type: "REPLACE_REFERENCES",
      references: tempReferences,
      stateiam: tempReferences.length > 0 ? "OK" : "WITHOUT_IAM",
    })
    store.dispatch({ type: "ADD_TO_REFERENCES", stateoem: "OK" });

  }

  }
}

export function checkMatricula(text) {
  let p = "[A-Z]{1,2}\\d{4}[A-Z]{1,3}|^\\d{4}[A-Z]{3}";
  let regExp = new RegExp(p);
  let esMatricula = false;
  if (!regExp.test(text.toUpperCase())) {
   
    esMatricula = false;
  } else {
  
   esMatricula = true;
  }
  return esMatricula;
} 



export function bigImg(x) {
  alert("cambio")
}
