import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { showItemReferencia, estadoAgrupacionDeMarcas, setHistoricApp, mostrarVistaGrafica, updateVisualizacionFiltro,DrawerHistoricoCoche } from "../action/actionCreators"
import { pad, ObtenerMotorizacionesTurismosByEngNoEnAPI, getIdTecdocMotorByIdHaynes } from "../action/actionCreatorsRequests"
import { addDefaultSrc } from "../librerias/herramientas.js"
import alertify from 'alertifyjs';

import { traducirPagina, MSGOK, MSGERROR, calcularNeto, limpiarCocheStore, efectoRemoveBuscadorReferencia } from '../librerias/funciones'
import { MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBInput } from 'mdbreact';
import {
    SelectOutlined,
    ImportOutlined,
    LikeOutlined,
    DislikeOutlined,
} from '@ant-design/icons';
import { Card, Drawer } from 'antd';
import store from "../store";


const FichaCodigoMotor = ({ props, configApp }) => {
    const [nivelTabla, setNivelTabla] = React.useState(1);
    const [motorizacion, setMotorizacion] = React.useState([]);
    const [hayRegristros, setHayRegristros] = React.useState(false);
    const [motorSeleccionado, setMotorSeleccionado] = React.useState({});



    const buscarMotorizacion = (codMotor) => {
        setMotorizacion([])
        ObtenerMotorizacionesTurismosByEngNoEnAPI(codMotor).then(registro => {
            if (registro !== undefined) {
                if (registro.REGISTROS !== null && registro.REGISTROS.length > 0) {
                    setMotorizacion(registro.REGISTROS)
                } else {
                    setMotorizacion(null)
                }

            }
        })

    }



    if (!hayRegristros && props.FICHA_ABIERTA) {
        setHayRegristros(true);

    } else {
        if (!props.FICHA_ABIERTA && hayRegristros) {
            setNivelTabla(1)
            setMotorizacion([])
            setHayRegristros(false)
            setMotorSeleccionado({})
        }
    }

    setTimeout(() => { document.querySelectorAll(".ant-drawer-header-no-title").forEach(el => el.remove()) }, 100)

    return (
        <Drawer
            width={650}
            onClose={() => { props.CERRAR_ABIERTA(false); props.MODIFICAR_MOTORES([]) }}
            visible={props.FICHA_ABIERTA}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '9999', color: '#fff' }}>
            <div>

                <div style={{ backgroundColor: '#000 ', color: '#010910' }}>

                    <div className="flex-container-Grow">
                        <div>
                            <MDBBtn className="backgroundGris soloColorNegro" aria-label="Close" style={{ border: 'none', width: '55px', height: '55px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { props.CERRAR_ABIERTA(false); props.MODIFICAR_MOTORES([]) }} >
                                <i aria-label="icon: close" className="anticon anticon-close">
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                    </svg>
                                </i>
                            </MDBBtn>
                        </div>

                        <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                            {props.title}
                        </div>
                    </div>
                </div>


                <div className="ant-drawer-header" style={{ backgroundColor: '#dcdcdc', color: '#021323', borderBottom: '0px', borderRadius: '0px', padding: '8px 24px' }}>
                    <div className="flex-container row">
                        <div className="flex-item-left" style={{ textAlign: 'left' }}>
                            <div style={{ fontSize: '16px' }} >
                                <SelectOutlined style={{ fontSize: '20px', verticalAlign: 'unset', marginRight: '10px' }} />
                                <span>
                                    {nivelTabla === 1 ? traducirPagina("Seleccione_cod_motor") : nivelTabla === 2 ? traducirPagina("Seleccione_una_motorización") : ""}
                                </span>
                            </div>
                        </div>

                        {nivelTabla === 2 ?
                            <div className="flex-item-right">
                                <div style={{ textAlign: 'right' }}>
                                    <button id="btnMantenimiento" title="Mantenimientos" onClick={() => { setNivelTabla(1) }}
                                        className="activebtnMenu2" style={{ height: '40px', width: '50px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#1890ff', backgroundColor: '#ccc', marginLeft: '10px' }}><ImportOutlined style={{ fontSize: '28px' }} />
                                    </button>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                </div>


                <div className="p-3" style={{ height: (configApp.height - 140).toString() + "px", overflow: 'auto' }}>

                    {nivelTabla === 1 ?
                        props.MOTORES === null ?
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("No_resultados")}</h3> </div>
                            :
                            props.MOTORES.length > 0 ?
                                <MDBTable hover>
                                    <MDBTableHead>
                                        <tr>
                                            <th>{traducirPagina("Fabricante")}</th>
                                            <th>{traducirPagina("KV").toUpperCase()}</th>
                                            <th>{traducirPagina("CV").toUpperCase()}</th>
                                            <th>{traducirPagina("CC").toUpperCase()}</th>
                                            <th>{traducirPagina("Cilindros")}</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {props.MOTORES.map((row, i) =>
                                            <tr key={row.ID} id={row.ID} style={{ padding: '.40rem 1.20rem', cursor: 'pointer' }} onClick={(e) => {
                                                buscarMotorizacion(row.ID)
                                                setNivelTabla(2)
                                                setMotorSeleccionado(row)
                                            }}>
                                                <td style={{ textAlign: 'left' }}>{row.FABRICANTE}</td>
                                                <td>{row.KW}</td>
                                                <td>{row.CV}</td>
                                                <td>{row.CC}</td>
                                                <td>{row.CILINDROS}</td>
                                            </tr>
                                        )}
                                    </MDBTableBody>
                                </MDBTable>
                                :
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                        :
                        nivelTabla === 2 ?
                            motorizacion === null ?
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("No_resultados")}                                </h3> </div>
                                :
                                motorizacion.length > 0 ?
                                    <MDBTable hover>
                                        <MDBTableHead>
                                            <tr>
                                                <th>{traducirPagina("Descripcion")}</th>
                                                <th>{traducirPagina("Motores")}</th>
                                                <th>{traducirPagina("Desde")}</th>
                                                <th>{traducirPagina("Hasta")}</th>
                                                <th>{traducirPagina("KW").toUpperCase()}</th>
                                                <th>{traducirPagina("CV").toUpperCase()}</th>
                                                <th>{traducirPagina("CC").toUpperCase()}</th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {motorizacion.map((row, i) =>
                                                <tr key={row.ID} id={row.ID} style={{ padding: '.40rem 1.20rem', cursor: 'pointer' }} onClick={(e) => {
                                                    efectoRemoveBuscadorReferencia("buscadorReferencia", true);
                                                    limpiarCocheStore()
                                                    store.dispatch(estadoAgrupacionDeMarcas({ marcas: [] }));

                                                    store.dispatch(getIdTecdocMotorByIdHaynes(row.ID,row));
                                                    store.dispatch({ type: "SET_TIPO_COMBUSTIBLE", TIPO_COMBUSTIBLE: row.TIPO_COMBUSTIBLE, })

                                                    store.dispatch(showItemReferencia("tree"));

                                                    store.dispatch({ type: "REPLACE_VEHICULO_MATRICULA", vehiculoPorMatricula: {} })

                                                    store.dispatch(mostrarVistaGrafica(false))
                                                    store.dispatch(updateVisualizacionFiltro(true));

                                                    let marca = {
                                                        ID: 0,
                                                        DESCRIPCION: ""
                                                    }
                                                    let modelo = {
                                                        ID: motorSeleccionado.ID,
                                                        DESCRIPCION: motorSeleccionado.FABRICANTE,
                                                    }

                                                    let objetoVehiculo = {
                                                        ID: row.ID,
                                                        DESCRIPCION: row.DESCRIPCION
                                                    };
                                                    store.dispatch(setHistoricApp(marca, "/allbrands", "brand"));

                                                    store.dispatch(setHistoricApp(modelo, "/modelos", "modelo"));
                                                    store.dispatch(setHistoricApp(objetoVehiculo, "/motorizacion", "tipomotor"));



                                                    props.CERRAR_ABIERTA(false)
                                                    store.dispatch(DrawerHistoricoCoche(false)); 

                                                }}>
                                                    <td style={{ textAlign: 'left' }}>{row.DESCRIPCION}</td>
                                                    <td>{row.MOTORES}</td>
                                                    <td>{row.DESDE}</td>
                                                    <td>{row.HASTA}</td>
                                                    <td>{row.KW}</td>
                                                    <td>{row.CV}</td>
                                                    <td>{row.CC}</td>
                                                </tr>
                                            )}
                                        </MDBTableBody>
                                    </MDBTable>
                                    :
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                            :
                            null
                    }

                </div>


            </div>
        </Drawer>
    )
}

FichaCodigoMotor.propTypes = {
    title: PropTypes.string,
    FICHA_ABIERTA: PropTypes.bool,
    CERRAR_ABIERTA: PropTypes.func,
    MOTORES: PropTypes.array,
    MODIFICAR_MOTORES: PropTypes.func,

};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,


    };
}

export default connect(mapStateToProps)(FichaCodigoMotor);