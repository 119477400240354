import React from "react";
import { traducirPagina } from "../../../librerias/funciones";
import { Input } from "antd";

const IdentificadorCesta = ({identificador="",eventIdentificador = () =>{}}) => {
    
    return (
        <div className="ContainerDetalles" style={{ height: '130px' }}>
            <div>
                <span className='tituloIdentificador'> Identificador de la línea por parte del cliente</span>

                <Input className='textoidentificador' maxlength="16" type='text' onChange={eventIdentificador} value={identificador} placeholder={traducirPagina("Identificador")}></Input>

                <span className='subtituloIdentificador'>Dispone de 16 caracteres máximos</span>
            </div>
        </div>
    )
}

export default IdentificadorCesta;