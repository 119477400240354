import React from "react";

export const Card_RMI = ({clase_card = "card_mantenimiento", header = null, footer = null,  children }) => {
    return (
        <div className={clase_card}>
            {header}
            {children}
            {footer}
        </div>
    );
}