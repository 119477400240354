import React from "react";
import BtnGenerico from "../../utils/Buttoms/BtnGenerico";
import { traducirPagina, verificarSiEsMostrador } from "../../../librerias/funciones";
import { DeleteOutlined, FileAddOutlined, PlusOutlined, ShoppingOutlined } from "@ant-design/icons";

import './header.css'

const HeaderCesta = ({ formConsulta = {}, children = {}, event_vaciar = () => { }, event_pedido = () => { }, event_presupuesto = () => { }, }) => {
    const { visibleConsulta, SetVisibleConsulta } = formConsulta



    return (
        <div className="container_header">
            <div className="container_left">
                {children}
            </div>

            <div className="container_right">
                <BtnGenerico title={traducirPagina("Consultas")} onClick={() => SetVisibleConsulta(true)}>
                    <PlusOutlined />
                </BtnGenerico>

                <BtnGenerico title={traducirPagina("Vaciar_cesta")} onClick={() => { event_vaciar() }}>
                    <DeleteOutlined />
                </BtnGenerico>

                <BtnGenerico
                    title={traducirPagina("Realizar_pedido")}
                    onClick={() => { event_pedido() }}>
                    <ShoppingOutlined />
                </BtnGenerico>

                {verificarSiEsMostrador() && (
                    <BtnGenerico title={traducirPagina("Crear_presupuesto")} onClick={() => { event_presupuesto() }}>
                        <FileAddOutlined />
                    </BtnGenerico>
                )}
            </div>
        </div>
    )

}

export default HeaderCesta;