import PropTypes from 'prop-types';
import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from 'mdbreact';
import 'antd/dist/antd.css';

import { Select, Card, Drawer, Input, Row, Col, Tabs, Upload, } from 'antd';
import { createRipple, DrawerFichaEnvioDocumento } from "../action/actionCreators";

import { MSGERROR, MSGOK, traducirPagina } from "../librerias/funciones"
import {
    LoadingOutlined,
    SendOutlined
} from '@ant-design/icons';
import store from '../store'
const { Dragger } = Upload;
const { Option } = Select;
const { TabPane } = Tabs;

const FichaEnvioEmail = ({ props, configApp, openDrawerFichaEnvioDocumento, datosConfiguracion,TEXTO_DEFECTO="",ASUNTO="" }) => {
    const [hayRegisto, setHayRegistro] = React.useState(false);
    const [enviando, setEnviando] = React.useState(false);

    const [payLoadLocal, setPayLoadLocal] = React.useState(
        {
            DESDE: "",
            PARA: "",
            ASUNTO: "",
            CUERPO: ""
        }
    )





    const modificarDocumento = () => {
        if (payLoadLocal.DESDE.trim() !== "" && payLoadLocal.PARA.trim() !== "") {
            setEnviando(true)
            props.ENVIAR_EMAIL(payLoadLocal).then((registros) => {
                setEnviando(false)
            })

        } else {
            MSGERROR(traducirPagina("flata_desde_email"))
        }
    }

    const guardarEstadoFormulario = (e) => {
        const DESDE = e.target.name === 'DESDE' ? e.target.value : payLoadLocal.DESDE;
        const PARA = e.target.name === 'PARA' ? e.target.value : payLoadLocal.PARA;
        const ASUNTO = e.target.name === 'ASUNTO' ? e.target.value : payLoadLocal.ASUNTO;
        const CUERPO = e.target.name === 'CUERPO' ? e.target.value : payLoadLocal.CUERPO;

        setPayLoadLocal(
            {
                DESDE: DESDE,
                PARA: PARA,
                ASUNTO: ASUNTO,
                CUERPO: CUERPO
            }
        )
    }


    if (!hayRegisto && props.ABRIR_FICHA) {
        setHayRegistro(true);
        payLoadLocal.PARA = props.PARA;
        payLoadLocal.DESDE = datosConfiguracion.emailEnvioDocumento;
        payLoadLocal.ASUNTO = ASUNTO
        payLoadLocal.CUERPO = TEXTO_DEFECTO
        setPayLoadLocal(payLoadLocal)

    } else {
        if (!props.ABRIR_FICHA && hayRegisto) {
            setHayRegistro(false);
            setPayLoadLocal({
                DESDE: "",
                PARA: "",
                ASUNTO: "",
                CUERPO: ""
            })
        }
    }


    return (
        <Drawer
            width={600}
            onClose={() => { props.CERRAR_FICHA(false) }}
            visible={props.ABRIR_FICHA}
            closable={false}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '99999999', color: '#fff' }}>
            <div className="flex-container-Grow-Tercero" >
                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { props.CERRAR_FICHA(false); createRipple(e) }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>

            {payLoadLocal === null ?
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <h4>{traducirPagina("No_conexion_servidor")}</h4>
                </div>
                :
                <div>
                    <div style={{ height: (configApp.height - 150).toString() + "px", }} className="ml-4 mr-4" >
                        <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000', marginTop: '10px' }}>

                            <Col md={24}>
                                <span>{traducirPagina("Enviado_desde")} </span>
                            </Col>
                            <Col md={24}>
                                <Input name="DESDE" value={payLoadLocal.DESDE} onChange={(e) => guardarEstadoFormulario(e)} />
                            </Col>

                            <Col md={24}>
                                <span>{traducirPagina("Enviado_para")}</span>
                            </Col>
                            <Col md={24}>
                                <Input name="PARA" value={payLoadLocal.PARA} onChange={(e) => guardarEstadoFormulario(e)} />
                            </Col>

                            <Col md={24}>
                                <span>{traducirPagina("Asunto")}</span>
                            </Col>

                            <Col md={24}>
                                <Input name="ASUNTO" value={payLoadLocal.ASUNTO} onChange={(e) => guardarEstadoFormulario(e)} />
                            </Col>

                            <Col md={24}>
                                <span>{traducirPagina("Texto_mensaje")}</span>
                            </Col>

                            <Col md={24}>
                                <Input.TextArea name="CUERPO" rows={15} value={payLoadLocal.CUERPO} onChange={(e) => guardarEstadoFormulario(e)} />
                            </Col>

                        </Row>
                    </div>

                    <div style={{ flexGrow: '6', textAlign: 'right' }} className="ml-4 mr-4" >
                        <button
                            className="activebtnMenu2"
                            style={{ height: '40px', width: '220px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', cursor: 'pointer' }}
                            onClick={(e) => {
                                if (!enviando) {
                                    modificarDocumento()
                                }

                            }}>

                            {enviando ? <LoadingOutlined style={{ fontSize: '20px', marginRight: '10px' }} /> : <SendOutlined style={{ fontSize: '20px', marginRight: '10px' }} />}    <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Enviar_y_salir").toUpperCase()} </span>
                        </button>
                    </div>
                </div>
            }
        </Drawer>
    )
}

FichaEnvioEmail.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string,
    emailCliente: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    ENVIAR_EMAIL: PropTypes.func,
    PARA: PropTypes.string
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        datosConfiguracion: state.datosConfiguracion

    };
}

export default connect(mapStateToProps)(FichaEnvioEmail);