import React from "react";
import PropTypes from 'prop-types';
import { MDBBreadcrumbItem, MDBNav, MDBNavItem, MDBNavLink, MDBTabPane, MDBTabContent, MDBIcon, MDBCol, MDBCardBody, MDBCard, MDBCardText, MDBRow, MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from 'mdbreact';

import { Drawer, Col } from 'antd';
import { connect } from 'react-redux';
import store from "../store";
import { DrawerFichaCocheDGT, createRipple } from "../action/actionCreators";
import { traducirPagina, copiarAlPortapapeles } from "../librerias/funciones"
import { CopyOutlined } from '@ant-design/icons';
import { obtenerImagenHaynesPro, getFichaCocheEnAPI, buscarMatriculaCoche, buscarBastidorCoche } from "../action/actionCreatorsRequests"

const FichaCocheDGT = ({ configApp, openDrawerFichaCocheDGT, historicapp, props, codmotorizacion, sesionHaynesPro, openDrawerHistoricoCoche, buscadorCoche, nameDropDownCar,fichaBusquedaVehiculo,DROP_SELECCION_COCHE }) => {
    const [activeItemOuterTabs, setActiveItemOuterTabs] = React.useState("1");
    const [hayCoche, setHayCoche] = React.useState(false);
    const [obtenerRegistroCoche, setObtenerRegistroCoche] = React.useState({});
    const [hayDatosMatricula, setDatosMatricula] = React.useState(false);
    const [obteneVehiculoPorMatricula, setObteneVehiculoPorMatricula] = React.useState({});

    const [hayImagenCoche, setHayImagenCoche] = React.useState(false);
    const [obtenerImagenCoche, setObtenerImagenCoche] = React.useState("");


    let buscarMorizacion = props.codMotorizacionSeleccionada !== "" ? props.codMotorizacionSeleccionada : codmotorizacion

    if (!hayCoche && openDrawerFichaCocheDGT) {
        setHayCoche(true)

       
        getFichaCocheEnAPI(buscarMorizacion).then(coche => {
            if (coche !== null && Object.keys(coche).length > 0) {
                setObtenerRegistroCoche(coche);
            } else {
               
                setObtenerRegistroCoche(null);
            }
        })

    } else {
        if (!openDrawerFichaCocheDGT && hayCoche) {
            //Se actualiza la variable buscar coche cuando se cierre la ficha.
            setObtenerRegistroCoche({})
            setActiveItemOuterTabs("1")
            setHayCoche(false)

        }
    }


    if (!hayImagenCoche && openDrawerFichaCocheDGT) {
        setHayImagenCoche(true)
        obtenerImagenHaynesPro(buscarMorizacion).then(imagen => {
            if (imagen !== null && imagen !== "") {

               
                setObtenerImagenCoche(imagen);
            } else {
               
                setObtenerImagenCoche(null);
            }
        })
    } else {
        if (!openDrawerFichaCocheDGT && hayImagenCoche) {
            setObtenerImagenCoche("")
            setHayImagenCoche(false)
        }
    }

    if (!hayDatosMatricula && openDrawerFichaCocheDGT) {
        setDatosMatricula(true);

        if (!openDrawerHistoricoCoche || buscadorCoche.txtBuscadorCoche !== undefined) {

                if (DROP_SELECCION_COCHE.IDENTIFICADOR.toUpperCase() === "MAT") {
                    let identificador = buscadorCoche.txtBuscadorCoche 
                  
                    if(identificador !== undefined && identificador !== null){
                        buscarMatriculaCoche(identificador).then(coche => {
                            if (coche !== null) {
                                setDatosMatricula(true);
                                setObteneVehiculoPorMatricula(coche)
                            }
                        })
                    }
                   
                } else if (DROP_SELECCION_COCHE.IDENTIFICADOR.toUpperCase() === "BAS") {
                    let identificador = buscadorCoche.txtBuscadorCoche 
                    if(identificador !== undefined && identificador !== null){
                        buscarBastidorCoche(identificador).then(coche => {
                            if (coche !== null) {
                                setDatosMatricula(true);
                                setObteneVehiculoPorMatricula(coche)
                            }
                        })
                    }
                }
        } else {
            setObteneVehiculoPorMatricula({})
        }

    } else {
        if (!openDrawerFichaCocheDGT && hayDatosMatricula) {
            setObteneVehiculoPorMatricula({})
            setDatosMatricula(false)
        }
    }


    return (
        <Drawer
            width={Object.keys(obteneVehiculoPorMatricula).length > 0 ? 1000 : 600}
            onClose={() => {
                store.dispatch(DrawerFichaCocheDGT(false));
                store.dispatch({ type: "SET_ID_MOTORIZACION_FICHA_HISTORIAL", codMotorizacionFichaHistorial: "" })

            }}
            visible={openDrawerFichaCocheDGT}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            closable={false}
            style={{ zIndex: '9999', color: '#fff' }}>

            <div style={{ backgroundColor: '#010910 ', color: '#010910', borderBottom: '0px', borderRadius: '0px' }} className="flex-container-Grow">

                <div style={{ width: '55px', display: 'inline-flex' }} >
                    <MDBBtn className="backgroundGris " aria-label="Close" style={{ color: '#000', backgroundColor: '#021323', border: 'none', width: '55px', height: '55px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { store.dispatch(DrawerFichaCocheDGT(false)); createRipple(e); store.dispatch({ type: "SET_ID_MOTORIZACION_FICHA_HISTORIAL", codMotorizacionFichaHistorial: "" }) }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ display: 'inline-flex', width: '328px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px' }}>
                    {props.title}
                </div>
            </div>

            <div className="ant-drawer-header" style={{ backgroundColor: '#dcdcdc', color: '#021323', borderBottom: '0px', borderRadius: '0px', padding: '8px 24px' }}>
                <div className="flex-container row">
                    <div className="flex-item-left" style={{ textAlign: 'left', fontSize: '15px', padding: '10px 5px' }}>
                        {obtenerRegistroCoche !== null ?
                            <div className="breadcrumbNew" style={{ background: '#4285f4', color: '#fff', display: historicapp.length !== undefined ? historicapp.length > 0 ? '' : 'none' : 'none' }}>
                                <MDBBreadcrumbItem style={{ background: 'fff' }} > <a> {obtenerRegistroCoche.MARCA} </a></MDBBreadcrumbItem>
                                <MDBBreadcrumbItem style={{ background: 'fff' }} > <a> {obtenerRegistroCoche.MODELO} </a></MDBBreadcrumbItem>
                                <MDBBreadcrumbItem style={{ background: 'fff' }} > <a> {obtenerRegistroCoche.MOTORES} </a></MDBBreadcrumbItem>

                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>


            <Col span={Object.keys(obteneVehiculoPorMatricula).length > 0 ? 12 : 0} style={{ paddingLeft: '20px' }}>
                <MDBNav tabs className="nav-justified mt-3" color="primary">
                    <MDBNavItem>
                        <MDBNavLink className="headerTabReferencia" link to="/referencia"  active={activeItemOuterTabs === "1"} onClick={() => setActiveItemOuterTabs("1")} role="tab">{traducirPagina("Identificación")}</MDBNavLink>
                    </MDBNavItem>

                    <MDBNavItem>
                        <MDBNavLink className="headerTabReferencia" link to="/referencia"  active={activeItemOuterTabs === "2"} onClick={() => setActiveItemOuterTabs("2")} role="tab" >DGT</MDBNavLink>
                    </MDBNavItem>

                    <MDBNavItem>
                        <MDBNavLink className="headerTabReferencia" link to="/referencia"  active={activeItemOuterTabs === "3"} onClick={() => setActiveItemOuterTabs("3")} role="tab">{traducirPagina("Ficha_Técnica")}</MDBNavLink>
                    </MDBNavItem>
                </MDBNav>

                <MDBTabContent className="card" activeItem={activeItemOuterTabs} style={{ height: (configApp.height - 185).toString() + "px", overflow: 'auto', backgroundColor: "#eee" }}>
                    <MDBTabPane tabId="1" role="tabpanel">
                        <div style={{ color: '#000', fontSize: '16px' }}>
                            <div className="row">
                                <MDBCol md="12">
                                    <h4 className="mt-1">{traducirPagina("Datos técnicos")}</h4>
                                    <MDBTable responsive hover>
                                        {Object.keys(obteneVehiculoPorMatricula).length > 0 ?
                                            obteneVehiculoPorMatricula.IDENTIFICACION_VEHICULO !== null ?
                                                <MDBTableBody>
                                                    {obteneVehiculoPorMatricula.MATRICULA !== null ?
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("Matricula")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.MATRICULA.Matricula}</td>
                                                        </tr>
                                                        : null
                                                    }
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Bastidor")}</td>
                                                        <td id="textoACopiarDGT" style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.IDENTIFICACION_VEHICULO.BASTIDOR}</td>
                                                        <td style={{ textAlign: 'left' }}>{<button onClick={e => { copiarAlPortapapeles('textoACopiarDGT') }} style={{ border: '0', cursor: 'pointer' }} > <CopyOutlined style={{ fontSize: '16px', color: '#000', verticalAlign: 'text-bottom' }} /> </button>}</td>
                                                    </tr>

                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Bastidor")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.IDENTIFICACION_VEHICULO.BASTIDOR}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>Fecha 1º matricula </td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.IDENTIFICACION_VEHICULO["FECHA 1ª MATRÍCULA"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Fecha_matricula")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.IDENTIFICACION_VEHICULO["FECHA MATRÍCULA"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Marca")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.IDENTIFICACION_VEHICULO.MARCA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Mercado")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.IDENTIFICACION_VEHICULO.MERCADO}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Modelo")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.IDENTIFICACION_VEHICULO.MODELO}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Modelo_genérico")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.IDENTIFICACION_VEHICULO["MODELO GENÉRICO"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Número_de_transmisiones")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.IDENTIFICACION_VEHICULO["NUMERO DE TRANSMISIONES"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Procedencia")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.IDENTIFICACION_VEHICULO.PROCEDENCIA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Provincia_matricula")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.IDENTIFICACION_VEHICULO["PROVINCIA MATRÍCULA"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Segmento")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.IDENTIFICACION_VEHICULO.SEGMENTO}</td>
                                                    </tr>
                                                </MDBTableBody>
                                                :
                                                null
                                            :
                                            null
                                        }
                                    </MDBTable>
                                </MDBCol>
                            </div>
                        </div>
                    </MDBTabPane>

                    <MDBTabPane tabId="2" role="tabpanel">
                        <div style={{ color: '#000', fontSize: '16px' }}>
                            <div className="row">
                                <MDBCol md="12">
                                    <h4 className="mt-1">{traducirPagina("Datos técnicos")}</h4>
                                    <MDBTable responsive hover>
                                        {Object.keys(obteneVehiculoPorMatricula).length > 0 ?
                                            obteneVehiculoPorMatricula.DATOSTECNICOSDGT !== null ?
                                                <MDBTableBody>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Cilindrada")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.DATOSTECNICOSDGT.CILINDRADA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>CO2</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.DATOSTECNICOSDGT.CO2}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Combustible")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.DATOSTECNICOSDGT.COMBUSTIBLE}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>KW</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.DATOSTECNICOSDGT.KW}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Marca")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.DATOSTECNICOSDGT.MARCA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Modelo")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.DATOSTECNICOSDGT.MODELO}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Peso_maximo")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.DATOSTECNICOSDGT["PESO MAXIMO"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Plazas")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.DATOSTECNICOSDGT.PLAZAS}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Potencia_fiscal")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.DATOSTECNICOSDGT["POTENCIA FISCAL"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Potencia_aproximada")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.DATOSTECNICOSDGT["POTENCIA REAL APROXIM"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Tara")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.DATOSTECNICOSDGT.TARA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Tipo")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.DATOSTECNICOSDGT.TIPO}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Variante")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.DATOSTECNICOSDGT.VARIANTE}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Versión")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.DATOSTECNICOSDGT.VERSION}</td>
                                                    </tr>
                                                </MDBTableBody>
                                                :
                                                null
                                            :
                                            null
                                        }
                                    </MDBTable>
                                </MDBCol>
                            </div>
                        </div>
                    </MDBTabPane>

                    <MDBTabPane tabId="3" role="tabpanel">
                        <div style={{ color: '#000', fontSize: '16px' }}>
                            <div className="row">
                                <MDBCol md="12">
                                    <h4 className="mt-1">{traducirPagina("Datos_técnicos")}</h4>
                                    <MDBTable responsive hover>
                                        {Object.keys(obteneVehiculoPorMatricula).length > 0 ?
                                            obteneVehiculoPorMatricula.FICHATECNICA !== null ?
                                                <MDBTableBody>

                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Carroceria")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA.CARROCERIA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("CAT_HOMOLOGACIÓN")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA["CAT HOMOLOGACION"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Cilindrada")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA.CILINDRADA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>Clasif. Industria</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA["CLASIF INDUSTRIA"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>CO2</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA.CO2}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Codigos_ECO")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA["CODIGOS ECO"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Combustible")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA.COMBUSTIBLE}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Consumo")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA.CONSUMO}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Contraseña_homologación")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA["CONTRASEÑA HOLOMOGACION"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Denominacion_comercial")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA["DENOMINACION COMERCIAL"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>Dist. entre ejes</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA["DIST ENTRE EJES"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>ECO innova</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA["ECO INNOVA"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Fabricante")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA.FABRICANTE}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>KW</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA.KW}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Marca")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA.MARCA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>Masa max. carga</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA["MASA MAX CARGA"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Masa_vehiculo_servicio")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA["MASA VEHICULO SERVICIO"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Nivel_emisiones")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA["NIVEL EMISIONES"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>Num. plazas de pie</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA["NUM PLAZAS DE PIE"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Peso_maximo")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA["PESO MAXIMO"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Plazas")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA.PLAZAS}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Potencia_fiscal")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA["POT FISCAL"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>Potencia real aprox.</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA["POTENCIA REAL APROX"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Procedencia")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA.PROCEDENCIA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Reducción_eco")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA["REDUCCION ECO"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Relación_potencia_peso")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA["RELACION POTENCIA PESO"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Tara")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA.TARA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Tipo")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA.TIPO}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Tipo_alimentación")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA["TIPO ALIMENTACION"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Variante")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA.VARIANTE}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Versión")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA.VERSION}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Via_anterior")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA["VIA ANTERIOR"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Via_posterior")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obteneVehiculoPorMatricula.FICHATECNICA["VIA POSTERIOR"]}</td>
                                                    </tr>
                                                </MDBTableBody>
                                                :
                                                null
                                            :
                                            null
                                        }
                                    </MDBTable>
                                </MDBCol>
                            </div>
                        </div>
                    </MDBTabPane>
                </MDBTabContent>
            </Col>



            <Col span={Object.keys(obteneVehiculoPorMatricula).length > 0 ? 12 : 24}>
                <div style={{ height: (configApp.height - 130).toString() + "px", overflowY: 'scroll' }} className="col-md-12">
                    <MDBRow>
                        <MDBCol md="12">
                            {obtenerRegistroCoche !== null ?
                                Object.keys(obtenerRegistroCoche).length > 0 ?
                                    <div className="mdb-skin">
                                        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                            <div className="row">
                                                <MDBCol md="6">
                                                    <h4 className="mt-1">{traducirPagina("Año_fabricación")}</h4>
                                                    <MDBTable responsive hover>
                                                        <MDBTableBody>
                                                            
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Desde")}</td>
                                                                <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.DESDE}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Hasta")}</td>
                                                                <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.HASTA}</td>
                                                            </tr>
                                                        </MDBTableBody>
                                                    </MDBTable>
                                                </MDBCol>

                                                <MDBCol md="6">
                                                    <MDBCard style={{ height: "10rem", marginTop: '8px' }}>
                                                        <MDBCardBody style={{ textAlign: 'center', padding: "0rem 1rem" }}>
                                                            <img src={obtenerImagenCoche} className="img-fluid" alt="" style={{ width: obtenerImagenCoche === null ? "213px" : '200px' }} />
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </MDBCol>
                                            </div>

                                            <h4 className="mt-1">{traducirPagina("Datos_técnicos")}</h4>
                                            <MDBTable responsive hover>
                                                <MDBTableBody>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{"ID TECDOC"}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.ID}</td>
                                                    </tr>

                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("txt_Origen")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.ORIGEN}</td>
                                                    </tr>

                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Carroceria")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.CARROCERIA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Tipo_traccion")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.TRACCION}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Potencia")}[KW]</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.KW}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Potencia")}[CV]</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.CV}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Cilindrada")}[CC]</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.CC}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Combustible")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.COMBUSTIBLE}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Catalizador")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.CATALIZADOR}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Cilindros")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.CILINDROS}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Combustible")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.COMBUSTIBLE}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Cilindros")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.CILINDROS}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Litros")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.LITROS}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Marca")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.MARCA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Modelo")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.MODELO}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Motores")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.MOTORES}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Motorización")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.MOTORIZACION}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Proceso_combustión")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.PROCESO_COMBUSTION}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Sistema_frenado")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.SISTEMA_FRENADO}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>TCS</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.TCS}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Tipo_frenado")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.TIPO_FRENADO}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Tipo_motor")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.TIPO_MOTOR}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Tracción")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.TRACCION}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Transmición")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.TRANSMISION}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Valvulas")}</td>
                                                        <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.VALVULAS}</td>
                                                    </tr>
                                                </MDBTableBody>
                                            </MDBTable>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border  fast colorSubAzul" role="status" disabled /> </div>
                                :
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("No_hay_vehiculo_seleccionado")}</h3> </div>
                            }
                        </MDBCol>
                    </MDBRow>
                </div>
            </Col>
        </Drawer>
    );
}

FichaCocheDGT.propTypes = {
    title: PropTypes.string,
    codMotorizacionSeleccionada: PropTypes.string
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        fichaBusquedaVehiculo: state.fichaBusquedaVehiculo,
        localreferences: state.localreferences,
        localvehicles: state.localvehicles,
        historicapp: state.fichaBusquedaVehiculo.historicapp,
        imagenHaynesCoche: state.imagenHaynesCoche,
        props: props,
        openDrawerFichaCocheDGT: state.estadoDrawer.openDrawerFichaCocheDGT,
        openDrawerHistoricoCoche: state.estadoDrawer.openDrawerHistoricoCoche,
        codmotorizacion: state.codmotorizacion,
        sesionHaynesPro: state.sesionHaynesPro,
        buscadorCoche: state.buscadorCoche,
        nameDropDownCar: state.nameDropDownCar,
        DROP_SELECCION_COCHE:state.DROP_SELECCION_COCHE,
    };
}


export default connect(mapStateToProps)(FichaCocheDGT);





