import React from "react";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { traducirPagina } from "../../librerias/funciones";


const CPSearch = ({ TEXTO_BUSCADOR = "", onChangeInput = () => { }, onCloseInput = () => { }, className = "",placeholder="" }) => {
    return (

        <div className={`grid-container ${className}`} style={{ padding: '0px' }}>
            <div className="grid-item">
                <input className="form-control eliminarFoco " type="text" aria-label="Search" placeholder={placeholder} style={{ backgroundColor: "transparent", border: '0px', height: '100%', color: '#000' }} name="txtInputCliente" autoComplete="off"
                    onChange={(e) => {
                        onChangeInput(e)
                    }}
                    value={TEXTO_BUSCADOR} />
            </div>

            <div className="grid-item" style={{ display: 'flex' }} >
                <button style={{ visibility: TEXTO_BUSCADOR.trim() === "" ? 'hidden' : 'visible' }} className="btn-matriculav2 btnEliminarBuscador"
                    onClick={(e) => { onCloseInput(e) }}>
                    <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />
                </button>

                <div className="search_separdador"></div>

                <button className="search_btn_buscador">  <SearchOutlined style={{ fontSize: '20px' }} />  </button>
            </div>
        </div>
    )
}

export default CPSearch;