import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";

const CPCantidad = ({ value = 1, event_minus = () => { }, event_input = () => { }, event_plus = () => { }, onBlur = () => { } }) => {
    return (
        <span className="next-number-picker next-number-picker-inline next-medium zoro-ui-number-picker number-picker product-number-picker" >
            <span className="next-input-group">
                <span className="next-input-group-addon next-before">
                    <button disabled="" type="button" className="next-btn next-medium next-btn-normal" role="button"
                        onClick={() => {
                            event_minus()
                        }}>
                        <MinusOutlined style={{ fontSize: '16px' }} />
                    </button>
                </span>
                <span className="next-input next-medium next-input-group-auto-width">
                    <input className="txtCantidad" aria-valuemax="1798" aria-valuemin="1" height="100%" autoComplete="off" style={{ width: '50px', margin: '5px', height: '24px' }}
                        value={value}
                        onChange={(e) => {
                            event_input(e)
                        }}
                        onBlur={onBlur}
                        
                    />
                </span>
                <span className="next-input-group-addon next-after">
                    <button type="button" className="next-btn next-medium next-btn-normal" role="button" data-spm-anchor-id="a2g0o.detail.1000016.i3.759e707cqToxAp"
                        onClick={() => {
                            event_plus()
                        }}
                    >
                        <PlusOutlined style={{ fontSize: '16px' }} />
                    </button>
                </span>
            </span>
        </span>
    )
}

export default CPCantidad;