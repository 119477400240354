import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBNav, MDBNavItem, MDBNavLink, MDBTabPane, MDBTabContent, MDBIcon, MDBCol, MDBCardBody, MDBCard, MDBCardText, MDBRow, MDBTable, MDBTableHead, MDBTableBody, MDBInput } from 'mdbreact';
import 'antd/dist/antd.css';

import { Select, Card, Drawer, Modal, Button, Form, Input, Checkbox, Row, Col, Tabs, Collapse } from 'antd';
import { DrawerPerfilUsuario, createRipple } from "../../action/actionCreators";
import { ObtenerManualesReparacionByGmEnAPI, ObtenerManualesReparacionEnAPI } from "../../action/actionCreatorsRequests";

import { MSGERROR, MSGOK, traducirPagina, verificarSiEsMostrador, generateUUID, imprimirDivGenerico } from "../../librerias/funciones"
import VisorGenerico from '../Visor/VisorGenerico';
import {

    EyeOutlined,
    PrinterOutlined
} from '@ant-design/icons';
import store from '../../store'

const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const Manuales = ({ props, codmotorizacion }) => {

    const [hayRegistros, setHayRegistros] = React.useState(false);
    const [registros, setRegistros] = React.useState([]);
    const [manualesRp, setManualesRp] = React.useState([]);
    const [listadoManuales, setListadoManuales] = React.useState([]);


    const [abrirVisor, setAbrirVisor] = React.useState(false);
    const [ficheroSeleccionado, setFicheroSeleccionado] = React.useState({});


    const ObtenerManualesReparacion = (ID_VEHICULO_TECDOC, ID_MANUAL) => {
        setRegistros([])
        ObtenerManualesReparacionByGmEnAPI(ID_VEHICULO_TECDOC, ID_MANUAL).then(registros => {
            if (registros !== undefined && registros !== null && registros.MANUAL_REPARACION !== undefined && registros.MANUAL_REPARACION !== null && registros.MANUAL_REPARACION.length > 0) {
                setRegistros(registros.MANUAL_REPARACION)
            } else {
                setRegistros(null)

            }

        })
    }


    const ObtenerManuales = (ID_VEHICULO_TECDOC) => {
        ObtenerManualesReparacionEnAPI(ID_VEHICULO_TECDOC).then(registros => {
            if (registros !== undefined && registros !== null && registros.MANUALES !== undefined && registros.MANUALES !== null && registros.MANUALES.length > 0) {
                setManualesRp(registros.MANUALES);


                if (registros.MANUALES.length > 0) {
                    const { MANUALES } = registros.MANUALES[1];

                    MANUALES.map((registro, i) => {
                        registro.SELECCIONADO = i === 0 ? true : false
                        registro.ID = generateUUID()
                    })
                    setListadoManuales(MANUALES)

                    ObtenerManualesReparacion(codmotorizacion, MANUALES[0].ID_MANUAL)
                }

            } else {
                setManualesRp(null)

            }

        })
    }


    if (!hayRegistros && props.ABRIR_FICHA) {
        setHayRegistros(true);

        ObtenerManuales(codmotorizacion);
    } else {
        if (!props.ABRIR_FICHA && hayRegistros) {
            setHayRegistros(false);

        }
    }


    const listaIndice = (registros) => {
        let indices = []
        registros.map((regis, index) => {
            indices.push(index);
        })

        return indices
    }

    const manualSeleccionado = (MANUAL) => {
        listadoManuales.map((registro) => {
            if (registro.ID === MANUAL.ID) {
                registro.SELECCIONADO = true
            } else {
                registro.SELECCIONADO = false
            }
        })

        setListadoManuales([...listadoManuales])

        ObtenerManualesReparacion(codmotorizacion, MANUAL.ID_MANUAL)
    }



    

    return (
        <div>
            <Drawer
                width={1200}
                onClose={() => {
                    if (props.TIPO === "MASTER") {
                        props.CERRAR.setState({ ABRIR_FICHA_MANUALES: false })
                    } else {
                        props.CERRAR_FICHA(false)
                    }
                }}
                closable={false}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>
                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                            createRipple(e)
                            if (props.TIPO === "MASTER") {
                                props.CERRAR.setState({ ABRIR_FICHA_MANUALES: false })
                            } else {
                                props.CERRAR_FICHA(false)
                            }

                        }}>
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>



                    <div style={{ flexGrow: '1', textAlign: 'end', color: '#fff', verticalAlign: 'middle', paddingRight: '10px', fontSize: '16px' }}>
                        <button  className={`col-md-12 ${'btnColor'}`} style={{
                            height: '40px', width: '50px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top',
                            color:  '#fff',
                            marginBottom: '5px', marginRight: '5px'
                        }} onClick={(e) => {
                       
                            imprimirDivGenerico("item_2_manuales");
                        }}>
                            <PrinterOutlined style={{ fontSize: '20px' }} />
                            <span style={{ verticalAlign: 'middle', fontSize: '16px', marginLeft: '10px' }}></span>
                        </button>
                    </div>
                </div>

                <div className='container_manuales'>
                    <div className='item_1_manuales'>

                        <div className='manual_select'>
                            {manualesRp === null ?
                                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <h4>{traducirPagina("No_sistemas")}</h4>
                                </div>
                                :
                                manualesRp.length > 0 ?
                                    <Select
                                        showSearch
                                        style={{ width: "100%", textAlign: 'left' }}
                                        placeholder={traducirPagina("Seleccione_una_opcion").toUpperCase()}
                                        optionFilterProp="children"

                                        size="large"
                                        onChange={(e) => {


                                        }}
                                        defaultValue={manualesRp[1].NOMBRE}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }>

                                        {manualesRp.map((registro, i) =>
                                            <Option key={i} value={registro.NOMBRE} onClick={() => {
                                                registro.MANUALES.map((registro, i) => {
                                                    registro.SELECCIONADO = false
                                                    registro.ID = generateUUID()
                                                })

                                                setRegistros("MSG")
                                                setListadoManuales(registro.MANUALES)
                                            }} >{registro.NOMBRE}</Option>
                                        )
                                        }
                                    </Select>
                                    :
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '5vh' }}> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                            }

                        </div>


                        <div className='manual_listado'>
                            {listadoManuales === null ?
                                <h4>{traducirPagina("No_resultados")}</h4>
                                :
                                listadoManuales.map((registro) =>
                                    <div title={registro.NOMBRE} style={{ backgroundColor: registro.SELECCIONADO && '#b2b2b2' }} onClick={() => { manualSeleccionado(registro) }}>{registro.NOMBRE}</div>
                                )
                            }
                        </div>

                    </div>

                    <div className='item_2_manuales' >

                        <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000', padding: '10px' }}>
                            <Col md={24} className="conatinerTiempos">
                                {registros === undefined || registros === null ?
                                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                        <h4>{traducirPagina("No_resultados")}</h4>
                                    </div>
                                    :
                                    registros !== "MSG" && registros.length > 0 && registros[0].LINEAS.length > 0 ?
                                        <Collapse
                                            className="site-collapse-custom-collapse"
                                            bordered={false}
                                            defaultActiveKey={listaIndice(registros[0].LINEAS)}
                                        >

                                            {
                                                registros[0].LINEAS !== null ?
                                                    registros[0].LINEAS.map((subnodo, index) =>
                                                        <Panel
                                                            className="site-collapse-custom-panel-manuales"
                                                            header={subnodo.NOMBRE}
                                                            key={index}
                                                        >
                                                            {subnodo === null ?
                                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '20vh' }}> <h3>{traducirPagina("No_lubricantes")}</h3> </div>
                                                                :
                                                                subnodo !== undefined && subnodo !== null && subnodo.LINEAS !== undefined && subnodo.LINEAS !== null ?
                                                                    subnodo.LINEAS.length === 0 ?
                                                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '20vh' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                                                        :
                                                                        <ul style={{ paddingLeft: '8px' }}>

                                                                            {subnodo.LINEAS.map((tarea, indice) =>
                                                                                <li key={indice} style={{ listStyle: 'none' }}>
                                                                                    <div className={"site-collapse-custom-tareas-manuales"}>
                                                                                        <Row gutter={[8, 8]} style={subnodo.LINEAS.length > 1 ? { borderBottom: (subnodo.LINEAS.length - 1) === indice ? "" : tarea.TIPO_GRUPO_ORACION === "PARAGRAPH" || tarea.NOMBRE === "" ? "" : "1px #dcdcdc solid" } : {}}>
                                                                                            <Col md={24}>
                                                                                                <Row gutter={[8, 8]}>
                                                                                                    <Col md={24}>
                                                                                                        <div>
                                                                                                            {
                                                                                                                tarea.TIPO_GRUPO_ORACION === "PARAGRAPH" ?
                                                                                                                    <span className='site-collapse-custom-tareas-manuales-subtitulo'>{tarea.PARRAFO_CONTENIDO.map((item) => item.NOMBRE).toString()} </span>
                                                                                                                    :
                                                                                                                    tarea.TIPO_GRUPO_ORACION === "BULLET_LIST" ?
                                                                                                                        <ul style={{ fontWeight: '500' }}>
                                                                                                                            {tarea.LINEAS.map((registro) =>
                                                                                                                                <li>{registro.NOMBRE}</li>
                                                                                                                            )}
                                                                                                                        </ul>
                                                                                                                        :
                                                                                                                        tarea.TIPO_GRUPO_ORACION === "NUMBERED_LIST" ?
                                                                                                                            <ol style={{ fontWeight: '500' }}>
                                                                                                                                {tarea.LINEAS.map((registro) =>
                                                                                                                                    <li>{registro.NOMBRE}</li>
                                                                                                                                )}
                                                                                                                            </ol>
                                                                                                                            :
                                                                                                                            <>  <span className='site-collapse-custom-tareas-manuales-subtitulo'>{`${tarea.NOMBRE}  ${tarea.INFORMACION !== null ? ` ${tarea.INFORMACION}` : ``} ${tarea.HERRAMIENTAS_ESPECIALES.map((item) => item.CODIGO).toString() !== "" ? `(${tarea.HERRAMIENTAS_ESPECIALES.map((item) => item.CODIGO).toString()})` : ``}`}</span>  <br></br></>

                                                                                                            }

                                                                                                            {tarea.ENLACES.length > 0 ?
                                                                                                                <hr></hr>
                                                                                                                :
                                                                                                                null}

                                                                                                            {tarea.ENLACES.map((item, i) =>
                                                                                                                <>
                                                                                                                    <div className={"site-collapse-custom-tareas-manuales"} style={{ padding: '0px 8px' }}>
                                                                                                                        <span className='site-collapse-custom-tareas-manuales-subtitulo' style={{ marginLeft: '20px', display: 'block' }}>{`${item.TEXTO !== undefined && item.TEXTO !== null && item.TEXTO !== "" ? `${item.TEXTO.toString()}` : ``}`} </span>
                                                                                                                    </div>

                                                                                                                    {tarea.ENLACES.length - 1 > i ?
                                                                                                                        <hr></hr>
                                                                                                                        :
                                                                                                                        null}
                                                                                                                </>
                                                                                                            )
                                                                                                            }

                                                                                                        </div>
                                                                                                    </Col>

                                                                                                    <Col md={24}>

                                                                                                        {tarea.MIME_DATA !== undefined && tarea.MIME_DATA !== null && Object.keys(tarea.MIME_DATA).length > 0 ?
                                                                                                            <>
                                                                                                                <div className='site-collapse-custom-tareas-manuales-imagen' onClick={() => {
                                                                                                                    setFicheroSeleccionado({ TITULO: `${tarea.NOMBRE}  ${tarea.INFORMACION !== null ? ` ${tarea.INFORMACION}` : ``} ${tarea.HERRAMIENTAS_ESPECIALES.map((item) => item.CODIGO).toString() !== "" ? `(${tarea.HERRAMIENTAS_ESPECIALES.map((item) => item.CODIGO).toString()})` : ``}`, TIPO_DOCUMENTO: "IMG", URL: tarea.MIME_DATA.MIME_DATA, FICHERO: {} })
                                                                                                                    setAbrirVisor(true)

                                                                                                                }}>
                                                                                                                    <img src={tarea.MIME_DATA.MIME_DATA}></img>

                                                                                                                    <div className="image-mask">
                                                                                                                        <div className="image-mask-info">
                                                                                                                            <EyeOutlined title='Visualizar' style={{ fontSize: '30px', display: 'inline-block', marginRight: '20px' }}></EyeOutlined>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                {tarea.MIME_DATA.LINEAS !== null ? tarea.MIME_DATA.LINEAS.map((registro) =>
                                                                                                                    <div className={"site-collapse-custom-tareas-manuales"} >
                                                                                                                        <span className='site-collapse-custom-tareas-manuales-subtitulo' style={{ marginLeft: '0px', display: 'block', fontWeight: 'bold' }}>{registro.nameField} </span>
                                                                                                                        <hr></hr>
                                                                                                                        {registro.subStoryLinesField.map((registro) =>
                                                                                                                            <ol style={{ fontWeight: '500' }}>
                                                                                                                                {registro.subStoryLinesField.map((item) =>
                                                                                                                                    <li>{item.nameField}</li>

                                                                                                                                )}
                                                                                                                            </ol>


                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                )
                                                                                                                    :
                                                                                                                    null
                                                                                                                }
                                                                                                            </>


                                                                                                            :
                                                                                                            null
                                                                                                        }

                                                                                                    </Col>
                                                                                                </Row>

                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                </li>
                                                                            )}
                                                                        </ul>

                                                                    : null
                                                            }
                                                        </Panel>
                                                    )
                                                    : null

                                            }
                                        </Collapse>
                                        :
                                        listadoManuales.length > 0 && registros !== "MSG" ?
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                                            :
                                            null
                                }

                            </Col>
                        </Row>
                    </div>
                </div>
            </Drawer>

            {abrirVisor ?
                <VisorGenerico
                    ABRIR_VISOR={abrirVisor}
                    CERRAR_VISOR={setAbrirVisor}
                    PROPIEDADES_DOC={ficheroSeleccionado}
                    MOSTRAR_BOTON_DESCARGA={false}

                >
                </VisorGenerico>
                :
                null
            }
        </div>

    )
}

Manuales.propTypes = {
    title: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    CERRAR: PropTypes.object,
    REFERENCIA_SELECCIONADA: PropTypes.object
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        codmotorizacion: state.codmotorizacion,


    };
}

export default connect(mapStateToProps)(Manuales);