import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from 'mdbreact';
import 'antd/dist/antd.css';
import { Drawer } from 'antd';
import { DrawerIntranet, createRipple, } from "../action/actionCreators";
import store from '../store'

import { datosLincenciaSertec,obtenerDatosUsuarioYLicenciaActual } from '../librerias/funciones'


const FichaIntranet = ({ props, configApp, openDrawerIntranet, usuario }) => {
    const [enlace, setEnlace] = React.useState("");

    React.useEffect(() => {
        const OBTENER_DATOS_USUARIO = datosLincenciaSertec();
        const { ID_SOCIO } = OBTENER_DATOS_USUARIO === undefined || OBTENER_DATOS_USUARIO === null ? { ID_SOCIO: 0 } : OBTENER_DATOS_USUARIO;
        let ENLACE = ""
/*
        if(obtenerDatosUsuarioYLicenciaActual().PLANTILLA.toUpperCase() === "NEXUS"){
            switch (ID_SOCIO) {
                case 91: //GPN
                    ENLACE = "https://gpn.ceiweb.fr/"
                    break;
                case 89://GPNC
                    break;
                case 113://APT
                    ENLACE = "https://thibaud.ceiweb.fr/"
                    break;
                case 114://GPP
                    ENLACE = "https://gpp.ceiweb.fr/ "
                    break;
                default:    
                   ENLACE = "https://nexusauto.servicenext.eu/"
                    break;
            }
        }
*/



        switch (ID_SOCIO) {
            case 91: //GPN
                ENLACE = "https://gpn.ceiweb.fr/"
                break;
            case 89://GPNC
                break;
            case 113://APT
                ENLACE = "https://thibaud.ceiweb.fr/"
                break;
            case 114://GPP
                ENLACE = "https://gpp.ceiweb.fr/ "
                break;
            default:    
               ENLACE = null
                break;
        }

        setEnlace(ENLACE)

    }, [])






    return (
        <Drawer
            width={configApp.width - 360}
            onClose={() => { store.dispatch(DrawerIntranet(false)); }}
            closable={false}
            visible={openDrawerIntranet}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '99999999', color: '#fff' }}>

            <div className={"estiloDrawerHeader"}>
                <div>
                    <div className="flex-container-Grow-Tercero" style={{ width: (configApp.width - 360).toString() + "px" }}>
                        <div style={{ textAlign: 'inherit' }}>
                            <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { store.dispatch(DrawerIntranet(false)); createRipple(e) }} >
                                <i aria-label="icon: close" className="anticon anticon-close">
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                    </svg>
                                </i>
                            </MDBBtn>
                        </div>

                        <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                            {"INTRANET"}
                        </div>
                    </div>
                </div>


                {enlace !== null ?
                    enlace === "" ?
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div style={{ color: 'black' }} color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                        :
                        <iframe
                            id={"intranet"}
                            width="100%"
                            height={(configApp.height - 60).toString() + "px"}
                            title={props.title}
                            frameBorder="0"
                            allowtransparency="true"
                            src={enlace}

                        />
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3></h3> </div>
                }
            </div>
        </Drawer>

    )
}



const mapStateToProps = (state, props) => {
    return {
        masInformacion: state.masInformacion,
        configApp: state.configApp,
        props: props,
        openDrawerIntranet: state.estadoDrawer.openDrawerIntranet,
        fichaBusquedaVehiculo: state.fichaBusquedaVehiculo,
        usuario: state.usuario
    };
}

export default connect(mapStateToProps)(FichaIntranet);