import React from "react";
import { Drawer } from 'antd';
import { connect } from 'react-redux';
import store from "../store";
import { onChildrenDrawerCloseIntegraciones } from "../action/actionCreators";
import IframeIntegracion from '../components/IframeIntegracion';
import CestaCompra from '../components/CestaCompra'
import FichaSeleccionVehiculo from "./FichaSeleccionVehiculo";
import { traducirPagina } from '../librerias/funciones'

const ContainerDrawerIntegraciones = ({ activeItemTipoIntegracion, configApp, openDrawerIntegraciones, openDrawerFichaMantenimientoVehiculo, haynesProTouch, codmotorizacion, fichaBusquedaVehiculo, historicapp, masInformacion }) => {
  setTimeout(() => { document.querySelectorAll(".ant-drawer-header-no-title").forEach(el => el.remove()) }, 100)
  return (
    <Drawer
      width={activeItemTipoIntegracion === "cesta" ? 1300 : activeItemTipoIntegracion === "busquedaVehiculo" ? 1250 :(configApp.width - 360)}
      onClose={() => { store.dispatch(onChildrenDrawerCloseIntegraciones(false));  store.dispatch({ type: 'STATE_ES_NEUMATICO', NEUMATICO:{ES_NEUMATICO:false,REFERENCIA_FABRICANTE:""}  }) }}
      visible={openDrawerIntegraciones}
      placement={"right"}
      bodyStyle={{ padding: '0px' }}
      style={{zIndex: activeItemTipoIntegracion === "cesta" ? "999":'9999', color: '#fff' }}>
        
      {(() => {
        switch (activeItemTipoIntegracion) {
          case 'recambio':
            return <IframeIntegracion id={activeItemTipoIntegracion} title={"RECAMBIO FACÍL"} url={"https://www.recambiofacil.com/"} config={configApp} tipoComponente={"iframe"} fichaAbierta={openDrawerIntegraciones}></IframeIntegracion>
         
 {/*
          case 'cesta':
            return <CestaCompra id={activeItemTipoIntegracion} title={traducirPagina("Cesta_de_la_compra").toUpperCase()} ></CestaCompra>
 */}
          case 'busquedaVehiculo':
            return <FichaSeleccionVehiculo id={activeItemTipoIntegracion} title={"SELECCIÓN VEHÍCULO"} config={configApp} vehiculo={fichaBusquedaVehiculo} fichaVehiculoTallerAbierta={openDrawerFichaMantenimientoVehiculo}></FichaSeleccionVehiculo>
        }
      })()}
    </Drawer>

  );
}

const mapStateToProps = state => {
  return {
    brands: state.brands,
    allBrands: state.allbrands,
    models: state.models,
    visibleDrawer: state.visibleDrawer,
    configApp: state.configApp,
    localreferences: state.localreferences,
    localvehicles: state.localvehicles,
    openDrawerIntegraciones: state.openDrawerIntegraciones,
    mostarTodasLasMarcas: state.mostarTodasLasMarcas,
    activeItemTipoIntegracion: state.activeItemTipoIntegracion,
    historicapp: state.fichaBusquedaVehiculo.historicapp,
    referenciaSeleccionada: state.referenciaSeleccionada,
    haynesProTouch: state.haynesProTouch,
    codmotorizacion: state.codmotorizacion,
    fichaBusquedaVehiculo: state.fichaBusquedaVehiculo,
    masInformacion: state.masInformacion,
    openDrawerFichaMantenimientoVehiculo: state.estadoDrawer.openDrawerFichaMantenimientoVehiculo,

  };
}


export default connect(mapStateToProps)(ContainerDrawerIntegraciones);


