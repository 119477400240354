import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MDBBtn } from 'mdbreact';
import { Select, Drawer } from 'antd';
import {
    CloudUploadOutlined,

} from '@ant-design/icons';
const { Option } = Select;
const FichaTraking = ({ props, configApp }) => {

    const [hayClientes, setHayClientes] = React.useState(false);
    const [url, setUrl] = React.useState("https://app.vonzu.es/find-expedition?domain=Prueba_Vonzu&reference=oq4rkViexg&postalCode=08012");


    if (!hayClientes && props.ABRIR_FICHA) {
        setHayClientes(true);
    } else {
        if (!props.ABRIR_FICHA && hayClientes) {

            setHayClientes(false);


        }
    }

    return (
        <div>

            <Drawer
                width={"640px"}
                onClose={() => {
                    props.CERRAR_FICHA(false)
                   
                }}
                closable={false}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                bodyStyle={{ padding: '0px', lineHeight: '0px' }}
                style={{ color: '#fff' }}>

                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                            onClick={(e) => {
                                props.CERRAR_FICHA(false)
                            }}>
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                   
                </div>

                <iframe
                    style={{ height: (configApp.height - 61).toString() + "px", overflowY: 'auto', overflowX: 'hidden' }}
                    width="100%"
                    title={props.title}
                    frameBorder="0"
                    allowtransparency="true"
                    src={props.URL}

                />
            </Drawer>
        </div>
    )
}

FichaTraking.propTypes = {
    title: PropTypes.string,
    CERRAR_FICHA: PropTypes.func,
    ABRIR_FICHA: PropTypes.bool,
    URL:PropTypes.string
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,

    };
}

export default connect(mapStateToProps)(FichaTraking);