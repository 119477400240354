import React from "react";
import PropTypes from 'prop-types';
import {
    MDBRow, MDBTable, MDBTableHead, MDBTableBody, MDBCol
} from 'mdbreact';
import { addDefaultSrc } from "../librerias/herramientas.js"
import { connect } from 'react-redux';
import store from "../store";
import { removeAllEngineType, removeAllModel, changeColorListItemFalse, showItemCar } from "../action/actionCreators";
import { ObtenerMotoresByFabricanteEnAPI, ObtenerModelosEjesEnAPI, ObtenerEjesByModeloEnAPI, getModelosTurismosEnAPI, getMotorizacionesTurismosEnAPI } from "../action/actionCreatorsRequests";
import { getFocus, traducirPagina, obtenerTipoDeBusquedaActual } from "../librerias/funciones"
import ComponentInputCoche from '../components/cpInputCoche'
import ContainerMotor from "./ContainerMotor.jsx";
import ContainerModelo from "./ContainerModelo.jsx"
import {
    ShoppingOutlined,
    UserOutlined,
    CloseOutlined,
    AppstoreOutlined,

    SearchOutlined,
    ImportOutlined,

} from '@ant-design/icons';

const { useState, useRef } = React;
const ContainerFabricante = ({ configApp, props, childrenDrawerLevel2 }) => {
    const [fabricanteSeleccionado, setFabricanteSeleccionado] = React.useState({});
    const [modeloSeleccionado, setModeloSeleccionado] = React.useState({});

    const [textoFabricante, setTextoFabricante] = React.useState("");
    const [fichaAbierta, setFichaAbierta] = React.useState(false);
    const [listaFabricante, setListaFabricante] = React.useState([]);
    const [listaFabricanteOrig, setListaFabricanteOrig] = React.useState([]);


    const modelarDatos = (marcas) => {
        let listaMarca = [];
        let objetoNuevoMarcas = [];
        let contAux = 0;

        for (var i = 0; i < marcas.length; i++) {
            contAux += 1;
            if (contAux <= 3) {
                listaMarca.push({ "ID": marcas[i].ManNo, "Marca": marcas[i].Term })
                if ((marcas.length - 1) === i) {
                    objetoNuevoMarcas.push({ "lista": listaMarca });
                }
            } else {
                objetoNuevoMarcas.push({ "lista": listaMarca });
                contAux = 0;
                listaMarca = []
                i = (i - 1)
            }
        }
        return objetoNuevoMarcas.length === 0 ? null : objetoNuevoMarcas;
    }


    if (props.FABRICANTES !== null) {

        if (props.FABRICANTES.length === 0 && fichaAbierta) {
            setFichaAbierta(false);
            setListaFabricante([])
            setListaFabricanteOrig([])
        }

        if (!fichaAbierta && childrenDrawerLevel2 && props.FABRICANTES.length > 0) {
            setFichaAbierta(true);
            /*
                        let listaMarca = [];
                        let objetoNuevoMarcas = [];
                        let contAux = 0;
            
                        for (var i = 0; i < props.FABRICANTES.length; i++) {
                            contAux += 1;
                            var comparador = (props.FABRICANTES.length - 1);
            
                            if (contAux === 4 || i === comparador) {
                                objetoNuevoMarcas.push({ "lista": listaMarca });
                                contAux = 0; listaMarca = [];
                            } else {
                                listaMarca.push({ "ID": props.FABRICANTES[i].ManNo, "Marca": props.FABRICANTES[i].Term })
                            }
                        }
            */

            setListaFabricante(modelarDatos(props.FABRICANTES))
            setListaFabricanteOrig(props.FABRICANTES);
        } else {
            if (!childrenDrawerLevel2 && fichaAbierta) {
                setFichaAbierta(false);
                setTextoFabricante("")
                setFabricanteSeleccionado({})
            }
        }
    }

    if (props.FABRICANTES !== null) {
        getFocus("imputdropmarcas");
    }


    const buscarEnTabla = (palabraABuscar) => {
        let palabra = palabraABuscar;
        if (palabra !== "") {
            if (listaFabricanteOrig !== null) {
                let resultadoFiltro = listaFabricanteOrig.filter((item) => {
                    return item.Term.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.Term.toUpperCase() === palabra.toUpperCase()
                })
                if (resultadoFiltro.length > 0) {
                    setListaFabricante(modelarDatos(resultadoFiltro));
                } else {
                    setListaFabricante(null);
                }

            }
        } else {
            setListaFabricante(modelarDatos(listaFabricanteOrig));
        }
    }


    /*

    const buscarEnTabla = (palabra, listaCliente) => {
        let registrosEncontrados = [];
        if (palabra !== "") {
            if (listaCliente !== null) {
                listaCliente.find(function (registro, i) {
                    registro.lista.find(function (regis, i) {

                        if (regis.ID.toString().toLowerCase().includes(palabra.toLowerCase())) {
                            let existeRegistro = registrosEncontrados.find(regi => regi.ID === regis.ID)
                            if (existeRegistro === undefined) {
                                registrosEncontrados.push(registro)
                            }
                        }

                        if (regis.Marca.toString().toLowerCase().includes(palabra.toLowerCase())) {

                            let existeRegistro = registrosEncontrados.find(regi => regi.ID === regis.ID)
                            if (existeRegistro === undefined) {
                                registrosEncontrados.push(registro)
                            }
                        }

                    })

                })
            }
            if (registrosEncontrados.length > 0) {
                setListaFabricante(registrosEncontrados);
            } else {
                setListaFabricante(null);
            }
        } else {
            setListaFabricante(listaFabricanteOrig);
        }
    }
*/


    const obtenerMotores = (FABRICANTE, TIPO) => {
        if (TIPO === "M") {
            props.MODIFICAR_MOTORES([])
            ObtenerMotoresByFabricanteEnAPI(FABRICANTE).then((registros) => {
                if (registros !== undefined) {
                    if (registros.MOTORES !== null && registros.MOTORES.length > 0) {
                        props.MODIFICAR_MOTORES(registros.MOTORES)
                    } else {
                        props.MODIFICAR_MOTORES(null)
                    }
                }
            })
        } else if (TIPO === "I" || TIPO === "T") {
            props.MODIFICAR_MOTORES([])
            getMotorizacionesTurismosEnAPI(FABRICANTE).then((registros) => {
                if (registros !== undefined) {
                    if (registros !== null && registros.length > 0) {
                        props.MODIFICAR_MOTORES(registros)
                    } else {
                        props.MODIFICAR_MOTORES(null)
                    }
                }
            })
        } else if (TIPO === "E") {
            props.MODIFICAR_MOTORES([])
            ObtenerEjesByModeloEnAPI(FABRICANTE).then((registros) => {

                if (registros !== undefined) {
                    if (registros.REGISTROS !== null && registros.REGISTROS.length > 0) {
                        props.MODIFICAR_MOTORES(registros.REGISTROS)
                    } else {
                        props.MODIFICAR_MOTORES(null)
                    }
                }
            })

        }
    }

    const obtenerModelos = (FABRICANTE) => {
        props.MODIFICAR_MODELOS([])

        ObtenerModelosEjesEnAPI(FABRICANTE).then((registros) => {
            if (registros !== undefined) {
                if (registros.REGISTROS !== null && registros.REGISTROS.length > 0) {
                    props.MODIFICAR_MODELOS(registros.REGISTROS)
                } else {
                    props.MODIFICAR_MODELOS(null)
                }
            }
        })
    }


    const obtenerModelosVehiculo = (FABRICANTE) => {
        props.MODIFICAR_MODELOS([])

        getModelosTurismosEnAPI(FABRICANTE).then((registros) => {
            if (registros !== undefined) {
                if (registros !== null && registros.length > 0) {
                    props.MODIFICAR_MODELOS(registros)
                } else {
                    props.MODIFICAR_MODELOS(null)
                }
            }
        })
    }


    const tipoDeBusqueda = (row) => {
        let busqueda = obtenerTipoDeBusquedaActual();

        if (busqueda === "M") {
            obtenerMotores(row.ID, "M");
            props.MODIFICAR_COMPONENTE("MOTOR");
            props.MODIFICAR_NIVEL(2);
            setFabricanteSeleccionado(row)

        } else if (busqueda === "I" || busqueda === "T") {
            obtenerModelosVehiculo(row.ID);
            props.MODIFICAR_COMPONENTE("MODELOS");

            props.MODIFICAR_NIVEL(3);
            setFabricanteSeleccionado(row)

        } else if (busqueda === "E") {
            obtenerModelos(row.ID);
            props.MODIFICAR_COMPONENTE("MODELOS");
            props.MODIFICAR_NIVEL(3);
            setFabricanteSeleccionado(row)
        }
    }



    return (
        props.COMPONENTE === "FABRICANTE" ?
            <div>
                <div className="mdb-skin">
                    <div className="mt-3 pb-2">
                        <div className="grid-container">
                            <div className="grid-item">
                                <input className="form-control boxCoche" type="text" aria-label="Search" placeholder={traducirPagina("Busqueda_por_fabricante")} style={{ backgroundColor: "#eee", width: '500px', border: '0' }} autoComplete="off" onKeyPress={(e) => { }} onChange={(e) => { buscarEnTabla(e.target.value); setTextoFabricante(e.target.value) }} value={textoFabricante} />
                            </div>

                            <div className="grid-item">
                                <button onClick={e => { setTextoFabricante(""); setListaFabricante(listaFabricanteOrig) }} className="btn-matricula  estiloBotonRemove" style={{ visibility: textoFabricante.length > 0 ? 'visible' : 'hidden' }}> <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} /> </button>
                            </div>

                            <div className="grid-item barraLateral" ></div>

                            <div className="grid-item">
                                <button onClick={e => { }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none', backgroundColor: 'transparent !important' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </button>
                            </div>
                        </div>
                    </div>
                </div>
                {listaFabricante === null ?
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("No_fabricantes_para_mostrar")}</h3> </div>
                    :
                    listaFabricante.length > 0 ?

                        listaFabricante === null || listaFabricante === undefined ?
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("No_fabricantes_para_mostrar")}</h3> </div>
                            :
                            listaFabricante.length > 0 ?

                                listaFabricante.map((item, i) =>
                                    <div key={i} id={i} style={{ color: '#000', fontSize: '16px', fontWeight: '500', marginBottom: '10px' }}>
                                        {item.lista.map((row, i) =>
                                            <div style={{ float: 'left', padding: '5px', width: '33%', cursor: 'pointer' }} className="efectoContainerVehiculo" onClick={() => { tipoDeBusqueda(row) }} >
                                                {row.Marca}
                                            </div>
                                        )}
                                    </div>

                                )

                                :
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>

                        :
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                }

            </div>
            :
            props.COMPONENTE === "MODELOS" ?
                <ContainerModelo MODELOS={props.MODELOS} FABRICANTE={fabricanteSeleccionado} MODIFICAR_MODELOS={props.MODIFICAR_MODELOS} MODIFICAR_COMPONENTE={props.MODIFICAR_COMPONENTE} BUSCAR_MOTORES={obtenerMotores} MODELO_SELECCIONADO={setModeloSeleccionado} ></ContainerModelo>
                :
                props.COMPONENTE === "MOTOR" ?

                    <ContainerMotor MOTORES={props.MOTORES} FABRICANTE={fabricanteSeleccionado} MODIFICAR_MOTORES={props.MODIFICAR_MOTORES} MODELO_SELECCIONADO={modeloSeleccionado} ></ContainerMotor>
                    :
                    <div></div>

    );
}

ContainerFabricante.propTypes = {
    FICHA_ABIERTA: PropTypes.bool,
    FABRICANTES: PropTypes.array,
    MODIFICAR_NIVEL: PropTypes.number,
    COMPONENTE: PropTypes.string,
    MODIFICAR_COMPONENTE: PropTypes.func,
    MOTORES: PropTypes.array,
    MODIFICAR_MOTORES: PropTypes.func,
    MODELOS: PropTypes.array,
    MODIFICAR_MODELOS: PropTypes.func,

};


const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        childrenDrawerLevel2: state.childrenDrawerLevel2,
    };
}



export default connect(mapStateToProps)(ContainerFabricante);