import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn, } from 'mdbreact';
import 'antd/dist/antd.css';

import { Select, Card, Drawer, Modal, Button, Form, Input, Checkbox, Row, Col, Tabs, Collapse } from 'antd';
import { DrawerStockAlmacen, createRipple, DrawerMasInformacion, actulizarReferenciaSeleccionada } from "../action/actionCreators";
import { ObtenerCobrosEnAPI, GuardarCobroEnAPI, pagoTpvEnApi, FormasPagoEnAPI } from "../action/actionCreatorsRequests";

import { MSGERROR, MSGOK, traducirPagina, obtenerDatosUsuarioYLicenciaActual, convertirMoneda, referenciaActualSelect, verificarSiEsMostrador, buscarVehiculoLocal, condicionesAbrirCesta, cestaAutomatica, generateUUID, obtenerFechaLocal, redondeo, convertirMonedaANumero } from "../librerias/funciones"
import {
    PlusOutlined,
    DeleteOutlined,
    CloseOutlined
} from '@ant-design/icons';
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { TextArea } = Input;

const PagarFactura = ({ props, }) => {
    const [hayRegistros, setHayRegistros] = React.useState(false);
    const [cobros, setCobros] = React.useState([]);
    const [Fpago, setFpago] = React.useState([]);
    const [modalPagar, setModalPagar] = React.useState(false);
    const [formatoMoneda, setFormatoMoneda] = React.useState(true);



    const inputRef = React.useRef(null);
    const sharedProps = {
        ref: inputRef,
    };

    const [payload, setPayload] = React.useState(
        {
            ACCION: 0,
            ID: 0,
            ID_DOCUMENTO: "",
            TIPO_DOCUMENTO: "F",
            FECHA: "",
            OBSERVACIONES: "",
            IMPORTE: 0,
            FORMA_PAGO: "EFE",
            LOADER: 0
        }
    );


    const ObtenerCobros = async (ID, CODIGO, ID_DOCUMENTO, TIPO_DOCUMENTO) => {
        const respuesta = await ObtenerCobrosEnAPI(ID, CODIGO, ID_DOCUMENTO, TIPO_DOCUMENTO);

        if (respuesta !== undefined && respuesta !== null && respuesta.REGISTROS !== null && respuesta.REGISTROS.length > 0) {
            setCobros(respuesta.REGISTROS);
        } else {
            setCobros(null);

        }
    }


    const FormasPago = async () => {
        const respuesta = await FormasPagoEnAPI();
        if (respuesta !== undefined && respuesta !== null && respuesta.REGISTROS !== null && respuesta.REGISTROS.length > 0) {
            respuesta.REGISTROS.map((registro, i) => {

                registro.SELECCIONADO = i === 0 ? true : false;

                let imagen = "";
                if (registro.FORMA_PAGO === "EFE") {
                    imagen = "./imagenes/iconos_pago/efectivo.png"

                } else if (registro.FORMA_PAGO === "TAR") {
                    imagen = "./imagenes/iconos_pago/tarjeta.png"

                } else if (registro.FORMA_PAGO === "TRA") {
                    imagen = "./imagenes/iconos_pago/m_transfer.png"

                }

                registro.IMAGEN = imagen;
            })
            actualizarFpago(respuesta.REGISTROS);
            setFpago(respuesta.REGISTROS);
        } else {
            setFpago(null);

        }
    }



    const actualizarFpago = async (Fpago) => {
        let { FORMA_PAGO, NOMBRE } = Fpago !== null && Fpago.length > 0 ? Fpago.find((registro) => registro.SELECCIONADO === true) : { FORMA_PAGO: "", NOMBRE: "" }
        payload.FORMA_PAGO = NOMBRE;
        setPayload({ ...payload })
    }


    const respuestaCobro = (respuesta) => {
        payload.LOADER = 0;
        setPayload({ ...payload })


        if (respuesta !== undefined && respuesta !== null && respuesta.OK) {
            MSGOK(traducirPagina("pago_realizado"))

            ObtenerCobros("", "", props.REGISTRO_SELECCIONAD0.ID, "F");

            setModalPagar(false)

        } else {
            MSGERROR(traducirPagina("pago_no_realizado"))
        }
    }


    const gestionarPago = async () => {
        const { FORMA_PAGO } = Fpago !== null && Fpago.length > 0 ? Fpago.find((registro) => registro.SELECCIONADO === true) : { FORMA_PAGO: "", NOMBRE: "" }
        payload.LOADER = 1;
        payload.ACCION = 0;

        setPayload({ ...payload })
        const copyPayload = { ...payload }
        delete copyPayload.LOADER

        if (FORMA_PAGO === "EFE" || FORMA_PAGO === "TRA") {
            GuardarCobroEnAPI(copyPayload).then((respuesta) => {
                respuestaCobro(respuesta)
            })
        } else if (FORMA_PAGO === "TAR") {
            try {
                GuardarCobroEnAPI(copyPayload).then((respuesta) => {
                    respuestaCobro(respuesta)
                })
                /*

                pagoTpvEnApi(copyPayload).then(async (tarjeta) => {
                    if (tarjeta !== undefined && tarjeta !== null) {
                        const { resultMessage } = tarjeta;
                        if (resultMessage === "ACEPTADA") {
                            const respuesta = await GuardarCobroEnAPI(copyPayload);
                            respuestaCobro(respuesta)
                        } else {
                            payload.LOADER = 0;
                            setPayload({ ...payload })
                            MSGERROR(resultMessage)
                        }
                    } else {
                        payload.LOADER = 0;
                        setPayload({ ...payload })
                        MSGERROR("No se puede conectar al tpv")
                    }
                });
*/
            } catch (error) {
                MSGERROR(error.message)
            }
        }
    }


    const calcularImporte = (COBROS, IMPORTE_TOTAL) => {
        let restante = 0;
        if (COBROS !== undefined && COBROS !== null) {
            const suma = COBROS.reduce((sum, registro) => sum + registro.IMPORTE, 0);
            restante = redondeo((IMPORTE_TOTAL - suma), 2)
        } else {
            restante = IMPORTE_TOTAL;
        }
        return restante;
    }

    if (!hayRegistros && props.ABRIR_FICHA) {
        setHayRegistros(true);
        FormasPago();

        ObtenerCobros("", "", props.REGISTRO_SELECCIONAD0.ID, "F");


        let fechaLocal = obtenerFechaLocal(false);
        payload.FECHA = fechaLocal;
        payload.IMPORTE = props.REGISTRO_SELECCIONAD0.IMPORTE_TOTAL
        payload.ID_DOCUMENTO = props.REGISTRO_SELECCIONAD0.ID
        setPayload({ ...payload })

        // ObtenerReferenciasByVehicleFAST(codmotorizacion)
    } else {
        if (!props.ABRIR_FICHA && hayRegistros) {
            setHayRegistros(false);
        }
    }

    return (
        <div>
            <Drawer
                width={580}
                onClose={() => {
                    props.CERRAR_FICHA(false)
                    props.IMPRESORA()
                }}
                closable={false}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={props.ES_TRAZABILIDAD ? { zIndex: '999999999', color: '#fff' } : { color: '#fff' }}>
                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                            createRipple(e)

                            props.CERRAR_FICHA(false)
                            props.IMPRESORA()

                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>

                    <div style={{ flexGrow: '1', textAlign: 'end', color: '#fff', verticalAlign: 'middle', paddingRight: '10px', fontSize: '16px' }}>
                        <button className={`col-md-12 ${calcularImporte(cobros, props.REGISTRO_SELECCIONAD0.IMPORTE_TOTAL) <= 0 ? 'colorBloqueoBotoneraFacturacion' : 'btnColor'}`}
                            style={{
                                height: '40px', width: '200px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top',
                                color: calcularImporte(cobros, props.REGISTRO_SELECCIONAD0.IMPORTE_TOTAL) <= 0 ? '#999' : '#fff',
                                marginBottom: '5px', marginRight: '5px'
                            }} onClick={(e) => {
                                if (calcularImporte(cobros, props.REGISTRO_SELECCIONAD0.IMPORTE_TOTAL) > 0) {
                                    setModalPagar(true);
                                    const fechaLocal = obtenerFechaLocal(false);
                                    const RESTANTE = calcularImporte(cobros, props.REGISTRO_SELECCIONAD0.IMPORTE_TOTAL);
                                    payload.IMPORTE = RESTANTE;
                                    payload.FECHA = fechaLocal;
                                    payload.OBSERVACIONES = ""
                                    payload.LOADER = 0;

                                    setPayload({ ...payload })

                                    if (Fpago !== null && Fpago.length > 0) {
                                        Fpago.map((registro, i) => {
                                            registro.SELECCIONADO = i === 0 ? true : false;
                                        })
                                        setFpago([...Fpago])

                                        actualizarFpago(Fpago)

                                    }
                                    setTimeout(() => {
                                        if (inputRef.current !== undefined && inputRef.current !== null) {
                                            inputRef.current.focus({
                                                cursor: 'start',
                                            }, []);
                                        }
                                    }, 500)

                                    // resetMModalCobro();

                                }

                            }}>
                            <PlusOutlined style={{ fontSize: '20px' }} />
                            <span style={{ verticalAlign: 'middle', fontSize: '16px', marginLeft: '10px' }}>{traducirPagina("Agregar_cobro")}</span>
                        </button>
                    </div>
                </div>


                {cobros === null ?
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("No_resultados")}</h3> </div>
                    :
                    <div className='container_cobros_fact'>
                        {cobros.map((registro, i) =>
                            <div key={i} className='item_cobros_fact'>


                                <div className='header_cobros' style={props.ES_TRAZABILIDAD ? {opacity: '0.5', pointerEvents: 'none'}:{} }>
                                  
                                        <div onClick={() => {
                                           
                                                payload.ACCION = 2;
                                                payload.ID = registro.ID;
                                                setPayload({ ...payload })
    
    
                                                GuardarCobroEnAPI(payload).then((respuesta) => {
                                                    if (respuesta.OK) {
                                                        MSGOK(traducirPagina("Cobro_eliminado"))
                                                        ObtenerCobros("", "", props.REGISTRO_SELECCIONAD0.ID, "F");
    
                                                    }
                                                })


                                        }}>
                                            <DeleteOutlined ></DeleteOutlined>
                                        </div>
                                  

                                </div>



                                <div className='container_Pag_Fact'>
                                    <div className="item_container_Pag_Fac_25">
                                        {`${traducirPagina("Codigo")}: `}
                                    </div>
                                    <div className="item_container_Pag_Fac_75 text_secundario">
                                        {registro.CODIGO}
                                    </div>
                                </div>


                                <div className='container_Pag_Fact'>
                                    <div className="item_container_Pag_Fac_25">
                                    {`${traducirPagina("Fecha")}: `}

                                    </div>
                                    <div className="item_container_Pag_Fac_75 text_secundario">
                                        {registro.FECHA}
                                    </div>
                                </div>


                                <div className='container_Pag_Fact'>
                                    <div className="item_container_Pag_Fac_25">
                                    {`${traducirPagina("Importe")}: `}
                                        
                                    </div>
                                    <div className="item_container_Pag_Fac_25 text_secundario">
                                        {convertirMoneda(registro.IMPORTE, "€")}
                                    </div>
                                    <div className="item_container_Pag_Fac_50 ">
                                        <div className='btn_imp_cobro'>
                                            {Fpago !== undefined && Fpago !== null && Fpago.length > 0 ? Fpago.find((item) => item.FORMA_PAGO === registro.FORMA_PAGO).NOMBRE : registro.FORMA_PAGO}
                                        </div>
                                    </div>
                                </div>


                                <div className='container_Pag_Fact'>
                                    <div className="item_container_Pag_Fac_25">
                                        {`${traducirPagina("Observaciones")}: `}
                                        
                                    </div>
                                    <div className="item_container_Pag_Fac_75 obs_cobro text_secundario">
                                        {registro.OBSERVACIONES}
                                    </div>
                                </div>
                            </div>
                        )}
                        
                    </div>
                }
            </Drawer>

            {modalPagar ?
                <Modal
                    visible={modalPagar}
                    width={650}
                    centered
                    title={traducirPagina("Cobrar_factura").toUpperCase()}
                    closeIcon={
                        <CloseOutlined style={{ fontSize: '20px', color: '#8c8c8c' }} onClick={() => {
                            if (payload.LOADER === 0) {
                                setModalPagar(false)
                            }
                        }}></CloseOutlined>
                    }

                    bodyStyle={{ position: 'relative', padding: '10px' }}
                    footer={null}>

                    <div className={`card_pay ${(payload.LOADER === 1 || payload.LOADER === 2 ? "disabled_container" : ``)} `}>
                        <div className='card_pay_item_1'>
                            {Fpago !== null && Fpago.length > 0 ?
                                Fpago.map((registro, i) =>
                                    <div key={i} style={{ backgroundImage: `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url(${registro.IMAGEN})` }} className={registro.SELECCIONADO ? "hover_card_pay_item_1" : ""} onClick={() => {
                                        Fpago.map((item) => {
                                            if (registro.FORMA_PAGO === item.FORMA_PAGO) {
                                                item.SELECCIONADO = true;
                                            } else {
                                                item.SELECCIONADO = false;
                                            }
                                        })
                                        setFpago([...Fpago])
                                        actualizarFpago(Fpago)
                                    }}>
                                        {registro.NOMBRE}
                                    </div>
                                )
                                :
                                null
                            }
                        </div>

                        <div className='card_pay_item_2'>
                            <div className='card_pay_item_2_control '>
                                <Input size="large" placeholder='Forma de pago' disabled value={payload.FORMA_PAGO} />
                            </div>

                            <div className='card_pay_item_2_control form_input_Pago'>

                                <Input type='date' size="large" placeholder={traducirPagina("Fecha")} value={payload.FECHA} onChange={(e) => {
                                    payload.FECHA = e.target.value
                                    setPayload({ ...payload })
                                }} />

                                <Input  {...sharedProps} size="large" placeholder={traducirPagina("Importe")} value={formatoMoneda ? convertirMoneda(payload.IMPORTE) : payload.IMPORTE}
                                    onChange={(e) => {
                                        payload.IMPORTE = e.target.value

                                        setPayload({ ...payload })

                                        setFormatoMoneda(false)
                                    }}
                                    onBlur={(e) => {
                                        /*
                                        const RESTANTE = calcularImporte(cobros, props.REGISTRO_SELECCIONAD0.IMPORTE_TOTAL);
                
                                        let IMPORTE = e.target.value;
                                        IMPORTE = IMPORTE === "" ? "0" : IMPORTE.toString();
                                        IMPORTE = IMPORTE.replace(".", "");
                                        IMPORTE = IMPORTE.replace(",", ".");
                
                                        IMPORTE = parseFloat(IMPORTE);
                
                                        if (IMPORTE <= RESTANTE) {
                                            payload.IMPORTE = IMPORTE;
                                            setPayload({ ...payload })
                                        } else {
                                            payload.IMPORTE = RESTANTE;
                                            setPayload({ ...payload })
                
                                            MSGERROR(`${"El importe tiene que ser menor o igual a"} ${convertirMoneda(RESTANTE,"€")}` )
                                        }
                                            setFormatoMoneda(true)
                */
                                        setFormatoMoneda(true)

                                    }}
                                    onFocus={(e) => { e.target.select(); }}
                                />
                            </div>

                            <div className='card_pay_item_2_control'>
                                <TextArea placeholder={traducirPagina("Observaciones")} rows={6} value={payload.OBSERVACIONES} allowClear onChange={(e) => {
                                    payload.OBSERVACIONES = e.target.value

                                    setPayload({ ...payload })
                                }} />
                            </div>

                            <div className='card_pay_item_2_control'>
                                <div className='btn_pago_modal' onClick={() => {
                                    const RESTANTE = calcularImporte(cobros, props.REGISTRO_SELECCIONAD0.IMPORTE_TOTAL);

                                    let IMPORTE = convertirMoneda(payload.IMPORTE);

                                    IMPORTE = IMPORTE === "" ? "0" : IMPORTE.toString();
                                    IMPORTE = IMPORTE.replace(".", "");
                                    IMPORTE = IMPORTE.replace(",", ".");

                                    IMPORTE = parseFloat(IMPORTE);

                                    if (IMPORTE <= RESTANTE) {
                                        payload.IMPORTE = IMPORTE;
                                        setPayload({ ...payload })

                                        gestionarPago();
                                    } else {
                                        // payload.IMPORTE = RESTANTE;
                                        //  setPayload({ ...payload })

                                        MSGERROR(`${traducirPagina("importe_debe_ser_menor")} ${convertirMoneda(RESTANTE, "€")}`)
                                    }

                                    setFormatoMoneda(true)

                                }}>
                                    {traducirPagina("COBRAR")}
                                </div>
                            </div>

                        </div>
                    </div>

                    {payload.LOADER === 1 || payload.LOADER === 2 ?
                        <div className="item_fast_body_loader_mask">
                            <div className="item_fast_body_loader_mask_image">

                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: '#fff' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>

                            </div>
                        </div>
                        :
                        null
                    }
                </Modal>
                :

                null
            }
        </div>
    )
}

PagarFactura.propTypes = {
    title: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    REGISTRO_SELECCIONAD0: PropTypes.object,
    IMPRESORA: PropTypes.func,
    ES_TRAZABILIDAD: PropTypes.bool
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,

    };
}

export default connect(mapStateToProps)(PagarFactura);