import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBNav, MDBNavItem, MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from 'mdbreact';
import 'antd/dist/antd.css';
import { Drawer, Tabs, Col, Layout, Card, Select, Row, Tree, Image, Checkbox } from 'antd';
import { getStockDesglose, ObtenerAlbaranHTMLEnAPI, ObtenerAlbaranesVentaEnAPI, ObtenerExpedicionesEnAPI } from "../../action/actionCreatorsRequests";
import {
    createRipple
} from "../../action/actionCreators";
import {
    traducirPagina, construirFecha, convertirAMesACodigo, convertirCodigoAMeses, recortarCadena, crearListaKeysGenerico, dynamicSortAsc, obtenerFechaLocalObjeto,
    filtroGenericoCategorias, obtenerPathNodoGrupos, ordenarTablaLocal, convertirMoneda, obtenerDatosUsuarioYLicenciaActual, obtenerFechaLocal
} from "../../librerias/funciones"




import store from '../../store'
import {
    ShoppingOutlined,
    EyeOutlined,
    LikeOutlined,
    DislikeOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    EyeInvisibleOutlined,
    CopyOutlined,
    CheckSquareOutlined,
    CloseSquareOutlined,
    FileAddOutlined,
    CloseOutlined,
    SearchOutlined,
    FieldTimeOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    PrinterOutlined,
    EnvironmentOutlined
} from '@ant-design/icons';
import FichaTracking from '../FichaTracking';

const { Content } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;
const { DirectoryTree } = Tree;
const { Meta } = Card;


const Traking = ({ props, configApp, usuario, clienteSeleccionado, listaTree, mostrarComponente, propiedadesVisor }) => {
    const [hayReferencias, setHayReferencias] = React.useState(false);
    const [expediciones, setExpediciones] = React.useState([]);

    const [urlExpedicion, setUrlExpedicion] = React.useState("");






    const [albarenesTaller, setAlbaranesTaller] = React.useState([]);
    const [textoFiltroReferencia, setTextoFiltroReferencia] = React.useState("");
    const [albarenesTallerAUX, setAlbaranesTallerAUX] = React.useState([]);
    const [albaranSeleccionado, setalbaranSeleccionado] = React.useState({});
    const [abrirFichaDetalle, setAbrirFichaDetalle] = React.useState(false);
    const [ABRIR_FICHA, setABRIR_FICHA] = React.useState(false);
    const [DOC_HTML, setDOC_HTML] = React.useState("");
    const [ABRIR_FICHA_TRACKING, setABRIR_FICHA_TRACKING] = React.useState(false);
    const [fechaLocalDesde, setFechaLocalDesde] = React.useState("");
    const [fechaLocalHasta, setFechaLocalHasta] = React.useState("");

    const [ordenarTabla, setOrdenarPorTabla] = React.useState({
        CODIGO: false,
        FECHA: false,
        BASE_IMPONIBLE: false,
        TOTAL_IVA: false,
        TOTAL: false
    });


    const [mesesDelAnio, setMesesDelAnio] = React.useState(
        [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
        ]
    );

    const [anios, setAnios] = React.useState([]);
    const [mesSeleccionado, setMesSeleccionado] = React.useState("")
    const [anioSeleccionado, setAnioSeleccionado] = React.useState("")

    const [filtroFecha, setFiltroFecha] = React.useState({
        CAMBIO_DIA: true,
        CAMBIO_MES: false,
        CAMBIO_ANIO: false,
        ACCION_DESDE: 4,
        ACCION_HASTA: 5,
        FECHA_PARTIDA: "",
        INCLUIR_ENTREGADOS: false
    });


    const buscarAnyosAPI = async () => {
        /*
        const anyos = await obtener_anyos_EnAPI();
        if (anyos !== undefined && anyos !== null && anyos.REGISTROS !== null && anyos.REGISTROS.length > 0) {
            setAnios(anyos.REGISTROS);
            let maximo = 0;


            for (let i = 0, len = anyos.REGISTROS.length; i < len; i++) {
                if (maximo < anyos.REGISTROS[i]) {
                    maximo = anyos.REGISTROS[i];
                }
            }

            setAnioSeleccionado(maximo)

        } else {
            setAnios(null);
        }
        */
    }


    const buscarDocumento = (DOCUMENTO) => {
        ObtenerAlbaranHTMLEnAPI(DOCUMENTO).then((albaran) => {
            if (albaran !== undefined) {
                if (albaran.HTML !== null && albaran.HTML !== "") {
                    setDOC_HTML(albaran.HTML)
                } else {
                    setDOC_HTML("")
                }
            }
        })
    }

    const ordenarTablaDesc = (registros) => {

        let datos = registros.sort(function (a, b) {
            a = a.CABECERA.fechaAlbaran.split('/').reverse().join('');
            b = b.CABECERA.fechaAlbaran.split('/').reverse().join('');
            return a < b ? 1 : a > b ? -1 : 0;
        });
        return datos;
    }

    const ordenarTablaCliente = (CAMPO, ORDERDIR) => {
        switch (CAMPO) {
            case "CODIGO":
                setAlbaranesTaller(ordenarTablaLocal(ORDERDIR === "A" ? "ASC" : "DESC", albarenesTaller, "CODIGO_ALBARAN"))
                break;
            case "FECHA":
                setAlbaranesTaller(ordenarTablaLocal(ORDERDIR === "A" ? "ASC" : "DESC", albarenesTaller, "FECHA_ALBARAN"))
                break;
            case "BASE_IMPONIBLE":
                setAlbaranesTaller(ordenarTablaLocal(ORDERDIR === "A" ? "ASC" : "DESC", albarenesTaller, "BASE_IMPONIBLE"))
                break;
            case "TOTAL_IVA":
                setAlbaranesTaller(ordenarTablaLocal(ORDERDIR === "A" ? "ASC" : "DESC", albarenesTaller, "TOTAL_IVA"))
                break;
            case "TOTAL":
                setAlbaranesTaller(ordenarTablaLocal(ORDERDIR === "A" ? "ASC" : "DESC", albarenesTaller, "TOTAL_ALBARAN"))
                break;
            default:

        }
    }
    const filtrarRegistrosPorReferencia = (palabra, registros) => {
        let resultado = []
        if (palabra !== "") {
            if (registros !== null) {
                let resultadoFiltro = [];
                registros.map((registro) => {
                    let existe = registro.LINEAS.filter((item) => item.referencia.toUpperCase().includes(palabra.toUpperCase()) || item.referencia.toUpperCase() === palabra.toUpperCase())
                    if (existe.length > 0) {
                        resultadoFiltro.push(registro)
                    }
                })

                if (resultadoFiltro.length > 0) {
                    resultado = resultadoFiltro;
                } else {
                    resultado = null;
                }
            }
        } else {
            resultado = albarenesTallerAUX;
        }

        setAlbaranesTaller(resultado);

        return resultado;
    }

    const filtrarEnTabla = (palabra, registros) => {
        let resultado = []
        if (palabra !== "") {
            if (registros !== null) {
                let resultadoFiltro = registros.filter((item) => {
                    return item.CODIGO_REPARTO.toString().toUpperCase().includes(palabra.toUpperCase()) ||
                        item.FECHA_CREACION.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.ESTADO.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.DIRECCION_DESTINO_CALLE.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.DIRECCION_DESTINO_CIUDAD.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.DIRECCION_DESTINO_PROVINCIA.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.DIRECCION_DESTINO_CODIGO_POSTAL.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.ALBARANES.toUpperCase().includes(palabra.toUpperCase()) 

                })
                if (resultadoFiltro.length > 0) {
                    resultado = resultadoFiltro;
                } else {
                    resultado = null;
                }
            }
        } else {
            resultado = registros;
        }

        return resultado;
    }




    const actualizarCabeceraTabla = (e, valor) => {
        const CODIGO = e === 'CODIGO' ? valor : false;
        const FECHA = e === 'FECHA' ? valor : false
        const BASE_IMPONIBLE = e === 'BASE_IMPONIBLE' ? valor : false;
        const TOTAL_IVA = e === 'TOTAL_IVA' ? valor : false;
        const TOTAL = e === 'TOTAL' ? valor : false;

        setOrdenarPorTabla(
            {
                CODIGO: CODIGO,
                FECHA: FECHA,
                BASE_IMPONIBLE: BASE_IMPONIBLE,
                TOTAL_IVA: TOTAL_IVA,
                TOTAL: TOTAL
            }
        )
    }



    const ObtenerExpediciones = (INCLUIR_ENTREGADOS, ACCION_DESDE, ACCION_HASTA, FECHA_PARTIDA) => {
        setExpediciones([])
        ObtenerExpedicionesEnAPI(INCLUIR_ENTREGADOS, ACCION_DESDE, ACCION_HASTA, FECHA_PARTIDA).then((expediciones) => {
            if (expediciones !== undefined && expediciones !== null && expediciones.REGISTROS !== null && expediciones.REGISTROS.length > 0) {
                setExpediciones(expediciones.REGISTROS)
            } else {
                setExpediciones(null)
            }
        })
    }


    if (!hayReferencias && props.ABRIR_FICHA) {
        setHayReferencias(true);
        let fechaLocal = obtenerFechaLocalObjeto(false);

        let anios = [];
        for (let i = 2021, len = 2022; i <= len; i++) {
            anios.push(i)
        }

        setAnios(anios);
        setAnioSeleccionado(fechaLocal.ANIO)
        setMesSeleccionado(convertirCodigoAMeses(fechaLocal.MES))

        const FECHA_INICIAL = construirFecha(fechaLocal.MES, fechaLocal.ANIO.toString(), { DESDE: 4, HASTA: 5 })
        filtroFecha.ACCION_DESDE = 4;
        filtroFecha.ACCION_HASTA = 5;
        filtroFecha.FECHA_PARTIDA = FECHA_INICIAL
        setFiltroFecha({ ...filtroFecha })

        ObtenerExpediciones(filtroFecha.INCLUIR_ENTREGADOS, 0, 0, "")
    } else {
        if (!props.ABRIR_FICHA && hayReferencias) {
            setHayReferencias(false);
        }
    }

    const filtroEntregados = (
        <Checkbox name="TAREAS_CERRADAS" checked={filtroFecha.INCLUIR_ENTREGADOS} onChange={(e) => {
            filtroFecha.INCLUIR_ENTREGADOS = e.target.checked;
            setFiltroFecha({ ...filtroFecha })

            ObtenerExpediciones(e.target.checked, 0, 0, "")

            //licenciasSertec(3, "D", false, e.target.checked, obtenerLicenciaLocal().ID_TALLER, null, filtroFecha.FECHA_PARTIDA, filtroFecha.ACCION_DESDE, filtroFecha.ACCION_HASTA);

        }} ><span style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>{"Incluir entregados"}</span> </Checkbox>
    );


    const registroExpediciones =  filtrarEnTabla(textoFiltroReferencia, expediciones);

    return (
        <div>
            <Drawer
                width={1200}
                onClose={() => {
                    if (props.TIPO === "MASTER") {
                        props.CERRAR.setState({ ABRIR_FICHA_TRAKING: false })
                    } else {
                        props.CERRAR_FICHA(false)
                    }

                }}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                closable={false}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>

                <div className="flex-container-Grow-Tercero">
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                            if (props.TIPO === "MASTER") {
                                props.CERRAR.setState({ ABRIR_FICHA_TRAKING: false })
                            } else {
                                props.CERRAR_FICHA(false)
                            }
                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>

                <div className="p-3" style={{ height: (configApp.height - 70).toString() + "px", overflow: 'auto' }}>

                    <div className="flex-container mdb-skin">
                        <div className="grid-container mb-3" style={{ backgroundColor: '#eee', marginRight: '10px' }}>
                            <div className="grid-item">
                                <input className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar")} style={{ backgroundColor: "transparent", width: '300px', border: '0px', height: '100%', color: '#000' }} name="txtInputPresupuesto" autoComplete="off" value={textoFiltroReferencia} onChange={(e) => { setTextoFiltroReferencia(e.target.value); filtrarRegistrosPorReferencia(e.target.value, albarenesTallerAUX) }} />
                            </div>

                            <div className="grid-item" >
                                <MDBBtn onClick={e => { createRipple(e); setTextoFiltroReferencia(""); filtrarRegistrosPorReferencia("", albarenesTallerAUX) }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: textoFiltroReferencia !== "" ? 'visible' : 'hidden' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                            </div>

                            <div className="grid-item barraLateral" ></div>

                            <div className="grid-item">
                                <MDBBtn onClick={e => { createRipple(e); }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                            </div>
                        </div>


                     
                     
                    </div>


                    {albarenesTaller !== undefined ?
                        registroExpediciones === null ?
                            <div style={{ textAlign: 'center', color: '#000' }}>
                                <h4>{traducirPagina("msgMotor")}</h4>
                            </div>
                            :
                            registroExpediciones.length > 0 ?

                                <div style={{ height: (configApp.height - 160).toString() + "px", overflowX: 'hidden', overflowY: 'auto', color: '#000' }}>
                                    <table style={{ border: '1px solid #d9d9d9', width: '100%' }}>
                                        <thead>
                                            <tr style={{ backgroundColor: '#e0e0e0' }}>
                                                <th style={{ width: '120px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}> {traducirPagina("Codigo")}  </th>
                                                <th style={{ width: '168px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}  > {traducirPagina("Fecha")} </th>
                                                <th style={{ width: '120px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}  > {traducirPagina("Estado")} </th>
                                                <th style={{ width: '250px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}  > {traducirPagina("Direccion_entrega")} </th>
                                                <th style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}  > {traducirPagina("Albaranes")} </th>

                                                <th style={{ width: '40px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }} > </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {registroExpediciones.map((registro, i) =>
                                                <tr key={registro.ID} style={{ backgroundColor: '#fff' }}>
                                                    <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }} title={registro.CODIGO_REPARTO}>{registro.CODIGO_REPARTO}</td>
                                                    <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }} title={registro.FECHA_CREACION}>{registro.FECHA_CREACION}</td>
                                                    <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{registro.ESTADO}</td>
                                                    <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>
                                                        <div>
                                                            {`${registro.DIRECCION_DESTINO_CALLE}`}
                                                        </div>
                                                        <div>
                                                            {`${registro.DIRECCION_DESTINO_CIUDAD}, ${registro.DIRECCION_DESTINO_PROVINCIA} ${registro.DIRECCION_DESTINO_CODIGO_POSTAL}`}
                                                        </div>
                                                    </td>
                                                    <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{`${registro.ALBARANES.replaceAll(";", " | ")}`}</td>


                                                    <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }}>
                                                        <div className="flex-container-Grow-Simple">
                                                            <div style={{ margin: '0px 2px' }} >
                                                                <button title={"Dar de baja"} onClick={() => {

                                                                    setABRIR_FICHA_TRACKING(true)
                                                                    setUrlExpedicion(`https://app.vonzu.es/find-expedition?domain=serca&reference=${registro.CODIGO_REPARTO}&postalCode=${registro.DIRECCION_DESTINO_CODIGO_POSTAL}`)
                                                                }}
                                                                    className="btn-matricula  estiloBotonRemove paddindDeTres">
                                                                    <EnvironmentOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div style={{ fontSize: '16px', fontWeight: '500', color: "#000" }} className="row">
                                    <MDBCol md="9">
                                        <div>
                                            <span className="skeleton-box" style={{ width: '800px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '800px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '700px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '700px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '600px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '600px' }}></span>
                                        </div>
                                    </MDBCol>
                                </div>
                        : null}

                </div>


            </Drawer>


            {
                ABRIR_FICHA_TRACKING ?
                    <FichaTracking
                        title={traducirPagina("Traking_reparto").toUpperCase()}
                        ABRIR_FICHA={ABRIR_FICHA_TRACKING}
                        CERRAR_FICHA={setABRIR_FICHA_TRACKING}
                        URL={urlExpedicion}
                    >
                    </FichaTracking>
                    :
                    null
            }
        </div>

    )
}

Traking.propTypes = {
    TIPO: PropTypes.string,
    CERRAR: PropTypes.object,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    VISCOSIDAD: PropTypes.string
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,

    };
}

export default connect(mapStateToProps)(Traking);