import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createRipple, DrawerFichaMantenimientoPresupuesto, DrawerFichaOrden, DrawerFichaImpresionDocumento } from "../action/actionCreators"
import { getOrdenesReparacionTallerEnAPI, setOrdenesReparacionTallerEnAPI, ObtenerOrdenReparacionHTMLEnAPI, ObtenerOrdenReparacionPDFEnAPI, OrdenReparacionToFacturaEnAPI, DuplicarOrdenReparacionEnAPI, ObtenerFacturaPDFEnAPI, ObtenerFacturaHTMLEnAPI, ObtenerFormulariosOrHTMLEnAPI, ObtenerFormulariosOrPDFEnAPI,ObtenerPresupuestoHTMLEnAPI,ObtenerPresupuestoPDFEnAPI } from "../action/actionCreatorsRequests"
import { MSGOK, efectoRemoveBuscadorReferencia, convertirMoneda, obtenerFechaLocal, descargarPdfPasandoBase64, traducirPagina, buscarVehiculoLocal } from '../librerias/funciones'
import { MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBDropdown, MDBDropdownToggle, MDBDropdownItem, MDBDropdownMenu } from 'mdbreact';
import FichaImpresionGenerico from './FichaImpresionGenerico';
import MantenimientoOrdenTaller from './MantenimientoOrdenTaller';
import FichaTrazabilidad from '../components/FichaTrazabilidad'
import FichaDuplicarDocumento from '../components/FichaDuplicarDocumento'
import PagarFactura from './PagarFactura';

import {
    PlusOutlined,
    CloseOutlined,
    SearchOutlined,
    EditOutlined,
    DeleteOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined,
    PrinterOutlined,
    SnippetsFilled,
    CopyOutlined,
    FileDoneOutlined,
    FieldTimeOutlined
} from '@ant-design/icons';
import { Select, Card, Drawer, Modal, Button } from 'antd';
import store from "../store";
const { Option } = Select;
const FichaOrdenReparacionTaller = ({ props, configApp, openDrawerFichaOrdenTaller, HISTORIAL_VEHICULO, openDrawerFichaAlbaranesTaller }) => {
    const [ordenarPorCodigo, setOrdenarPorCodigo] = React.useState(false);
    const [ordenarPorFecha, setOrdenarPorFecha] = React.useState(false);
    const [hayRegistros, setHayRegistros] = React.useState(false);
    const [ordenesTaller, setOrdenesTaller] = React.useState([]);
    const [ordenesTallerAUX, setOrdenesTallerAUX] = React.useState([]);
    const [fechaLocalDesde, setFechaLocalDesde] = React.useState("");
    const [fechaLocalHasta, setFechaLocalHasta] = React.useState("");

    const [ABRIR_FICHA_IMPRESION, setABRIR_FICHA_IMPRESION] = React.useState(false);
    const [DOC_HTML, setDOC_HTML] = React.useState("");
    const [registroSeleccionado, setRegistroSeleccionado] = React.useState({});
    const [abrirFichaMantenimiento, setAbrirFichaMantenimiento] = React.useState(false);
    const [estadoSeleccionado, setEstadoSeleccionado] = React.useState("TODOS");

    const [fichaTrazabilidad, setFichaTrazabilidad] = React.useState(false);
    const [documentoSeleccionado, setDocumentoSeleccionado] = React.useState({});
    const [idDocTrazabilidad, setIdDocTrazabilidad] = React.useState({});
    const [fichaDuplicarDocumento, setFichaDuplicarDocumento] = React.useState(false);
    const [accion, setAccion] = React.useState("");

    const [abrirModalVehiculo, setAbrirModalVehiculo] = React.useState(false);
    const [VEHICULO_LOCAL, setVEHICULO_LOCAL] = React.useState({});

    const [abrirPagoFactura, setAbrirPagoFactura] = React.useState(false);


    const crearDocumento = async () => {
        store.dispatch({ type: "UPDATE_CLIENTE_SELECCIONADO_PARA_PRESUPUESTO_TALLER", cliente: {} })

        let VEHICULO = await buscarVehiculoLocal();
        VEHICULO.HISTORIAL_VEHICULO = HISTORIAL_VEHICULO
        const { VEHICULO_TECDOC } = VEHICULO;



        if (Object.keys(VEHICULO_TECDOC).length > 0) {
            setAbrirModalVehiculo(true);
            setVEHICULO_LOCAL(VEHICULO)
        } else {
            setAbrirFichaMantenimiento(true);
            setRegistroSeleccionado({})
        }
    }

    const obtenerRegistros = (ID, ORDERCOLUM, ORDERDIR, DESDE, HASTA, ESTADO) => {
        setOrdenesTaller([])
        setOrdenesTallerAUX([])
        getOrdenesReparacionTallerEnAPI(ID, ORDERCOLUM, ORDERDIR, DESDE, HASTA, (ESTADO !== "TODOS" ? ESTADO : "")).then((orden) => {
            if (orden !== undefined) {
                if (orden.ORDENESREPARACION !== null) {
                    setOrdenesTaller(orden.ORDENESREPARACION)
                    setOrdenesTallerAUX(orden.ORDENESREPARACION)

                } else {
                    setOrdenesTaller(null)
                    setOrdenesTallerAUX(null)
                }
            }
        })
    }


    if (!hayRegistros && openDrawerFichaOrdenTaller) {
        setHayRegistros(true);
        let obtenerFechaLocalDesde = obtenerFechaLocal(true)
        let obtenerFechaLocalHasta = obtenerFechaLocal(false)
        setFechaLocalDesde(obtenerFechaLocalDesde)
        setFechaLocalHasta(obtenerFechaLocalHasta)

        obtenerRegistros("", 2, "D", obtenerFechaLocalDesde, obtenerFechaLocalHasta, estadoSeleccionado)


        if (openDrawerFichaAlbaranesTaller) {
            setTimeout(() => {
                crearDocumento();
            }, 500)
        }

    } else {
        if (!openDrawerFichaOrdenTaller && hayRegistros) {
            setHayRegistros(false);
            setOrdenesTaller([])
            setOrdenesTallerAUX([])
            setRegistroSeleccionado({})
            setIdDocTrazabilidad({})

        }
    }

    const ordenarTablaCliente = (ID, ORDERCOLUM, ORDERDIR) => {
        obtenerRegistros("", ORDERCOLUM, ORDERDIR, fechaLocalDesde, fechaLocalHasta, estadoSeleccionado)
    }

    const eliminarDocumento = (payload) => {
        setOrdenesReparacionTallerEnAPI(2, payload).then((respuesta) => {
            if (respuesta.OK) {
                MSGOK("Orden eliminado correctamente.")
                let filteredItems = ordenesTaller.filter(item => item.ID !== payload.ID)

                if (filteredItems.length > 0) {
                    setOrdenesTaller(filteredItems)
                    setOrdenesTallerAUX(filteredItems)
                } else {
                    setOrdenesTaller(null)
                    setOrdenesTallerAUX([])
                }
            }
        })
    }


    const verificarFechas = (desde, hasta) => {

        let dateDesde = new Date(desde);
        let dateHasta = new Date(hasta);

        setOrdenesTaller([])
        if (dateDesde < dateHasta) {
            setFechaLocalDesde(desde)
            setFechaLocalHasta(hasta)

            obtenerRegistros("", 2, "D", desde, hasta, estadoSeleccionado)


        }

    }


    const buscarEnTabla = (palabra, listaCliente) => {
        if (palabra !== "") {
            if (listaCliente !== null) {
                let resultadoFiltro = listaCliente.filter((item) => {
                    return item.CODIGO.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.FECHA.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.VEH_MATRICULA.toUpperCase().includes(palabra.toUpperCase()) ||

                        item.CLI_NOMBRE.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.IMPORTE_TOTAL.toString().toUpperCase().includes(palabra.toUpperCase()) ||
                        item.ESTADO.toUpperCase().includes(palabra.toUpperCase())
                })

                if (resultadoFiltro.length > 0) {
                    setOrdenesTaller(resultadoFiltro);
                } else {
                    setOrdenesTaller(null);
                }
            }
        } else {
            setOrdenesTaller(ordenesTallerAUX);
        }
    }


    const buscarDocumento = (ID, TIPO_DOCUMENTO) => {
        if (TIPO_DOCUMENTO === "OR") {
            ObtenerOrdenReparacionHTMLEnAPI(ID).then((documento) => {
                if (documento !== undefined) {
                    if (documento.HTML !== null && documento.HTML !== "") {
                        setDOC_HTML(documento.HTML)
                    } else {
                        setDOC_HTML("")
                    }
                }
            })
        } else {
            ObtenerFormulariosOrHTMLEnAPI(ID, TIPO_DOCUMENTO).then((documento) => {
                if (documento !== undefined) {
                    if (documento.HTML !== null && documento.HTML !== "") {
                        setDOC_HTML(documento.HTML)
                    } else {
                        setDOC_HTML("")
                    }
                }
            })
        }
    }


    const buscarDocumentoPDF = () => {
        if(idDocTrazabilidad.TIPO_DOCUMENTO === "PRESUPUESTO" ){

            ObtenerPresupuestoPDFEnAPI(idDocTrazabilidad.ID).then((doc) => {
                if (doc !== undefined) {
                    if (doc !== "" && doc !== null) {
                        descargarPdfPasandoBase64(doc, "Presupuesto")
    
                    }
                }
            })
        }else if (Object.keys(idDocTrazabilidad).length > 0 && (idDocTrazabilidad.TIPO_DOCUMENTO === "FACTURA VENTA" || idDocTrazabilidad.TIPO_DOCUMENTO === "FACTURA")) {

            ObtenerFacturaPDFEnAPI(idDocTrazabilidad.ID).then((documento) => {
                if (documento !== undefined) {
                    if (documento.BYTES !== null && documento.BYTES !== "") {
                        descargarPdfPasandoBase64(documento.BYTES, "FACTURA")
                    }
                }
            })

        } else if (idDocTrazabilidad.TIPO_DOCUMENTO === "ORDEN REPARACION" || idDocTrazabilidad.TIPO_DOCUMENTO === "ORDEN REPARACIÓN") {
            ObtenerOrdenReparacionPDFEnAPI(idDocTrazabilidad.ID).then((documento) => {
                if (documento !== undefined) {
                    if (documento.BYTES !== null && documento.BYTES !== "") {
                        descargarPdfPasandoBase64(documento.BYTES, "ORDEN_DE_REPARACION")
                    }
                }
            })
        } else {
            ObtenerFormulariosOrPDFEnAPI(idDocTrazabilidad.ID, idDocTrazabilidad.TIPO_DOCUMENTO).then((documento) => {
                if (documento !== undefined) {
                    if (documento.BYTES !== null && documento.BYTES !== "") {
                        descargarPdfPasandoBase64(documento.BYTES, "ORDEN_DE_REPARACION_" + idDocTrazabilidad.TIPO_DOCUMENTO + "_")
                    }
                }
            })
        }
    }


    const pasarDocumento = (ID_DOCUMENTO, ID_CLIENTE, ID_VEHICULO, FECHA) => {
        setFichaDuplicarDocumento(false);
        OrdenReparacionToFacturaEnAPI(ID_DOCUMENTO, ID_CLIENTE, ID_VEHICULO, FECHA).then(documento => {
            if (documento !== undefined) {
                if (documento.OK) {
                    MSGOK("Documento enviado correctamente.")

                    obtenerRegistros("", 2, "D", fechaLocalDesde, fechaLocalHasta, estadoSeleccionado)
                }
            }
        })
    }

    const duplicarDocumento = (ID_DOCUMENTO, ID_CLIENTE, ID_VEHICULO, FECHA) => {
        setFichaDuplicarDocumento(false);
        DuplicarOrdenReparacionEnAPI(ID_DOCUMENTO, ID_CLIENTE, ID_VEHICULO, FECHA).then(documento => {
            if (documento !== undefined) {
                if (documento.OK) {
                    MSGOK("Documento duplicado correctamente.")

                    obtenerRegistros("", 2, "D", fechaLocalDesde, fechaLocalHasta, estadoSeleccionado)
                }
            }
        })
    }





    const abrirDocumetoTrazabilidad = (registro) => {
        setIdDocTrazabilidad({ TIPO_DOCUMENTO: registro.TIPO_DOCUMENTO, ID: registro.ID })

        if (registro.TIPO_DOCUMENTO === "PRESUPUESTO") {
            /*
            store.dispatch({ type: "UPDATE_PRESUPUESTO_ID_TALLER", id: registro.ID });
            store.dispatch(DrawerFichaImpresionDocumento(true));
*/
            setABRIR_FICHA_IMPRESION(true)

            ObtenerPresupuestoHTMLEnAPI(registro.ID).then((doc) => {
                if (doc !== undefined) {
                    if (doc !== null || doc !== "") {
                        setDOC_HTML(doc)
                    } else {
                        setDOC_HTML("")
                    }
                }
            })


        } else if (registro.TIPO_DOCUMENTO === "ORDEN REPARACION" || registro.TIPO_DOCUMENTO === "ORDEN REPARACIÓN") {
            setABRIR_FICHA_IMPRESION(true)

            ObtenerOrdenReparacionHTMLEnAPI(registro.ID).then((documento) => {
                if (documento !== undefined) {
                    if (documento.HTML !== null && documento.HTML !== "") {
                        setDOC_HTML(documento.HTML)
                    } else {
                        setDOC_HTML("")
                    }
                }
            })
        } else {
            setABRIR_FICHA_IMPRESION(true)
            ObtenerFacturaHTMLEnAPI(registro.ID).then((documento) => {
                if (documento !== undefined) {
                    if (documento.HTML !== null && documento.HTML !== "") {
                        setDOC_HTML(documento.HTML)
                    } else {
                        setDOC_HTML("")
                    }
                }
            })
        }
    }



    const IMPRESORA_LOCAL = async () => {
       
    }


    return (
        <div>
            <Drawer
                width={1300}
                closable={false}
                onClose={() => { store.dispatch(DrawerFichaOrden(false)); }}
                visible={openDrawerFichaOrdenTaller}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff', zIndex: 1000 }}>


                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                            onClick={(e) => {
                                store.dispatch(DrawerFichaOrden(false));
                                createRipple(e)

                            }}>
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>


                <div className="p-3" style={{ height: (configApp.height - 70).toString() + "px", overflow: 'auto' }}>
                    <div className="flex-container mdb-skin">

                        <div className="grid-container mb-3" style={{ backgroundColor: '#eee' }}>
                            <div className="grid-item">
                                <input className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_orden")} style={{ backgroundColor: "transparent", width: '300px', border: '0px', height: '100%', color: '#000' }} autoComplete="off" onChange={(e) => { buscarEnTabla(e.target.value, ordenesTallerAUX) }} />
                            </div>

                            <div className="grid-item" >
                                <MDBBtn id="closeBuscadorPresupuestoTaller" onClick={e => { createRipple(e); efectoRemoveBuscadorReferencia("buscadorPresupuestoTaller", true); }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: 'hidden' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                            </div>


                            <div className="grid-item barraLateral" ></div>

                            <div className="grid-item">
                                <MDBBtn onClick={e => { createRipple(e); }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                            </div>
                        </div>


                        <div style={{ backgroundColor: 'transparent', color: '#000', marginTop: '5px', marginLeft: '10px' }}>
                            {openDrawerFichaOrdenTaller ?
                                <Select
                                    placeholder={traducirPagina("Filtrar_por_estado").toUpperCase()}
                                    style={{ width: "200px", textAlign: 'left' }}
                                    optionFilterProp="children"
                                    onChange={(estado) => {
                                        setEstadoSeleccionado(estado); obtenerRegistros("", 2, "D", fechaLocalDesde, fechaLocalHasta, estado)
                                    }}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value={"TODOS"}>{traducirPagina("Todos").toUpperCase()}</Option>
                                    <Option value={"PEN"}>{traducirPagina("Pendiente").toUpperCase()}</Option>
                                    <Option value={"INI"}>{traducirPagina("Iniciada").toUpperCase()}</Option>
                                    <Option value={"FIN"}>{traducirPagina("Finalizada").toUpperCase()}</Option>
                                    <Option value={"FAC"}>{traducirPagina("Facturada").toUpperCase()}</Option>

                                </Select>
                                :
                                null
                            }
                        </div>



                        <div className="grid-item" style={{ backgroundColor: 'transparent', color: '#000', marginTop: '5px', marginLeft: '10px' }}>
                            <input type="date" value={fechaLocalDesde} onChange={(e) => setFechaLocalDesde(e.target.value)}></input>
                        </div>

                        <div className="grid-item" style={{ backgroundColor: 'transparent', color: '#000', marginTop: '5px', marginLeft: '10px' }}>
                            <input type="date" value={fechaLocalHasta} onChange={(e) => { setFechaLocalHasta(e.target.value); }} ></input>
                        </div>

                        <div>
                            <button className="activebtnMenu2 btnEstiloPersonalizado" style={{ height: '40px', width: '100px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#fff', backgroundColor: '#transparent', cursor: 'pointer' }} onClick={() => {
                                verificarFechas(fechaLocalDesde, fechaLocalHasta)
                            }
                            }><SearchOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Filtrar")} </span>  </button>
                        </div>

                        <div>

                            <button className="activebtnMenu2 btnEstiloPersonalizado" style={{ height: '40px', width: '180px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#fff', backgroundColor: 'transparent', cursor: 'pointer' }}
                                onClick={() => {
                                    //setAbrirFichaMantenimiento(true);
                                    //setRegistroSeleccionado({})

                                    crearDocumento();
                                }

                                }><PlusOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Nueva_orden")} </span>  </button>
                        </div>
                    </div>

                    <Card style={{ height: (configApp.height - 170).toString() + "px" }} className="mb-2 mdb-skin" >
                        {ordenesTaller !== undefined ?
                            ordenesTaller === null ?
                                <div style={{ textAlign: 'center' }}>
                                    <h4>{traducirPagina("No_resultados")}</h4>
                                </div>
                                :
                                ordenesTaller.length > 0 ?
                                    <div>
                                        <div style={{ fontSize: '16px', fontWeight: '500', color: "#000", height: (configApp.height - 220).toString() + "px", overflow: 'auto' }} className="row">
                                            <MDBTable responsive hover >
                                                <MDBTableHead>
                                                    <tr>
                                                        <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '110px', verticalAlign: 'baseline', cursor: 'pointer' }} onClick={() => { ordenarTablaCliente("", 1, ordenarPorCodigo ? "A" : "D"); setOrdenarPorCodigo(ordenarPorCodigo ? false : true); setOrdenarPorFecha(false); }} >
                                                            {traducirPagina("Codigo")} {ordenarPorCodigo ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}
                                                        </th>
                                                        <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '100px', verticalAlign: 'baseline', cursor: 'pointer' }} onClick={() => { ordenarTablaCliente("", 2, ordenarPorFecha ? "A" : "D"); setOrdenarPorFecha(ordenarPorFecha ? false : true); setOrdenarPorCodigo(false); }}>{traducirPagina("Fecha")}{ordenarPorFecha ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />} </th>
                                                        <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '120px', verticalAlign: 'baseline', textAlign: 'center' }} >{traducirPagina("Matricula")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", verticalAlign: 'baseline' }}>{traducirPagina("Cliente")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '120px', verticalAlign: 'baseline', textAlign: 'right' }}>{traducirPagina("Total")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '65px', verticalAlign: 'baseline', textAlign: 'center' }}>{traducirPagina("Estado")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '100px', verticalAlign: 'baseline' }}></th>
                                                    </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                    {ordenesTaller.map((orden, i) =>
                                                        <tr key={orden.ID}>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{orden.CODIGO}</td>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{orden.FECHA}</td>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle', textAlign: 'center' }}>{orden.VEH_MATRICULA}</td>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{orden.CLI_NOMBRE}</td>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle', textAlign: 'right' }}>{convertirMoneda(orden.IMPORTE_TOTAL, "€")}</td>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle', textAlign: 'center' }}>{orden.ESTADO}</td>
                                                            <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>

                                                                <div className="flex-container-Grow-Simple">

                                                                    <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                        <MDBBtn title={traducirPagina("Pasar_factura")} onClick={() => {
                                                                            setFichaDuplicarDocumento(true)
                                                                            setDocumentoSeleccionado(orden)
                                                                            setAccion("PASAR")

                                                                        }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                            <FileDoneOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                        </MDBBtn>
                                                                    </div>

                                                                    <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                        <MDBBtn title={traducirPagina("Duplicar_OR")} onClick={() => {
                                                                            setFichaDuplicarDocumento(true)
                                                                            setDocumentoSeleccionado(orden)
                                                                            setAccion("DUPLICAR")

                                                                        }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                            <CopyOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                        </MDBBtn>
                                                                    </div>

                                                                    <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                        <MDBBtn title={traducirPagina("Trazabilidad_OR")} onClick={() => {
                                                                            setDocumentoSeleccionado(orden)
                                                                            setFichaTrazabilidad(true)

                                                                        }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                            <FieldTimeOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                        </MDBBtn>
                                                                    </div>


                                                                    <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                        <MDBBtn title={traducirPagina("Editar_OR")} onClick={() => {
                                                                            if (orden.ES_MODIFICABLE) {
                                                                                setAbrirFichaMantenimiento(true);
                                                                                setRegistroSeleccionado(orden)
                                                                            }

                                                                        }}
                                                                            className="btn-matricula  estiloBotonRemove paddindDeTres">
                                                                            <EditOutlined style={{ color: orden.ES_MODIFICABLE ? '#333' : '#999', cursor: 'pointer', fontSize: '20px' }} />
                                                                        </MDBBtn>
                                                                    </div>

                                                                    <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                        <MDBBtn title={traducirPagina("Eliminar_OR")} onClick={() => {
                                                                            if (orden.ES_ELIMINABLE) {
                                                                                eliminarDocumento(orden)
                                                                            }

                                                                        }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                            <DeleteOutlined style={{ color: orden.ES_ELIMINABLE ? '#333' : '#999', cursor: 'pointer', fontSize: '20px' }} />
                                                                        </MDBBtn>
                                                                    </div>


                                                                    <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                        <MDBDropdown>
                                                                            <MDBDropdownToggle nav style={{}}>
                                                                                <button title={traducirPagina("Imprimir_OR")} onClick={() => {
                                                                                }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                                    <PrinterOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                                </button>

                                                                            </MDBDropdownToggle>

                                                                            <MDBDropdownMenu right>

                                                                                <MDBDropdownItem onClick={() => {
                                                                                    setRegistroSeleccionado(orden);
                                                                                    setABRIR_FICHA_IMPRESION(true)
                                                                                    buscarDocumento(orden.ID, "OR")
                                                                                    setIdDocTrazabilidad({ TIPO_DOCUMENTO: "ORDEN REPARACION", ID: orden.ID })


                                                                                }}>{traducirPagina("Orden_de_reparacion")}</MDBDropdownItem>

                                                                                <MDBDropdownItem onClick={() => {
                                                                                    setRegistroSeleccionado(orden);
                                                                                    setABRIR_FICHA_IMPRESION(true)
                                                                                    buscarDocumento(orden.ID, "FR")
                                                                                    setIdDocTrazabilidad({ TIPO_DOCUMENTO: "FR", ID: orden.ID })

                                                                                }}>{traducirPagina("Formulario_de_recepcion")}</MDBDropdownItem>

                                                                                <MDBDropdownItem onClick={() => {
                                                                                    setRegistroSeleccionado(orden);
                                                                                    setABRIR_FICHA_IMPRESION(true)
                                                                                    buscarDocumento(orden.ID, "RA")
                                                                                    setIdDocTrazabilidad({ TIPO_DOCUMENTO: "RA", ID: orden.ID })


                                                                                }}>{traducirPagina("Formulario_de_recepcion_activa")}</MDBDropdownItem>
                                                                                <MDBDropdownItem onClick={() => {
                                                                                    setRegistroSeleccionado(orden);
                                                                                    setABRIR_FICHA_IMPRESION(true)
                                                                                    buscarDocumento(orden.ID, "RD")
                                                                                    setIdDocTrazabilidad({ TIPO_DOCUMENTO: "RD", ID: orden.ID })

                                                                                }}>{traducirPagina("Resguardo_de_deposito")}</MDBDropdownItem>

                                                                            </MDBDropdownMenu>
                                                                        </MDBDropdown>
                                                                    </div>

                                                                </div>
                                                            </td>

                                                        </tr>
                                                    )}
                                                </MDBTableBody>
                                            </MDBTable>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ fontSize: '16px', fontWeight: '500', color: "#000" }} className="row">
                                        <MDBCol md="9">
                                            <div>
                                                <span className="skeleton-box" style={{ width: '900px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '900px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '800px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '800px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '800px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '800px' }}></span>
                                            </div>
                                        </MDBCol>
                                    </div>
                            : null}
                    </Card>
                </div>
            </Drawer>

            {ABRIR_FICHA_IMPRESION ?
                <FichaImpresionGenerico
                    title={traducirPagina("Imprimir_documento").toUpperCase()}
                    ABRIR_FICHA={ABRIR_FICHA_IMPRESION}
                    CERRAR_FICHA={setABRIR_FICHA_IMPRESION}
                    DOCUMENTO={DOC_HTML}
                    MODIFICAR_DOCUMENTO={setDOC_HTML}
                    DESCARGAR_DOCUMENTO={buscarDocumentoPDF}
                >
                </FichaImpresionGenerico>
                :
                null
            }

            {abrirFichaMantenimiento ?
                <MantenimientoOrdenTaller
                    title={traducirPagina("Ficha_OR").toUpperCase()}
                    DOCUMENTO_SELECCIONADO={registroSeleccionado}
                    MODIFICAR_DOCUMENTO_SELECCIONADO={setRegistroSeleccionado}


                    ABRIR_FICHA={abrirFichaMantenimiento}
                    CERRAR_FICHA={setAbrirFichaMantenimiento}
                    GUARDAR_DOCUMENTO={obtenerRegistros}
                    FECHA_LOCAL={{ DESDE: fechaLocalDesde, HASTA: fechaLocalHasta }}
                    VEHICULO_LOCAL={VEHICULO_LOCAL}

                >

                </MantenimientoOrdenTaller>

                :
                null}


            {
                fichaTrazabilidad ?
                    <FichaTrazabilidad
                        title={traducirPagina("Trazabilidad_del_documento").toUpperCase()}
                        ID_DOCUMENTO={documentoSeleccionado.ID}
                        TIPO_DOCUMENTO={"OR"}
                        ABRIR_FICHA={fichaTrazabilidad}
                        CERRAR_FICHA={setFichaTrazabilidad}
                        ABRIR_IMPRESION={abrirDocumetoTrazabilidad}
                        ABRIR_FICHA_COBRO={setAbrirPagoFactura}

                    >

                    </FichaTrazabilidad>

                    :
                    null
            }

            {
                abrirPagoFactura ?
                    <PagarFactura
                        title={traducirPagina("Cobros_factura").toUpperCase()}
                        ABRIR_FICHA={abrirPagoFactura}
                        CERRAR_FICHA={setAbrirPagoFactura}
                        REGISTRO_SELECCIONAD0={{ID:documentoSeleccionado.ID_FACTURA,IMPORTE_TOTAL:0}}
                        ES_TRAZABILIDAD={fichaTrazabilidad}
                        IMPRESORA={IMPRESORA_LOCAL}

                    >
                    </PagarFactura>
                    :
                    null
            }

            {
                fichaDuplicarDocumento ?
                    <FichaDuplicarDocumento
                        title={accion === "DUPLICAR" ? traducirPagina("Duplicar_documento").toUpperCase() : traducirPagina("Pasar_documento").toUpperCase()}
                        DOCUMENTO={documentoSeleccionado}
                        TIPO_DOCUMENTO={"ORDEN REPARACION"}
                        ACCION={accion}
                        ABRIR_FICHA={fichaDuplicarDocumento}
                        CERRAR_FICHA={setFichaDuplicarDocumento}
                        ENVIAR={accion === "DUPLICAR" ? duplicarDocumento : pasarDocumento}
                    >
                    </FichaDuplicarDocumento>
                    :
                    null

            }


            {abrirModalVehiculo ?
                <Modal
                    visible={abrirModalVehiculo}
                    width={700}
                    centered
                    title={traducirPagina("FICHA_DEL_VEHÍCULO")}
                    afterClose={() => {
                        setAbrirFichaMantenimiento(true);
                        setRegistroSeleccionado({})

                        setAbrirModalVehiculo(false);
                        setVEHICULO_LOCAL({});
                    }}
                    onCancel={() => setAbrirModalVehiculo(false)}
                    zIndex={10000}
                    footer={[
                        <Button key="back" onClick={() => {
                            setAbrirFichaMantenimiento(true);
                            setRegistroSeleccionado({})

                            setAbrirModalVehiculo(false);
                            setVEHICULO_LOCAL({});
                        }}>
                              {traducirPagina("NO")}
                        </Button>,
                        <Button className="btnColor" key="submit" type="primary" onClick={() => {
                            setAbrirFichaMantenimiento(true);
                            setRegistroSeleccionado({})

                            setAbrirModalVehiculo(false);
                        }}> {traducirPagina("SI")}</Button>
                    ]}
                >

                    <h4 style={{ margin: '0px 0px 24px 0px' }}>{traducirPagina("Msg_presupuesto_actual")}</h4>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <tbody data-test="table-body">
                                <tr>
                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Matricula").toUpperCase()}</td>
                                    <td style={{ textAlign: 'left' }}>{VEHICULO_LOCAL.MATRICULA}</td>
                                </tr>

                                <tr>
                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Bastidor").toUpperCase()}</td>
                                    <td style={{ textAlign: 'left' }}>{VEHICULO_LOCAL.BASTIDOR}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Marca").toUpperCase()}</td>
                                    <td style={{ textAlign: 'left' }}>{VEHICULO_LOCAL.MARCA_VEHICULO}</td></tr>
                                <tr>
                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Modelo").toUpperCase()}</td>
                                    <td style={{ textAlign: 'left' }}>{VEHICULO_LOCAL.MODELO_VEHICULO}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Motor").toUpperCase()}</td>
                                    <td style={{ textAlign: 'left' }}>{VEHICULO_LOCAL.MOTOR_VEHICULO}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </Modal>
                :
                null
            }

        </div>
    )
}


FichaOrdenReparacionTaller.propTypes = {
    title: PropTypes.string,
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        openDrawerFichaOrdenTaller: state.estadoDrawer.openDrawerFichaOrdenTaller,
        HISTORIAL_VEHICULO: state.fichaBusquedaVehiculo.historicapp,
        openDrawerFichaAlbaranesTaller: state.estadoDrawer.openDrawerFichaAlbaranesTaller,


    };
}

export default connect(mapStateToProps)(FichaOrdenReparacionTaller);