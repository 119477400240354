import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCestaLineasEnAPI, ObtenerConversacionesEnAPI, ObtenerMensajesnAPI } from "../action/actionCreatorsRequests"

import { traducirPagina, referenciaActualSelect, convertirMoneda, redondeo, MSGOK, obtenerDatosUsuarioYLicenciaActual, MSGERROR } from '../librerias/funciones'
import { MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBInput } from 'mdbreact';

import { Select, Card, Modal, Button, Input, Row, Col, Drawer, Popover } from 'antd';
import FichaConversacionTelegram from './FichaConversacionTelegram';
import VisorGenerico from './Visor/VisorGenerico';

import {
    PlusOutlined,
    CloseOutlined,
    SearchOutlined,
    EditOutlined,
    DeleteOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined,
    PrinterOutlined,
    CopyOutlined,
    FileDoneOutlined,
    StarOutlined,
    FieldTimeOutlined,
    ShareAltOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,

} from '@ant-design/icons';

import store from "../store";
import { UserAddOutlined, UserOutlined } from '@ant-design/icons';
const { Option } = Select;

const Mensajes = (props) => {
    const [conversaciones, setconversaciones] = React.useState([]);
    const [abrirConversacion, setAbrirConversacion] = React.useState(false);
    const [conversacionTelegram, setconversacionTelegram] = React.useState([]);
    const [chatSeleccionado, setChatSeleccionado] = React.useState({});

    const [mesesDelAnio, setMesesDelAnio] = React.useState(
        [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
        ]
    );

    const [anios, setAnios] = React.useState([]);

    const [filtroFecha, setFiltroFecha] = React.useState({
        CAMBIO_DIA: true,
        CAMBIO_MES: false,
        CAMBIO_ANIO: false,
        ACCION_DESDE: 4,
        ACCION_HASTA: 5,
        FECHA_PARTIDA: ""
    });
    

    const obtenerConversacion = async (ID_CONVERSACION, FECHA) => {
        const registro = await ObtenerMensajesnAPI(ID_CONVERSACION, FECHA)
        if (registro !== undefined && registro.REGISTROS !== undefined && registro.REGISTROS !== null && registro.REGISTROS.length > 0) {
            setconversacionTelegram([...registro.REGISTROS])
        } else {
            setconversacionTelegram(null);
        }
        return registro;
    }



    const obtenerConversacionesTelegram = () => {
        ObtenerConversacionesEnAPI().then(registro => {
            if (registro !== undefined && registro.REGISTROS !== undefined && registro.REGISTROS !== null && registro.REGISTROS.length > 0) {
                setconversaciones(registro.REGISTROS)
            } else {
                setconversaciones(null);
            }
        })
    }


    const filtrarDatosLocales = (licencias, params, textoBuscador) => {
        let filtroLicencias = licencias !== undefined && licencias !== null ? [...licencias] : [];

        if (params.ESTADO_MENSAJE) {
            filtroLicencias = filtroLicencias.filter((item) => item.MENSAJES_PENDIENTES > 0 || item.MENSAJES_PENDIENTES === 0)

        } else {
            filtroLicencias = filtroLicencias.filter((item) => item.MENSAJES_PENDIENTES > 0)
        }


        if (textoBuscador !== "") {
            let resultado = []
            if (filtroLicencias !== null) {
                let resultadoFiltro = filtroLicencias.filter((item) => {
                    return item.CONTACTO.toString().toUpperCase().includes(textoBuscador.toUpperCase()) ||
                        item.TEXTO_ULTIMO_MENSAJE.toUpperCase().includes(textoBuscador.toUpperCase()) ||
                        item.FECHA_ULTIMO_MENSAJE.toUpperCase().includes(textoBuscador.toUpperCase())
                })
                if (resultadoFiltro.length > 0) {
                    resultado = resultadoFiltro;
                } else {
                    resultado = null;
                }
            }
            filtroLicencias = resultado
        }

        return filtroLicencias;
    }

    React.useEffect(() => {
        let TIENE_PERMISO = store.getState().usuario.LoginSertec !== undefined && store.getState().usuario.LoginSertec !== null && store.getState().usuario.LoginSertec.PERMISO_TELEGRAM ? true : false
        if (TIENE_PERMISO) {
            if (!abrirConversacion) {
                //obtenerConversacionesTelegram();
                const interval = setInterval(() => {
                    obtenerConversacionesTelegram();

                }, 2000);
                return () => clearInterval(interval);

            }
        }
    }, []);

    return <div>
        {filtrarDatosLocales(conversaciones, { ESTADO_MENSAJE: props.ESTADO_MENSAJE }, props.FILTRO_TEXTO) === null ?
            <h4 style={{ color: '#fff' }}>{traducirPagina("No_resultados")}</h4>
            :

            conversaciones !== undefined && conversaciones !== null && conversaciones.length > 0 ?
                filtrarDatosLocales(conversaciones, { ESTADO_MENSAJE: props.ESTADO_MENSAJE }, props.FILTRO_TEXTO).map((registro, i) =>
                    <div key={i} style={{ backgroundColor: '#4e4e4e', height: '60px', padding: '10px', position: 'relative', marginBottom: '10px', cursor: 'pointer' }} className={"efectoContainerTelegram"}
                        onClick={(e) => {
                            setAbrirConversacion(true)
                            obtenerConversacion(registro.ID, null);
                            setChatSeleccionado(registro)
                        }}>



                        {
                            registro.MENSAJES_PENDIENTES > 0 ?
                                <div style={{ left: 10, position: 'absolute', width: '30px', height: '30px', borderRadius: '50%', backgroundColor: '#33b5e5', color: '#fff', fontSize: '12px', textAlign: 'center', paddingTop: '5px' }}>
                                    {registro.MENSAJES_PENDIENTES}
                                </div>
                                :

                                <div style={{ left: 10, position: 'absolute', width: '30px', height: '30px', borderRadius: '50%', backgroundColor: '#3a3939', color: '#fff', fontSize: '12px', textAlign: 'center', paddingTop: '5px' }}>
                                    <UserOutlined ></UserOutlined>
                                </div>


                        }


                        <div style={{ left: 45, position: 'absolute' }}>
                            {registro.CONTACTO.split("|")[0]}
                        </div>

                        <div style={{ right: 10, position: 'absolute' }}>
                            {registro.FECHA_ULTIMO_MENSAJE}
                        </div>
                        <br />

                        <div style={{ left: 45, position: 'absolute' }}>
                            {registro.TEXTO_ULTIMO_MENSAJE}
                        </div>


                        <div style={{ right: 10, position: 'absolute', color: '#33b5e5' }}>

                            <Popover placement="topLeft" title="Cliente" content={<div dangerouslySetInnerHTML={{ __html: registro.CONTACTO.split("|")[1] }}></div>}>
                                {traducirPagina("Mas_info")}
                            </Popover>
                        </div>
                    </div>
                )
                :
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#fff' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>

        }

        {abrirConversacion ?
            <FichaConversacionTelegram
                title={"NOMBRE USUARIO"}
                ABRIR_FICHA={abrirConversacion}
                CERRAR_FICHA={setAbrirConversacion}
                CONVERSACION={conversacionTelegram}
                MODIFICAR_CONVERSACION={setconversacionTelegram}
                BUSCAR_CONVERSACIÓN={obtenerConversacion}
                DATOS_CHAT={chatSeleccionado}

                ABRIR_VISOR={props.ABRIR_VISOR}
                CERRAR_VISOR={props.CERRAR_VISOR}
                FICHERO_SELECCIONADO={props.FICHERO_SELECCIONADO}
            >
            </FichaConversacionTelegram>
            :
            null
        }
    </div>;
};




const FichaTelegram = ({ props, configApp }) => {
    const [hayRegistros, setHayRegistros] = React.useState(false);
    const [textoFiltro, setTextoFiltro] = React.useState("");
    const [estadoMensaje, setEstadoMensaje] = React.useState(true);



    const [abrirVisor, setAbrirVisor] = React.useState(false);
    const [ficheroSeleccionado, setFicheroSeleccionado] = React.useState({});


    if (!hayRegistros && props.ABRIR_FICHA) {
        setHayRegistros(true);

    } else {
        if (!props.ABRIR_FICHA && hayRegistros) {
            setHayRegistros(false);
        }
    }

    return (
        <div>
            <Drawer
                width={"490px"}
                closable={false}
                onClose={() => {

                    if (props.TIPO === "MASTER") {
                        props.CERRAR.setState({ ABRIR_FICHA_TELEGRAM: false })
                    } else {
                        props.CERRAR_FICHA(false)
                    }

                }}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ zIndex: '999', color: '#fff' }}>

                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <button className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                            onClick={(e) => {

                                if (props.TIPO === "MASTER") {
                                    props.CERRAR.setState({ ABRIR_FICHA_TELEGRAM: false })
                                } else {
                                    props.CERRAR_FICHA(false)
                                }

                            }}>
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </button>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>


                </div>

                <div className="ctSub_Menu_Telegram">
                    <div className='ctSub_Menu_Telegram_item_1'>
                        <div className="grid-container mdb-skin" style={{ backgroundColor: '#eee', width: '100%' }}>
                            <div className="grid-item" style={{ width: '90%' }}>
                                <input className="form-control" type="text" aria-label="Search" placeholder={"Buscar mensajes"} style={{ backgroundColor: "transparent", border: '0px', height: '100%', color: '#000' }} autoComplete="off" onChange={(e) => {
                                    setTextoFiltro(e.target.value.trim() !== "" ? e.target.value : "")
                                }} value={textoFiltro} />
                            </div>

                            <div className="grid-item">

                                <MDBBtn onClick={(e) => {
                                    setTextoFiltro("")
                                }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: textoFiltro === "" ? 'hidden' : 'visible' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                            </div>


                            <div className="grid-item barraLateral" ></div>

                            <div className="grid-item">
                                <MDBBtn onClick={e => { }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                            </div>
                        </div>
                    </div>

                    <div className='ctSub_Menu_Telegram_2'>
                        {estadoMensaje ?
                            <EyeOutlined title='Mensaje pendientes' onClick={() => {
                                setEstadoMensaje(!estadoMensaje)
                            }}></EyeOutlined>
                            :
                            <EyeInvisibleOutlined title='Todos los mensajes' onClick={() => {
                                setEstadoMensaje(!estadoMensaje)

                            }}></EyeInvisibleOutlined>
                        }
                    </div>
                </div>

                <div style={{ height: (configApp.height - 141).toString() + "px", overflowX: 'hidden', overflowY: 'auto', backgroundColor: '#3a3939', color: '#fff', padding: '20px' }}>
                    <Mensajes
                        FILTRO_TEXTO={textoFiltro}
                        ESTADO_MENSAJE={estadoMensaje}

                        ABRIR_VISOR={abrirVisor}
                        CERRAR_VISOR={setAbrirVisor}
                        FICHERO_SELECCIONADO={setFicheroSeleccionado}
                    ></Mensajes>
                </div>
            </Drawer>


            {abrirVisor ?
                <VisorGenerico
                    ABRIR_VISOR={abrirVisor}
                    CERRAR_VISOR={setAbrirVisor}
                    PROPIEDADES_DOC={ficheroSeleccionado}
                    MOSTRAR_BOTON_DESCARGA={true}

                >
                </VisorGenerico>
                :
                null
            }
        </div>
    )
}

FichaTelegram.propTypes = {
    title: PropTypes.string,
    TIPO: PropTypes.string,
    CERRAR: PropTypes.object,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func

};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp
    };
}

export default connect(mapStateToProps)(FichaTelegram);