import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createRipple, onChildrenDrawerCloseIntegraciones, DrawerCarroCompra, DrawerMasInformacion, DrawerFichaMantenimientoClienteTaller, DrawerFichaClienteTaller } from "../action/actionCreators"
import { getCestaLineasEnAPI, eliminarCestaLineasEnAPI, setCestaLineasEnAPI, vaciarCestaLineasEnAPI, getClienteTallerEnAPI, setClienteTallerEnAPI } from "../action/actionCreatorsRequests"
import { traducirPagina, referenciaActualSelect, convertirMoneda, redondeo, MSGOK, obtenerDatosUsuarioYLicenciaActual, MSGERROR, efectoRemoveBuscadorReferencia } from '../librerias/funciones'
import { MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBInput } from 'mdbreact';
import FichaMantenimientoVehiculoTaller from '../components/MantenimientoVehiculoTaller'
import FichaMantenimientoCliente from '../components/MantenimientoClienteTaller'

import {
    PlusOutlined,
    CloseOutlined,
    SearchOutlined,
    EditOutlined,
    DeleteOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined,
    ImportOutlined,
    CloudUploadOutlined
} from '@ant-design/icons';

import { Select, Card, Modal, Button, Input, Row, Col, Drawer } from 'antd';

import store from "../store";
import FichaMasivaCliente from './Cliente/FichaMasivaCliente';
const { Option } = Select;
const FichaClienteTaller = ({ props, configApp, openDrawerFichaClienteTaller, clienteTallerSeleccionado, openDrawerFichaMantenimientoVehiculo }) => {
    const [ordenarPorCodigo, setOrdenarPorCodigo] = React.useState(false);
    const [ordenarPorNombre, setOrdenarPorNombre] = React.useState(false);
    const [ordenarPorCIF, setOrdenarPorCIF] = React.useState(false);
    const [hayClientes, setHayClientes] = React.useState(false);
    const [clientesTaller, setClientesTaller] = React.useState([]);
    const [obtenerClientesAux, setObtenerClientesAux] = React.useState([]);
    const [actualizarClientes, setActualizarClientes] = React.useState(false);
    const [textoFiltroCliente, setTextoFiltroCliente] = React.useState("");
    const [mantenimientoCliente, setMantenimientoCliente] = React.useState(false);
    const [masivaCliente, setMasivaCliente] = React.useState(false);


    const buscarRegistros = () => {
        getClienteTallerEnAPI("", 1, "A").then((cli) => {
            if (cli.CLIENTES !== null) {
                setClientesTaller(cli.CLIENTES)
                setObtenerClientesAux(cli.CLIENTES)
                setHayClientes(true);
            } else {
                setClientesTaller(null)
                setObtenerClientesAux(null)
                setHayClientes(true);
            }
        })
    }

    if (!hayClientes && openDrawerFichaClienteTaller) {
        setHayClientes(true);
        buscarRegistros()
    } else {
        if (!openDrawerFichaClienteTaller && hayClientes) {
            setTextoFiltroCliente("")
            setHayClientes(false);
            setClientesTaller([])
            setObtenerClientesAux([])
        }
    }

    if (!actualizarClientes && clienteTallerSeleccionado.actualizarListaCliente) {
        store.dispatch({ type: "UPDATE_LISTA_TALLER_CLIENTE", actualizarListaCliente: false })
        setActualizarClientes(true);

        if (clienteTallerSeleccionado.esNuevoCliente) {
            getClienteTallerEnAPI("", 1, "A").then((cli) => {
                if (cli.CLIENTES !== null) {
                    setClientesTaller(cli.CLIENTES)
                    setObtenerClientesAux(cli.CLIENTES)
                    setOrdenarPorCodigo(false)
                    setOrdenarPorNombre(false)
                    setOrdenarPorCIF(false)
                }
            })
        } else {
            let modificarCliente = clienteTallerSeleccionado.clienteTaller;
            clientesTaller.map((item, index) => {
                if (item.ID == modificarCliente.id) {
                    clientesTaller[index].CODIGO = modificarCliente.codigo;
                    clientesTaller[index].CIF = modificarCliente.cif;
                    clientesTaller[index].CP = modificarCliente.cp;
                    clientesTaller[index].DIRECCION = modificarCliente.direccion;
                    clientesTaller[index].ID = modificarCliente.id;
                    clientesTaller[index].MAIL = modificarCliente.email;
                    clientesTaller[index].NOMBRE = modificarCliente.nombre;
                    clientesTaller[index].OBSERVACIONES = modificarCliente.observacion;
                    clientesTaller[index].PAIS = modificarCliente.pais;
                    clientesTaller[index].POBLACION = modificarCliente.poblacion;
                    clientesTaller[index].PROVINCIA = modificarCliente.provincia;
                    clientesTaller[index].TELEFONO = modificarCliente.telefono1;
                    clientesTaller[index].TELEFONO_2 = modificarCliente.telefono2;
                    clientesTaller[index].TELEFONO_3 = modificarCliente.telefono3
                }
            })
            setClientesTaller(clientesTaller)
            setObtenerClientesAux(clientesTaller)
        }

        store.dispatch({ type: "UPDATE_CLIENTE_TALLER", clienteTaller: {} })
    } else {
        if (!clienteTallerSeleccionado.actualizarListaCliente && actualizarClientes) {
            setActualizarClientes(false);
            setOrdenarPorCodigo(false)
            setOrdenarPorNombre(false)
            setOrdenarPorCIF(false)
        }
    }


    const ordenarTablaCliente = (ID, ORDERCOLUM, ORDERDIR) => {
        getClienteTallerEnAPI(ID, ORDERCOLUM, ORDERDIR).then((cli) => {
            if (cli.CLIENTES !== null) {
                setClientesTaller(cli.CLIENTES)
                setObtenerClientesAux(cli.CLIENTES)
                if (textoFiltroCliente.trim() !== "") {
                    buscarCliente(textoFiltroCliente, cli.CLIENTES);
                }

            }
        })
    }

    const eliminarCliente = (ID) => {
        let payload = {
            id: ID,
            codigo: null,
            nombre: "",
            direccion: "",
            poblacion: "",
            provincia: "",
            cp: "",
            pais: "",
            telefono1: "",
            telefono2: "",
            telefono3: "",
            email: "",
            observacion: "",
            cif: ""
        }
        setClienteTallerEnAPI(2, payload).then((respuesta) => {
            if (respuesta.OK) {
                MSGOK("Cliente eliminado correctamente.")
                let filteredItems = clientesTaller.filter(item => item.ID !== ID)
                if (filteredItems.length > 0) {
                    setClientesTaller(filteredItems)
                    setObtenerClientesAux(filteredItems)
                } else {
                    setClientesTaller(null)
                    setObtenerClientesAux([])
                }

            }
        })
    }


    const buscarCliente = (palabra, listaCliente) => {
        let clientesEncontrado = [];
        if (palabra !== "") {
            if (listaCliente !== null) {
                listaCliente.find(function (cliente, i) {
                    if (cliente.CODIGO.toLowerCase().includes(palabra.toLowerCase())) {
                        let existeCliente = clientesEncontrado.find(cli => cli.ID === cliente.ID)
                        if (existeCliente === undefined) {
                            clientesEncontrado.push(cliente)
                        }
                    }

                    if (cliente.NOMBRE.toLowerCase().includes(palabra.toLowerCase())) {
                        let existeCliente = clientesEncontrado.find(cli => cli.ID === cliente.ID)
                        if (existeCliente === undefined) {
                            clientesEncontrado.push(cliente)
                        }
                    }
                    if (cliente.CIF.toLowerCase().includes(palabra.toLowerCase())) {
                        let existeCliente = clientesEncontrado.find(cli => cli.ID === cliente.ID)
                        if (existeCliente === undefined) {
                            clientesEncontrado.push(cliente)
                        }

                    }
                    if (cliente.TELEFONO.toLowerCase().includes(palabra.toLowerCase())) {
                        let existeCliente = clientesEncontrado.find(cli => cli.ID === cliente.ID)
                        if (existeCliente === undefined) {
                            clientesEncontrado.push(cliente)
                        }

                    }
                    if (cliente.CP.toLowerCase().includes(palabra.toLowerCase())) {
                        let existeCliente = clientesEncontrado.find(cli => cli.ID === cliente.ID)
                        if (existeCliente === undefined) {
                            clientesEncontrado.push(cliente)
                        }

                    }
                    if (cliente.MAIL.toLowerCase().includes(palabra.toLowerCase())) {
                        let existeCliente = clientesEncontrado.find(cli => cli.ID === cliente.ID)
                        if (existeCliente === undefined) {
                            clientesEncontrado.push(cliente)
                        }

                    }

                })
            }
            if (clientesEncontrado.length > 0) {
                setClientesTaller(clientesEncontrado);
            } else {
                setClientesTaller(null);
            }
        } else {
            setClientesTaller(obtenerClientesAux);
        }
    }



    return (
        <div>
            <Drawer
                width={clienteTallerSeleccionado.esDesdeFichaPresupuesto ? 900 : 1300}
                onClose={() => { store.dispatch(DrawerFichaClienteTaller(false)); }}
                visible={openDrawerFichaClienteTaller}
                placement={"right"}
                closable={false}
                bodyStyle={{ padding: '0px' }}
                style={{ zIndex: '9999999999', color: '#fff' }}>


                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                            onClick={(e) => {
                                store.dispatch(DrawerFichaClienteTaller(false));
                                createRipple(e)
                            }}>
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>

                <div className="p-3" style={{ height: (configApp.height - 70).toString() + "px", overflowX: 'hidden', overflowY: 'auto' }}>
                    <div className="flex-container mdb-skin">
                        <div style={{ flexGrow: '6', textAlign: 'left' }}>
                            <div className="grid-container mb-3" style={{ backgroundColor: '#eee' }}>
                                <div className="grid-item">
                                    <input id="buscadorClienteTaller" className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_cliente")} style={{ backgroundColor: "transparent", width: '400px', border: '0px', height: '100%', color: '#000' }} name="txtInputCliente" autoComplete="off" value={textoFiltroCliente} onChange={(e) => { setTextoFiltroCliente(e.target.value); buscarCliente(e.target.value, obtenerClientesAux) }} />
                                </div>

                                <div className="grid-item" >
                                    <MDBBtn id="closeBuscadorClienteTaller" onClick={e => { createRipple(e); efectoRemoveBuscadorReferencia("buscadorCliente", true); }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: 'hidden' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                </div>

                                <div className="grid-item barraLateral" ></div>

                                <div className="grid-item">
                                    <MDBBtn onClick={e => { createRipple(e); }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: clienteTallerSeleccionado.esDesdeFichaPresupuesto ? 'none' : '' }}>
                            <button className="activebtnMenu2 btnEstiloPersonalizado" style={{ height: '40px', width: '180px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#1890ff', backgroundColor: 'transparent', cursor: 'pointer' }}
                                onClick={() => {
                                    setMasivaCliente(true)
                                }
                                }><CloudUploadOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("txt_Masiva")} </span>  </button>


                            <button className="activebtnMenu2 btnEstiloPersonalizado" style={{ height: '40px', width: '180px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#1890ff', backgroundColor: 'transparent', cursor: 'pointer' }} onClick={() => {
                                store.dispatch(DrawerFichaMantenimientoClienteTaller(true))
                                store.dispatch({ type: "UPDATE_MODO_FICHA_CLIENTE_TALLER", esNuevoCliente: true })
                                setMantenimientoCliente(true)
                            }

                            }><PlusOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Nuevo_Cliente")} </span>  </button>
                        </div>
                    </div>

                    <Card style={{ height: (configApp.height - 170).toString() + "px" }} className="mb-2 mdb-skin" >
                        {clientesTaller === null ?
                            <div style={{ textAlign: 'center' }}>
                                <h4>{traducirPagina("No_clientes")}</h4>
                            </div>
                            :
                            clientesTaller.length > 0 ?
                                <div style={{ fontSize: '16px', fontWeight: '500', color: "#000", height: (configApp.height - 225).toString() + "px", overflowX: 'hidden', overflowY: 'auto' }} className="row">
                                    <MDBTable responsive hover >
                                        <MDBTableHead>
                                            <tr>
                                                <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '110px', verticalAlign: 'baseline', cursor: 'pointer' }} onClick={() => { ordenarTablaCliente("", 1, ordenarPorCodigo ? "A" : "D"); setOrdenarPorCodigo(ordenarPorCodigo ? false : true); setOrdenarPorNombre(false); setOrdenarPorCIF(false) }} >
                                                    {traducirPagina("Codigo")} {ordenarPorCodigo ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}
                                                </th>
                                                <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", verticalAlign: 'baseline', cursor: 'pointer' }} onClick={() => { ordenarTablaCliente("", 2, ordenarPorNombre ? "A" : "D"); setOrdenarPorNombre(ordenarPorNombre ? false : true); setOrdenarPorCodigo(false); setOrdenarPorCIF(false) }}>{traducirPagina("Nombre")}{ordenarPorNombre ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />} </th>
                                                <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '110px', verticalAlign: 'baseline', cursor: 'pointer' }} onClick={() => { ordenarTablaCliente("", 3, ordenarPorCIF ? "A" : "D"); setOrdenarPorCIF(ordenarPorCIF ? false : true); setOrdenarPorCodigo(false); setOrdenarPorNombre(false) }}>{traducirPagina("Cif").toUpperCase()}{ordenarPorCIF ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}</th>
                                                <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '200px', verticalAlign: 'baseline' }}>{traducirPagina("Email")}</th>
                                                <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '200px', verticalAlign: 'baseline' }}>{traducirPagina("Telefono")}</th>
                                                <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '100px', verticalAlign: 'baseline' }}>{traducirPagina("CP")}</th>
                                                <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: !clienteTallerSeleccionado.esDesdeFichaPresupuesto ? '100px' : '0px', verticalAlign: 'baseline' }}></th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {clientesTaller.map((cliente, i) =>
                                                <tr key={cliente.ID} onClick={(e) => {
                                                    if (clienteTallerSeleccionado.esDesdeFichaPresupuesto) {
                                                        store.dispatch(DrawerFichaClienteTaller(false));
                                                        store.dispatch({ type: "UPDATE_CLIENTE_SELECCIONADO_PARA_PRESUPUESTO_TALLER", cliente: cliente })
                                                    }
                                                }}>
                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{cliente.CODIGO}</td>
                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{cliente.NOMBRE}</td>
                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{cliente.CIF}</td>
                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{cliente.MAIL}</td>
                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{cliente.TELEFONO}</td>
                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{cliente.CP}</td>

                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>
                                                        {!clienteTallerSeleccionado.esDesdeFichaPresupuesto ?
                                                            <div className="flex-container-Grow-Simple">
                                                                <div style={{ textAlign: 'left', margin: '0px 2px' }}>
                                                                    <MDBBtn onClick={() => {
                                                                        store.dispatch({ type: "UPDATE_ID_CLIENTE_ID", id: cliente.ID });
                                                                        store.dispatch({ type: "UPDATE_MODO_FICHA_CLIENTE_TALLER", esNuevoCliente: false });
                                                                        store.dispatch(DrawerFichaMantenimientoClienteTaller(true));
                                                                        setMantenimientoCliente(true)

                                                                    }}
                                                                        className="btn-matricula  estiloBotonRemove paddindDeTres">
                                                                        <EditOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                    </MDBBtn>
                                                                </div>

                                                                <div style={{ textAlign: 'left', margin: '0px 2px' }}>
                                                                    <MDBBtn onClick={() => {
                                                                        eliminarCliente(cliente.ID)
                                                                    }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                        <DeleteOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                    </MDBBtn>
                                                                </div>
                                                            </div>
                                                            : null}
                                                    </td>
                                                </tr>
                                            )}
                                        </MDBTableBody>
                                    </MDBTable>
                                </div>

                                :
                                <div style={{ fontSize: '16px', fontWeight: '500', color: "#000" }} className="row">
                                    <MDBCol md="9">
                                        <div>
                                            <span className="skeleton-box" style={{ width: '900px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '900px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '800px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '800px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '800px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '800px' }}></span>
                                        </div>
                                    </MDBCol>
                                </div>
                        }
                    </Card>
                </div>
            </Drawer>

            {mantenimientoCliente ?
                <FichaMantenimientoCliente
                    ABRIR_FICHA={mantenimientoCliente}
                    CERRAR_FICHA={setMantenimientoCliente}
                    GUARDAR_INTERNO={true}
                >
                </FichaMantenimientoCliente>
                :
                null
            }


            {
                masivaCliente &&
                <FichaMasivaCliente
                    ABRIR_FICHA={masivaCliente}
                    CERRAR_FICHA={setMasivaCliente}
                    BUSCAR_CLIENTES={buscarRegistros}
                >
                </FichaMasivaCliente>
            }

        </div>
    )
}


FichaClienteTaller.propTypes = {
    title: PropTypes.string,
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        openDrawerFichaClienteTaller: state.estadoDrawer.openDrawerFichaClienteTaller,
        clienteTallerSeleccionado: state.clienteTallerSeleccionado,
        openDrawerFichaMantenimientoVehiculo: state.estadoDrawer.openDrawerFichaMantenimientoVehiculo

    };
}

export default connect(mapStateToProps)(FichaClienteTaller);