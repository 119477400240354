import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from 'mdbreact';
import 'antd/dist/antd.css';
import { Drawer, Row, Col, Collapse } from 'antd';
import { ObtenerAjustesByVehiculoTecdocEnAPI } from "../action/actionCreatorsRequests";
import { credencialesHaynes, traducirPagina, imprimirDivGenerico } from "../librerias/funciones"
import VisorGenerico from '../components/Visor/VisorGenerico'
import {
    EyeOutlined,
    PrinterOutlined,
    SearchOutlined
} from '@ant-design/icons';
import FichaNeumaticosErp from './FichaNeumaticosErp';

;
const { Panel } = Collapse;

const FichaAjustes = ({ props, codmotorizacion }) => {
    const [lubricantes, setLubricantes] = React.useState([]);
    const [abrirVisor, setAbrirVisor] = React.useState(false);
    const [ficheroSeleccionado, setFicheroSeleccionado] = React.useState({});
    const [menuAjustes, setMenuAjustes] = React.useState([]);
    const [ABRIR_FICHA_NEUMATICOS, setABRIR_FICHA_NEUMATICOS] = React.useState(false);
    const [registroNeumatico, setRegistroNeumatico] = React.useState({});





    const listaIndice = () => {
        let indices = []
        lubricantes.map((regis, index) => {
            indices.push(index);
        })

        return indices
    }


    const manualSeleccionado = (MANUAL) => {
        menuAjustes.map((registro) => {
            if (registro.ID === MANUAL.ID) {
                registro.SELECCIONADO = true
            } else {
                registro.SELECCIONADO = false
            }
        })

        setMenuAjustes([...menuAjustes])
    }

    const nuevoFormatoTexto = (TEXTO) => {
        let MODIFICAR = TEXTO.split("/").join(" ").split(" ").join(" ")
        let LISTA = MODIFICAR.split(" ");
        let ELIMINAR_LETRA = LISTA[2] !== undefined ? LISTA[2].slice(1) : "";
        let CONSCATENAR = `${LISTA[0]}${LISTA[1]}${ELIMINAR_LETRA}`

    

        return CONSCATENAR === undefined ? "" : CONSCATENAR;
    }


    React.useEffect(() => {
        const { TOKEN_TECNICO_DIST } = credencialesHaynes();
        ObtenerAjustesByVehiculoTecdocEnAPI(codmotorizacion, TOKEN_TECNICO_DIST).then(registro => {
            if (registro !== undefined) {
                if (registro.AJUSTES !== null && registro.AJUSTES.length > 0) {
                    setLubricantes(registro.AJUSTES)

                    let REGISTROS = [];
                    registro.AJUSTES.map((registro, i) =>

                        REGISTROS.push({ NOMBRE: registro.NOMBRE, ID: i, SELECCIONADO: false })
                    )
                    setMenuAjustes(REGISTROS)
                }
            }
        })
    }, [])



    return (
        <div>
            <Drawer
                width={"80%"}
                onClose={() => {
                    if (props.TIPO === "MASTER") {
                        props.CERRAR.setState({ ABRIR_FICHA_AJUSTES: false })
                    } else {
                        props.CERRAR_FICHA(false)
                    }

                }}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                closable={false}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>
                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                            if (props.TIPO === "MASTER") {
                                props.CERRAR.setState({ ABRIR_FICHA_AJUSTES: false })
                            } else {
                                props.CERRAR_FICHA(false)
                            }

                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ textAlign: 'left', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>


                    <div style={{ flexGrow: '1', textAlign: 'end', color: '#fff', verticalAlign: 'middle', paddingRight: '10px', fontSize: '16px' }}>
                        <button className={`col-md-12 ${'btnColor'}`} style={{
                            height: '40px', width: '50px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top',
                            color: '#fff',
                            marginBottom: '5px', marginRight: '5px'
                        }} onClick={(e) => {

                            imprimirDivGenerico("informacion_ajustes");
                        }}>
                            <PrinterOutlined style={{ fontSize: '20px' }} />
                            <span style={{ verticalAlign: 'middle', fontSize: '16px', marginLeft: '10px' }}></span>
                        </button>
                    </div>

                </div>
                

                <div className="layout_Ajustes">
                    <div className='menu_ajustes'>
                        {menuAjustes === null ?
                            <h4>{traducirPagina("No_resultados")}</h4>
                            :
                            menuAjustes.length === 0 ?
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                                :
                                <div className='manual_listado' style={{ height: 'auto' }}>
                                    {
                                        menuAjustes.map((registro, i) =>
                                            <a href={`#page_${i}`} title={registro.NOMBRE} style={{ backgroundColor: registro.SELECCIONADO && '#b2b2b2' }}
                                                onClick={() => {
                                                    manualSeleccionado(registro)
                                                }}>{registro.NOMBRE}</a>
                                        )}
                                </div>
                        }
                    </div>
                    <div className='informacion_ajustes' >

                        <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000', margin: '0px' }}>

                            <Col md={24} className="conatinerTiempos">
                                {lubricantes === null ?
                                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                        <h4>{traducirPagina("No_resultados")}</h4>
                                    </div>
                                    :
                                    lubricantes.length > 0 ?
                                        <Collapse
                                            className="site-collapse-custom-collapse"
                                            bordered={false}
                                            defaultActiveKey={listaIndice()}
                                        >
                                            {lubricantes.map((nodo, index) =>
                                                <Panel
                                                    className="site-collapse-custom-panel"
                                                    header={<div className='hederTituloAjustes' id={`page_${index}`} >{nodo.NOMBRE}</div>}
                                                    key={index}
                                                >
                                                    {nodo === null ?
                                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '20vh' }}> <h3>{traducirPagina("Sin_referencias")}</h3> </div>
                                                        :
                                                        nodo.SUBAJUSTES !== undefined ?
                                                            nodo.SUBAJUSTES.length === 0 ?
                                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '20vh' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                                                :

                                                                <ul className='containerAjustes'>
                                                                    {
                                                                        nodo.SUBAJUSTES.map((registro, i) =>
                                                                            <>
                                                                                <li className='Subtitulo_submenu'  >
                                                                                    <span>{registro.NOMBRE}</span>
                                                                                </li>

                                                                                <table style={{ width: '100%' }} className={"tabla_ajuestes"}>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th ></th>
                                                                                            <th style={{ width: '190px' }}></th>
                                                                                            <th style={{ width: '60px' }}></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {registro.SUBAJUSTES.map((item, i) =>
                                                                                            <>
                                                                                                <tr style={{ borderBottom: registro.SUBAJUSTES.length - 1 === i && 'none' }}>
                                                                                                    <td>
                                                                                                        {item.NOMBRE}
                                                                                                        {item.OBSERVACIONES !== null ?
                                                                                                            <div>{`(${item.OBSERVACIONES})`}</div>
                                                                                                            :
                                                                                                            null
                                                                                                        }

                                                                                                        {item.ARTICULOS !== null &&
                                                                                                            item.ARTICULOS.map((registro) =>
                                                                                                                <li className='listaAjustes'>
                                                                                                                    <div>{registro.descriptionField}</div>
                                                                                                                </li>
                                                                                                            )
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td>{item.VALOR}</td>
                                                                                                    <td>
                                                                                                        {
                                                                                                            item.UNIDAD_MEDIDAD !== null ?
                                                                                                                `${item.UNIDAD_MEDIDAD}`
                                                                                                                :

                                                                                                                item.ID_DESCRIPCION === "414" || item.ID_DESCRIPCION === "317000011" ?
                                                                                                                    <div className='btn_buscar_neumatico_ajustes' onClick={() => {
                                                                                                                        setRegistroNeumatico(item)
                                                                                                                        setABRIR_FICHA_NEUMATICOS(true)

                                                                                                                    }}>
                                                                                                                        <SearchOutlined></SearchOutlined>
                                                                                                                        {traducirPagina("Buscar")}
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    null

                                                                                                        }
                                                                                                    </td>
                                                                                                </tr>

                                                                                                {item.SUBAJUSTES !== null ?
                                                                                                    item.SUBAJUSTES.map((registro) =>
                                                                                                        <>
                                                                                                            <tr style={{ borderBottom: registro.SUBAJUSTES.length - 1 === i && 'none' }} >

                                                                                                                <td style={{ paddingLeft: '20px' }}>{registro.NOMBRE}</td>
                                                                                                                <td>{registro.VALOR}</td>

                                                                                                                <td>{`${item.UNIDAD_MEDIDAD !== null ? item.UNIDAD_MEDIDAD : ""}`}</td>
                                                                                                            </tr>
                                                                                                            {registro.SUBAJUSTES.map((item) =>
                                                                                                                <tr style={{ borderBottom: registro.SUBAJUSTES.length - 1 === i && 'none' }}>

                                                                                                                    <td style={{ paddingLeft: '40px' }}>{registro.NOMBRE}</td>
                                                                                                                    <td>{registro.VALOR}</td>

                                                                                                                    <td>{`${item.UNIDAD_MEDIDAD !== null ? item.UNIDAD_MEDIDAD : ""}`}</td>
                                                                                                                </tr>
                                                                                                            )
                                                                                                            }
                                                                                                        </>
                                                                                                    )
                                                                                                    :
                                                                                                    null
                                                                                                }

                                                                                                {item.IMAGEN !== null ?
                                                                                                    <tr>
                                                                                                        <th colspan="3">
                                                                                                            <div className='site-collapse-custom-tareas-manuales-imagen' style={{ position: 'relative' }} onClick={() => {

                                                                                                                setFicheroSeleccionado({ TITULO: `${item.NOMBRE} `, TIPO_DOCUMENTO: "IMG", URL: item.IMAGEN, FICHERO: {} })
                                                                                                                setAbrirVisor(true)

                                                                                                            }}>
                                                                                                                <img src={item.IMAGEN}></img>

                                                                                                                <div className="image-mask">
                                                                                                                    <div className="image-mask-info">
                                                                                                                        <EyeOutlined title='Visualizar' style={{ fontSize: '30px', display: 'inline-block', marginRight: '20px' }}></EyeOutlined>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                        </th>
                                                                                                    </tr>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                            </>

                                                                                        )}
                                                                                    </tbody>
                                                                                </table>
                                                                            </>
                                                                        )
                                                                    }

                                                                </ul>
                                                            : null
                                                    }
                                                </Panel>
                                            )
                                            }
                                        </Collapse>
                                        :
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
            </Drawer>



            {abrirVisor ?
                <VisorGenerico
                    ABRIR_VISOR={abrirVisor}
                    CERRAR_VISOR={setAbrirVisor}
                    PROPIEDADES_DOC={ficheroSeleccionado}
                    MOSTRAR_BOTON_DESCARGA={false}
                >
                </VisorGenerico>
                :
                null
            }


            {ABRIR_FICHA_NEUMATICOS ?
                <FichaNeumaticosErp
                    title={traducirPagina("Catalogo_de_neumaticos").toUpperCase()}
                    ABRIR_FICHA={ABRIR_FICHA_NEUMATICOS}
                    CERRAR_FICHA={setABRIR_FICHA_NEUMATICOS}
                    TIPO={""}
                    MEDIDAS={nuevoFormatoTexto(registroNeumatico.VALOR)}
                    ID_VEHICULO={-1}
                >

                </FichaNeumaticosErp>
                : null
            }

        </div>
    )
}

FichaAjustes.propTypes = {
    title: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    CERRAR: PropTypes.object,
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        openDrawerFichaLubricantes: state.estadoDrawer.openDrawerFichaLubricantes,
        codmotorizacion: state.codmotorizacion

    };
}

export default connect(mapStateToProps)(FichaAjustes);