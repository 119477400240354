import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { traducirPagina } from "../../librerias/funciones";

export const FileView = (props) => {
    const { ABRIR_FICHA, TITULO, SELECCIONADO } = props.PROPIEDADES;



    return <>
        
            <div style={{ backgroundColor: "rgba(0, 0, 0, 0.9)",zIndex:'9999',width:'1200px' }} className="react-simple-image-viewer__modal" onClick={() => {
                props.PROPIEDADES.ABRIR_FICHA = false
                props.MODIFICAR_PROPIEDADES({ ...props.PROPIEDADES })
            }
            }>
                <span className="react-simple-image-viewer__close" onClick={() => {
                    props.PROPIEDADES.ABRIR_FICHA = false
                    props.MODIFICAR_PROPIEDADES({ ...props.PROPIEDADES })
                }}> <CloseOutlined /> </span>
                <span className="react-simple-image-viewer__span">{TITULO}</span>

                <div className="react-simple-image-viewer__modal-content">
                    {SELECCIONADO.ENCLACE !== null ?
                        SELECCIONADO.ENCLACE !== "" ?
                            <div><img src={SELECCIONADO.ENCLACE} alt="" className="visor_imagen_generica"></img></div>
                            :
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#fff' }}> <div color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                        :
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#fff' }}> <h3>{traducirPagina("No_resultados")}</h3> </div>
                    }
                </div>
            </div>
         

    </>;
};