import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DrawerFichaClienteTaller, DrawerFichaManoDeObra, DrawerFichaArticulo, DrawerFichaImpresionDocumento, DrawerFichaEnvioDocumento } from "../action/actionCreators"
import { getFabricantesEnAPI, getModelosTurismosEnAPI, getMotorizacionesTurismosEnAPI, setPresupuestosTallerEnAPI, GetPortapapelesPresupuestosEnAPI, LimpiarPortapapelesPresupuestosEnAPI, setOrdenesReparacionTallerEnAPI, getFacturasEnAPI, EnviarFacturaEnAPI, ObtenerFacturaHTMLEnAPI, ObtenerFacturaPDFEnAPI, setFacturaEnAPI, abortFetch } from "../action/actionCreatorsRequests"
import { MSGOK, traducirPagina, convertirMoneda, nuevoFormatoFechaCliente, obtenerFechaLocal, obtenerLineasPorTipo, calculoTotalesPresupuesto, asignarImportes, convertirMonedaANumero, descargarPdfPasandoBase64, MSGERROR, crearLineasParaElPresupuestoLocal, convertirSimboloMoneda } from '../librerias/funciones'
import { MDBBtn, MDBNav, MDBNavItem, MDBNavLink, MDBTabPane, MDBTabContent, MDBCol, MDBRow, MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';
import FichaEnvioEmail from '../components/FichaEnvioEmail'
import ManoObraGenerico from './ManoObraGenerico';
import ArticuloGenerico from './ArticuloGenerico';
import FichaImpresionGenerico from './FichaImpresionGenerico'
import {
    PlusOutlined,
    SaveOutlined,
    EditOutlined,
    DeleteOutlined,
    SearchOutlined,
    PrinterOutlined,
    MailOutlined,
    ArrowDownOutlined
} from '@ant-design/icons';

import { Card, Input, Row, Col, Drawer, Tabs, DatePicker, Select } from 'antd';
import store from "../store";
import FichaVehClienteGenerico from './FichaVehClienteGenerico';

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const MantenimientoFactura = ({ props, configApp, PresupuestoTallerSeleccionado, ALBARAN_SELECCIONADO }) => {
    const [hayRegistro, setHayRegistro] = React.useState(false);
    const [tabPorDefecto, setTabPorDefecto] = React.useState("1");
    const [activeItemOuterTabs, setActiveItemOuterTabs] = React.useState("1");
    const [fabricantes, setFabricantes] = React.useState([]);
    const [fabricantePorDefecto, setFabricantePorDefecto] = React.useState(0);
    const [modelos, setModelos] = React.useState([]);
    const [modelosPorDefecto, setModelosPorDefecto] = React.useState(0);
    const [motorizaciones, setMotorizaciones] = React.useState([]);
    const [motorizacionesPorDefecto, setMotorizacionesPorDefecto] = React.useState(0);
    const [lineasManoObra, setLineasManoObra] = React.useState([]);
    const [lineasArticulos, setLineasArticulos] = React.useState([]);
    const [lineaSeleccionada, setLineaSeleccionada] = React.useState({});
    const [hayLineasEnPortapapeles, setHayLineasEnPortapapeles] = React.useState(false);

    const [abrirFichaManoObra, setAbrirFichaManoObra] = React.useState(false);
    const [abrirFichaArticulo, setAbrirFichaArticulo] = React.useState(false);
    const [ABRIR_FICHA_IMPRESION, setABRIR_FICHA_IMPRESION] = React.useState(false);
    const [DOC_HTML, setDOC_HTML] = React.useState("");
    const [ABRIR_FICHA_EMAIL, setABRIR_FICHA_EMAIL] = React.useState("");
    const [fichaVehiculo, setFichaVehiculo] = React.useState(false);

    const [payLoadLocal, setPayLoadLocal] = React.useState(
        {
            ID: null,
            CODIGO: "",
            FECHA: "",
            ID_VEHICULO: 0,
            VEH_MATRICULA: "",
            VEH_DENOMINACION: "",
            VEH_ID_TECDOC: 0,
            ID_CLIENTE: null,
            ID_MARCA_MATRICULA: 0,
            ID_MODELO_MATRICULA: 0,
            ID_MOTOR_MATRICULA: 0,
            CLI_NOMBRE: "",
            CLI_CIF: "",
            CLI_DIRECCION: "",
            CLI_POBLACION: "",
            CLI_PROVINCIA: "",
            CLI_CP: "",
            CLI_PAIS: "",
            CLI_TELEFONO: "",
            CLI_TELEFONO_2: "",
            CLI_TELEFONO_3: "",
            CLI_MAIL: "",
            VALOR_DTO_GENERAL: 0,
            VALOR_IVA_1: 0,
            VALOR_IVA_2: 0,
            VALOR_IVA_3: 0,
            IMPORTE_BRUTO_1: 0,
            IMPORTE_BRUTO_2: 0,
            IMPORTE_BRUTO_3: 0,
            IMPORTE_BRUTO: 0,
            IMPORTE_DESCUENTOS_1: 0,
            IMPORTE_DESCUENTOS_2: 0,
            IMPORTE_DESCUENTOS_3: 0,
            IMPORTE_DESCUENTOS: 0,
            IMPORTE_BASE_IMPONIBLE_1: 0,
            IMPORTE_BASE_IMPONIBLE_2: 0,
            IMPORTE_BASE_IMPONIBLE_3: 0,
            IMPORTE_BASE_IMPONIBLE: 0,
            IMPORTE_IMPUESTOS: 0,
            IMPORTE_IMPUESTOS_1: 0,
            IMPORTE_IMPUESTOS_2: 0,
            IMPORTE_IMPUESTOS_3: 0,
            IMPORTE_TOTAL_1: 0,
            IMPORTE_TOTAL_2: 0,
            IMPORTE_TOTAL_3: 0,
            IMPORTE_TOTAL: 0,
            OBSERVACIONES_FACTURA: "",
            IMPRIMIDO: false,
            ENVIADO: false,
            FECHA_IMPRESION: "",
            FECHA_ENVIO: "",
            ESTADO: "P",
            VEH_BASTIDOR: "",
            LINEAS: [],
            BASTIDOR: ""
        }
    );




    const cargaAutomaticaDeLineas = () => {
        //let LINEASLOCAL = PresupuestoTallerSeleccionado.presupuestoSecundario;
        //let concatenrLineas = LINEASLOCAL.concat(payLoadLocal.LINEAS);
        GetPortapapelesPresupuestosEnAPI().then((LINEAS) => {
            if (LINEAS !== undefined && LINEAS !== null) {
                if (LINEAS.PRESUPUESTOS !== null && LINEAS.PRESUPUESTOS.length > 0) {
                    LINEAS.PRESUPUESTOS.map((lineas) => {
                        lineas.ORDEN_LINEA = 0;
                    })
                    let concatenrLineas = LINEAS.PRESUPUESTOS.concat(payLoadLocal.LINEAS);

                    recalcularTotalesLocal(concatenrLineas)
                    MSGOK(traducirPagina("Descarga_lineas"))

                    LimpiarPortapapelesPresupuestosEnAPI(3).then((registro) => {
                        if (registro !== undefined) {
                            if (registro.OK) {
                                setHayLineasEnPortapapeles(false);
                            }
                        }
                    })

                }
            }
        })
    }



    const buscarModelos = (fabricante) => {
        getModelosTurismosEnAPI(fabricante).then((modelos) => {
            if (modelos !== undefined) {
                if (modelos !== null) {
                    setModelos(modelos)
                } else {
                    setModelos(null)
                }
            }
        })
    }

    const buscarMotorizaciones = (modelo) => {
        getMotorizacionesTurismosEnAPI(modelo).then((motorizacion) => {
            if (motorizacion !== undefined) {
                if (motorizacion !== null) {
                    setMotorizaciones(motorizacion)
                } else {
                    setMotorizaciones(null)
                }
            }
        })
    }

    const ponerCocheADocumento = async () => {

        if (Object.keys(props.VEHICULO_LOCAL).length > 0) {
            const { MATRICULA, BASTIDOR, MARCA_VEHICULO, MODELO_VEHICULO, MOTOR_VEHICULO, VEHICULO_TECDOC, HISTORIAL_VEHICULO } = props.VEHICULO_LOCAL;

            if (HISTORIAL_VEHICULO.length === 3) {
                let ID_MARCA = HISTORIAL_VEHICULO[0].id
                let ID_MODELO = HISTORIAL_VEHICULO[1].id
                let ID_MOTOR = HISTORIAL_VEHICULO[2].id

                payLoadLocal.VEH_MATRICULA = MATRICULA;

                payLoadLocal.VEH_BASTIDOR = BASTIDOR;
                payLoadLocal.BASTIDOR = BASTIDOR;

                //payLoadLocal.VEH_ID_TECDOC = VEHICULO_TECDOC.ID;
                payLoadLocal.ID_MOTOR_MATRICULA = VEHICULO_TECDOC.ID;
                payLoadLocal.VEH_DENOMINACION = MARCA_VEHICULO + " " + MODELO_VEHICULO + " " + MOTOR_VEHICULO;




                const MODELO = await buscarModelos(ID_MARCA);
                const MOTOR = await buscarMotorizaciones(ID_MODELO)

                setFabricantePorDefecto(ID_MARCA)
                setModelosPorDefecto(ID_MODELO)
                setMotorizacionesPorDefecto(ID_MOTOR)
                setPayLoadLocal(payLoadLocal)

            }
        }
    }




    const recalcularTotalesLocal = (concatenrLineas) => {

        let ManoObre = obtenerLineasPorTipo(concatenrLineas, "T")
        let Articulos = obtenerLineasPorTipo(concatenrLineas, "A")

        setLineasManoObra(ManoObre)
        setLineasArticulos(Articulos)
        let resultTotales = calculoTotalesPresupuesto(ManoObre, Articulos);
        payLoadLocal.VALOR_IVA_1 = resultTotales.iva_1;
        payLoadLocal.VALOR_IVA_2 = resultTotales.iva_2;
        payLoadLocal.VALOR_IVA_3 = resultTotales.iva_3;
        payLoadLocal.IMPORTE_BRUTO_1 = resultTotales.importeBruto_1;
        payLoadLocal.IMPORTE_BRUTO_2 = resultTotales.importeBruto_2;
        payLoadLocal.IMPORTE_BRUTO_3 = resultTotales.importeBruto_3;
        payLoadLocal.IMPORTE_BRUTO = resultTotales.importeBruto;
        payLoadLocal.IMPORTE_DESCUENTOS_1 = resultTotales.importeDescuento_1;
        payLoadLocal.IMPORTE_DESCUENTOS_2 = resultTotales.importeDescuento_2;
        payLoadLocal.IMPORTE_DESCUENTOS_3 = resultTotales.importeDescuento_3;
        payLoadLocal.IMPORTE_DESCUENTOS = resultTotales.importeDescuento;
        payLoadLocal.IMPORTE_BASE_IMPONIBLE_1 = resultTotales.baseImp_1;
        payLoadLocal.IMPORTE_BASE_IMPONIBLE_2 = resultTotales.baseImp_2;
        payLoadLocal.IMPORTE_BASE_IMPONIBLE_3 = resultTotales.baseImp_3;
        payLoadLocal.IMPORTE_BASE_IMPONIBLE = resultTotales.baseImp;
        payLoadLocal.IMPORTE_IMPUESTOS = resultTotales.impuesto;
        payLoadLocal.IMPORTE_IMPUESTOS_1 = resultTotales.impuesto_1;
        payLoadLocal.IMPORTE_IMPUESTOS_2 = resultTotales.impuesto_2;
        payLoadLocal.IMPORTE_IMPUESTOS_3 = resultTotales.impuesto_3;
        payLoadLocal.IMPORTE_TOTAL_1 = resultTotales.total_1;
        payLoadLocal.IMPORTE_TOTAL_2 = resultTotales.total_2;
        payLoadLocal.IMPORTE_TOTAL_3 = resultTotales.total_3;
        payLoadLocal.IMPORTE_TOTAL = resultTotales.total;

        setPayLoadLocal(payLoadLocal)
    }




    const buscarFabricantes = () => {
        getFabricantesEnAPI().then((fabricantes) => {
            if (fabricantes !== undefined) {
                if (fabricantes !== null) {
                    setFabricantes(fabricantes)
                } else {
                    setFabricantes(null)
                }
            }
        })
    }
    const vehiculoSeleccionado = (vehiculo) => {
        payLoadLocal.ID_VEHICULO = vehiculo.ID;
        payLoadLocal.VEH_MATRICULA = vehiculo.MATRICULA;
        payLoadLocal.VEH_BASTIDOR = vehiculo.BASTIDOR;
        payLoadLocal.BASTIDOR = vehiculo.BASTIDOR;

        setPayLoadLocal(payLoadLocal)

        setFabricantes([{ ManNo: 1, Term: vehiculo.MARCA }])
        setModelos([{ ID: 1, DESCRIPCION: vehiculo.MODELO }])
        setMotorizaciones([{ ID: 1, DESCRIPCION: vehiculo.MOTOR }])
        setFabricantePorDefecto(1);
        setModelosPorDefecto(1);
        setMotorizacionesPorDefecto(1)


        setFichaVehiculo(false)
    }




    if (Object.keys(PresupuestoTallerSeleccionado.cliente).length > 0 && PresupuestoTallerSeleccionado.cliente.ID !== payLoadLocal.ID_CLIENTE) {
        payLoadLocal.ID_CLIENTE = PresupuestoTallerSeleccionado.cliente.ID;
        payLoadLocal.CLI_NOMBRE = PresupuestoTallerSeleccionado.cliente.NOMBRE;
        payLoadLocal.CLI_CIF = PresupuestoTallerSeleccionado.cliente.CIF;
        payLoadLocal.CLI_DIRECCION = PresupuestoTallerSeleccionado.cliente.DIRECCION;
        payLoadLocal.CLI_POBLACION = PresupuestoTallerSeleccionado.cliente.POBLACION;
        payLoadLocal.CLI_PROVINCIA = PresupuestoTallerSeleccionado.cliente.PROVINCIA;
        payLoadLocal.CLI_CP = PresupuestoTallerSeleccionado.cliente.CP;
        payLoadLocal.CLI_PAIS = PresupuestoTallerSeleccionado.cliente.PAIS;
        payLoadLocal.CLI_TELEFONO = PresupuestoTallerSeleccionado.cliente.TELEFONO;
        payLoadLocal.CLI_TELEFONO_2 = PresupuestoTallerSeleccionado.cliente.TELEFONO_2;
        payLoadLocal.CLI_TELEFONO_3 = PresupuestoTallerSeleccionado.cliente.TELEFONO_3;
        payLoadLocal.CLI_MAIL = PresupuestoTallerSeleccionado.cliente.MAIL;
    }


    if (!hayRegistro && props.ABRIR_FICHA) {
        setHayRegistro(true);


        if (Object.keys(ALBARAN_SELECCIONADO).length > 0) {
            const COPIAR_ALBARAN = { ...ALBARAN_SELECCIONADO };
            setTabPorDefecto("2")
            let listaArticulo = [];
            store.dispatch({ type: 'STATE_ALBARAN_SELECCIONADO', ALBARAN_SELECCIONADO: {} });
            COPIAR_ALBARAN.LINEAS.map((articulo, indice) => {
                let ITEM_ARTICULO = {};
                if (parseInt(articulo.cantidad) > 0) {
                    ITEM_ARTICULO = {
                        PRECIO: articulo.precio,
                        REFERENCIA_PROVEEDOR: articulo.referencia,
                        REFERENCIA: articulo.referencia_comercial /*articulo.referencia_comercial*/,
                        CODIGO_MARCA_ERP: articulo.marca,
                        NOMRE_MARCA: articulo.marca,
                        CODIGO_MARCA: articulo.marca_grupo,
                        NOMBRE_GRUPO_MONTAJE: articulo.descripcion,
                        DESCUENTO: 0,
                        CANTIDAD: parseInt(articulo.cantidad)
                    }
                }
                listaArticulo.push(crearLineasParaElPresupuestoLocal(ITEM_ARTICULO, "A", true));

            })
            recalcularTotalesLocal(listaArticulo);
        }


        GetPortapapelesPresupuestosEnAPI().then((LINEAS) => {
            if (LINEAS !== undefined && LINEAS !== null) {
                if (LINEAS.PRESUPUESTOS !== null && LINEAS.PRESUPUESTOS.length > 0) {
                    setHayLineasEnPortapapeles(true);
                }
            }
        })



        if (Object.keys(props.DOCUMENTO_SELECCIONADO).length > 0) {



            getFacturasEnAPI(props.DOCUMENTO_SELECCIONADO.ID, 1, "A").then((factura) => {
                if (factura !== undefined && factura !== null) {
                    if (factura.FACTURAS !== null) {
                        let datosAPI = factura.FACTURAS[0];
                        let obtenerFecha = nuevoFormatoFechaCliente(datosAPI.FECHA)
                        let ManoObre = obtenerLineasPorTipo(datosAPI.LINEAS, "T")
                        let Articulos = obtenerLineasPorTipo(datosAPI.LINEAS, "A")

                        setLineasManoObra(ManoObre)
                        setLineasArticulos(Articulos)


                        let resultTotales = calculoTotalesPresupuesto(ManoObre, Articulos)
                        let temporal = {
                            ID: datosAPI.ID,
                            CODIGO: datosAPI.CODIGO,
                            FECHA: obtenerFecha,
                            ID_VEHICULO: datosAPI.ID_VEHICULO,
                            VEH_MATRICULA: datosAPI.VEH_MATRICULA,
                            VEH_DENOMINACION: datosAPI.VEH_DENOMINACION,
                            VEH_ID_TECDOC: datosAPI.VEH_ID_TECDOC,
                            ID_CLIENTE: datosAPI.ID_CLIENTE,
                            ID_MARCA_MATRICULA: datosAPI.ID_MARCA_MATRICULA,
                            ID_MODELO_MATRICULA: datosAPI.ID_MODELO_MATRICULA,
                            ID_MOTOR_MATRICULA: datosAPI.ID_MOTOR_MATRICULA,
                            CLI_NOMBRE: datosAPI.CLI_NOMBRE,
                            CLI_CIF: datosAPI.CLI_CIF,
                            CLI_DIRECCION: datosAPI.CLI_DIRECCION,
                            CLI_POBLACION: datosAPI.CLI_POBLACION,
                            CLI_PROVINCIA: datosAPI.CLI_PROVINCIA,
                            CLI_CP: datosAPI.CLI_CP,
                            CLI_PAIS: datosAPI.CLI_PAIS,
                            CLI_TELEFONO: datosAPI.CLI_TELEFONO,
                            CLI_TELEFONO_2: datosAPI.CLI_TELEFONO_2,
                            CLI_TELEFONO_3: datosAPI.CLI_TELEFONO_3,
                            CLI_MAIL: datosAPI.CLI_MAIL,
                            VALOR_DTO_GENERAL: datosAPI.VALOR_DTO_GENERAL,
                            VALOR_IVA_1: resultTotales.iva_1,
                            VALOR_IVA_2: resultTotales.iva_2,
                            VALOR_IVA_3: resultTotales.iva_3,
                            IMPORTE_BRUTO_1: resultTotales.importeBruto_1,
                            IMPORTE_BRUTO_2: resultTotales.importeBruto_2,
                            IMPORTE_BRUTO_3: resultTotales.importeBruto_3,
                            IMPORTE_BRUTO: resultTotales.importeBruto,
                            IMPORTE_DESCUENTOS_1: resultTotales.importeDescuento_1,
                            IMPORTE_DESCUENTOS_2: resultTotales.importeDescuento_2,
                            IMPORTE_DESCUENTOS_3: resultTotales.importeDescuento_3,
                            IMPORTE_DESCUENTOS: resultTotales.importeDescuento,
                            IMPORTE_BASE_IMPONIBLE_1: resultTotales.baseImp_1,
                            IMPORTE_BASE_IMPONIBLE_2: resultTotales.baseImp_2,
                            IMPORTE_BASE_IMPONIBLE_3: resultTotales.baseImp_3,
                            IMPORTE_BASE_IMPONIBLE: resultTotales.baseImp,
                            IMPORTE_IMPUESTOS: resultTotales.impuesto,
                            IMPORTE_IMPUESTOS_1: resultTotales.impuesto_1,
                            IMPORTE_IMPUESTOS_2: resultTotales.impuesto_2,
                            IMPORTE_IMPUESTOS_3: resultTotales.impuesto_3,
                            IMPORTE_TOTAL_1: resultTotales.total_1,
                            IMPORTE_TOTAL_2: resultTotales.total_2,
                            IMPORTE_TOTAL_3: resultTotales.total_3,
                            IMPORTE_TOTAL: resultTotales.total,
                            OBSERVACIONES_FACTURA: datosAPI.OBSERVACIONES_FACTURA,
                            IMPRIMIDO: datosAPI.IMPRIMIDO,
                            ENVIADO: datosAPI.ENVIADO,
                            FECHA_IMPRESION: datosAPI.FECHA_IMPRESION,
                            FECHA_ENVIO: datosAPI.FECHA_ENVIO,
                            ESTADO: datosAPI.ESTADO,
                            LINEAS: datosAPI.LINEAS,
                            BASTIDOR: datosAPI.BASTIDOR
                        }

                        setPayLoadLocal(temporal)
                        buscarVehiculo(temporal)
                    }
                }
            })


        } else {
            //cargaAutomaticaDeLineas();

            //Codigo para modificar el presupuesto...
            let fechaLocal = obtenerFechaLocal(false);
            payLoadLocal.FECHA = fechaLocal;
            setPayLoadLocal(payLoadLocal)

            ponerCocheADocumento()
        }
    } else {
        if (!props.ABRIR_FICHA && hayRegistro) {
            setHayLineasEnPortapapeles(false)
            setFabricantes([])
            setModelos([])
            setMotorizaciones([])
            setFabricantePorDefecto(0)
            setModelosPorDefecto(0)
            setMotorizacionesPorDefecto(0)

            setTabPorDefecto("1")
            setActiveItemOuterTabs("1")

            setLineasManoObra([])
            setLineasArticulos([])
            setPayLoadLocal({
                ID: null,
                CODIGO: "",
                FECHA: "",
                ID_VEHICULO: 0,
                VEH_MATRICULA: "",
                VEH_DENOMINACION: "",
                VEH_ID_TECDOC: 0,
                ID_CLIENTE: null,
                ID_MARCA_MATRICULA: 0,
                ID_MODELO_MATRICULA: 0,
                ID_MOTOR_MATRICULA: 0,
                CLI_NOMBRE: "",
                CLI_CIF: "",
                CLI_DIRECCION: "",
                CLI_POBLACION: "",
                CLI_PROVINCIA: "",
                CLI_CP: "",
                CLI_PAIS: "",
                CLI_TELEFONO: "",
                CLI_TELEFONO_2: "",
                CLI_TELEFONO_3: "",
                CLI_MAIL: "",
                VALOR_DTO_GENERAL: 0,
                VALOR_IVA_1: 0,
                VALOR_IVA_2: 0,
                VALOR_IVA_3: 0,
                IMPORTE_BRUTO_1: 0,
                IMPORTE_BRUTO_2: 0,
                IMPORTE_BRUTO_3: 0,
                IMPORTE_BRUTO: 0,
                IMPORTE_DESCUENTOS_1: 0,
                IMPORTE_DESCUENTOS_2: 0,
                IMPORTE_DESCUENTOS_3: 0,
                IMPORTE_DESCUENTOS: 0,
                IMPORTE_BASE_IMPONIBLE_1: 0,
                IMPORTE_BASE_IMPONIBLE_2: 0,
                IMPORTE_BASE_IMPONIBLE_3: 0,
                IMPORTE_BASE_IMPONIBLE: 0,
                IMPORTE_IMPUESTOS_1: 0,
                IMPORTE_IMPUESTOS_2: 0,
                IMPORTE_IMPUESTOS_3: 0,
                IMPORTE_IMPUESTOS: 0,
                IMPORTE_TOTAL_1: 0,
                IMPORTE_TOTAL_2: 0,
                IMPORTE_TOTAL_3: 0,
                IMPORTE_TOTAL: 0,
                OBSERVACIONES_FACTURA: "",
                IMPRIMIDO: false,
                ENVIADO: false,
                FECHA_IMPRESION: "",
                FECHA_ENVIO: "",
                ESTADO: "P",
                VEH_BASTIDOR: "",
                LINEAS: [],
                BASTIDOR: ""
            })
        }
    }


    const buscarVehiculo = (registro) => {
        if (registro.ID_MARCA_MATRICULA > 0) {
            setFabricantePorDefecto(registro.ID_MARCA_MATRICULA)
            setModelosPorDefecto(registro.ID_MODELO_MATRICULA)
            setMotorizacionesPorDefecto(registro.ID_MOTOR_MATRICULA)

            buscarModelos(registro.ID_MARCA_MATRICULA)
            buscarMotorizaciones(registro.ID_MODELO_MATRICULA)
        }

    }




    const guardarEstadoFormulario = (e) => {
        const ID = e.target.name === 'ID' ? e.target.value : payLoadLocal.ID;
        const CODIGO = e.target.name === 'CODIGO' ? e.target.value : payLoadLocal.CODIGO;
        const FECHA = e.target.name === 'FECHA' ? nuevoFormatoFechaCliente(e.target.value) : payLoadLocal.FECHA;
        const ID_VEHICULO = e.target.name === 'ID_VEHICULO' ? e.target.value : payLoadLocal.ID_VEHICULO;
        const VEH_MATRICULA = e.target.name === 'VEH_MATRICULA' ? e.target.value : payLoadLocal.VEH_MATRICULA;
        const VEH_DENOMINACION = e.target.name === 'VEH_DENOMINACION' ? e.target.value : payLoadLocal.VEH_DENOMINACION;
        const VEH_ID_TECDOC = e.target.name === 'VEH_ID_TECDOC' ? e.target.value : payLoadLocal.VEH_ID_TECDOC;
        const ID_CLIENTE = e.target.name === 'ID_CLIENTE' ? e.target.value : payLoadLocal.ID_CLIENTE;
        const CLI_NOMBRE = e.target.name === 'CLI_NOMBRE' ? e.target.value : payLoadLocal.CLI_NOMBRE;
        const CLI_CIF = e.target.name === 'CLI_CIF' ? e.target.value : payLoadLocal.CLI_CIF;
        const CLI_DIRECCION = e.target.name === 'CLI_DIRECCION' ? e.target.value : payLoadLocal.CLI_DIRECCION;
        const CLI_POBLACION = e.target.name === 'CLI_POBLACION' ? e.target.value : payLoadLocal.CLI_POBLACION;
        const CLI_PROVINCIA = e.target.name === 'CLI_PROVINCIA' ? e.target.value : payLoadLocal.CLI_PROVINCIA;
        const CLI_CP = e.target.name === 'CLI_CP' ? e.target.value : payLoadLocal.CLI_CP;
        const CLI_PAIS = e.target.name === 'CLI_PAIS' ? e.target.value : payLoadLocal.CLI_PAIS;
        const CLI_TELEFONO = e.target.name === 'CLI_TELEFONO' ? e.target.value : payLoadLocal.CLI_TELEFONO;
        const CLI_TELEFONO_2 = e.target.name === 'CLI_TELEFONO_2' ? e.target.value : payLoadLocal.CLI_TELEFONO_2;
        const CLI_TELEFONO_3 = e.target.name === 'CLI_TELEFONO_3' ? e.target.value : payLoadLocal.CLI_TELEFONO_3;
        const CLI_MAIL = e.target.name === 'CLI_MAIL' ? e.target.value : payLoadLocal.CLI_MAIL;
        const VALOR_DTO_GENERAL = e.target.name === 'VALOR_DTO_GENERAL' ? e.target.value : payLoadLocal.VALOR_DTO_GENERAL;
        const VALOR_IVA_1 = e.target.name === 'VALOR_IVA_1' ? e.target.value : payLoadLocal.VALOR_IVA_1;
        const VALOR_IVA_2 = e.target.name === 'VALOR_IVA_2' ? e.target.value : payLoadLocal.VALOR_IVA_2;
        const VALOR_IVA_3 = e.target.name === 'VALOR_IVA_3' ? e.target.value : payLoadLocal.VALOR_IVA_3;
        const IMPORTE_BRUTO_1 = e.target.name === 'IMPORTE_BRUTO_1' ? e.target.value : payLoadLocal.IMPORTE_BRUTO_1;
        const IMPORTE_BRUTO_2 = e.target.name === 'IMPORTE_BRUTO_2' ? e.target.value : payLoadLocal.IMPORTE_BRUTO_2;
        const IMPORTE_BRUTO_3 = e.target.name === 'IMPORTE_BRUTO_3' ? e.target.value : payLoadLocal.IMPORTE_BRUTO_3;
        const IMPORTE_BRUTO = e.target.name === 'IMPORTE_BRUTO' ? e.target.value : payLoadLocal.IMPORTE_BRUTO;
        const IMPORTE_DESCUENTOS_1 = e.target.name === 'IMPORTE_DESCUENTOS_1' ? e.target.value : payLoadLocal.IMPORTE_DESCUENTOS_1;
        const IMPORTE_DESCUENTOS_2 = e.target.name === 'IMPORTE_DESCUENTOS_2' ? e.target.value : payLoadLocal.IMPORTE_DESCUENTOS_2;
        const IMPORTE_DESCUENTOS_3 = e.target.name === 'IMPORTE_DESCUENTOS_3' ? e.target.value : payLoadLocal.IMPORTE_DESCUENTOS_3;
        const IMPORTE_DESCUENTOS = e.target.name === 'IMPORTE_DESCUENTOS' ? e.target.value : payLoadLocal.IMPORTE_DESCUENTOS;
        const IMPORTE_BASE_IMPONIBLE_1 = e.target.name === 'IMPORTE_BASE_IMPONIBLE_1' ? e.target.value : payLoadLocal.IMPORTE_BASE_IMPONIBLE_1;
        const IMPORTE_BASE_IMPONIBLE_2 = e.target.name === 'IMPORTE_BASE_IMPONIBLE_2' ? e.target.value : payLoadLocal.IMPORTE_BASE_IMPONIBLE_2;
        const IMPORTE_BASE_IMPONIBLE_3 = e.target.name === 'IMPORTE_BASE_IMPONIBLE_3' ? e.target.value : payLoadLocal.IMPORTE_BASE_IMPONIBLE_3;
        const IMPORTE_BASE_IMPONIBLE = e.target.name === 'IMPORTE_BASE_IMPONIBLE' ? e.target.value : payLoadLocal.IMPORTE_BASE_IMPONIBLE;
        const IMPORTE_IMPUESTOS_1 = e.target.name === 'IMPORTE_IMPUESTOS_1' ? e.target.value : payLoadLocal.IMPORTE_IMPUESTOS_1;
        const IMPORTE_IMPUESTOS_2 = e.target.name === 'IMPORTE_IMPUESTOS_2' ? e.target.value : payLoadLocal.IMPORTE_IMPUESTOS_2;
        const IMPORTE_IMPUESTOS_3 = e.target.name === 'IMPORTE_IMPUESTOS_3' ? e.target.value : payLoadLocal.IMPORTE_IMPUESTOS_3;
        const IMPORTE_IMPUESTOS = e.target.name === 'IMPORTE_IMPUESTOS' ? e.target.value : payLoadLocal.IMPORTE_IMPUESTOS;

        const IMPORTE_TOTAL_1 = e.target.name === 'IMPORTE_TOTAL_1' ? e.target.value : payLoadLocal.IMPORTE_TOTAL_1;
        const IMPORTE_TOTAL_2 = e.target.name === 'IMPORTE_TOTAL_2' ? e.target.value : payLoadLocal.IMPORTE_TOTAL_2;
        const IMPORTE_TOTAL_3 = e.target.name === 'IMPORTE_TOTAL_3' ? e.target.value : payLoadLocal.IMPORTE_TOTAL_3;
        const IMPORTE_TOTAL = e.target.name === 'IMPORTE_TOTAL' ? e.target.value : payLoadLocal.IMPORTE_TOTAL;
        const OBSERVACIONES_FACTURA = e.target.name === 'OBSERVACIONES_FACTURA' ? e.target.value : payLoadLocal.OBSERVACIONES_FACTURA;
        const IMPRIMIDO = e.target.name === 'IMPRIMIDO' ? e.target.value : payLoadLocal.IMPRIMIDO;
        const ENVIADO = e.target.name === 'ENVIADO' ? e.target.value : payLoadLocal.ENVIADO;
        const FECHA_IMPRESION = e.target.name === 'FECHA_IMPRESION' ? e.target.value : payLoadLocal.FECHA_IMPRESION;
        const FECHA_ENVIO = e.target.name === 'FECHA_ENVIO' ? e.target.value : payLoadLocal.FECHA_ENVIO;
        const ESTADO = e.target.name === 'ESTADO' ? e.target.value : payLoadLocal.ESTADO;
        const VEH_BASTIDOR = e.target.name === 'VEH_BASTIDOR' ? e.target.value : payLoadLocal.VEH_BASTIDOR;
        const ID_MARCA_MATRICULA = e.target.name === 'ID_MARCA_MATRICULA' ? e.target.value : payLoadLocal.ID_MARCA_MATRICULA;
        const ID_MODELO_MATRICULA = e.target.name === 'ID_MODELO_MATRICULA' ? e.target.value : payLoadLocal.ID_MODELO_MATRICULA;
        const ID_MOTOR_MATRICULA = e.target.name === 'ID_MOTOR_MATRICULA' ? e.target.value : payLoadLocal.ID_MOTOR_MATRICULA;

        const LINEAS = e.target.name === 'LINEAS' ? e.target.value : payLoadLocal.LINEAS;

        const BASTIDOR = e.target.name === 'BASTIDOR' ? e.target.value : payLoadLocal.BASTIDOR;


        setPayLoadLocal(
            {
                ID: ID,
                CODIGO: CODIGO,
                FECHA: FECHA,
                ID_VEHICULO: ID_VEHICULO,
                VEH_MATRICULA: VEH_MATRICULA,
                VEH_DENOMINACION: VEH_DENOMINACION,
                VEH_ID_TECDOC: VEH_ID_TECDOC,
                ID_CLIENTE: ID_CLIENTE,
                ID_MARCA_MATRICULA: ID_MARCA_MATRICULA,
                ID_MODELO_MATRICULA: ID_MODELO_MATRICULA,
                ID_MOTOR_MATRICULA: ID_MOTOR_MATRICULA,
                CLI_NOMBRE: CLI_NOMBRE,
                CLI_CIF: CLI_CIF,
                CLI_DIRECCION: CLI_DIRECCION,
                CLI_POBLACION: CLI_POBLACION,
                CLI_PROVINCIA: CLI_PROVINCIA,
                CLI_CP: CLI_CP,
                CLI_PAIS: CLI_PAIS,
                CLI_TELEFONO: CLI_TELEFONO,
                CLI_TELEFONO_2: CLI_TELEFONO_2,
                CLI_TELEFONO_3: CLI_TELEFONO_3,
                CLI_MAIL: CLI_MAIL,
                VALOR_DTO_GENERAL: VALOR_DTO_GENERAL,
                VALOR_IVA_1: VALOR_IVA_1,
                VALOR_IVA_2: VALOR_IVA_2,
                VALOR_IVA_3: VALOR_IVA_3,
                IMPORTE_BRUTO_1: IMPORTE_BRUTO_1,
                IMPORTE_BRUTO_2: IMPORTE_BRUTO_2,
                IMPORTE_BRUTO_3: IMPORTE_BRUTO_3,
                IMPORTE_BRUTO: IMPORTE_BRUTO,
                IMPORTE_DESCUENTOS_1: IMPORTE_DESCUENTOS_1,
                IMPORTE_DESCUENTOS_2: IMPORTE_DESCUENTOS_2,
                IMPORTE_DESCUENTOS_3: IMPORTE_DESCUENTOS_3,
                IMPORTE_DESCUENTOS: IMPORTE_DESCUENTOS,
                IMPORTE_BASE_IMPONIBLE_1: IMPORTE_BASE_IMPONIBLE_1,
                IMPORTE_BASE_IMPONIBLE_2: IMPORTE_BASE_IMPONIBLE_2,
                IMPORTE_BASE_IMPONIBLE_3: IMPORTE_BASE_IMPONIBLE_3,
                IMPORTE_BASE_IMPONIBLE: IMPORTE_BASE_IMPONIBLE,
                IMPORTE_IMPUESTOS_1: IMPORTE_IMPUESTOS_1,
                IMPORTE_IMPUESTOS_2: IMPORTE_IMPUESTOS_2,
                IMPORTE_IMPUESTOS_3: IMPORTE_IMPUESTOS_3,
                IMPORTE_IMPUESTOS: IMPORTE_IMPUESTOS,
                IMPORTE_TOTAL_1: IMPORTE_TOTAL_1,
                IMPORTE_TOTAL_2: IMPORTE_TOTAL_2,
                IMPORTE_TOTAL_3: IMPORTE_TOTAL_3,
                IMPORTE_TOTAL: IMPORTE_TOTAL,
                OBSERVACIONES_FACTURA: OBSERVACIONES_FACTURA,
                IMPRIMIDO: IMPRIMIDO,
                ENVIADO: ENVIADO,
                FECHA_IMPRESION: FECHA_IMPRESION,
                FECHA_ENVIO: FECHA_ENVIO,
                ESTADO: ESTADO,
                VEH_BASTIDOR: VEH_BASTIDOR,
                BASTIDOR: BASTIDOR,
                LINEAS: LINEAS
            }
        )
    }

    const nuevoDocumento = (payLoadLocal) => {
        let nuevoLineas = [];

        lineasManoObra.map(manoObra => {
            let obj = {
                ORDEN_LINEA: manoObra.ORDEN_LINEA,
                ID_FACTURA: payLoadLocal.ID,
                TIPO_LINEA: manoObra.TIPO_LINEA,
                REFERENCIA_SOCIO: manoObra.REFERENCIA_SOCIO,
                REFERENCIA_TECDOC: manoObra.REFERENCIA_TECDOC,
                MARCA_SOCIO: manoObra.MARCA_SOCIO,
                MARCA_SOCIO_DESCRIPCION: manoObra.MARCA_SOCIO_DESCRIPCION,
                MARCA_TECDOC: manoObra.MARCA_TECDOC,
                MARCA_TECDOC_DESCRIPCION: manoObra.MARCA_TECDOC_DESCRIPCION,
                REFERENCIA_DESCRIPCION: manoObra.REFERENCIA_DESCRIPCION,
                MANO_OBRA: manoObra.MANO_OBRA,
                PRECIO: manoObra.PRECIO,
                IVA_VALOR: manoObra.IVA_VALOR,
                DTO_VALOR: manoObra.DTO_VALOR,
                CANTIDAD: manoObra.CANTIDAD,
                IMPORTE_BRUTO: manoObra.IMPORTE_BRUTO,
                IMPORTE_DESCUENTOS: manoObra.IMPORTE_DESCUENTOS,
                IMPORTE_BASE_IMPONIBLE: manoObra.IMPORTE_BASE_IMPONIBLE,
                IMPORTE_IMPUESTOS: manoObra.IMPORTE_IMPUESTOS,
                IMPORTE_TOTAL: manoObra.IMPORTE_TOTAL
            }
            nuevoLineas.push(obj);
        })

        lineasArticulos.map(manoObra => {
            let obj = {
                ORDEN_LINEA: manoObra.ORDEN_LINEA,
                ID_FACTURA: payLoadLocal.ID,
                TIPO_LINEA: manoObra.TIPO_LINEA,
                REFERENCIA_SOCIO: manoObra.REFERENCIA_SOCIO,
                REFERENCIA_TECDOC: manoObra.REFERENCIA_TECDOC,
                MARCA_SOCIO: manoObra.MARCA_SOCIO,
                MARCA_SOCIO_DESCRIPCION: manoObra.MARCA_SOCIO_DESCRIPCION,
                MARCA_TECDOC: manoObra.MARCA_TECDOC,
                MARCA_TECDOC_DESCRIPCION: manoObra.MARCA_TECDOC_DESCRIPCION,
                REFERENCIA_DESCRIPCION: manoObra.REFERENCIA_DESCRIPCION,
                MANO_OBRA: manoObra.MANO_OBRA,
                PRECIO: manoObra.PRECIO,
                IVA_VALOR: manoObra.IVA_VALOR,
                DTO_VALOR: manoObra.DTO_VALOR,
                CANTIDAD: manoObra.CANTIDAD,
                IMPORTE_BRUTO: manoObra.IMPORTE_BRUTO,
                IMPORTE_DESCUENTOS: manoObra.IMPORTE_DESCUENTOS,
                IMPORTE_BASE_IMPONIBLE: manoObra.IMPORTE_BASE_IMPONIBLE,
                IMPORTE_IMPUESTOS: manoObra.IMPORTE_IMPUESTOS,
                IMPORTE_TOTAL: manoObra.IMPORTE_TOTAL
            }
            nuevoLineas.push(obj);
        })

        let fabricante = fabricantes.filter((fabricante) => fabricante.ManNo === fabricantePorDefecto)
        let modelo = modelos.filter((modelo) => modelo.ID === modelosPorDefecto)
        let motorizacion = motorizaciones.filter((motor) => motor.ID === motorizacionesPorDefecto)

        if (fabricante.length > 0 && modelo.length > 0 && motorizacion.length > 0) {
            payLoadLocal.VEH_DENOMINACION = fabricante[0].Term + " " + modelo[0].DESCRIPCION + " " + motorizacion[0].DESCRIPCION;
        }


        payLoadLocal.VEH_BASTIDOR = payLoadLocal.BASTIDOR;
        payLoadLocal.VEH_ID_TECDOC = payLoadLocal.ID_MOTOR_MATRICULA;
        let MOSTRAR_MESAJE = payLoadLocal.VEH_ID_TECDOC > 0 && payLoadLocal.VEH_MATRICULA === "" ? true : false;

        let formatoImporte = { ...payLoadLocal };


        formatoImporte.IMPORTE_TOTAL = convertirMonedaANumero(formatoImporte.IMPORTE_TOTAL)
        formatoImporte.IMPORTE_IMPUESTOS = convertirMonedaANumero(formatoImporte.IMPORTE_IMPUESTOS)
        formatoImporte.IMPORTE_IMPUESTOS_1 = convertirMonedaANumero(formatoImporte.IMPORTE_IMPUESTOS_1)
        formatoImporte.IMPORTE_IMPUESTOS_2 = convertirMonedaANumero(formatoImporte.IMPORTE_IMPUESTOS_2)
        formatoImporte.IMPORTE_IMPUESTOS_3 = convertirMonedaANumero(formatoImporte.IMPORTE_IMPUESTOS_3)
        formatoImporte.IMPORTE_BASE_IMPONIBLE = convertirMonedaANumero(formatoImporte.IMPORTE_BASE_IMPONIBLE)
        formatoImporte.IMPORTE_BASE_IMPONIBLE_1 = convertirMonedaANumero(formatoImporte.IMPORTE_BASE_IMPONIBLE_1)
        formatoImporte.IMPORTE_BASE_IMPONIBLE_2 = convertirMonedaANumero(formatoImporte.IMPORTE_BASE_IMPONIBLE_2)
        formatoImporte.IMPORTE_BASE_IMPONIBLE_3 = convertirMonedaANumero(formatoImporte.IMPORTE_BASE_IMPONIBLE_3)
        formatoImporte.IMPORTE_DESCUENTOS = convertirMonedaANumero(formatoImporte.IMPORTE_DESCUENTOS)
        formatoImporte.IMPORTE_DESCUENTOS_1 = convertirMonedaANumero(formatoImporte.IMPORTE_DESCUENTOS_1)
        formatoImporte.IMPORTE_DESCUENTOS_2 = convertirMonedaANumero(formatoImporte.IMPORTE_DESCUENTOS_2)
        formatoImporte.IMPORTE_DESCUENTOS_3 = convertirMonedaANumero(formatoImporte.IMPORTE_DESCUENTOS_3)
        formatoImporte.IMPORTE_BRUTO = convertirMonedaANumero(formatoImporte.IMPORTE_BRUTO)
        formatoImporte.IMPORTE_BRUTO_1 = convertirMonedaANumero(formatoImporte.IMPORTE_BRUTO_1)
        formatoImporte.IMPORTE_BRUTO_2 = convertirMonedaANumero(formatoImporte.IMPORTE_BRUTO_2)
        formatoImporte.IMPORTE_BRUTO_3 = convertirMonedaANumero(formatoImporte.IMPORTE_BRUTO_3)
        formatoImporte.VALOR_IVA_1 = convertirMonedaANumero(formatoImporte.VALOR_IVA_1)
        formatoImporte.VALOR_IVA_2 = convertirMonedaANumero(formatoImporte.VALOR_IVA_2)
        formatoImporte.VALOR_IVA_3 = convertirMonedaANumero(formatoImporte.VALOR_IVA_3)


        formatoImporte.VALOR_DTO_GENERAL = (typeof formatoImporte.VALOR_DTO_GENERAL === 'number') ? formatoImporte.VALOR_DTO_GENERAL : convertirMonedaANumero(formatoImporte.VALOR_DTO_GENERAL)


        if (MOSTRAR_MESAJE) {
            MSGERROR(traducirPagina("Ingrese_una_matricula"))
        } else {
            setFacturaEnAPI(Object.keys(props.DOCUMENTO_SELECCIONADO).length > 0 ? 1 : 0, formatoImporte, nuevoLineas).then((orden) => {
                if (orden !== undefined) {
                    if (orden.OK) {
                        MSGOK(traducirPagina("Orden_se_guardo"));
                        props.GUARDAR_DOCUMENTO("", 2, "D", props.FECHA_LOCAL.DESDE, props.FECHA_LOCAL.HASTA, props.ESTADO)
                        props.CERRAR_FICHA(false)
                    }
                }
            })
        }

    }


    const eliminarLineaArticulo = (ID_LINEA) => {
        let local = []
        lineasArticulos.map((articulo) => {
            if (articulo.ID !== ID_LINEA) {
                local.push(articulo)
            }
        })
        setLineasArticulos(local)



        let concatenrLineas = local.concat(lineasManoObra);
        recalcularTotalesLocal(concatenrLineas)
    }

    const eliminarLineaManoObra = (ID_LINEA) => {
        let local = []
        lineasManoObra.map((manoObra) => {
            if (manoObra.ID !== ID_LINEA) {
                local.push(manoObra)
            }
        })
        setLineasManoObra(local)
        let concatenrLineas = local.concat(lineasArticulos);
        recalcularTotalesLocal(concatenrLineas)
    }


    const buscarDocumento = (ID) => {
        ObtenerFacturaHTMLEnAPI(ID).then((documento) => {
            if (documento !== undefined) {
                if (documento.HTML !== null && documento.HTML !== "") {
                    setDOC_HTML(documento.HTML)
                } else {
                    setDOC_HTML("")
                }
            }
        })

    }


    const buscarDocumentoPDF = () => {

        ObtenerFacturaPDFEnAPI(payLoadLocal.ID).then((documento) => {
            if (documento !== undefined) {
                if (documento.BYTES !== null && documento.BYTES !== "") {
                    descargarPdfPasandoBase64(documento.BYTES, "FACTURA")
                }
            }
        })

    }


    const enviarDocumentoPDF = async (payLoad) => {
        payLoad.ID_ORDEN = payLoadLocal.ID
        //payLoad.CUERPO = "Envio de documento"
        setABRIR_FICHA_EMAIL(false);


        const documento = await EnviarFacturaEnAPI(true, payLoad)
        if (documento !== undefined) {
            if (documento.OK) {
                setABRIR_FICHA_EMAIL(false);
                MSGOK("Documento enviado correctamente.")
            }
        }


    }


    const cambiosVehiculo = async () => {
        if (payLoadLocal.VEH_MATRICULA !== "") {
            const data = await buscarFabricantes();

            payLoadLocal.ID_VEHICULO = 0;
            payLoadLocal.BASTIDOR = ""

            setFabricantePorDefecto(0);
            setModelosPorDefecto(0);
            setMotorizacionesPorDefecto(0);

            //buscarModelos([]);
            setMotorizaciones([]);
            setPayLoadLocal(payLoadLocal)
        }
    }

    const cambiosFabricantes = () => {
        payLoadLocal.ID_VEHICULO = 0;
        //payLoadLocal.VEH_MATRICULA = "";
        //payLoadLocal.BASTIDOR = "";

        setPayLoadLocal(payLoadLocal)
    }


    const formatearImporteFacturacion = (valor, simbolo) => {
        const formato = typeof valor === "number" ? valor : valor === "" ? "" : `${valor} ${convertirSimboloMoneda(simbolo)}`;
        return formato;
    }



    const IMPRESORA_LOCAL = async () => {
        props.GUARDAR_DOCUMENTO("", 2, "D", props.FECHA_LOCAL.DESDE, props.FECHA_LOCAL.HASTA, props.ESTADO)

        /*
        if(resultado !== undefined && resultado !== null && resultado.OK){
            props.GUARDAR_DOCUMENTO("", 2, "D", props.FECHA_LOCAL.DESDE, props.FECHA_LOCAL.HASTA)
        }
        */
    }


    return (
        <div>
            <Drawer
                width={1300}
                closable={false}
                onClose={() => {
                    abortFetch();
                    props.MODIFICAR_DOCUMENTO_SELECCIONADO({})
                    IMPRESORA_LOCAL()
                    store.dispatch({ type: "UPDATE_CLIENTE_SELECCIONADO_PARA_PRESUPUESTO_TALLER", cliente: {} })
                    store.dispatch({ type: "UPDATE_VEHICULO_SELECCIONADO_PARA_PRESUPUESTO", vehiculo: {} })
                    props.CERRAR_FICHA(false)

                }}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ zIndex: '99999999', color: '#fff' }}>

                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                            onClick={(e) => {
                                abortFetch();

                                props.MODIFICAR_DOCUMENTO_SELECCIONADO({})
                                IMPRESORA_LOCAL()
                                store.dispatch({ type: "UPDATE_CLIENTE_SELECCIONADO_PARA_PRESUPUESTO_TALLER", cliente: {} })
                                store.dispatch({ type: "UPDATE_VEHICULO_SELECCIONADO_PARA_PRESUPUESTO", vehiculo: {} })
                                props.CERRAR_FICHA(false)


                            }}>
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ textAlign: 'left', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>

                    <div style={{ flexGrow: '1', textAlign: 'end', color: '#fff', verticalAlign: 'middle', paddingRight: '10px', fontSize: '16px' }}>
                        <button className={`col-md-12 ${!hayLineasEnPortapapeles ? 'colorBloqueoBotoneraFacturacion' : 'btnColor'}`} disabled={hayLineasEnPortapapeles ? false : true}
                            style={{
                                height: '40px', width: '300px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top',
                                color: hayLineasEnPortapapeles ? '#fff' : '#999',
                                /*backgroundColor: hayLineasEnPortapapeles ? '#4285f4' : '#f7f7f7',*/
                                marginBottom: '5px', marginRight: '5px'
                            }} onClick={(e) => {
                                cargaAutomaticaDeLineas()
                            }}>
                            <ArrowDownOutlined style={{ fontSize: '20px' }} />
                            <span style={{ verticalAlign: 'middle', fontSize: '16px', marginLeft: '10px' }}>{traducirPagina("Descargar_seleccionados").toUpperCase()}</span>
                        </button>

                        <button disabled={payLoadLocal.ID === null ? true : false} className={`col-md-12 ${payLoadLocal.ID === null ? 'colorBloqueoBotoneraFacturacion' : 'btnColor'}`} style={{
                            height: '40px', width: '50px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top',
                            color: payLoadLocal.ID === null ? '#999' : '#fff',
                            /*backgroundColor: payLoadLocal.ID === null ? '#f7f7f7' : '#4285f4',*/
                            marginBottom: '5px', marginRight: '5px'
                        }} onClick={(e) => {

                            setABRIR_FICHA_EMAIL(true)
                        }}>
                            <MailOutlined style={{ fontSize: '20px' }} />
                            <span style={{ verticalAlign: 'middle', fontSize: '16px', marginLeft: '10px' }}></span>
                        </button>

                        <button disabled={payLoadLocal.ID === null ? true : false} className={`col-md-12 ${payLoadLocal.ID === null ? 'colorBloqueoBotoneraFacturacion' : 'btnColor'}`} style={{
                            height: '40px', width: '50px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top',
                            color: payLoadLocal.ID === null ? '#999' : '#fff',
                            /*backgroundColor: payLoadLocal.ID === null ? '#f7f7f7' : '#4285f4',*/
                            marginBottom: '5px', marginRight: '5px'
                        }} onClick={(e) => {
                            setABRIR_FICHA_IMPRESION(true)
                            buscarDocumento(payLoadLocal.ID)

                        }}>
                            <PrinterOutlined style={{ fontSize: '20px' }} />
                            <span style={{ verticalAlign: 'middle', fontSize: '16px', marginLeft: '10px' }}></span>
                        </button>

                        <button className="col-md-12 btnColor" style={{ height: '40px', width: '250px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top', color: '#fff', backgroundColor: '#4285f4', marginBottom: '5px' }} onClick={(e) => {
                            nuevoDocumento(payLoadLocal)
                        }}>
                            <SaveOutlined style={{ fontSize: '20px' }} />
                            <span style={{ verticalAlign: 'middle', fontSize: '16px', marginLeft: '10px' }}>{traducirPagina("Guardar_y_cerrar").toUpperCase()}</span>
                        </button>

                    </div>

                </div>

                <div className="container-fluid">
                    <MDBRow>
                        <MDBCol md="5">
                            <MDBNav tabs className="nav-justified mt-3" color="primary">
                                <MDBNavItem>
                                    <MDBNavLink className="headerTabReferencia" link to="/referencia" active={activeItemOuterTabs === "1"} onClick={() => setActiveItemOuterTabs("1")} role="tab">{traducirPagina("Identificación")}</MDBNavLink>
                                </MDBNavItem>

                                <MDBNavItem>
                                    <MDBNavLink className="headerTabReferencia" link to="/referencia" active={activeItemOuterTabs === "2"} onClick={() => { setActiveItemOuterTabs("2"); buscarFabricantes(); }} role="tab">{traducirPagina("Vehiculo")}</MDBNavLink>
                                </MDBNavItem>

                                <MDBNavItem>
                                    <MDBNavLink className="headerTabReferencia" link to="/referencia" active={activeItemOuterTabs === "3"} onClick={() => setActiveItemOuterTabs("3")} role="tab">{traducirPagina("Observaciones")}</MDBNavLink>
                                </MDBNavItem>
                            </MDBNav>


                            <MDBTabContent className="card" activeItem={activeItemOuterTabs} style={{ height: (configApp.height - 130).toString() + "px", overflow: 'auto', backgroundColor: "#eee" }}>
                                <MDBTabPane tabId="1" role="tabpanel">
                                    <div style={{ color: '#000', fontSize: '16px' }}>
                                        <div className="row">
                                            <MDBCol md="12">
                                                <h4 className="mt-1 ">{traducirPagina("Datos técnicos")}</h4>
                                                <MDBTable>
                                                    <MDBTableBody>
                                                        <tr>
                                                            <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{traducirPagina("Codigo").toUpperCase()}</td>
                                                            <td colSpan={2} style={{ textAlign: 'left', verticalAlign: 'middle' }}>{payLoadLocal.CODIGO}</td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{traducirPagina("Fecha").toUpperCase()}</td>
                                                            <td colSpan={2} id="textoACopiar" style={{ textAlign: 'left', color: '#000' }}><input type="date" name={"FECHA"} value={payLoadLocal.FECHA} onChange={(e) => guardarEstadoFormulario(e)} /> </td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{traducirPagina("Cliente").toUpperCase()}</td>
                                                            <td style={{ textAlign: 'left', width: '200px' }}>{payLoadLocal.ID_CLIENTE}</td>
                                                            <td style={{ textAlign: 'left' }}>{<button onClick={e => { store.dispatch(DrawerFichaClienteTaller(true)); store.dispatch({ type: 'UPDATE_FRONT_PARA_FICHA_PRESUPUESTO', esDesdeFichaPresupuesto: true }) }} style={{ border: '0', cursor: 'pointer' }}> <SearchOutlined style={{ fontSize: '16px', color: '#000', verticalAlign: 'text-bottom' }} /> </button>}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{traducirPagina("Nombre").toUpperCase()}</td>
                                                            <td colSpan={2} style={{ textAlign: 'left' }}><Input name="CLI_NOMBRE" placeholder={traducirPagina("Nombre_del_cliente")} value={payLoadLocal.CLI_NOMBRE} onChange={(e) => guardarEstadoFormulario(e)} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{traducirPagina("Cif").toUpperCase()}</td>
                                                            <td colSpan={2} style={{ textAlign: 'left' }}><Input name="CLI_CIF" placeholder={traducirPagina("Cif_del_cliente")} value={payLoadLocal.CLI_CIF} onChange={(e) => guardarEstadoFormulario(e)} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{traducirPagina("Provincia").toUpperCase()}</td>
                                                            <td colSpan={2} style={{ textAlign: 'left' }}><Input placeholder={traducirPagina("Provincia").toUpperCase()} name="CLI_PROVINCIA" value={payLoadLocal.CLI_PROVINCIA} onChange={(e) => guardarEstadoFormulario(e)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{traducirPagina("Poblacion").toUpperCase()}</td>
                                                            <td colSpan={2} style={{ textAlign: 'left' }}><Input placeholder={traducirPagina("Poblacion")} name="CLI_POBLACION" value={payLoadLocal.CLI_POBLACION} onChange={(e) => guardarEstadoFormulario(e)} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{traducirPagina("Direccion").toUpperCase()}</td>
                                                            <td colSpan={2} style={{ textAlign: 'left' }}><Input placeholder={traducirPagina("Direccion")} name="CLI_DIRECCION" value={payLoadLocal.CLI_DIRECCION} onChange={(e) => guardarEstadoFormulario(e)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{traducirPagina("Codigo_postal").toUpperCase()}</td>
                                                            <td colSpan={2} style={{ textAlign: 'left' }}><Input placeholder={traducirPagina("Codigo_postal")} name="CLI_CP" value={payLoadLocal.CLI_CP} onChange={(e) => guardarEstadoFormulario(e)} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{traducirPagina("Pais").toUpperCase()}</td>
                                                            <td colSpan={2} style={{ textAlign: 'left' }}><Input placeholder={traducirPagina("Pais")} name="CLI_PAIS" value={payLoadLocal.CLI_PAIS} onChange={(e) => guardarEstadoFormulario(e)} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{traducirPagina("Telefono").toUpperCase()}</td>
                                                            <td colSpan={2} style={{ textAlign: 'left' }}><Input placeholder={traducirPagina("Telefono")} name="CLI_TELEFONO" value={payLoadLocal.CLI_TELEFONO} onChange={(e) => guardarEstadoFormulario(e)} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{traducirPagina("Telefono_2").toUpperCase()}</td>
                                                            <td colSpan={2} style={{ textAlign: 'left' }}><Input placeholder={traducirPagina("Telefono_2")} name="CLI_TELEFONO_2" value={payLoadLocal.CLI_TELEFONO_2} onChange={(e) => guardarEstadoFormulario(e)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{traducirPagina("Email").toUpperCase()}</td>
                                                            <td colSpan={2} style={{ textAlign: 'left' }}><Input placeholder={traducirPagina("Email")} name="CLI_MAIL" value={payLoadLocal.CLI_MAIL} onChange={(e) => guardarEstadoFormulario(e)} /></td>
                                                        </tr>
                                                    </MDBTableBody>
                                                </MDBTable>
                                            </MDBCol>
                                        </div>
                                    </div>
                                </MDBTabPane>

                                <MDBTabPane tabId="2" role="tabpanel">
                                    <div style={{ color: '#000', fontSize: '16px' }}>
                                        <div className="row">
                                            <MDBCol md="12">
                                                <MDBTable responsive hover>
                                                    <MDBTableBody>
                                                        <tr>
                                                            <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{traducirPagina("Matricula").toUpperCase()}</td>
                                                            <td style={{ textAlign: 'left' }}><Input placeholder={traducirPagina("Matricula")} name="VEH_MATRICULA" onChange={(e) => guardarEstadoFormulario(e)} value={payLoadLocal.VEH_MATRICULA} onBlur={(e) => { cambiosVehiculo() }} /></td>
                                                            <td style={{ textAlign: 'left' }}>{<button disabled={payLoadLocal.ID_CLIENTE === null || payLoadLocal.ID_CLIENTE === 0 ? true : false} onClick={e => { setFichaVehiculo(true) }} style={{ border: '0', cursor: 'pointer', verticalAlign: 'sub' }}> <SearchOutlined style={{ fontSize: '16px', color: '#000', verticalAlign: 'text-bottom' }} /> </button>}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{traducirPagina("Marca").toUpperCase()}</td>
                                                            <td colSpan={2} style={{ textAlign: 'left' }}>
                                                                {fabricantes !== null ?

                                                                    <select value={fabricantePorDefecto} className="custom-select" style={{ width: "280px" }} onChange={(e) => { setFabricantePorDefecto(e.target.value); buscarModelos(e.target.value); setMotorizaciones([]); setMotorizacionesPorDefecto(0) }}>

                                                                        <option value={0}>{traducirPagina("Seleccione_una_marca").toUpperCase()}</option>

                                                                        {fabricantes.length > 0 ?
                                                                            fabricantes.map((registro) =>
                                                                                <option key={registro.ManNo} value={registro.ManNo}>{registro.Term}</option>
                                                                            )
                                                                            :
                                                                            null
                                                                        }
                                                                    </select>
                                                                    :
                                                                    <h5>{traducirPagina("No_fabricantes")}</h5>
                                                                }
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{traducirPagina("Modelo").toUpperCase()}</td>
                                                            <td colSpan={2} style={{ textAlign: 'left' }}>
                                                                {modelos !== null ?
                                                                    <select value={modelosPorDefecto} className="custom-select" style={{ width: "280px" }} onChange={(e) => { setModelosPorDefecto(e.target.value); buscarMotorizaciones(e.target.value); cambiosFabricantes() }}>
                                                                        <option value={0}>{traducirPagina("Seleccione_un_modelo").toUpperCase()}</option>

                                                                        {modelos.length > 0 ?
                                                                            modelos.map((registro) =>
                                                                                <option key={registro.ID} value={registro.ID}>{registro.DESCRIPCION}</option>
                                                                            )
                                                                            :
                                                                            null
                                                                        }
                                                                    </select>
                                                                    :
                                                                    <h5>{traducirPagina("No_modelos")}</h5>
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            {/*registro.DESCRIPCION + " & " + registro.DESDE + " - " + (registro.HASTA === "" ? "Hasta la fecha actual":registro.HASTA */}
                                                            <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{traducirPagina("Motor").toUpperCase()}</td>
                                                            <td colSpan={2} style={{ textAlign: 'left' }}>
                                                                {motorizaciones !== null ?
                                                                    <select value={motorizacionesPorDefecto} className="custom-select" style={{ width: "280px" }} onChange={(e) => { payLoadLocal.ID_MOTOR_MATRICULA = parseInt(e.target.value); setMotorizacionesPorDefecto(e.target.value); cambiosFabricantes() }}>
                                                                        <option value={0}>{traducirPagina("Seleccione_cod_motor").toUpperCase()}</option>

                                                                        {motorizaciones.length > 0 ?
                                                                            motorizaciones.map((registro) =>
                                                                                <option key={registro.ID} value={registro.ID}>{registro.DESCRIPCION}</option>
                                                                            )
                                                                            :
                                                                            null
                                                                        }
                                                                    </select>
                                                                    :
                                                                    <h5>{traducirPagina("No_motorizaciones")}</h5>
                                                                }
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{traducirPagina("Bastidor").toUpperCase()}</td>
                                                            <td colSpan={2} style={{ textAlign: 'left' }}><Input placeholder={traducirPagina("Bastidor")} name="BASTIDOR" onChange={(e) => guardarEstadoFormulario(e)} value={payLoadLocal.BASTIDOR} /></td>
                                                        </tr>

                                                    </MDBTableBody>
                                                </MDBTable>
                                            </MDBCol>
                                        </div>
                                    </div>
                                </MDBTabPane>

                                <MDBTabPane tabId="3" role="tabpanel">
                                    <div style={{ color: '#000', fontSize: '16px' }}>
                                        <div className="row">
                                            <MDBCol md="12">
                                                <MDBTable responsive hover>
                                                    <MDBTableBody>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("Observaciones").toUpperCase()}</td>
                                                        </tr>
                                                        <tr>
                                                            <td> <TextArea rows={20} name="OBSERVACIONES_FACTURA" value={payLoadLocal.OBSERVACIONES_FACTURA} onChange={(e) => guardarEstadoFormulario(e)} /> </td>
                                                        </tr>
                                                    </MDBTableBody>
                                                </MDBTable>
                                            </MDBCol>
                                        </div>
                                    </div>
                                </MDBTabPane>

                            </MDBTabContent>
                        </MDBCol>

                        <MDBCol md="7">
                            <div className="flex-container mt-3">
                                <div style={{ flexGrow: '6', textAlign: 'right' }} className="mb-2">
                                    <button className="activebtnMenu2 btnEstiloPersonalizado" style={{ height: '40px', width: '180px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', marginLeft: '10px', color: '#1890ff', backgroundColor: 'transparent', cursor: 'pointer' }} onClick={() => {
                                        tabPorDefecto === "1" ? setAbrirFichaManoObra(true) : setAbrirFichaArticulo(true)

                                        setLineaSeleccionada({})
                                    }
                                    }><PlusOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Nueva_linea")} </span>  </button>
                                </div>
                            </div>


                            <Tabs defaultActiveKey={"1"} activeKey={tabPorDefecto} onChange={(tab) => setTabPorDefecto(tab)}>

                                <TabPane tab={traducirPagina("Mano_de_obra").toUpperCase()} key="1">
                                    <div style={{ height: (configApp.height - 400).toString() + "px", overflowY: 'auto', overflowX: 'hidden' }} >
                                        {lineasManoObra.length === 0 ?

                                            <div style={{ textAlign: 'center' }}>
                                                <h4>{traducirPagina("No_mano_de_obra")}</h4>
                                            </div>
                                            :
                                            lineasManoObra.map((manoObra, i) =>
                                                <div key={i} className="containerReferenciaItem">

                                                    <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                                                        <Col md={18} >

                                                            <div style={{ color: '#666' }} dangerouslySetInnerHTML={{ __html: (manoObra.MANO_OBRA.replaceAll("\n", "<br>")) }}>

                                                            </div>
                                                        </Col>

                                                        <Col md={6} style={{ textAlign: 'right' }}>


                                                            <span>{traducirPagina("Precio") + ": " + convertirMoneda(manoObra.IMPORTE_BASE_IMPONIBLE, " €")}</span>
                                                            <br />
                                                            <Row style={{ fontSize: '16px', fontWeight: '500', color: '#000' }} className="mdb-skin">
                                                                <Col md={24}>
                                                                    <MDBBtn onClick={() => {
                                                                        setAbrirFichaManoObra(true)
                                                                        setLineaSeleccionada(manoObra)
                                                                    }} style={{ marginRight: '5px' }}
                                                                        className="btn-matricula paddindDeTres">
                                                                        <EditOutlined style={{ color: '#999', cursor: 'pointer', fontSize: '20px' }} />
                                                                    </MDBBtn>


                                                                    <MDBBtn onClick={() => { eliminarLineaManoObra(manoObra.ID) }}
                                                                        className="btn-matricula  paddindDeTres" >
                                                                        <DeleteOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                    </MDBBtn>
                                                                </Col>

                                                            </Row>
                                                        </Col>

                                                    </Row>
                                                </div>
                                            )
                                        }
                                    </div>
                                </TabPane>

                                <TabPane tab={traducirPagina("Articulos").toUpperCase()} key="2" >
                                    <div style={{ height: (configApp.height - 400).toString() + "px", overflow: 'auto' }} >
                                        {lineasArticulos.length === 0 ?
                                            <div style={{ textAlign: 'center' }}>
                                                <h4>{traducirPagina("No_articulos")}</h4>
                                            </div>
                                            :
                                            <MDBTable responsive hover className="mdb-skin">
                                                <MDBTableHead>
                                                    <tr>
                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '110px', verticalAlign: 'baseline' }}>{traducirPagina("Referencia")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", verticalAlign: 'baseline' }}>{traducirPagina("Descripcion")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '40px', verticalAlign: 'baseline' }}>{traducirPagina("Uds")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '90px', verticalAlign: 'baseline' }}>{traducirPagina("Imp_bruto")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '50px', verticalAlign: 'baseline' }}>{traducirPagina("Dto")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '110x', verticalAlign: 'baseline' }}>{traducirPagina("B_imponible")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '90px', verticalAlign: 'baseline' }}></th>

                                                    </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                    {lineasArticulos.length > 0 ?
                                                        lineasArticulos.map((articulo, i) =>
                                                            <tr key={i} style={{ color: '#666' }}>
                                                                <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '110px', verticalAlign: 'inherit' }}>{articulo.REFERENCIA_TECDOC}</td>
                                                                <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", verticalAlign: 'inherit' }}>{articulo.REFERENCIA_DESCRIPCION}</td>
                                                                <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '40px', verticalAlign: 'inherit', textAlign: 'right' }}>{articulo.CANTIDAD}</td>
                                                                <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '90px', verticalAlign: 'inherit', textAlign: 'right' }}>{convertirMoneda(articulo.IMPORTE_BRUTO, "€")}</td>
                                                                <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '50px', verticalAlign: 'inherit', textAlign: 'right' }}>{articulo.DTO_VALOR + "%"}</td>
                                                                <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '110px', verticalAlign: 'inherit', textAlign: 'right', color: '#000' }}>{convertirMoneda(articulo.IMPORTE_BASE_IMPONIBLE, "€")}</td>

                                                                <td style={{ paddingLeft: 0, fontSize: "16px", textAlign: 'right', verticalAlign: 'middle' }} className="mdb-skin">
                                                                    <Col md={24}>
                                                                        <MDBBtn onClick={() => {
                                                                            setAbrirFichaArticulo(true)
                                                                            setLineaSeleccionada(articulo)

                                                                        }} style={{ marginRight: '5px' }}
                                                                            className="btn-matricula paddindDeTres">
                                                                            <EditOutlined style={{ color: '#999', cursor: 'pointer', fontSize: '20px' }} onClick={() => {

                                                                            }} />
                                                                        </MDBBtn>


                                                                        <MDBBtn onClick={() => { eliminarLineaArticulo(articulo.ID) }}
                                                                            className="btn-matricula  paddindDeTres" >
                                                                            <DeleteOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                        </MDBBtn>
                                                                    </Col>

                                                                </td>
                                                            </tr>

                                                        )
                                                        : null
                                                    }
                                                </MDBTableBody>
                                            </MDBTable>
                                        }
                                    </div>
                                </TabPane>
                            </Tabs>

                            <div style={{ color: '#000', marginTop: '10px' }}>
                                <MDBTable>
                                    <MDBTableHead>
                                        <tr style={{ color: '#666' }}>
                                            <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '120px', verticalAlign: 'baseline', textAlign: 'right' }}>{traducirPagina("Bruto").toUpperCase()}</th>
                                            <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '100px', verticalAlign: 'baseline', textAlign: 'right' }}>{traducirPagina("Dto").toUpperCase()}</th>
                                            <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '120px', verticalAlign: 'baseline', textAlign: 'right' }}>{traducirPagina("B_imponible").toUpperCase()}</th>
                                            <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '80px', verticalAlign: 'baseline', textAlign: 'right' }}>{traducirPagina("IVA").toUpperCase()}</th>
                                            <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '120px', verticalAlign: 'baseline', textAlign: 'right' }}>{traducirPagina("Impuestos").toUpperCase()}</th>

                                            <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", verticalAlign: 'baseline', textAlign: 'right', color: '#4285f4', backgroundColor: '#f7f7f7' }}>{traducirPagina("Total").toUpperCase()}</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        <tr style={{ color: '#666' }}>
                                            <td style={{ textAlign: 'right', fontSize: "12px" }}>{formatearImporteFacturacion(payLoadLocal.IMPORTE_BRUTO_1, "€")}</td>
                                            <td style={{ textAlign: 'right', fontSize: "12px" }}>{formatearImporteFacturacion(payLoadLocal.IMPORTE_DESCUENTOS_1, "€")}</td>
                                            <td style={{ textAlign: 'right', fontSize: "12px" }}>{formatearImporteFacturacion(payLoadLocal.IMPORTE_BASE_IMPONIBLE_1, "€")}</td>
                                            <td style={{ textAlign: 'right', fontSize: "12px" }}>{formatearImporteFacturacion(payLoadLocal.VALOR_IVA_1, "%")}</td>
                                            <td style={{ textAlign: 'right', fontSize: "12px" }}>{formatearImporteFacturacion(payLoadLocal.IMPORTE_IMPUESTOS_1, "€")}</td>
                                            <td style={{ textAlign: 'right', color: '#4285f4', backgroundColor: '#f7f7f7', fontSize: "12px", borderBottom: 'solid 1px #f7f7f7' }}>{"\u00A0"}</td>
                                        </tr>
                                        <tr style={{ color: '#666' }}>
                                            <td style={{ textAlign: 'right', fontSize: "12px" }}>{formatearImporteFacturacion(payLoadLocal.IMPORTE_BRUTO_2, "€")}</td>
                                            <td style={{ textAlign: 'right', fontSize: "12px" }}>{formatearImporteFacturacion(payLoadLocal.IMPORTE_DESCUENTOS_2, "€")}</td>
                                            <td style={{ textAlign: 'right', fontSize: "12px" }}>{formatearImporteFacturacion(payLoadLocal.IMPORTE_BASE_IMPONIBLE_2, "€")}</td>
                                            <td style={{ textAlign: 'right', fontSize: "12px" }}>{formatearImporteFacturacion(payLoadLocal.VALOR_IVA_2, "%")}</td>
                                            <td style={{ textAlign: 'right', fontSize: "12px" }}>{formatearImporteFacturacion(payLoadLocal.IMPORTE_IMPUESTOS_2, "€")}</td>
                                            <td style={{ textAlign: 'right', color: '#4285f4', backgroundColor: '#f7f7f7', fontSize: "12px", borderBottom: 'solid 1px #f7f7f7' }}>{"\u00A0"}</td>
                                        </tr>
                                        <tr style={{ color: '#666' }}>
                                            <td style={{ textAlign: 'right', fontSize: "12px" }}>{formatearImporteFacturacion(payLoadLocal.IMPORTE_BRUTO_3, "€")}</td>
                                            <td style={{ textAlign: 'right', fontSize: "12px" }}>{formatearImporteFacturacion(payLoadLocal.IMPORTE_DESCUENTOS_3, "€")}</td>
                                            <td style={{ textAlign: 'right', fontSize: "12px" }}>{formatearImporteFacturacion(payLoadLocal.IMPORTE_BASE_IMPONIBLE_3, "€")}</td>
                                            <td style={{ textAlign: 'right', fontSize: "12px" }}>{formatearImporteFacturacion(payLoadLocal.VALOR_IVA_3, "%")}</td>
                                            <td style={{ textAlign: 'right', fontSize: "12px" }}>{formatearImporteFacturacion(payLoadLocal.IMPORTE_IMPUESTOS_3, "€")}</td>
                                            <td style={{ textAlign: 'right', color: '#4285f4', backgroundColor: '#f7f7f7', fontSize: "12px" }}>{"\u00A0"}</td>
                                        </tr>
                                        <tr style={{ color: '#666' }}>
                                            <td style={{ textAlign: 'right' }}>{formatearImporteFacturacion(payLoadLocal.IMPORTE_BRUTO, "€")}</td>
                                            <td style={{ textAlign: 'right' }}>{formatearImporteFacturacion(payLoadLocal.IMPORTE_DESCUENTOS, "€")}</td>
                                            <td style={{ textAlign: 'right' }}>{formatearImporteFacturacion(payLoadLocal.IMPORTE_BASE_IMPONIBLE, "€")}</td>
                                            <td style={{ textAlign: 'right', fontSize: "12px" }}>{"\u00A0"}</td>
                                            <td style={{ textAlign: 'right' }}>{formatearImporteFacturacion(payLoadLocal.IMPORTE_IMPUESTOS, "€")}</td>
                                            <td style={{ textAlign: 'right', color: '#4285f4', backgroundColor: '#f7f7f7', fontSize: '20px' }}>{formatearImporteFacturacion(payLoadLocal.IMPORTE_TOTAL, "€")}</td>
                                        </tr>
                                    </MDBTableBody>
                                </MDBTable>
                            </div>

                        </MDBCol>
                    </MDBRow>
                </div>
            </Drawer>

            {abrirFichaManoObra ?
                <ManoObraGenerico
                    title={traducirPagina("Mano_de_obra").toUpperCase()}
                    ABRIR_FICHA={abrirFichaManoObra}
                    CERRAR_FICHA={setAbrirFichaManoObra}
                    lineaSeleccionada={lineaSeleccionada}
                    payLoadLocal={payLoadLocal}
                    statePayLoadDocumento={setPayLoadLocal}
                    stateLineasManoObra={setLineasManoObra}
                    lineasArticulos={lineasArticulos}
                    lineas={lineasManoObra}
                    LIMPIAR_SELECCIONADA={setLineaSeleccionada}
                >
                </ManoObraGenerico>
                :
                null
            }

            {abrirFichaArticulo ?
                <ArticuloGenerico
                    title={traducirPagina("Ficha_articulo").toUpperCase()}
                    ABRIR_FICHA={abrirFichaArticulo}
                    CERRAR_FICHA={setAbrirFichaArticulo}
                    lineas={lineasArticulos}
                    lineasManoObra={lineasManoObra}
                    setLineasArticulos={setLineasArticulos}
                    lineaSeleccionada={lineaSeleccionada}
                    setLineaSeleccionada={setLineaSeleccionada}
                    setPayLoadLocal={setPayLoadLocal}
                    payLoadLocal={payLoadLocal}
                >
                </ArticuloGenerico>
                :
                null}


            {ABRIR_FICHA_IMPRESION ?
                <FichaImpresionGenerico
                    title={traducirPagina("Ficha_factura_de_venta").toUpperCase()}
                    ABRIR_FICHA={ABRIR_FICHA_IMPRESION}
                    CERRAR_FICHA={setABRIR_FICHA_IMPRESION}
                    DOCUMENTO={DOC_HTML}
                    MODIFICAR_DOCUMENTO={setDOC_HTML}
                    DESCARGAR_DOCUMENTO={buscarDocumentoPDF}


                    DOCUMENTO_SELECCIONADO={props.DOCUMENTO_SELECCIONADO}
                    IMPRESORA={IMPRESORA_LOCAL}

                >
                </FichaImpresionGenerico>
                :
                null
            }

            {ABRIR_FICHA_EMAIL ?
                <FichaEnvioEmail
                    title={traducirPagina("Ficha_enviar_email").toUpperCase()}
                    ABRIR_FICHA={ABRIR_FICHA_EMAIL}
                    CERRAR_FICHA={setABRIR_FICHA_EMAIL}
                    ENVIAR_EMAIL={enviarDocumentoPDF}
                    PARA={payLoadLocal.CLI_MAIL}
                >
                </FichaEnvioEmail>
                :
                null
            }

            {fichaVehiculo ?
                <FichaVehClienteGenerico
                    title={traducirPagina("Ficha_vehiculo").toUpperCase()}
                    ID_CLIENTE={payLoadLocal.ID_CLIENTE}
                    ABRIR_FICHA={fichaVehiculo}
                    CERRAR_FICHA={setFichaVehiculo}
                    VEHICULO_SELECCIONADO={vehiculoSeleccionado}
                ></FichaVehClienteGenerico>
                :
                null
            }

        </div>
    )
}


MantenimientoFactura.propTypes = {
    title: PropTypes.string,
    DOCUMENTO_SELECCIONADO: PropTypes.object,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    GUARDAR_DOCUMENTO: PropTypes.func,
    FECHA_LOCAL: PropTypes.object,
    MODIFICAR_DOCUMENTO_SELECCIONADO: PropTypes.object,
    ESTADO: PropTypes.string
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        openDrawerFichaClienteTaller: state.estadoDrawer.openDrawerFichaClienteTaller,
        VehiculoTallerSeleccionado: state.VehiculoTallerSeleccionado,
        PresupuestoTallerSeleccionado: state.PresupuestoTallerSeleccionado,
        ALBARAN_SELECCIONADO: state.ALBARAN_SELECCIONADO

    };
}

export default connect(mapStateToProps)(MantenimientoFactura);