import React from "react";
import PropTypes from 'prop-types';
import {
    MDBRow, MDBCol, MDBTable, MDBTableHead, MDBTableBody
} from 'mdbreact';
import { connect } from 'react-redux';
import store from "../store";
import { getFocus, traducirPagina, efectoRemoveBuscadorReferencia, limpiarCocheStore } from "../librerias/funciones"
import {
    CloseOutlined,
    SearchOutlined
} from '@ant-design/icons';
import { setHistoricApp, showChildrenDrawerLevel2, mostrarVistaGrafica, showItemReferencia, estadoAgrupacionDeMarcas, updateVisualizacionFiltro } from "../action/actionCreators";
import { getIdTecdocMotorByIdHaynes, setHistorialBusqueda, ObtenerModelosEnAPI } from "../action/actionCreatorsRequests";

const DrawerMotorMoto = ({ props }) => {

    const [modelosMotos, setModelosMotos] = React.useState([]);
    const [textoBuscador, setTextoBuscador] = React.useState("");




    const ObtenerModelos = async (ID_FABRICANTE) => {
        const registros = await ObtenerModelosEnAPI(ID_FABRICANTE)

        if (registros !== undefined && registros !== null && registros.MOTORESTURISMOS !== undefined && registros.MOTORESTURISMOS !== null && registros.MOTORESTURISMOS.length > 0) {

            registros.MOTORESTURISMOS.map((registro, i) => {
                registro.VISIBLE = true;
                registro.INTERNO = i
            })

            setModelosMotos(registros.MOTORESTURISMOS)
        } else {
            setModelosMotos(null)

        }
    }


    const filtroLocalDeLaTabla = (textoAFiltrar, registros) => {
        let resultado = []
        /*
           item.DESDE.toString().toUpperCase().includes(textoAFiltrar.toUpperCase()) ||
                        item.HASTA.toString().toUpperCase().includes(textoAFiltrar.toUpperCase()) ||
                        item.KW.toString().toUpperCase().includes(textoAFiltrar.toUpperCase()) ||
                        item.CV.toString().toUpperCase().includes(textoAFiltrar.toUpperCase()) ||
                        item.CC.toString().toUpperCase().includes(textoAFiltrar.toUpperCase())
        
        */

        if (textoAFiltrar.trim() !== "") {
            if (registros !== null) {

                let resultadoFiltro = registros.filter((item) => {
                    return item.DESCRIPCION.toUpperCase().includes(textoAFiltrar.toUpperCase()) ||
                        item.DESCRIPCION.toUpperCase() === textoAFiltrar.toUpperCase() ||
                        item.DESDE.toString().toUpperCase().includes(textoAFiltrar.toUpperCase()) ||
                        item.HASTA.toString().toUpperCase().includes(textoAFiltrar.toUpperCase()) ||
                        item.KW.toString().toUpperCase().includes(textoAFiltrar.toUpperCase()) ||
                        item.CV.toString().toUpperCase().includes(textoAFiltrar.toUpperCase()) ||
                        item.CC.toString().toUpperCase().includes(textoAFiltrar.toUpperCase())
        

                })

                if (resultadoFiltro.length > 0) {
                    resultado = resultadoFiltro;
                } else {
                    resultado = [];
                }


            }
        } else {
            resultado = registros;
        }
        return resultado
    }




    React.useEffect(() => {
        ObtenerModelos(props.ID_FABRICANTE)
    }, [])


    /*
        if (Object.keys(enginesType).length > 0) {
            getFocus("imputdropmotorizacion");
        }
    
        */

    const LISTA_MOTOS = modelosMotos.filter((item) => item.VISIBLE);

    return (

        <MDBRow>
            <MDBCol md="12">

                <div className="mdb-skin">

                    <div className="mt-3 pb-2">
                        <div className="grid-container" style={{ backgroundColor: '#eee' }}>
                            <div className="grid-item">
                                <input className="form-control eliminarFoco " type="text" aria-label="Search" placeholder={"Búscador de modelos"} style={{ backgroundColor: "transparent", border: '0px', height: '100%', color: '#000', width: '400px' }} name="txtInputCliente" autoComplete="off" onChange={(e) => {
                                    setTextoBuscador(e.target.value)

                                    let result = filtroLocalDeLaTabla(e.target.value, modelosMotos);

                                    modelosMotos.map((registro) => {
                                        const buscar = result.find((item) => item.INTERNO === registro.INTERNO)
                                        if (buscar === undefined) {
                                            registro.VISIBLE = false;
                                        } else {
                                            registro.VISIBLE = true;

                                        }
                                    })

                                    setModelosMotos([...modelosMotos])

                                }} value={textoBuscador} />
                            </div>

                            <div className="grid-item" >
                                <button style={{ visibility: textoBuscador.trim() === "" ? 'hidden' : 'visible', border: 'none' }} className="btn-matriculav2 btnEliminarBuscador" onClick={(e) => {

                                    setTextoBuscador("");
                                    modelosMotos.map((registro) => {
                                        registro.VISIBLE = true;
                                    })

                                    setModelosMotos([...modelosMotos])

                                }}>  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </button>
                            </div>

                            <div className="grid-item barraLateral" ></div>

                            <div className="grid-item">
                                <button className="btn-matricula btnEliminarBuscador" style={{ border: 'none' }}>  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </button>
                            </div>
                        </div>
                    </div>
                    {LISTA_MOTOS === undefined || LISTA_MOTOS === null ?
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("No_resultados")}</h3> </div>
                        :
                        LISTA_MOTOS.length === 0 ?
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                            :

                            <MDBTable hover>
                                <MDBTableHead>
                                    <tr>
                                        <th style={{ textAlign: 'left' }}>{traducirPagina("Modelo")}</th>
                                        <th>{traducirPagina("Año")}</th>
                                        <th>KW</th>
                                        <th>CV</th>
                                        <th>CC</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {

                                        LISTA_MOTOS.map((row, i) =>

                                            <tr key={i} style={{ padding: '.40rem 1.20rem', cursor: 'pointer' }}
                                                onClick={() => {

                                                    let ID_VEHICULO = row.ID === 0 ? row.ID_MODELO_CATALOGO : row.ID

                                                    efectoRemoveBuscadorReferencia("buscadorReferencia", true);
                                                    limpiarCocheStore()
                                                    store.dispatch(estadoAgrupacionDeMarcas({ marcas: [] }));
                                                    store.dispatch(showChildrenDrawerLevel2(false));
                                                    store.dispatch(getIdTecdocMotorByIdHaynes(row.ID, row));

                                                    store.dispatch({ type: "STATE_ID_MODELO_MOTO", ID_MODELO_MOTO: row.ID_MODELO_CATALOGO });

                                                    //store.dispatch({ type: "SET_TIPO_COMBUSTIBLE", TIPO_COMBUSTIBLE: row.TIPO_COMBUSTIBLE })

                                                    store.dispatch(showItemReferencia("tree"));
                                                    // store.dispatch(setHistoricApp(row, "/motorizacion", "tipomotor"));

                                                    let marca = {
                                                        ID: props.FABRICANTE.ID,
                                                        DESCRIPCION: props.FABRICANTE.Marca
                                                    }

                                                    let modelo = {
                                                        ID: 0,
                                                        DESCRIPCION: row.DESCRIPCION,
                                                    }


                                                    let objetoVehiculo = {
                                                        ID: ID_VEHICULO,
                                                        DESCRIPCION: row.DESCRIPCION
                                                    };

                                                    store.dispatch(setHistoricApp(marca, "/allbrands", "brand"));
                                                    store.dispatch(setHistoricApp(modelo, "/modelos", "modelo"));
                                                    store.dispatch(setHistoricApp(objetoVehiculo, "/motorizacion", "tipomotor"));


                                                    if (row.ID != 0) {
                                                        setHistorialBusqueda({
                                                            MARCA: props.FABRICANTE.ID,
                                                            MODELO: 0,
                                                            MOTORIZACION: row.ID,
                                                            NOMBRE_MARCA: props.FABRICANTE.Marca,
                                                            NOMBRE_MODELO: "",
                                                            NOMBRE_MOTOR: row.DESCRIPCION

                                                        })
                                                    }




                                                    store.dispatch({ type: "REPLACE_VEHICULO_MATRICULA", vehiculoPorMatricula: {} })
                                                    store.dispatch(mostrarVistaGrafica(false))
                                                    store.dispatch(updateVisualizacionFiltro(true));


                                                }}>
                                                <td style={{ textAlign: 'left' }}>{row.DESCRIPCION}</td>
                                                <td>{`${(row === "0" ? "..." : row.DESDE)} - ${row.HASTA === "0" ? "..." : row.HASTA}`}</td>
                                                <td>{row.KW === "" ? "..." : row.KW}</td>
                                                <td>{row.CV === "" ? "..." : row.CV}</td>
                                                <td>{row.CC === "" ? "..." : row.CC}</td>
                                            </tr>

                                        )}
                                </MDBTableBody>
                            </MDBTable>


                    }

                </div>


            </MDBCol>
        </MDBRow>
    );
}

DrawerMotorMoto.propTypes = {
    ID_FABRICANTE: PropTypes.number,
    FABRICANTE: PropTypes.object
};

const mapStateToProps = (state, props) => {
    return {
        props: props,

    };
}


export default connect(mapStateToProps)(DrawerMotorMoto);