import React from "react";
import ContainerApp from "./components/ContainerApp";
import List_Tree from './pages/List_Tree'
import login_page from './pages/Login_Page'
import { Route, Switch, Router } from "react-router-dom";
import MasterPage from './pages/master_page';
import history from './history';



function App() {
  return (
    <div className="App">
      <MasterPage />
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={login_page} />
          <Route exact path="/referencia" component={ContainerApp} />
          <Route exact path="/piezas" component={List_Tree} />
        </Switch>
      </Router>
    </div>


  );
}
export default App;

