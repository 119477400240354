import React from "react";

import { connect } from 'react-redux';
import { MDBBtn } from 'mdbreact';

import { Layout, Tree, Checkbox } from 'antd';
import { createRipple } from "../action/actionCreators"

import { actualizarNodoTree, mostrarVistaGrafica, getListTreeGraficos, detenerBusquedaDetalles, updateGruposMontajeSeleccionados, updateGruposMarcasSeleccionadas, verFiltroPorMarca, verFiltroPorGrupo } from "../action/actionCreators";
import { getListSuplier } from "../action/actionCreatorsRequests";

import ContainerReferencia from "../components/ContainerReferencia";
import { crearListaKeysTree, mostarReferenciasEnPantalla, obtenerPathNodo, traducirPagina, filtroTree, obtenerTipoDeBusquedaActual } from '../librerias/funciones'


import store from '../store'
import {
  ApartmentOutlined,
  SearchOutlined,
  CloseOutlined
} from '@ant-design/icons';



const { DirectoryTree } = Tree;

const List_Tree = ({ listaTree, configApp, grupoDeMarcas, filtrosReferencia, listaTreeFiltrada, FiltroGrupoPorDefecto, codmotorizacion }) => {
  let redmensionarAltura = (configApp.height - 192).toString();
  const [todasLasMarcas, setMarcas] = React.useState([])
  const [activarTodasLasMarcas, setactivarTodasLasMarcas] = React.useState(true);
  const [todosLosGrupos, setGrupos] = React.useState([]);
  const [activarTodosLosGrupos, setactivarTodosLosGrupos] = React.useState(true);

  const [checkedListMarcas, setCheckedListMarcas] = React.useState([]);
  const [checkedListGrupos, setCheckedListGrupos] = React.useState([]);
  const [textoArbol, setTextoArbol] = React.useState("");
  const [nodoIdSeleccionado, setNodoIdSeleccionado] = React.useState([]);
  const [filtroArbol, setFiltroArbol] = React.useState([]);
  const [filtroActivado, setfiltroActivado] = React.useState(false);
  const [actulizarGrupo, setActulizarGrupo] = React.useState(true);

  React.useEffect(() => {
    if (filtroArbol.length > 0 && listaTree.length === 0) {
      setNodoIdSeleccionado([])
      setFiltroArbol([])
      setTextoArbol("")
    }

    if (grupoDeMarcas.marcas.length === 0 && !activarTodasLasMarcas) {
      setactivarTodasLasMarcas(true)
      setMarcas([]);
      setCheckedListMarcas([])
    }

    if (grupoDeMarcas.marcas.length > 0 && activarTodasLasMarcas) {
      setMarcas(grupoDeMarcas.marcas);
      setactivarTodasLasMarcas(false)
    }

    if (grupoDeMarcas.grupoMontaje !== undefined) {
      if (grupoDeMarcas.grupoMontaje.length > 0 && activarTodosLosGrupos) {
        setGrupos(grupoDeMarcas.grupoMontaje);
        setactivarTodosLosGrupos(false);


      }
    } else {
      if (grupoDeMarcas.grupoMontaje === undefined && !activarTodosLosGrupos) {
        setactivarTodosLosGrupos(true);
        setGrupos([]);
        setCheckedListGrupos([]);
      }
    }

  });




  const actulizarMarcasSeleccionadas = list => {
    setCheckedListMarcas(list);
  };


  const verficarCambiosListaMarcas = () => {
    setCheckedListMarcas(filtrosReferencia.verTodasLasMarcas ? todasLasMarcas : []);
    //setCheckAllMarcas(filtrosReferencia.verTodasLasMarcas);


  };

  const actulizarGruposSeleccionadas = list => {
    setCheckedListGrupos(list);
  };

  const verficarCambiosListaGrupos = () => {
    setCheckedListGrupos(filtrosReferencia.verTodosLosGrupos ? todosLosGrupos : []);
    //setCheckAllGrupos(filtrosReferencia.verTodosLosGrupos);
  };

  const aplicarFiltroPorMarca = (e) => {
    let texto = e.target.value.toUpperCase();
    if (texto.length > 2) {
      let marcas = grupoDeMarcas.marcas.filter(marca => marca.toUpperCase().includes(texto));
      if (marcas.length === 0) {
        setactivarTodasLasMarcas(true)
      } else {
        setMarcas(marcas);
        setactivarTodasLasMarcas(false)
      }
    } else {
      setactivarTodasLasMarcas(true)
    }
  }
  const aplicarFiltroPorGrupo = (e) => {
    let texto = e.target.value.toUpperCase();
    if (texto.length > 2) {
      let grupos = grupoDeMarcas.grupoMontaje.filter(grupo => grupo.toUpperCase().includes(texto));
      if (grupos.length === 0) {
        setactivarTodosLosGrupos(true)
      } else {
        setGrupos(grupos);
        setactivarTodosLosGrupos(false)
      }
    } else {
      setactivarTodosLosGrupos(true)
    }
  }


  const efectoBotonRemoveLocal = (idControl, idBoton, tipoId) => {
    let controlBuscador = idControl === "" ? "" : document.getElementById(idControl).value;
    let activarEfecto = controlBuscador.trim() === "" ? false : true;

    if (activarEfecto) {
      document.getElementById(idBoton).style.visibility = "visible";
    } else {
      if (tipoId === "MARCAS") {
        document.getElementById("buscadorFiltroMarca").value = "";
        setMarcas(grupoDeMarcas.marcas);
      } else if (tipoId === "ARBOL") {
        setNodoIdSeleccionado([])
        setTextoArbol("")
        setFiltroArbol([])
        store.dispatch({
          type: "REGISTRO_FILTRO_TREE",
          openFiltroTree: false,
          registrosFiltro: []
        })
      }
      else {
        document.getElementById("buscadorFiltroGrupo").value = "";
        setGrupos(grupoDeMarcas.grupoMontaje);
      }
      document.getElementById(idBoton).style.visibility = "hidden";
    }
  }



  const loop = (data) => data.map(item => {



    item.title = item.title === null ? "Sin titulo" : item.title;
    const title = (
      item.level !== 1 && item.children.length === 0 ?
        <span style={{ fontSize: '16px' }} onClick={() => { getListSuplier(item.key, item.ES_MOTO); store.dispatch(detenerBusquedaDetalles(false)); store.dispatch(actualizarNodoTree(item.key)); store.dispatch(mostrarVistaGrafica(false)) }}> {item.title}</span>
        :
        <span style={{ fontSize: '16px', display: 'flex' }}>
          <button title="Buscar todas las referencias." className="estilosbotonBucarTree" style={{ marginRight: '5px' }} onClick={() => { getListSuplier(crearListaKeysTree(item), item.ES_MOTO); store.dispatch(detenerBusquedaDetalles(false)); store.dispatch(actualizarNodoTree(crearListaKeysTree(item))); store.dispatch(mostrarVistaGrafica(false)) }}>
            <SearchOutlined style={{ color: '#666', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '12px', height: '12px' }} />
          </button>
          {item.title}
        </span>
    );

    if (item.children) {
      return { title, key: item.key, children: loop(item.children), ES_MOTO: item.ES_MOTO };
    }

    return { title, key: item.key, isLeaf: true, ES_MOTO: item.ES_MOTO };
  });

  const busquedaArbolLocal = (listaOriginal, key, nodeFinal) => {
    let nodoIncial = key[0].NODE_ID
    let buscarNodo = listaOriginal.find((tree) => tree.key === nodoIncial)
    setTextoArbol(nodeFinal.Term)
    setNodoIdSeleccionado([nodeFinal.NODE_ID.toString()])

    if (buscarNodo !== undefined) {
      setFiltroArbol([buscarNodo])
    }
    store.dispatch({
      type: "REGISTRO_FILTRO_TREE",
      openFiltroTree: false,
      registrosFiltro: []
    })
  }


  const loopGraficos = (data) => data.map(item => {
    item.title = item.title === null ? "Sin titulo" : item.title;
    const node = item;
    const title = (
      item.level === 2 || item.level === 3 || item.level === 4 || item.level === 5 ?
        <span style={{ fontSize: '16px' }} > {item.title}</span>
        :
        <span style={{ fontSize: '16px' }}>{item.title}</span>
    );
    if (item.children) {
      return { title, key: item.key, children: loopGraficos(item.children) };
    }
    return { title, key: item.key, isLeaf: true };
  });


  const filtroPorDefecto = (ESTADO) => {
    if (ESTADO) {
      let listaTop = []
      let adicionales = []
      grupoDeMarcas.grupoMontaje.map((registro) => {
        listaTop.push(
          {
            NOMBRE_GRUPO: registro,
            NUMERO: grupoDeMarcas.auxReferencia.filter((referencia) => referencia.NOMBRE_GRUPO_MONTAJE === registro).length
          })
      })

      let grupoMayor = listaTop.reduce((item, comparador) => item.NUMERO > comparador.NUMERO ? item : comparador)
      let tempAdicional = grupoDeMarcas.auxReferencia.filter((registro, i) => registro.GRUPO_MONTAJE === 0)
      tempAdicional.map((registro, i) => {
        adicionales.push(registro.NOMBRE_GRUPO_MONTAJE);
      })

      adicionales.push(grupoMayor.NOMBRE_GRUPO)
      actulizarGruposSeleccionadas(adicionales);

      store.dispatch(updateGruposMontajeSeleccionados(adicionales));
      mostarReferenciasEnPantalla(grupoDeMarcas.auxReferencia);
    } else {
      setfiltroActivado(false)
      actulizarGruposSeleccionadas([]);
      store.dispatch(updateGruposMontajeSeleccionados([]));
      mostarReferenciasEnPantalla(grupoDeMarcas.auxReferencia);
    }
  }



  if (codmotorizacion.toString() === "") {
    if (grupoDeMarcas.marcas.length > 0) {
      if (!filtroActivado && FiltroGrupoPorDefecto) {



      } else {
        if (filtroActivado && !FiltroGrupoPorDefecto) {
          setfiltroActivado(false)
          actulizarGruposSeleccionadas([]);
          store.dispatch(updateGruposMontajeSeleccionados([]));
          mostarReferenciasEnPantalla(grupoDeMarcas.auxReferencia);
        }
      }
    } else {
      if (filtroActivado) {
        setfiltroActivado(false)
      }
    }
  }



  return (
    <>
      <ContainerReferencia
        ACTUALIZAR_POR_DEFECTO={filtroPorDefecto}
        ACTUALIZAR_GRUPO={setActulizarGrupo}
        ESTADO_GRUPO={actulizarGrupo}
        LISTA_GRUPOS={checkedListGrupos}


        textoArbol={textoArbol}
        setTextoArbol={setTextoArbol}
        efectoBotonRemoveLocal={efectoBotonRemoveLocal}
        busquedaArbolLocal={busquedaArbolLocal}
        aplicarFiltroPorMarca={aplicarFiltroPorMarca}
        verficarCambiosListaMarcas={verficarCambiosListaMarcas}
        actulizarMarcasSeleccionadas={actulizarMarcasSeleccionadas}
        todasLasMarcas={todasLasMarcas}
        checkedListMarcas={checkedListMarcas}
        aplicarFiltroPorGrupo={aplicarFiltroPorGrupo}
        verficarCambiosListaGrupos={verficarCambiosListaGrupos}
        actulizarGruposSeleccionadas={actulizarGruposSeleccionadas}
        todosLosGrupos={todosLosGrupos}
        checkedListGrupos={checkedListGrupos}
        nodoIdSeleccionado={nodoIdSeleccionado}
        loop={loop}
        filtroArbol={filtroArbol}


      >

      </ContainerReferencia>
      
    </>
  );

}

const mapStateToProps = state => {
  return {
    models: state.models,
    search: state.searchValue,
    listaTree: state.listaTree,
    listaTreeGraficos: state.listaTreeGraficos,
    expandedKeys: state.expandedKeys,
    autoExpandParent: state.autoExpandParent,
    idtecdocmotorbyhaynes: state.idtecdocmotorbyhaynes,
    configApp: state.configApp,
    buscadorTree: state.buscadorTree,
    references: state.references,
    stateiam: state.stateiam,
    stateoem: state.stateoem,
    pagination: state.pagination,
    grupoDeMarcas: state.grupoDeMarcas,
    checkedmarcas: state.checkedmarcas,
    filtrosReferencia: state.filtrosReferencia,
    listaTreeFiltrada: state.listaTreeFiltrada,
    FiltroGrupoPorDefecto: state.FiltroGrupoPorDefecto,
    codmotorizacion: state.codmotorizacion,

  };
}

export default connect(mapStateToProps)(List_Tree);