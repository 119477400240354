import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from 'mdbreact';
import 'antd/dist/antd.css';

import { Select, Card, Drawer, Input, Row, Col, Tabs, Upload, message, Button } from 'antd';
import { DrawerFichaEmpresa, createRipple } from "../action/actionCreators";
import { GetEmpresaEnAPI, SetEmpresaEnAPI } from "../action/actionCreatorsRequests";

import { MSGERROR, MSGOK, traducirPagina } from "../librerias/funciones"
import {
    PlusOutlined,
    SaveOutlined,
    EditOutlined,
    DeleteOutlined,
    InboxOutlined,
    UploadOutlined,
    ClearOutlined
} from '@ant-design/icons';
import store from '../store'
const { Dragger } = Upload;
const { Option } = Select;
const { TabPane } = Tabs;

const FichaEmpresa = ({ props, configApp, openDrawerEmpresa, }) => {
    const [hayEmpresa, setHayEmpresa] = React.useState(false);
    const [tabPorDefecto, setTabPorDefecto] = React.useState("1");
    const [imagenPrevia, setImagenPrevia] = React.useState({ original: "", modificado: "", tipoLogotipo: "" });
    const [fileList, setFileList] = React.useState([]);

    


        
    const [adicionales, setAdicionales] = React.useState(
        {
            MARGEN_NEUMATICOS_PORCENTAJE:"",
            MARGEN_NEUMATICOS_FIJO:"",

            EXISTE_MARGEN_PORCENTAJE:false,
            EXISTE_MARGEN_FIJO:false,

        })


    const [payLoadLocal, setPayLoadLocal] = React.useState(
        {
            ID: null,
            ID_USUARIO_SERTEC: 0,
            ID_TALLER: 0,
            RAZON: "",
            CIF: "",
            DIRECCION: "",
            POBLACION: "",
            PROVINCIA: "",
            CP: "",
            PAIS: "",
            TELEFONO: "",
            TELEFONO_2: "",
            TELEFONO_3: "",
            MAIL: "",
            OBSERVACIONES: "",
            EMAIL: "",
            PRECIO_MANO_OBRA: "",
            CIENTO_IVA: "",
            LOGOTIPO: "",
            COMENTARIOS_PIE_PRESUPUESTO: "",
            REGISTRO_MERCANTIL: "",

            MARGEN_NEUMATICOS_FIJO: "",
            MARGEN_NEUMATICOS_PORCENTAJE: ""
        }
    )

    if (!hayEmpresa && openDrawerEmpresa) {
        setHayEmpresa(true);
        GetEmpresaEnAPI().then(empresa => {
            if (empresa !== null && empresa.length > 0) {
                setPayLoadLocal({
                    ID: empresa[0].ID,
                    ID_USUARIO_SERTEC: empresa[0].ID_USUARIO_SERTEC,
                    ID_TALLER: empresa[0].ID_TALLER,
                    RAZON: empresa[0].RAZON,
                    CIF: empresa[0].CIF,
                    DIRECCION: empresa[0].DIRECCION,
                    POBLACION: empresa[0].POBLACION,
                    PROVINCIA: empresa[0].PROVINCIA,
                    CP: empresa[0].CP,
                    PAIS: empresa[0].PAIS,
                    TELEFONO: empresa[0].TELEFONO,
                    TELEFONO_2: empresa[0].TELEFONO_2,
                    TELEFONO_3: empresa[0].TELEFONO_3,
                    MAIL: empresa[0].MAIL,
                    OBSERVACIONES: empresa[0].OBSERVACIONES,
                    EMAIL: empresa[0].EMAIL,
                    PRECIO_MANO_OBRA: empresa[0].PRECIO_MANO_OBRA,
                    CIENTO_IVA: empresa[0].CIENTO_IVA,
                    LOGOTIPO: empresa[0].LOGOTIPO,
                    COMENTARIOS_PIE_PRESUPUESTO: empresa[0].COMENTARIOS_PIE_PRESUPUESTO,
                    REGISTRO_MERCANTIL: empresa[0].REGISTRO_MERCANTIL,

                })


                adicionales.MARGEN_NEUMATICOS_FIJO = empresa[0].MARGEN_NEUMATICOS_FIJO
                adicionales.MARGEN_NEUMATICOS_PORCENTAJE = empresa[0].MARGEN_NEUMATICOS_PORCENTAJE
                setAdicionales({...adicionales})



                setImagenPrevia({
                    original: empresa[0].LOGOTIPO === null ? "" : empresa[0].LOGOTIPO,
                    modificado: empresa[0].LOGOTIPO === null ? "" : empresa[0].LOGOTIPO,
                    tipoLogotipo: empresa[0].TIPO_LOGOTIPO === null ? "" : empresa[0].TIPO_LOGOTIPO
                })

            } else {
                setPayLoadLocal(null)
            }
        })
    } else {
        if (!openDrawerEmpresa && hayEmpresa) {
            setHayEmpresa(false);
            setTabPorDefecto("1");
            setImagenPrevia({ original: "", modificado: "", tipoLogotipo: "" })
            setFileList([])
            setPayLoadLocal({
                ID: null,
                ID_USUARIO_SERTEC: 0,
                ID_TALLER: 0,
                RAZON: "",
                CIF: "",
                DIRECCION: "",
                POBLACION: "",
                PROVINCIA: "",
                CP: "",
                PAIS: "",
                TELEFONO: "",
                TELEFONO_2: "",
                TELEFONO_3: "",
                MAIL: "",
                OBSERVACIONES: "",
                EMAIL: "",
                PRECIO_MANO_OBRA: "",
                CIENTO_IVA: "",
                LOGOTIPO: "",
                COMENTARIOS_PIE_PRESUPUESTO: "",
                REGISTRO_MERCANTIL: "",
                MARGEN_NEUMATICOS_FIJO: "",
                MARGEN_NEUMATICOS_PORCENTAJE: ""
            })
            

            adicionales.MARGEN_NEUMATICOS_PORCENTAJE=""
            adicionales.MARGEN_NEUMATICOS_FIJO=""
            adicionales.EXISTE_MARGEN_PORCENTAJE=false
            adicionales.EXISTE_MARGEN_FIJO=false
            setAdicionales({...adicionales})
        }
    }



    const modificarUsuario = () => {
        setAdicionales({...adicionales})
        let copiaPayload = {...payLoadLocal}

        copiaPayload.MARGEN_NEUMATICOS_FIJO = adicionales.MARGEN_NEUMATICOS_FIJO
        copiaPayload.MARGEN_NEUMATICOS_PORCENTAJE = adicionales.MARGEN_NEUMATICOS_PORCENTAJE

        SetEmpresaEnAPI(copiaPayload, imagenPrevia).then(usuario => {
            if (usuario.OK) {
                store.dispatch({ type: "STATE_MODIFICAR_IVA_FACTURACION", ivaPorDefecto: payLoadLocal.CIENTO_IVA })
                store.dispatch({ type: "STATE_MODIFICAR_MANO_OBRA_FACTURACION", manoObraPorDecto: payLoadLocal.PRECIO_MANO_OBRA })

                MSGOK("Empresa modificado correctamente.")
                store.dispatch(DrawerFichaEmpresa(false));
            }
        })
    }

    const guardarEstadoFormulario = (e) => {
        const ID = e.target.name === 'ID' ? e.target.value : payLoadLocal.ID;
        const ID_USUARIO_SERTEC = e.target.name === 'ID_USUARIO_SERTEC' ? e.target.value : payLoadLocal.ID_USUARIO_SERTEC;
        const ID_TALLER = e.target.name === 'ID_TALLER' ? e.target.value : payLoadLocal.ID_TALLER;
        const RAZON = e.target.name === 'RAZON' ? e.target.value : payLoadLocal.RAZON;
        const CIF = e.target.name === 'CIF' ? e.target.value : payLoadLocal.CIF;
        const DIRECCION = e.target.name === 'DIRECCION' ? e.target.value : payLoadLocal.DIRECCION;
        const POBLACION = e.target.name === 'POBLACION' ? e.target.value : payLoadLocal.POBLACION;
        const PROVINCIA = e.target.name === 'PROVINCIA' ? e.target.value : payLoadLocal.PROVINCIA;
        const CP = e.target.name === 'CP' ? e.target.value : payLoadLocal.CP;
        const PAIS = e.target.name === 'PAIS' ? e.target.value : payLoadLocal.PAIS;
        const TELEFONO = e.target.name === 'TELEFONO' ? e.target.value : payLoadLocal.TELEFONO;
        const TELEFONO_2 = e.target.name === 'TELEFONO_2' ? e.target.value : payLoadLocal.TELEFONO_2;
        const TELEFONO_3 = e.target.name === 'TELEFONO_3' ? e.target.value : payLoadLocal.TELEFONO_3;
        const MAIL = e.target.name === 'MAIL' ? e.target.value : payLoadLocal.MAIL;
        const OBSERVACIONES = e.target.name === 'OBSERVACIONES' ? e.target.value : payLoadLocal.OBSERVACIONES;
        const EMAIL = e.target.name === 'EMAIL' ? e.target.value : payLoadLocal.EMAIL;
        const PRECIO_MANO_OBRA = e.target.name === 'PRECIO_MANO_OBRA' ? e.target.value : payLoadLocal.PRECIO_MANO_OBRA;
        const CIENTO_IVA = e.target.name === 'CIENTO_IVA' ? e.target.value : payLoadLocal.CIENTO_IVA;
        const LOGOTIPO = e.target.name === 'LOGOTIPO' ? e.target.value : payLoadLocal.LOGOTIPO;
        const COMENTARIOS_PIE_PRESUPUESTO = e.target.name === 'COMENTARIOS_PIE_PRESUPUESTO' ? e.target.value : payLoadLocal.COMENTARIOS_PIE_PRESUPUESTO;
        const REGISTRO_MERCANTIL = e.target.name === 'REGISTRO_MERCANTIL' ? e.target.value : payLoadLocal.REGISTRO_MERCANTIL;



        /*
        const MARGEN_NEUMATICOS_PORCENTAJE = e.target.name === 'MARGEN_NEUMATICOS_PORCENTAJE' ? e.target.value : payLoadLocal.MARGEN_NEUMATICOS_PORCENTAJE;
        const MARGEN_NEUMATICOS_FIJO = e.target.name === 'MARGEN_NEUMATICOS_FIJO' ? e.target.value : payLoadLocal.MARGEN_NEUMATICOS_FIJO;


        let MARGEN_NEUMATICOS_PORCENTAJE_AUX = ""
        let MARGEN_NEUMATICOS_FIJO_AUX = ""

      
        if(MARGEN_NEUMATICOS_FIJO !== ""){
            MARGEN_NEUMATICOS_PORCENTAJE_AUX = 0
            MARGEN_NEUMATICOS_FIJO_AUX = MARGEN_NEUMATICOS_FIJO
        }

        if(MARGEN_NEUMATICOS_PORCENTAJE !== ""){
            MARGEN_NEUMATICOS_FIJO_AUX = 0
            MARGEN_NEUMATICOS_PORCENTAJE_AUX = MARGEN_NEUMATICOS_PORCENTAJE
        }

        */

        setPayLoadLocal(
            {
                ID: ID,
                ID_USUARIO_SERTEC: ID_USUARIO_SERTEC,
                ID_TALLER: ID_TALLER,
                RAZON: RAZON,
                CIF: CIF,
                DIRECCION: DIRECCION,
                POBLACION: POBLACION,
                PROVINCIA: PROVINCIA,
                CP: CP,
                PAIS: PAIS,
                TELEFONO: TELEFONO,
                TELEFONO_2: TELEFONO_2,
                TELEFONO_3: TELEFONO_3,
                MAIL: MAIL,
                OBSERVACIONES: OBSERVACIONES,
                EMAIL: EMAIL,
                PRECIO_MANO_OBRA: PRECIO_MANO_OBRA,
                CIENTO_IVA: CIENTO_IVA,
                LOGOTIPO: "",
                COMENTARIOS_PIE_PRESUPUESTO: COMENTARIOS_PIE_PRESUPUESTO,
                REGISTRO_MERCANTIL: REGISTRO_MERCANTIL,

               
            }
        )
    }


    const componente = {
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList)

            if (newFileList.length === 0) {
                setImagenPrevia({ original: "", modificado: "", tipoLogotipo: "" })
            }
        },
        beforeUpload: file => {
            if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
                MSGERROR(`${file.name} ${traducirPagina("sin_extension")} .png o .jpg`)
            } else if (file.size >= 500000) {
                MSGERROR(`${traducirPagina("tamanio_maximo_permitido")} (500KB)`)
            } else {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    let texto = "data:" + file.type + ";base64,"
                    let cadena = reader.result.replace(texto, "")
                    setImagenPrevia({ original: reader.result, modificado: cadena, tipoLogotipo: file.type })
                };
                reader.onerror = function (error) {
                    MSGERROR(`${traducirPagina("problema_carga_imagen")}: ${error}`)
                };

                let fichero = fileList
                fichero.push(file)
                setFileList(fichero)

            }
            return file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.size >= 500000 ? false : Upload.LIST_IGNORE;
        }
    };

    return (
        <Drawer
            width={700}
            onClose={() => { store.dispatch(DrawerFichaEmpresa(false)); }}
            closable={false}

            visible={openDrawerEmpresa}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '99999999', color: '#fff' }}>
            <div className="flex-container-Grow-Tercero" >
                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { store.dispatch(DrawerFichaEmpresa(false)); createRipple(e) }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>

            {payLoadLocal === null ?
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <h4>{traducirPagina("No_conexion_servidor")}</h4>
                </div>
                :
                <Tabs defaultActiveKey={"1"} activeKey={tabPorDefecto} onChange={(tab) => setTabPorDefecto(tab)} >
                    <TabPane tab={traducirPagina("Identificación")} key="1" >
                        <div style={{ height: (configApp.height - 190).toString() + "px", }} className="ml-4 mr-4" >
                            <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                                <Col md={12}>
                                    <span>{traducirPagina("Razon")} </span>
                                </Col>
                                <Col md={12}>
                                    <span>{traducirPagina("Cif").toUpperCase()} </span>
                                </Col>

                                <Col md={12}>
                                    <Input name="RAZON" value={payLoadLocal.RAZON} onChange={(e) => guardarEstadoFormulario(e)} />
                                </Col>
                                <Col md={12}>
                                    <Input name="CIF" value={payLoadLocal.CIF} onChange={(e) => guardarEstadoFormulario(e)} />
                                </Col>

                                <Col md={24}>
                                    <span>{traducirPagina("Pais")} </span>
                                </Col>
                                <Col md={24}>
                                    <Input name="PAIS" value={payLoadLocal.PAIS} onChange={(e) => guardarEstadoFormulario(e)} />
                                </Col>

                                <Col md={24}>
                                    <span>{traducirPagina("Provincia")}</span>
                                </Col>
                                <Col md={24}>
                                    <Input name="PROVINCIA" value={payLoadLocal.PROVINCIA} onChange={(e) => guardarEstadoFormulario(e)} />
                                </Col>

                                <Col md={24}>
                                    <span>{traducirPagina("Poblacion")}</span>
                                </Col>

                                <Col md={24}>
                                    <Input name="POBLACION" value={payLoadLocal.POBLACION} onChange={(e) => guardarEstadoFormulario(e)} />
                                </Col>

                                <Col md={20}>
                                    <span>{traducirPagina("Direccion")} </span>
                                </Col>

                                <Col md={4}>
                                    <span>{traducirPagina("CP").toUpperCase()}</span>
                                </Col>

                                <Col md={20}>
                                    <Input name="DIRECCION" value={payLoadLocal.DIRECCION} onChange={(e) => guardarEstadoFormulario(e)} />
                                </Col>

                                <Col md={4}>
                                    <Input name="CP" value={payLoadLocal.CP} onChange={(e) => guardarEstadoFormulario(e)} />
                                </Col>

                                <Col md={8} >
                                    <span>{traducirPagina("Telefono")} </span>
                                </Col>

                                <Col md={8} >
                                    <span>{traducirPagina("Telefono_2")} </span>
                                </Col>

                                <Col md={8} >
                                    <span>{traducirPagina("Telefono_3")} </span>
                                </Col>

                                <Col md={8}>
                                    <Input name="TELEFONO" value={payLoadLocal.TELEFONO} onChange={(e) => guardarEstadoFormulario(e)} />
                                </Col>
                                <Col md={8}>
                                    <Input name="TELEFONO_2" value={payLoadLocal.TELEFONO_2} onChange={(e) => guardarEstadoFormulario(e)} />
                                </Col>
                                <Col md={8}>
                                    <Input name="TELEFONO_3" value={payLoadLocal.TELEFONO_3} onChange={(e) => guardarEstadoFormulario(e)} />
                                </Col>
                                <Col md={24}>
                                    <span>{traducirPagina("Email")}</span>
                                </Col>

                                <Col md={24}>
                                    <Input name="MAIL" value={payLoadLocal.MAIL} onChange={(e) => guardarEstadoFormulario(e)} />
                                </Col>

                            </Row>
                        </div>

                        <div style={{ flexGrow: '6', textAlign: 'right' }} className="ml-4 mr-4" >
                            <button className="activebtnMenu2" style={{ height: '40px', width: '220px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', cursor: 'pointer' }} onClick={(e) => modificarUsuario()}><SaveOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Guardar_y_salir").toUpperCase()} </span>  </button>
                        </div>
                    </TabPane>
                    <TabPane tab={traducirPagina("Facturacion")} key="2" >
                        <div style={{ height: (configApp.height - 203).toString() + "px", }} className="mb-2" >
                            <div style={{ height: (configApp.height - 190).toString() + "px", }} className="ml-4 mr-4" >
                                <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                                
                                    <Col md={12}>
                                        <span>{traducirPagina("Precio_mano_de_obra")} </span>
                                    </Col>
                                    <Col md={12}>
                                        <span>{traducirPagina("Iva_por_defecto")} </span>
                                    </Col>
                                
                                    <Col md={12} >
                                        <Input name="PRECIO_MANO_OBRA" value={payLoadLocal.PRECIO_MANO_OBRA} onChange={(e) => guardarEstadoFormulario(e)} />
                                    </Col>

                                    <Col md={12}>
                                        <Input name="CIENTO_IVA" value={payLoadLocal.CIENTO_IVA} onChange={(e) => guardarEstadoFormulario(e)} />
                                    </Col>

                                    <Col md={24}>
                                        <span>{traducirPagina("Comentarios_pie_presupuesto")} </span>
                                    </Col>

                                    <Col md={24}>
                                        <Input.TextArea allowClear rows={9} name="COMENTARIOS_PIE_PRESUPUESTO" value={payLoadLocal.COMENTARIOS_PIE_PRESUPUESTO} onChange={(e) => guardarEstadoFormulario(e)} />
                                    </Col>

                                    <Col md={24}>
                                        <span>{traducirPagina("Registro_mercantil")} </span>
                                    </Col>

                                    <Col md={24}>
                                        <Input.TextArea allowClear rows={9} name="REGISTRO_MERCANTIL" value={payLoadLocal.REGISTRO_MERCANTIL} onChange={(e) => guardarEstadoFormulario(e)} />
                                    </Col>


                                    <Col md={12}>
                                        <span>{traducirPagina("txt_margen_procentaje")} </span>
                                    </Col>
                                    <Col md={12}>
                                        <span>{traducirPagina("txt_margen_fijo")} </span>
                                    </Col>
                                
                                    <Col md={12} >
                                        <Input  disabled={adicionales.EXISTE_MARGEN_PORCENTAJE} value={adicionales.MARGEN_NEUMATICOS_PORCENTAJE} onChange={(e) => {
                                            adicionales.MARGEN_NEUMATICOS_PORCENTAJE = e.target.value;

                                            adicionales.MARGEN_NEUMATICOS_FIJO = 0;
                                            adicionales.EXISTE_MARGEN_FIJO = adicionales.MARGEN_NEUMATICOS_PORCENTAJE !== "" && adicionales.MARGEN_NEUMATICOS_PORCENTAJE !== "0"  
                                            setAdicionales({...adicionales})
                                        

                                        }} />
                                    </Col>

                                    <Col md={12}>
                                        <Input disabled={adicionales.EXISTE_MARGEN_FIJO}  value={adicionales.MARGEN_NEUMATICOS_FIJO} onChange={(e) =>{
                                                  adicionales.MARGEN_NEUMATICOS_FIJO = e.target.value;

                                                  adicionales.MARGEN_NEUMATICOS_PORCENTAJE = 0;
                                                  adicionales.EXISTE_MARGEN_PORCENTAJE = adicionales.MARGEN_NEUMATICOS_FIJO !== "" && adicionales.MARGEN_NEUMATICOS_FIJO !== "0" 
                                                  setAdicionales({...adicionales})

                                        }} />
                                    </Col>

                                </Row>
                            </div>
                        </div>

                        <div style={{ flexGrow: '6', textAlign: 'right' }} className="ml-4 mr-4" >
                            <button className="activebtnMenu2" style={{ height: '40px', width: '220px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', cursor: 'pointer' }} onClick={(e) => modificarUsuario()}><SaveOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Guardar_y_salir").toUpperCase()} </span>  </button>
                        </div>
                    </TabPane>

                    <TabPane tab={traducirPagina("Observaciones")} key="3">

                        <div style={{ height: (configApp.height - 203).toString() + "px", }} className="mb-2" >
                            <div style={{ height: (configApp.height - 190).toString() + "px", }} className="ml-4 mr-4">
                                <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                                    <Col md={24}>
                                        <span>{traducirPagina("Observaciones")} </span>
                                    </Col>
                                    <Col md={24} >
                                        <Input.TextArea name="OBSERVACIONES" rows={20} value={payLoadLocal.OBSERVACIONES} onChange={(e) => guardarEstadoFormulario(e)} />
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div style={{ flexGrow: '6', textAlign: 'right' }} className="ml-4 mr-4" >
                            <button className="activebtnMenu2" style={{ height: '40px', width: '220px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', cursor: 'pointer' }} onClick={(e) => modificarUsuario()}><SaveOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Guardar_y_salir").toUpperCase()} </span>  </button>
                        </div>
                    </TabPane>

                    <TabPane tab={traducirPagina("Logotipo")} key="4">
                        <div style={{ height: (configApp.height - 203).toString() + "px", }} className="mb-2" >
                            <div style={{ height: (configApp.height - 190).toString() + "px", }} className="ml-4 mr-4">
                                <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                                    <Col md={24}>
                                        <span>{traducirPagina("msgFicEmp_logotipo_01")} </span>
                                        <br />
                                        <span>{traducirPagina("msgFicEmp_logotipo_02")}</span>
                                        <br />
                                        <span>{traducirPagina("msgFicEmp_logotipo_03")}</span>
                                        <br />
                                        <br />
                                    </Col>

                                    <Col md={12}>
                                        <Upload {...componente}
                                            maxCount={1}
                                        >
                                            <Button style={{ display: imagenPrevia.tipoLogotipo !== "" ? 'none' : '' }} ><UploadOutlined style={{ fontSize: '20px' }} />{traducirPagina("Seleccione_la_imagen")}</Button>
                                        </Upload>
                                    </Col>

                                    <Col md={12}>
                                        <Button style={{ display: imagenPrevia.tipoLogotipo === "" ? 'none' : '' }} onClick={(e) => setImagenPrevia({ original: "", modificado: "", tipoLogotipo: "" })}> <DeleteOutlined style={{ fontSize: '20px' }} />{traducirPagina("Limpiar_logotipo")}</Button>
                                    </Col>
                                    <Col md={24}>
                                        <br />
                                        <br />
                                        <img src={"data:" + imagenPrevia.tipoLogotipo + ";base64," + imagenPrevia.modificado} style={{ width: '400px', display: imagenPrevia.modificado === "" ? 'none' : "" }}></img>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div style={{ flexGrow: '6', textAlign: 'right' }} className="ml-4 mr-4" >
                            <button className="activebtnMenu2" style={{ height: '40px', width: '220px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', cursor: 'pointer' }} onClick={(e) => modificarUsuario()}><SaveOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Guardar_y_salir").toUpperCase()} </span>  </button>
                        </div>
                    </TabPane>
                </Tabs>
            }
        </Drawer>
    )
}
FichaEmpresa.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        openDrawerEmpresa: state.estadoDrawer.openDrawerEmpresa,

    };
}

export default connect(mapStateToProps)(FichaEmpresa);