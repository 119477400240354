import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from 'mdbreact';
import 'antd/dist/antd.css';

import { Select, Card, Drawer, Modal, Button, Form, Input, Checkbox, Row, Col, Tabs } from 'antd';
import { DrawerPerfilUsuario, createRipple, DrawerFichaMantenimientoVehiculoTaller, DrawerFichaClienteTaller, DrawerFichaMantenimientoClienteTaller } from "../action/actionCreators";
import { GetUsuariosEnAPI, SetUsuarioEnAPI, GetIdiomasEnAPI, setClienteVehiculoTallerEnAPI, setClienteTallerEnAPI } from "../action/actionCreatorsRequests";

import { MSGERROR, MSGOK, traducirPagina, nuevoFormatoFechaCliente } from "../librerias/funciones"
import FichaMantenimientoVehiculoTaller from '../components/MantenimientoVehiculoTaller'
import FichaMantenimientoCliente from '../components/MantenimientoClienteTaller'


import {
    PlusOutlined,
    SaveOutlined,
    EditOutlined,
    DeleteOutlined,
    SendOutlined,
    EyeOutlined,
    FileAddOutlined,
    SearchOutlined,
    MailOutlined
} from '@ant-design/icons';
import store from '../store'
import FichaVehClienteGenerico from './FichaVehClienteGenerico.jsx';
import BtnEnvio from './Flotas/BtnEnvio.jsx';
import FichaEnvioEmail from './FichaEnvioEmail.jsx';

const { Option } = Select;
const { TabPane } = Tabs;

const FichaDuplicarDocumento = ({ props, configApp, PresupuestoTallerSeleccionado, openDrawerFichaMantenimientoVehiculo, BTN_PASAR_ENVIAR = false, ENVIAR_EMAIL = () => { },MOD_DOCUMENTO = () =>{} }) => {
    const [hayRegistros, setHayRegistros] = React.useState(false)
    const [ID_DOCUMENTO, setID_DOCUMENTO] = React.useState(0);
    const [fechaLocal, setFechaLocal] = React.useState("");
    const [cliente, setCliente] = React.useState(0);
    const [nombreCliente, setNombreCliente] = React.useState("");
    const [denominacionVehiculo, setDenominacionVehiculo] = React.useState("");
    const [vehiculo, setVehiculo] = React.useState(0);
    const [fichaVehiculo, setFichaVehiculo] = React.useState(false);
    const [mantenimientoVehiculo, setMantenimientoVehiculo] = React.useState(false);
    const [mantenimientoCliente, setMantenimientoCliente] = React.useState(false);
    const [fichaEnvioMail, setFichaEnvioMail] = React.useState(false)



    if (!hayRegistros && props.ABRIR_FICHA) {

        setHayRegistros(true);
        let obtenerFecha = nuevoFormatoFechaCliente(props.DOCUMENTO.FECHA)
        setID_DOCUMENTO(props.DOCUMENTO.ID)
        let denominacion = props.DOCUMENTO.VEH_MATRICULA === "" ? "" : props.DOCUMENTO.VEH_MATRICULA + " - " + props.DOCUMENTO.VEH_DENOMINACION
        setDenominacionVehiculo(denominacion)
        setFechaLocal(obtenerFecha)
        setCliente(props.DOCUMENTO.ID_CLIENTE)
        setNombreCliente(props.DOCUMENTO.CLI_NOMBRE)
        setVehiculo(props.DOCUMENTO.ID_VEHICULO)



    } else {
        if (!props.ABRIR_FICHA && hayRegistros) {
            setID_DOCUMENTO(0)
            setNombreCliente("")
            setHayRegistros(false);
            setDenominacionVehiculo("")
            setFechaLocal("")
            setCliente(0)
            setVehiculo(0)

        }
    }

    if (Object.keys(PresupuestoTallerSeleccionado.cliente).length > 0 && PresupuestoTallerSeleccionado.cliente.ID !== cliente) {
        setCliente(PresupuestoTallerSeleccionado.cliente.ID)
        setNombreCliente(PresupuestoTallerSeleccionado.cliente.NOMBRE)
    }

    const vehiculoSeleccionado = (vehiculo) => {
        setVehiculo(vehiculo.ID)
        setFichaVehiculo(false)
        let denominacion = vehiculo.MATRICULA + " - " + vehiculo.MARCA + "/" + vehiculo.MODELO + "/" + vehiculo.MOTOR
        setDenominacionVehiculo(denominacion)
    }


    const asignarVehiculoCliente = (vehiculo) => {
        vehiculo.ID_CLIENTE = cliente
        setClienteVehiculoTallerEnAPI(0, vehiculo).then((respuesta) => {
            if (respuesta.OK) {
                MSGOK("Vehículo creado correctamente.")
                setMantenimientoVehiculo(false)
            }
        })
    }

    const guardarCliente = (cliente) => {
        setClienteTallerEnAPI(0, cliente).then((respuesta) => {
            if (respuesta.OK) {
                store.dispatch(DrawerFichaMantenimientoClienteTaller(false));
                setMantenimientoCliente(false);
                MSGOK("Cliente añadido correctamente.")
            }
        })
    }

    const gestionarDocumento = (ID_DOCUMENTO, cliente, vehiculo, fechaLocal) => {
        props.ENVIAR(ID_DOCUMENTO, cliente, vehiculo, fechaLocal)

    }


    return (
        <div>
            <Drawer
                width={480}
                onClose={() => { props.CERRAR_FICHA(false); store.dispatch({ type: "UPDATE_CLIENTE_SELECCIONADO_PARA_PRESUPUESTO_TALLER", cliente: {} }) }}
                visible={props.ABRIR_FICHA}
                closable={false}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ zIndex: '99999999', color: '#fff' }}>
                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { props.CERRAR_FICHA(false); store.dispatch({ type: "UPDATE_CLIENTE_SELECCIONADO_PARA_PRESUPUESTO_TALLER", cliente: {} }) }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>



                <div style={{ height: (configApp.height - 160).toString() + "px" }} className="m-4" >
                    <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                        <Col md={24}>
                            <span>{traducirPagina("msgFic_DupComp_01")} </span>
                        </Col>

                        <Col md={24} >
                            <input type="date" value={fechaLocal} onChange={(e) => setFechaLocal(e.target.value)}></input>
                        </Col>

                        <Col md={24} style={{ marginTop: '15px' }}>
                            <span>{traducirPagina("msgFic_DupComp_02")} </span>
                        </Col>

                        <Col md={20} >
                            <Input disabled={true} name="CORREO_RECUPERACION_PWD" value={nombreCliente} onChange={(e) => { }} />
                        </Col>

                        <Col md={2} className="mdb-skin">
                            <MDBBtn title={traducirPagina("Buscar_cliente")} onClick={() => {
                                store.dispatch(DrawerFichaClienteTaller(true));
                                store.dispatch({ type: 'UPDATE_FRONT_PARA_FICHA_PRESUPUESTO', esDesdeFichaPresupuesto: true })

                            }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                <SearchOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '22px' }} />
                            </MDBBtn>
                        </Col>
                        <Col md={2} className="mdb-skin">

                            <MDBBtn title={traducirPagina("Nuevo_Cliente")} onClick={() => {
                                //store.dispatch(DrawerFichaMantenimientoClienteTaller(true))
                                store.dispatch({ type: "UPDATE_MODO_FICHA_CLIENTE_TALLER", esNuevoCliente: true })
                                setMantenimientoCliente(true)


                            }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                <FileAddOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '22px' }} />
                            </MDBBtn>
                        </Col>

                        <Col md={24} style={{ marginTop: '15px' }}>
                            <span>{traducirPagina("msgFic_DupComp_03")} </span>
                        </Col>

                        <Col md={20} >
                            <Input disabled={true} name="CORREO_RECEPCION_PEDIDOS" value={denominacionVehiculo} onChange={(e) => { }} />
                        </Col>
                        <Col md={2} className="mdb-skin">
                            <MDBBtn title={traducirPagina("Buscar_vehiculo")} onClick={() => {
                                setFichaVehiculo(true)


                            }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                <SearchOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '22px' }} />
                            </MDBBtn>
                        </Col>
                        <Col md={2} className="mdb-skin">

                            <MDBBtn title={traducirPagina("Nuevo_vehiculo")} onClick={() => {
                                store.dispatch({ type: "UPDATE_MODO_FICHA_VEHICULO_TALLER", esNuevoVehiculo: true });
                                setMantenimientoVehiculo(true)
                                store.dispatch(DrawerFichaMantenimientoVehiculoTaller(true));


                            }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                <FileAddOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '22px' }} />
                            </MDBBtn>
                        </Col>


                    </Row>
                </div>

                <div style={{ flexGrow: '6', textAlign: 'right', display: 'flex', justifyContent: BTN_PASAR_ENVIAR ? 'space-between' : 'end' }} className="ml-4 mr-4">
                    {
                        BTN_PASAR_ENVIAR && (
                            <BtnEnvio
                                onClick={() => {
                                    ENVIAR_EMAIL(true)
                                    props.DOCUMENTO.ID_CLIENTE =  cliente
                                    props.DOCUMENTO.ID_VEHICULO = vehiculo
                                    props.DOCUMENTO.FECHA = fechaLocal

                                 

                                    MOD_DOCUMENTO({...props.DOCUMENTO})
                                }}>
                                <MailOutlined style={{ fontSize: '20px' }} /> <span>{traducirPagina("txt_pasar_enviar").toUpperCase()} </span>
                            </BtnEnvio>
                        )
                    }

                    <BtnEnvio
                        onClick={() => { gestionarDocumento(ID_DOCUMENTO, cliente, vehiculo, fechaLocal) }}>
                        <SendOutlined style={{ fontSize: '20px' }} /> <span>{props.title} </span>
                    </BtnEnvio>
                </div>


            </Drawer>

            <FichaVehClienteGenerico
                title={traducirPagina("Ficha_vehiculo").toUpperCase()}
                ID_CLIENTE={cliente}
                ABRIR_FICHA={fichaVehiculo}
                CERRAR_FICHA={setFichaVehiculo}
                VEHICULO_SELECCIONADO={vehiculoSeleccionado}
            ></FichaVehClienteGenerico>

         

            {mantenimientoVehiculo ?
                <FichaMantenimientoVehiculoTaller
                    ABRIR_FICHA={mantenimientoVehiculo}
                    CERRAR_FICHA={setMantenimientoVehiculo}
                    GUARDAR_INTERNO={false}
                    GUARDAR_REGISTRO={asignarVehiculoCliente}
                ></FichaMantenimientoVehiculoTaller>
                : null

            }
            {mantenimientoCliente ?
                <FichaMantenimientoCliente
                    ABRIR_FICHA={mantenimientoCliente}
                    CERRAR_FICHA={setMantenimientoCliente}
                    GUARDAR_INTERNO={false}
                    GUARDAR_REGISTRO={guardarCliente}
                >
                </FichaMantenimientoCliente>
                :
                null
            }
        </div>

    )
}

FichaDuplicarDocumento.propTypes = {
    title: PropTypes.string,
    DOCUMENTO: PropTypes.object,
    TIPO_DOCUMENTO: PropTypes.string,
    ACCION: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    ENVIAR: PropTypes.func
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        PresupuestoTallerSeleccionado: state.PresupuestoTallerSeleccionado,
        openDrawerFichaMantenimientoVehiculo: state.estadoDrawer.openDrawerFichaMantenimientoVehiculo


    };
}

export default connect(mapStateToProps)(FichaDuplicarDocumento);