import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createRipple, onChildrenDrawerCloseIntegraciones, DrawerFichaPublicidad } from "../action/actionCreators"
import { getCestaLineasEnAPI, SolicitarInfoServicioEnAPI } from "../action/actionCreatorsRequests"
import { traducirPagina, referenciaActualSelect, convertirMoneda, redondeo, MSGOK, obtenerDatosUsuarioYLicenciaActual, MSGERROR, efectoRemoveBuscadorReferencia, obtenerUsuario } from '../librerias/funciones'
import { MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBInput } from 'mdbreact';

import { Select, Card, Modal, Button, Input, Row, Col, Drawer } from 'antd';

import store from "../store";
const { Option } = Select;
const FichaPublicidad = ({ props, configApp, openDrawerFichaPublicidad }) => {
    const [hayRegistros, setHayRegistros] = React.useState(false);
    const [seEnvioEmail, setSeEnvioEmail] = React.useState(false);


    if (!hayRegistros && openDrawerFichaPublicidad) {
        setHayRegistros(true);


    } else {
        if (!openDrawerFichaPublicidad && hayRegistros) {
            setHayRegistros(false);
            setSeEnvioEmail(false)
        }
    }

    const enviarInformacion = () => {

        SolicitarInfoServicioEnAPI(props.TIPO_LICENCIA).then(mensaje => {
            if (mensaje !== undefined) {
                if (mensaje.OK) {
                    MSGOK(traducirPagina("mensaje_enviado_departamento"));
                } else {
                    MSGERROR(traducirPagina("sin_envio_email"));
                }
            }

        })
    }


    setTimeout(() => { document.querySelectorAll(".ant-drawer-header-no-title").forEach(el => el.remove()) }, 100)
    return (
        <div>
            <Drawer
                width={450}
                onClose={() => { store.dispatch(DrawerFichaPublicidad(false)); }}
                visible={openDrawerFichaPublicidad}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ zIndex: '999', color: '#fff' }}>

                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <button className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                            onClick={(e) => {
                                store.dispatch(DrawerFichaPublicidad(false));
                            }}>
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </button>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.TIPO_LICENCIA}
                    </div>
                </div>


                <div className="p-3" style={{ height: (configApp.height - 61).toString() + "px", overflowX: 'hidden', overflowY: 'auto', backgroundColor: '#3a3939', color: '#fff' }}>
                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
                        <img src={props.TIPO_LICENCIA === "NEXT DATA" ? "/imagenes/nextdata.png" : props.TIPO_LICENCIA === "NEXT ASSIST" ? "/imagenes/logonextassit.png" : "/imagenes/nextacademy.png"} style={{ width: '100%' }} ></img>
                    </div>

                    <br></br>

                    <div style={{ fontSize: '16px', textAlign: 'center' }}>
                        {
                            props.TIPO_LICENCIA === "NEXT DATA" ?

                                <div>
                                    <h4 style={{ color: '#fff' }}>{traducirPagina("msgFicPubl_NextData_01")}</h4>


                                    <br></br>
                                    {traducirPagina("msgFicPubl_NextData_02")}
                                    <br></br>
                                    <br></br>
                                    {traducirPagina("msgFicPubl_NextData_03")}
                                </div>
                                :
                                props.TIPO_LICENCIA === "NEXT ASSIST" ?
                                    <div>
                                        <h4 style={{ color: '#fff' }}>{traducirPagina("msgFicPubl_NextAssist_01")}</h4>
                                        <br></br>
                                        {traducirPagina("msgFicPubl_NextAssist_02")}
                                        <br></br>
                                        <br></br>
                                        {traducirPagina("msgFicPubl_NextAssist_03")}
                                        <br></br>
                                        {traducirPagina("msgFicPubl_NextAssist_04")}
                                        <br></br>
                                        <br></br>
                                        {traducirPagina("msgFicPubl_NextAssist_05")}
                                    </div>

                                    :
                                    <div>
                                        {traducirPagina("msgFicPubl_NextAssist_06")}
                                        <br></br>
                                        <br></br>
                                        {traducirPagina("msgFicPubl_NextAssist_07")}
                                        <br></br>
                                        <br></br>
                                        {traducirPagina("msgFicPubl_NextAssist_08")}
                                    </div>
                        }
                        <br></br>
                        <a href={props.TIPO_LICENCIA === "NEXT DATA" ? "https://servicenext.eu/nextdata/" : props.TIPO_LICENCIA === "NEXT ASSIST" ? "https://servicenext.eu/nextassist/" : "https://servicenext.eu/nextacademy-cloud/"} target="_blank">{traducirPagina("Mas_informacion")}</a>
                    </div>


                    <br></br>
                    <br></br>
                    <div style={{ fontSize: '16px', textAlign: 'center' }}>

                        {traducirPagina("Me_gustaria_contratar_servicio")}
                    </div>

                    <br></br>

                    <div style={{ fontSize: '16px', textAlign: 'center' }}>
                        <button style={{ padding: '10px', border: '0', borderRadius: '3px', fontSize: '18px', verticalAlign: 'bottom', backgroundColor: '#1e3a83', marginRight: '10px' }} onClick={(e) => { enviarInformacion() }}>{traducirPagina("Ponerse_contacto_conmigo")}</button>
                    </div>




                    <br></br>
                    <br></br>
                    <br></br>

                    <div>
                        <img src="/imagenes/next.jpeg" style={{ width: '100%' }} ></img>
                    </div>
                </div>

                
            </Drawer>


        </div>
    )
}

FichaPublicidad.propTypes = {
    title: PropTypes.string,
    ESTADO_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    SUBTITULO_FICHA: PropTypes.string,
    TIPO_LICENCIA: PropTypes.string
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        openDrawerFichaClienteSocio: state.estadoDrawer.openDrawerFichaClienteSocio,
        clienteTallerSeleccionado: state.clienteTallerSeleccionado,
        openDrawerFichaPublicidad: state.estadoDrawer.openDrawerFichaPublicidad
    };
}

export default connect(mapStateToProps)(FichaPublicidad);