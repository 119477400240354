import React from "react";
import {
    MSGOK,
    MSGERROR,
    soloNumeros

} from "../../librerias/funciones"
import store from '../../store'

import { Drawer } from 'antd';
import { MDBBtn } from 'mdbreact';
import ContainerReferenciaGenerico from "../ContainerReferenciaGenerico";
import { getFichaCocheEnAPI } from "../../action/actionCreatorsRequests";

export const FichaReferenciaSugerida = (props) => {
    const [MotorVehiculo, setMotorVehiculo] = React.useState("");


    const getFichaCoche = async (ID_VEHICULO) => {
        let respuesta = await getFichaCocheEnAPI(ID_VEHICULO);
        if (respuesta !== undefined && respuesta !== null) {
            setMotorVehiculo(respuesta.MOTORES)
        }
    }



    React.useEffect(() => {
        store.dispatch({ type: 'STATE_ARTICULOS_SUGERIDOS', SUGERIDOS: { ES_ARTICULO_SUGERIDO: true } });

        if (props.ID_VEHICULO.toString() !== "") {
            getFichaCoche(props.ID_VEHICULO)

        }
    }, [])


    return <div>
        <Drawer
            width={1200}
            onClose={() => {
                props.CERRAR_FICHA(false)
                store.dispatch({ type: 'STATE_ARTICULOS_SUGERIDOS', SUGERIDOS: { ES_ARTICULO_SUGERIDO: false } });
            }}
            visible={props.ABRIR_FICHA}
            placement={"right"}
            closable={false}
            bodyStyle={{ padding: '0px' }}
            style={{ color: '#fff' }}>

            <div className="flex-container-Grow-Tercero" >

                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                        onClick={(e) => {
                            props.CERRAR_FICHA(false)
                            store.dispatch({ type: 'STATE_ARTICULOS_SUGERIDOS', SUGERIDOS: { ES_ARTICULO_SUGERIDO: false } });
                        }}>
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>


            {
                MotorVehiculo !== "" &&

                <div className="p-3">
                    <ContainerReferenciaGenerico tabActivado={""} tipoComponente={"articulosSugeridos"} referencia={MotorVehiculo} marca={props.GRUPO_MONTAJE}></ContainerReferenciaGenerico>
                    {/*  <ContainerReferenciaGenerico tabActivado={""} tipoComponente={"articulosSugeridos"} referencia={"F3P"} marca={305}></ContainerReferenciaGenerico> */}
                </div>
            }

        </Drawer>

    </div>;
};