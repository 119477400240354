import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { act } from 'react-dom/test-utils';
import { objetoGrafico, objetoGraficoV2, estadoMasterPage, traducirPagina } from "./librerias/funciones"

const reducer = (state, action) => {

    switch (action.type) {
        case 'REPLACE_BRANDS':
            return {
                ...state,
                fichaBusquedaVehiculo: { ...state.fichaBusquedaVehiculo, allManufactur: action.objetoGenerico }
            }
        case 'REPLACE_VEHICULO_MATRICULA':
            return {
                ...state,
                fichaBusquedaVehiculo: { ...state.fichaBusquedaVehiculo, vehiculoPorMatricula: action.vehiculoPorMatricula }
            }
        case 'REPLACE_VEHICULO_BASTIDOR':
            return {
                ...state,
                fichaBusquedaVehiculo: { ...state.fichaBusquedaVehiculo, vehiculoPorBastidor: action.vehiculoPorBastidor }
            }

        case 'REPLACE_VEHICULO_IDMODELO':
            return {
                ...state,
                fichaBusquedaVehiculo: { ...state.fichaBusquedaVehiculo, idmodelo: action.idmodelo }
            }
        case 'REPLACE_VEHICULO_IDMARCA':
            return {
                ...state,
                fichaBusquedaVehiculo: { ...state.fichaBusquedaVehiculo, idmarca: action.idmarca }
            }

        case 'REPLACE_BANDERA_PAIS':
            return {
                ...state,
                fichaBusquedaVehiculo: { ...state.fichaBusquedaVehiculo, banderaImagen: action.banderaImagen, codPaisIdioma: action.codPaisIdioma }
            }
        case 'CONVERT__MOTORIZACION_BY_HAYNESPRO':
            return {
                ...state,
                fichaBusquedaVehiculo: { ...state.fichaBusquedaVehiculo, codigoHaynesPro: action.codigoHaynesPro }
            }
        case 'REMOVE_ALL_REFERENCES':
            return {
                ...state,
                references: [],
            }

        case 'GET_MODEL_VEHICLE':
            return {
                ...state,
                fichaBusquedaVehiculo: { ...state.fichaBusquedaVehiculo, models: action.objetoGenerico },
            }
        case 'GET_ENGINE_TYPE':
            return {
                ...state,
                fichaBusquedaVehiculo: { ...state.fichaBusquedaVehiculo, enginesType: action.objetoGenerico },
            }
        case 'REMOVE_ALL_ENGINE':
            return {
                ...state,
                fichaBusquedaVehiculo: { ...state.fichaBusquedaVehiculo, enginesType: { original: [], aux: [] } },
            }
        case 'REMOVE_ALL_MODEL':
            return {
                ...state,
                fichaBusquedaVehiculo: { ...state.fichaBusquedaVehiculo, models: { original: [], aux: [] } },

            }
        case 'IDENTIFICACION_COCHE':
            return {
                ...state,
                fichaBusquedaVehiculo: { ...state.fichaBusquedaVehiculo, cocheABuscar: action.cocheABuscar }

            }
        case 'VEHICULO_SERTEC':
            return {
                ...state,
                fichaBusquedaVehiculo: { ...state.fichaBusquedaVehiculo, VEHICULO_SERTEC: action.VEHICULO_SERTEC }

            }
        case 'SET_TIPO_COMBUSTIBLE':
            return {
                ...state,
                fichaBusquedaVehiculo: { ...state.fichaBusquedaVehiculo, TIPO_COMBUSTIBLE: action.TIPO_COMBUSTIBLE },
            }
        case 'CHANGE_COLOR_LIST':
            return {
                ...state,
                isColorListItem: action.isColorListItem,
            }

        case 'URL_ACADEMY':
            return {
                ...state,
                urlacademy: action.urlacademy
            }
        case 'LIST_TREE':
            return {
                ...state,
                listaTree: action.listaTree
            }
        case 'REGISTRO_FILTRO_TREE':
            return {
                ...state,
                listaTreeFiltrada: {
                    ...state.listaTreeFiltrada,
                    openFiltroTree: action.openFiltroTree,
                    registrosFiltro: action.registrosFiltro
                }
            }
        case 'LISTA_ESTRUCTURA':
            return {
                ...state,
                listaEstructura: action.listaEstructura
            }
        case 'LIST_TREE_GRAFICOS':
            return {
                ...state,
                listaTreeGraficos: action.listaTreeGraficos
            }
        case "SPINNNERACADEMY":
            return {
                ...state,
                spinnerAcademy: action.spinnerAcademy,
            }
        case "SPINNNERLOGINHAYNES":
            return {
                ...state,
                spinnerLoginHaynes: action.spinnerLoginHaynes,
            }
        case "SHOW_MODAL":
            return {
                ...state,
                visiblerespuesta: action.visiblerespuesta,
            }
        case "HANDLE_OK_MODAL":
            return {
                ...state,
                visiblerespuesta: action.visiblerespuesta,
            }
        case "HANDLE_CANCEL_MODAL":
            return {
                ...state,
                visiblerespuesta: action.visiblerespuesta,
            }
        case "REPLACE_URL_HAYNESPRO":
            return {
                ...state,
                urlhaynes: action.urlhaynes,
            }

        case "CLEAR_URL_HAYNESPRO":
            return {
                ...state,
                urlhaynes: action.urlhaynes,
            }
        case "LOAD_VEHICLE_HAYNES":
            return {
                ...state,
                mostrarCoche: action.mostrarCoche,
            }
        case "GET_HISTORIC_APP":
            return {
                ...state,
                fichaBusquedaVehiculo: { ...state.fichaBusquedaVehiculo, historicapp: action.historicapp }
            }

        case "SET_HISTORIC_APP":
            return {
                ...state,
                fichaBusquedaVehiculo: { ...state.fichaBusquedaVehiculo, historicapp: action.historicapp }

            }
        case "DELETE_ITEM_HISTORIC_APP":
            return {
                ...state,
                fichaBusquedaVehiculo: { ...state.fichaBusquedaVehiculo, historicapp: action.historicapp }
            }
        case "DELETE_ITEM_HISTORIC_VEHICLE":
            return {
                ...state,
                fichaBusquedaVehiculo: {
                    ...state.fichaBusquedaVehiculo,
                    allManufactur: action.allManufactur,
                    enginesType: action.enginesType,
                    fichaCoche: action.fichaCoche,
                    historicapp: action.historicapp,
                    idmarca: action.idmarca,
                    idmodelo: action.idmodelo,
                    models: action.models,
                    vehiculoPorBastidor: action.vehiculoPorBastidor,
                    vehiculoPorMatricula: action.vehiculoPorMatricula
                }
            }
        case "HANDLE_STATE_DRAWER_BRANDS":
            return {
                ...state,
                visibleDrawer: action.visibleDrawer
            }
        case "CHANGE_DIMENSIONS_APP":
            return {
                ...state,
                configApp: { ...state.configApp, width: action.width, height: action.height }

            }
        case "SAVE_REFERENCE_TO_LOCAL":
            return {
                ...state,
                localreferences: action.localreferences,
            }
        case "GET_LOCAL_REFERENCES":
            return {
                ...state,
                localreferences: action.localreferences,
            }
        case "SAVE_VEHICLE_TO_LOCAL":
            return {
                ...state,
                localvehicles: action.localvehicles,
            }
        case "GET_LOCAL_VEHICLE":
            return {
                ...state,
                localvehicles: action.localvehicles,
            }
        case "UPDATE_HISTORIC_APP":
            return {
                ...state,

                fichaBusquedaVehiculo: {
                    ...state.fichaBusquedaVehiculo,
                    historicapp: action.historic,
                    models: { original: action.models, aux: action.models },
                    enginesType: { original: action.enginesType, aux: action.enginesType }
                },

            }
        case "HANDLE_STATE_VIEWE_IMAGE":
            return {
                ...state,
                mostrarComponente: action.mostrarComponente,
                propiedadesVisor: action.propiedadesVisor,

            }

        case "HANDLE_STATE_MASTER_PAGE":
            return {
                ...state,
                showmasterpage: action.showmasterpage,
            }


        case "SET_SESION_HAYNESPRO":
            return {
                ...state,
                sesionHaynesPro: action.sesionHaynes,
            }
        case "SET_SESION_USER":
            return {
                ...state,
                usuario: action.usuario,
            }

        case "SET_URL_NEXTASSIST":
            return {
                ...state,
                urlnextassisttaller: action.urlnextassisttaller,
            }
        case "SHOW_DRAWER_LEVEL2":
            return {
                ...state,
                childrenDrawerLevel2: action.childrenDrawerLevel2
            }
        case "CLOSE_DRAWER_LEVEL2":
            return {
                ...state,
                childrenDrawerLevel2: action.childrenDrawerLevel2
            }

        case "SHOW_DRAWER_INTEGRACIONES":
            return {
                ...state,
                openDrawerIntegraciones: action.openDrawerIntegraciones
            }
        case "CLOSE_DRAWER_INTEGRACIONES":
            return {
                ...state,
                openDrawerIntegraciones: action.openDrawerIntegraciones
            }
        case "STATE_DRAWER_GRUPO_COCHE":
            return {
                ...state,
                estadoDrawer: action.estadoDrawer
            }

        case "STATE_DRAWER_HISTORICO_COCHE":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerHistoricoCoche: action.openDrawerHistoricoCoche }
            }
        case "STATE_DRAWER_CARRO_COMPRA":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerCarroCompra: action.openDrawerCarroCompra }
            }

        case "STATE_DRAWER_MAS_INFO":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerMasInfo: action.openDrawerMasInfo }
            }
        case "STATE_DRAWER_FICHA_MAS_INFO2":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerMasInfo2: action.openDrawerMasInfo2 }
            }
        case "STATE_DRAWER_FICHA_EMPRESA":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerEmpresa: action.openDrawerEmpresa }
            }

        case "STATE_DRAWER_STOCK_ALMACEN":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerStockAlmacen: action.openDrawerStockAlmacen }
            }
        case "STATE_DRAWER_FICHA_CLIENTE":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaCliente: action.openDrawerFichaCliente }
            }
        case "STATE_DRAWER_FICHA_COCHE_DGT":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaCocheDGT: action.openDrawerFichaCocheDGT }
            }
        case "STATE_DRAWER_FICHA_CLIENTE_TALLER":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaClienteTaller: action.openDrawerFichaClienteTaller }
            }
        case "STATE_DRAWER_FICHA_MANTENIMIENTO_CLIENTE":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaMantenimientoCliente: action.openDrawerFichaMantenimientoCliente }
            }
        case "STATE_DRAWER_FICHA_MANTENIMIENTO_VEHICULO":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaMantenimientoVehiculo: action.openDrawerFichaMantenimientoVehiculo }
            }

        case "STATE_DRAWER_REFERENCIA_GRAFICO":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerReferenciaGrafico: action.openDrawerReferenciaGrafico }
            }
        case "STATE_DRAWER_REFERENCIA_GRAFICO_CARROCERIA":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerReferenciaGraficoCarroceria: action.openDrawerReferenciaGraficoCarroceria }
            }
        case "STATE_DRAWER_HAYNES_PRO":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerHaynesPro: action.openDrawerHaynesPro }
            }
        case "STATE_DRAWER_INTRANET":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerIntranet: action.openDrawerIntranet }
            }

        case "STATE_DRAWER_NEXT_ASSIST":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerNextAssist: action.openDrawerNextAssist }
            }
        case "STATE_DRAWER_ACADEMY_CLOUD":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerAcademyCloud: action.openDrawerAcademyCloud }
            }
        case "STATE_DRAWER_PERFIL_USUARIO":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerPerfilUsuario: action.openDrawerPerfilUsuario }
            }

        case "SHOW_DRAWER_LEVEL3":
            return {
                ...state,
                childrenDrawerLevel3: action.childrenDrawerLevel3
            }
        case "CLOSE_DRAWER_LEVEL3":
            return {
                ...state,
                childrenDrawerLevel3: action.childrenDrawerLevel3
            }
        case "SHOW_ALL_BRANDS":
            return {
                ...state,
                mostarTodasLasMarcas: action.mostarTodasLasMarcas
            }
        case "STATE_ACTIVEITEM_BRANDS":
            return {
                ...state,
                activeItemCar: action.activeItemCar
            }
        case "STATE_ACTIVEITEM_INTEGRACION":
            return {
                ...state,
                activeItemTipoIntegracion: action.activeItemTipoIntegracion
            }

        case "STATE_DROPDOWNN_COCHE":
            return {
                ...state,
                DROP_CAR: { ...state.DROP_CAR, nameDropDownCar: action.nameDropDownCar, CODIGO_DROP: action.CODIGO_DROP }
            }
        case "STATE_SELECCION_DROP":
            return {
                ...state,
                DROP_SELECCION_COCHE: { ...state.DROP_SELECCION_COCHE, NAME_Drop: action.NAME_Drop, IDENTIFICADOR: action.IDENTIFICADOR }
            }
        case "STATE_GT_ESTIMATE":
            return {
                ...state,
                GT_ESTIMATE: { ...state.GT_ESTIMATE, CODIGO: action.CODIGO }
            }
        case "STATE_GT_ESTIMATE_COCHE":
            return {
                ...state,
                GT_ESTIMATE: { ...state.GT_ESTIMATE, IDENTIFICADOR_COCHE: action.IDENTIFICADOR_COCHE }
            }
        case "STATE_GT_ESTIMATE_ID":
            return {
                ...state,
                GT_ESTIMATE: { ...state.GT_ESTIMATE, ID: action.ID }
            }
        case "STATE_SEARCH_COCHE":
            return {
                ...state,
                buscadorCoche: action.buscadorCoche
            }
        case "STATE_ACTIVEITEM_REFERENCIA":
            return {
                ...state,
                activeItemReferencia: action.activeItemReferencia
            }
        case "FICHA_COCHE":
            return {
                ...state,
                fichaBusquedaVehiculo: { ...state.fichaBusquedaVehiculo, fichaCoche: action.fichaCoche },
            }

        case "FILTRO_TREE":
            return {
                ...state,
                buscadorTree: action.buscadorTree
            }
        case "UPDATE_NODE_TREE":
            return {
                ...state,
                buscadorTree: { ...state.buscadorTree, nodoIdActual: action.nodoIdActual },
            }
        case "UPDATE_PAGINATION":
            return {
                ...state,
                pagination: action.pagination
            }
        case "STATE_BRANDS_GROUPING":
            return {
                ...state,
                grupoDeMarcas: action.grupoDeMarcas
            }
        case "UPDATE_BRANDS_GROUPING":
            return {
                ...state,
                grupoDeMarcas: { ...state.grupoDeMarcas, marcas: action.marcas }
            }
        case "UPDATE_REGISTER_PAGE_NOW":
            return {
                ...state,
                grupoDeMarcas: { ...state.grupoDeMarcas, registroActual: action.registroActual }
            }
        case "STATE_COLAPSE_MARCA":
            return {
                ...state,
                colapseMarca: action.colapseMarca
            }
        case "UPDATE_PAGINA_AMOSTRAR":
            return {
                ...state,
                pagination: { ...state.pagination, paginaAMostrar: action.paginaAMostrar, numeroRegistroPagina: action.numeroRegistroPagina },
            }
        case "STATE_COLAPSE_DESCUENTO":
            return {
                ...state,
                configApp: { ...state.configApp, visualizacionDescuento: action.visualizacionDescuento },
            }
        case "STATE_COLAPSE_FILTRO":
            return {
                ...state,
                configApp: { ...state.configApp, visualizacionFiltro: action.visualizacionFiltro },
            }
        case "STATE_FILTRO_REFERENCIA":
            return {
                ...state,
                pagination: { ...state.pagination, tipoFiltroPorMarca: action.tipoFiltroPorMarca },
            }
        case "STATE_CHECKED_MARCA":
            return {
                ...state,
                checkedmarcas: action.checkedmarcas
            }
        case "STATE_SELECTED_REFERENCE":
            return {
                ...state,
                referenciaSeleccionada: action.referenciaSeleccionada
            }
        case "STATE_SELECTED_REFERENCE_MAS_INFO_2":
            return {
                ...state,
                referenciaMasInfo2: action.referenciaMasInfo2
            }
        case "STATE_CLICK_POLYGON":
            return {
                ...state,
                datosGraficos: { ...state.datosGraficos, auxiliar: objetoGraficoV2(action.nivel, action.tooltip), historialGrafico: action.historialGrafico }
            }

        case "STATE_HISTORY_GRAFIC_CAR":
            return {
                ...state,
                datosGraficos: { ...state.datosGraficos, historialGrafico: action.historialGrafico }
            }

        case "SET_ID_MOTORIZACION":
            return {
                ...state,
                codmotorizacion: action.codmotorizacion
            }
        case "SET_ID_MOTORIZACION_FICHA_HISTORIAL":
            return {
                ...state,
                codMotorizacionFichaHistorial: action.codMotorizacionFichaHistorial
            }
        case "STATE_COLAPSE_BUSQUEDA_GRAFICA":
            return {
                ...state,
                configApp: { ...state.configApp, visualizacionGrafico: action.visualizacionGrafico },
            }
        case "SET_HAYNES_TOUCH_SESION":
            return {
                ...state,
                haynesProTouch: action.haynesProTouch,
            }
        case "SET_IMAGEN_COCHE":
            return {
                ...state,
                imagenHaynesCoche: action.imagenHaynesCoche,
            }
        case "STOP_BUSQUEDA_DETALLES":
            return {
                ...state,
                detenerBusquedaDetalles: action.detenerBusquedaDetalles,
            }

        case 'REPLACE_DIRECCION_MAS_INFORMACION':
            return {
                ...state,
                masInformacion: { ...state.masInformacion, direccion: action.direccion }
            }

        case 'REPLACE_IMAGENES_MAS_INFORMACION':
            return {
                ...state,
                masInformacion: { ...state.masInformacion, imagenes: action.imagenes }
            }
        case 'REPLACE_DOCUMENTOS_MAS_INFORMACION':
            return {
                ...state,
                masInformacion: { ...state.masInformacion, documentos: action.documentos }
            }
        case 'REPLACE_REFOE_MAS_INFORMACION':
            return {
                ...state,
                masInformacion: { ...state.masInformacion, refOE: action.refOE }
            }
        case 'REPLACE_DESPIECE_MAS_INFORMACION':
            return {
                ...state,
                masInformacion: { ...state.masInformacion, despiece: action.despiece }
            }
        case 'REPLACE_VEHICULOS_MAS_INFORMACION':
            return {
                ...state,
                masInformacion: { ...state.masInformacion, vehiculos: action.vehiculos }
            }
        case 'REPLACE_MODELS_MAS_INFORMACION':
            return {
                ...state,
                masInformacion: { ...state.masInformacion, masInfoModels: action.masInfoModels }
            }
        case 'REPLACE_MOTORIZACION_MAS_INFORMACION':
            return {
                ...state,
                masInformacion: { ...state.masInformacion, masInfoMotorizacion: action.masInfoMotorizacion }
            }
        case 'REPLACE_DESGLOSE_REFERENCIA':
            return {
                ...state,
                masInformacion: { ...state.masInformacion, desglose: action.desglose }
            }
        case "UPDATE_GRUPO_MONTAJE_SELECCIONADOS":
            return {
                ...state,
                grupoDeMarcas: { ...state.grupoDeMarcas, gruposMontajeSeleccionados: action.gruposMontajeSeleccionados }
            }
        case "UPDATE_LADOS_SELECCIONADOS":
            return {
                ...state,
                grupoDeMarcas: { ...state.grupoDeMarcas, ladosSeleccionados: action.ladosSeleccionados }
            }
        case "UPDATE_MARCAS_SELECCIONADAS":
            return {
                ...state,
                grupoDeMarcas: { ...state.grupoDeMarcas, marcasSeleccionadas: action.marcasSeleccionadas }
            }
        case "UPDATE_FILTROS_REFERENCIA_MARCAS":
            return {
                ...state,
                filtrosReferencia: { ...state.filtrosReferencia, verTodasLasMarcas: action.verTodasLasMarcas }
            }
        case "UPDATE_FILTROS_REFERENCIA_GRUPOS":
            return {
                ...state,
                filtrosReferencia: { ...state.filtrosReferencia, verTodosLosGrupos: action.verTodosLosGrupos }
            }

        case "UPDATE_IDIOMA_SERTEC":
            return {
                ...state,
                idiomaActual: action.idiomaActual
            }
        case "STATE_REFERENCE_ACTUAL":
            return {
                ...state,
                referenciaActual: action.referenciaActual
            }
        case "STATE_NODO_GRAFICO":
            return {
                ...state,
                nodoGraficoSeleccionado: {
                    ...state.nodoGraficoSeleccionado,
                    id: action.id,
                    nombreGrupo: action.nombreGrupo,
                    es_accesorio: action.es_accesorio === undefined ? false : action.es_accesorio
                }
            }

        case "STATE_FICHA_CLIENTE_SELECCIONADO":
            return {
                ...state,
                clienteSeleccionado: {
                    ...state.clienteSeleccionado,
                    registroCliente: action.registroCliente,
                    nombre: action.nombre,
                    codigo: action.codigo,
                    cif: action.cif
                }
            }
        case "STATE_TXT_BUSCAR_REFERENCIA":
            return {
                ...state,
                txtBuscadorReferencia: action.txtBuscadorReferencia
            }

        case "STATE_MODAL_VEHICULO":
            return {
                ...state,
                abrirModalVehiculo: action.abrirModalVehiculo
            }
        case "STATE_CESTA_LINEAS_COMPRA":
            return {
                ...state,
                actualizarCestaLineas: action.actualizarCestaLineas
            }
        case "STATE_REFERENCIA_MARCA_VEHICULO_SELECCIONADO":
            return {
                ...state,
                ReferenciaMarcaVehiculoSeleccionado: action.ReferenciaMarcaVehiculoSeleccionado
            }
        case "UPDATE_ID_CLIENTE_ID":
            return {
                ...state,
                clienteTallerSeleccionado: { ...state.clienteTallerSeleccionado, id: action.id }
            }
        case "UPDATE_MODO_FICHA_CLIENTE_TALLER":
            return {
                ...state,
                clienteTallerSeleccionado: { ...state.clienteTallerSeleccionado, esNuevoCliente: action.esNuevoCliente }
            }
        case "UPDATE_LISTA_TALLER_CLIENTE":
            return {
                ...state,
                clienteTallerSeleccionado: { ...state.clienteTallerSeleccionado, actualizarListaCliente: action.actualizarListaCliente }
            }
        case "UPDATE_CLIENTE_TALLER":
            return {
                ...state,
                clienteTallerSeleccionado: { ...state.clienteTallerSeleccionado, clienteTaller: action.clienteTaller }
            }

        case "UPDATE_FRONT_PARA_FICHA_PRESUPUESTO":
            return {
                ...state,
                clienteTallerSeleccionado: { ...state.clienteTallerSeleccionado, esDesdeFichaPresupuesto: action.esDesdeFichaPresupuesto }
            }

        case "UPDATE_VEHICULO_TALLER_POR_KTYPE":
            return {
                ...state,
                VehiculoTallerSeleccionado: { ...state.VehiculoTallerSeleccionado, vehiculoPorApi: action.vehiculoPorApi }
            }

        case "UPDATE_VEHICULO_TALLER_POR_MODELO":
            return {
                ...state,
                VehiculoTallerSeleccionado: { ...state.VehiculoTallerSeleccionado, vehiculoPorModelo: action.vehiculoPorModelo }
            }
        case "UPDATE_VEHICLE_ID_TALLER":
            return {
                ...state,
                VehiculoTallerSeleccionado: { ...state.VehiculoTallerSeleccionado, id: action.id }
            }
        case "UPDATE_MODO_FICHA_VEHICULO_TALLER":
            return {
                ...state,
                VehiculoTallerSeleccionado: { ...state.VehiculoTallerSeleccionado, esNuevoVehiculo: action.esNuevoVehiculo }
            }
        case "UPDATE_CLIENTE_VEHICULO_TALLER":
            return {
                ...state,
                VehiculoTallerSeleccionado: { ...state.VehiculoTallerSeleccionado, clienteVehiculoTaller: action.clienteVehiculoTaller }
            }
        case "UPDATE_LISTA_CLIENTE_VEHICULO":
            return {
                ...state,
                VehiculoTallerSeleccionado: { ...state.VehiculoTallerSeleccionado, actualizarListaVehiculo: action.actualizarListaVehiculo }
            }

        case "UPDATE_PRESUPUESTO_ID_TALLER":
            return {
                ...state,
                PresupuestoTallerSeleccionado: { ...state.PresupuestoTallerSeleccionado, id: action.id }
            }

        case "UPDATE_MODO_FICHA_PRESUPUESTO_TALLER":
            return {
                ...state,
                PresupuestoTallerSeleccionado: { ...state.PresupuestoTallerSeleccionado, esNuevoPresupuesto: action.esNuevoPresupuesto }
            }
        case "UPDATE_CLIENTE_SELECCIONADO_PARA_PRESUPUESTO_TALLER":
            return {
                ...state,
                PresupuestoTallerSeleccionado: { ...state.PresupuestoTallerSeleccionado, cliente: action.cliente }
            }
        case "UPDATE_VEHICULO_SELECCIONADO_PARA_PRESUPUESTO":
            return {
                ...state,
                PresupuestoTallerSeleccionado: { ...state.PresupuestoTallerSeleccionado, vehiculo: action.vehiculo }
            }
        case "UPDATE_LISTA_PRESUPUESTO":
            return {
                ...state,
                PresupuestoTallerSeleccionado: { ...state.PresupuestoTallerSeleccionado, actualizarListaPresupuesto: action.actualizarListaPresupuesto }
            }
        case "UPDATE_LISTA_PRESUPUESTO_SECUNDARIO":
            return {
                ...state,
                PresupuestoTallerSeleccionado: { ...state.PresupuestoTallerSeleccionado, presupuestoSecundario: action.presupuestoSecundario }
            }

        case "UPDATE_TIPO_BUSQUEDA_MODELO":
            return {
                ...state,
                BusquedaPorModelo: { ...state.BusquedaPorModelo, tipoBusqueda: action.tipoBusqueda, descripcion: action.descripcion }
            }
        case "UPDATE_FILTRO_BUSQUEDA":
            return {
                ...state,
                TextoBuscadorPorModelo: action.TextoBuscadorPorModelo
            }

        case "STATE_DRAWER_FICHA_PRESUPUESTO_TALLER":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaPresupuestoTaller: action.openDrawerFichaPresupuestoTaller }
            }
        case "STATE_DRAWER_FICHA_ALBARANES_TALLER":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaAlbaranesTaller: action.openDrawerFichaAlbaranesTaller }
            }
        case "STATE_DRAWER_FICHA_HISTORIAL_PEDIDOS":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaHistorialPedidos: action.openDrawerFichaHistorialPedidos }
            }

        case "STATE_DRAWER_FICHA_PROVEEDORES":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaProveedores: action.openDrawerFichaProveedores }
            }

        case "STATE_DRAWER_FICHA_ORDENES":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaOrdenTaller: action.openDrawerFichaOrdenTaller }
            }
        case "STATE_DRAWER_FICHA_FACTURA":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaFacturaTaller: action.openDrawerFichaFacturaTaller }
            }
        case "STATE_DRAWER_FICHA_GT_ESTIMATE":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaGTEstimate: action.openDrawerFichaGTEstimate }
            }

        case "STATE_UPDATE_EQUIVALENCIA_ARTICULO_SELECCIONADO":
            return {
                ...state,
                EquivalenciaArticuloSeleccionado: { ...state.EquivalenciaArticuloSeleccionado, marca: action.marca, referencia: action.referencia, artno: action.artno }
            }

        case "STATE_DRAWER_FICHA_DATOS_CLIENTES":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaDatosClientes: action.openDrawerFichaDatosClientes }
            }
        case "STATE_DRAWER_FICHA_MARCAS_VEHICULO":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaMarcasVehiculo: action.openDrawerFichaMarcasVehiculo }
            }
        case "STATE_DRAWER_FICHA_REFERENCIAS_VEHICULO":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaReferenciaVehiculo: action.openDrawerFichaReferenciaVehiculo }
            }
        case "STATE_DRAWER_FICHA_BATERIAS_VEHICULO":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaBateriasVehiculo: action.openDrawerFichaBateriasVehiculo }
            }
        case "STATE_DRAWER_FICHA_EQUIVALENCIA_ARTICULO":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaEquivalenciaArticulo: action.openDrawerFichaEquivalenciaArticulo }
            }

        case "STATE_DRAWER_FICHA_MANTENIMIENTO_PRESUPUESTO":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaMantenimientoPresupuesto: action.openDrawerFichaMantenimientoPresupuesto }
            }
        case "STATE_DRAWER_FICHA_VEHICULO_PRESUPUESTO":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaVehiculoCliente: action.openDrawerFichaVehiculoCliente }
            }
        case "STATE_DRAWER_FICHA_MANO_OBRA":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaManoObra: action.openDrawerFichaManoObra }
            }
        case "STATE_DRAWER_FICHA_ARTICULO":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaArticulo: action.openDrawerFichaArticulo }
            }
        case "STATE_DRAWER_FICHA_IMPRESION_DOCUMENTO":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaImpresionDocumento: action.openDrawerFichaImpresionDocumento }
            }
        case "STATE_DRAWER_FICHA_ENVIO_DOCUMENTO":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaEnvioDocumento: action.openDrawerFichaEnvioDocumento }
            }
        case "STATE_DRAWER_FICHA_TIEMPOS_REPARACION":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaTiemposReparacion: action.openDrawerFichaTiemposReparacion }
            }
        case "STATE_DRAWER_FICHA_LUBRICANTES":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaLubricantes: action.openDrawerFichaLubricantes }
            }
        case "STATE_DRAWER_FICHA_NEUMATICOS":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaNeumaticos: action.openDrawerFichaNeumaticos }
            }
        case "STATE_DRAWER_FICHA_GM_VEHICULO_HAYNES":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaGMYVehiculoHaynesPro: action.openDrawerFichaGMYVehiculoHaynesPro }
            }
        case "STATE_DRAWER_FICHA_MANTENIMIENTO_HAYNES":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaMantenimientoHaynesPro: action.openDrawerFichaMantenimientoHaynesPro }
            }
        case "STATE_DRAWER_FICHA_PUBLICIDAD":
            return {
                ...state,
                estadoDrawer: { ...state.estadoDrawer, openDrawerFichaPublicidad: action.openDrawerFichaPublicidad }
            }

        case "STATE_MODIFICAR_IVA_FACTURACION":
            return {
                ...state,
                datosFacturacion: { ...state.datosFacturacion, ivaPorDefecto: action.ivaPorDefecto }
            }
        case "STATE_MODIFICAR_MANO_OBRA_FACTURACION":
            return {
                ...state,
                datosFacturacion: { ...state.datosFacturacion, manoObraPorDecto: action.manoObraPorDecto }
            }
        case "STATE_MODIFICAR_EMAIL_ENVIO_DOCUMENTO":
            return {
                ...state,
                datosConfiguracion: { ...state.datosConfiguracion, emailEnvioDocumento: action.emailEnvioDocumento }
            }
        case "STATE_CLIENTES_MOSTRADOR":
            return {
                ...state,
                ClientesMostrador: action.ClientesMostrador
            }
        case "STATE_PRELOADAER":
            return {
                ...state,
                PreLoaderPage: action.PreLoaderPage
            }
        case "STATE_FILTRO_GRUPO_POR_DEFECTO":
            return {
                ...state,
                FiltroGrupoPorDefecto: action.FiltroGrupoPorDefecto
            }
        case "STATE_TIIPO_GRAFICO_MECANICO":
            return {
                ...state,
                TipoGrafico: action.TipoGrafico
            }

        case "STATE_TIPO_VEHICULO_POR_MODELO":
            return {
                ...state,
                TIPO_VEHICULO_POR_MODELO: action.TIPO_VEHICULO_POR_MODELO
            }

        case "STATE_DIRECTORIO_DOCUMENTO":
            return {
                ...state,
                DIRECTORIO_DOCUMENTO: action.DIRECTORIO_DOCUMENTO
            }

        case "STATE_ID_MODELO_MOTO":
            return {
                ...state,
                ID_MODELO_MOTO: action.ID_MODELO_MOTO
            }
        case "STATE_ALBARAN_SELECCIONADO":
            return {
                ...state,
                ALBARAN_SELECCIONADO: action.ALBARAN_SELECCIONADO
            }
        case "STATE_ES_NEUMATICO":
            return {
                ...state,
                NEUMATICO: action.NEUMATICO
            }
        case "STATE_NOTIFICACIONES_APP":
            return {
                ...state,
                NOTIFICACIONES_APP: { ...state.NOTIFICACIONES_APP, CHAT: action.CHAT, CESTA: action.CESTA }
            }

        case "STATE_VEHICLE_OEM":
            return {
                ...state,
                VEHICULO_OEM: action.VEHICULO_OEM
            }

        case "STATE_ARTICULOS_SUGERIDOS":
            return {
                ...state,
                SUGERIDOS: action.SUGERIDOS
            }
        case "STATE_DICCIONARIOS":
            return {
                ...state,
                DICCIONARIO: action.DICCIONARIO,

            }
        case "STATE_DICCIONARIO_LOGIN":
            return {
                ...state,
                DICCIONARIO_LOGIN: action.DICCIONARIO_LOGIN,

            }
    }
    return state;
};



const logger = store => next => action => {
    if (window.location.hostname !== "sertec.servicenext.eu") {
        console.log('dispatching', action)
        let result = next(action)
        console.log('next state', store.getState());
        return result;
    } else {
        let result = next(action)
        return result;
    }

}

const objetoMarcas = () => {
    let marcas = [
        { nombre: "alfaRomeo.png", id: 2 },
        //{nombre:"aprilia.PNG",id:0},
        { nombre: "audi.png", id: 5 },
        { nombre: "bmw.png", id: 16 },
        { nombre: "buick.png", id: 816 },
        { nombre: "cadillac.png", id: 819 },
        //{nombre:"cagiva.png",id:0},
        { nombre: "chevrolet.png", id: 138 },
        { nombre: "chrysler.png", id: 20 },
        { nombre: "citroen.png", id: 21 },
        { nombre: "dacia.png", id: 139 },
        { nombre: "daewoo.png", id: 185 },
        { nombre: "daihatsu.png", id: 25 },
        //{nombre:"derbi.png",id:0},
        { nombre: "dodge.png", id: 29 },
        //{nombre:"ducati.png",id:0},
        { nombre: "fiat.png", id: 35 },
        { nombre: "ford.png", id: 36 },
        //{nombre:"gilera.png",id:0},
        { nombre: "honda.png", id: 45 },
        { nombre: "hyundai.png", id: 183 },
        { nombre: "infiniti.png", id: 1526 },
        { nombre: "isuzu.png", id: 54 },
        { nombre: "iveco.png", id: 55 },
        { nombre: "jaguar.png", id: 56 },
        { nombre: "jeep.png", id: 882 },
        //{nombre:"kawasaki.png",id:0},
        { nombre: "kia.png", id: 184 },
        //{nombre:"kymco.png",id:0},
        { nombre: "lada.png", id: 63 },
        { nombre: "lancia.png", id: 64 },
        { nombre: "landRover.png", id: 1820 },
        { nombre: "ldv.png", id: 1380 },
        { nombre: "lexus.png", id: 842 },
        { nombre: "lincoln.png", id: 1200 },
        { nombre: "mazda.png", id: 72 },
        { nombre: "mercedes.png", id: 74 },
        { nombre: "mg.png", id: 75 },
        { nombre: "mini.png", id: 1523 },
        { nombre: "mitsubishi.png", id: 77 },
        { nombre: "nissan.png", id: 80 },
        { nombre: "opel.png", id: 84 },
        { nombre: "peugeot.png", id: 88 },
        { nombre: "piaggio.png", id: 181 },
        { nombre: "porsche.png", id: 92 },
        { nombre: "proton.png", id: 778 },
        { nombre: "renault.png", id: 93 },
        { nombre: "rover.png", id: 95 },
        { nombre: "saab.png", id: 99 },
        { nombre: "seat.png", id: 104 },
        { nombre: "skoda.png", id: 106 },
        { nombre: "smart.png", id: 1138 },
        { nombre: "ssangyong.png", id: 175 },
        { nombre: "subaru.png", id: 107 },
        { nombre: "suzuki.png", id: 109 },
        { nombre: "toyota.png", id: 111 },
        { nombre: "tvr.png", id: 861 },
        //{nombre:"vespa.png",id:0},
        { nombre: "volskwagen.png", id: 121 },
        { nombre: "volvo.png", id: 120 },
        //{nombre:"yamaha.png",id:0},
    ]
    return marcas;
}


//"https://sertec.servicenext.eu/Graficos/IMG/",
//"http://86.109.106.103:9000/IMAGENES_TECDOC/IMG/"
export default createStore(reducer, {
    DIRECTORIO_DOCUMENTO: "",
    ALBARAN_SELECCIONADO: {},
    ID_MODELO_MOTO: null,
    DICCIONARIO: [],
    DICCIONARIO_LOGIN: [],


    NEUMATICO: { ES_NEUMATICO: false, REFERENCIA_FABRICANTE: "" },
    SUGERIDOS: { ES_ARTICULO_SUGERIDO: false },
    fichaBusquedaVehiculo: {
        manufactur: objetoMarcas(),
        allManufactur: {},
        models: {},
        enginesType: {},
        fichaCoche: {},
        historicapp: [],
        vehiculoPorMatricula: {},
        vehiculoPorBastidor: {},
        idmarca: "",
        idmodelo: "",

        TIPO_COMBUSTIBLE: 1,
        banderaImagen: "./imagenes/ESP.png",
        codPaisIdioma: "",
        codigoHaynesPro: "",
        cocheABuscar: "",
        VEHICULO_SERTEC: {}
    },
    datosFacturacion: {
        ivaPorDefecto: 0,
        manoObraPorDecto: 0
    },
    datosConfiguracion: {
        emailEnvioDocumento: "",
    },

    DROP_SELECCION_COCHE: {
        NAME_Drop: "Matricula",
        IDENTIFICADOR: "MAT"
    },
    GT_ESTIMATE: {
        ID: "",
        CODIGO: "",
        IDENTIFICADOR_COCHE: ""
    },
    estadoDrawer: {
        openDrawerHistoricoCoche: false,
        openDrawerCarroCompra: false,
        openDrawerMasInfo: false,
        openDrawerMasInfo2: false,
        openDrawerStockAlmacen: false,
        openDrawerReferenciaGrafico: false,
        openDrawerReferenciaGraficoCarroceria: false,
        openDrawerHaynesPro: false,
        openDrawerIntranet: false,
        openDrawerPerfilUsuario: false,
        openDrawerNextAssist: false,
        openDrawerAcademyCloud: false,
        openDrawerFichaCliente: false,
        openDrawerFichaCocheDGT: false,
        openDrawerFichaClienteTaller: false,
        openDrawerFichaMantenimientoCliente: false,
        openDrawerFichaMantenimientoVehiculo: false,
        openDrawerFichaPresupuestoTaller: false,
        openDrawerFichaAlbaranesTaller: false,
        openDrawerFichaDatosClientes: false,
        openDrawerFichaMarcasVehiculo: false,
        openDrawerFichaReferenciaVehiculo: false,
        openDrawerFichaBateriasVehiculo: false,
        openDrawerFichaEquivalenciaArticulo: false,
        openDrawerFichaMantenimientoPresupuesto: false,
        openDrawerEmpresa: false,
        openDrawerFichaVehiculoCliente: false,
        openDrawerFichaManoObra: false,
        openDrawerFichaArticulo: false,
        openDrawerFichaImpresionDocumento: false,
        openDrawerFichaEnvioDocumento: false,
        openDrawerFichaTiemposReparacion: false,
        openDrawerFichaGMYVehiculoHaynesPro: false,
        openDrawerFichaMantenimientoHaynesPro: false,
        openDrawerFichaPublicidad: false,
        openDrawerFichaLubricantes: false,
        openDrawerFichaNeumaticos: false,
        openDrawerFichaHistorialPedidos: false,
        openDrawerFichaProveedores: false,
        openDrawerFichaOrdenTaller: false,
        openDrawerFichaFacturaTaller: false,
        openDrawerFichaGTEstimate: false
    },
    clienteTallerSeleccionado: {
        id: "",
        esNuevoCliente: false,
        actualizarListaCliente: false,
        clienteTaller: {},
        esDesdeFichaPresupuesto: false
    },

    VehiculoTallerSeleccionado: {
        id: "",
        esNuevoVehiculo: false,
        actualizarListaVehiculo: false,
        clienteVehiculoTaller: {},
        vehiculoPorApi: {},
        vehiculoPorModelo: {}
    },

    EquivalenciaArticuloSeleccionado: {
        marca: "",
        referencia: "",
        artno: "",
    },
    PresupuestoTallerSeleccionado: {
        id: 0,
        esNuevoPresupuesto: false,
        actualizarListaPresupuesto: false,
        cliente: {},
        vehiculo: {},
        presupuestoSecundario: []
    },

    BusquedaPorModelo: {
        tipoBusqueda: 'C',
        descripcion: 'CARROCERIA',

    },

    NOTIFICACIONES_APP: { CHAT: 0, CESTA: 0 },

    TIPO_VEHICULO_POR_MODELO: "",
    TipoGrafico: 1,
    FiltroGrupoPorDefecto: true,
    VEHICLE_OEM: {},
    PreLoaderPage: false,
    ClientesMostrador: [],
    TextoBuscadorPorModelo: "",
    ReferenciaMarcaVehiculoSeleccionado: "",
    abrirModalVehiculo: false, actualizarCestaLineas: false,
    localvehicles: [], mostrarComponente: false, propiedadesVisor: {}, usuario: {}, colapseMarca: true, datosGraficos: { original: objetoGraficoV2(), auxiliar: objetoGraficoV2(0), verTreeApartadoGrafico: false, historialGrafico: [] },
    isColorListItem: false, urlacademy: "", listaTree: [], listaEstructura: [], listaTreeGraficos: [],
    configApp: { width: 0, height: 0, visualizacionDescuento: true, visualizacionFiltro: true, visualizacionGrafico: true }, localreferences: [], DROP_CAR: { nameDropDownCar: "Matricula", CODIGO_DROP: "MAT" }, buscadorCoche: {}, buscadorTree: { expandedKeys: [], searchValue: '', nodoIdActual: "" }, codmotorizacion: "", codMotorizacionFichaHistorial: "",
    spinnerAcademy: true, spinnerLoginHaynes: true, showmasterpage: false, childrenDrawerLevel2: false, childrenDrawerLevel3: false, openDrawerIntegraciones: false,
    activeItemReferencia: "", grupoDeMarcas: { marcas: [] }, pagination: { numeroRegistroPagina: 10, tipoFiltroPorMarca: [] },
    urlhaynes: "", urlnextassisttaller: [], mostrarCoche: false, sesionHaynesPro: "", mostarTodasLasMarcas: false, activeItemCar: "1", activeItemTipoIntegracion: "", checkedmarcas: [], referenciaSeleccionada: {}, referenciaMasInfo2: {}, haynesProTouch: [], imagenHaynesCoche: "", detenerBusquedaDetalles: false, masInformacion: { direccion: {}, imagenes: {}, documentos: {}, refOE: {}, despiece: {}, vehiculos: {}, masInfoModels: {}, masInfoMotorizacion: {}, desglose: {} },
    filtrosReferencia: { verTodasLasMarcas: true, verTodosLosGrupos: true }, idiomaActual: {}, referenciaActual: {}, nodoGraficoSeleccionado: { id: "", nombreGrupo: "" }, txtBuscadorReferencia: "", clienteSeleccionado: { registroCliente: {}, nombre: "", codigo: "", cif: "", listaTreeFiltrada: { openFiltroTree: false, registrosFiltro: [] } }
}, applyMiddleware(logger, thunk));
