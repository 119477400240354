import React from "react";
import {
    traducirPagina

} from "../../librerias/funciones"
import { Drawer, Modal } from 'antd';

import { DownOutlined, PrinterOutlined, RightOutlined, ExpandAltOutlined } from "@ant-design/icons";
import { MDBBtn } from 'mdbreact';
import { ObtenerListaDatosTecnicosEnAPI, ObtenerUnDatoTecnicoHTMLEnAPI } from "../../action/actionCreatorsRequests";

export const RMIAjustes = (props) => {
    const [initComponent, setInitComponent] = React.useState({
        REGISTROS: "",
        HTML_MANUAL: "",
        ABRIR_MODAL: false,
        TITULO_MODAL: "",
        ID_GRUPO_SELECCIONADO: 0,
        RUTA: []


    })


    const ObtenerListaDatosTecnicos = async (ID_VEHICULO) => {
        const registros = await ObtenerListaDatosTecnicosEnAPI(ID_VEHICULO);
        const { OK, LISTA_DATOS_TECNICOS } = registros === undefined ? { OK: false, LISTA_DATOS_TECNICOS: null } : registros

        if (OK) {

            LISTA_DATOS_TECNICOS.map((registro, i) => {
                registro.SELECCIONADO = true;
                registro.LISTA_SUBGRUPOS.map((item, x) => item.SELECCIONADO = true)
                return registro
            })

            let { ID_GRUPO_PRINCIPAL, NOMBRE_GRUPO_PRINCIPAL } = LISTA_DATOS_TECNICOS !== undefined && LISTA_DATOS_TECNICOS !== null && LISTA_DATOS_TECNICOS.length > 0 ? LISTA_DATOS_TECNICOS[0] : { ID_GRUPO_PRINCIPAL: null, NOMBRE_GRUPO_PRINCIPAL: "" }

            if (ID_GRUPO_PRINCIPAL !== null) {
                ObtenerUnDatoTecnicoHTML(ID_GRUPO_PRINCIPAL, null, null)
                initComponent.TITULO_MODAL = `${NOMBRE_GRUPO_PRINCIPAL}`
                initComponent.ID_GRUPO_SELECCIONADO = ID_GRUPO_PRINCIPAL
            }
            initComponent.REGISTROS = LISTA_DATOS_TECNICOS
        } else {
            initComponent.REGISTROS = null
        }


        if (initComponent.REGISTROS === null) {
            initComponent.HTML_MANUAL = null
        }

        setInitComponent({ ...initComponent });
    }


    const ObtenerUnDatoTecnicoHTML = async (ID_GRUPO_PRINCIPAL, ID_SUBGRUPO, ID_ELEMENTO) => {
        const ID_VEHICULO_TECDOC = props.ID_VEHICULO != 0 ? props.ID_VEHICULO : ""

        initComponent.HTML_MANUAL = ""
        setInitComponent({ ...initComponent });

        const registros = await ObtenerUnDatoTecnicoHTMLEnAPI(ID_VEHICULO_TECDOC, ID_GRUPO_PRINCIPAL, ID_SUBGRUPO, ID_ELEMENTO);
        const { OK, DATOS_TECNICOS_HTML } = registros === undefined ? { OK: false, DATOS_TECNICOS_HTML: null } : registros
        if (OK) {
            initComponent.HTML_MANUAL = DATOS_TECNICOS_HTML
        } else {
            initComponent.HTML_MANUAL = null
        }
        setInitComponent({ ...initComponent });
    }


    const printDiv = async () => {

        let data = document.getElementsByClassName("ContenedorDocumentoAjustes");

        if (data.length > 0) {
            let myWindow = window.open('', 'my div', 'height=600,width=600');
            myWindow.document.write(data[0].innerHTML);
            myWindow.document.close();

            myWindow.onload = function () {
                myWindow.focus();
                myWindow.print();
                myWindow.close();
            };
        }
    }



    React.useEffect(() => {
        const ID_VEHICULO_TECDOC = props.ID_VEHICULO != 0 ? props.ID_VEHICULO : ""

        ObtenerListaDatosTecnicos(ID_VEHICULO_TECDOC)
    }, [])



    return <div>
        <Drawer
            width={"90%"}
            onClose={() => {
                if (props.TIPO_FICHA === "MASTER") {
                    props.CERRAR.setState({ ABRIR_FICHA_RMI_AJUSTES: false })
                } else {
                    props.CERRAR_FICHA(false)
                }
            }}
            visible={props.ABRIR_FICHA}
            placement={"right"}
            closable={false}
            bodyStyle={{ padding: '0px' }}
            style={{ color: '#fff' }}>

            <div className="flex-container-Grow-Tercero" >

                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                        onClick={(e) => {
                            if (props.TIPO_FICHA === "MASTER") {
                                props.CERRAR.setState({ ABRIR_FICHA_RMI_AJUSTES: false })
                            } else {
                                props.CERRAR_FICHA(false)
                            }
                        }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>

            <div className="containerRMIManuales">

                <div className="item_1_manualesRMI">
                    {

                        initComponent.REGISTROS === null ?
                            <div className="center_item_alert" style={{ height: '100%' }}> <h3>{traducirPagina("No_resultados")}</h3> </div>
                            :
                            initComponent.REGISTROS === "" ?
                                <div className="center_item_alert" style={{ height: '100%' }} > <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                                :

                                <div className="lista_secudario_manuales">

                                    {initComponent.REGISTROS.map((registro) =>
                                        <>
                                            <div className="efecto" key={registro.ID_GRUPO_PRINCIPAL} onClick={() => {
                                                ObtenerUnDatoTecnicoHTML(registro.ID_GRUPO_PRINCIPAL, null, null)
                                                initComponent.ID_GRUPO_SELECCIONADO = registro.ID_GRUPO_PRINCIPAL
                                                initComponent.TITULO_MODAL = `${registro.NOMBRE_GRUPO_PRINCIPAL}`
                                                setInitComponent({ ...initComponent })
                                            }}>

                                                {registro.SELECCIONADO ? <DownOutlined></DownOutlined> : <RightOutlined></RightOutlined>}   {registro.NOMBRE_GRUPO_PRINCIPAL}

                                            </div>


                                            {registro.SELECCIONADO &&

                                                registro.LISTA_SUBGRUPOS.map((item) =>
                                                    <>
                                                        <div className="item_nivel2_manual efecto" onClick={() => {

                                                            initComponent.TITULO_MODAL = `${item.NOMBRE_SUBGRUPO}`
                                                            setInitComponent({ ...initComponent })


                                                            ObtenerUnDatoTecnicoHTML(null, item.ID_SUBGRUPO)


                                                        }}>{item.SELECCIONADO ? <DownOutlined></DownOutlined> : <RightOutlined></RightOutlined>} {item.NOMBRE_SUBGRUPO} </div>

                                                        {item.SELECCIONADO &&
                                                            <div className="item_nivel2_manual">
                                                                <ul className="lista_secudario_grupo_manual">
                                                                    {item.LISTA_ELEMENTOS.map((registro_el, i) =>
                                                                        <li>
                                                                            {
                                                                                <div className="item_manual_ver" style={item.LISTA_ELEMENTOS.length - 1 === i ? { marginBottom: '0px' } : {}} onClick={() => {
                                                                                    initComponent.ID_GRUPO_SELECCIONADO = item.ID_SUBGRUPO
                                                                                    ObtenerUnDatoTecnicoHTML(null, null, registro_el.ID_ELEMENTO)
                                                                                    initComponent.TITULO_MODAL = `${item.NOMBRE_SUBGRUPO}, ${registro_el.TEXTO_ELEMENTO}`
                                                                                    setInitComponent({ ...initComponent })

                                                                                }}>{registro_el.TEXTO_ELEMENTO}</div>

                                                                            }
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        }
                                                    </>
                                                )
                                            }
                                        </>
                                    )}
                                </div>
                    }
                </div>


                <div className='item_2_manualesRMI'>

                    {

                        initComponent.HTML_MANUAL === null ?
                            <div className="center_item_alert calcular_altura_modal_rmi" > <h3>{traducirPagina("No_resultados")}</h3> </div>
                            :
                            initComponent.HTML_MANUAL === "" ?
                                <div className="center_item_alert calcular_altura_modal_rmi" > <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                                :
                                <>
                                    <div className="header_manual_html">
                                        <button onClick={() => {
                                            initComponent.ABRIR_MODAL = true;
                                            setInitComponent({ ...initComponent })

                                        }}><ExpandAltOutlined></ExpandAltOutlined></button>

                                        <button><PrinterOutlined onClick={() => {
                                            printDiv("printManualLocal")
                                        }}></PrinterOutlined></button>
                                    </div>

                                    <div className='lista_secudario_manuales calcular_altura_manual'>

                                        <div className="ContenedorDocumentoManual printManualLocal" dangerouslySetInnerHTML={{ __html: (initComponent.HTML_MANUAL) }}></div>
                                    </div>
                                </>


                    }

                </div>

            </div>
        </Drawer>




        <Modal
            title={<div className="containerModalTitulo"><div>{initComponent.TITULO_MODAL}</div> <div><button className="btnPrintRMI" onClick={() => { printDiv("printManualModal") }}><PrinterOutlined></PrinterOutlined></button></div></div>}
            centered
            visible={initComponent.ABRIR_MODAL}
            onOk={() => { initComponent.ABRIR_MODAL = false; setInitComponent({ ...initComponent }) }}
            onCancel={() => { initComponent.ABRIR_MODAL = false; setInitComponent({ ...initComponent }) }}
            footer={null}
            width={"100%"}
        >

            {initComponent.HTML_MANUAL === null ?
                <div className="center_item_alert calcular_altura_modal_rmi" > <h3>{traducirPagina("No_resultados")}</h3> </div>
                :
                initComponent.HTML_MANUAL === "" ?
                    <div className="center_item_alert calcular_altura_modal_rmi" > <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                    :
                    <div className='manual_modal_body'>

                        <div className="ContenedorDocumentoAjustes printManualModal" dangerouslySetInnerHTML={{ __html: (initComponent.HTML_MANUAL) }}></div>


                    </div>
            }
        </Modal>

    </div>;
};