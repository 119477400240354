import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createRipple, DrawerFichaMantenimientoVehiculoTaller } from "../action/actionCreators"
import { setClienteTallerEnAPI, getClienteTallerEnAPI, setProveedoresEnAPI, getProveedoresEnAPI } from "../action/actionCreatorsRequests"
import { MSGOK, traducirPagina } from '../librerias/funciones'
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBCol } from 'mdbreact';
import {
    PlusOutlined,
    SaveOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';

import { Card, Input, Row, Col, Drawer, Tabs } from 'antd';
import store from "../store";
const { TabPane } = Tabs;

const MantenimientoProveedor = ({ props, configApp }) => {
    const [hayRegistros, setHayRegistros] = React.useState(false);
    const [tabPorDefecto, setTabPorDefecto] = React.useState("1");
    const [vehiculos, setVehiculos] = React.useState([]);

    const [datosProveedor, setDatosProveedor] = React.useState(
        {
            ID: null,
            CODIGO: "",
            NOMBRE: "",
            CIF: "",
            DIRECCION: "",
            POBLACION: "",
            PROVINCIA: "",
            CP: "",
            PAIS: "",
            TELEFONO: "",
            TELEFONO_2: "",
            TELEFONO_3: "",
            MAIL: "",
            OBSERVACIONES: ""
        }
    );

    if (!hayRegistros && props.ABRIR_FICHA) {
        setHayRegistros(true);
        if (Object.keys(props.PAYLOAD).length > 0) {
            getProveedoresEnAPI(1, 1, "1", props.PAYLOAD.ID, null, "", "").then((proveedor) => {
                if (proveedor !== undefined) {
                    if (proveedor.PROVEEDORES !== null || proveedor.PROVEEDORES.length > 0) {
                        let REGISTRO = proveedor.PROVEEDORES[0]
                        setDatosProveedor(
                            {
                                ID: REGISTRO.ID,
                                CODIGO: REGISTRO.CODIGO,
                                NOMBRE: REGISTRO.NOMBRE,
                                CIF: REGISTRO.CIF,
                                DIRECCION: REGISTRO.DIRECCION,
                                POBLACION: REGISTRO.POBLACION,
                                PROVINCIA: REGISTRO.PROVINCIA,
                                CP: REGISTRO.CP,
                                PAIS: REGISTRO.PAIS,
                                TELEFONO: REGISTRO.TELEFONO,
                                TELEFONO_2: REGISTRO.TELEFONO_2,
                                TELEFONO_3: REGISTRO.TELEFONO_3,
                                MAIL: REGISTRO.MAIL,
                                OBSERVACIONES: REGISTRO.OBSERVACIONES
                            }
                        )
                    }
                }
            })
        }
    } else {
        if (!props.ABRIR_FICHA && hayRegistros) {
            setHayRegistros(false);
            setTabPorDefecto("1");
            setDatosProveedor({
                ID: null,
                CODIGO: "",
                NOMBRE: "",
                CIF: "",
                DIRECCION: "",
                POBLACION: "",
                PROVINCIA: "",
                CP: "",
                PAIS: "",
                TELEFONO: "",
                TELEFONO_2: "",
                TELEFONO_3: "",
                MAIL: "",
                OBSERVACIONES: ""
            })
        }
    }

    const guardarDatosCliente = (e) => {
        const ID = e.target.name === 'ID' ? e.target.value : datosProveedor.ID;
        const CODIGO = e.target.name === 'CODIGO' ? e.target.value : datosProveedor.CODIGO;
        const NOMBRE = e.target.name === 'NOMBRE' ? e.target.value : datosProveedor.NOMBRE;
        const CIF = e.target.name === 'CIF' ? e.target.value : datosProveedor.CIF;
        const DIRECCION = e.target.name === 'DIRECCION' ? e.target.value : datosProveedor.DIRECCION;
        const POBLACION = e.target.name === 'POBLACION' ? e.target.value : datosProveedor.POBLACION;
        const PROVINCIA = e.target.name === 'PROVINCIA' ? e.target.value : datosProveedor.PROVINCIA;
        const CP = e.target.name === 'CP' ? e.target.value : datosProveedor.CP;
        const PAIS = e.target.name === 'PAIS' ? e.target.value : datosProveedor.PAIS;
        const TELEFONO = e.target.name === 'TELEFONO' ? e.target.value : datosProveedor.TELEFONO;
        const TELEFONO_2 = e.target.name === 'TELEFONO_2' ? e.target.value : datosProveedor.TELEFONO_2;
        const TELEFONO_3 = e.target.name === 'TELEFONO_3' ? e.target.value : datosProveedor.TELEFONO_3;
        const MAIL = e.target.name === 'MAIL' ? e.target.value : datosProveedor.MAIL;
        const OBSERVACIONES = e.target.name === 'OBSERVACIONES' ? e.target.value : datosProveedor.OBSERVACIONES;

        setDatosProveedor(
            {
                ID: ID,
                CODIGO: CODIGO,
                NOMBRE: NOMBRE,
                CIF: CIF,
                DIRECCION: DIRECCION,
                POBLACION: POBLACION,
                PROVINCIA: PROVINCIA,
                CP: CP,
                PAIS: PAIS,
                TELEFONO: TELEFONO,
                TELEFONO_2: TELEFONO_2,
                TELEFONO_3: TELEFONO_3,
                MAIL: MAIL,
                OBSERVACIONES: OBSERVACIONES
            }
        )
    }

    const insertarCliente = () => {
        //Verificar campos necesarios.
        setProveedoresEnAPI(Object.keys(props.PAYLOAD).length > 0 ? 1 : 0, datosProveedor).then((respuesta) => {
            if (respuesta !== undefined) {
                if (respuesta.OK) {
                    props.MOSTRAR_PROV(1, 1, "1", null, null, "", "")
                    props.CERRAR_FICHA(false)
                    if (Object.keys(props.PAYLOAD).length > 0) {
                        MSGOK("Proveedor modificado correctamente.")
                    } else {
                        MSGOK("Proveedor añadido correctamente.")
                    }
                }
            }
        })
    }

    return (
        <Drawer
            width={600}
            onClose={() => {
                props.CERRAR_FICHA(false)
            }}
            visible={props.ABRIR_FICHA}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '999999999999999', color: '#fff' }}>

            <div className="flex-container-Grow-Tercero" >
                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                        onClick={(e) => {
                            props.CERRAR_FICHA(false)

                        }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>

            <div className="p-2" style={{ height: (configApp.height - 70).toString() + "px", overflow: 'auto' }}>
                <Tabs defaultActiveKey={"1"} activeKey={tabPorDefecto} onChange={(tab) => setTabPorDefecto(tab)} >
                    <TabPane tab={traducirPagina("Datos_generales")} key="1" >
                        <div style={{ height: (configApp.height - 190).toString() + "px", }} className="ml-4 mr-4" >
                            <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                                <Col md={10} >
                                    <span>{traducirPagina("Codigo")} </span>
                                </Col>
                                <Col md={14} >
                                    <span>{traducirPagina("Cif").toUpperCase()}</span>
                                </Col>
                                <Col md={10} >
                                    <Input disabled={true} name="CODIGO" value={datosProveedor.CODIGO} onChange={(e) => guardarDatosCliente(e)} />
                                </Col>

                                <Col md={10} >
                                    <Input name="CIF" value={datosProveedor.CIF} onChange={(e) => guardarDatosCliente(e)} />
                                </Col>

                                <Col md={24}>
                                    <span>{traducirPagina("Nombre")} </span>
                                </Col>
                                <Col md={24} >
                                    <Input name="NOMBRE" value={datosProveedor.NOMBRE} onChange={(e) => guardarDatosCliente(e)} />
                                </Col>

                                <Col md={8} >
                                    <span>{traducirPagina("Telefono_1")} </span>
                                </Col>

                                <Col md={8} >
                                    <span>{traducirPagina("Telefono_2")} </span>
                                </Col>

                                <Col md={8} >
                                    <span>{traducirPagina("Telefono_3")} </span>
                                </Col>

                                <Col md={8} >
                                    <Input name="TELEFONO" value={datosProveedor.TELEFONO} onChange={(e) => guardarDatosCliente(e)} />
                                </Col>
                                <Col md={8} >
                                    <Input name="TELEFONO_2" value={datosProveedor.TELEFONO_2} onChange={(e) => guardarDatosCliente(e)} />
                                </Col>
                                <Col md={8} >
                                    <Input name="TELEFONO_3" value={datosProveedor.TELEFONO_3} onChange={(e) => guardarDatosCliente(e)} />
                                </Col>
                                <Col md={24}>
                                    <span>{traducirPagina("Email")}</span>
                                </Col>

                                <Col md={24}>
                                    <Input name="MAIL" value={datosProveedor.MAIL} onChange={(e) => guardarDatosCliente(e)} />
                                </Col>

                                <Col md={24}>
                                    <span>{traducirPagina("Observaciones")}</span>
                                </Col>

                                <Col md={24}>
                                    <Input.TextArea allowClear rows={7} name="OBSERVACIONES" value={datosProveedor.OBSERVACIONES} onChange={(e) => guardarDatosCliente(e)} />
                                </Col>
                            </Row>
                        </div>

                        <div style={{ flexGrow: '6', textAlign: 'right' }} className="ml-4 mr-4" >
                            <button className="activebtnMenu2" onClick={() => { insertarCliente(); }} style={{ height: '40px', width: '220px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', cursor: 'pointer' }} ><SaveOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Guardar_y_salir").toUpperCase()} </span>  </button>
                        </div>
                    </TabPane>

                    <TabPane tab={traducirPagina("Direccion_fiscal")} key="2" >
                        <div style={{ height: (configApp.height - 203).toString() + "px", }} className="mb-2" >
                            <div style={{ height: (configApp.height - 190).toString() + "px", }} className="ml-4 mr-4" >
                                <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                                    <Col md={24} >
                                        <span>{traducirPagina("Pais")} </span>
                                    </Col>
                                    <Col md={24}>
                                        <Input name="PAIS" value={datosProveedor.PAIS} onChange={(e) => guardarDatosCliente(e)} />
                                    </Col>

                                    <Col md={24}>
                                        <span>{traducirPagina("Provincia")}</span>
                                    </Col>
                                    <Col md={24} >
                                        <Input name="PROVINCIA" value={datosProveedor.PROVINCIA} onChange={(e) => guardarDatosCliente(e)} />
                                    </Col>

                                    <Col md={24}>
                                        <span>{traducirPagina("Poblacion")}</span>
                                    </Col>

                                    <Col md={24} >
                                        <Input name="POBLACION" value={datosProveedor.POBLACION} onChange={(e) => guardarDatosCliente(e)} />
                                    </Col>

                                    <Col md={20} >
                                        <span>{traducirPagina("Direccion")} </span>
                                    </Col>

                                    <Col md={4}>
                                        <span>{traducirPagina("CP").toUpperCase()}</span>
                                    </Col>

                                    <Col md={20} >
                                        <Input name="DIRECCION" value={datosProveedor.DIRECCION} onChange={(e) => guardarDatosCliente(e)} />
                                    </Col>

                                    <Col md={4} >
                                        <Input name="CP" value={datosProveedor.CP} onChange={(e) => guardarDatosCliente(e)} />
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div style={{ flexGrow: '6', textAlign: 'right' }} className="ml-4 mr-4" >
                            <button className="activebtnMenu2" onClick={() => { insertarCliente(); }} style={{ height: '40px', width: '220px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', cursor: 'pointer' }} ><SaveOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Guardar_y_salir").toUpperCase()} </span>  </button>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </Drawer>
    )
}


MantenimientoProveedor.propTypes = {
    title: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    PAYLOAD: PropTypes.object,
    MOSTRAR_PROV: PropTypes.func

};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp
    };
}

export default connect(mapStateToProps)(MantenimientoProveedor);