import React from "react"

const BtnSolicitar = ({ texto = [], onClick = () => { } }) => {

    const separador = (texto) => {
        if (texto === null) { return [] }

        let lista = texto.split("!")

        if (lista.length === 2) {
            lista[0] = `${lista[0]} !`
        }

        return lista
    }

    const items = separador(texto)

    return (
        <button style={{ minHeight: '60px', width: '250px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#fff', backgroundColor: '#fd2501' }} onClick={onClick}>
            {items.map((item, i) =>
                <span style={{ fontWeight: '500', display: 'block', verticalAlign: 'middle', fontSize: '16px', marginTop: items.length === 2 && (items.length - 1) === i ? '10px' : '0px' }}>{item}</span>
            )}
        </button>
    )

}

export default BtnSolicitar;