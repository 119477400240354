import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DrawerFichaArticulo } from "../action/actionCreators"
import { MSGOK, convertirMoneda, calculoLineaFacturacion, dynamicSortDesc, dynamicSortAsc, calculoTotalesPresupuesto,asignarImportes,MSGERROR, traducirPagina } from '../librerias/funciones'
import { MDBBtn, MDBCol } from 'mdbreact';
import {
    SaveOutlined

} from '@ant-design/icons';

import { Card, Input, Row, Col, Drawer, Tabs } from 'antd';
import store from "../store";
const { TabPane } = Tabs;

const FichaArticulo = ({ props, configApp, openDrawerFichaArticulo, datosFacturacion }) => {
    const [cargarLineasLocal, setCargarLineasLocal] = React.useState(false);
    const [desabilitarBotonLinea, setDesabilitarBotonLinea] = React.useState(false);

    const [lineasLocal, setLineasLocal] = React.useState([]);
    const inputRef = React.useRef(null);
    const sharedProps = {
        ref: inputRef,
    };
    const [payLoadLocal, setPayLoadLocal] = React.useState(
        {
            ORDEN_LINEA: 0,
            ID_PRESUPUESTO: null,
            TIPO_LINEA: "A",
            REFERENCIA_SOCIO: "",
            REFERENCIA_TECDOC: "",
            MARCA_SOCIO: "",
            MARCA_SOCIO_DESCRIPCION: "",
            MARCA_TECDOC: 0,
            MARCA_TECDOC_DESCRIPCION: "",
            REFERENCIA_DESCRIPCION: "",
            MANO_OBRA: "",
            PRECIO: 0.00,
            IVA_VALOR: 0.00,
            DTO_VALOR: 0.00,
            CANTIDAD: 1.00,
            IMPORTE_BRUTO: 0.00,
            IMPORTE_DESCUENTOS: 0.00,
            IMPORTE_BASE_IMPONIBLE: 0.00,
            IMPORTE_IMPUESTOS: 0.00,
            IMPORTE_TOTAL: 0.00,
            ID: 0
        }
    );


    const calcularTodo = (cantidad, precio, DTO, iva) => {
        let lineFacturacion = calculoLineaFacturacion(cantidad, precio, DTO, iva)

        payLoadLocal.IMPORTE_BRUTO = lineFacturacion.importeBruto;
        payLoadLocal.IMPORTE_DESCUENTOS = lineFacturacion.importeDescuento;
        payLoadLocal.IMPORTE_BASE_IMPONIBLE = lineFacturacion.baseImp;
        payLoadLocal.IMPORTE_IMPUESTOS = lineFacturacion.impuesto;
        payLoadLocal.IMPORTE_TOTAL = lineFacturacion.total;

        setPayLoadLocal(payLoadLocal)
    }


    if (openDrawerFichaArticulo && !cargarLineasLocal) {
        setCargarLineasLocal(true);
        setLineasLocal(props.lineas)

        setTimeout(() => {
            inputRef.current.focus({
                cursor: 'start',
            });
        }, 500)


        if (Object.keys(props.lineaSeleccionada).length > 0) {
            setPayLoadLocal({
                ORDEN_LINEA: props.lineaSeleccionada.ORDEN_LINEA,
                ID_PRESUPUESTO: props.lineaSeleccionada.ID_PRESUPUESTO,
                TIPO_LINEA: props.lineaSeleccionada.TIPO_LINEA,
                REFERENCIA_SOCIO: props.lineaSeleccionada.REFERENCIA_SOCIO,
                REFERENCIA_TECDOC: props.lineaSeleccionada.REFERENCIA_TECDOC,
                MARCA_SOCIO: props.lineaSeleccionada.MARCA_SOCIO,
                MARCA_SOCIO_DESCRIPCION: props.lineaSeleccionada.MARCA_SOCIO_DESCRIPCION,
                MARCA_TECDOC: props.lineaSeleccionada.MARCA_TECDOC,
                MARCA_TECDOC_DESCRIPCION: props.lineaSeleccionada.MARCA_TECDOC_DESCRIPCION,
                REFERENCIA_DESCRIPCION: props.lineaSeleccionada.REFERENCIA_DESCRIPCION,
                MANO_OBRA: props.lineaSeleccionada.MANO_OBRA,
                PRECIO: props.lineaSeleccionada.PRECIO,
                IVA_VALOR: props.lineaSeleccionada.IVA_VALOR,
                DTO_VALOR: props.lineaSeleccionada.DTO_VALOR,
                CANTIDAD: props.lineaSeleccionada.CANTIDAD,
                IMPORTE_BRUTO: props.lineaSeleccionada.IMPORTE_BRUTO,
                IMPORTE_DESCUENTOS: props.lineaSeleccionada.IMPORTE_DESCUENTOS,
                IMPORTE_BASE_IMPONIBLE: props.lineaSeleccionada.IMPORTE_BASE_IMPONIBLE,
                IMPORTE_IMPUESTOS: props.lineaSeleccionada.IMPORTE_IMPUESTOS,
                IMPORTE_TOTAL: props.lineaSeleccionada.IMPORTE_TOTAL,
                ID: props.lineaSeleccionada.ID

            })
        } else {
            payLoadLocal.IVA_VALOR = datosFacturacion.ivaPorDefecto
            //payLoadLocal.PRECIO = datosFacturacion.manoObraPorDecto

            setPayLoadLocal(payLoadLocal)
            calcularTodo(payLoadLocal.CANTIDAD, payLoadLocal.PRECIO, payLoadLocal.DTO_VALOR, payLoadLocal.IVA_VALOR);
        }

    } else {
        if (cargarLineasLocal && !openDrawerFichaArticulo) {
            setCargarLineasLocal(false);
            setLineasLocal([])
            //setFormatearCantidad(true)
          
            setPayLoadLocal({
                ORDEN_LINEA: 0,
                ID_PRESUPUESTO: null,
                TIPO_LINEA: "A",
                REFERENCIA_SOCIO: "",
                REFERENCIA_TECDOC: "",
                MARCA_SOCIO: "",
                MARCA_SOCIO_DESCRIPCION: "",
                MARCA_TECDOC: 0,
                MARCA_TECDOC_DESCRIPCION: "",
                REFERENCIA_DESCRIPCION: "",
                MANO_OBRA: "",
                PRECIO: 0.00,
                IVA_VALOR: 0.00,
                DTO_VALOR: 0.00,
                CANTIDAD: 1.00,
                IMPORTE_BRUTO: 0.00,
                IMPORTE_DESCUENTOS: 0.00,
                IMPORTE_BASE_IMPONIBLE: 0.00,
                IMPORTE_IMPUESTOS: 0.00,
                IMPORTE_TOTAL: 0.00,
                ID: 0
            })
        }
    }

    const gestionarLineaArticulo = () => {
        let arr = lineasLocal;

        if (Object.keys(props.lineaSeleccionada).length > 0) {
            let buscarID = arr.findIndex(manoObra => manoObra.ID === payLoadLocal.ID)
            if (buscarID !== undefined) {
                //let i = arr.indexOf(payLoadLocal.ID);
                arr.splice(buscarID, 1);

                let ultimaID = 0;
                if (lineasLocal.length === 0) {
                    ultimaID = 1;
                } else {
                    let item = lineasLocal.sort(dynamicSortDesc("ID"));
                    ultimaID = item[0].ID + 1;
                }

                payLoadLocal.ID = ultimaID;
                arr.push(payLoadLocal);


                props.setLineasArticulos(arr.sort(dynamicSortAsc("ORDEN_LINEA")))
                let resultTotales = calculoTotalesPresupuesto(props.lineasManoObra, props.lineas)
                props.setPayLoadLocal(asignarImportes(props,resultTotales))
                store.dispatch(DrawerFichaArticulo(false));
            }

        } else {
            //NUEVA LINEA
            let ultimaID = 0;
            if (lineasLocal.length === 0) {
                ultimaID = 1;
            } else {
                let item = lineasLocal.sort(dynamicSortDesc("ID"));
                ultimaID = item[0].ID + 1;
            }

            payLoadLocal.ID = ultimaID;
            arr.push(payLoadLocal);

            props.setLineasArticulos(arr.sort(dynamicSortAsc("ORDEN_LINEA")))
            let resultTotales = calculoTotalesPresupuesto(props.lineasManoObra, props.lineas)
            props.setPayLoadLocal(asignarImportes(props,resultTotales))
            store.dispatch(DrawerFichaArticulo(false));
        }
    }


    const guardarEstadoFormulario = (e) => {
        const CANTIDAD = e.target.name === 'CANTIDAD' ? e.target.value : payLoadLocal.CANTIDAD;
        const DTO_VALOR = e.target.name === 'DTO_VALOR' ? e.target.value : payLoadLocal.DTO_VALOR;
        //const ID = e.target.name === 'ID' ? e.target.value : payLoadLocal.ID;
        const ID_PRESUPUESTO = e.target.name === 'ID_PRESUPUESTO' ? e.target.value : payLoadLocal.ID_PRESUPUESTO;
        //const ID_TALLER = e.target.name === 'ID_TALLER' ? e.target.value : payLoadLocal.ID_TALLER;
        const IMPORTE_BASE_IMPONIBLE = e.target.name === 'IMPORTE_BASE_IMPONIBLE' ? e.target.value : payLoadLocal.IMPORTE_BASE_IMPONIBLE;
        const IMPORTE_BRUTO = e.target.name === 'IMPORTE_BRUTO' ? e.target.value : payLoadLocal.IMPORTE_BRUTO;
        const IMPORTE_DESCUENTOS = e.target.name === 'IMPORTE_DESCUENTOS' ? e.target.value : payLoadLocal.IMPORTE_DESCUENTOS;
        const IMPORTE_IMPUESTOS = e.target.name === 'IMPORTE_IMPUESTOS' ? e.target.value : payLoadLocal.IMPORTE_IMPUESTOS;
        const IMPORTE_TOTAL = e.target.name === 'IMPORTE_TOTAL' ? e.target.value : payLoadLocal.IMPORTE_TOTAL;
        const IVA_VALOR = e.target.name === 'IVA_VALOR' ? e.target.value : payLoadLocal.IVA_VALOR;
        const MANO_OBRA = e.target.name === 'MANO_OBRA' ? e.target.value : payLoadLocal.MANO_OBRA;
        const MARCA_SOCIO = e.target.name === 'MARCA_SOCIO' ? e.target.value : payLoadLocal.MARCA_SOCIO;
        const MARCA_SOCIO_DESCRIPCION = e.target.name === 'MARCA_SOCIO_DESCRIPCION' ? e.target.value : payLoadLocal.MARCA_SOCIO_DESCRIPCION;
        const MARCA_TECDOC = e.target.name === 'MARCA_TECDOC' ? e.target.value : payLoadLocal.MARCA_TECDOC;
        const MARCA_TECDOC_DESCRIPCION = e.target.name === 'MARCA_TECDOC_DESCRIPCION' ? e.target.value : payLoadLocal.MARCA_TECDOC_DESCRIPCION;
        const REFERENCIA_DESCRIPCION = e.target.name === 'REFERENCIA_DESCRIPCION' ? e.target.value : payLoadLocal.REFERENCIA_DESCRIPCION;
        const REFERENCIA_SOCIO = e.target.name === 'REFERENCIA_SOCIO' ? e.target.value : payLoadLocal.REFERENCIA_SOCIO;
        const REFERENCIA_TECDOC = e.target.name === 'REFERENCIA_TECDOC' ? e.target.value : payLoadLocal.REFERENCIA_TECDOC;
        const TIPO_LINEA = e.target.name === 'TIPO_LINEA' ? e.target.value : payLoadLocal.TIPO_LINEA;
        const PRECIO = e.target.name === 'PRECIO' ? e.target.value : payLoadLocal.PRECIO;
        const ORDEN_LINEA = e.target.name === 'ORDEN_LINEA' ? e.target.value : payLoadLocal.ORDEN_LINEA;
        const ID = e.target.name === 'ID' ? e.target.value : payLoadLocal.ID;


        setPayLoadLocal(
            {
                ORDEN_LINEA: ORDEN_LINEA,
                ID_PRESUPUESTO: ID_PRESUPUESTO,
                TIPO_LINEA: TIPO_LINEA,
                REFERENCIA_SOCIO: REFERENCIA_SOCIO,
                REFERENCIA_TECDOC: REFERENCIA_TECDOC,
                MARCA_SOCIO: MARCA_SOCIO,
                MARCA_SOCIO_DESCRIPCION: MARCA_SOCIO_DESCRIPCION,
                MARCA_TECDOC: MARCA_TECDOC,
                MARCA_TECDOC_DESCRIPCION: MARCA_TECDOC_DESCRIPCION,
                REFERENCIA_DESCRIPCION: REFERENCIA_DESCRIPCION,
                MANO_OBRA: MANO_OBRA,
                PRECIO: PRECIO,
                IVA_VALOR: IVA_VALOR,
                DTO_VALOR: DTO_VALOR,
                CANTIDAD: CANTIDAD,
                IMPORTE_BRUTO: IMPORTE_BRUTO,
                IMPORTE_DESCUENTOS: IMPORTE_DESCUENTOS,
                IMPORTE_BASE_IMPONIBLE: IMPORTE_BASE_IMPONIBLE,
                IMPORTE_IMPUESTOS: IMPORTE_IMPUESTOS,
                IMPORTE_TOTAL: IMPORTE_TOTAL,
                ID: ID
            }
        )
    }


    const verificarElIva = (e) =>{
        let lineaManoObra = props.lineasManoObra;
        let lineaArticulos = props.lineas;
        let totalLinea = lineaManoObra.concat(lineaArticulos);

        let uniqueArray = totalLinea.reduce(function (a, d) {
            if (a.indexOf(d.IVA_VALOR) === -1) {
                 a.push(d.IVA_VALOR);
            }
            return a;
         }, []);

       
         if(uniqueArray.length === 3 ){
            let comprabarCoincidencia = uniqueArray.find(iva => (iva.toString()) === e.target.value)
            if(comprabarCoincidencia === undefined){
                MSGERROR(`${traducirPagina("msg_limite_iva")} ${uniqueArray.toString()}`)
                setDesabilitarBotonLinea(true)
             }else{
                setDesabilitarBotonLinea(false)
             }
        }else{
           setDesabilitarBotonLinea(false)
        }
    }



    return (
        <Drawer
            width={600}
            onClose={() => {
                store.dispatch(DrawerFichaArticulo(false));
                props.setLineaSeleccionada({})
            }}
            visible={openDrawerFichaArticulo}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '9999999999', color: '#fff' }}>

            <div className="flex-container-Grow-Tercero" >
                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                        onClick={(e) => {
                            store.dispatch(DrawerFichaArticulo(false)); 
                            props.setLineaSeleccionada({})
                        }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>

            <div className="p-2" style={{ height: (configApp.height - 70).toString() + "px", overflow: 'auto' }}>

                <div style={{ height: (configApp.height - 125).toString() + "px", }} className="ml-4 mr-4" >
                    <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                        <Col md={24}>
                            <span>{traducirPagina("Numero_orden_linea")}</span>
                        </Col>

                        <Col md={24}>
                            <Input  {...sharedProps} name="ORDEN_LINEA" value={payLoadLocal.ORDEN_LINEA} onChange={(e) => guardarEstadoFormulario(e)} onFocus={(e) => { e.target.select(); }} />
                        </Col>

                        <Col md={12}>
                            <span>{traducirPagina("Referencia")}</span>
                        </Col>
                        <Col md={12}>
                            <span>{traducirPagina("Marca")}</span>
                        </Col>

                        <Col md={12}>
                            <Input name="REFERENCIA_TECDOC" value={payLoadLocal.REFERENCIA_TECDOC} onChange={(e) => guardarEstadoFormulario(e)} />
                        </Col>
                        <Col md={12}>
                            <Input name="MARCA_TECDOC_DESCRIPCION" value={payLoadLocal.MARCA_TECDOC_DESCRIPCION} onChange={(e) => guardarEstadoFormulario(e)} />
                        </Col>


                        <Col md={24}>
                            <span>{traducirPagina("Descripción")}</span>
                        </Col>

                        <Col md={24}>
                            <Input.TextArea allowClear rows={8} name="REFERENCIA_DESCRIPCION" value={payLoadLocal.REFERENCIA_DESCRIPCION} onChange={(e) => guardarEstadoFormulario(e)} />
                        </Col>


                        <Col md={8} >
                            <span>{traducirPagina("Cantidad")} </span>
                        </Col>

                        <Col md={8} >
                            <span>{traducirPagina("Precio")} </span>
                        </Col>

                        <Col md={8} >
                            <span>{traducirPagina("Importe_bruto")} </span>
                        </Col>

                        <Col md={8} >
                            <Input name="CANTIDAD"
                                value={payLoadLocal.CANTIDAD}
                                onChange={(e) => {
                                    calcularTodo(e.target.value, payLoadLocal.PRECIO, payLoadLocal.DTO_VALOR, payLoadLocal.IVA_VALOR);
                                    guardarEstadoFormulario(e);

                                }}
                                onBlur={(e) => {

                                }}
                                onFocus={(e) => { e.target.select(); }} />
                        </Col>

                        <Col md={8}>
                            <Input name="PRECIO" value={
                                payLoadLocal.PRECIO
                            }
                                onChange={(e) => {
                                    calcularTodo(payLoadLocal.CANTIDAD, e.target.value, payLoadLocal.DTO_VALOR, payLoadLocal.IVA_VALOR);
                                    guardarEstadoFormulario(e);
                                }}
                                onBlur={(e) => { }}
                                onFocus={(e) => { e.target.select();  }} />
                        </Col>

                        <Col md={8} >

                            <Input name="IMPORTE_BRUTO" value={convertirMoneda(payLoadLocal.IMPORTE_BRUTO, "€")} disabled={true} onChange={(e) => { guardarEstadoFormulario(e); }} />
                        </Col>


                        <Col md={8} >
                            <span>{traducirPagina("Dto_valor")}</span>
                        </Col>

                        <Col md={8}>
                            <span>{traducirPagina("Importe_descuento")} </span>
                        </Col>

                        <Col md={8}>
                            <span>{traducirPagina("Base_imponible")} </span>
                        </Col>

                        <Col md={8}>
                            <Input name="DTO_VALOR" value={
                                payLoadLocal.DTO_VALOR
                            }
                                onChange={(e) => {
                                    calcularTodo(payLoadLocal.CANTIDAD, payLoadLocal.PRECIO, e.target.value, payLoadLocal.IVA_VALOR);
                                    guardarEstadoFormulario(e);
                                }}
                                onBlur={(e) => {  }}
                                onFocus={(e) => { e.target.select();}} />


                        </Col>

                        <Col md={8} >
                            <Input name="IMPORTE_DESCUENTOS" disabled={true} value={convertirMoneda(payLoadLocal.IMPORTE_DESCUENTOS, "€")} onChange={(e) => guardarEstadoFormulario(e)} />
                        </Col>

                        <Col md={8} >
                            <Input name="IMPORTE_BASE_IMPONIBLE" disabled={true} value={convertirMoneda(payLoadLocal.IMPORTE_BASE_IMPONIBLE, "€")} onChange={(e) => guardarEstadoFormulario(e)} />
                        </Col>


                        <Col md={8} >
                            <span>{traducirPagina("IVA").toUpperCase()} </span>
                        </Col>

                        <Col md={8}>
                            <span>{traducirPagina("Importe_impuestos")}</span>
                        </Col>

                        <Col md={8}>
                            <span>{traducirPagina("Importe_total")}</span>
                        </Col>

                        <Col md={8}>
                            <Input name="IVA_VALOR" value={
                                payLoadLocal.IVA_VALOR
                            }
                                onChange={(e) => {
                                    calcularTodo(payLoadLocal.CANTIDAD, payLoadLocal.PRECIO, payLoadLocal.DTO_VALOR, e.target.value);
                                    guardarEstadoFormulario(e);
                                }}
                                onBlur={(e) => { ;verificarElIva(e) }}
                                onFocus={(e) => { e.target.select();  }} />


                        </Col>

                        <Col md={8}>
                            <Input name="IMPORTE_IMPUESTOS" disabled={true} value={convertirMoneda(payLoadLocal.IMPORTE_IMPUESTOS, "€")} onChange={(e) => guardarEstadoFormulario(e)} />
                        </Col>

                        <Col md={8}>
                            <Input name="IMPORTE_TOTAL" disabled={true} value={convertirMoneda(payLoadLocal.IMPORTE_TOTAL, "€")} onChange={(e) => guardarEstadoFormulario(e)} />
                        </Col>

                    </Row>
                </div>

                <div style={{ flexGrow: '6', textAlign: 'right' }} className="ml-4 mr-4" >
                    <button disabled={desabilitarBotonLinea} className="activebtnMenu2" onClick={() => {
                        gestionarLineaArticulo()
                    }} style={{ height: '40px', width: '220px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', cursor: 'pointer' }} ><span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Aceptar").toUpperCase()} </span>  </button>
                </div>
            </div>
        </Drawer>
    )
}


FichaArticulo.propTypes = {
    title: PropTypes.string,
    lineas: PropTypes.array,
    lineasManoObra: PropTypes.array,
    setLineasArticulos: PropTypes.func,
    lineaSeleccionada: PropTypes.object,
    setLineaSeleccionada: PropTypes.func,
   
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        openDrawerFichaArticulo: state.estadoDrawer.openDrawerFichaArticulo,
        clienteTallerSeleccionado: state.clienteTallerSeleccionado,
        VehiculoTallerSeleccionado: state.VehiculoTallerSeleccionado,
        datosFacturacion: state.datosFacturacion
    };
}

export default connect(mapStateToProps)(FichaArticulo);