import React from "react";
import {
    traducirPagina,
    MODIFICAR_TIPO_BUSQUEDA
} from "../librerias/funciones"
import { CloseOutlined, GlobalOutlined, SearchOutlined } from "@ant-design/icons";

export const CPBuscarMoto = (props) => {
    const [initFilter, setInitFilter] = React.useState({
        TXT_FILTRO: ""
    })

    React.useEffect(() => {
    }, [])


    return <div>
        <div className="grid-container bordeContainer" style={{ backgroundColor: '#eee', padding: '0px', height: '40px', marginTop: '2px' }}>
            <div className="grid-item" style={{ borderRadius: '3px' }}>
                <input className="form-control eliminarFoco" type="text" aria-label="Search" placeholder={traducirPagina("txt_buscador_moto")} style={{ backgroundColor: "transparent", border: '0px', height: '100%', color: '#000', width: '170px' }} autoComplete="off"
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            props.EVENTO_TXT_FILTRO(initFilter)
                        }
                    }}
                    onChange={(e) => {
                        initFilter.TXT_FILTRO = e.target.value;
                        setInitFilter({ ...initFilter })
                    }}
                    value={initFilter.TXT_FILTRO} />
            </div>

            {initFilter.TXT_FILTRO.trim() !== "" &&
                <>
                    <div className="grid-item" >
                        <button className="btn_buscador_tarifa" style={{ visibility: initFilter.TXT_FILTRO.trim() === "" ? 'hidden' : 'visible' }} onClick={(e) => {
                            props.LIMPIAR_PANTALLA_MOTO()


                            initFilter.TXT_FILTRO = "";
                            setInitFilter({ ...initFilter })
                        }}> <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </button>
                    </div>

                    <div style={{ visibility: initFilter.TXT_FILTRO.trim() === "" ? 'hidden' : 'visible' }} className="grid-item barraLateral" ></div>
                </>
            }
            <div className="grid-item" onClick={(e) => {
                props.EVENTO_TXT_FILTRO(initFilter)
            }}>
                <button className="btn_buscador_tarifa">  <SearchOutlined style={{ fontSize: '20px' }} />  </button>
            </div>

            <div className="grid-item barraLateral" ></div>


            <div className="grid-item" style={{ borderRadius: '0px 3px 3px 0px', backgroundColor: '#4285f4' }} onClick={(e) => {
                props.EVENTO_BTN_GENERICO(initFilter)
                MODIFICAR_TIPO_BUSQUEDA("MT");

            }}>
                <button className="btn_buscador_tarifa" style={{ backgroundColor: '#4285f4', color: '#fff', display: 'flex', gap: '10px' }}>
                    <img src="./imagenes/motocicletav2.svg" style={{ height: '26px',marginTop:'5px' }} ></img>
                </button>
            </div>

        </div>
    </div>;
};