import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DrawerFichaImpresionDocumento } from "../action/actionCreators"


import {  ObtenerPresupuestoHTMLEnAPI, ImprimirFacturaEnAPI } from "../action/actionCreatorsRequests"
import { MSGOK, convertirMoneda, calculoLineaFacturacion, dynamicSortDesc, dynamicSortAsc, calculoTotalesPresupuesto, asignarImportes, MSGERROR, descargarPdfPasandoBase64 } from '../librerias/funciones'
import { MDBBtn, MDBCol } from 'mdbreact';
import {
    SaveOutlined,
    MailOutlined,
    PrinterOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
    ArrowDownOutlined

} from '@ant-design/icons';

import { Card, Input, Row, Col, Drawer, Tabs } from 'antd';
import store from "../store";
const { TabPane } = Tabs;

const FichaImpresionGenerico = ({ props, configApp }) => {
    const [hayDocumento, setHayDocumento] = React.useState(false);
    const [escalaPorDefecto, setEscalaPorDefecto] = React.useState(0.72);


    if (!hayDocumento && props.ABRIR_FICHA) {
        setHayDocumento(true);

    } else {
        if (!props.ABRIR_FICHA && hayDocumento) {
            //setDocumento("");
            setEscalaPorDefecto(0.72)
            setHayDocumento(false);
          

        }
    }


    const printDiv = async() => {
        if(props.DOCUMENTO_SELECCIONADO !== undefined && props.DOCUMENTO_SELECCIONADO !== null && Object.keys(props.DOCUMENTO_SELECCIONADO).length > 0){
            const ID = props.DOCUMENTO_SELECCIONADO.ID;
            const data =  await ImprimirFacturaEnAPI(ID);
            if(data.OK){
                let data = document.getElementsByClassName("ContenedorDocumento");

                if (data.length > 0) {
                    let myWindow = window.open('', 'my div', 'height=600,width=600');
                    myWindow.document.write(data[0].innerHTML);
                    myWindow.document.close();
        
                    myWindow.onload = function () {
                        myWindow.focus();
                        myWindow.print();
                        myWindow.close();
                    };
                }
            }

           //props.IMPRESORA(data);
        }else{
            let data = document.getElementsByClassName("ContenedorDocumento");

            if (data.length > 0) {
                let myWindow = window.open('', 'my div', 'height=600,width=600');
                myWindow.document.write(data[0].innerHTML);
                myWindow.document.close();
    
                myWindow.onload = function () {
                    myWindow.focus();
                    myWindow.print();
                    myWindow.close();
                };
            }
        }
    }



    return (
        <Drawer
            width={600}
            closable={false}
            onClose={() => {
                props.CERRAR_FICHA(false)
                props.MODIFICAR_DOCUMENTO("")

                if( typeof props.IMPRESORA === 'function'){
                    props.IMPRESORA();
                }
              
            }}
            visible={props.ABRIR_FICHA}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '999999999999', color: '#fff' }}>

            <div className="flex-container-Grow-Tercero" >
                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                        onClick={(e) => { props.CERRAR_FICHA(false);  
                        props.MODIFICAR_DOCUMENTO("")

                        if( typeof props.IMPRESORA === 'function'){
                            props.IMPRESORA();
                        }


                         }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>

            <div className="ant-drawer-header" style={{ backgroundColor: '#676767', color: '#021323', borderBottom: '0px', borderRadius: '0px', padding: '0px 0px' }}>
                <div className="flex-container">
                    <div style={{ flexGrow: '1', textAlign: 'end', color: '#fff', verticalAlign: 'middle', fontSize: '16px' }}>
                        <button style={{ height: '40px', width: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top', color: '#fff', backgroundColor: '#676767', marginBottom: '5px', marginRight: '5px' }} onClick={(e) => {
                            let operacion = escalaPorDefecto + 0.01
                            setEscalaPorDefecto(operacion)
                        }}>
                            <ZoomInOutlined style={{ fontSize: '22px' }} />

                        </button>

                        <button style={{ height: '40px', width: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top', color: '#fff', backgroundColor: '#676767' }} onClick={(e) => {
                            let operacion = escalaPorDefecto - 0.01
                            setEscalaPorDefecto(operacion)
                        }}>
                            <ZoomOutOutlined style={{ fontSize: '22px' }} />

                        </button>
                        <button style={{ height: '40px', width: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top', color: '#fff', backgroundColor: '#676767' }} onClick={(e) => {
                            props.DESCARGAR_DOCUMENTO();

                         

                        }}>
                            <ArrowDownOutlined style={{ fontSize: '20px' }} />
                        </button>

                        <button style={{ height: '40px', width: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top', color: '#fff', backgroundColor: '#676767', marginRight: '5px' }} onClick={(e) => {
                            printDiv("ContainerDocumento");


                        }}>
                            <PrinterOutlined style={{ fontSize: '22px' }} />
                        </button>

                    </div>

                </div>
            </div>

            <div className="p-2" style={{ height: (configApp.height - 109).toString() + "px", overflow: 'auto', backgroundColor: '#525659' }}   >
                {props.DOCUMENTO !== "" ?
                    <div className="ml-2 mr-2 mt-2" id="ContainerDocumento">
                        <div style={{ width: '780px', transform: 'scale(' + escalaPorDefecto + ')', transformOrigin: 'left top 0px', height: '100%' }} dangerouslySetInnerHTML={{ __html: props.DOCUMENTO }} />
                    </div>
                    :
                    null
                }
            </div>
        </Drawer>
    )
}


FichaImpresionGenerico.propTypes = {
    title: PropTypes.string,
    DOCUMENTO: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    DESCARGAR_DOCUMENTO: PropTypes.func,
    MODIFICAR_DOCUMENTO: PropTypes.func,

    DOCUMENTO_SELECCIONADO: PropTypes.object,
    IMPRESORA: PropTypes.func
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        openDrawerFichaImpresionDocumento: state.estadoDrawer.openDrawerFichaImpresionDocumento,
        clienteTallerSeleccionado: state.clienteTallerSeleccionado,
        VehiculoTallerSeleccionado: state.VehiculoTallerSeleccionado,
        datosFacturacion: state.datosFacturacion
    };
}

export default connect(mapStateToProps)(FichaImpresionGenerico);