import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createRipple, onChildrenDrawerCloseIntegraciones, DrawerFichaPublicidad } from "../action/actionCreators"
import { EnviarArchivoEnAPI, ObtenerArchivoEnAPI, EnviarMensajeEnAPI } from "../action/actionCreatorsRequests"
import { traducirPagina, referenciaActualSelect, convertirMoneda, redondeo, MSGOK, obtenerDatosUsuarioYLicenciaActual, MSGERROR, efectoRemoveBuscadorReferencia, descargarFicheroPasandoBase64, generateUUID, verificarExtension } from '../librerias/funciones'
import { MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBInput } from 'mdbreact';
import {
    UserOutlined,
    ShopOutlined,
    SendOutlined,
    PaperClipOutlined,
    FileUnknownOutlined,
    FolderOutlined,
    VideoCameraOutlined,
    SoundOutlined,
    FileImageOutlined,
    CloudDownloadOutlined,
    UploadOutlined,
    DeleteOutlined,
    SaveOutlined,
    ArrowDownOutlined,
    EyeOutlined,
    PlusOutlined
} from '@ant-design/icons';


import { Select, Drawer, Modal, Button, Input, Row, Col, Upload } from 'antd';
const { Option } = Select;
const FichaConversacionTelegram = ({ props, configApp }) => {
    const [hayRegistros, setHayRegistros] = React.useState(false);
    const [mensajeAEnviar, setMensajeAEnviar] = React.useState("");
    const [visible, setVisible] = React.useState(false);
    const [imagenPrevia, setImagenPrevia] = React.useState({ original: "", modificado: "", tipoLogotipo: "", nombre: "" });
    const [fileList, setFileList] = React.useState([]);
    const [mensajeFichero, setMensajeFichero] = React.useState("");
    const [archivosLocal, setArchivosLocal] = React.useState([]);



    const [nuevosMensajes, setNuevosMensajes] = React.useState({
        NUMERO_MENSAJES: 0,
        ES_NUEVO_MENSAJE: false
    });


    const gestionarFicheroLocal = (input) => {
        const url = input.value;
        const ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();


        if (input.files && input.files[0]) {

            const reader = new FileReader();
            reader.onload = (e) => {
                const registro =
                {
                    ID: generateUUID(),
                    NOMBRE: input.files[0].name,
                    STATUS: 'pending',
                    EXTENSION: ext,
                    DATOS: e.target.result,
                    TYPE: input.files[0].type,
                    PROPIEDADES_FICHERO: verificarExtension(ext)
                }

                setArchivosLocal([...archivosLocal, registro])
                input.value = '';
            }
            reader.readAsDataURL(input.files[0]);

        } else {
            MSGERROR(traducirPagina("archivo_no_permitido"))
            input.value = '';
        }
    }


    const eliminarFichero = (fichero) => {
        let registros = archivosLocal.filter((registro) => registro.ID !== fichero.ID);

        setArchivosLocal([...registros])
    }



    const scrollEnConversacion = (ID_DIV, SEGUNDOS) => {
        setTimeout(() => {
            if (document.getElementById(ID_DIV) !== null) {
                let height = document.getElementById(ID_DIV).scrollHeight;
                document.getElementById(ID_DIV).scrollTop += height;
            }
        }, SEGUNDOS)
    }

    const estaElScrollAlFinalDeConversacion = (ID_DIV) => {
        let esFinalConversacion = false;
        if (document.getElementById(ID_DIV) !== null) {
            let element = document.getElementById(ID_DIV);
            if (element.offsetHeight + element.scrollTop >= element.scrollHeight) {
                esFinalConversacion = true;
            }
        }
        return esFinalConversacion;
    }


    React.useEffect(() => {
        const interval = setInterval(async () => {
            const registro = await props.BUSCAR_CONVERSACIÓN(props.DATOS_CHAT.ID, null);
            const estadoScroll = estaElScrollAlFinalDeConversacion("containerConversacion");

            if (registro !== undefined &&  registro !== null &&  registro.REGISTROS !== undefined && registro.REGISTROS !== null && registro.REGISTROS.length > 0) {
                if (nuevosMensajes.NUMERO_MENSAJES === 0) {
                    const mensajes = registro.REGISTROS.filter((registro) => registro.TIPO_MENSAJE === "CLIENTE")
                    nuevosMensajes.NUMERO_MENSAJES = mensajes.length;
                    nuevosMensajes.ES_NUEVO_MENSAJE = false;
                    setNuevosMensajes({ ...nuevosMensajes })
                } else {
                    const mensajes = registro.REGISTROS.filter((registro) => registro.TIPO_MENSAJE === "CLIENTE")

                    if (mensajes.length > nuevosMensajes.NUMERO_MENSAJES) {
                        nuevosMensajes.NUMERO_MENSAJES = mensajes.length;
                        nuevosMensajes.ES_NUEVO_MENSAJE = estadoScroll ? false : true;
                        setNuevosMensajes({ ...nuevosMensajes })

                        //scrollEnConversacion("containerConversacion", 600)
                    }
                }
            }

            if (estadoScroll && nuevosMensajes.ES_NUEVO_MENSAJE) {
                nuevosMensajes.ES_NUEVO_MENSAJE = false;
                setNuevosMensajes(nuevosMensajes);
            }

        }, 2000);
        return () => clearInterval(interval);
    }, []);


    const gestionarFicheros = async (fichero) => {
        try {

            if (fichero.TIPO_ARCHIVO === "I" || fichero.EXTENSION_ARCHIVO === "pdf") {
                props.CERRAR_VISOR(true);
                props.FICHERO_SELECCIONADO({ TITULO: fichero.NOMBRE_ARCHIVO, TIPO_DOCUMENTO: fichero.TIPO_ARCHIVO === "I" ? "IMG" : "DOC", URL: "", FICHERO: fichero })
            }
            
            //let registro = await 
            ObtenerArchivoEnAPI(fichero.ID_ARCHIVO, fichero.NOMBRE_ARCHIVO, fichero.TIPO_ARCHIVO, fichero.EXTENSION_ARCHIVO).then((registro) => {
                const { OK, ARCHIVO, EXTENSION_ARCHIVO, NOMBRE_ARCHIVO } = registro;
                if (OK) {
                    if (fichero.TIPO_ARCHIVO === "I" || fichero.EXTENSION_ARCHIVO === "pdf") {
                        const FORMATO = fichero.TIPO_ARCHIVO === "I" ? `image/${EXTENSION_ARCHIVO}` : `application/${EXTENSION_ARCHIVO}`
    
                        const CONSTRUIR_URL = `data:${FORMATO};base64,${ARCHIVO}`;
                        props.FICHERO_SELECCIONADO({ TITULO: NOMBRE_ARCHIVO, TIPO_DOCUMENTO: fichero.TIPO_ARCHIVO === "I" ? "IMG" : "DOC", URL: CONSTRUIR_URL, FICHERO: fichero })
                        props.CERRAR_VISOR(true);
    
                    } else {
                        descargarFicheroPasandoBase64(ARCHIVO, NOMBRE_ARCHIVO, EXTENSION_ARCHIVO)
                    }
                }
            })
            
        } catch (error) {
            MSGERROR(error)
        }
    }

    const componente = {
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList)

            if (newFileList.length === 0) {
                setImagenPrevia({ original: "", modificado: "", tipoLogotipo: "" })
            }
        },
        beforeUpload: file => {
            //console.log("tipo fichero", file.type)

            if (file.size >= 500000) {
                MSGERROR(`${traducirPagina("tamanio_maximo_permitido")} (500KB)`)
            } else {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    let texto = "data:" + file.type + ";base64,"
                    let cadena = reader.result.replace(texto, "")

                    setImagenPrevia({ original: reader.result, modificado: cadena, tipoLogotipo: file.type, nombre: file.name })
                };
                reader.onerror = function (error) {
                    MSGERROR(`${traducirPagina("problema_carga_imagen")}: ${error}`)
                };

                let fichero = fileList
                fichero.push(file)
                setFileList(fichero)

            }
            return file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.size >= 500000 ? false : Upload.LIST_IGNORE;
        }
    };


    const enviarMensaje = async (ID_CONVERSACION, ID_USUARIO_TELEGRAM_TO, MENSAJE) => {
        setMensajeAEnviar("")

        const { OK } = await EnviarMensajeEnAPI(ID_CONVERSACION, ID_USUARIO_TELEGRAM_TO, MENSAJE)
        if (OK) {
            await props.BUSCAR_CONVERSACIÓN(ID_CONVERSACION, null);
            scrollEnConversacion("containerConversacion", 1000)

        }

    }

    const EnviarArchivoTelegram = (ID_CONVERSACION, ID_USUARIO_TELEGRAM_TO, MENSAJE) => {
        if (archivosLocal.length > 0) {
            const { NOMBRE, EXTENSION, DATOS, TYPE, PROPIEDADES_FICHERO } = archivosLocal[0];
            const { ID_TIPO } = PROPIEDADES_FICHERO;
            let TIPO_ARCHIVO = ID_TIPO === 1 ? "I" : "A";
            let ES_IMAGEN_ARCHIVO = ID_TIPO === 1 ? true : false;
            let ELIMINAR = `data:${TYPE};base64,`;
            let ARCHIVO = DATOS.replace(ELIMINAR, "");

            EnviarArchivoEnAPI(ID_CONVERSACION, ID_USUARIO_TELEGRAM_TO, MENSAJE, NOMBRE, TIPO_ARCHIVO, EXTENSION, ES_IMAGEN_ARCHIVO, ARCHIVO).then(registro => {
                if (registro.OK) {
                    setMensajeFichero("")
                    setVisible(false);
                    scrollEnConversacion("containerConversacion", 1000);
                }
            })
        } else {
            MSGERROR(`${traducirPagina("adjuntar_fichero")}.`)
        }
    }

    const propiedadesImagen = async (registro) => {
        let DIMENSIONES = { HEIGHT_IMG: 0, WIDTH_IMG: 0 }
        if (registro.MINI_IMAGEN_ARCHIVO !== undefined && registro.MINI_IMAGEN_ARCHIVO !== null) {
            let img = new Image();
            img.src = `data:image${registro.EXTENSION_ARCHIVO}};base64,${registro.MINI_IMAGEN_ARCHIVO}`;
            await img.decode();
            DIMENSIONES.HEIGHT_IMG = img.height;
            DIMENSIONES.WIDTH_IMG = img.width;
        }
        return DIMENSIONES;
    }


    if (props.CONVERSACION !== null && props.CONVERSACION.length > 0 && !hayRegistros && props.ABRIR_FICHA) {
        setHayRegistros(true);

        scrollEnConversacion("containerConversacion", 1000)

    } else {
        if (!props.ABRIR_FICHA && hayRegistros) {
            setHayRegistros(false);
        }
    }

    return (
        <div>
            <Drawer
                width={"600px"}
                closable={false}
                onClose={() => {
                    if (props.TIPO === "MASTER") {
                        props.CERRAR.setState({ ABRIR_FICHA_TELEGRAM: false })
                    } else {
                        props.CERRAR_FICHA(false)
                        props.MODIFICAR_CONVERSACION([])
                    }

                }}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>

                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <button className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                            onClick={(e) => {

                                if (props.TIPO === "MASTER") {
                                    props.CERRAR.setState({ ABRIR_FICHA_TELEGRAM: false })
                                } else {
                                    props.CERRAR_FICHA(false)
                                    props.MODIFICAR_CONVERSACION([])
                                }
                            }}>
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </button>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.DATOS_CHAT.CONTACTO.split("|")[0]}
                    </div>
                </div>

                <div style={{ height: (configApp.height - 111).toString() + "px", overflowX: 'hidden', overflowY: 'auto', backgroundColor: '#3a3939', padding: '10px' }}>

                    <div id="containerConversacion" style={{ height: (configApp.height - 131).toString() + "px", overflowX: 'hidden', overflowY: 'auto', paddingBottom: '20px' }}>
                        {props.CONVERSACION === null ?
                            null
                            :
                            props.CONVERSACION.map((registro) =>
                                registro.TIPO_MENSAJE === "CLIENTE" ?
                                    <div style={{ backgroundColor: '#646464', minHeight: '50px', padding: '20px', margin: '10px 10px 10px 10px', cursor: 'pointer', width: '70%', color: '#fff', float: 'left', borderRadius: '7px', fontSize: '20px' }} >
                                        {registro.EXTENSION_ARCHIVO !== "" ?
                                            <div>
                                                <UserOutlined style={{ fontSize: '24px', color: '#f7f7f7', float: 'left', paddingRight: '10px', display: registro.MENSAJE === "" ? 'none' : '' }} /> {registro.MENSAJE}

                                                <div style={{ minHeight: '80px', padding: '10px', marginBottom: '10px', backgroundColor: '#5c5b5b', marginTop: '10px', position: 'relative' }} onClick={() => { gestionarFicheros(registro) }}>
                                                    {registro.MINI_IMAGEN_ARCHIVO !== null ?

                                                        <div className='previaImagen' title={registro.NOMBRE_ARCHIVO}>
                                                            <img src={`data:image${registro.EXTENSION_ARCHIVO}};base64,${registro.MINI_IMAGEN_ARCHIVO}`} alt={registro.NOMBRE_ARCHIVO}></img>
                                                        </div>

                                                        :
                                                        <>
                                                            <div style={{ float: 'left' }}>

                                                                {
                                                                    registro.TIPO_ARCHIVO === "I" ?
                                                                        <FileImageOutlined style={{ fontSize: '50px', color: '#f7f7ff7', float: 'left', paddingRight: '10px' }} />
                                                                        :
                                                                        registro.TIPO_ARCHIVO === "V" ?
                                                                            <SoundOutlined style={{ fontSize: '50px', color: '#f7f7ff7', float: 'left', paddingRight: '10px' }} />
                                                                            :
                                                                            registro.TIPO_ARCHIVO === "X" ?
                                                                                <VideoCameraOutlined style={{ fontSize: '50px', color: '#f7f7ff7', float: 'left', paddingRight: '10px' }} />
                                                                                :
                                                                                registro.TIPO_ARCHIVO === "A" ?
                                                                                    <FolderOutlined style={{ fontSize: '50px', color: '#f7f7ff7', float: 'left', paddingRight: '10px' }} />
                                                                                    :
                                                                                    <FileUnknownOutlined style={{ fontSize: '50px', color: '#f7f7ff7', float: 'left', paddingRight: '10px' }} />
                                                                }
                                                            </div>

                                                            <div style={{ color: '#f7f7ff7', fontSize: '16px' }}>
                                                                {registro.NOMBRE_ARCHIVO}
                                                            </div>
                                                        </>
                                                    }

                                                    {/*
                                                    <div style={{  color: '#f7f7ff7' }} onClick={() => { ObtenerArchivo(registro.ID_ARCHIVO, registro.NOMBRE_ARCHIVO, registro.TIPO_ARCHIVO, registro.EXTENSION_ARCHIVO) }}>
                                                        {registro.NOMBRE_ARCHIVO} <br /> {<div style={{ color: '#33b5e5', fontSize: '14px' }}> <CloudDownloadOutlined style={{ float: 'left', paddingRight: '10px', fontSize: '22px' }} /> Descargar archivo </div>}
                                                    </div>
                                                */}

                                                    <div className="image-mask_telegram">
                                                        <div className="image-mask-info_telegram">
                                                            {
                                                                registro.TIPO_ARCHIVO === "I" || registro.EXTENSION_ARCHIVO === "pdf" ?
                                                                    <EyeOutlined title='Visualizar' style={{ fontSize: '30px', display: 'inline-block', marginRight: '20px' }}></EyeOutlined>
                                                                    :
                                                                    <CloudDownloadOutlined title='Descargar' style={{ fontSize: '30px', display: 'inline-block' }}></CloudDownloadOutlined>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            :
                                            <div> <UserOutlined style={{ fontSize: '24px', color: '#f7f7f7', float: 'left', paddingRight: '10px' }} /> {registro.MENSAJE} </div>

                                        }
                                        <div style={{ textAlign: 'right', fontSize: '16px', color: '#f7f7f7' }}> {registro.FECHA_ALTA}</div>
                                    </div>
                                    :
                                    <div style={{ backgroundColor: '#4e4e4e', minHeight: '50px', padding: '20px', margin: '10px 10px 10px 10px', cursor: 'pointer', width: '70%', float: 'right', color: '#c7c6c6', borderRadius: '7px', fontSize: '20px' }}>
                                        {registro.EXTENSION_ARCHIVO !== "" ?
                                            <div>
                                                <ShopOutlined style={{ fontSize: '24px', color: '#33b5e5', float: 'left', paddingRight: '10px', display: registro.MENSAJE === "" ? 'none' : '' }} /> {registro.MENSAJE}

                                                <div style={{ minHeight: '80px', padding: '10px', marginBottom: '10px', backgroundColor: '#5c5b5b', marginTop: '10px', position: 'relative' }} onClick={() => { gestionarFicheros(registro) }}>
                                                    {registro.MINI_IMAGEN_ARCHIVO !== null ?
                                                        <div className='previaImagen' title={registro.NOMBRE_ARCHIVO}>
                                                            <img src={`data:image${registro.EXTENSION_ARCHIVO}};base64,${registro.MINI_IMAGEN_ARCHIVO}`} alt={registro.NOMBRE_ARCHIVO}></img>
                                                        </div>
                                                        :
                                                        <>
                                                            <div style={{ float: 'left' }} >
                                                                {registro.TIPO_ARCHIVO === "I" ?
                                                                    <FileImageOutlined style={{ fontSize: '50px', color: '#f7f7ff7', float: 'left', paddingRight: '10px' }} />
                                                                    :
                                                                    registro.TIPO_ARCHIVO === "V" ?
                                                                        <SoundOutlined style={{ fontSize: '50px', color: '#f7f7ff7', float: 'left', paddingRight: '10px' }} />
                                                                        :
                                                                        registro.TIPO_ARCHIVO === "X" ?
                                                                            <VideoCameraOutlined style={{ fontSize: '50px', color: '#f7f7ff7', float: 'left', paddingRight: '10px' }} />
                                                                            :
                                                                            registro.TIPO_ARCHIVO === "A" ?
                                                                                <FolderOutlined style={{ fontSize: '50px', color: '#f7f7ff7', float: 'left', paddingRight: '10px' }} />
                                                                                :
                                                                                <FileUnknownOutlined style={{ fontSize: '50px', color: '#f7f7ff7', float: 'left', paddingRight: '10px' }} />
                                                                }
                                                            </div>

                                                            <div style={{ color: '#f7f7ff7', fontSize: '16px' }}>
                                                                {registro.NOMBRE_ARCHIVO}
                                                            </div>
                                                        </>
                                                    }

                                                    {/*
                                                    <div style={{ float: 'left', color: '#f7f7ff7' }} onClick={() => { ObtenerArchivo(registro.ID_ARCHIVO, registro.NOMBRE_ARCHIVO, registro.TIPO_ARCHIVO, registro.EXTENSION_ARCHIVO) }}>
                                                        {registro.NOMBRE_ARCHIVO} <br /> {<div style={{ color: '#33b5e5', fontSize: '14px' }}> <CloudDownloadOutlined style={{ float: 'left', paddingRight: '10px', fontSize: '22px' }} /> Descargar archivo </div>}
                                                    </div>
                                                */}

                                                    <div className="image-mask_telegram">
                                                        <div className="image-mask-info_telegram">
                                                            {
                                                                registro.TIPO_ARCHIVO === "I" || registro.EXTENSION_ARCHIVO === "pdf" ?
                                                                    <EyeOutlined title='Visualizar' style={{ fontSize: '30px', display: 'inline-block', marginRight: '20px' }}></EyeOutlined>
                                                                    :
                                                                    <CloudDownloadOutlined title='Descargar' style={{ fontSize: '30px', display: 'inline-block' }}></CloudDownloadOutlined>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            :
                                            <div> <ShopOutlined style={{ fontSize: '24px', color: '#f7f7f7', float: 'left', paddingRight: '10px' }} /> {registro.MENSAJE} </div>
                                        }
                                        <div style={{ textAlign: 'right', fontSize: '16px', color: '#f7f7f7' }}> {registro.FECHA_ALTA}</div>

                                    </div>
                            )
                        }


                        {
                            nuevosMensajes.ES_NUEVO_MENSAJE ?
                                <button className='btn_flotante-telegram' onClick={() => {
                                    nuevosMensajes.ES_NUEVO_MENSAJE = false;
                                    setNuevosMensajes({ ...nuevosMensajes })
                                    scrollEnConversacion("containerConversacion", 100)
                                }}>Nuevos mensajes <ArrowDownOutlined style={{ marginLeft: '5px' }}></ArrowDownOutlined></button>
                                :
                                null
                        }


                    </div>
                </div>

                <div style={{ height: "50px", backgroundColor: '#4a4949' }}>
                    <div>
                        <div style={{ float: 'left' }}>
                            <input
                                value={mensajeAEnviar}
                                type="text"
                                placeholder={"Escribe un mensaje"}
                                onChange={(e) => { setMensajeAEnviar(e.target.value.trim() !== "" ? e.target.value : "") }}
                                onKeyPress={(e) => e.key === 'Enter' ? enviarMensaje(props.DATOS_CHAT.ID, props.DATOS_CHAT.ID_TELEGRAM_CONTACTO_TO, mensajeAEnviar) : {}}
                                style={{ outline: 'none', border: '0px', color: '#fff', padding: '10px', width: '400px', backgroundColor: '#4a4949', fontSize: '20px' }}>
                            </input>
                        </div>

                        <div style={{ color: '#fff', width: '48px', float: 'right', paddingTop: '10px' }} >
                            <SendOutlined style={{ fontSize: '30px' }} onClick={() => { enviarMensaje(props.DATOS_CHAT.ID, props.DATOS_CHAT.ID_TELEGRAM_CONTACTO_TO, mensajeAEnviar) }} />
                        </div>

                        <div style={{ color: '#fff', width: '48px', float: 'right', paddingTop: '10px' }}>
                            <PaperClipOutlined style={{ fontSize: '30px' }} onClick={() => {
                                setImagenPrevia({ original: "", modificado: "", tipoLogotipo: "", nombre: "" })

                                setMensajeFichero(mensajeAEnviar.trim() !== "" ? mensajeAEnviar:"");
                                setVisible(true)
                            }} />
                        </div>
                    </div>
                </div>


                <Modal
                    title="Enviar documento"
                    centered
                    visible={visible}
                    onCancel={() => setVisible(false)}
                    afterClose={() =>{setArchivosLocal([]);  }}
                    width={"40%"}
                    footer={[
                        <Button key="back" onClick={() => { setVisible(false) }}>
                            Salir
                        </Button>,
                        <Button className="btnColor" key="submit" type="primary" onClick={() => {
/*
                            let soloExtension = imagenPrevia.tipoLogotipo.split("/")[1]
                            let tipoArchivo = "";
                            let esImagen = false;

                            if (imagenPrevia.tipoLogotipo.split("/")[0] === "image") {
                                tipoArchivo = "I"
                                esImagen = true;
                            } else {
                                tipoArchivo = "A"
                            }
                            */

                            EnviarArchivoTelegram(props.DATOS_CHAT.ID, props.DATOS_CHAT.ID_TELEGRAM_CONTACTO_TO, mensajeFichero)
                            setMensajeAEnviar("")
                        }}> Enviar</Button>
                    ]}
                >
                    <Row>
                        <Col>
                            <div className="mb-2">
                                <div className="containerFicheros" style={{ margin: '0px 0px 0px 1px' }}>
                                    {archivosLocal !== undefined ?
                                        archivosLocal.map((fichero, i) =>
                                            <div key={i} className="containerFicheros_card" title={fichero.NOMBRE} >
                                                <div className='containerFicheros_card_body' style={{ height: '140px', width: '140px' }}>
                                                    <img src={`./imagenes/iconos/${fichero.PROPIEDADES_FICHERO.TIPO}`} alt={fichero.NOMBRE} />

                                                    <div className="image-mask_telegram">
                                                        <div className="image-mask-info_telegram" onClick={() => { eliminarFichero(fichero) }}>
                                                            <label><DeleteOutlined style={{ fontSize: '14px', display: 'block' }}></DeleteOutlined> Eliminar</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) :
                                        null
                                    }

                                    {
                                        archivosLocal !== undefined && archivosLocal.length < 1 ?
                                            <>
                                                <label id="getFileLabel" htmlFor="getFile" style={{ fontSize: '18px' }}><PlusOutlined ></PlusOutlined> Adjuntar</label>
                                                <input type="file" id="getFile" onChange={(e) => { gestionarFicheroLocal(e.target) }} />
                                            </>
                                            :
                                            null
                                    }
                                </div>

{/*
                                <Row style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                                    <Col md={24}>

                                        <Upload {...componente}
                                            maxCount={1}
                                        >
                                            <Button style={{ display: imagenPrevia.tipoLogotipo !== "" ? 'none' : '' }} ><UploadOutlined style={{ fontSize: '20px' }} /> Seleccione la imagen</Button>
                                        </Upload>


                                    </Col>

                                    <Col md={12}>
                                        <Button style={{ display: imagenPrevia.tipoLogotipo === "" ? 'none' : '' }} onClick={(e) => setImagenPrevia({ original: "", modificado: "", tipoLogotipo: "" })}> <DeleteOutlined style={{ fontSize: '20px' }} /> Limpiar logotipo</Button>
                                    </Col>
                                    <Col md={24}>
                                        <br />
                                        <br />
                                        <img src={"data:" + imagenPrevia.tipoLogotipo + ";base64," + imagenPrevia.modificado} style={{ width: '400px', display: imagenPrevia.modificado === "" ? 'none' : "" }}></img>
                                    </Col>
                                </Row>
                                */}

                            </div>

                        </Col>
                        <Col md={24} className="mt-2">
                            <span>{"Mensaje"} </span>
                        </Col>
                        <Col md={24}>
                            <Input.TextArea value={mensajeFichero} onChange={(e) => { setMensajeFichero(e.target.value) }} allowClear rows={5} />
                        </Col>
                    </Row>
                </Modal>

            </Drawer>
        </div>
    )
}

FichaConversacionTelegram.propTypes = {
    title: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    CONVERSACION: PropTypes.array,
    MODIFICAR_CONVERSACION: PropTypes.func,
    BUSCAR_CONVERSACIÓN: PropTypes.func,
    DATOS_CHAT: PropTypes.object,
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp
    };
}

export default connect(mapStateToProps)(FichaConversacionTelegram);