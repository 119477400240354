import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from 'mdbreact';
import { ObtenerMarcasdeUnVehiculoEnAPI } from "../action/actionCreatorsRequests"
import 'antd/dist/antd.css';

import { Drawer } from 'antd';
import { DrawerFichaMarcasVehiculo, createRipple, DrawerFichaReferenciaVehiculo } from "../action/actionCreators";
import { traducirPagina, idModeloLatam } from "../librerias/funciones"
import store from '../store'
import {
    CloseOutlined,
    SearchOutlined
} from '@ant-design/icons';


const FichaMarcasVehiculo = ({ props, configApp, openDrawerFichaMarcasVehiculo, codmotorizacion }) => {
    const [hayMarcasVehiculo, setHayMarcasVehiculo] = React.useState(false);
    const [obtenerMarcasVehiculo, setObtenerMarcasVehiculo] = React.useState([]);
    const [obtenerMarcasVehiculoAux, setObtenerMarcasVehiculoAux] = React.useState([]);


    if (!hayMarcasVehiculo && openDrawerFichaMarcasVehiculo) {
        setHayMarcasVehiculo(true)

        let TIPO_VEHICULO_POR_MODELO = store.getState().TIPO_VEHICULO_POR_MODELO;
        let TIPO_VEH = TIPO_VEHICULO_POR_MODELO === "" ? store.getState().BusquedaPorModelo.tipoBusqueda : TIPO_VEHICULO_POR_MODELO;

        let codmotorizacion = store.getState().codmotorizacion;

        let ID_MODELO_CATALOGO = store.getState().ID_MODELO_MOTO;
        if (ID_MODELO_CATALOGO === null) {
            ID_MODELO_CATALOGO = idModeloLatam()
        }




        ObtenerMarcasdeUnVehiculoEnAPI(codmotorizacion, TIPO_VEH, ID_MODELO_CATALOGO).then(marcas => {
            if (marcas !== null && marcas.length > 0) {
                setObtenerMarcasVehiculoAux(marcas);
                setObtenerMarcasVehiculo(modelarDatos(marcas));
            } else {
                setObtenerMarcasVehiculo(null);
            }
        })
    } else {
        if (!openDrawerFichaMarcasVehiculo && hayMarcasVehiculo) {
            setObtenerMarcasVehiculo([])
            setHayMarcasVehiculo(false)
            if (document.getElementById("buscadorMarcaVeh") !== null) {
                document.getElementById("buscadorMarcaVeh").value = "";
            }
        }
    }


    const modelarDatos = (marcas) => {
        let listaMarca = [];
        let objetoNuevoMarcas = [];
        let contAux = 0;

        for (var i = 0; i < marcas.length; i++) {
            contAux += 1;
            if (contAux <= 3) {
                listaMarca.push({ "Codigo": marcas[i].Codigo, "Marca": marcas[i].Marca, "Imagen": marcas[i].Imagen })
                if ((marcas.length - 1) === i) {
                    objetoNuevoMarcas.push({ "lista": listaMarca });
                }
            } else {
                objetoNuevoMarcas.push({ "lista": listaMarca });
                contAux = 0;
                listaMarca = []
                i = (i - 1)
            }
        }
        return objetoNuevoMarcas.length === 0 ? null : objetoNuevoMarcas;
    }



    const buscarEnTabla = (palabraABuscar) => {
        let palabra = palabraABuscar.target.value;
        if (palabra !== "") {
            if (obtenerMarcasVehiculoAux !== null) {
                let resultadoFiltro = obtenerMarcasVehiculoAux.filter((item) => {
                    return item.Marca.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.Marca.toUpperCase() === palabra.toUpperCase()
                })
                if (resultadoFiltro.length > 0) {
                    setObtenerMarcasVehiculo(modelarDatos(resultadoFiltro));
                } else {
                    setObtenerMarcasVehiculo(null);
                }

            }
        } else {
            setObtenerMarcasVehiculo(modelarDatos(obtenerMarcasVehiculoAux));
        }
    }

    const efectoBotonRemove = (idControl, idBoton) => {
        let controlBuscador = idControl === "" ? "" : document.getElementById(idControl).value;
        let activarEfecto = controlBuscador.trim() === "" ? false : true;

        if (activarEfecto) {
            document.getElementById(idBoton).style.visibility = "visible";
        } else {
            document.getElementById("buscadorMarcaVeh").value = "";
            setObtenerMarcasVehiculo(modelarDatos(obtenerMarcasVehiculoAux));

            document.getElementById(idBoton).style.visibility = "hidden";
        }
    }

    return (
        <Drawer
            width={600}
            onClose={() => { store.dispatch(DrawerFichaMarcasVehiculo(false)); }}
            visible={openDrawerFichaMarcasVehiculo}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '999', color: '#fff' }}>

            <div className="flex-container-Grow-Tercero" >
                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { store.dispatch(DrawerFichaMarcasVehiculo(false)); createRipple(e) }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>

            <div className="pt-3 pl-3 pr-3 mdb-skin" >
                <div style={{ flexGrow: '8', textAlign: 'end', display: 'flex', justifyContent: 'flex-end' }} className="d-none d-md-inline">
                    <div className="grid-container mb-3" style={{ backgroundColor: '#eee', marginTop: '2px' }}>
                        <div className="grid-item">
                            <input id="buscadorMarcaVeh" className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_por_marcas")} style={{ backgroundColor: "transparent", width: '490px', border: '0px', height: '100%', color: '#000' }} autoComplete="off" onChange={(e) => { buscarEnTabla(e); efectoBotonRemove("buscadorMarcaVeh", "closeBuscadorMarcaVeh") }} />
                        </div>

                        <div className="grid-item" >
                            <MDBBtn id="closeBuscadorMarcaVeh" onClick={e => { createRipple(e); efectoBotonRemove("", "closeBuscadorMarcaVeh") }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: 'hidden' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                        </div>

                        <div className="grid-item barraLateral" ></div>

                        <div className="grid-item">
                            <MDBBtn onClick={e => { createRipple(e); }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-3" style={{ height: (configApp.height - 150).toString() + "px", overflow: 'auto' }}>
                {
                    obtenerMarcasVehiculo === null ?
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>Actualmente no hay marcas</h3> </div>
                        :
                        obtenerMarcasVehiculo.length > 0 ?

                            obtenerMarcasVehiculo.map((item, i) =>
                                <div key={i} id={i} style={{ color: '#000', fontSize: '16px', fontWeight: '500', marginBottom: '10px' }}>
                                    {item.lista.map((row, i) =>
                                        <div style={{ float: 'left', padding: '5px', width: '33%', cursor: 'pointer' }} className="efectoContainerVehiculo" onClick={() => {
                                            store.dispatch(DrawerFichaReferenciaVehiculo(true));
                                            //store.dispatch({ type: "STATE_REFERENCIA_MARCA_VEHICULO_SELECCIONADO", ReferenciaMarcaVehiculoSeleccionado: row.Codigo }) 
                                            store.dispatch({ type: "STATE_REFERENCIA_MARCA_VEHICULO_SELECCIONADO", ReferenciaMarcaVehiculoSeleccionado: { ID_MARCA: row.Codigo, NOMBRE_MARCA: row.Marca } })

                                        }} >
                                            {row.Marca}
                                        </div>
                                    )}
                                </div>

                            )

                            :
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#000' }}> <div color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>


                }
            </div>
        </Drawer>
    )
}

FichaMarcasVehiculo.propTypes = {
    onClick: PropTypes.func,
    config: PropTypes.object,
    detallesReferencia: PropTypes.object,
    masInformacion: PropTypes.object,
    fichaAbierta: PropTypes.bool,
    marca: PropTypes.number,
    referenciaProveedor: PropTypes.string,
    activeItemOuterTabs: PropTypes.string
};

const mapStateToProps = (state, props) => {
    return {
        codmotorizacion: state.codmotorizacion,
        configApp: state.configApp,
        props: props,
        openDrawerFichaMarcasVehiculo: state.estadoDrawer.openDrawerFichaMarcasVehiculo

    };
}

export default connect(mapStateToProps)(FichaMarcasVehiculo);