import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MDBBtn } from 'mdbreact';
import { MDBContainer, MDBNav, MDBNavItem, MDBNavLink, MDBTabPane, MDBTabContent, MDBListGroup, MDBListGroupItem } from 'mdbreact';
import { Drawer } from 'antd';
import store from "../store";
import { getHistorialBusqueda } from "../action/actionCreatorsRequests";
import { List, Avatar } from 'antd';
import { traducirPagina, busquedaCochePorMatriculaOBastiidor, obtenerDatosUsuarioYLicenciaActual } from "../librerias/funciones"
import { CopyOutlined } from '@ant-design/icons';
const FichaHistorialVehYQ = ({ props,configApp }) => {

    const [activeItemOuterTabs, setActiveItemOuterTabs] = React.useState("3");
    const [todosLosVehiculos, setTodosLosVehiculos] = React.useState([]);
    const [todasLasReferencia, setTodasLasReferencia] = React.useState([]);
    const [todosLosBastidores, setTodosLosBastidores] = React.useState([]);
    const [todasLasMatriculas, setTodasLasMatriculas] = React.useState([]);
    const [hayRegistros, setHayRegistros] = React.useState(false);


    const toggleOuterTabs = (tab) => {
        switch (tab) {
            case "1":
                getHistorialBusqueda(4).then((data) => {
                    setTodosLosVehiculos(data === null ? [] : data)
                })
                break;
            case "2":
                getHistorialBusqueda(3).then((data) => {
                    setTodasLasReferencia(data === null ? [] : data)
                })
                break;
            case "3":
                getHistorialBusqueda(2).then((data) => {
                    setTodosLosBastidores(data === null ? [] : data)
                })
                break;
            case "4":
                getHistorialBusqueda(1).then((data) => {
                    setTodasLasMatriculas(data === null ? [] : data)
                })
                break;
        }
        if (activeItemOuterTabs !== tab) {
            setActiveItemOuterTabs(tab)
        }
    };


    if (!hayRegistros && props.ABRIR_FICHA) {
        setHayRegistros(true);

        getHistorialBusqueda(2).then((data) => {
            setTodosLosBastidores(data === null ? [] : data)
        })

    } else {
        if (!props.ABRIR_FICHA && hayRegistros) {
            setHayRegistros(false);
            setTodosLosBastidores([])
            setTodasLasMatriculas([])
           
        }
    }

/*

    let previaToken = obtenerToken();
    if (todosLosVehiculos !== undefined && previaToken !== null && props.verFichaHistorico) {

        if (todosLosVehiculos.length === 0 && buacarUnaSolaVez) {
            setBuacarUnaSolaVez(false)
            getHistorialBusqueda(4).then((data) => {
                setTodosLosVehiculos(data === null ? [] : data)
                setBuacarUnaSolaVez(false)
            })
        }
    } else {
        if (!props.verFichaHistorico && todosLosVehiculos.length > 0) {
            if (activeItemOuterTabs !== "1") {
                setActiveItemOuterTabs("1");
            }

            setTodosLosVehiculos([]);
            setBuacarUnaSolaVez(true)
        }
    }
*/

    return (
        <Drawer
            width={"600px"}
            onClose={() => {     props.CERRAR_FICHA(false) }}
            visible={props.ABRIR_FICHA}
            closable={false}

            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '9999', color: '#fff' }}>
            <div>
                <div className="flex-container-Grow-Tercero">
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {     props.CERRAR_FICHA(false)}} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>


                <MDBContainer>
                    <MDBNav tabs className="nav-justified mt-3" color="primary">
                        <MDBNavItem className="mr-1" style={{display:obtenerDatosUsuarioYLicenciaActual().PERMISO_MATRICULAS ?  '':'none'}}>
                            <MDBNavLink className="headerTabReferencia" link to="#" active={activeItemOuterTabs === "3"} onClick={() => toggleOuterTabs("3")} role="tab"> {traducirPagina("Bastidores")}</MDBNavLink>
                        </MDBNavItem>

                        <MDBNavItem style={{display:obtenerDatosUsuarioYLicenciaActual().PERMISO_MATRICULAS ? '':'none'}}>
                            <MDBNavLink className="headerTabReferencia" link to="#" active={activeItemOuterTabs === "4"} onClick={() => toggleOuterTabs("4")} role="tab"> {traducirPagina("Matriculas")}</MDBNavLink>
                        </MDBNavItem>
                       
                    </MDBNav>

                    <MDBTabContent className="card" activeItem={activeItemOuterTabs} style={{ height: (configApp.height - 130).toString() + "px", overflow: 'auto', backgroundColor: "#eee" }}>
                   
                        <MDBTabPane tabId="3" role="tabpanel">
                            <List
                                itemLayout="horizontal"
                                dataSource={todosLosBastidores}
                                renderItem={item => (
                                    <List.Item style={{ cursor: 'pointer' }}>
                                        <List.Item.Meta onClick={(e) => {
                                             props.SELECCIONADO(item)
                                           

                                        }}
                                            avatar={<Avatar src="./imagenes/enlace.svg" />}
                                            title={<div style={{ cursor: 'pointer' }}><span id="textoACopiarHistorial" style={{ color: '#000', fontSize: '16px', fontWeight: '500' }}>{item.BASTIDOR}</span>    </div>}
                                            description={<span style={{ color: '#999' }}>{item.FECHA_ALTA}</span>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </MDBTabPane>

                        <MDBTabPane tabId="4" role="tabpanel">
                            <List
                                itemLayout="horizontal"
                                dataSource={todasLasMatriculas}
                                renderItem={item => (
                                    <List.Item
                                    actions={[<a key="list-loadmore-edit">
                                    <button onClick={() => {
                                        let resultado = "";
                                        let obtenerBuscadores = store.getState().buscadorCoche;
                                        if (Object.keys(obtenerBuscadores).length > 0) {
                                            if (obtenerBuscadores.txtBuscadorCoche === undefined) {
                                                obtenerBuscadores["txtBuscadorCoche"] = item.MATRICULA
                                                obtenerBuscadores["actual"] = { key: "dropcoche", value: item.MATRICULA }
                                                resultado = obtenerBuscadores;
                                            } else {
                                                obtenerBuscadores.txtBuscadorCoche = item.MATRICULA;
                                                obtenerBuscadores.actual = { key: "dropcoche", value: item.MATRICULA };
                                                resultado = obtenerBuscadores;
                                            }

                                        } else {
                                            resultado = { "txtBuscadorCoche": item.MATRICULA, "actual": { key: "dropcoche", value: item.MATRICULA } };
                                        }
                                        store.dispatch({ type: "STATE_SEARCH_COCHE", buscadorCoche: resultado })
                                        store.dispatch({  type: "STATE_SELECCION_DROP", NAME_Drop:  traducirPagina("Matricula"), IDENTIFICADOR: "MAT"})
                                        busquedaCochePorMatriculaOBastiidor(true);
                                    }
                                    }
                                        style={{ height: '40px', width: '135px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: "#bbb", marginRight: '10px' }}>
                                        <img src="./imagenes/cocheGris.svg" style={{ height: '26px', color: 'rgb(102, 102, 102)', cursor: 'pointer', fontSize: '20px', marginRight: '10px' }} alt={"coche"} ></img>

                                        <span style={{ verticalAlign: 'middle', fontSize: '16px' }}></span>{traducirPagina("VER_FICHA")}</button>  </a>]}
                                   style={{ cursor: 'pointer' }} >
                                        <List.Item.Meta
                                            onClick={() => {
                                                props.SELECCIONADO(item)

                                            }}
                                            avatar={<Avatar src="./imagenes/enlace.svg" />}
                                            title={<div><span style={{ color: '#000', fontSize: '16px', fontWeight: '500' }}>{item.MATRICULA}</span></div>}
                                            description={<span style={{ color: '#999' }}>{item.FECHA_ALTA}</span>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </MDBTabPane>
                     
                    </MDBTabContent>
                </MDBContainer>
            </div>
        </Drawer>
    )
}

FichaHistorialVehYQ.propTypes = {
    title: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    SELECCIONADO: PropTypes.func
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,

    };
}


export default connect(mapStateToProps)(FichaHistorialVehYQ);