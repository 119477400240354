import React from "react";
import PropTypes from 'prop-types';
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from 'mdbreact';

import { Drawer, Popover } from 'antd';
import { connect } from 'react-redux';
import { traducirPagina } from "../librerias/funciones"

const SeleccionCocheIQ = ({ props }) => {
    const [atributos, setAtributos] = React.useState([]);


    const cabeceraTabla = (REGISTROS) => {
        let maximo = 0;
        let posicion = 0;
        REGISTROS.map((registro, i) => {
            if (registro.ATRIBUTOS.length > maximo) {
                maximo = registro.ATRIBUTOS.length
                posicion = i
            }
        })

        setAtributos(REGISTROS[posicion].ATRIBUTOS)
    }

    const buscarClaveEnTabla = (REGISTRO_ATRIBUTOS, LISTA_TABLA, POSICION) => {
        const { CLAVE } = REGISTRO_ATRIBUTOS;
        let RESULTADO = LISTA_TABLA.find((item) => item.CLAVE === CLAVE);
        return RESULTADO === undefined ? "" : RESULTADO.VALOR
    }


    React.useEffect(() => {
        cabeceraTabla(props.REGISTROS)
    }, [])



    return (
        <Drawer
            width={1300}
            onClose={() => {
                props.CERRAR_FICHA(false)

            }}
            visible={props.ABRIR_FICHA}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            closable={false}
            style={{ color: '#fff' }}>

            <div style={{ backgroundColor: '#010910 ', color: '#010910', borderBottom: '0px', borderRadius: '0px' }} className="flex-container-Grow">

                <div style={{ width: '55px', display: 'inline-flex' }} >
                    <MDBBtn className="backgroundGris " aria-label="Close" style={{ color: '#000', backgroundColor: '#021323', border: 'none', width: '55px', height: '55px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                        props.CERRAR_FICHA(false)

                    }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ display: 'inline-flex', width: '328px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px' }}>
                    {props.title}
                </div>
            </div>


            <div className="Container_vehiculo_IQ">
                <div className="tituloLeyendaVehiculo">
                    <p>{traducirPagina("Seleccion_vehiculo")}</p>
                </div>

                <div className="container_tabla_vehiculo_IQ">
                    {props.REGISTROS.length > 0 && atributos.length > 0 ?
                        props.REGISTROS !== undefined && props.REGISTROS !== null ?
                            <MDBTable responsive hover style={{ width: '100%' }}>
                                <MDBTableHead>
                                    <tr>
                                    
                                        <th style={{ textAlign: 'left', width: '220px' }}>{traducirPagina("Nombre")}</th>
                                        {atributos.map((registro,i) =>
                                            <th style={{ textAlign: 'left',width:atributos.length - 1 === i  ? '' :'100px' }}>{registro.NOMBRE}</th>
                                        )}
                                    </tr>
                                </MDBTableHead>

                                <MDBTableBody>
                                    {props.REGISTROS.map((row, i) =>
                                        <tr style={{ padding: '.40rem 1.20rem', cursor: 'pointer' }} onClick={() => {
                                            props.EVENTO_SELECCIONADO(row)
                                        }}>
                                            <td style={{ textAlign: 'left' }} >{row.NOMBRE}</td>

                                            {atributos.map((registro, i) =>
                                                <td style={{ textAlign: 'left' }} >{buscarClaveEnTabla(registro, row.ATRIBUTOS, i) }</td>
                                            )}
                                        </tr>
                                    )}
                                </MDBTableBody>
                            </MDBTable>
                            : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}>  <div className="spinner-border  fast colorSubAzul" role="status" disabled /></div>
                        :
                        null
                    }
                </div>
            </div>
        </Drawer>
    );
}

SeleccionCocheIQ.propTypes = {
    title: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    REGISTROS: PropTypes.object,
    EVENTO_SELECCIONADO: PropTypes.func,
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props
    };
}


export default connect(mapStateToProps)(SeleccionCocheIQ);



