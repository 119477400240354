import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createRipple, onChildrenDrawerCloseIntegraciones, DrawerCarroCompra, DrawerMasInformacion, DrawerFichaHistorialPedido } from "../action/actionCreators"
import { getClienteTallerEnAPI, setClienteTallerEnAPI, ObtenerHistoricoPedidosEnAPI, ObtenerPedidoHTMLEnAPI, ObtenerPedidoPDFPDFEnAPI } from "../action/actionCreatorsRequests"
import { traducirPagina, MSGOK, MSGERROR, efectoRemoveBuscadorReferencia, obtenerFechaLocal, convertirMoneda, dynamicSortDesc, descargarPdfPasandoBase64,ordenarTablaLocal } from '../librerias/funciones'
import { MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBInput } from 'mdbreact';
import DetalleHistorialPedido from './DetalleHistorialPedido';
import FichaImpresionGenerico from './FichaImpresionGenerico';
import {
    PlusOutlined,
    CloseOutlined,
    SearchOutlined,
    EditOutlined,
    DeleteOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined,
    PrinterOutlined,
    EyeOutlined
} from '@ant-design/icons';


import { Select, Card, Modal, Button, Input, Row, Col, Drawer } from 'antd';

import store from "../store";
const { Option } = Select;
const FichaHistorialPedido = ({ props, configApp, openDrawerFichaHistorialPedidos }) => {
    const [hayRegistros, setHayRegistros] = React.useState(false);
    const [historial, setHistorial] = React.useState([]);
    const [historialAUX, setHistorialAUX] = React.useState([]);

    const [fechaLocalDesde, setFechaLocalDesde] = React.useState("");
    const [fechaLocalHasta, setFechaLocalHasta] = React.useState("");
    const [actualizarClientes, setActualizarClientes] = React.useState(false);
    const [textoFiltroPresupuesto, setTextoFiltroPresupuesto] = React.useState("");
    const [textoFiltroReferencia, setTextoFiltroReferencia] = React.useState("");

    const [abrirFichaDetalle, setAbrirFichaDetalle] = React.useState(false);
    const [registroSeleccionado, setRegistroSeleccionado] = React.useState({});
    const [ABRIR_FICHA, setABRIR_FICHA] = React.useState(false);
    const [DOC_HTML, setDOC_HTML] = React.useState("");
    const [limpiarBuscadorRef, setLimpiarBuscadorRef] = React.useState(false);
    const [limpiarBuscadorLocal, setLimpiarBuscadorLocal] = React.useState(false);
    const [ordenarTabla, setOrdenarPorTabla] = React.useState({
        CODIGO: false,
        FECHA: false,
        NUMERO_PEDIDO: false,
        PEDIDO_POR: false,
        DESCRIPCION_ALMACEN_SOCIO: false,
        IMPORTE: false,
    });


    const buscarHistorialDePedidos = (PAGE, ORDERCOLUM, ORDERDIR, PAYLOAD) => {
        setHistorial([])
        setHistorialAUX([])
        ObtenerHistoricoPedidosEnAPI(PAGE, ORDERCOLUM, ORDERDIR, { ID: PAYLOAD.ID, CODIGO: PAYLOAD.CODIGO, FECHA_DESDE: PAYLOAD.FECHA_DESDE, FECHA_HASTA: PAYLOAD.FECHA_HASTA, REFERENCIA: PAYLOAD.REFERENCIA }).then((pedidos) => {
            if (pedidos !== undefined) {
                if (pedidos.REGISTROS !== null && pedidos.REGISTROS.length > 0) {
                    setHistorial(pedidos.REGISTROS)
                    setHistorialAUX(pedidos.REGISTROS)
                } else {
                    setHistorial(null)
                    setHistorialAUX([])
                }
            } else {
                setHistorial(null)
                setHistorialAUX([])
            }
        })
    }

    if (!hayRegistros && openDrawerFichaHistorialPedidos) {
        let obtenerFechaLocalDesde = obtenerFechaLocal(true)
        let obtenerFechaLocalHasta = obtenerFechaLocal(false)

        setHayRegistros(true);
        setFechaLocalDesde(obtenerFechaLocalDesde)
        setFechaLocalHasta(obtenerFechaLocalHasta)

        buscarHistorialDePedidos(1, 1, 1, { ID: 0, CODIGO: "", FECHA_DESDE: obtenerFechaLocalDesde, FECHA_HASTA: obtenerFechaLocalHasta, REFERENCIA: "" });
    } else {
        if (!openDrawerFichaHistorialPedidos && hayRegistros) {
            setTextoFiltroPresupuesto("")
            setHayRegistros(false);
        }
    }

    const ordenarTablaDesc = (registros) => {

        let datos = registros.sort(function (a, b) {
            a = a.CABECERA.fechaAlbaran.split('/').reverse().join('');
            b = b.CABECERA.fechaAlbaran.split('/').reverse().join('');
            return a < b ? 1 : a > b ? -1 : 0;
        });
        return datos;
    }

    const ordenarTablaAsc = (registros) => {

        let datos = registros.sort(function (a, b) {
            a = a.CABECERA.fechaAlbaran.split('/').reverse().join('');
            b = b.CABECERA.fechaAlbaran.split('/').reverse().join('');
            return a < b ? 1 : a > b ? -1 : 0;
        });
        return datos;
    }




    const verificarFechas = (desde, hasta) => {
        //let dateDesde = new Date(desde);
        //let dateHasta = new Date(hasta);
        setHistorial([]);
        setFechaLocalDesde(desde)
        setFechaLocalHasta(hasta)
        buscarHistorialDePedidos(1, 1, 1, { ID: 0, CODIGO: "", FECHA_DESDE: desde, FECHA_HASTA: hasta });
    }


    const buscarEnTabla = (palabra, listaCliente) => {

        if (palabra !== "") {
            if (listaCliente !== null) {
                let resultadoFiltro = listaCliente.filter((item) => {
                    return item.CODIGO_PEDIDO_SOCIO
                        .toUpperCase()
                        .includes(palabra.toUpperCase()) ||
                        item.FECHA.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.NUMERO_PEDIDO.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.PEDIDO_POR.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.DESCRIPCION_ALMACEN_SOCIO.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.IMPORTE.toUpperCase().includes(palabra.toUpperCase())
                })

                if (resultadoFiltro.length > 0) {
                    setHistorial(resultadoFiltro);
                } else {
                    setHistorial(null);
                }
            }
        } else {
            setHistorial(historialAUX);
        }

    }

    const buscarDocumento = (ID) => {
        ObtenerPedidoHTMLEnAPI(ID).then((pedido) => {
            if (pedido !== undefined) {
                if (pedido.HTML !== null && pedido.HTML !== "") {
                    setDOC_HTML(pedido.HTML)
                } else {
                    setDOC_HTML("")
                }
            }
        })
    }


    const buscarDocumentoPDF = () => {
        ObtenerPedidoPDFPDFEnAPI(registroSeleccionado.ID).then((pedido) => {
            if (pedido !== undefined) {
                if (pedido.PDF !== "" && pedido.PDF !== null) {
                    descargarPdfPasandoBase64(pedido.PDF, "Pedido")
                }
            }
        })
    }
    const actualizarCabeceraTabla = (e, valor) => {
        const CODIGO = e === 'CODIGO' ? valor : false;
        const FECHA = e === 'FECHA' ? valor : false
        const NUMERO_PEDIDO = e === 'NUMERO_PEDIDO' ? valor : false;
        const PEDIDO_POR = e === 'PEDIDO_POR' ? valor : false;
        const DESCRIPCION_ALMACEN_SOCIO = e === 'DESCRIPCION_ALMACEN_SOCIO' ? valor : false;
        const IMPORTE = e === 'IMPORTE' ? valor : false;


        setOrdenarPorTabla(
            {
                CODIGO: CODIGO,
                FECHA: FECHA,
                NUMERO_PEDIDO: NUMERO_PEDIDO,
                PEDIDO_POR: PEDIDO_POR,
                DESCRIPCION_ALMACEN_SOCIO: DESCRIPCION_ALMACEN_SOCIO,
                IMPORTE: IMPORTE,

            }
        )

    }



    return (
        <div>
            <Drawer
                width={1200}
                onClose={() => { store.dispatch(DrawerFichaHistorialPedido(false)); }}
                visible={openDrawerFichaHistorialPedidos}
                closable={false}

                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ zIndex: '99999999', color: '#fff' }}>

                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                            onClick={(e) => {
                                store.dispatch(DrawerFichaHistorialPedido(false));
                                createRipple(e)
                            }}>
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>


                <div className="p-3" style={{ height: (configApp.height - 70).toString() + "px", overflow: 'auto' }}>
                    <div className="flex-container mdb-skin">

                        <div>
                            <div className="grid-container mb-3" style={{ backgroundColor: '#eee', marginRight: '10px' }}>
                                <div className="grid-item">
                                    <input id="buscadorPresupuestoTaller" className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_en_la_tabla")} style={{ backgroundColor: "transparent", width: '200px', border: '0px', height: '100%', color: '#000' }} name="txtInputPresupuesto" autoComplete="off" value={textoFiltroPresupuesto}
                                        onChange={(e) => { setTextoFiltroPresupuesto(e.target.value); buscarEnTabla(e.target.value, historial); setLimpiarBuscadorLocal(e.target.value.trim() !== "" ? true : false) }} />
                                </div>

                                <div className="grid-item">
                                    <MDBBtn onClick={e => {
                                        createRipple(e); setTextoFiltroPresupuesto("");
                                        setLimpiarBuscadorLocal(false)
                                        buscarEnTabla("", historial)

                                    }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: limpiarBuscadorLocal ? 'visible' : 'hidden' }} className="btn-matriculav2"

                                    >  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                </div>

                                <div className="grid-item barraLateral" ></div>

                                <div className="grid-item">
                                    <MDBBtn onClick={e => { createRipple(e); }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                </div>
                            </div>
                        </div>


                        <div>
                            <div className="grid-container mb-3" style={{ backgroundColor: '#eee' }}>
                                <div className="grid-item">
                                    <input id="buscadorReferencia" className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_por_referencia")} style={{ backgroundColor: "transparent", width: '200px', border: '0px', height: '100%', color: '#000' }} name="txtInputPresupuesto" autoComplete="off" value={textoFiltroReferencia}
                                        onKeyPress={(e) => e.key === 'Enter' ?
                                            buscarHistorialDePedidos(1, 1, 1, { ID: 0, CODIGO: "", FECHA_DESDE: fechaLocalDesde, FECHA_HASTA: fechaLocalHasta, REFERENCIA: textoFiltroReferencia })
                                            : {}}
                                        onChange={(e) => {
                                            setTextoFiltroReferencia(e.target.value);
                                            setLimpiarBuscadorRef(e.target.value.trim() !== "" ? true : false)

                                        }} />
                                </div>


                                <div className="grid-item">
{/**onClick={e => { createRipple(e); efectoRemoveBuscadorReferencia("buscadorReferencia", true); }} */}
                                    <MDBBtn  style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: limpiarBuscadorRef ? 'visible' : 'hidden' }} className="btn-matriculav2" onClick={(e) => {
                                        setTextoFiltroReferencia("")
                                        setLimpiarBuscadorRef(false)
                                        buscarHistorialDePedidos(1, 1, 1, { ID: 0, CODIGO: "", FECHA_DESDE: fechaLocalDesde, FECHA_HASTA: fechaLocalHasta, REFERENCIA: "" });

                                    }}>  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                </div>

                                <div className="grid-item barraLateral" ></div>

                                <div className="grid-item">
                                    <MDBBtn onClick={e => {
                                        createRipple(e);
                                        buscarHistorialDePedidos(1, 1, 1, { ID: 0, CODIGO: "", FECHA_DESDE: fechaLocalDesde, FECHA_HASTA: fechaLocalHasta, REFERENCIA: textoFiltroReferencia });

                                    }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                </div>
                            </div>
                        </div>


                        <div className="grid-item" style={{ backgroundColor: 'transparent', color: '#000', marginTop: '5px', marginLeft: '10px' }}>
                            <input type="date" value={fechaLocalDesde} onChange={(e) => setFechaLocalDesde(e.target.value)}></input>
                        </div>

                        <div className="grid-item" style={{ backgroundColor: 'transparent', color: '#000', marginTop: '5px', marginLeft: '10px' }}>
                            <input type="date" value={fechaLocalHasta} onChange={(e) => setFechaLocalHasta(e.target.value)}></input>
                        </div>

                        <div className="grid-item" style={{ backgroundColor: 'transparent', color: '#000', marginLeft: '5px' }}>

                            <button className="col-md-12" style={{ height: '33px', width: '130px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top', color: '#fff', backgroundColor: '#4285f4' }} onClick={(e) => {
                                verificarFechas(fechaLocalDesde, fechaLocalHasta)
                            }}>
                                <SearchOutlined style={{ fontSize: '20px' }} />
                                <span style={{ verticalAlign: 'middle', fontSize: '16px', marginLeft: '10px' }}>{traducirPagina("Filtrar").toUpperCase()}</span>
                            </button>
                        </div>
                    </div>

                    <Card style={{ height: (configApp.height - 170).toString() + "px" }} className="mdb-skin" >
                        {historial !== undefined ?
                            historial === null ?
                                <div style={{ textAlign: 'center' }}>
                                    <h4>{traducirPagina("No_se_encontraron_pedidos")}</h4>
                                </div>
                                :
                                historial.length > 0 ?

                                    <div style={{ fontSize: '16px', fontWeight: '500', color: "#000", height: (configApp.height - 220).toString() + "px", overflow: 'auto' }} className="row">
                                        <MDBTable responsive hover >
                                            <MDBTableHead>
                                                <tr>
                                                    <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '150px', verticalAlign: 'baseline', cursor: 'pointer' }} onClick={() => {
                                                        actualizarCabeceraTabla("CODIGO", !ordenarTabla.CODIGO);

                                                        setHistorial(ordenarTablaLocal(ordenarTabla.CODIGO ? "ASC" : "DESC", historial, "CODIGO_PEDIDO_SOCIO"))


                                                    }}> {traducirPagina("Codigo").toUpperCase()} {ordenarTabla.CODIGO ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}</th>

                                                    <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '100px', verticalAlign: 'baseline', cursor: 'pointer' }} onClick={() => {
                                                        actualizarCabeceraTabla("FECHA", !ordenarTabla.FECHA);
                                                        setHistorial(ordenarTablaLocal(ordenarTabla.FECHA ? "ASC" : "DESC", historial, "FECHA"))


                                                    }}>{traducirPagina("Fecha").toUpperCase()} {ordenarTabla.FECHA ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}</th>

                                                    <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '150px', verticalAlign: 'baseline' }}onClick={() => {
                                                        actualizarCabeceraTabla("NUMERO_PEDIDO", !ordenarTabla.NUMERO_PEDIDO);
                                                        setHistorial(ordenarTablaLocal(ordenarTabla.NUMERO_PEDIDO ? "ASC" : "DESC", historial, "NUMERO_PEDIDO"))


                                                    }}>{traducirPagina("Mi_pedido").toUpperCase()} {ordenarTabla.NUMERO_PEDIDO ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}</th>

                                                    <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '230x', verticalAlign: 'baseline' }}onClick={() => {
                                                        actualizarCabeceraTabla("PEDIDO_POR", !ordenarTabla.PEDIDO_POR);
                                                        setHistorial(ordenarTablaLocal(ordenarTabla.PEDIDO_POR ? "ASC" : "DESC", historial, "PEDIDO_POR"))


                                                    }}>{traducirPagina("Pedido_por").toUpperCase()} {ordenarTabla.PEDIDO_POR ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}</th>

                                                    <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", verticalAlign: 'baseline' }} onClick={() => {
                                                        actualizarCabeceraTabla("DESCRIPCION_ALMACEN_SOCIO", !ordenarTabla.DESCRIPCION_ALMACEN_SOCIO);
                                                        setHistorial(ordenarTablaLocal(ordenarTabla.DESCRIPCION_ALMACEN_SOCIO ? "ASC" : "DESC", historial, "DESCRIPCION_ALMACEN_SOCIO"))


                                                    }}>{traducirPagina("Almacen").toUpperCase()} {ordenarTabla.DESCRIPCION_ALMACEN_SOCIO ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}</th>

                                                    <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '180px', verticalAlign: 'baseline', textAlign: 'right' }}onClick={() => {
                                                        actualizarCabeceraTabla("IMPORTE", !ordenarTabla.IMPORTE);
                                                        setHistorial(ordenarTablaLocal(ordenarTabla.IMPORTE ? "ASC" : "DESC", historial, "IMPORTE"))


                                                    }}>{traducirPagina("Importe").toUpperCase()} {ordenarTabla.IMPORTE ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}</th>
                                                    <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '100px', verticalAlign: 'baseline', textAlign: 'right' }}> </th>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                {historial.map((pedido, i) =>
                                                    <tr key={i}>
                                                        <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{pedido.CODIGO_PEDIDO_SOCIO}</td>
                                                        <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{pedido.FECHA}</td>
                                                        <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{pedido.NUMERO_PEDIDO}</td>
                                                        <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{pedido.PEDIDO_POR}</td>
                                                        <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{pedido.DESCRIPCION_ALMACEN_SOCIO.toUpperCase()}</td>
                                                        <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle', textAlign: 'right' }}>{convertirMoneda(pedido.IMPORTE, "€")}</td>

                                                        <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>
                                                            <div className="flex-container-Grow-Simple">

                                                                <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                    <MDBBtn onClick={() => {
                                                                        setRegistroSeleccionado(pedido);
                                                                        setAbrirFichaDetalle(true)
                                                                    }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                        <EyeOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                    </MDBBtn>
                                                                </div>

                                                                <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                    <MDBBtn onClick={() => {
                                                                        setRegistroSeleccionado(pedido);
                                                                        setABRIR_FICHA(true)
                                                                        buscarDocumento(pedido.ID)
                                                                    }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                        <PrinterOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                    </MDBBtn>
                                                                </div>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </MDBTableBody>
                                        </MDBTable>
                                    </div>
                                    :
                                    <div style={{ fontSize: '16px', fontWeight: '500', color: "#000" }} className="row">
                                        <MDBCol md="9">
                                            <div>
                                                <span className="skeleton-box" style={{ width: '800px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '800px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '700px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '700px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '600px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '600px' }}></span>
                                            </div>
                                        </MDBCol>
                                    </div>
                            : null}
                    </Card>
                </div>
            </Drawer>

            {abrirFichaDetalle ?
                <DetalleHistorialPedido title={traducirPagina("Historico_de_pedido_enviado").toUpperCase()} VER_FICHA={abrirFichaDetalle} CERRAR_FICHA={setAbrirFichaDetalle} REGISTRO={registroSeleccionado}></DetalleHistorialPedido>
                :
                null
            }
            {ABRIR_FICHA ?
                <FichaImpresionGenerico title={traducirPagina("Ficha_pedido").toUpperCase()} ABRIR_FICHA={ABRIR_FICHA} CERRAR_FICHA={setABRIR_FICHA} DOCUMENTO={DOC_HTML} MODIFICAR_DOCUMENTO={setDOC_HTML} DESCARGAR_DOCUMENTO={buscarDocumentoPDF}> </FichaImpresionGenerico>
                :
                null
            }
        </div>

    )
}


FichaHistorialPedido.propTypes = {
    title: PropTypes.string,
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        openDrawerFichaHistorialPedidos: state.estadoDrawer.openDrawerFichaHistorialPedidos,
        clienteTallerSeleccionado: state.clienteTallerSeleccionado
    };
}

export default connect(mapStateToProps)(FichaHistorialPedido);