import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    CloseOutlined,
    ZoomInOutlined,
    ZoomOutOutlined

} from '@ant-design/icons';

import { Vehiculo_Documentacion_TecnicaEnAPI } from "../../action/actionCreatorsRequests";
import {
    traducirPagina,
    idModeloLatam,
    controlarClickDerecho

} from "../../librerias/funciones"

import { Drawer } from 'antd';
import { useEffect } from 'react';

const VisorDocumentos = (props) => {
    const { TIPO_FICHA, ABRIR_FICHA, PROPIEDADES_DOC,URL } = props
    const [initComponent, setInitComponent] = React.useState({
        REGISTROS: URL
    })

    const [escalaPorDefecto, setEscalaPorDefecto] = React.useState(0.80);
    const buscarDocumentos = async () => {
        let ID_VEHICULO = idModeloLatam()

        //const cambios = await ObtenerDOCVehiculoEnAPI();
        //console.log("cambios",cambios)

        const registros = await Vehiculo_Documentacion_TecnicaEnAPI(ID_VEHICULO);
        const { OK, URL_DOCUMENTO } = registros === undefined ? { OK: false, URL_DOCUMENTO: null } : registros
        if (OK) {
            initComponent.REGISTROS = URL_DOCUMENTO
        } else {
            initComponent.REGISTROS = null
        }
        setInitComponent({ ...initComponent });
    }

    useEffect(() => {
      //  buscarDocumentos();
    }, [])

    return (
        <>
            <Drawer
                width={800}
                onClose={() => {
                    if (TIPO_FICHA === "MASTER") {
                        props.CERRAR.setState({ ABRIR_FICHA_DOC: false })
                    } else {
                        props.CERRAR_FICHA(false)
                    }
                }}
                visible={ABRIR_FICHA}
                placement={"right"}
                bodyStyle={{ padding: '0px', margin: '0px', lineHeight: '0px' }}
                closable={false}
                style={{ color: '#fff' }}>



                <div className="flex-container-Grow-Tercero" style={{ lineHeight: '31px' }} >
                    <div style={{ textAlign: 'inherit' }}>
                        <button className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', padding: '15px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                            if (TIPO_FICHA === "MASTER") {
                                props.CERRAR.setState({ ABRIR_FICHA_DOC: false })
                            } else {
                                props.CERRAR_FICHA(false)
                            }

                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close" style={{ verticalAlign: 'text-bottom' }}>
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </button>
                    </div>

                    <div style={{ flexGrow: '3', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {traducirPagina("Documentos").toUpperCase()}
                    </div>
                </div>


                <div className='container_visor_tecnico' onContextMenu={(e) => e.preventDefault()}>
{/*
                    <div className='eventos_visor'>
                        <button style={{ height: '40px', width: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top', color: '#fff', backgroundColor: '#676767', marginBottom: '5px', marginRight: '5px' }} onClick={(e) => {
                            let operacion = escalaPorDefecto + 0.01
                            setEscalaPorDefecto(operacion)
                        }}>
                            <ZoomInOutlined style={{ fontSize: '22px' }} />

                        </button>

                        <button style={{ height: '40px', width: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top', color: '#fff', backgroundColor: '#676767' }} onClick={(e) => {
                            let operacion = escalaPorDefecto - 0.01
                            setEscalaPorDefecto(operacion)
                        }}>
                            <ZoomOutOutlined style={{ fontSize: '22px' }} />

                        </button>
                    </div>
 */}

                    {
                        initComponent.REGISTROS === null ?
                            <div className="center_item_alert" style={{ height: 'inherit' }}> <h3>{traducirPagina("No_resultados")}</h3> </div>
                            :
                            initComponent.REGISTROS === "" ?
                                <div style={{ height: 'inherit', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <div className="spinner-border  fast colorSubAzul" role="status" disabled /> </div>
                                :

                                <iframe title='Visor de pdf' src={initComponent.REGISTROS} style={{ width: '100%' , height: "100%", border: 0 }} ></iframe>


/*
                                <div className="iframe_Doc" onContextMenu={(e) => e.preventDefault()}>
                                    <iframe
                                        id="iframeVisorDocVehiculo"
                                        onContextMenu={(e) => e.preventDefault()}
                                        width="100%"
                                        height="100%"
                                        frameBorder="0"
                                        src={`https://docs.google.com/gview?url=${initComponent.REGISTROS}${"&embedded=true&toolbar=0&navpanes=0"}`}
                                        //src={`${initComponent.REGISTROS}${"#embedded=true&navpanes=0&toolbar=0"}`}
                                    >
                                    </iframe>
                                </div>
                                */
                    }



                    {/*
                            <div style={{ transform: 'scale(' + escalaPorDefecto + ')', transformOrigin: 'left top 0px', height: '100%' }}>
                        <canvas id="the-canvas"></canvas>
                    </div>

*/}




                </div>

            </Drawer>
        </>
    )
}


VisorDocumentos.propTypes = {
    title: PropTypes.string,
    ABRIR_VISOR: PropTypes.bool,
    CERRAR_VISOR: PropTypes.func,

    PROPIEDADES_DOC: PropTypes.object,
    ZOOM_DOCUMENTO: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
    };
}

export default connect(mapStateToProps)(VisorDocumentos);