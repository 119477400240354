import React from "react";
import { pad } from "../action/actionCreatorsRequests";



export const ImagenArticulo = (props) => {
    const IMGEN_POR_DEFECTO = "./imagenes/sinimagen.jpg";
    const ref = React.useRef()

    const { REGISTRO, DIRECTORIO_DOCUMENTO } = props;
    const { detalles,LOADER } = REGISTRO;
    const [loaded, setLoaded] = React.useState(false)
    const { IMAGEN, IMAGEN_URL, MARCA } = detalles !== undefined && detalles !== null && detalles !== "" && Object.keys(detalles).length > 0 && detalles

    const onLoad = () => {
        setLoaded(true)
    }

    const cargarImagen = () => {
        let IMAGEN_ACTUAL = ""

        if (IMAGEN_URL !== undefined && IMAGEN_URL !== null && IMAGEN_URL !== "") {
            IMAGEN_ACTUAL = IMAGEN_URL
        } else {
            if (IMAGEN !== undefined && IMAGEN !== null && IMAGEN !== "") {
                IMAGEN_ACTUAL = `${DIRECTORIO_DOCUMENTO}${pad(parseInt(MARCA), 4).toString()}/${IMAGEN}`
            }
        }

        return IMAGEN_ACTUAL
    }


    const URL = cargarImagen();

    return (
        <>
            {LOADER !== undefined ?
                <img src={URL}
                    loading="lazy"
                    ref={ref}
                    onLoad={onLoad}
                    onError={(e) => {
                        e.target.src = IMGEN_POR_DEFECTO;
                    }}
                    className="img-fluid z-depth-0"
                    style={{ verticalAlign: 'text-top' }}
                />
                :
                null
            }

            {!loaded ?
                <>
                    <span className="skeleton-box" style={{ width: '100%' }}></span>
                    <br />
                    <span className="skeleton-box" style={{ width: '100%' }}></span>
                    <br />
                    <span className="skeleton-box" style={{ width: '100%' }}></span>
                    <br />
                    <span className="skeleton-box" style={{ width: '100%' }}></span>
                    <br />
                    <span className="skeleton-box" style={{ width: '100%' }}></span>
                    <br />
                    <span className="skeleton-box" style={{ width: '100%' }}></span>

                </>
                :
                null
            }
        </>


    );
};
