import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBNav, MDBNavItem, MDBNavLink, MDBTabPane, MDBTabContent, MDBIcon, MDBCol, MDBCardBody, MDBCard, MDBCardText, MDBRow, MDBTable, MDBTableHead, MDBTableBody, MDBContainer } from 'mdbreact';
import 'antd/dist/antd.css';
import { Drawer } from 'antd';
import { DrawerNextAssist, createRipple } from "../action/actionCreators";
import { traducirPagina } from "../librerias/funciones"
import store from '../store'


const FichaNextAssist = ({ props, configApp, openDrawerNextAssist, usuario }) => {
    const [enlaceNextAssist, setEnlaceNextAssist] = React.useState("");
    const [hayEnlce, setHayEnlce] = React.useState(false);

    if (!hayEnlce && openDrawerNextAssist) {
        setHayEnlce(true)
        let credencial = usuario.LoginSertec.SERVICIOS.filter((registro) => registro.PLATAFORMA === "ASSIST")
        if (credencial[0].USUARIO !== "") {
            setEnlaceNextAssist("https://assist.servicenext.eu/?user=" + credencial[0].USUARIO + "&pwd=" + credencial[0].PWD)
        } else {
            setEnlaceNextAssist("https://assist.servicenext.eu/")
        }
    } else {
        if (!openDrawerNextAssist && hayEnlce) {
            setEnlaceNextAssist("")
            setHayEnlce(false)
        }
    }

    return (
        <Drawer
            width={configApp.width - 360}
            onClose={() => { store.dispatch(DrawerNextAssist(false)); }}
            visible={openDrawerNextAssist}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '99999999', color: '#fff' }}>

            <div className={"estiloDrawerHeader"}>
                <div>
                    <div className="flex-container-Grow-Tercero" style={{ width: (configApp.width - 360).toString() + "px" }}>
                        <div style={{ textAlign: 'inherit' }}>
                            <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { store.dispatch(DrawerNextAssist(false)); createRipple(e) }} >
                                <i aria-label="icon: close" className="anticon anticon-close">
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                    </svg>
                                </i>
                            </MDBBtn>
                        </div>

                        <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                            {props.title}
                        </div>
                    </div>
                </div>


                <iframe
                    id={"nextAssist"}
                    width="100%"
                    height={(configApp.height - 60).toString() + "px"}
                    title={props.title}
                    frameBorder="0"
                    allowtransparency="true"
                    src={enlaceNextAssist}
                />
            </div>
        </Drawer>

    )
}

FichaNextAssist.propTypes = {
    onClick: PropTypes.func,
    config: PropTypes.object,
    detallesReferencia: PropTypes.object,
    masInformacion: PropTypes.object,
    fichaAbierta: PropTypes.bool,
    activeItemOuterTabs: PropTypes.string
};

const mapStateToProps = (state, props) => {
    return {
        masInformacion: state.masInformacion,
        configApp: state.configApp,
        props: props,
        openDrawerNextAssist: state.estadoDrawer.openDrawerNextAssist,
        usuario: state.usuario
    };
}

export default connect(mapStateToProps)(FichaNextAssist);