import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from 'mdbreact';
import 'antd/dist/antd.css';
import { Drawer } from 'antd';
import { DrawerFichaGTEstimate, createRipple, actualizarCodigoGT, actualizarCocheIdentificador, actualizarCocheID, DrawerFichaPresupuestoTaller, DrawerFichaMantenimientoPresupuesto } from "../action/actionCreators";
import { loginHaynesProTouch, getIdVehicleByTecdocNumberV2, GetUrlGTmotiveEnAPI,VerificarPresusupuestoGTEnAPI } from "../action/actionCreatorsRequests";
import store from '../store'
import { traducirPagina } from '../librerias/funciones'

const FichaGTEstimate = ({ props, configApp, openDrawerFichaGTEstimate, fichaBusquedaVehiculo, DROP_SELECCION_COCHE, GT_ESTIMATE }) => {
    const [enlaceHaynes, setEnlaceHaynes] = React.useState("");
    const [hayEnlce, setHayEnlce] = React.useState(false);
    const [DATOS_GT, setDATOS_GT] = React.useState({});

    if (!hayEnlce && openDrawerFichaGTEstimate) {
        setHayEnlce(true)
        let bastidor = ""
        let matricula = ""
        let codigo = ""
        
        if(DROP_SELECCION_COCHE.IDENTIFICADOR.toUpperCase() === "MAT"){
            matricula = fichaBusquedaVehiculo.cocheABuscar;
            bastidor = ""
        }else{
            bastidor = fichaBusquedaVehiculo.cocheABuscar;
            matricula = "";
        }

        
        if(GT_ESTIMATE.ID !== ""){
            codigo = GT_ESTIMATE.ID;
            matricula = "";
            bastidor = "";
        }
        

        GetUrlGTmotiveEnAPI(bastidor, matricula, codigo).then(registro => {
            if (registro.OK) {
                setEnlaceHaynes(registro.URL);
                setDATOS_GT(registro)
                //CODIGO
            } else {
                setEnlaceHaynes(null)
            }
        })
    } else {
        if (!openDrawerFichaGTEstimate && hayEnlce) {
            setEnlaceHaynes("")
            setHayEnlce(false)
            setDATOS_GT({})
        }
    }

    const guardarCodigoGt = async() => {
        if (Object.keys(DATOS_GT).length > 0) {
            if (DATOS_GT.CODIGO !== "") {
                
                const resultado = await VerificarPresusupuestoGTEnAPI(DATOS_GT.ID_PRESUPUESTO_SERTEC,DATOS_GT.ID_PRESUPUESTO_GTESTIMATE,DATOS_GT.CODIGO_PRESUPUESTO_GTESTIMATE);
                const {OK,MENSAJE_ERROR} = resultado;

                if(OK){

                    store.dispatch(DrawerFichaPresupuestoTaller(true));
                    let bastidor = ""
                    let matricula = ""
    
                    if (DROP_SELECCION_COCHE.IDENTIFICADOR.toUpperCase() === "MAT") {
                        matricula = fichaBusquedaVehiculo.cocheABuscar;
                        bastidor = ""
                    } else {
                        bastidor = fichaBusquedaVehiculo.cocheABuscar;
                        matricula = "";
                    }
    
    
                    let identificador = matricula === "" ? bastidor : matricula
                    store.dispatch(actualizarCodigoGT(DATOS_GT.ID_PRESUPUESTO_SERTEC))
                    store.dispatch(actualizarCocheIdentificador(identificador))
                    store.dispatch(actualizarCocheID(DATOS_GT.ID_PRESUPUESTO_GTESTIMATE))
    
                    setTimeout(() => {
                        store.dispatch({ type: "UPDATE_PRESUPUESTO_ID_TALLER", id: DATOS_GT.ID_PRESUPUESTO_SERTEC });
                        //store.dispatch({ type: "UPDATE_PRESUPUESTO_ID_TALLER", id: 326 });
                        store.dispatch({ type: "UPDATE_MODO_FICHA_PRESUPUESTO_TALLER", esNuevoPresupuesto: false });
                        store.dispatch(DrawerFichaMantenimientoPresupuesto(true));
                    }, 500)

                }

            }
        }
    }


    return (
        <Drawer
            width={"80%"}
            onClose={() => { store.dispatch(DrawerFichaGTEstimate(false)); guardarCodigoGt() }}
            visible={openDrawerFichaGTEstimate}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '99999999', color: '#fff' }}>
            <div>
                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { store.dispatch(DrawerFichaGTEstimate(false)); createRipple(e); guardarCodigoGt() }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>
            </div>


            {enlaceHaynes !== null ?
                enlaceHaynes === "" ?
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div style={{ color: 'black' }} color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                    :
                    <iframe
                        id={"GT_ESTIMATE"}
                        width="100%"
                        height={(configApp.height - 66).toString() + "px"}
                        title={props.title}
                        frameBorder="0"
                        allowtransparency="true"
                        src={enlaceHaynes}

                    />
                :
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("No_conexion_GT_ESTIMATE")}   </h3> </div>
            }

        </Drawer>

    )
}

FichaGTEstimate.propTypes = {

    IDENTIFICADOR: PropTypes.string,
    TIPO_BUSQUEDA: PropTypes.string

};

const mapStateToProps = (state, props) => {
    return {
        masInformacion: state.masInformacion,
        configApp: state.configApp,
        props: props,
        openDrawerFichaGTEstimate: state.estadoDrawer.openDrawerFichaGTEstimate,
        fichaBusquedaVehiculo: state.fichaBusquedaVehiculo,
        DROP_SELECCION_COCHE: state.DROP_SELECCION_COCHE,
        GT_ESTIMATE: state.GT_ESTIMATE
    };
}

export default connect(mapStateToProps)(FichaGTEstimate);