import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    CloseOutlined

} from '@ant-design/icons';

import { Card, Input, Row, Col, Drawer, Tabs, Carousel } from 'antd';
import store from "../store";
const { TabPane } = Tabs;

const VisorBoletines = ({ props, configApp }) => {
    return (
        <div style={{ backgroundColor: "rgba(0, 0, 0, 0.9)", display: props.ABRIR_VISOR ? '' : 'none',width:'100%' }} className="react-simple-image-viewer__modal"

            onClick={() => {
                props.CERRAR_VISOR(false);
            }}>
            <span className="react-simple-image-viewer__close" onClick={() => {
                props.CERRAR_VISOR(false);
            }}> <CloseOutlined /> </span>
            <span className="react-simple-image-viewer__span">{props.PROPIEDADES_DOC.TITULO.toUpperCase()}</span>

            <div className="react-simple-image-viewer__modal-content">
                {props.TIPO_DOCUMENTO.includes("DOC") ?
                    <div>
                        <iframe src={props.PROPIEDADES_DOC.URL} style={{ width: '900px', height: '0px', border: 0, marginTop: '62px' }} ></iframe>
                    </div>
                    :
                    props.ZOOM_DOCUMENTO ?
                        <div style={{ height: (configApp.height - 180).toString() + "px", overflow: 'auto', textAlign: 'center' }}>
                            <img src={props.PROPIEDADES_DOC.URL} alt="" style={{ backgroundColor: '#fff' }}></img>
                        </div>
                        :
                        <img src={props.PROPIEDADES_DOC.URL} alt="" style={{ height: '500px' }} ></img>
                }
            </div>
        </div>
    )
}


VisorBoletines.propTypes = {
    title: PropTypes.string,
    ABRIR_VISOR: PropTypes.bool,
    CERRAR_VISOR: PropTypes.func,
    TIPO_DOCUMENTO: PropTypes.string,
    PROPIEDADES_DOC: PropTypes.object,
    ZOOM_DOCUMENTO: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
    };
}

export default connect(mapStateToProps)(VisorBoletines);